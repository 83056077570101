import { RumInitConfiguration } from '@datadog/browser-rum';

import { getEnvironment } from 'utils/environmentUtils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_RUM: any;
  }
}

type EventResource = {
  url?: string;
};

const validUrls = [
  process.env.REACT_APP_URL || '',
  process.env.REACT_APP_API_URL || '',
  process.env.REACT_APP_EMBED_ASSET_URL || '',
];

const isValidResource = (eventResource: EventResource) => {
  if (!eventResource.url) return false;

  for (const url in validUrls) {
    if (eventResource.url.includes(validUrls[url])) {
      return true;
    }
  }

  return false;
};

const shouldReportErrors =
  !!window.DD_RUM &&
  ['staging', 'production'].includes(getEnvironment()) &&
  process.env.REACT_APP_ENVIRONMENT !== 'production-medme';

const initDataDog = (env: 'app' | 'embed') => {
  if (!shouldReportErrors) return;

  const isApp = env === 'app';
  const sampleRate = isApp || getEnvironment() === 'staging' ? 100 : 10;

  const sharedConfig = {
    site: 'datadoghq.com',
    proxyUrl: `${process.env.REACT_APP_API_URL}telemetry/events/`,
    // This needs to match EMBEDDO_ENVIRONMENT in Heroku
    // REACT_APP_ENVIRONMENT should match in every case except for preview envs
    // REACT_APP_ENV_TAG is generated for preview envs only
    env: process.env.REACT_APP_ENV_TAG ?? process.env.REACT_APP_ENVIRONMENT,
    version: process.env.PKG_VERSION,
    sampleRate,
    allowedTracingOrigins: [process.env.REACT_APP_API_URL ?? ''],
    tracingSampleRate: sampleRate,
  };

  const appConfig: RumInitConfiguration = {
    ...sharedConfig,
    applicationId: '465ae197-1316-43f4-b293-a8ba17e7e945',
    clientToken: 'pub4332059a181bb617916141bd0751432b',
    service: 'embeddo',
    proxyUrl: `${process.env.REACT_APP_API_URL}telemetry/events/`,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  };

  const embedConfig: RumInitConfiguration = {
    ...sharedConfig,
    applicationId: '3ecfd5e2-3c0c-4915-80d7-f77e324b3e08',
    clientToken: 'puba1bfcfb6b746a053fc643b5bdd9ae537',
    service: 'embeddo-embed',
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: false,
    beforeSend: (event) => {
      if (event.error) {
        // Discard error events that aren't manually reported
        // Errors reported from customer code can't automatically be differentiated from our own
        if (!event.context?.manual) {
          return false;
        }
      } else if (event.resource) {
        // Discard resource events that aren't in the url array
        if (!isValidResource(event.resource)) {
          return false;
        }
      } else if (event.type === 'view') {
        // Can't discard view events
      } else {
        // Discard uncaught events for embed
        return false;
      }
    },
  };

  const config = isApp ? appConfig : embedConfig;
  window.DD_RUM.init(config);

  if (isApp) {
    window.DD_RUM.startSessionReplayRecording();
  }
};

type RumUser = {
  email?: string;
  endUserId?: number;
  endUserName?: string;
  teamId?: number;
  teamName?: string;
};

const setUser = (user: RumUser) => {
  if (!shouldReportErrors) return;

  window.DD_RUM.setUser(user);
};

const clearUser = () => {
  if (!shouldReportErrors) return;

  window.DD_RUM.clearUser();
};

const reportError = (error: unknown, errorInfo?: object | undefined) => {
  if (!shouldReportErrors) return;

  window.DD_RUM.addError(error, { ...errorInfo, manual: true });
};

export { initDataDog, reportError, setUser, clearUser };

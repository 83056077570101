import { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { CredentialsInput } from './CredentialsInput';
import { Select, APP_PORTAL_ID } from 'components/ds';
import {
  DatabasesToPort,
  DATABASES,
  DATA_SOURCE_AUTH,
  SUPPORTED_FIDO_DATA_SOURCES,
} from 'pages/ConnectDataSourceFlow/constants';

import * as styles from './CredentialsSection.css';
import {
  setPasswordAuthentication,
  setDataSourceConfig,
  setAwsAuthOptions,
} from 'reducers/fidoDataSourceConfigurationReducer';

const configSchema = (type: DATABASES) => {
  switch (type) {
    case DATABASES.SQLSERVER:
      return {
        hostHelp: undefined,
        hostLabel: 'Server',
        hostPlaceholder: '<x>.database.windows.net',
        databasePlaceholder: 'master',
      };
    case DATABASES.POSTGRES:
    case DATABASES.MYSQL:
      return {
        hostHelp:
          'The name of the server that hosts the database.  This does not include "https://".',
        hostLabel: 'Hostname',
        hostPlaceholder: 'e.g. rds.amazonaws.com',
        databasePlaceholder: 'e.g. acme_production',
      };
  }
};

const DataSourceAuthOptions = [
  { value: DATA_SOURCE_AUTH.USERNAME_PASSWORD, label: 'Username and Password' },
];

export const FidoCredentialsSection: FC = () => {
  const { config, type, awsAuthOptions, isUpdatingDataSource } = useSelector(
    (state: ReduxState) => ({
      config: state.fidoDataSourceConfig.config,
      type: state.fidoDataSourceConfig.type,
      awsAuthOptions: state.fidoDataSourceConfig.awsAuthOptions,
      isUpdatingDataSource: state.fidoDataSourceConfig.isUpdatingDataSource,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  if (!type || !SUPPORTED_FIDO_DATA_SOURCES.includes(type)) {
    return null;
  }

  const configSchemaForType = configSchema(type);
  if (!configSchemaForType) return null;

  const expectedPort = DatabasesToPort[type];

  return (
    <div>
      <CredentialsInput
        helpTooltip={configSchemaForType.hostHelp}
        label={configSchemaForType.hostLabel}
        placeholder={configSchemaForType.hostPlaceholder}
        updateConfig={(host) => dispatch(setDataSourceConfig({ host }))}
        value={config?.host}
      />

      <CredentialsInput
        helpTooltip={`${expectedPort} is the default for ${type} databases, but in some instances, it might be different.`}
        label="Port"
        placeholder={`e.g. ${expectedPort}`}
        type="number"
        updateConfig={(port) => dispatch(setDataSourceConfig({ port: Number(port) }))}
        value={config?.port}
      />

      <CredentialsInput
        label="Database"
        placeholder={configSchemaForType.databasePlaceholder}
        updateConfig={(database) => dispatch(setDataSourceConfig({ database }))}
        value={config?.database}
      />

      <div>
        <Select
          className={styles.credentialInput}
          label="Authentication"
          onChange={(newAuthMethod) =>
            dispatch(setDataSourceConfig({ authMethod: newAuthMethod as string }))
          }
          portalContainerId={APP_PORTAL_ID}
          selectedValue={config.authMethod}
          values={DataSourceAuthOptions}
        />

        {config.authMethod === DATA_SOURCE_AUTH.USERNAME_PASSWORD ? (
          <>
            <CredentialsInput
              helpTooltip="We recommend making a read-only account for Explo."
              label="Username"
              placeholder="e.g. analytics"
              updateConfig={(username) => dispatch(setPasswordAuthentication({ username }))}
              value={config?.authentication?.username}
            />
            <CredentialsInput
              helpTooltip="The password is associated with the username above."
              label="Password"
              optional={isUpdatingDataSource}
              placeholder={
                isUpdatingDataSource ? 'password will be unmodified if left blank' : undefined
              }
              type="password"
              updateConfig={(password) =>
                dispatch(setPasswordAuthentication({ password: password || null }))
              }
              value={config?.authentication?.password}
            />
          </>
        ) : (
          <>
            <CredentialsInput
              helpTooltip="We recommend making a read-only account for Explo."
              label="Username"
              placeholder="e.g. analytics"
              updateConfig={(username) => dispatch(setAwsAuthOptions({ username }))}
              value={awsAuthOptions?.username}
            />
            <CredentialsInput
              label="AWS Region"
              placeholder="us-east-1"
              updateConfig={(region) => dispatch(setAwsAuthOptions({ region }))}
              value={awsAuthOptions?.region}
            />
            <CredentialsInput
              label="AWS Access Key ID"
              updateConfig={(accessKey) => dispatch(setAwsAuthOptions({ accessKey }))}
              value={awsAuthOptions?.accessKey}
            />
            <CredentialsInput
              label="AWS Secret Key"
              placeholder="e.g. **********"
              type="password"
              updateConfig={(secretKey) => dispatch(setAwsAuthOptions({ secretKey }))}
              value={awsAuthOptions?.secretKey}
            />
            <CredentialsInput
              optional
              label="SSL Cert"
              placeholder="e.g. -----BEGIN CERTIFICATE-----"
              type="textarea"
              updateConfig={(sslCert) => dispatch(setAwsAuthOptions({ sslCert }))}
              value={awsAuthOptions?.sslCert}
            />
          </>
        )}
      </div>
    </div>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { v4 as uuidv4 } from 'uuid';

import {
  NamespaceResourceService,
  TestConnectionRequest,
  TestConnectionResponse,
  TestConnectionResourceService,
  NamespaceResponse,
  DataSourceResourceService,
  DataSourceResponse,
  DataSourceRequest,
  UUID,
  DataSourceConfiguration,
} from '@explo-tech/fido-api';

import { ACTION } from 'actions/types';
import { createApiRequestConfig } from 'actions/actionUtils';
import { DataSource, ParentSchema } from 'actions/dataSourceActions';
import { makeFidoThunkRequest, makeThunkRequest } from 'utils/thunkUtils';
import { ReduxState } from 'reducers/rootReducer';
import { ErrorResponse } from 'actions/responseTypes';

type ConnectDataSourceUsingFidoBody = {
  fido_id: string;
  fido_namespace_id: string;
  fido_namespace_name: string;
  provided_id: string;
  access_group_ids: number[];
  onSuccess: () => void;
};

// create_datasource is going to replace our connection function.
// This creates the data source such that it and its parent schema are linked to fido via fido_id
export const createEmbeddoDataSource = createAsyncThunk<
  { data_source: DataSource; schema: ParentSchema | undefined },
  ConnectDataSourceUsingFidoBody,
  { state: ReduxState }
>(
  ACTION.CREATE_EMBEDDO_DATA_SOURCE,
  async ({
    fido_id,
    fido_namespace_id,
    provided_id,
    access_group_ids,
    fido_namespace_name,
    onSuccess,
  }) => {
    return makeThunkRequest(
      createApiRequestConfig('datasources/create_datasource/', 'POST', {
        fido_id,
        fido_namespace_id,
        fido_namespace_name,
        provided_id,
        access_group_ids,
      }),
      'Error connecting data source',
      { onSuccess },
    );
  },
);

type ConnectDataSourceInfo = {
  schemaName: string;
  dataSourceName: string;
  externalId: string;
  accessGroupIds: number[];
  finalConfig: DataSourceConfiguration;
  onSuccess: () => void;
};

export const createNamespace = createAsyncThunk<
  NamespaceResponse,
  ConnectDataSourceInfo,
  { state: ReduxState }
>(
  ACTION.CREATE_NAMESPACE,
  async (
    { schemaName, dataSourceName, externalId, accessGroupIds, finalConfig, onSuccess },
    { getState, dispatch },
  ) => {
    const namespaceId = uuidv4();
    return makeFidoThunkRequest(
      () =>
        NamespaceResourceService.createNamespace({
          namespace: { name: schemaName, id: namespaceId },
        }),
      getState().fido.fidoToken ?? '',
      'Error creating namespace',
      (namespaceResponse: NamespaceResponse) =>
        dispatch(
          createDataSourceInFido({
            namespaceId: namespaceResponse.namespace.id,
            namespaceName: schemaName,
            dataSourceRequest: {
              dataSource: {
                name: dataSourceName,
                externalId,
                namespaceId,
                configuration: finalConfig,
              },
            },
            accessGroupIds,
            onSuccess,
          }),
        ),
    );
  },
);

export const createDataSourceInFido = createAsyncThunk<
  DataSourceResponse,
  {
    namespaceId: UUID;
    namespaceName: string;
    dataSourceRequest: DataSourceRequest;
    accessGroupIds: number[];
    onSuccess: () => void;
  },
  { state: ReduxState }
>(ACTION.CREATE_DATA_SOURCE_IN_FIDO, async (requestBody, { getState, dispatch }) => {
  return makeFidoThunkRequest(
    () =>
      DataSourceResourceService.createDataSource(
        requestBody.namespaceId,
        requestBody.dataSourceRequest,
      ),
    getState().fido.fidoToken ?? '',
    'Error creating data source',
    (datasourceResponse) => {
      return dispatch(
        createEmbeddoDataSource({
          fido_id: datasourceResponse.dataSource.id,
          fido_namespace_id: datasourceResponse.dataSource.namespaceId,
          fido_namespace_name: requestBody.namespaceName,
          provided_id: datasourceResponse.dataSource.externalId,
          access_group_ids: requestBody.accessGroupIds,
          onSuccess: requestBody.onSuccess,
        }),
      );
    },
  );
});

export const testConnectionUsingFido = createAsyncThunk<
  TestConnectionResponse,
  TestConnectionRequest & { onSuccess: () => void; onError: () => void },
  { state: ReduxState }
>(
  ACTION.TEST_DATA_SOURCE_CONNECTION_USING_FIDO,
  async ({ configuration, onSuccess, onError }, { getState }) =>
    makeFidoThunkRequest(
      () => TestConnectionResourceService.testConnection({ configuration }),
      getState().fido.fidoToken ?? '',
      'Error testing data source connection',
      onSuccess,
      onError,
    ),
);

export const retestConnectionUsingFido = createAsyncThunk<
  TestConnectionResponse,
  TestConnectionRequest & {
    id: UUID;
    onSuccess: (data: TestConnectionResponse) => void;
    onError: (e?: string) => void;
  },
  { state: ReduxState }
>(
  ACTION.RETEST_DATA_SOURCE_CONNECTION_USING_FIDO,
  async ({ configuration, id, onSuccess, onError }, { getState }) =>
    makeFidoThunkRequest(
      () => TestConnectionResourceService.retestConnection(id, { configuration }),
      getState().fido.fidoToken ?? '',
      'Error retesting data source connection',
      onSuccess,
      (e: ErrorResponse) => onError(e?.detail),
    ),
);

export const updateDataSourceInFido = createAsyncThunk<
  DataSourceResponse,
  DataSourceRequest & {
    id: UUID;
    namespaceId: UUID;
    onSuccess: () => void;
    onError: (e: ErrorResponse) => void;
  },
  { state: ReduxState }
>(
  ACTION.UPDATE_DATA_SOURCE_IN_FIDO,
  async ({ dataSource, id, namespaceId, onSuccess, onError }, { getState }) =>
    makeFidoThunkRequest(
      () => DataSourceResourceService.updateDataSource(id, namespaceId, { dataSource }),
      getState().fido.fidoToken ?? '',
      'Error updating data source configuration',
      onSuccess,
      onError,
    ),
);

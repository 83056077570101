import { FC, useMemo } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { SettingHeader } from 'components/SettingHeader';
import { CollapsibleGroup } from 'components/CollapsibleGroup';
import { MapTooltipConfig } from './MapTooltipConfig';

import { Switch, sprinkles } from 'components/ds';

import { OPERATION_TYPES, VisualizeOperationInstructions } from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { NUMBER_TYPES } from 'constants/dataConstants';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';

type Props = {
  instructions: VisualizeOperationInstructions;
  schema: DatasetSchema;
  dataPanelId: string;
};

export const LocationMarkerMapTooltipConfig: FC<Props> = ({
  instructions,
  schema,
  dataPanelId,
}) => {
  const dispatch = useDispatch();

  const latitudeColumnName = instructions.VISUALIZE_GEOSPATIAL_CHART?.latitudeColumn?.name;
  const longitudeColumnName = instructions.VISUALIZE_GEOSPATIAL_CHART?.longitudeColumn?.name;

  const formattableSchema = useMemo(
    () =>
      schema.filter(
        (col) =>
          NUMBER_TYPES.has(col.type) &&
          latitudeColumnName !== col.name &&
          longitudeColumnName !== col.name,
      ),
    [schema, latitudeColumnName, longitudeColumnName],
  );

  return (
    <>
      <SettingHeader
        infoText="Format the tooltip's columns and its header. You can only format numeric columns."
        name="Tooltip"
      />
      <div className={containerStyle}>
        <Switch
          label="Show header"
          onChange={() => {
            if (!instructions.VISUALIZE_GEOSPATIAL_CHART) return;
            const newInstructions = produce(instructions.VISUALIZE_GEOSPATIAL_CHART, (draft) => {
              if (!draft.mapFormat) draft.mapFormat = {};
              draft.mapFormat.hideTooltipHeader = !draft.mapFormat.hideTooltipHeader;
            });
            dispatch(
              updateVisualizeOperation(
                newInstructions,
                OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP,
              ),
            );
          }}
          switchOn={!instructions.VISUALIZE_GEOSPATIAL_CHART?.mapFormat?.hideTooltipHeader}
        />
        {formattableSchema.map((col) => {
          return (
            <CollapsibleGroup key={`column-${col.name}`} title={col.name}>
              <MapTooltipConfig
                hideHeader
                colName={col.name}
                dataPanelId={dataPanelId}
                instructions={instructions}
                operationType={OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP}
              />
            </CollapsibleGroup>
          );
        })}
      </div>
    </>
  );
};

const containerStyle = sprinkles({ flexItems: 'column', padding: 'sp1.5', gap: 'sp1.5' });

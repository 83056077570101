import { FC, Suspense, lazy, useCallback, ReactNode } from 'react';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';

import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';

import { DatasetSchema } from 'types/datasets';
import * as styles from './index.css';
import { loadMask } from '../EmbedDataGrid/index.css';
import '@inovua/reactdatagrid-enterprise/index.css';

import { TypeCellProps, TypeFooterRow } from '@inovua/reactdatagrid-community/types';
import { TypeSummaryReducer } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { PIVOT_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';

export type EmbedPivotTableRow = Record<string, ReactNode>;

const ReactDataGrid = lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@inovua/reactdatagrid-enterprise'),
);

type Props = {
  groupByColumns: string[];
  loading: boolean;
  pivotColumns: string[];
  schema: DatasetSchema;
  rows: EmbedPivotTableRow[];
  // Specify columns and groupColumn to override default columns generated from schema
  columns: TypeColumn[];
  groupColumn: TypeColumn;
  onCellClick?: (event: MouseEvent, cellProps: TypeCellProps) => void;
  summaryReducer?: TypeSummaryReducer;
  footerRows?: TypeFooterRow[];
};

export const EmbedPivotTable: FC<Props> = ({
  loading,
  schema,
  rows,
  groupByColumns,
  pivotColumns,
  columns,
  groupColumn,
  onCellClick,
  footerRows,
  summaryReducer,
}) => {
  const renderLoadMask = useCallback(({ visible }: { visible: boolean }) => {
    return visible ? <EmbedSpinner fillContainer className={loadMask} /> : null;
  }, []);

  return (
    <div className={sprinkles({ parentContainer: 'fill', flexItems: 'column' })}>
      <Suspense fallback={<EmbedSpinner fillContainer />}>
        <ReactDataGrid
          disableGroupByToolbar
          className={styles.dataGrid}
          columnOrder={schema?.map((col) => col.name)}
          columns={columns}
          dataSource={rows}
          footerRows={footerRows}
          groupBy={groupByColumns}
          groupColumn={groupColumn}
          idProperty="id"
          licenseKey={process.env.REACT_APP_DATAGRID_KEY}
          loading={loading}
          onCellClick={onCellClick}
          pivot={pivotColumns}
          renderLoadMask={renderLoadMask}
          reorderColumns={false}
          showColumnMenuTool={false}
          sortable={false}
          summaryReducer={summaryReducer}
        />
        {rows.length >= PIVOT_ROW_LIMIT ? (
          <div className={styles.rowLimitContainer}>
            Pivot row limit reached, please filter your data or reduce the number of groups.
          </div>
        ) : null}
      </Suspense>
    </div>
  );
};

import { FC } from 'react';

import { Input, Switch } from 'components/ds';
import * as styles from './styles.css';

import { LabelWithTooltipConfig } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from './utils';

type Props = {
  config: LabelWithTooltipConfig;

  updateLabelConfig: (config: LabelWithTooltipConfig) => void;
};

export const LabelConfig: FC<Props> = ({ config, updateLabelConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateLabelConfig);

  return (
    <>
      <Input
        showInputButton
        className={styles.configInput}
        defaultValue={config.label}
        label="Label"
        onSubmit={(newValue) => updateConfig((draft) => (draft.label = newValue))}
        placeholder="Enter a label"
      />
      <Switch
        className={styles.configInput}
        label="Descriptive Tooltip"
        onChange={() => updateConfig((draft) => (draft.showTooltip = !draft.showTooltip))}
        switchOn={config.showTooltip}
      />
      {config.showTooltip ? (
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.infoTooltipText}
          onSubmit={(newValue) => updateConfig((draft) => (draft.infoTooltipText = newValue))}
        />
      ) : null}
    </>
  );
};

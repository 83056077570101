import cx from 'classnames';
import { FC, MouseEventHandler } from 'react';

import { sprinkles, Icon } from 'components/ds';
import { IconName } from 'components/ds/Icon';

import * as styles from './exportStyles.css';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

interface ExportOptionItemProps {
  onClick: MouseEventHandler;
  option: string;
  icon: IconName;
  rightArrow?: boolean;
}

export const ExportOptionItem: FC<ExportOptionItemProps> = ({
  onClick,
  option,
  icon,
  rightArrow,
}) => (
  <div
    className={cx(
      styles.menuItem,
      GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownItemHover,
    )}
    onClick={onClick}>
    <div className={styles.menuItemLabelIcon}>
      <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} />
      <div className={embedSprinkles({ body: 'primary' })}>{option}</div>
    </div>
    {rightArrow ? (
      <Icon className={sprinkles({ color: 'contentTertiary' })} name="arrow-right" />
    ) : null}
  </div>
);

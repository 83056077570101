import { createSelector, createSlice, Reducer } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import * as actions from 'actions/reportBuilderActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { fetchReportBuilderVersionsSuccess } from 'actions/reportBuilderVersionActions';
import { cloneResourceThunk } from './thunks/versionManagementThunks';
import { moveEntry, listReportFolderContents } from 'reducers/thunks/resourceThunks';

interface ReportBuilderReducerState {
  currentReportBuilder: number | null;
  reportBuilders: RD.ResponseData<ReportBuilder[]>;
}

const reportBuilderReducerInitialState: ReportBuilderReducerState = {
  currentReportBuilder: null,
  reportBuilders: RD.Idle(),
};

export const getCurrentReportBuilder = createSelector(
  (state: ReportBuilderReducerState) => state.currentReportBuilder,
  (state: ReportBuilderReducerState) => state.reportBuilders,

  (reportBuilderId, reportBuilders) => {
    if (reportBuilderId === null || !RD.isSuccess(reportBuilders)) return null;

    return (
      reportBuilders.data.find((reportBuilder) => reportBuilder.id === reportBuilderId) ?? null
    );
  },
);

const reportBuilderSlice = createSlice({
  name: 'reportBuilder',
  initialState: reportBuilderReducerInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(actions.fetchReportBuildersActions.requestAction, (state) => {
        state.reportBuilders = RD.Loading();
      })
      .addCase(actions.fetchReportBuildersActions.errorAction, (state) => {
        state.reportBuilders = RD.Error('Error Loading Report Builders');
      })
      .addCase(actions.fetchReportBuildersActions.successAction, (state, { payload }) => {
        state.reportBuilders = RD.Success(payload.report_builders);
      })
      .addCase(listReportFolderContents.pending, (state) => {
        state.reportBuilders = RD.Loading();
      })
      .addCase(listReportFolderContents.rejected, (state) => {
        state.reportBuilders = RD.Error('Error Loading Report Builders');
      })
      .addCase(listReportFolderContents.fulfilled, (state, { payload }) => {
        state.reportBuilders = RD.Success(payload.resources);
      })
      .addCase(actions.createReportBuilderSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.reportBuilders)) return;

        state.reportBuilders.data.push(payload.report_builder);
        state.currentReportBuilder = payload.report_builder.id;
      })
      .addCase(actions.deleteReportBuilderSuccess, (state, { payload }) => {
        state.reportBuilders = RD.map(state.reportBuilders, (reportBuilders) =>
          reportBuilders.filter((rb) => rb.id !== payload.id),
        );
      })
      .addCase(actions.renameReportBuilderSuccess, (state, { payload }) => {
        RD.update(state.reportBuilders, (reportBuilders) => {
          const reportBuilder = reportBuilders.find((elem) => elem.id === payload.id);
          if (reportBuilder) reportBuilder.name = payload.name;
        });
      })
      .addCase(fetchReportBuilderVersionsSuccess, (state, { payload }) => {
        if (typeof payload.id === 'number') state.currentReportBuilder = payload.id;
      })
      .addCase(cloneResourceThunk.fulfilled, (state, { payload, meta }) => {
        const { new_resource } = payload;
        if (meta.arg.isExplore || 'dashboard_attributes' in new_resource) return;

        RD.update(state.reportBuilders, (reportBuilders) => {
          state.currentReportBuilder = new_resource.id;
          reportBuilders.push(new_resource);
        });
      })
      .addCase(moveEntry.fulfilled, (state, { payload }) => {
        /**
         * Move will only ever remove things from the current view
         * because we can move into different folders (out of view)
         * or we can use the move modal on a report currently in view
         * to move it into another folder, so out of view.
         */
        if (payload.type !== 'report' || !RD.isSuccess(state.reportBuilders)) return;
        state.reportBuilders.data = state.reportBuilders.data.filter(({ id }) => id !== payload.id);
      }),
});

// see dashboardStylesReducer for this bullshit
export const reportBuilderReducer =
  reportBuilderSlice.reducer as Reducer<ReportBuilderReducerState>;

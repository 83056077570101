import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Input } from 'components/ds';
import { NumberFormatToggle } from 'components/NumberFormatToggle';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, YAxisFormat } from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  DEFAULT_Y_AXIS_FORMAT_INDEX,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const InteractiveTooltipConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const yAxisFormat = instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX];
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  const updateYAxis = (updatedYAxisFormat: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...updatedYAxisFormat };
    dispatch(
      updateVisualizeOperation(updateYAxisFormat(newYAxisFormat, instructions), visualizationType),
    );
  };

  return (
    <div className={configRootClass}>
      <NumberFormatToggle
        disableAbbr
        disableTime
        className={configInputClass}
        label="Value Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateYAxis({ numberFormat: { id: numberFormat } })}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(yAxisFormat?.decimalPlaces ?? 2)}
        label="Value Decimal Places"
        onSubmit={(newValue: string) => {
          const intValue = parseInt(newValue);
          updateYAxis({ decimalPlaces: intValue > 0 ? intValue : 0 });
        }}
      />
    </div>
  );
};

import { FC, useState, useMemo, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import {
  sprinkles,
  IconButton,
  Checkbox,
  Tooltip,
  APP_PORTAL_ID,
  Input,
  Select,
} from 'components/ds';
import { InfoCard } from 'components/InfoCard';
import * as styles from './styles.css';

import { setSelectedDrilldownColumn } from 'actions/cssLinkActions';
import { ReduxState } from 'reducers/rootReducer';
import {
  DatasetConfig,
  DrilldownColConfig,
  DrilldownColConfigOptions,
  updateDatasetDrilldownColConfig,
  updateDatasetDrilldownColConfigs,
} from 'actions/datasetActions';
import { sortBy } from 'utils/standard';
import { titleCase } from 'utils/graphUtils';
import { extendedDrilldownConfigToNormal, initConfig } from 'utils/drilldownDatasetUtils';
import { DatasetSchema } from 'types/datasets';
import { COLUMN_FITS, ColumnFitOptions } from 'constants/types';
import { updateDatasetDrilldownConfig } from 'reducers/dashboardEditConfigReducer';

type Props = {
  datasetConfig: DatasetConfig;
  datasetSchema: DatasetSchema;
};

export interface ExtendedDrilldownColConfig extends DrilldownColConfig {
  name: string;
  type: string;
}

export const DrilldownDatasetColumns: FC<Props> = ({ datasetConfig, datasetSchema }) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  /** Schema is source of truth
   * 1. Map over schema to get name and type - schema is source of truth
   * 2. If drilldown config exists, which it won't for previously saved datasets
   *    For each column in the schema find the corresponding drilldown info
   *    If it exists, add the corresponding config info
   *    If it doesn't exist, assume isIncluded and isVisible, and index by:
   *    have a count starting at length of drilldown config or 0, and assign index as we go
   */
  const schemaMappedWithConfigs: ExtendedDrilldownColConfig[] = useMemo(() => {
    let countingIndex = Object.keys(datasetConfig.drilldownColumnConfigs || {})?.length;

    return datasetSchema.map((col) => {
      if (datasetConfig.drilldownColumnConfigs?.[col.name]) {
        return {
          name: col.name,
          type: col.type,
          ...datasetConfig.drilldownColumnConfigs[col.name],
        };
      } else {
        const initExtendedConfig = {
          name: col.name,
          type: col.type,
          ...initConfig(countingIndex, titleCase(col.name)),
        };
        countingIndex += 1;
        return initExtendedConfig;
      }
    });
  }, [datasetConfig, datasetSchema]);

  const sortableColumns = useMemo(
    () => sortBy(schemaMappedWithConfigs, 'index'),
    [schemaMappedWithConfigs],
  );

  const isSearching = searchQuery.trim() !== '';
  const filteredSortableColumns = useMemo(() => {
    if (!isSearching) return sortableColumns;

    const loweredSearch = searchQuery.toLowerCase();
    return sortableColumns.reduce((acc, col) => {
      if (col.name.toLowerCase().includes(loweredSearch)) {
        acc.push(col);
      }
      return acc;
    }, [] as ExtendedDrilldownColConfig[]);
  }, [searchQuery, sortableColumns, isSearching]);

  const numColsIncluded = useMemo(
    () => filteredSortableColumns.reduce((sum, c) => (c.isIncluded ? sum + 1 : sum), 0),
    [filteredSortableColumns],
  );
  const shouldSelectAll = numColsIncluded !== filteredSortableColumns.length;

  const updateColConfig = useCallback(
    (colName, newConfigs: DrilldownColConfigOptions) => {
      dispatch(
        updateDatasetDrilldownColConfig({ datasetId: datasetConfig.id, colName, ...newConfigs }),
      );
    },
    [datasetConfig, dispatch],
  );

  const updateIsIncluded = (col: ExtendedDrilldownColConfig) => {
    updateColConfig(col.name, {
      isIncluded: !col.isIncluded,
      isVisible: !col.isIncluded,
    });
  };

  const updateIsVisible = (col: ExtendedDrilldownColConfig) => {
    updateColConfig(col.name, { isVisible: !col.isVisible });
  };

  const selectAll = useCallback(
    (isSelected: boolean) => {
      filteredSortableColumns.forEach((col) =>
        updateColConfig(col.name, {
          isIncluded: isSelected,
          isVisible: isSelected,
        }),
      );
    },
    [filteredSortableColumns, updateColConfig],
  );

  return (
    <div
      className={styles.drilldownDatasetColumnsRoot}
      onClick={(e) => {
        dispatch(setSelectedDrilldownColumn());
        e.stopPropagation();
      }}>
      <InfoCard
        noTopMargin
        className={styles.infoCardClass}
        text="This sets the dataset level formatting settings for the underlying data modal."
      />
      <Select
        className={sprinkles({ marginBottom: 'sp2' })}
        infoText="Automatically sizes columns."
        label="Column Fit"
        onChange={(value) =>
          dispatch(
            updateDatasetDrilldownConfig({
              id: datasetConfig.id,
              updates: { columnFit: value as COLUMN_FITS },
            }),
          )
        }
        selectedValue={datasetConfig.drilldownConfig?.columnFit ?? COLUMN_FITS.FILL}
        values={ColumnFitOptions}
      />
      <div className={sprinkles({ heading: 'h4', marginLeft: 'sp1' })}>Dataset Columns</div>
      <Input
        className={styles.drilldownDatasetColumnsSearchBar}
        leftIcon="search"
        onChange={setSearchQuery}
        placeholder="Search columns"
        value={searchQuery}
      />
      {isSearching && filteredSortableColumns.length === 0 ? (
        <div className={sprinkles({ flexItems: 'centerColumn', height: 'fill', gap: 'sp1' })}>
          <div className={sprinkles({ body: 'b2', color: 'contentSecondary' })}>No results</div>
        </div>
      ) : (
        <>
          <div
            className={sprinkles({ flexItems: 'alignCenter', marginLeft: 'sp4' })}
            style={{ minHeight: 52 }}>
            <div className={sprinkles({ flexItems: 'alignCenter' })}>
              <Checkbox
                isChecked={!shouldSelectAll}
                isIndeterminate={!shouldSelectAll}
                onChange={() => selectAll(shouldSelectAll)}
              />
              <div className={styles.columnsSelectTextClass}>COLUMNS</div>
            </div>
          </div>
          <div className={sprinkles({ flexItems: 'column', overflowY: 'auto', flex: 1 })}>
            <SortableList
              getIdFromElem={(col) => col.name}
              onListUpdated={(newCols) => {
                const updatedIndicesConfig = newCols.map((col, index) => {
                  return { ...col, index };
                });
                const newConfigs = extendedDrilldownConfigToNormal(updatedIndicesConfig);

                dispatch(
                  updateDatasetDrilldownColConfigs({ datasetId: datasetConfig.id, newConfigs }),
                );
              }}
              sortableItems={sortableColumns}>
              {filteredSortableColumns.map((col) => (
                <SortableListItem key={col.name} sortId={col.name}>
                  <SortableColumn
                    col={col}
                    key={col.name}
                    updateIsIncluded={() => updateIsIncluded(col)}
                    updateIsVisible={() => updateIsVisible(col)}
                  />
                </SortableListItem>
              ))}
            </SortableList>
          </div>
        </>
      )}
    </div>
  );
};

type SortableColumnProps = {
  col: ExtendedDrilldownColConfig;
  updateIsIncluded: () => void;
  updateIsVisible: () => void;
};

const SortableColumn: FC<SortableColumnProps> = ({ col, updateIsIncluded, updateIsVisible }) => {
  const dispatch = useDispatch();

  const selectedDrilldownColumn = useSelector(
    (state: ReduxState) => state.cssLinks.selectedDrilldownColumn,
  );

  const setSelectedColumn = (selectColumn: boolean) =>
    dispatch(
      setSelectedDrilldownColumn(selectColumn ? { name: col.name, type: col.type } : undefined),
    );

  return (
    <div
      className={cx(
        styles.baseColumn,
        { [styles.hoverColumn]: selectedDrilldownColumn?.name !== col.name },
        { [styles.selectedColumn]: selectedDrilldownColumn?.name === col.name },
      )}>
      <div
        className={sprinkles({ flexItems: 'alignCenter' })}
        onClick={(e) => {
          if (col.isIncluded) setSelectedColumn(true);
          e.stopPropagation();
        }}>
        <SortableListItemDragHandle
          className={cx(
            sprinkles({ color: 'contentTertiary', marginRight: 'sp1' }),
            styles.dragHandle,
          )}
        />
        <Tooltip text={col.isIncluded ? 'Visible to customer' : 'Not visible to customer'}>
          <span onClick={(e) => e.stopPropagation()}>
            <Checkbox
              isChecked={col.isIncluded}
              onChange={() => {
                updateIsIncluded();
                setSelectedColumn(!col.isIncluded);
              }}
            />
          </span>
        </Tooltip>
        <div
          className={cx(
            styles.colNameClass,
            sprinkles({ color: col.isIncluded ? undefined : 'contentTertiary' }),
          )}>
          {col.name}
        </div>
        <Tooltip
          portalContainerId={APP_PORTAL_ID}
          text={col.isVisible ? 'Start visible' : 'Do not start visible'}>
          <span className={sprinkles({ marginLeft: 'auto' })}>
            <IconButton
              disabled={!col.isIncluded}
              name={col.isVisible ? 'eye-open' : 'eye-closed'}
              onClick={(e) => {
                if (col.isIncluded) {
                  if (!col.isVisible) setSelectedColumn(true);
                  updateIsVisible();
                }
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

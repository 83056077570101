import { useMemo, useState, FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { maxBy, orderBy } from 'utils/standard';
import * as RD from 'remotedata';

import { ViewType } from 'components/PageHeader';
import { EmptyPageActionCallout } from 'components/EmptyPageActionCallout';
import { DisabledDashboardMenu } from 'pages/homeAppPage/DisabledDashboardMenu';
import { IconButton, Spinner, sprinkles } from 'components/ds';
import { ResourceConfigurationMenu } from './ResourceConfigurationMenu';
import * as styles from './styles.css';
import { ResourceItem } from './ResourceItem';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { Dashboard } from 'actions/dashboardActions';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { isCreateResourceDisabled } from 'utils/paymentPlanUtils';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import {
  canUserViewResourceConfigurationMenu,
  doesUserHavePermission,
} from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { Folder } from 'reducers/thunks/resourceThunks';
import { FolderItem } from './FolderItem';
import { FolderConfigurationMenu } from './FolderConfigurationMenu';
import { ROUTES } from 'constants/routes';
import { getResourceText } from 'utils/exploResourceUtils';
import { getResources } from 'reducers/folderReducer';

export type Resource = Dashboard | ReportBuilder;

type Props = {
  viewType?: ViewType;
  pageType: ResourcePageType;

  dashboardAttributes?: DashboardAttribute[];
  emailCadenceList?: ExploreEmailCadence[];
};

export const ResourceListPage: FC<Props> = ({
  viewType,
  pageType,
  dashboardAttributes,
  emailCadenceList,
}): JSX.Element => {
  const history = useHistory();
  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const { currentUser, teamPaymentPlan, folders, isLoading, isError, resources } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      teamPaymentPlan: state.currentUser.team?.payment_plan,
      folders: state.folder.folders,
      currentFolder: state.folder.breadcrumbs[state.folder.breadcrumbs.length - 1],
      isLoading: RD.isLoading(state.folder.resourcesStatus),
      isError: RD.isError(state.folder.resourcesStatus),
      resources: getResources(state, isExploreProduct),
      createResourceLoading: createLoadingSelector(
        [ACTION.CREATE_DASHBOARD, ACTION.CLONE_DASHBOARD],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const [resourceUpdating, setResourceUpdating] = useState<number | undefined>();
  const [folderUpdating, setFolderUpdating] = useState<number | undefined>();

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const userPermissions = currentUser.permissions[resourcePermissionEntity];

  const userCanCreateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const userCanConfigureResource = canUserViewResourceConfigurationMenu(
    currentUser,
    resourcePermissionEntity,
  );

  const rootPath = isExploreProduct ? ROUTES.HOME_APP_PAGE_TEST : ROUTES.REPORT_BUILDER_TEST;

  const filteredResources = useMemo(() => {
    if (!resources) return [];

    const sortedResources = orderBy(
      resources,
      ({ latest_versions }) => {
        const lastModified = maxBy(latest_versions, (info) => info.version_number)?.modified;
        return lastModified ? getTimezoneAwareUnix(lastModified) : 0;
      },
      'desc',
    );

    return sortedResources;
  }, [resources]);

  if (isError) {
    return (
      <div className={styles.errorLoadingResources}>
        Error Loading {getResourceText(pageType, { plural: true, capitalized: true })}
      </div>
    );
  }

  const createResourceDisabled = isCreateResourceDisabled(
    isExploreProduct,
    resources.length,
    teamPaymentPlan,
  );

  const renderDotsMenu = (
    resource: Resource,
    emailCadence: ExploreEmailCadence | undefined,
    isCard: boolean,
    isDisabled: boolean,
  ) => {
    if (!userCanConfigureResource) return null;

    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (resource.id === resourceUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    if (isDisabled) {
      // Only Dashboard can be disabled
      return (
        <div className={containerStyle} onClick={(e) => e.stopPropagation()}>
          <DisabledDashboardMenu
            dashboard={resource as Dashboard}
            dashboardList={resources as Dashboard[]}
            setLoadingStateForDashboard={(isLoading) =>
              setResourceUpdating(isLoading ? resource.id : undefined)
            }
          />
        </div>
      );
    }

    return (
      <div className={containerStyle} onClick={(e) => e.stopPropagation()}>
        <ResourceConfigurationMenu
          createResourceDisabled={createResourceDisabled}
          dashboardAttributes={dashboardAttributes}
          emailCadence={emailCadence}
          pageType={pageType}
          resource={resource}
          resourcePermissionEntity={resourcePermissionEntity}
          setLoadingStateForResource={(isLoading) =>
            setResourceUpdating(isLoading ? resource.id : undefined)
          }
          trigger={<IconButton className={iconStyle} name="ellipsis-vertical" />}
        />
      </div>
    );
  };

  const renderFolderDotsMenu = (folder: Folder, isCard: boolean) => {
    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (folder.id === folderUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    return (
      <div className={containerStyle} onClick={(e) => e.preventDefault()}>
        <FolderConfigurationMenu
          folder={folder}
          pageType={pageType}
          setLoadingStateForFolder={(isLoading) =>
            setFolderUpdating(isLoading ? folder.id : undefined)
          }
          trigger={<IconButton className={iconStyle} name="ellipsis-vertical" />}
        />
      </div>
    );
  };

  const viewResource = (resource: Resource) => {
    const emailCadence = isExploreProduct
      ? emailCadenceList?.find((cadence) => cadence.dashboard_template_id === resource.id)
      : undefined;

    const isDisabled = 'disabled' in resource ? resource.disabled ?? false : false;

    return (
      <ResourceItem
        dotsMenu={renderDotsMenu(resource, emailCadence, viewType === ViewType.Card, isDisabled)}
        hasEmailState={emailCadence !== undefined}
        isCard={viewType === ViewType.Card}
        isLoading={isLoading}
        key={resource.id}
        onClickUrl={
          isExploreProduct ? `/dashboard/${resource.id}` : `/report-builder/${resource.id}/datasets`
        }
        resource={resource}
        showPreview={pageType !== ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  const renderList = () => {
    if (resources.length + folders.length === 0 && !isLoading && userCanCreateResource) {
      return (
        <EmptyPageActionCallout
          text={`Get started by creating your first ${getResourceText(pageType)}`}
        />
      );
    }

    return (
      <div className={viewType === ViewType.Card ? sprinkles({ flex: 1 }) : undefined}>
        <div
          className={viewType === ViewType.Card ? styles.cardsGrid : styles.resourceListContainer}>
          {folders.map((folder) => (
            <FolderItem
              dotsMenu={renderFolderDotsMenu(folder, viewType === ViewType.Card)}
              folder={folder}
              isCard={viewType === ViewType.Card}
              key={folder.id}
              openFolder={() => !isLoading && history.push(`${rootPath}/${folder.id}`)}
            />
          ))}
          {filteredResources.map(viewResource)}
        </div>
      </div>
    );
  };

  return <div className={sprinkles({ flex: 1 })}>{renderList()}</div>;
};

import { ACTION } from 'actions/types';
import {
  FetchCustomerReportDataJob,
  FetchCustomerReportDataJobSuccess,
  FetchCustomerReportRowCountJobSuccess,
  FetchCustomerReportDataJobRequest,
  FetchCustomerReportRowCountJobRequest,
  ReportBuilderJobError,
  FetchCustomerReportRowCountJob,
} from 'components/JobQueue/types';
import { createErrorAction, createRequestAction, createSuccessAction } from 'actions/actionUtils';

export const fetchEmbeddedReportDataRequest =
  createRequestAction<FetchCustomerReportDataJobRequest>(ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB);

export const fetchEmbeddedReportDataSuccess =
  createSuccessAction<FetchCustomerReportDataJobSuccess>(ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB);

export const fetchEmbeddedReportDataError = createErrorAction<
  ReportBuilderJobError<FetchCustomerReportDataJob>
>(ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB);

export const fetchEmbeddedRowCountRequest =
  createRequestAction<FetchCustomerReportRowCountJobRequest>(
    ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB,
  );

export const fetchEmbeddedRowCountSuccess =
  createSuccessAction<FetchCustomerReportRowCountJobSuccess>(
    ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB,
  );

export const fetchEmbeddedRowCountError = createErrorAction<
  ReportBuilderJobError<FetchCustomerReportRowCountJob>
>(ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB);

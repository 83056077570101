import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Select, Tooltip, sprinkles, Label, Input } from 'components/ds';
import { SettingHeader } from 'components/SettingHeader';
import { showSuccessToast } from 'shared/sharedToasts';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';

import { VisualizeGeospatialChartInstructions } from 'constants/types';
import { saveMapViewState } from 'utils/customEventUtils';
import {
  MAP_STYLES_SELECT_VALUES,
  MARKER_COLOR,
  MARKER_SIZE,
  MARKER_OUTLINE_COLOR,
  MARKER_OUTLINE_WIDTH,
} from 'constants/maps';
import { updateMapFormat } from 'reducers/thunks/mapThunks';

const MARKER_SIZE_MIN = 10;
const MARKER_SIZE_MAX = 50;
const MARKER_OUTLINE_WIDTH_MIN = 0;
const MARKER_OUTLINE_WIDTH_MAX = 30;

type Props = {
  instructions: VisualizeGeospatialChartInstructions;
  dataPanelId: string;
};

export const LocationMarkerMapConfig: FC<Props> = ({ instructions, dataPanelId }) => {
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader name="Map Options" />

      <div className={containerStyle}>
        <Select
          label="Map Style"
          onChange={(value) => dispatch(updateMapFormat(dataPanelId, { style: value }))}
          selectedValue={instructions.mapFormat?.style}
          values={MAP_STYLES_SELECT_VALUES}
        />
        <Tooltip text="This will save the current map view as the starting point.">
          <Button
            fillWidth
            onClick={() => {
              saveMapViewState();
              showSuccessToast('Saved Map View');
            }}>
            Save Initial View
          </Button>
        </Tooltip>
      </div>

      <SettingHeader name="Marker" />
      <div className={containerStyle}>
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label htmlFor="">Fill</Label>
          <CustomStylesColorPicker
            fill
            color={instructions.mapFormat?.markerColor ?? MARKER_COLOR}
            onClose={(newColor) => {
              dispatch(updateMapFormat(dataPanelId, { markerColor: newColor }));
            }}
          />
        </div>
        <Input
          defaultValue={(instructions.mapFormat?.markerSize ?? MARKER_SIZE).toString()}
          label={{ text: 'Size', infoText: 'Marker size must be between 10 and 50 pixels' }}
          onSubmit={(value) => {
            const parsedVal = parseInt(value);
            const isOutOfRange = parsedVal > MARKER_SIZE_MAX || parsedVal < MARKER_SIZE_MIN;
            const newMarkerSize = isNaN(parsedVal) || isOutOfRange ? undefined : parsedVal;
            dispatch(updateMapFormat(dataPanelId, { markerSize: newMarkerSize }));
          }}
        />
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label htmlFor="">Outline Color</Label>
          <CustomStylesColorPicker
            fill
            color={instructions.mapFormat?.markerOutlineColor ?? MARKER_OUTLINE_COLOR}
            onClose={(newColor) => {
              dispatch(updateMapFormat(dataPanelId, { markerOutlineColor: newColor }));
            }}
          />
        </div>
        <Input
          defaultValue={(
            instructions.mapFormat?.markerOutlineWidth ?? MARKER_OUTLINE_WIDTH
          ).toString()}
          label={{
            text: 'Outline',
            infoText: 'Outline width must be between 0 and 20 pixels',
          }}
          onSubmit={(value) => {
            const parsedVal = parseInt(value);
            const isOutOfRange =
              parsedVal > MARKER_OUTLINE_WIDTH_MAX || parsedVal < MARKER_OUTLINE_WIDTH_MIN;
            const newMarkerOutlineWidth = isNaN(parsedVal) || isOutOfRange ? undefined : parsedVal;
            dispatch(updateMapFormat(dataPanelId, { markerOutlineWidth: newMarkerOutlineWidth }));
          }}
        />
      </div>
    </>
  );
};

const containerStyle = sprinkles({ padding: 'sp1.5', flexItems: 'column', gap: 'sp1.5' });

import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { ColorPicker } from 'components/ColorPicker';
import { mixColors } from 'utils/general';
import * as styles from './index.css';

type Props = {
  // A list of either 2 or 3 hues
  hues: string[];
  // A list of either 2 or 3 callbacks fired on hue change
  onHuesChanged: ((newHue: string) => void)[];
};

export const GradientColorPicker: FC<Props> = ({ hues, onHuesChanged }) => {
  const color1 = hues[0];
  const color2 = hues[1];

  if (hues.length === 2) {
    return (
      <div className={sprinkles({ paddingTop: 'sp3' })}>
        <ColorPicker
          fill
          buttonClassName={cx(styles.colorPickerButton, styles.linearTopColorPicker)}
          className={styles.colorPicker}
          color={hues[0]}
          label=""
          onClose={onHuesChanged[0]}
        />
        <div
          className={styles.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.66).rgb().toString() }}
        />
        <div
          className={styles.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.33).rgb().toString() }}
        />
        <ColorPicker
          fill
          buttonClassName={cx(styles.colorPickerButton, styles.linearBottomColorPicker)}
          className={styles.colorPicker}
          color={hues[1]}
          label=""
          onClose={onHuesChanged[1]}
        />
      </div>
    );
  }

  const color3 = hues[2];

  return (
    <div className={sprinkles({ paddingTop: 'sp2' })}>
      <ColorPicker
        fill
        buttonClassName={cx(styles.colorPickerButton, styles.linearTopColorPicker)}
        className={styles.colorPicker}
        color={hues[0]}
        label=""
        onClose={onHuesChanged[0]}
      />
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.66).rgb().toString() }}
      />
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.33).rgb().toString() }}
      />
      <ColorPicker
        fill
        buttonClassName={cx(styles.colorPickerButton, styles.linearCenterColorPicker)}
        className={styles.colorPicker}
        color={hues[1]}
        label=""
        onClose={onHuesChanged[1]}
      />
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.66).rgb().toString() }}
      />
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.33).rgb().toString() }}
      />
      <ColorPicker
        fill
        buttonClassName={cx(styles.colorPickerButton, styles.linearBottomColorPicker)}
        className={styles.colorPicker}
        color={hues[2]}
        label=""
        onClose={onHuesChanged[2]}
      />
    </div>
  );
};

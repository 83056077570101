import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';
import { ParentSchema, TableDataset } from 'actions/dataSourceActions';

type FetchDatasetsData = {
  datasets: TableDataset[];
  schema_id: number;
};

export const {
  actionFn: fetchDatasets,
  requestAction: fetchDatasetsRequest,
  successAction: fetchDatasetsSuccess,
  errorAction: fetchDatasetsError,
} = defineAPIAction<FetchDatasetsData>(ACTION.FETCH_DATASETS, 'schema', 'tables', 'GET');

type FetchParentSchemasData = { parent_schemas: ParentSchema[] };

export const { actionFn: fetchUsedParentSchemas, ...fetchUsedParentSchemasActions } =
  defineAPIAction<FetchParentSchemasData>(
    ACTION.FETCH_USED_PARENT_SCHEMAS,
    'schema',
    'get_used_schemas',
    'GET',
  );

export type FetchSchemaTablesMappingData = {
  schema_tables: { [schemaId: number]: TableDataset[] };
};

export const { actionFn: fetchAllSchemaTables, ...fetchAllSchemaTablesActions } =
  defineAPIAction<FetchSchemaTablesMappingData>(
    ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM,
    'schema',
    'all_tables',
    'GET',
  );

import { FC } from 'react';
import produce from 'immer';

import { Select } from 'components/ds';

import { TimePeriodDropdownElemConfig } from 'types/dashboardTypes';

type Props = {
  config: TimePeriodDropdownElemConfig;

  updateDropdownConfig: (config: TimePeriodDropdownElemConfig) => void;
};

export const TimePeriodDefaultValuesConfig: FC<Props> = ({ config, updateDropdownConfig }) => {
  const options = config.values.map((option) => ({
    value: String(option.value),
    label: option.name,
  }));

  return (
    <Select
      label="Default value"
      onChange={(value) => {
        const newConfig = produce(config, (draft) => {
          draft.defaultValue = Number(value);
        });
        updateDropdownConfig(newConfig);
      }}
      placeholder="Select default option"
      selectedValue={config.defaultValue?.toString()}
      values={options}
    />
  );
};

import { FC } from 'react';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { NO_HEADER_OPERATION_TYPES } from 'constants/types';
import {
  ACCESS_GROUP_NO_DEFAULT_DATASOURCES_WARNING_WITH_LINK,
  IS_ACCESS_GROUP_ERROR,
  parseErrorMessage,
} from 'utils/queryUtils';
import { DataPanel } from 'types/exploResource';
import { HEADER_HEIGHT } from './DashboardDatasetView';
import { PanelError } from './PanelError';

type Props = {
  dataPanel: DataPanel;
  error: string;
  loading: boolean;
};

export const DashboardChartError: FC<Props> = ({ loading, dataPanel, error }) => {
  const errMsg =
    typeof error === 'string' && IS_ACCESS_GROUP_ERROR(error)
      ? ACCESS_GROUP_NO_DEFAULT_DATASOURCES_WARNING_WITH_LINK(error)
      : 'There was an error fetching the results';

  const height = NO_HEADER_OPERATION_TYPES.has(dataPanel.visualize_op.operation_type)
    ? '100%'
    : `calc(100% - ${HEADER_HEIGHT}px)`;

  return (
    <div
      className={cx(
        sprinkles({ padding: 'sp1' }),
        'data-panel-error',
        embedSprinkles({ backgroundColor: 'containerFill', color: 'primaryFont' }),
      )}
      id={dataPanel.id}
      style={{ height }}>
      <PanelError
        action={
          <EmbedButton
            icon="arrow-up-right-from-square"
            onClick={() => alert(error)}
            variant="primary">
            View full error
          </EmbedButton>
        }
        description={parseErrorMessage(error)}
        loading={loading}
        title={errMsg}
      />
    </div>
  );
};

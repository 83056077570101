import { forwardRef } from 'react';
import cx from 'classnames';

import { Input, Props } from 'components/ds/Input';
import * as styles from './index.css';

export type EmbedInputProps = Props;

export const EmbedInput = forwardRef<HTMLInputElement, Props>(({ className, ...props }, ref) => {
  return <Input className={cx(styles.input, className)} ref={ref} {...props} />;
});

EmbedInput.displayName = 'EmbedInput';

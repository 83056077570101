import { FC, useContext } from 'react';
import cx from 'classnames';

import { Icon, sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type Props = {
  loading?: boolean;
  className?: string;
  title: JSX.Element | string;
  description?: JSX.Element | string;
  action?: JSX.Element;
};

export const PanelError: FC<Props> = ({ loading, className, title, description, action }) => {
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  return (
    <div className={cx(embedSprinkles({ backgroundColor: 'containerFill' }), rootClass, className)}>
      {loading ? (
        <EmbedSpinner size="lg" />
      ) : (
        <Icon
          className={embedSprinkles({ color: 'panelErrorIcon' })}
          name="circle-exclamation"
          size={globalStyleConfig.components?.panelError?.icon?.size || 'lg'}
        />
      )}
      <div className={embedSprinkles({ otherText: 'panelErrorMessage' })}>{title}</div>
      {description ? (
        <div className={embedSprinkles({ otherText: 'panelErrorDescription' })}>{description}</div>
      ) : null}
      {action || null}
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'centerColumn',
  gap: 'sp1',
  padding: 'sp2',
  height: 'fill',
  overflow: 'auto',
  textAlign: 'center',
});

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  saveGlobalStylesSuccess,
  fetchGoogleFontsSuccess,
  fetchGoogleFontsRequest,
  fetchGoogleFontsError,
  saveAdditionalStyleSuccess,
  deleteAdditionalStyleSuccess,
} from 'actions/styleConfigActions';
import { GlobalStyleConfig } from 'globalStyles/types';

export const EXPLO_DASHBOARD_STORAGE_KEY = 'explo_dashboard';

export enum ComponentSection {
  Spinner = 'Spinner',
  Button = 'Button',
  PanelError = 'PanelError',
  Modal = 'Modal',
}

type StyleConfigReducerState = {
  editingThemeName: string | null;
  editingConfig: GlobalStyleConfig | null;
  googleFonts: RD.ResponseData<string[]>;
  selectedDashboardId: number | null;
  openComponentSection: ComponentSection | null;
};

const initialState: StyleConfigReducerState = {
  editingThemeName: null,
  editingConfig: null,
  selectedDashboardId: null,
  googleFonts: RD.Idle(),
  openComponentSection: ComponentSection.Spinner,
};

const styleConfigSlice = createSlice({
  name: 'styleConfig',
  initialState,
  reducers: {
    resetStylingConfigReducer: (state) => {
      state.editingConfig = null;
      state.editingThemeName = null;
    },
    setEditingConfig: (state, { payload }: PayloadAction<GlobalStyleConfig | null>) => {
      state.editingConfig = payload;
    },
    selectDashboardToPreviewStyleChanges: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedDashboardId = payload;

      if (payload) localStorage.setItem(EXPLO_DASHBOARD_STORAGE_KEY, String(payload));
      else localStorage.removeItem(EXPLO_DASHBOARD_STORAGE_KEY);
    },
    setEditingTheme: (state, { payload }: PayloadAction<string | null>) => {
      state.editingThemeName = payload;
    },
    createNewTheme: (
      state,
      { payload }: PayloadAction<{ name: string; config: GlobalStyleConfig }>,
    ) => {
      state.editingThemeName = payload.name;
      state.editingConfig = payload.config;
    },
    setOpenComponentSection: (state, { payload }: PayloadAction<ComponentSection | null>) => {
      state.openComponentSection = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveGlobalStylesSuccess, (state) => {
        state.editingConfig = null;
        state.editingThemeName = null;
      })
      .addCase(saveAdditionalStyleSuccess, (state) => {
        state.editingConfig = null;
      })
      .addCase(deleteAdditionalStyleSuccess, (state) => {
        state.editingConfig = null;
        state.editingThemeName = null;
      })
      .addCase(fetchGoogleFontsRequest, (state) => {
        state.googleFonts = RD.Loading();
      })
      .addCase(fetchGoogleFontsError, (state) => {
        state.googleFonts = RD.Error('Error loading google fonts');
      })
      .addCase(fetchGoogleFontsSuccess, (state, { payload }) => {
        state.googleFonts = RD.Success(payload.items);
      });
  },
});

export const styleConfigReducer = styleConfigSlice.reducer;
export const {
  resetStylingConfigReducer,
  setEditingConfig,
  selectDashboardToPreviewStyleChanges,
  createNewTheme,
  setEditingTheme,
  setOpenComponentSection,
} = styleConfigSlice.actions;

import { PureComponent } from 'react';
import cx from 'classnames';
import { isEqual, keyBy } from 'utils/standard';

import { sprinkles } from 'components/ds';
import { EmbedInfoIcon, EmbedSpinner } from 'components/embed';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { HighCharts } from './highCharts';
import TrendPctChange from './shared/trendPctChange';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { ChartMenu } from 'components/ChartMenu';
import NumberTrendTextPanel from '../../../shared/charts/numberTrendTextPanel';
import { NoDataPanel } from 'components/ChartLayout/NoDataPanel';

import {
  TrendGroupToggleOption,
  V2KPITrendInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DatasetSchema } from 'types/datasets';
import { formatValue, getAxisNumericalValue } from './utils';
import { getCategoricalColors, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { DateTime } from 'luxon';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import {
  areRequiredVariablesSet,
  formatDateRange,
  getComparisonDates,
  getPctChange,
  getPeriodDates,
  isKpiTrendReadyToDisplay,
} from './utils/trendUtils';
import { SeriesLineOptions, TooltipFormatterContextObject } from 'highcharts';
import { format } from 'utils/localizationUtils';

import * as styles from './numberTrend.css';
import { sharedTitleConfig } from './utils/sharedConfigs';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type LineOptions = Omit<SeriesLineOptions, 'data'> & {
  data: [DateTime, number][];
};

type ChartData = LineOptions[];

const PERIOD_INDEX = 0;
const COMPARISON_INDEX = 1;
const DATE_INDEX = 0;

type TotalAggregatedValues = {
  periodRange: number;
  comparisonRange: number;
};

type Props = {
  loading?: boolean;
  newDataLoading?: boolean;
  previewData: Record<string, string | number>[];
  aggValuesLoading?: boolean;
  aggregatedValues?: TotalAggregatedValues;
  instructions?: V2KPITrendInstructions;
  dataPanelTemplateId: string;
  editableDashboard?: boolean;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  infoTooltipText?: string;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  hideIcons?: boolean;
  processString: (s: string) => string;
};

type State = {
  hoveredIndex?: number;
  data?: ChartData;
};

export class NumberTrend extends PureComponent<Props, State> {
  state: State = {};

  constructor(props: Props) {
    super(props);

    if (
      !props.loading &&
      isKpiTrendReadyToDisplay(props.instructions) &&
      props.schema?.length >= 2
    ) {
      this.state = {
        data: this.processTrendData(),
      };
    }
  }

  getBuiltInVarsMap = () => {
    const { data } = this.state;
    if (!data) return {};
    return {
      current_period: this.getPeriodDateRange(data),
      comparison_period: this.getComparisonDateRange(data),
    };
  };

  componentDidUpdate(prevProps: Props) {
    // Don't check props.loading as it's improperly set right now
    // (it will be set to true despite the previewData being already fetched)
    // and the previewData being different should be enough info to confidently
    // re-process the trend data
    if (
      isKpiTrendReadyToDisplay(this.props.instructions) &&
      this.props.schema?.length >= 2 &&
      (!isEqual(prevProps.previewData, this.props.previewData) ||
        !isEqual(prevProps.instructions, this.props.instructions))
    ) {
      this.setState({
        data: this.processTrendData(),
      });
    }
  }

  render() {
    const {
      instructions,
      previewData,
      loading,
      variables,
      aggValuesLoading,
      aggregatedValues,
      editableDashboard,
    } = this.props;

    const requiredVarNotsSet = !areRequiredVariablesSet(variables, instructions);

    // If the dashboard is editable, then we want to reload the entire chart when changes
    // happen. Either way, if there are no agg values to show, we should be loading the
    // entire chart since this.renderNumberTrendTextPanel() will crash if no values are present
    const isDataLoading = instructions?.hideTrendLines
      ? aggValuesLoading && (editableDashboard || !aggregatedValues)
      : loading;
    const instructionsReady = isKpiTrendReadyToDisplay(instructions);

    if (isDataLoading || !instructionsReady || requiredVarNotsSet) {
      return this.renderLoadingState(requiredVarNotsSet, instructionsReady, loading);
    }

    if (instructions?.hideTrendLines) {
      return this.renderNumberTrendTextPanel();
    }

    if (!previewData || previewData.length === 0) {
      return this.renderNoDataBody();
    }

    return this.renderNumberTrend();
  }

  renderLoadingState = (
    requiredVarNotsSet: boolean,
    instructionsReady: boolean,
    loading?: boolean,
  ) => {
    const { instructions, generalOptions, processString } = this.props;
    const showHeader = !generalOptions?.headerConfig?.isHeaderHidden;
    const title = this.getTitle();
    const configPanel = (className: string) => (
      <NeedsConfigurationPanel
        className={className}
        instructionsNeedConfiguration={!instructionsReady}
        loading={loading}
        requiredVarsNotSet={requiredVarNotsSet}
      />
    );
    if (instructions?.hideTrendLines) {
      return (
        <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
          {showHeader && (
            <div
              className={cx(
                styles.noTrendLineLoadingTitle,
                embedSprinkles({ otherText: 'kpiTitle' }),
              )}
              style={{
                fontSize: instructions.titleFormat?.fontSize,
              }}>
              {processString(title || '')}
            </div>
          )}
          {configPanel(styles.noTrendLineLoadingState)}
        </div>
      );
    }
    return (
      <div className={sprinkles({ parentContainer: 'fill' })}>
        {showHeader && (
          <div className={styles.titleContainer}>
            <div className={cx(styles.chartTitle, embedSprinkles({ otherText: 'kpiTitle' }))}>
              {processString(title || '')}
            </div>
          </div>
        )}
        {configPanel(styles.trendLineLoadingState)}
      </div>
    );
  };

  renderNoDataBody = () => {
    const { generalOptions, processString } = this.props;
    return (
      <div
        className={cx(
          sprinkles({ height: 'fill' }),
          embedSprinkles({ body: 'primaryWithoutColor' }),
        )}>
        {!generalOptions?.headerConfig?.isHeaderHidden && (
          <div className={styles.titleContainer}>
            <div
              className={cx(
                styles.chartTitle,
                sprinkles({ maxWidth: 'fill' }),
                embedSprinkles({ otherText: 'kpiTitle' }),
              )}>
              {processString(this.getTitle())}
            </div>
          </div>
        )}
        <NoDataPanel noDataState={generalOptions?.noDataState} />
      </div>
    );
  };

  renderNumberTrendTextPanel = () => {
    const {
      globalStyleConfig,
      instructions,
      generalOptions,
      aggregatedValues,
      aggValuesLoading,
      infoTooltipText,
      processString,
    } = this.props;

    const usesComparison = this.getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
    const periodAggregatedValue = aggregatedValues?.periodRange || 0;
    const comparisonAggregatedValue =
      (usesComparison ? aggregatedValues?.comparisonRange : undefined) || 0;
    const trendChangeVal = instructions?.displayFormat?.showAbsoluteChange
      ? this.getNumericChange(periodAggregatedValue, comparisonAggregatedValue)
      : getPctChange(periodAggregatedValue, comparisonAggregatedValue);
    const trendChangeValFormat = instructions?.displayFormat?.showAbsoluteChange
      ? instructions?.valueFormat?.numberFormat ?? V2_NUMBER_FORMATS.NUMBER
      : V2_NUMBER_FORMATS.PERCENT;
    const subtitle = processString(instructions?.textOnlyFormat?.subtitle ?? '');

    const isZeroNoData = generalOptions?.noDataState?.isZeroNoData;

    const noDataCurrentPeriod =
      aggregatedValues === undefined ||
      isNaN(periodAggregatedValue) ||
      (isZeroNoData && periodAggregatedValue === 0);

    const noDataPreviousPeriod =
      aggregatedValues === undefined ||
      isNaN(comparisonAggregatedValue) ||
      (isZeroNoData && comparisonAggregatedValue === 0);

    return (
      <NumberTrendTextPanel
        aggValuesLoading={aggValuesLoading}
        currentPeriodValue={periodAggregatedValue}
        displayFormat={instructions?.displayFormat}
        generalOptions={generalOptions}
        globalStyleConfig={globalStyleConfig}
        headerActions={this.renderHeaderActions()}
        infoTooltipText={infoTooltipText}
        noData={!!noDataCurrentPeriod}
        noDataPrevPeriod={!!noDataPreviousPeriod}
        processString={processString}
        subtitle={subtitle}
        titleFormat={instructions?.titleFormat}
        trendChangeVal={usesComparison ? trendChangeVal : undefined}
        trendChangeValFormatId={trendChangeValFormat.id}
        trendChangeValLabel={this.getComparisonRange()}
        valueFormat={instructions?.valueFormat}
      />
    );
  };

  renderNumberTrend = () => {
    const { data } = this.state;

    if (!data) return <div />;

    return (
      <>
        {this.renderChartAggregatedValues(data)}
        <HighCharts chartOptions={this._spec(data)} className={sprinkles({ display: 'flex' })} />
        {this.renderChartDateRange(data)}
      </>
    );
  };

  renderChartDateRange = (data: ChartData) => {
    const { instructions, globalStyleConfig } = this.props;

    const periodData = data[PERIOD_INDEX].data;
    const trendGrouping = this.getTrendGrouping();
    const dateFormat = getDateFormat(trendGrouping);
    const start = periodData[0][DATE_INDEX];
    const startDate = start.toLocaleString(dateFormat);
    const end = periodData[periodData.length - 1][DATE_INDEX];
    const endDate = end.toLocaleString(dateFormat);

    const periodColor =
      instructions?.displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0];
    return (
      <div className={cx(styles.chartAxis, embedSprinkles({ body: 'primaryWithoutColor' }))}>
        <div className={styles.chartDateRange} style={{ color: periodColor }}>
          <span>{startDate}</span>
          <span>{endDate}</span>
        </div>
        {this.renderComparisonDateRange(data)}
      </div>
    );
  };

  renderComparisonDateRange = (data: ChartData) => {
    const { instructions } = this.props;

    // If set to "no comparison", compData will be an empty array
    const compData = data[data.length - 1].data;
    if (compData?.length !== 2) return null;

    const trendGrouping = this.getTrendGrouping();
    const dateFormat = getDateFormat(trendGrouping);

    const compStart = compData[0][DATE_INDEX];
    const compStartDate = getTimezoneAwareDate(compStart.toISO()).toLocaleString(dateFormat);
    const compEnd = compData[compData.length - 1][DATE_INDEX];
    const compEndDate = getTimezoneAwareDate(compEnd.toISO()).toLocaleString(dateFormat);

    const comparisonColor = instructions?.displayFormat?.comparisonColor || '#757575';
    return (
      <div className={styles.chartDateRange} style={{ color: comparisonColor }}>
        <span>{compStartDate}</span>
        <span>{compEndDate}</span>
      </div>
    );
  };

  renderHeader = () => {
    const { generalOptions, infoTooltipText, newDataLoading, processString, hideIcons } =
      this.props;

    const isHeaderHidden = generalOptions?.headerConfig?.isHeaderHidden;
    const linkFormat = generalOptions?.linkFormat;

    const shouldRenderClickThrough = linkFormat?.link && linkFormat?.url && !hideIcons;

    if (isHeaderHidden && !shouldRenderClickThrough && !generalOptions?.enableRawDataDrilldown)
      return;

    const title = this.getTitle();

    return (
      <div
        className={cx(
          sprinkles({
            display: 'flex',
            alignItems: 'center',
            justifyContent: !isHeaderHidden ? 'space-between' : 'flex-end',
          }),
        )}
        style={{ height: 32 }}>
        {!isHeaderHidden ? (
          <div className={styles.titleContainer}>
            {title && (
              <div className={cx(styles.chartTitle, embedSprinkles({ otherText: 'kpiTitle' }))}>
                {processString(title)}
              </div>
            )}
            {infoTooltipText ? <EmbedInfoIcon noMargin text={infoTooltipText} /> : null}

            {newDataLoading && (
              <EmbedSpinner className={sprinkles({ marginLeft: 'sp3' })} size="md" />
            )}
          </div>
        ) : null}
        {this.renderHeaderActions()}
      </div>
    );
  };

  renderHeaderActions = () => {
    const { dataPanelTemplateId, generalOptions, hideIcons } = this.props;

    const linkFormat = generalOptions?.linkFormat;

    if (hideIcons) return null;

    return (
      <>
        <UrlClickThroughButton linkFormat={linkFormat} />
        <ChartMenu
          dataPanelId={dataPanelTemplateId}
          enableDrilldownModal={generalOptions?.enableRawDataDrilldown}
        />
      </>
    );
  };

  renderChartAggregatedValues = (data: ChartData) => {
    const { instructions } = this.props;
    const useComparison = this.getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
    const periodAggregatedValue = this.getTimeRangeAggregatedValue(data, PERIOD_INDEX);
    const comparisonAggregatedValue = this.getTimeRangeAggregatedValue(data, COMPARISON_INDEX);
    const pctChange = useComparison
      ? getPctChange(periodAggregatedValue, comparisonAggregatedValue)
      : undefined;

    return (
      <div className={embedSprinkles({ body: 'primaryWithoutColor' })}>
        {this.renderHeader()}
        <div
          className={sprinkles({
            flexItems: 'alignCenterBetween',
            gap: 'sp1',
          })}>
          <div
            className={sprinkles({
              display: 'flex',
              alignItems: 'flex-end',
              gap: 'sp.5',
            })}>
            <div
              className={styles.periodAggValue}
              style={{
                color:
                  this.props.instructions?.displayFormat?.periodColor ||
                  this.props.globalStyleConfig.visualizations.categoricalPalette.hue1,
              }}>
              <div className={styles.aggValue}>
                {this.getFormattedAggValue(periodAggregatedValue)}
                <span
                  className={sprinkles({
                    marginLeft: instructions?.valueFormat?.unitPadding ? 'sp.5' : 'sp0',
                  })}>
                  {instructions?.valueFormat?.units}
                </span>
              </div>
            </div>
            {useComparison && (
              <div
                className={cx(styles.comparisonAggValue, embedSprinkles({ body: 'secondary' }))}
                style={{ color: this.props.instructions?.displayFormat?.comparisonColor }}>
                from {this.getFormattedAggValue(comparisonAggregatedValue)}
                <span
                  className={sprinkles({
                    marginLeft: instructions?.valueFormat?.unitPadding ? 'sp.25' : 'sp0',
                  })}>
                  {instructions?.valueFormat?.units}
                </span>
              </div>
            )}
          </div>
          {pctChange !== undefined && (
            <TrendPctChange instructions={instructions} pctChange={pctChange} />
          )}
        </div>
      </div>
    );
  };

  getFormattedAggValue = (value?: number) => {
    const { instructions } = this.props;

    if (!value || isNaN(value)) return '-';

    const decimalPlaces = instructions?.valueFormat?.decimalPlaces ?? 2;
    const significantDigits = instructions?.valueFormat?.significantDigits ?? 3;
    const formatId = instructions?.valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id;

    return formatValue({
      value: value,
      decimalPlaces,
      significantDigits,
      formatId,
      hasCommas: true,
      timeFormatId: instructions?.valueFormat?.timeFormat?.id,
      customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
    });
  };

  getNumericChange = (base: number, comparison: number) => {
    return base - comparison;
  };

  getTimeRangeAggregatedValue = (data: ChartData, timeRangeIndex: number): number => {
    const { aggregatedValues } = this.props;
    return timeRangeIndex === PERIOD_INDEX
      ? getAxisNumericalValue(aggregatedValues?.periodRange ?? '')
      : getAxisNumericalValue(aggregatedValues?.comparisonRange ?? '');
  };

  getPeriodDateRange = (data: ChartData) => {
    return this.getDateRange(data[PERIOD_INDEX].data);
  };

  getComparisonDateRange = (data: ChartData) => {
    return this.getDateRange(data[COMPARISON_INDEX].data);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDateRange = (compData: any) => {
    const { hoveredIndex } = this.state;
    if (hoveredIndex !== undefined) {
      if (!compData[hoveredIndex]) return '-';
      const periodStartDate = getTimezoneAwareDate(compData[hoveredIndex][DATE_INDEX]);
      return formatDateRange(periodStartDate, this.getTrendGrouping());
    } else {
      const startDate = formatDateRange(
        getTimezoneAwareDate(compData[0][DATE_INDEX]),
        this.getTrendGrouping(),
        true,
      );
      const endDate = formatDateRange(
        getTimezoneAwareDate(compData[compData.length - 1][DATE_INDEX]),
        this.getTrendGrouping(),
        true,
        true,
      );
      return `${startDate} - ${endDate}`;
    }
  };

  _spec = (data: ChartData): Highcharts.Options | undefined => {
    const { previewData, schema, instructions, globalStyleConfig } = this.props;
    if (schema?.length === 0 || !previewData) return;

    const getFormattedAggValue = (value: number) => this.getFormattedAggValue(value);

    const setHoverIndex = (index?: number) => {
      this.setState({ hoveredIndex: index });
    };

    return {
      chart: {
        type: 'line',
        backgroundColor: globalStyleConfig.container.fill,
      },
      series: data,
      title: sharedTitleConfig,
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
        series: {
          animation: false,
          point: {
            events: {
              mouseOver: function () {
                setHoverIndex(this.index);
              },
              mouseOut: function () {
                setHoverIndex(undefined);
              },
            },
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: { text: undefined },
      },
      xAxis: {
        crosshair: true,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        lineWidth: 1,
        lineColor: '#EEEEEE',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          // We can't use an arrow function because "this" references the formatter's context
          return renderTooltip(getFormattedAggValue, data, instructions, this.points);
        },
        backgroundColor: this.props.globalStyleConfig.container.fill,
        borderWidth: 0,
        borderRadius: 4,
      },
    };
  };

  getPeriodColumnName = () => this.props.schema[0].name;

  getAggColumnName = () => this.props.schema[1].name;

  getPeriodAndComparisonDates = () => {
    const { instructions } = this.props;

    const { periodStartDate, periodEndDate, periodDates } = getPeriodDates(
      this.getPeriodRange(),
      this.getTrendGrouping(),
      this.getCustomStartDate(),
      this.getCustomEndDate(),
      instructions?.periodColumn?.trendDateOffset ?? 0,
    );

    const comparisonDates = getComparisonDates(
      periodStartDate,
      periodEndDate,
      periodDates.length,
      this.getComparisonRange(),
      this.getPeriodRange(),
      this.getTrendGrouping(),
    );

    return { periodDates, comparisonDates };
  };

  processTrendData = () => {
    const { previewData, instructions, globalStyleConfig } = this.props;

    if (!previewData) return undefined;

    const periodColumnName = this.getPeriodColumnName();
    const aggColumnName = this.getAggColumnName();

    const { periodDates, comparisonDates } = this.getPeriodAndComparisonDates();

    previewData.forEach((row) => {
      if (!instructions?.periodColumn?.column.type) return;

      row[periodColumnName] = getTimezoneAwareDate(row[periodColumnName].toString()).toLocaleString(
        DateTime.DATE_SHORT,
      );
    });

    const dataByDate = keyBy(previewData, periodColumnName);
    const series: Record<string, LineOptions> = {
      period: {
        type: 'line',
        name: 'Period',
        data: [],
        color:
          instructions?.displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0],
      },
      comparison: {
        type: 'line',
        name: 'Comparison',
        data: [],
        color: instructions?.displayFormat?.comparisonColor || '#757575',
        lineWidth: 1,
        opacity: 0.6,
        dashStyle: 'Dash',
      },
    };

    periodDates.forEach((date) => {
      let aggValue = 0;
      const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
      if (dataByDate[parsedDate]) {
        const asNumber = getAxisNumericalValue(dataByDate[parsedDate][aggColumnName]);
        aggValue = !isNaN(asNumber) ? asNumber : 0;
      }
      const entry: [DateTime, number] = [date, aggValue];
      series.period.data.push(entry);
    });

    comparisonDates.forEach((date) => {
      let aggValue = 0;
      const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
      if (dataByDate[parsedDate]) {
        const asNumber = getAxisNumericalValue(dataByDate[parsedDate][aggColumnName]);
        aggValue = !isNaN(asNumber) ? asNumber : 0;
      }
      const entry: [DateTime, number] = [date, aggValue];
      series.comparison.data.push(entry);
    });

    return [series.period, series.comparison];
  };

  getPeriodRange = () =>
    this.props.instructions?.periodColumn?.periodRange || PeriodRangeTypes.LAST_4_WEEKS;

  getComparisonRange = () =>
    this.props.instructions?.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD;

  // At this point TrendGrouping cannot be a DateGroupToggleId
  getTrendGrouping = () =>
    (this.props.instructions?.trendGrouping || TrendGroupingOptions.WEEKLY) as TrendGroupingOptions;

  getCustomEndDate = () => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.customEndDate
      ? getTimezoneAwareDate(instructions.periodColumn.customEndDate)
      : DateTime.local();
  };

  getCustomStartDate = () => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.customStartDate
      ? getTimezoneAwareDate(instructions.periodColumn.customStartDate)
      : DateTime.local();
  };

  // if the title is undefined, we then use the aggregation name as the title for the KPI
  getTitle = () => {
    const { instructions, generalOptions } = this.props;

    if (generalOptions?.headerConfig?.title !== undefined) return generalOptions.headerConfig.title;

    const aggColumn = instructions?.aggColumn?.column;

    return aggColumn?.friendly_name || aggColumn?.name || '';
  };
}

// Highcharts Tooltips do not render JSX so we need to pass it raw HTML
function renderTooltip(
  getFormattedAggValue: (value: number) => string,
  data: LineOptions[],
  instructions?: V2KPITrendInstructions,
  points?: TooltipFormatterContextObject[],
) {
  const periodPoint = points?.[PERIOD_INDEX];
  if (!periodPoint) return null;

  const { tooltipCell, tooltipLine, periodRow, tooltipTable, periodLine } = styles;

  const comparisonPoint = points?.[COMPARISON_INDEX];
  const pctChange = getPctChange(periodPoint.y, comparisonPoint?.y || 0);
  const isPositive = pctChange > 0;
  const isNoChange = pctChange === 0;
  const changeText = format('.1%')(Math.abs(pctChange));
  const changeSign = isPositive ? '+' : isNoChange ? '' : '-';

  const cellClass = cx(tooltipCell, GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor);
  const dateFormat = getDateFormat(instructions?.trendGrouping);
  const changeClass = getTooltipStyle(isNoChange, isPositive, instructions);
  const periodIndex = periodPoint.x;
  const periodDate = data[0].data[periodIndex][0];
  const periodDateText = periodDate.toLocaleString(dateFormat);
  const periodLineStyle = `border-right: 2px solid ${periodPoint.color}`;
  const periodText = `<tr class="${periodRow}"><td class="${periodLine}"><div class="${tooltipLine}" style="${periodLineStyle}"/></td><td class="${cellClass}">${periodDateText}</td><td class="${cellClass}">${getFormattedAggValue(
    periodPoint.y,
  )}</td><td class="${changeClass}">${changeSign}${changeText}</td></tr>`;

  const comparisonText = renderComparisonTooltip(getFormattedAggValue, data, instructions, points);

  const tableClass = cx(tooltipTable, embedSprinkles({ color: 'primaryFont' }));
  return `<table class="${tableClass}">${periodText}${comparisonText}</table>`;
}

function renderComparisonTooltip(
  getFormattedAggValue: (value: number) => string,
  data: LineOptions[],
  instructions?: V2KPITrendInstructions,
  points?: TooltipFormatterContextObject[],
) {
  const comparisonPoint = points?.[COMPARISON_INDEX];
  if (!comparisonPoint) return '';

  const { comparisonRow, tooltipLine, tooltipCell, comparisonLine } = styles;

  const dateFormat = getDateFormat(instructions?.trendGrouping);
  const comparisonIndex = comparisonPoint.x;
  const comparisonDate = data[1].data[comparisonIndex][0];
  const comparisonDateText = comparisonDate.toLocaleString(dateFormat);
  const comparisonLineStyle = `border-right: 2px dashed ${comparisonPoint.color}`;

  const rowClass = cx(comparisonRow, embedSprinkles({ color: 'secondaryFont' }));
  const cellClass = cx(tooltipCell, GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor);
  return `<tr class="${rowClass}"><td class="${comparisonLine}"><div class="${tooltipLine}" style="${comparisonLineStyle}"/></td><td class="${cellClass}">${comparisonDateText}</td><td class="${cellClass}">${getFormattedAggValue(
    comparisonPoint.y,
  )}</td><td class="${tooltipCell}">-</td></tr>`;
}

function getTooltipStyle(
  isNoChange: boolean,
  isPositive: boolean,
  instructions?: V2KPITrendInstructions,
) {
  const changeStyle = isNoChange
    ? cx(styles.tooltipNoChange, embedSprinkles({ color: 'primaryFont' }))
    : instructions?.displayFormat?.trendColorsReversed
    ? isPositive
      ? styles.tooltipNegativeChange
      : styles.tooltipPositiveChange
    : isPositive
    ? styles.tooltipPositiveChange
    : styles.tooltipNegativeChange;

  return `${styles.tooltipChange} ${changeStyle}`;
}

function getDateFormat(trendGrouping?: TrendGroupingOptions | TrendGroupToggleOption) {
  return trendGrouping === TrendGroupingOptions.HOURLY
    ? DateTime.DATETIME_SHORT
    : DateTime.DATE_SHORT;
}

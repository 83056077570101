import { FC, forwardRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { IdTag } from './IdTag';
import { DashboardElementView } from 'pages/dashboardPage/dashboardElement/dashboardElementView';
import * as styles from './wrapperStyles.css';

import { PassedProps as ElementGridLayoutProps } from './ElementGridLayout';
import { DashboardStates } from 'reducers/rootReducer';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import { selectItemOnDashboardThunk } from 'reducers/thunks/dashboardSelectionThunks';

type Props = {
  datasetNamesToId: Record<string, string>;
  element: DashboardElement;
  elementStartsOnRightSide: boolean;
  isDragging: boolean;
  isInContainer?: boolean;
  isMobileView: boolean;
  isResizing: boolean;
  elementGridLayoutProps: ElementGridLayoutProps | undefined;
  elementNamesById: Record<string, string>;
  variables: DashboardVariableMap;

  stopDragging: () => void;
};

export const ElementWrapper: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    {
      element,
      isDragging,
      stopDragging,
      isMobileView,
      isResizing,
      isInContainer,
      variables,
      elementNamesById,
      elementStartsOnRightSide,
      datasetNamesToId,
      elementGridLayoutProps,
      children,
      ...props
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const { isSelected, isHovered, isEditing } = useSelector(
      (state: DashboardStates) => ({
        isEditing: state.dashboardInteractions.interactionsInfo.isEditing,
        isHovered: state.dashboardInteractions.linkedElementHoverId === element.id,
        isSelected: state.dashboardInteractions.selectedItem?.id === element.id,
      }),
      shallowEqual,
    );

    return (
      <div
        {...props}
        className={cx(styles.element, {
          [styles.selectedItem]: isSelected || isHovered,
          [styles.editableElement]: isEditing,
          [styles.hoverElement]: isEditing && !isSelected && !isDragging,
        })}
        key={element.id}
        onClick={(e) => {
          if (isEditing && !isSelected) {
            dispatch(selectItemOnDashboardThunk(element.id, { type: element.element_type }));
          }
          e.stopPropagation();
        }}
        ref={ref}>
        {isEditing ? <IdTag name={element.name} stopDragging={stopDragging} /> : null}
        <DashboardElementView
          dashboardElement={element}
          datasetNamesToId={datasetNamesToId}
          editableDashboard={isEditing}
          elementGridLayoutProps={elementGridLayoutProps}
          elementNamesById={elementNamesById}
          elementStartsOnRightSide={elementStartsOnRightSide}
          isInContainer={isInContainer}
          isMobileView={isMobileView}
          isResizing={isResizing}
          variables={variables}
        />
        {/* Children here is the resizer handle, so not needed unless editing */}
        {isEditing ? children : null}
      </div>
    );
  },
);

ElementWrapper.displayName = 'ElementWrapper';

import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { GoalLineConfig } from './GoalLineConfig';
import { CollapsibleGroup } from 'components/CollapsibleGroup';
import { sprinkles } from 'components/ds';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, GoalLineChartConfig } from 'constants/types';
import { configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: GoalLineChartConfig;
};

export const GoalLineGroupConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  if (!instructions.goalLines?.length) return null;

  return (
    <div className={configRootClass}>
      {instructions.goalLines?.map((goalLineConfig, index) => (
        <CollapsibleGroup
          className={sprinkles({ marginX: 'sp1.5', marginBottom: 'sp1.5' })}
          key={index}
          onDelete={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.goalLines?.splice(index, 1);
            });
            dispatch(updateVisualizeOperation(newInstructions, visualizationType));
          }}
          title={goalLineConfig.label || `Goal Line #${index + 1}`}>
          <GoalLineConfig
            config={goalLineConfig}
            updateConfig={(newConfig) => {
              const newInstructions = produce(instructions, (draft) => {
                if (draft.goalLines) draft.goalLines[index] = newConfig;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            visualizationType={visualizationType}
          />
        </CollapsibleGroup>
      ))}
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { sortBy } from 'utils/standard';
import * as RD from 'remotedata';

import { AllowlistDomain } from 'actions/teamActions';
import {
  createAllowlistDomainSuccess,
  deleteAllowlistDomainSuccess,
  fetchAllowlistDomainsActions,
} from 'actions/allowlistDomainActions';

interface AllowlistDomainReducerState {
  domains: RD.ResponseData<AllowlistDomain[]>;
}

const allowlistDomainReducerInitialState: AllowlistDomainReducerState = {
  domains: RD.Idle(),
};

const allowlistDomainSlice = createSlice({
  name: 'allowlistDomain',
  initialState: allowlistDomainReducerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllowlistDomainsActions.requestAction, (state) => {
        state.domains = RD.Loading();
      })
      .addCase(fetchAllowlistDomainsActions.errorAction, (state) => {
        state.domains = RD.Error('Error loading allowlist domains');
      })
      .addCase(fetchAllowlistDomainsActions.successAction, (state, { payload }) => {
        state.domains = RD.Success(sortBy(payload.whitelist_domains, 'id'));
      })
      .addCase(createAllowlistDomainSuccess, (state, { payload }) => {
        RD.update(state.domains, (domains) => domains.push(payload.whitelist_domain));
      })
      .addCase(deleteAllowlistDomainSuccess, (state, { payload }) => {
        state.domains = RD.map(state.domains, (domains) =>
          domains.filter((domain) => domain.id !== payload.id),
        );
      });
  },
});

export const allowlistDomainReducer = allowlistDomainSlice.reducer;

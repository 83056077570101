import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { AddDataPanelModal } from 'pages/dashboardPage/addDataPanelModal';
import { DashboardEditBanner } from 'pages/dashboardPage/dashboardEditBanner';
import { DashboardDebugger } from 'pages/dashboardPage/DashboardDebugger';
import { EditDashboardLeftPanel } from './EditDashboardLeftPanel';
import { EditDashboardPageBody } from './editDashboardBody';
import * as styles from './editDashboardPage.css';

import { VersionInfo } from 'types/exploResource';
import { Dashboard } from 'actions/dashboardActions';

import { ReduxState } from 'reducers/rootReducer';
import { getTimezone } from 'utils/timezoneUtils';
import { DeleteElementModal } from './deleteElementModal';
import { getIsFullPageDashboard } from 'reducers/dashboardInteractionsReducer';

type Props = {
  dashboard: Dashboard;
  dashboardVersionInfo: VersionInfo;
  width?: number;
};

export const EditDashboardPage: FC<Props> = ({ dashboard, dashboardVersionInfo, width }) => {
  const { editableDashboard, allDashboardVersions, isFullPageDashboard, rightPaneOpen } =
    useSelector(
      (state: ReduxState) => ({
        editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
        allDashboardVersions: state.dashboardVersions.versions,
        isFullPageDashboard: getIsFullPageDashboard(state.dashboardInteractions),
        rightPaneOpen: state.dashboardInteractions.paneOpenStates.right,
      }),
      shallowEqual,
    );

  return (
    <div className={styles.root}>
      <DashboardEditBanner
        allDashboardVersions={allDashboardVersions}
        dashboard={dashboard}
        dashboardVersionInfo={dashboardVersionInfo}
        inEditMode={editableDashboard}
      />
      {editableDashboard ? null : (
        <div className={styles.previewBanner}>
          This is a preview of&nbsp;<b>{dashboard.name}</b>. Changes will not be saved
        </div>
      )}
      <div className={styles.bodyBelowBanner}>
        <EditDashboardLeftPanel
          dashboardId={dashboard.id}
          isFullPageDashboard={isFullPageDashboard}
          shareLinkTitle={dashboard.share_link_title}
        />
        <EditDashboardPageBody
          dashboard={dashboard}
          isFullPageDashboard={isFullPageDashboard}
          versionInfo={dashboardVersionInfo}
          width={width}
        />
        {!isFullPageDashboard && rightPaneOpen ? (
          <div className={styles.rightSide}>
            <DashboardDebugger
              dashboardId={dashboard.id}
              timezone={getTimezone(dashboard.default_timezone)}
            />
          </div>
        ) : null}
      </div>
      <AddDataPanelModal dashboardId={dashboard.id} />
      <DeleteElementModal />
    </div>
  );
};

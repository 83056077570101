import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import { makeThunkRequest } from 'utils/thunkUtils';
import { CustomerReport, CustomerReportConfig } from 'actions/customerReportActions';
import { ACTION } from 'actions/types';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import {
  createFilter,
  openBuiltIn,
  openCustomerReport,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { Metadata } from 'utils/analyticsUtils';
import { getCurrentISOString } from 'utils/dateUtils';
import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import {
  ToggleFavoriteCustomerReportBuilderMetadataResponse,
  ToggleFavoriteCustomerReportBuilderMetadataBody,
  SaveCustomerReportBody,
} from 'reportBuilderContent/apiTypes';
import {
  ReportType,
  CreateFilterPayload,
  SelectedReportType,
} from 'reportBuilderContent/reducers/types';

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;

export const openCustomerReportThunk =
  (report: CustomerReport & { viewId?: string }): Thunk =>
  (dispatch) => {
    dispatch(openCustomerReport(report));
    dispatch(
      sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_OPENED, {
        customer_report_id: report.id,
        customer_report_name: report.name,
      }),
    );
  };

export const openBuiltInThunk =
  (builtIn: BuiltInReportConfig): Thunk =>
  (dispatch) => {
    dispatch(openBuiltIn(builtIn));
    dispatch(
      sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.BUILT_IN_REPORT_OPENED, {
        build_in_id: builtIn.id,
        built_in_name: builtIn.name,
      }),
    );
  };

type CreateCustomerReportThunkBody = {
  name: string;
  config: CustomerReportConfig | null;
  builtInId?: string;
  reportId?: number;
};

export const createCustomerReportThunk =
  ({ name, config, builtInId, reportId }: CreateCustomerReportThunkBody): Thunk =>
  (dispatch) => {
    dispatch(createCustomerReport({ name, config }));

    const metadata: Metadata = {};
    if (builtInId) metadata['from_built_in_id'] = builtInId;
    else if (reportId) metadata['from_customer_report_id'] = reportId;

    dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_CREATED, metadata));
  };

type CreateCustomerReportBody = {
  resource_embed_id: string | undefined;
  name: string;
  config: CustomerReportConfig | null;
};

export const createCustomerReport = createAsyncThunk<
  { report: CustomerReport },
  { name: string; config: CustomerReportConfig | null },
  { state: ReportBuilderReduxState }
>(ACTION.CREATE_CUSTOMER_REPORT, async ({ name, config }, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const now = getCurrentISOString();
  if (embeddedReportBuilder.isPreview) {
    const nextId = Math.max(...embeddedReportBuilder.reports.map((report) => report.id), 0) + 1;
    return Promise.resolve({
      report: {
        id: nextId,
        name,
        config: {
          views: [createBlankView()],
          ...config,
        },
        is_starred: false,
        modified: now,
      },
    });
  }

  const createCustomerReportBody: CreateCustomerReportBody = {
    resource_embed_id: embeddedReportBuilder.requestInfo.embedId,
    name,
    config,
  };

  const requestConfig = createApiRequestConfig(
    'customer_reports/create_report/',
    'POST',
    createCustomerReportBody,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

export const saveCustomerReportThunk =
  (body: SaveCustomerReportBody): Thunk =>
  (dispatch) => {
    dispatch(saveCustomerReport(body));
    dispatch(
      sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_SAVED, {
        from_customer_report_id: body.report_id,
      }),
    );
  };

export const saveCustomerReport = createAsyncThunk<
  { report: CustomerReport },
  SaveCustomerReportBody,
  { state: ReportBuilderReduxState }
>(ACTION.SAVE_CUSTOMER_REPORT, async (body, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const requestConfig = createApiRequestConfig(
    'customer_reports/save_report/',
    'POST',
    body,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

export const createFilterThunk =
  (body: CreateFilterPayload): Thunk =>
  (dispatch, getState) => {
    const { selectedReport } = getState().reportEditing;
    dispatch(createFilter(body));
    dispatch(
      sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_FILTER_OPENED, {
        from_customer_report_id: selectedReport?.id,
        column_name: body.column.name,
        filter_operator: body.filterOperator,
      }),
    );
  };

export const favoriteCustomerReportThunk =
  (isStarred: boolean, reportInfo: SelectedReportType): Thunk =>
  (dispatch, getState) => {
    const {
      embeddedReportBuilder: {
        requestInfo: { embedId },
      },
    } = getState();
    if (reportInfo.type === ReportType.CUSTOMER_REPORT) {
      dispatch(favoriteCustomerReport({ report_id: reportInfo.id }));
    } else if (reportInfo.type === ReportType.BUILT_IN) {
      dispatch(favoriteBuiltIn({ resource_embed_id: embedId, built_in_id: reportInfo.id }));
    }

    dispatch(
      sendAnalyticsEvent(
        isStarred
          ? REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_UNFAVORITED
          : REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_FAVORITED,
        reportInfo.type === ReportType.BUILT_IN
          ? { from_built_in_id: reportInfo.id }
          : { from_customer_report_id: reportInfo.id },
      ),
    );
  };

export const favoriteCustomerReport = createAsyncThunk<
  { report_id: number },
  { report_id: number },
  { state: ReportBuilderReduxState }
>(ACTION.STAR_CUSTOMER_REPORT, async (body, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const requestConfig = createApiRequestConfig(
    'customer_reports/star_report/',
    'POST',
    body,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

export const favoriteBuiltIn = createAsyncThunk<
  ToggleFavoriteCustomerReportBuilderMetadataResponse,
  ToggleFavoriteCustomerReportBuilderMetadataBody,
  { state: ReportBuilderReduxState }
>(ACTION.TOGGLE_FAVORITE_BUILT_IN, async (body, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const requestConfig = createApiRequestConfig(
    'customer_reports/toggle_favorite_built_in/',
    'POST',
    body,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

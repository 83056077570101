import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Export } from 'pages/ReportBuilder/Export';
import { useQuery } from 'utils/routerUtils';
import { CustomerReportVisualization } from 'actions/customerReportActions';
import { OPERATION_TYPES } from 'constants/types';

type MatchParams = {
  reportBuilderEmbedId: string;
  customerToken: string;
};

export const ExportReportBuilderPage: FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const query = useQuery();

  const reportBuilderId = params.reportBuilderEmbedId;
  const customerToken = params.customerToken;
  const environment = query.get('environment') || undefined;
  const embedJwt = query.get('embedJwt') || undefined;
  const visualization = query.get('visualization') || OPERATION_TYPES.VISUALIZE_TABLE;
  const datasetId = query.get('dataset_id') || undefined;
  const title = query.get('title') || undefined;
  const variables = useJsonQueryParam(query, 'variables');
  const filters = useJsonQueryParam(query, 'filters');
  const sort = useJsonQueryParam(query, 'sort');
  const groupBys = useJsonQueryParam(query, 'group_bys');
  const colGroupBys = useJsonQueryParam(query, 'col_group_bys');
  const aggregations = useJsonQueryParam(query, 'aggregations');
  const totals = useJsonQueryParam(query, 'totals');
  const hiddenColumns = useJsonQueryParam(query, 'hidden_columns');
  const columns = useJsonQueryParam(query, 'columns');

  return (
    <Export
      aggregations={aggregations}
      columnGroupBys={colGroupBys}
      columns={columns}
      customerToken={customerToken}
      datasetId={datasetId}
      embedJwt={embedJwt}
      embedType="pdf"
      environment={environment}
      filters={filters}
      groupBys={groupBys}
      hiddenColumns={hiddenColumns}
      reportBuilderEmbedId={reportBuilderId}
      sort={sort}
      title={title}
      totals={totals}
      variables={variables}
      visualization={visualization as CustomerReportVisualization}
    />
  );
};

const useJsonQueryParam = (query: URLSearchParams, param: string) => {
  const queryParam = query.get(param);
  const value = useMemo(() => {
    try {
      return queryParam ? JSON.parse(queryParam) : undefined;
    } catch {
      return;
    }
  }, [queryParam]);
  return value;
};

import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { SettingHeader } from 'components/SettingHeader';
import KPIAggregation from '../KPIAggregation';
import DropdownSection from './droppable/DropdownSection';
import { FilterValueInput } from 'components/FilterValueInput';
import DroppableColumnSection from './droppable/DroppableColumnSection';
import { sprinkles, Input, Select } from 'components/ds';

import {
  FilterValueDateType,
  KPIPeriodColumnInfo,
  OPERATION_TYPES,
  TrendGroupToggleOption,
  TrendGroupToggleOptionId,
  V2KPITrendInstructions,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import { DATETIME, TIMESTAMP, DATE } from 'constants/dataConstants';
import {
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  TimePeriodDropdownElemConfig,
} from 'types/dashboardTypes';
import {
  PeriodRangeTypes,
  PeriodComparisonRangeTypes,
  PERIOD_COMPARISON_RANGE_TYPES,
  TrendGroupingOptions,
  TREND_GROUPING_OPTIONS,
} from 'types/dateRangeTypes';
import { FilterOperator } from 'types/filterOperations';
import { filterForValidDateGroupElements } from 'utils/dataPanelColUtils';

type Props = {
  instructions: V2KPITrendInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
  schema: DatasetSchema;
};

const trendGroupingOptions = Object.values(TREND_GROUPING_OPTIONS);

export const KPITrendConfig: FC<Props> = ({
  instructions,
  chartType,
  loading,
  dashboardElements,
  schema,
}) => {
  const dispatch = useDispatch();

  const validDateGroupElements = useMemo(
    () => filterForValidDateGroupElements(dashboardElements).map((elem) => ({ value: elem.name })),
    [dashboardElements],
  );

  const trendOptions = useMemo(() => {
    const options: { id: string; name: string }[] = [...trendGroupingOptions];
    if (validDateGroupElements.length > 0) {
      options.push({ id: TrendGroupToggleOptionId, name: 'Date Group Toggle' });
    }
    return options;
  }, [validDateGroupElements.length]);

  const selectedTrend = useMemo(
    () =>
      trendOptions.find((option) => option.id === instructions.trendGrouping) ??
      TREND_GROUPING_OPTIONS[TrendGroupingOptions.WEEKLY],
    [trendOptions, instructions.trendGrouping],
  );

  const updateInstructions = (updates: Partial<V2KPITrendInstructions>) => {
    const newInstructions = produce(instructions, (draft) => {
      return { ...draft, ...updates };
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const updatePeriodCol = (
    updateFunc: (curr: KPIPeriodColumnInfo) => KPIPeriodColumnInfo | undefined,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.periodColumn) return;
      draft.periodColumn = updateFunc(draft.periodColumn);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <div>
      <KPIAggregation
        chartType={chartType}
        instructions={instructions as V2KPITrendInstructions}
        loading={loading}
        schema={schema}
      />
      <SettingHeader name="Period Range" />
      <DroppableColumnSection
        isKpiTrendColumn
        required
        allowedTypes={[DATE, DATETIME, TIMESTAMP]}
        columns={instructions.periodColumn ? [{ column: instructions.periodColumn.column }] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) =>
          updateInstructions({
            periodColumn: { column: col, periodRange: PeriodRangeTypes.LAST_4_WEEKS },
          })
        }
        onColOptionChanged={(option) =>
          updatePeriodCol((periodColumn) => {
            let updated = { ...periodColumn, periodRange: option.id as PeriodRangeTypes };
            if (
              option.id === PeriodRangeTypes.DATE_RANGE_INPUT ||
              option.id === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
            ) {
              updated = { ...updated, customEndDate: undefined, customStartDate: undefined };
            }

            return updated;
          })
        }
        onRemoveCol={() => updatePeriodCol(() => undefined)}
        periodRange={instructions.periodColumn?.periodRange}
        schema={schema}
      />

      {instructions.periodColumn?.periodRange === PeriodRangeTypes.CUSTOM_RANGE ? (
        <div className={customRangeDateSelectorsClass}>
          <FilterValueInput
            preventFutureDates
            filterOperator={FilterOperator.DATE_IS_BETWEEN}
            filterValue={{
              startDate: instructions.periodColumn?.customStartDate,
              endDate: instructions.periodColumn?.customEndDate,
            }}
            onFilterValueUpdate={(newValue) => {
              const newRange = newValue as FilterValueDateType;

              updatePeriodCol((periodCol) => {
                return {
                  ...periodCol,
                  customStartDate: newRange.startDate,
                  // Backend expects y-m-d formatting
                  customEndDate: newRange.endDate,
                };
              });
            }}
          />
        </div>
      ) : null}
      {instructions.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ? (
        <Select
          className={customRangeDateSelectorsClass}
          onChange={(value) =>
            updatePeriodCol((periodCol) => ({ ...periodCol, rangeElemId: value }))
          }
          placeholder="Select an Input"
          selectedValue={instructions.periodColumn.rangeElemId}
          values={filterForValidElementsForDateRangeInput(dashboardElements).map((elem) => ({
            value: elem.name,
          }))}
        />
      ) : null}
      {instructions.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN ? (
        <Select
          className={customRangeDateSelectorsClass}
          onChange={(value) =>
            updatePeriodCol((periodCol) => ({ ...periodCol, timePeriodElemId: value }))
          }
          placeholder="Select an Input"
          selectedValue={instructions.periodColumn.timePeriodElemId}
          values={filterForValidElementsForTimePeriodDropdown(dashboardElements).map((elem) => ({
            value: elem.name,
          }))}
        />
      ) : null}
      {canPeriodRangeUseOffset(instructions.periodColumn?.periodRange) ? (
        <Input
          showInputButton
          className={sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1' })}
          defaultValue={instructions.periodColumn?.trendDateOffset?.toString()}
          label={{
            text: 'Offset (Days Ago)',
            infoText:
              "This sets the period range's offset e.g. if the period range is 'Last 4 Weeks' and the offset is 2, the period range will be between 2 days ago and 4 weeks ago from 2 days ago",
          }}
          onSubmit={(value) =>
            updatePeriodCol((periodCol) => ({
              ...periodCol,
              trendDateOffset: isNaN(parseInt(value)) ? 0 : parseInt(value),
            }))
          }
          placeholder="0"
        />
      ) : null}
      <SettingHeader name="Comparison Range" />
      <DropdownSection
        disableEdits={loading}
        onOptionChanged={(option) =>
          updateInstructions({ periodComparisonRange: option.id as PeriodComparisonRangeTypes })
        }
        options={Object.values(PERIOD_COMPARISON_RANGE_TYPES)}
        selectedOption={
          PERIOD_COMPARISON_RANGE_TYPES[
            instructions.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD
          ]
        }
      />
      <SettingHeader name="Grouping" />
      <DropdownSection
        disableEdits={loading}
        onOptionChanged={(option) =>
          updateInstructions({
            trendGrouping: option.id as TrendGroupingOptions | TrendGroupToggleOption,
          })
        }
        options={trendOptions}
        selectedOption={selectedTrend}
      />
      {selectedTrend.id === TrendGroupToggleOptionId ? (
        <Select
          className={customRangeDateSelectorsClass}
          onChange={(value) => updateInstructions({ trendGroupingElementId: value })}
          placeholder="Select a Date Group Toggle"
          selectedValue={instructions.trendGroupingElementId}
          values={validDateGroupElements}
        />
      ) : null}
    </div>
  );
};

const canPeriodRangeUseOffset = (range?: PeriodRangeTypes) => {
  return (
    range !== undefined &&
    range !== PeriodRangeTypes.CUSTOM_RANGE &&
    range !== PeriodRangeTypes.DATE_RANGE_INPUT &&
    range !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN
  );
};

const filterForValidElementsForDateRangeInput = (dashboardElements?: DashboardElement[]) =>
  dashboardElements?.filter(
    (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
  ) ?? [];

const filterForValidElementsForTimePeriodDropdown = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter((elem) => {
    if (elem.element_type !== DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN) return false;
    const config = elem.config as TimePeriodDropdownElemConfig;

    return !!config.values?.length;
  });
};

const customRangeDateSelectorsClass = sprinkles({
  paddingX: 'sp1',
  paddingTop: 'sp0',
  marginBottom: 'sp1',
});

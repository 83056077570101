import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { Switch, sprinkles } from 'components/ds';
import { SettingHeader } from 'components/SettingHeader';
import { HeatMapGradientConfiguration } from './HeatMapGradientConfiguration';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { HeatMapFormat, OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const HeatMapConfig: FC<Props> = ({
  globalStyleConfig,
  visualizationType,
  instructions,
}) => {
  const dispatch = useDispatch();
  const heatMap = instructions.chartSpecificFormat?.heatMap;

  const updateHeatMapInstructions = (updates: HeatMapFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        heatMap: {
          ...draft.chartSpecificFormat?.heatMap,
          ...updates,
        },
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <Switch
        className={configInputClass}
        label="Show Chart Values"
        onChange={() => updateHeatMapInstructions({ showCellLabels: !heatMap?.showCellLabels })}
        switchOn={heatMap?.showCellLabels}
      />
      {heatMap?.showCellLabels ? (
        <Switch
          className={configInputClass}
          label="Hide Zeros"
          onChange={() => updateHeatMapInstructions({ hideZeros: !heatMap?.hideZeros })}
          switchOn={heatMap?.hideZeros}
        />
      ) : null}
      <SettingHeader name="Gradient" />
      <div className={sprinkles({ margin: 'sp1.5' })}>
        <HeatMapGradientConfiguration
          globalStyleConfig={globalStyleConfig}
          instructions={instructions}
          updateHeatMapInstructions={updateHeatMapInstructions}
        />
      </div>
    </div>
  );
};

import { DetailedHTMLProps, FC, TextareaHTMLAttributes, useEffect, useState } from 'react';
import { camelCase } from 'utils/standard';
import cx from 'classnames';

import { Label } from '../Label';
import * as styles from './index.css';

type LabelProps = { text: string; infoText?: string; variableInputLabel?: boolean };

type ControlledProps = {
  defaultValue?: never;
  onSubmit?: never;

  value: string | undefined;
  onChange: (value: string) => void;
};

type UncontrolledProps = {
  value?: never;
  onChange?: never;

  defaultValue: string | undefined;
  onSubmit?: (value: string) => void;
};

type StateProps = UncontrolledProps | ControlledProps;

interface TextAreaProps
  extends Omit<
    DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'onSubmit' | 'value' | 'onChange'
  > {
  label?: string | LabelProps;
  noResize?: boolean;
}

export type Props = StateProps & TextAreaProps;

export const TextArea: FC<Props> = ({
  onSubmit,
  disabled,
  label,
  defaultValue,
  className,
  noResize,
  value,
  onChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || value || '');

  const currLabel = typeof label === 'string' ? label : label?.text;
  const name = currLabel ? camelCase(currLabel) : '';

  useEffect(() => {
    setInputValue(defaultValue || value || '');
  }, [defaultValue, value]);

  const renderLabel = () => {
    if (currLabel === undefined || label === undefined) return null;

    if (typeof label === 'string') return <Label htmlFor={name}>{currLabel}</Label>;

    return (
      <Label forVariableInput={label.variableInputLabel} htmlFor={name} infoText={label.infoText}>
        {currLabel}
      </Label>
    );
  };

  return (
    <div className={className}>
      {renderLabel()}
      <textarea
        {...props}
        className={cx(styles.base, { [styles.disabled]: disabled, [styles.noResize]: noResize })}
        id={name}
        onBlur={() => onSubmit?.(inputValue)}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange?.(e.target.value);
        }}
        value={inputValue}
      />
    </div>
  );
};

import { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, sortBy } from 'utils/standard';

import { ApplyFilterRow } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/ApplyFilterRow';
import { FilterDropdown } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/FilterDropdown';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Icon, sprinkles, Popover } from 'components/ds';
import { EmbedButton } from 'components/embed';
import * as styles from '../FilterTag.css';

import { FilterValueType } from 'constants/types';
import {
  FILTER_OPERATOR_TYPES_BY_ID,
  FilterOperator,
  FilterOperatorWithSupport,
} from 'types/filterOperations';
import {
  FilterableColumn,
  getFilterClauseValueText,
  getOperatorName,
} from 'utils/customerReportUtils';
import { CustomerReportFilter } from 'actions/customerReportActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { applyFilter, setOpenFilter } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  // Used to hold open popover when the popover has a dropdown open
  holdPopoverOpen: boolean;
  setHoldPopoverOpen: (open: boolean) => void;
  value?: FilterValueType;
  operator: FilterOperator;
  setOperator: (operator: FilterOperator) => void;
  operatorOptions: FilterOperatorWithSupport[];
};

export const FilterPopover: FC<Props> = ({
  operatorOptions,
  clause,
  column,
  children,
  holdPopoverOpen,
  value,
  operator,
  setHoldPopoverOpen,
  setOperator,
}) => {
  const dispatch = useDispatch();
  const openFilterId = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.openFilterId,
  );
  const currOperator = FILTER_OPERATOR_TYPES_BY_ID[operator];
  const clauseId = clause?.id ?? column.name;
  const newClause = { filterColumn: column, filterOperation: { id: operator }, filterValue: value };
  const isFilterComplete = clause ? !isFilterClauseIncomplete(clause) : false;
  const isNewFilterComplete = !isFilterClauseIncomplete(newClause);

  const renderClause = () => {
    if (!clause) return null;
    return (
      <EmbedText body="b3" color="active">
        &nbsp;
        {getFilterClauseValueText(clause)}
      </EmbedText>
    );
  };

  const applyFilterFunc = useCallback(() => {
    if (
      (isFilterComplete || isNewFilterComplete) &&
      (operator != clause?.filterOperation.id || haveValuesChanged(value, clause.filterValue))
    ) {
      const applyInfo = clause ? { filterId: clause.id } : { column };
      dispatch(
        applyFilter({
          ...applyInfo,
          value,
          filterOperator: operator,
          isPostFilter: column.isPostFilter,
        }),
      );
    } else dispatch(setOpenFilter(null));
  }, [clause, column, dispatch, isNewFilterComplete, isFilterComplete, operator, value]);

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Popover
      align="start"
      className={styles.popover}
      isOpen={openFilterId === clauseId}
      onOpenChange={(open) => {
        const stayOpen = open || holdPopoverOpen;
        if (!stayOpen && openFilterId) applyFilterFunc();
        else if (stayOpen) dispatch(setOpenFilter(clauseId));
      }}
      sideOffset={8}
      trigger={
        <div className={styles.tag}>
          <Icon
            className={sprinkles({ color: 'contentSecondary' })}
            name={isFilterComplete ? 'filter' : 'regular-filter'}
          />
          <EmbedText body="b3" className={sprinkles({ marginLeft: 'sp1' })} color="contentPrimary">
            {column.display}
          </EmbedText>
          {isFilterComplete ? renderClause() : null}
        </div>
      }>
      <div className={styles.filterHeader} ref={contentRef}>
        <div className={sprinkles({ flexItems: 'center', gap: 'sp.5', overflow: 'hidden' })}>
          <EmbedText body="b1" className={sprinkles({ flex: 1 })} color="contentSecondary">
            {column.display}
          </EmbedText>
          <FilterDropdown
            items={operatorOptions}
            onOpenChange={setHoldPopoverOpen}
            onSelect={(id) => setOperator(id as FilterOperator)}
            selectedItem={{ id: currOperator.id, name: getOperatorName(currOperator) }}
          />
        </div>
        <EmbedButton
          icon="cross"
          onClick={() => dispatch(setOpenFilter(null))}
          variant="tertiary"
        />
      </div>
      {children}
      <ApplyFilterRow
        clause={clause}
        column={column}
        onApply={applyFilterFunc}
        operator={currOperator.id}
        showDelete={isFilterComplete || !column.default}
        value={value}
      />
    </Popover>
  );
};

const haveValuesChanged = (oldVal: FilterValueType, newVal: FilterValueType): boolean => {
  const isOldValArray = Array.isArray(oldVal);
  const isNewValArray = Array.isArray(newVal);
  if (isOldValArray !== isNewValArray) return true;
  if (isOldValArray && isNewValArray) return !isEqual(sortBy(oldVal), sortBy(newVal));

  return !isEqual(oldVal, newVal);
};

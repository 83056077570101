import { DashboardVariable } from 'types/dashboardTypes';

export const sendVariableUpdatedEvent = (
  isIframe: boolean,
  varName: string,
  newValue: DashboardVariable,
) => {
  const detail = { varName, newValue };
  if (isIframe) {
    window.parent.postMessage({ event: 'sendVariableUpdatedEvent', detail }, '*');
  } else {
    window.dispatchEvent(new CustomEvent('sendVariableUpdatedEvent', { detail }));
  }
};

export const sendDashboardLoadedEvent = (isIframe: boolean) => {
  if (isIframe) {
    window.parent.postMessage({ event: 'dashboardLoaded' }, '*');
  } else {
    window.dispatchEvent(new CustomEvent('dashboardLoaded'));
  }
};

export const sendDashboardReadyToLoadEvent = (isIframe: boolean) => {
  if (isIframe) {
    window.parent.postMessage({ event: 'dashboardReadyToLoad' }, '*');
  } else {
    window.dispatchEvent(new CustomEvent('dashboardReadyToLoad'));
  }
};

export const SAVE_RESOURCE_CONFIG_EVENT = 'saveResourceConfigEvent';

export const saveResourceConfig = () => {
  setTimeout(() => {
    window.dispatchEvent(new CustomEvent(SAVE_RESOURCE_CONFIG_EVENT));
  }, 200);
};

export const SELECT_DATASET_FOR_DRILLDOWN_FORMATTING = 'selectDatasetForDrilldownFormatting';

export type DrilldownDatasetId = { id: string };

export const selectDatasetForDrilldownFormatting = (id: string) => {
  const detail: DrilldownDatasetId = { id };

  window.dispatchEvent(new CustomEvent(SELECT_DATASET_FOR_DRILLDOWN_FORMATTING, { detail }));
};

export const SAVE_MAP_VIEW_STATE = 'SaveMapViewState';

export const saveMapViewState = () => {
  window.dispatchEvent(new CustomEvent('SaveMapViewState'));
};

export const UPDATE_EXPLO_DASHBOARD_VARIABLE = 'updateExploDashboardVariable';
export type UpdateExploDashboardVariablePayload = { varName: string; value: DashboardVariable };

import XRegExp from 'xregexp';
import parse from 'url-parse';

import { getQueryTablesReferencedByText, variableRegex } from './dataPanelConfigUtils';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DateGroupToggleConfig,
  DatepickerElemConfig,
  DateRangePickerElemConfig,
  SelectElemConfig,
  SwitchElementConfig,
  TextInputElemConfig,
  TimePeriodDropdownElemConfig,
} from 'types/dashboardTypes';
import {
  FilterValueSourceType,
  INPUT_TYPE,
  OPERATION_TYPES,
  SECTION_OPTIONS,
  TableJoinColumnConfig,
  V2_CHART_GOAL_LINE_OPERATIONS,
  GoalLineChartConfig,
  V2_COLOR_ZONE_OPERATIONS,
  SchemaChange,
  TrendGroupToggleOptionId,
} from 'constants/types';
import { DATE_PART_INPUT_AGG, V2_VIZ_INSTRUCTION_TYPE } from 'constants/dataConstants';
import {
  COLOR_CATEGORY_FILTER_SUFFIX,
  NONE_CATEGORY_COLOR_VALUE,
  SELECT_ELEMENT_SET,
} from 'constants/dashboardConstants';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import { DEFAULT_DATE_TYPES, PeriodRangeTypes, TrendGroupingOptions } from 'types/dateRangeTypes';
import { getDefaultRangeValues, getDefaultRelativeValue } from './dateUtils';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { getSelectFilterDatasetId } from './filterUtils';
import { getDataPanelLinks } from './filterLinking';
import { isChartUsingMultipleColorCategories } from './colorColUtils';
import { getDisplayVarName, getLengthVarName } from './extraVariableUtils';
import { EmbeddedDashboardType, shouldUseUrlParams } from 'components/EmbeddedDashboard/types';
import { ColumnTooltips } from 'components/embed/EmbedDataGrid';
import { getDatasetsByName } from './datasetUtils';
import { DatasetDataObject } from 'actions/datasetActions';

export const getDatasetIdsDependentOnVariable = (
  datasetIds: string[],
  datasetsById: Record<string, ResourceDataset>,
  changedElementNamesSet: Set<string>,
) => {
  if (changedElementNamesSet.size === 0) return datasetIds;

  return datasetIds.filter((datasetId) => {
    const dataset = datasetsById[datasetId];
    return isQueryDependentOnVariable(changedElementNamesSet, dataset);
  });
};

export const getElemsReliantOnVariableChange = (
  elemsWithDefaults: DashboardElement[],
  datasetsById: Record<string, ResourceDataset>,
  changedElementNamesSet: Set<string>,
) => {
  return elemsWithDefaults.filter((elem) => {
    const config = elem.config as SelectElemConfig;
    const datasetId = getSelectFilterDatasetId(config);
    if (!datasetId || !(datasetId in datasetsById)) return false;

    const dataset = datasetsById[datasetId];
    return isQueryDependentOnVariable(changedElementNamesSet, dataset);
  });
};

export const isQueryDependentOnVariable = (
  changedElementNamesSet: Set<string>,
  dataset: ResourceDataset | undefined,
): boolean => {
  if (!dataset || changedElementNamesSet.size === 0) return false;
  if ('query_variables' in dataset && dataset.query_variables) {
    return !!dataset.query_variables.find((variable) => {
      if (changedElementNamesSet.has(variable)) return true;
      return changedElementNamesSet.has(variable.split('.')[0]);
    });
  } else if (dataset.query) {
    let matchingVars = false;
    XRegExp.forEach(dataset.query, variableRegex, (match) => {
      const varName = match[2]?.trim();
      if (varName && changedElementNamesSet.has(varName)) matchingVars = true;
      if (changedElementNamesSet.has(varName.split('.')[0])) matchingVars = true;
    });
    return matchingVars;
  }
  return false;
};

export const getDataPanelsDependentOnVariable = (
  dataPanels: DataPanel[],
  datasetsById: Record<string, ResourceDataset>,
  allElements: DashboardElement[],
  changedElementNamesSet: Set<string>,
  variables: DashboardVariableMap,
) => {
  if (changedElementNamesSet.size === 0) return [];

  const dpLinks = getDataPanelLinks(allElements, changedElementNamesSet);
  return dataPanels.filter((dp) =>
    isDpReliantOnVariable(dp, datasetsById, dpLinks, changedElementNamesSet, variables),
  );
};

const isDpReliantOnVariable = (
  dataPanel: DataPanel,
  datasetsById: Record<string, ResourceDataset>,
  dpLinks: Record<string, Set<string> | undefined>,
  changedElementNamesSet: Set<string>,
  variables: DashboardVariableMap,
) => {
  if (isConfigDependentOnVariable(changedElementNamesSet, dataPanel, variables)) {
    return true;
  }
  const datasetIds = getDatasetIdsForDataPanel(dataPanel, undefined, true);

  for (let idx = 0; idx < datasetIds.length; idx++) {
    const datasetId = datasetIds[idx];

    if (dpLinks[datasetId]?.has(dataPanel.id)) return true;

    const dataset = datasetsById[datasetId];
    if (isQueryDependentOnVariable(changedElementNamesSet, dataset)) return true;
  }

  return false;
};

const isConfigDependentOnVariable = (
  changedElementNamesSet: Set<string>,
  dataPanel: DataPanel,
  variables: DashboardVariableMap,
) => {
  if (!dataPanel.visualize_op) return false;
  const filterClauses = dataPanel.filter_op?.instructions.filterClauses;

  if (
    filterClauses?.some(
      (filterClause) =>
        filterClause.filterValueSource === FilterValueSourceType.VARIABLE &&
        changedElementNamesSet.has(filterClause.filterValueVariableId || ''),
    )
  )
    return true;

  if (
    filterClauses?.some((filterClause) => {
      return Array.from(changedElementNamesSet).some((elem) => {
        const varId = filterClause.filterValueVariableId;
        if (!varId) return false;

        if (varId.endsWith('.category')) {
          if (varId.replace('.category', '') === elem) return true;
        } else if (varId.endsWith('.color') && varId.replace('.color', '') === elem) {
          return true;
        }

        return false;
      });
    })
  )
    return true;

  const vizInstructionType = V2_VIZ_INSTRUCTION_TYPE[dataPanel.visualize_op.operation_type];
  const twoDimensionInstructions = dataPanel.visualize_op.instructions.V2_TWO_DIMENSION_CHART ?? {};

  if (
    vizInstructionType === 'Two-dimensional' &&
    twoDimensionInstructions.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG &&
    changedElementNamesSet.has(twoDimensionInstructions.categoryColumn.bucketElemId || '')
  )
    return true;

  const colorColOptions = twoDimensionInstructions.colorColumnOptions;
  const coloVarName = dataPanel.provided_id + COLOR_CATEGORY_FILTER_SUFFIX;
  let foundColorConfigChangedElement = false;
  if (colorColOptions?.length) {
    changedElementNamesSet.forEach((elem) => {
      if (elem === coloVarName) {
        foundColorConfigChangedElement = true;
      } else {
        colorColOptions.forEach((colorCol) => {
          if (variables[elem] === colorCol.column.name && elem.includes(dataPanel.provided_id)) {
            foundColorConfigChangedElement = true;
          }
        });
      }
    });
  }

  if (
    (vizInstructionType === 'Two-dimensional' ||
      vizInstructionType === 'Grouped Stacked Bar Chart') &&
    foundColorConfigChangedElement
  )
    return true;

  if (dataPanel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    const config = dataPanel.visualize_op.instructions.V2_KPI_TREND;
    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT &&
      changedElementNamesSet.has(config.periodColumn.rangeElemId || '')
    )
      return true;

    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN &&
      changedElementNamesSet.has(config.periodColumn.timePeriodElemId || '')
    )
      return true;

    if (
      config?.trendGrouping === TrendGroupToggleOptionId &&
      changedElementNamesSet.has(config.trendGroupingElementId || '')
    )
      return true;
  }

  return false;
};

export const isJoinConfigReady = (colConfig: TableJoinColumnConfig) => {
  return colConfig.joinOn && colConfig.joinColumn && colConfig.joinDisplayColumn;
};

export const getDatasetIdsForDataPanel = (
  dataPanel: DataPanel,
  datasets: Record<string, ResourceDataset> | undefined,
  includeOwnDataset = false,
  isPDFDownload = false,
): string[] => {
  const dpDatasetId = getDataPanelDatasetId(dataPanel);
  const startingId = includeOwnDataset ? [dpDatasetId] : [];
  const datasetIds = new Set<string>(startingId);
  const datasetsByName = datasets ? getDatasetsByName(datasets) : undefined;

  const vizOp = dataPanel.visualize_op;
  const { instructions, generalFormatOptions } = vizOp;

  const checkString = (str: string | undefined) => {
    if (!str || !datasetsByName) return;
    getQueryTablesReferencedByText(str, datasetsByName).forEach((id) => datasetIds.add(id));
  };

  // add any datasets from the data panel title
  const headerConfig = generalFormatOptions?.headerConfig ?? {};
  if (!headerConfig.isHeaderHidden) checkString(headerConfig.title);

  // add operation type misc specific panels
  const opType = vizOp.operation_type;
  if (opType === OPERATION_TYPES.VISUALIZE_TABLE) {
    Object.values(instructions.VISUALIZE_TABLE.schemaDisplayOptions || {}).forEach((colConfig) => {
      if (!(isJoinConfigReady(colConfig) && colConfig.joinTable?.id)) return;
      datasetIds.add(colConfig.joinTable.id);
    });
  } else if (
    opType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    if (opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2) {
      checkString(String(instructions.V2_KPI?.valueFormat?.progressGoal || ''));
    }
    checkString(instructions.V2_KPI?.generalFormat?.subtitle);
  } else if (opType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    checkString(instructions.V2_KPI_TREND?.textOnlyFormat?.subtitle);
  }
  if (V2_CHART_GOAL_LINE_OPERATIONS.has(opType)) {
    addGoalLineDatasets(datasetIds, datasetsByName, instructions.V2_TWO_DIMENSION_CHART);
  } else if (opType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    addGoalLineDatasets(datasetIds, datasetsByName, instructions.V2_BOX_PLOT);
  } else if (opType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    addGoalLineDatasets(datasetIds, datasetsByName, instructions.V2_SCATTER_PLOT);
  }

  if (V2_COLOR_ZONE_OPERATIONS.has(opType)) {
    instructions.V2_TWO_DIMENSION_CHART?.colorFormat?.colorZones?.forEach((zone) =>
      checkString(zone.zoneThreshold),
    );
  }

  if (
    isPDFDownload &&
    (opType === OPERATION_TYPES.VISUALIZE_TABLE ||
      opType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER)
  ) {
    const pdfFormat = generalFormatOptions?.export?.pdfFormat;

    if (pdfFormat?.headerEnabled) {
      if (pdfFormat.centerOption === SECTION_OPTIONS.TEXT) checkString(pdfFormat.centerContent);
      if (pdfFormat.leftOption === SECTION_OPTIONS.TEXT) checkString(pdfFormat.leftContent);
      if (pdfFormat.rightOption === SECTION_OPTIONS.TEXT) checkString(pdfFormat.rightContent);
    }
  }

  return Array.from(datasetIds);
};

const addGoalLineDatasets = (
  datasetIds: Set<string>,
  datasetsByName: Record<string, ResourceDataset> | undefined,
  goalLineConfig?: GoalLineChartConfig,
) => {
  if (!datasetsByName) return;
  goalLineConfig?.goalLines?.forEach((goalLine) =>
    getQueryTablesReferencedByText(goalLine.goalValue, datasetsByName)
      .concat(getQueryTablesReferencedByText(goalLine.goalValueMax, datasetsByName))
      .forEach((id) => datasetIds.add(id)),
  );
};

export const getDefaultVariablesFromDashElements = (
  elems: DashboardElement[],
  timezone: string,
  variablesDefaultValues?: DashboardVariableMap,
) => {
  const variables: Record<string, DashboardVariable> = {};

  elems.forEach((elem) => {
    if (SELECT_ELEMENT_SET.has(elem.element_type)) {
      const { valuesConfig } = elem.config as SelectElemConfig;
      if (valuesConfig.valuesSource === INPUT_TYPE.MANUAL && valuesConfig.manualDefaultValue) {
        try {
          const isMultiSelect = elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT;

          const manualValues: DashboardVariable[] = JSON.parse(valuesConfig.manualValues);
          const manualDisplayValues: DashboardVariable[] = JSON.parse(valuesConfig.manualDisplays);

          const valueOverride = variablesDefaultValues?.[elem.name];
          if (valueOverride === undefined) {
            const defaultValue = isMultiSelect
              ? JSON.parse(valuesConfig.manualDefaultValue as string)
              : valuesConfig.manualDefaultValue;

            if (defaultValue === undefined) return;

            variables[elem.name] = defaultValue;
            if (isMultiSelect) {
              variables[getLengthVarName(elem.name)] = (defaultValue as string[] | number[]).length;
            } else {
              const valueIdx = manualValues.findIndex((val) => val === defaultValue);
              if (valueIdx < 0) return;
              variables[getDisplayVarName(elem.name)] = manualDisplayValues[valueIdx];
            }
            return;
          }

          const overrideIdx = manualValues.findIndex((val) => val === valueOverride);
          if (overrideIdx < 0) {
            console.error(
              `Invalid value ${valueOverride} passed for variable ${elem.name}. Ensure that the type of the input (e.g. number) matches the type of the values in the editor.`,
            );
          } else {
            variables[elem.name] = valueOverride;
            if (isMultiSelect) {
              variables[getLengthVarName(elem.name)] = (
                valueOverride as string[] | number[]
              ).length;
            } else {
              variables[getDisplayVarName(elem.name)] = manualDisplayValues[overrideIdx];
            }
          }

          return;
        } catch {
          return;
        }
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH) {
      const config = elem.config as SwitchElementConfig;
      if (config.defaultOn) {
        variables[elem.name] = config.onStatusValue || 'true';
      } else {
        variables[elem.name] = config.offStatusValue || 'false';
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN) {
      const config = elem.config as TimePeriodDropdownElemConfig;
      if (config.defaultValue) {
        // only set the default value if there is an option that represents it in the values config
        const selectedOption = config.values.find((option) => option.value === config.defaultValue);
        if (selectedOption) variables[elem.name] = config.defaultValue;
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER) {
      const config = elem.config as DatepickerElemConfig;
      if (config.defaultType === DEFAULT_DATE_TYPES.EXACT) {
        if (config.defaultValue) variables[elem.name] = config.defaultValue;
      } else if (config.relativeDefaultValue) {
        variables[elem.name] = getDefaultRelativeValue(config.relativeDefaultValue, timezone);
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
      const config = elem.config as DateRangePickerElemConfig;
      if (!config.defaultDateRange) return;

      variables[elem.name] = getDefaultRangeValues(
        config.defaultDateRange,
        config.endDateEndOfDay,
        timezone,
      );
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
      const config = elem.config as DateGroupToggleConfig;
      variables[elem.name] = config.defaultGroupingOption || TrendGroupingOptions.MONTHLY;
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.TEXT_INPUT) {
      const config = elem.config as TextInputElemConfig;
      if (config.defaultValue) variables[elem.name] = config.defaultValue;
    }
  });

  return variables;
};

export const initializeDpColorCategoryDropdownVariables = (dps: DataPanel[]) => {
  const variables: Record<string, DashboardVariable> = {};

  dps.forEach((dp) => {
    if (!isChartUsingMultipleColorCategories(dp.visualize_op)) return;
    const twoDInstructions = dp.visualize_op.instructions.V2_TWO_DIMENSION_CHART ?? {};
    variables[dp.provided_id + COLOR_CATEGORY_FILTER_SUFFIX] =
      twoDInstructions.defaultColorGroupingOff
        ? NONE_CATEGORY_COLOR_VALUE
        : twoDInstructions.colorColumnOptions?.[0].column.name;
  });

  return variables;
};
type TooltipInfo = { showTooltip?: boolean; infoTooltipText?: string };

export const resolveTooltipVariables = (
  { showTooltip, infoTooltipText }: TooltipInfo,
  variables: DashboardVariableMap | undefined,
): string | undefined => {
  if (!showTooltip) return;
  const tooltipText = infoTooltipText?.trim() ?? '';
  return tooltipText && variables ? replaceVariablesInString(tooltipText, variables) : tooltipText;
};

/** This is used to replace inputs that previously only expected number inputs
 * but now allow for variables and dataset variables as inputs
 */
export const resolveNumberInputWithVariables = (
  value: string | undefined,
  variables: DashboardVariableMap | undefined,
  datasetNamesToId: Record<string, string> | undefined,
  datasetData: DatasetDataObject | undefined,
) => {
  // Safety check in case something was missed in the migration to change type to string
  if (typeof value === 'number') {
    console.error(
      `resolveNumberInputWithVariables is called with value ${value} that is not a string`,
    );
    return value;
  }

  const trimmedValue = value?.trim();
  if (!trimmedValue) return;

  const processedValue = parseFloat(
    replaceVariablesInString(trimmedValue, variables, datasetNamesToId, datasetData),
  );

  return isNaN(processedValue) ? undefined : processedValue;
};

const VARS_TO_EXCLUDE = new Set(['refresh-minutes', 'userTransformedSchema', 'timezone']);

export function getQueryVariables(
  embedType: EmbeddedDashboardType | undefined,
  updateUrlParams?: boolean,
) {
  if (!shouldUseUrlParams(embedType, updateUrlParams)) return {};

  const rawVars = parse(window.location.href, true).query;
  const queryVariables: DashboardVariableMap = {};

  if (rawVars) {
    Object.keys(rawVars).forEach((key) => {
      if (VARS_TO_EXCLUDE.has(key)) return;

      try {
        const val = rawVars[key];
        if (val) queryVariables[key] = JSON.parse(val);
      } catch (e) {
        return;
      }
    });
  }

  return queryVariables;
}

// Get tooltip text with variables for each column in changeSchemaList
export const getTooltipVariables = (
  changeSchemaList: SchemaChange[],
  variables?: DashboardVariableMap,
) => {
  const columnTooltips: ColumnTooltips = {};
  changeSchemaList.forEach(({ col, showTooltip, tooltipText }) => {
    if (showTooltip && tooltipText) {
      columnTooltips[col] = variables
        ? replaceVariablesInString(tooltipText, variables)
        : tooltipText;
    }
  });
  return columnTooltips;
};

export const getRefreshMinutes = (refreshMinutes: number | undefined) =>
  parseFloat(
    // our docs accidentally specified refresh_minutes as the var name, so support both
    getValueOrDefault('refresh_minutes', getValueOrDefault('refresh-minutes', refreshMinutes)),
  ) || undefined;

export const getValueOrDefault = (searchValue: string, value?: string | number | boolean) => {
  if (value != null) return value;

  const rawVars = parse(window.location.href, true).query;
  const rawVar = rawVars?.[searchValue];
  try {
    if (rawVar != null) return JSON.parse(rawVar);
  } catch {
    if (typeof rawVar === 'string') return rawVar;
  }
};

export const filterHiddenElements = (
  elements: Record<string, DashboardElement> | undefined,
  hiddenElements: Set<string>,
): DashboardElement[] => {
  const elementList = Object.values(elements ?? {});

  return hiddenElements.size
    ? elementList.filter((elem) => !hiddenElements.has(elem.name))
    : elementList;
};

export const filterHiddenPanels = (
  dataPanels: Record<string, DataPanel> | undefined,
  hiddenElements: Set<string>,
): Record<string, DataPanel> => {
  if (!dataPanels || !hiddenElements.size) return dataPanels ?? {};
  const visiblePanels: Record<string, DataPanel> = {};
  Object.keys(dataPanels).forEach((dpId) => {
    const dp = dataPanels[dpId];
    if (hiddenElements.has(dp.provided_id)) return;
    visiblePanels[dpId] = dp;
  });
  return visiblePanels;
};

export const isElementHidden = (variable: DashboardVariable): boolean => {
  if (variable === undefined) return false;
  variable = String(variable);
  return variable.toLowerCase() === 'true';
};

// Since we also get from iframe just have an exhaustive check of different
// ways user could pass in truthy values
export const isVariableTrue = (variable: DashboardVariable): boolean => {
  if (!variable) return false;
  if (typeof variable === 'boolean' && variable === true) return true;
  if (typeof variable !== 'string') return false;

  return String(variable).toLowerCase() === 'true';
};

import { v4 as uuidv4 } from 'uuid';
import { uniq } from 'utils/standard';

import { Aggregation, OPERATION_TYPES, SortInfo } from 'constants/types';
import { FilterOperator } from 'types/filterOperations';
import {
  CustomerReportAgg,
  CustomerReportGroupBy,
  CustomerReportView,
} from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { DATE_TYPES } from 'constants/dataConstants';
import { AIView } from 'reportBuilderContent/apiTypes';
import { getSchemaColFromAgg, getSchemaColFromGroupBy } from 'utils/V2ColUtils';
import { BaseCol } from 'types/columnTypes';

/**
 * Give a column, gets the correct name and type if it were to be used as a filter or sort
 * If the column is an aggregation, the name will be the aggregation name. i.e. book_rate_max
 *
 * @param column
 * @param aggregations
 * @param groupBys
 * @param columnGroupBys
 * @param dataset
 */
const getFilterColData = (
  column: BaseCol,
  aggregations: CustomerReportAgg[],
  groupBys: CustomerReportGroupBy[],
  columnGroupBys: CustomerReportGroupBy[],
  { columnConfigs }: ReportBuilderDataset,
) => {
  const findInArray = <T extends { column: BaseCol }>(col: BaseCol, arr: T[]) =>
    arr.find((item) => item.column.name === col.name && item.column.type === col.type);

  const agg = findInArray(column, aggregations);
  if (agg) {
    const datasetAgg = getSchemaColFromAgg(columnConfigs, {}, agg);
    return { name: datasetAgg.name, type: datasetAgg.type, isPostFilter: true };
  }

  const groupBy = findInArray(column, groupBys);
  if (groupBy) {
    const datasetGroupBy = getSchemaColFromGroupBy(columnConfigs, {}, groupBy);
    return { name: datasetGroupBy.name, type: datasetGroupBy.type, isPostFilter: false };
  }

  const colGroupBy = findInArray(column, columnGroupBys);
  if (colGroupBy) {
    const datasetColGroupBy = getSchemaColFromGroupBy(columnConfigs, {}, colGroupBy);
    return { name: datasetColGroupBy.name, type: datasetColGroupBy.type, isPostFilter: false };
  }

  return { ...column, isPostFilter: false };
};

const getArrayFromOutput = <T>(output: T[]): T[] => (Array.isArray(output) ? output : []);

export const aiOutputToView = (
  name: string,
  chart: AIView,
  dataset: ReportBuilderDataset,
): CustomerReportView | undefined => {
  const schema = dataset.schema;
  if (!schema) return;

  const datasetColNames = schema.map((col) => col.name);

  const groupBys = getArrayFromOutput(chart.groupBys)
    .map((groupBy) => {
      return {
        column: groupBy.column,
        bucket: DATE_TYPES.has(groupBy.column.type) ? groupBy.groupByType : undefined, // Bucketing only allowed for dates
      };
    })
    .filter((groupBy) => datasetColNames.includes(groupBy.column.name));

  const aggregations = getArrayFromOutput(chart.aggregations)
    .map((agg) => ({
      column: agg.column,
      agg: { id: agg.aggregationType },
    }))
    .filter(
      (agg) => aggregationValues.has(agg.agg.id) && datasetColNames.includes(agg.column.name),
    );

  const columnGroupBys = getArrayFromOutput(chart.columnGroupBys)
    .map((columnGroupBy) => ({
      column: columnGroupBy.column,
    }))
    .filter((columnGroupBy) => datasetColNames.includes(columnGroupBy.column.name));

  const filters = getArrayFromOutput(chart.filters)
    .map((filter, i) => {
      // Filter column names needs to have the right suffix after aggregating. i.e. book_rate_max
      const { name, type, isPostFilter } = getFilterColData(
        filter.column,
        aggregations,
        groupBys,
        columnGroupBys,
        dataset,
      );
      return {
        id: i,
        isPostFilter,
        filterColumn: { name, type },
        filterOperation: { id: filter.operator },
        filterValue: filter.value,
      };
    })
    .filter(
      (filter) => filter.filterColumn.type && filterOperatorValues.has(filter.filterOperation.id),
    );

  const isTable = chart.chartType === 'table';

  // Sorting is only applicable to tables
  const sort: SortInfo[] | undefined = isTable
    ? getArrayFromOutput(chart.sort).map(({ order, column }) => {
        // Sort column names needs to have the right suffix after aggregating. i.e. book_rate_max
        // (groupBys are rows and sorting is disabled when using columnGroupBys so sorting by them won't do anything)
        const { name } = getFilterColData(column, aggregations, groupBys, columnGroupBys, dataset);
        return {
          order,
          column: { name },
        };
      })
    : undefined;

  // Column order determines what columns from the original dataset to render and what order to render them in
  // Column order is only applicable to tables because all other chart types have aggregations and groupBys
  const columnOrder = isTable
    ? uniq([
        ...datasetColNames,
        ...(sort || []).map(({ column }) => column.name), // Ensure sort columns are included
        ...filters.map(({ filterColumn }) => filterColumn.name), // Filters are not needed, but nice to show
      ])
    : datasetColNames;

  const columnSet = new Set(getArrayFromOutput(chart.columns));
  const hiddenColumns = datasetColNames.filter((col) => !columnSet.has(col));

  return {
    id: uuidv4(),
    name,
    columnOrder,
    hiddenColumns: hiddenColumns,
    visualization: chartTypeToOperationType[chart.chartType],
    filters,
    sort,
    groupBys,
    aggregations,
    columnGroupBys,
  };
};
const chartTypeToOperationType = {
  table: OPERATION_TYPES.VISUALIZE_TABLE,
  bar: OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  line: OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  pie: OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  number: OPERATION_TYPES.VISUALIZE_NUMBER_V2,
} as const;

const aggregationValues = new Set(Object.values(Aggregation));
const filterOperatorValues = new Set(Object.values(FilterOperator));

import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';

import { SettingHeader } from 'components/SettingHeader';
import DroppableColumnSection from './droppable/DroppableColumnSection';

import { Aggregation, DisplayOptions, OPERATION_TYPES } from 'constants/types';
import { DatasetColumn, DatasetSchema } from 'types/datasets';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { showWarningToast } from 'shared/sharedToasts';
import { PivotAgg } from 'types/dateRangeTypes';
import { findMatchingAgg, findMatchingAggColIdx } from 'utils/aggUtils';
import * as utils from 'utils/V2ColUtils';
import { AggregationType } from 'types/columnTypes';

type Props = {
  instructions: V2PivotTableInstructions;
  loading: boolean;
  schema: DatasetSchema;
};

const opType = OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2;

export const V2PivotTableConfig: FC<Props> = ({ instructions, schema, loading }) => {
  const dispatch = useDispatch();

  const selectedGroupBys = useMemo(() => {
    const selectedSet = new Set<string>();
    instructions.colGroupBys.forEach((groupBy) => selectedSet.add(groupBy.column.name));
    instructions.rowGroupBys.forEach((groupBy) => selectedSet.add(groupBy.column.name));
    return selectedSet;
  }, [instructions.colGroupBys, instructions.rowGroupBys]);

  const updateInstructions = (
    updateFunc: (draft: WritableDraft<V2PivotTableInstructions>) => void,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      updateFunc(draft);
    });
    dispatch(updateVisualizeOperation(newInstructions, opType));
  };

  const handleAddedGroupBy = (
    col: DatasetColumn,
    oldColName: string | undefined,
    isColGroupBy: boolean,
  ) => {
    if (selectedGroupBys.has(col.name)) {
      return showWarningToast(`Column ${col.name} is already selected`);
    }

    updateInstructions((draft) => {
      // Since only one is allowed for col group bys for now, just set to empty before adding
      if (isColGroupBy) draft.colGroupBys = [];
      const groupBys = isColGroupBy ? draft.colGroupBys : draft.rowGroupBys;

      const newGroupBy = utils.createGroupByCol(col);
      if (oldColName === undefined || isColGroupBy) {
        groupBys.push(newGroupBy);
      } else {
        const indexToReplace = groupBys.findIndex((col) => col.column.name === oldColName);
        if (indexToReplace !== -1) groupBys.splice(indexToReplace, 1, newGroupBy);
      }

      handleColumnConfigUpdate(draft, {
        newColName: utils.getGroupByName(newGroupBy),
        newDisplay: utils.getGroupByDisplay(newGroupBy),
      });
    });
  };

  const handleRemoveGroupBy = (colName: string | undefined, isColGroupBy: boolean) => {
    if (!colName) return;
    updateInstructions((draft) => {
      const groupBys = isColGroupBy ? draft.colGroupBys : draft.rowGroupBys;

      const groupByIdx = groupBys.findIndex(({ column }) => column.name === colName);
      if (groupByIdx !== -1) groupBys.splice(groupByIdx, 1);
    });
  };

  const handleChangeGroupBy = (
    option: { id: string },
    colName: string | undefined,
    isColGroupBy: boolean,
  ) => {
    if (!colName) return;
    updateInstructions((draft) => {
      const groupBys = isColGroupBy ? draft.colGroupBys : draft.rowGroupBys;

      const col = groupBys.find(({ column }) => column.name === colName);
      if (!col) return;

      const oldColName = utils.getGroupByName(col);
      col.bucket = { id: option.id as PivotAgg };

      handleColumnConfigUpdate(draft, {
        oldColName,
        newColName: utils.getGroupByName(col),
        newDisplay: utils.getGroupByDisplay(col),
      });
    });
  };

  return (
    <div>
      <SettingHeader name="Rows" />
      <DroppableColumnSection
        required
        columns={instructions.rowGroupBys}
        disableEdits={loading}
        onColAdded={(col, oldColName) => handleAddedGroupBy(col, oldColName, false)}
        onColOptionChanged={(option, colName) => handleChangeGroupBy(option, colName, false)}
        onRemoveCol={({ name: colName }) => handleRemoveGroupBy(colName, false)}
        schema={schema}
      />
      <SettingHeader
        btnProps={{
          icon: 'function',
          tooltipText: 'Click to add a custom formula aggregation',
          onClick: () =>
            updateInstructions((draft) => {
              const hasEmptyFormula = draft.aggregations.some(
                ({ agg }) => agg.id === Aggregation.FORMULA && !agg.formula?.trim(),
              );
              if (hasEmptyFormula) return;
              const newAgg = utils.getFormulaAgg();
              draft.aggregations.push(newAgg);

              handleColumnConfigUpdate(draft, {
                newColName: utils.getAggColName(newAgg),
                newDisplay: 'Custom',
              });
            }),
        }}
        name="Values"
      />
      <DroppableColumnSection
        columns={instructions.aggregations}
        disableEdits={loading}
        onColAdded={(col, oldColName, oldColAggType) => {
          const newAgg = utils.getNewAggCol(col, instructions.aggregations);

          if (!newAgg) {
            return showWarningToast(`There are no more ways to aggregate ${col.name}`);
          }

          updateInstructions((draft) => {
            if (oldColName === undefined) {
              draft.aggregations.push(newAgg);
            } else {
              const indexToReplace = findMatchingAggColIdx(
                instructions.aggregations,
                oldColName,
                oldColAggType,
              );
              if (indexToReplace === -1) return;

              draft.aggregations.splice(indexToReplace, 1, newAgg);
            }

            handleColumnConfigUpdate(draft, {
              newColName: utils.getAggColName(newAgg),
              newDisplay: utils.getAggColDisplay(newAgg),
              displayFormatting: { decimalPlaces: 2 },
            });
          });
        }}
        onColOptionChanged={(option, colName, aggType) =>
          updateInstructions((draft) => {
            const newAgg = option as AggregationType;

            if (findMatchingAgg(draft.aggregations, colName, newAgg)) {
              if (newAgg.id === aggType?.id) return;
              return showWarningToast(
                'The selected aggregation is already present for this column. Duplicates are not allowed.',
              );
            }

            const colIdx = findMatchingAggColIdx(draft.aggregations, colName, aggType);
            if (colIdx === -1) return;
            const oldAggColName = utils.getAggColName(draft.aggregations[colIdx]);
            draft.aggregations[colIdx].agg = { id: newAgg.id, formula: newAgg.formula };
            if (aggType?.id === Aggregation.FORMULA) return;

            handleColumnConfigUpdate(draft, {
              oldColName: oldAggColName,
              newColName: utils.getAggColName(draft.aggregations[colIdx]),
              newDisplay: utils.getAggColDisplay(draft.aggregations[colIdx]),
            });
          })
        }
        onRemoveCol={(col, aggType) =>
          updateInstructions((draft) => {
            const aggColIdx = findMatchingAggColIdx(draft.aggregations, col.name, aggType);
            if (aggColIdx !== -1) draft.aggregations.splice(aggColIdx, 1);
          })
        }
        schema={schema}
      />
      <SettingHeader name="Columns" />
      <DroppableColumnSection
        columns={instructions.colGroupBys}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col, oldColName) => handleAddedGroupBy(col, oldColName, true)}
        onColOptionChanged={(option, colName) => handleChangeGroupBy(option, colName, true)}
        onRemoveCol={({ name: colName }) => handleRemoveGroupBy(colName, true)}
        schema={schema}
      />
    </div>
  );
};

type HandleColConfigUpdate = {
  oldColName?: string;
  newColName: string;
  newDisplay: string;
  displayFormatting?: DisplayOptions;
};

const handleColumnConfigUpdate = (
  draft: WritableDraft<V2PivotTableInstructions>,
  { oldColName, newColName, newDisplay, displayFormatting }: HandleColConfigUpdate,
): void => {
  // Adding column
  if (!oldColName) {
    draft.columnConfigs[newColName] = { name: newDisplay, displayFormatting };
    // Replacing column
  } else {
    draft.columnConfigs[newColName] = { ...draft.columnConfigs[oldColName], name: newDisplay };
    if (oldColName in draft.columnConfigs) delete draft.columnConfigs[oldColName];
  }
};

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { groupBy } from 'utils/standard';

import { DroppableComponentItem } from 'pages/dashboardPage/droppableComponentItem';
import { SettingHeader } from 'components/SettingHeader';
import { LayoutSelectionSection } from './LayoutSelectionSection';
import * as styles from './styles.css';

import { DROPPABLE_DASHBOARD_ELEMENT_CONFIGS, ELEMENT_GROUPS } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import { OPERATION_TYPES } from 'constants/types';

const GROUPED_ELEMS = groupBy(DROPPABLE_DASHBOARD_ELEMENT_CONFIGS, 'group');
const GROUP_ORDER = [
  ELEMENT_GROUPS.CHARTS,
  ELEMENT_GROUPS.CONTROLS,
  ELEMENT_GROUPS.EXPORT,
  ELEMENT_GROUPS.DISPLAY,
];

export const EditDashboardPanel: FC = () => {
  const team = useSelector((state: ReduxState) => state.currentUser.team);

  const isStickyHeaderEnabled = !!team?.feature_flags?.enable_dashboard_sticky_header;
  const isEditableSectionEnabled = !!team?.entitlements?.enable_editable_section;
  const isNewPivotTableEnabled = !!team?.feature_flags.enable_new_pivot_table;

  return (
    <>
      <LayoutSelectionSection
        isEditableSectionEnabled={isEditableSectionEnabled}
        isStickyHeaderEnabled={isStickyHeaderEnabled}
      />
      {GROUP_ORDER.map((group) => (
        <div key={`elem-drop-group-${group}`}>
          <SettingHeader name={group} />
          <div className={styles.droppableElementContainer}>
            {GROUPED_ELEMS[group].map((config) => {
              // New Pivot table replaces old and collapsible list
              if (config.name === 'Collapsible List' && isNewPivotTableEnabled) return null;
              if (config.name === 'Pivot Table') {
                const isNewPivot = config.id.includes(OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2);
                if (
                  (isNewPivot && !isNewPivotTableEnabled) ||
                  (!isNewPivot && isNewPivotTableEnabled)
                )
                  return null;
              }
              return (
                <DroppableComponentItem
                  elementType={config.id}
                  icon={config.icon}
                  key={`droppable-dash-elem-${config.id}`}
                  name={config.name}
                />
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

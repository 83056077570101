import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { Switch, Input } from 'components/ds';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ScatterPlotFormat, OPERATION_TYPES, V2ScatterPlotInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2ScatterPlotInstructions;
};

export const ScatterPlotConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const { scatterPlotFormat } = instructions;
  const dispatch = useDispatch();

  const updateScatterPlotFormat = (scatterPlotFormat: ScatterPlotFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.scatterPlotFormat = {
        ...draft.scatterPlotFormat,
        ...scatterPlotFormat,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(scatterPlotFormat?.radius ?? 4)}
        label="Marker Radius"
        onSubmit={(newValue) => {
          const newInt = parseInt(newValue);
          updateScatterPlotFormat({ radius: newInt > 0 ? newInt : undefined });
        }}
      />
      {!instructions.groupingColumn ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={scatterPlotFormat?.seriesLabel ?? 'Series 1'}
          label="Label"
          onSubmit={(newValue) => updateScatterPlotFormat({ seriesLabel: newValue })}
        />
      ) : undefined}
      <Switch
        className={configInputClass}
        label="Use Jitter to Spread Points"
        onChange={() => updateScatterPlotFormat({ useJitter: !scatterPlotFormat?.useJitter })}
        switchOn={scatterPlotFormat?.useJitter}
      />
    </div>
  );
};

import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, sprinkles } from 'components/ds';
import { showSuccessToast } from 'shared/sharedToasts';
import { TextElementConfigPanel } from '../TextElementConfigPanel';
import { ExportElementConfigPanel } from '../ExportElementConfigPanel';
import { IframeElementConfigPanel } from '../IframeElementConfigPanel';
import { ContainerElementConfigPanel } from '../ContainerElementConfigPanel';
import { ImageElementConfigPanel } from '../ImageElementConfigPanel';
import { FilterConfigPanel } from '../FilterConfigPanel';
import { RenameElementModal } from './RenameElementModal';
import * as styles from './index.css';

import {
  ApplyFilterElemConfig,
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  ExportElemConfig,
  IframeElemConfig,
  ImageElemConfig,
  TextDashboardElemConfig,
  DashboardElement,
  DashboardButtonElemConfig,
} from 'types/dashboardTypes';
import { duplicateDashboardItem, updateElementConfig } from 'actions/dashboardV2Actions';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { ApplyFilterButtonConfigPanel } from '../ApplyFilterButtonConfigPanel';
import { RefreshButtonConfigPanel } from '../RefreshButtonConfigPanel';
import { setDeleteSelectedItemModalOpen } from 'reducers/dashboardInteractionsReducer';

type Props = {
  dashboardId: number;
  shareLinkTitle: string | null;
  element: DashboardElement;
};

export const ElementConfigPanel: FC<Props> = ({ element, dashboardId, shareLinkTitle }) => {
  const dispatch = useDispatch();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [renameElementModalOpen, setRenameElementModalOpen] = useState(false);

  const updateConfig = useCallback(
    (config: DashboardElementConfig) => {
      dispatch(updateElementConfig({ elementId: element.id, config }));
    },
    [dispatch, element.id],
  );

  const renderNonFilterConfig = () => {
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <TextElementConfigPanel
            config={element.config as TextDashboardElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <ExportElementConfigPanel
            config={element.config as ExportElemConfig}
            dashboardId={dashboardId}
            dashboardShareLinkTitle={shareLinkTitle}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IFRAME:
        return (
          <IframeElementConfigPanel
            config={element.config as IframeElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return <ContainerElementConfigPanel containerId={element.id} />;
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <ImageElementConfigPanel
            config={element.config as ImageElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        return (
          <ApplyFilterButtonConfigPanel
            config={element.config as ApplyFilterElemConfig}
            elementId={element.id}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON:
        return (
          <RefreshButtonConfigPanel
            config={element.config as DashboardButtonElemConfig}
            updateConfig={updateConfig}
          />
        );
    }
    return null;
  };

  const renderConfigurationMenu = () => {
    if (FILTER_ELEMENTS.has(element.element_type)) {
      return (
        <div className={styles.filterConfigMenu}>
          <FilterConfigPanel element={element} updateConfig={updateConfig} />
        </div>
      );
    }

    return <div className={styles.configurationMenu}>{renderNonFilterConfig()}</div>;
  };

  return (
    <>
      <div className={styles.configurationHeader}>
        <div className={sprinkles({ fontWeight: 700, flex: 1, truncateText: 'ellipsis' })}>
          {element.name}
        </div>
        <IconButton name="edit" onClick={() => setRenameElementModalOpen(true)} />
        <IconButton
          disabled={isDuplicating}
          name={isDuplicating ? 'tick' : 'copy'}
          onClick={() => {
            setIsDuplicating(true);
            dispatch(
              duplicateDashboardItem({
                dashboardItem: element,
                itemType: element.element_type,
                dashId: dashboardId,
              }),
            );

            showSuccessToast(`${element.name} has been duplicated below`, 3, () =>
              setIsDuplicating(false),
            );
          }}
        />
        <IconButton name="trash" onClick={() => dispatch(setDeleteSelectedItemModalOpen(true))} />
      </div>
      {renderConfigurationMenu()}
      {renameElementModalOpen ? (
        <RenameElementModal element={element} onClose={() => setRenameElementModalOpen(false)} />
      ) : null}
    </>
  );
};

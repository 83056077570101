import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import { LineChart } from 'pages/dashboardPage/charts/lineChart';

import { OPERATION_TYPES } from 'constants/types';
import { CustomerReportAgg, CustomerReportGroupBy } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import {
  customerReportToSharedInstructions,
  cloneRowsIfDate,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import { getCurrentColorTracker } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DatasetColumn } from 'types/datasets';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';

type Props = {
  groupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  reportData: ReportData;
  schema: DatasetColumn[];
  columnConfigs?: ReportBuilderColConfigs;
  onSelect?: (chartInfo: ChartMenuInfo | null) => void;
};

export const ReportLineChart: FC<Props> = ({
  aggregations,
  reportData: { isLoading, rows },
  groupBys,
  schema,
  columnConfigs,
  onSelect,
}) => {
  const { variables, globalStyleConfig, colorCategoryTracker } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
      colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () => customerReportToSharedInstructions(aggregations, groupBys, columnConfigs),
    [aggregations, groupBys, columnConfigs],
  );

  // LineChart mutates previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const previewData = useMemo(() => cloneRowsIfDate(groupBys, rows), [groupBys, rows]);

  const missingText = [
    ...(!aggregations?.length ? ['Y-Axis Values'] : []),
    ...(!groupBys?.length ? ['X-Axis Grouping'] : []),
  ];
  if (missingText.length)
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_LINE_CHART_V2}>
        Missing {missingText.join(' and ')}
      </InvalidData>
    );

  return (
    <LineChart
      backgroundColor=""
      colorTracker={colorCategoryTracker}
      dataPanelTemplateId=""
      datasetData={{}}
      datasetNamesToId={{}}
      generalOptions={{ enableRawDataDrilldown: !!onSelect }}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      loading={isLoading}
      operationType={OPERATION_TYPES.VISUALIZE_LINE_CHART_V2}
      previewData={previewData}
      schema={schema}
      setChartMenu={onSelect || noOp}
      variables={variables}
    />
  );
};

const noOp = () => null;

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { useRouteMatch } from 'react-router';
import { usePrevious } from '@radix-ui/react-use-previous';

import { ErrorState } from 'components/ErrorState';
import { EditDashboardPage } from 'pages/dashboardPage/editDashboardPage';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { withWidth, WithWidthProps } from 'components/HOCs/withWidth';

import { fetchDashboardVersions } from 'actions/dashboardV2Actions';
import { fetchDashboard, fetchDashboardList } from 'actions/dashboardActions';
import { ACTION } from 'actions/types';
import { fetchUserTeam } from 'actions/teamActions';

import { createLoadingSelector } from 'reducers/api/selectors';
import { setExploreInteractionsInfo } from 'reducers/dashboardInteractionsReducer';
import { pageView } from 'analytics/exploAnalytics';
import { VIEW_MODE } from 'types/dashboardTypes';
import { useLoadEditMetadata } from 'utils/hookUtils';

type Props = Pick<WithWidthProps, 'width'>;

type MatchParams = {
  dashboardId: string;
};

const DashboardPageBase: FC<Props> = ({ width }) => {
  const dispatch = useDispatch();

  const [initialViewIds, setInitialViewIds] = useState<string[]>();

  const {
    dashboardLoading,
    currentDashboardId,
    dashboardList,
    currentUser,
    dashboardLoadingError,
    dashboardConfigLoaded,
    dashboardVersionInfo,
    inEditMode,
    teamData,
    viewMode,
  } = useSelector(
    (state: ReduxState) => ({
      dashboardLoading: createLoadingSelector(
        [ACTION.FETCH_DASHBOARD, ACTION.LIST_TEAM_DATA_SOURCES],
        false,
      )(state),
      currentDashboardId: state.dashboard.currentDashboardId,
      dashboardList: state.dashboard.dashboardList,
      currentUser: state.currentUser,
      dashboardLoadingError: state.dashboard.error,
      dashboardConfigLoaded: !!state.dashboardEditConfig.config,
      dashboardVersionInfo: state.dashboardEditConfig.versionInfo,
      teamData: state.teamData.data,
      inEditMode: state.dashboardInteractions.interactionsInfo.isEditing,
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
    }),
    shallowEqual,
  );

  const {
    params: { dashboardId },
  } = useRouteMatch<MatchParams>();

  const updateEditModeUrlHash = useCallback(
    (newViewMode?: VIEW_MODE) => {
      switch (newViewMode ?? viewMode) {
        case VIEW_MODE.PDF:
          window.location.hash = '#edit_pdf';
          break;
        case VIEW_MODE.EMAIL:
          window.location.hash = '#edit_email';
          break;
        case VIEW_MODE.MOBILE:
          window.location.hash = '#edit_mobile';
          break;
        default:
          window.location.hash = '#edit';
      }
    },
    [viewMode],
  );

  const metadataLoading = useLoadEditMetadata(initialViewIds);

  useEffect(() => {
    const hash = window.location.hash;

    dispatch(
      fetchDashboard({ id: dashboardId }, (data) => {
        document.title = `Explo | ${data.dashboard_template_name}`;

        if (hash === '#edit' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.DEFAULT }));
        } else if (hash === '#edit_pdf' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.PDF }));
        } else if (hash === '#edit_email' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.EMAIL }));
        } else if (hash === '#edit_mobile' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.MOBILE }));
        } else {
          window.location.hash = '';
        }

        setInitialViewIds(
          Object.values(data.dashboard_version.configuration.datasets ?? {}).map(
            (dataset) => dataset.fido_id ?? '',
          ),
        );
      }),
    );

    dispatch(fetchDashboardVersions({ id: dashboardId }));

    pageView('Dashboard');
  }, [currentUser.team?.id, dashboardId, dispatch]);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  useEffect(() => {
    if (!dashboardList) dispatch(fetchDashboardList({ id: currentUser.team?.id }));
  }, [dashboardList, dispatch, currentUser.team?.id]);

  const prevInEditMode = usePrevious(inEditMode);
  const prevViewMode = usePrevious(viewMode);

  useEffect(() => {
    if (prevInEditMode !== inEditMode) {
      if (inEditMode) updateEditModeUrlHash(viewMode);
      else window.location.hash = '';
      return;
    }
    if (prevViewMode === viewMode || !inEditMode) return;
    updateEditModeUrlHash(viewMode);
  }, [inEditMode, viewMode, updateEditModeUrlHash, prevInEditMode, prevViewMode]);

  const currentDashboard = useMemo(() => {
    return currentDashboardId !== undefined
      ? dashboardList?.find((dash) => dash.id === currentDashboardId)
      : undefined;
  }, [dashboardList, currentDashboardId]);

  useEffect(() => {
    dispatch(
      setExploreInteractionsInfo({
        disableFiltersWhileLoading: currentDashboard?.disable_filters_while_loading,
        supportEmail: teamData?.support_email ?? undefined,
      }),
    );
  }, [dispatch, currentDashboard?.disable_filters_while_loading, teamData?.support_email]);

  if (dashboardLoadingError) return <ErrorState text={dashboardLoadingError.detail} />;

  if (
    dashboardLoading ||
    !currentDashboard ||
    !dashboardVersionInfo ||
    metadataLoading ||
    !dashboardConfigLoaded
  )
    return <ExploLoadingSpinner />;

  return (
    <EditDashboardPage
      dashboard={currentDashboard}
      dashboardVersionInfo={dashboardVersionInfo}
      width={width}
    />
  );
};

export const DashboardPage = withWidth(DashboardPageBase);

import { isInteger, isNumber } from 'utils/standard';

import { BOOLEAN, FLOAT, INTEGER_DATA_TYPE, STRING } from 'constants/dataConstants';
import { RESERVED_VARIABLES } from 'constants/variables';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DashboardVariableMap } from 'types/dashboardTypes';

export const isNameValid = (name: string) => name.trim() !== '' && !RESERVED_VARIABLES.has(name);

export const isDefaultValueValid = (type: string, defaultValue: string | undefined) => {
  if (defaultValue === undefined || defaultValue.trim() === '') return true;
  return shouldUseDefaultValue(type, defaultValue);
};

const shouldUseDefaultValue = (type: string, defaultValue: string) => {
  if (defaultValue.trim() === '') return false;

  if (type === BOOLEAN) return defaultValue === 'true' || defaultValue === 'false';
  if (type === INTEGER_DATA_TYPE) return isInteger(parseFloat(defaultValue));
  if (type === FLOAT) return isNumber(parseFloat(defaultValue));
  return type === STRING;
};

export const getDefaultVariablesFromParams = (
  params: Record<string, DashboardParam>,
): DashboardVariableMap => {
  const variables: DashboardVariableMap = {};

  Object.values(params).forEach(({ type, defaultValue, name }) => {
    if (!defaultValue || !shouldUseDefaultValue(type, defaultValue)) return;

    if (type === FLOAT) variables[name] = parseFloat(defaultValue);
    else if (type === INTEGER_DATA_TYPE) variables[name] = parseInt(defaultValue);
    else variables[name] = defaultValue;
  });

  return variables;
};

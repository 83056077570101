const CURRENCY_MAPPING: Record<string, [string, string]> = {
  // euro
  eur: ['', '\u00a0€'],
  // us dollar
  usd: ['$', ''],
  // brazilian real
  brl: ['R$', ''],
  // pakistani rupee
  pkr: ['\u20A8\u00a0', ''],
  // pound sterling
  gbp: ['\u00A3', ''],
  // for some reason when we first built this, we set str to be british sterling.
  // That's not british sterling, it's some cryptocurrency, but now we have to
  // support this mapping in case anyone's using it, so hopefully we never support
  // the cryptocurrency Stellar
  str: ['\u00A3', ''],
  // UAE dirham
  // the space before AED is intentional here
  aed: ['', ' AED'],
  // indonesian rupiah
  idr: ['Rp', ''],
  // indian rupee
  inr: ['\u20B9', ''],
  // candian dollar
  cad: ['CA$', ''],
  // mexican peso
  mxn: ['$', ''],
};

export default CURRENCY_MAPPING;

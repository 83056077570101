import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DataGrid, Icon, sprinkles } from 'components/ds';

import { DATASET_VIEWS } from './dashboardDatasetEditor';
import { DatasetColumn, DatasetSchema } from 'types/datasets';
import { DatasetConfig, DatasetData } from 'actions/datasetActions';
import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { ReduxState } from 'reducers/rootReducer';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { getSortedDrilldownColumns } from 'utils/drilldownDatasetUtils';
import { setSelectedDrilldownColumn } from 'actions/cssLinkActions';
import { QuerySuggestion } from '../DashboardDebugger/QuerySuggestion';

type Props = {
  datasetConfig: DatasetConfig | null;
  datasetData: DatasetData | null;
  datasetSchema: DatasetSchema | null;
  datasetQuery: string | null;
  enableFormat?: boolean;
  fetchDataset: (query: string, pageNumber?: number) => void;
  getTablePreview: () => void;
  openTab: (id: string) => void;
  onRunSuggestion: () => void;
};

/**
 * Component for managing the data preview and format tables for the dataset editor
 *
 * @param {DatasetConfig} datasetConfig The visual config of the dataset
 * @param {DatasetData} datasetData The queried data for the dataset, generated at runtime
 * @param {DatasetSchema} datasetSchema The schema metadata for the dataset
 * @param {datasetQuery} datasetQuery The query for this dataset
 * @param {Record} datasetQueries All dataset queries so we can get the selected query by id
 * @param {boolean} enableFormat Whether or not to enable the format tab options
 * @param {Function} fetchDataset Fetches the dataset from API when changing the page
 * @param {Function} getTablePreview Fetches table preview data from API
 * @param {Function} openTab Switches tabs for the dataset editor
 */
export const DatasetPreview: FC<Props> = ({
  datasetConfig,
  datasetData,
  datasetSchema,
  datasetQuery,
  enableFormat,
  fetchDataset,
  getTablePreview,
  openTab,
  onRunSuggestion,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  const selectedDrilldownColumn = useSelector(
    (state: ReduxState) => state.cssLinks.selectedDrilldownColumn,
  );

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (!datasetConfig || !datasetQuery) return;

      fetchDataset(datasetQuery, pageNumber);
      setPageNumber(pageNumber);
    },
    [datasetConfig, datasetQuery, fetchDataset],
  );

  const paginatorProps: PaginatorProps | undefined = useMemo(() => {
    if (!datasetConfig || !datasetData) return;

    return {
      totalRowCount: datasetData.totalRowCount,
      currentPage: pageNumber,
      isPagingDisabled: isPagingDisabled(datasetData.unsupportedOperations),
      loading: !!datasetData.loading,
      goToPage: ({ page }) => handlePageChange(page),
    };
  }, [datasetConfig, pageNumber, handlePageChange, datasetData]);

  const renderQueryRedirectText = (text: string) => {
    return (
      <span
        className={sprinkles({ color: 'active', cursor: 'pointer' })}
        onClick={() => openTab(DATASET_VIEWS.QUERY)}>
        {text}
      </span>
    );
  };

  if (!datasetConfig) {
    return <DataGrid loading />;
  }

  if (datasetData?.error) {
    return (
      <DatasetEditorNonIdealState
        description={datasetData.error}
        icon={
          <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="lg" />
        }
        primaryContent={renderQueryRedirectText('Revise your query')}
        secondaryContent={
          <QuerySuggestion
            error={datasetData.error}
            onRun={onRunSuggestion}
            query={datasetQuery ?? ''}
          />
        }
        title="There was a problem with your query"
      />
    );
  }

  if (!datasetQuery) {
    return (
      <DatasetEditorNonIdealState
        description="The resulting table will show up here once you write some SQL"
        icon={<Icon name="rectangle-terminal" size="lg" />}
        primaryContent={renderQueryRedirectText('Write a query')}
        title="No query to preview"
      />
    );
  }

  if (!datasetData?.rows) {
    if (datasetData?.loading) {
      return <DataGrid loading />;
    } else {
      // they have not run the preview for the query
      return (
        <DatasetEditorNonIdealState
          description="This will run your query but not save it to the dataset."
          icon={<Icon name="eye-open" size="lg" />}
          primaryContent={
            <Button
              disabled={!datasetQuery || datasetQuery.length === 0}
              onClick={getTablePreview}
              variant="secondary">
              Preview
            </Button>
          }
          title="Click the preview button"
        />
      );
    }
  }

  return (
    <DataGrid
      columnConfigs={enableFormat ? datasetConfig.drilldownColumnConfigs : undefined}
      columnSelectionEnabled={enableFormat}
      loading={datasetData.loading}
      onColumnSelect={(column?: DatasetColumn) => {
        if (!enableFormat) return;
        dispatch(
          setSelectedDrilldownColumn(
            column && column.name !== selectedDrilldownColumn?.name
              ? { name: column.name, type: column.type }
              : undefined,
          ),
        );
      }}
      paginatorProps={paginatorProps}
      rows={datasetData.rows}
      schema={
        enableFormat
          ? getSortedDrilldownColumns(datasetSchema || [], datasetConfig)
          : datasetSchema || []
      }
      selectedColumn={selectedDrilldownColumn?.name}
    />
  );
};

import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { Input, Switch, Toggle, ToggleItem, sprinkles } from 'components/ds';
import { PlotLineSelect } from 'components/PlotLineSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  ChartSpecificFormat,
  LineChartFormat,
  LineElasticity,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  defaultLineWidth: number;
};

export const LineChartConfig: FC<Props> = ({
  visualizationType,
  instructions,
  defaultLineWidth,
}) => {
  const dispatch = useDispatch();

  const isAreaChart =
    visualizationType === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
    visualizationType === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2;
  const isComboChart = visualizationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2;

  const { chartSpecificFormat } = instructions;
  const { lineChart, timeSeriesDataFormat } = chartSpecificFormat ?? {};

  const selectedElasticity = lineChart?.elasticity || LineElasticity.CURVED;

  const updateChartSpecificFormat = (specificUpdates: ChartSpecificFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        ...specificUpdates,
      };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const updateLineChartInstructions = (lineChartFormatUpdates: LineChartFormat) => {
    const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
      draft.lineChart = { ...draft.lineChart, ...lineChartFormatUpdates };
    });
    updateChartSpecificFormat(newChartSpecificFormat);
  };

  return (
    <div className={sprinkles({ padding: 'sp1.5', flexItems: 'column', gap: 'sp1.5' })}>
      <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
        <Toggle
          className={sprinkles({ flex: 1 })}
          label="Line Elasticity"
          onValueChange={(value) =>
            updateLineChartInstructions({ elasticity: value as LineElasticity })
          }
          selectedValue={selectedElasticity}>
          {Object.values(LineElasticity).map((elasticity) => (
            <ToggleItem key={elasticity} value={elasticity} />
          ))}
        </Toggle>
        <Input
          showInputButton
          className={sprinkles({ flex: 1 })}
          defaultValue={String(lineChart?.lineWidth ?? defaultLineWidth)}
          label="Line Width"
          onSubmit={(newValue) => {
            const newInt = parseInt(newValue);
            updateLineChartInstructions({ lineWidth: newInt >= 0 ? newInt : undefined });
          }}
        />
      </div>
      {isAreaChart ? null : (
        <PlotLineSelect
          currentType={lineChart?.lineType}
          onChange={(lineType) => updateLineChartInstructions({ lineType })}
        />
      )}
      <Switch
        label="Show Markers"
        onChange={() => updateLineChartInstructions({ hideMarkers: !lineChart?.hideMarkers })}
        switchOn={!lineChart?.hideMarkers}
      />

      {isComboChart ? null : (
        <>
          <Switch
            label="Fill in missing dates with 0s"
            onChange={() => {
              const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
                draft.timeSeriesDataFormat = {
                  ...draft.timeSeriesDataFormat,
                  zeroMissingDates: !timeSeriesDataFormat?.zeroMissingDates,
                };
              });
              updateChartSpecificFormat(newChartSpecificFormat);
            }}
            switchOn={timeSeriesDataFormat?.zeroMissingDates}
          />
          <Switch
            label="Fill in missing values with 0s"
            onChange={() => {
              const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
                draft.timeSeriesDataFormat = {
                  ...draft.timeSeriesDataFormat,
                  zeroMissingValues: !timeSeriesDataFormat?.zeroMissingValues,
                };
              });
              updateChartSpecificFormat(newChartSpecificFormat);
            }}
            switchOn={timeSeriesDataFormat?.zeroMissingValues}
          />
        </>
      )}
    </div>
  );
};

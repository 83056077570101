import { Component, createRef, RefObject } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import cx from 'classnames';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import GridLayout, {
  Responsive as BaseResponsiveGridLayout,
  WidthProvider,
  Layout,
  ReactGridLayoutProps,
} from '@explo-tech/react-grid-layout';

import { DataPanelWrapper } from './DataPanelWrapper';
import { ElementWrapper } from './ElementWrapper';
import { ResizeHandle } from 'components/ResizeHandle';

import {
  droppingElementId,
  getDraggingConfig,
  MOBILE_BREAKPOINT_WIDTH,
  DASHBOARD_ROW_HEIGHT,
  PDF_EDITOR_MARGIN_SIZE,
  DRAGGABLE_HANDLE_CLASS,
} from 'constants/dashboardConstants';
import {
  doesDpEndOnRightHalfOfPage,
  doesElementStartOnRightHalfOfPage,
  filterForContainerElements,
} from 'utils/dashboardUtils';
import * as layoutUtils from 'utils/layoutUtils';
import {
  ContainerElemConfig,
  DashboardElement,
  DashboardVariableMap,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { withWidth } from 'components/HOCs/withWidth';
import { updateElementConfig } from 'actions/dashboardV2Actions';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import * as resourceUtils from 'utils/exploResourceUtils';
import { DashboardStates } from 'reducers/rootReducer';
import { onDropThunk, updateLayoutThunk } from 'reducers/thunks/dashboardLayoutThunks';
import { selectItemOnDashboardThunk } from 'reducers/thunks/dashboardSelectionThunks';

const ResponsiveGridLayout = WidthProvider(BaseResponsiveGridLayout);

const styles = (theme: Theme) =>
  createStyles({
    editableDashboardLayout: {
      minHeight: `5000px !important`,
    },
    containerDashboardLayout: {
      minHeight: `100% !important`,
    },
    emptyContainerState: {
      width: '100% !important',
      height: '100% !important',
      transform: 'none !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.ds.grey600,
    },
  });

export type PassedProps = {
  columns?: number;
  containerId?: string;
  containerLayout?: Layout;
  datasetNamesToId: Record<string, string>;
  datasets: Record<string, ResourceDataset>;
  dashboardElements: DashboardElement[];
  dataPanels: DataPanel[];
  dashboardLayout: Layout[];
  isDemoCustomer: boolean;
  isEditableSectionEnabled: boolean;
  globalStyleConfig: GlobalStyleConfig;
  isViewOnly: boolean;
  pageType: PAGE_TYPE;
  width?: number;
  variables: DashboardVariableMap;
};

type Props = PassedProps & PropsFromRedux & WithStyles<typeof styles>;

type State = {
  isDragging: boolean;
  isResizing: boolean;
  initialLayoutChange: boolean;
};

class ElementGridLayout extends Component<Props, State> {
  gridLayout: RefObject<GridLayout>;

  state: State = {
    isDragging: false,
    isResizing: false,
    initialLayoutChange: true,
  };

  constructor(props: Props) {
    super(props);

    this.gridLayout = createRef();
  }

  isMobileView() {
    const { isViewOnly, containerId, width, viewMode } = this.props;

    // This is only used to update containers in mobile views,
    // so not necessary once already in a container
    if (!isViewOnly || containerId || viewMode === VIEW_MODE.EMAIL) return false;

    return (width ?? 0) <= MOBILE_BREAKPOINT_WIDTH;
  }

  selectItemOnStart = (i: string) => {
    if (!this.props.editableDashboard) return;
    const dashboardElement = this.props.dashboardElements.find(({ id }) => id === i);
    this.props.selectItemOnDashboardThunk(i, {
      type: dashboardElement?.element_type,
    });
  };

  render = () => {
    const {
      classes,
      columns,
      containerId,
      dataPanels,
      dashboardElements,
      draggingElementType,
      editableDashboard,
      isViewOnly,
      dashboardLayout,
      containerLayout,
      width,
      globalStyleConfig,
      viewMode,
      pageType,
      isEditableSectionEnabled,
    } = this.props;
    const elementNamesById: Record<string, string> = {};
    dashboardElements.forEach((elem) => (elementNamesById[elem.id] = elem.name));

    let layout = layoutUtils.processLayout({
      layout: dashboardLayout,
      dashboardElements,
      dataPanels,
      viewMode,
    });
    if (this.isMobileView()) {
      layout = layoutUtils.formatContainerElementHeightForMobile(layout, dashboardElements);
    }

    // We don't want to filter on container id here because we care about every
    // data panel in the dashboard

    const gridLayoutChildren = layoutUtils
      .getSortedGridItems(
        [
          ...filterForContainerElements(dataPanels, containerId).elemsInContainer,
          ...filterForContainerElements(dashboardElements, containerId, true).elemsInContainer,
        ],
        layout,
      )
      .map((gridItem) =>
        resourceUtils.isDashboardElement(gridItem)
          ? this.getDashboardElement(gridItem, elementNamesById)
          : this.renderDataPanel(gridItem),
      );

    const droppingElement = this.renderDroppingElement();
    if (droppingElement) gridLayoutChildren.push(droppingElement);

    const isEmptyContainer = containerId && !gridLayoutChildren.length;

    if (
      viewMode === VIEW_MODE.DEFAULT &&
      editableDashboard &&
      isEmptyContainer &&
      !draggingElementType
    ) {
      gridLayoutChildren.push(
        <div className={classes.emptyContainerState} key="containerPlaceholder">
          Drag new elements into the container
        </div>,
      );
    }

    const marginSize = layoutUtils.getLayoutMargin(viewMode, globalStyleConfig);

    const numRows = containerLayout?.h ?? 1;

    const sharedLayoutProps: Partial<Omit<ReactGridLayoutProps, 'cols'>> = {
      className: cx('layout', {
        [classes.editableDashboardLayout]:
          editableDashboard && !isEditableSectionEnabled && viewMode !== VIEW_MODE.EMAIL,
        [classes.containerDashboardLayout]: containerId,
      }),
      draggableCancel: 'input,textarea,.bp3-popover-wrapper',
      isDraggable: !isEmptyContainer && editableDashboard,
      isResizable: !isEmptyContainer && editableDashboard,
      // we don't allow nested containers
      isDroppable: containerId
        ? editableDashboard && draggingElementType !== DASHBOARD_ELEMENT_TYPES.CONTAINER
        : editableDashboard,
      onDrop: editableDashboard ? this.onDrop : undefined,
      compactType: this.dashboardIsEmpty() || viewMode === VIEW_MODE.PDF ? null : undefined,
      droppingItem: getDraggingConfig(draggingElementType),
      useCSSTransforms: editableDashboard && pageType !== PAGE_TYPE.END_USER_DASHBOARD,
      margin: [marginSize, marginSize] as [number, number],
      style:
        viewMode === VIEW_MODE.PDF
          ? {
              marginTop: -(PDF_EDITOR_MARGIN_SIZE / 2),
              marginBottom: isEditableSectionEnabled ? 0 : -(PDF_EDITOR_MARGIN_SIZE / 2),
            }
          : undefined,
    };

    const cols = viewMode === VIEW_MODE.EMAIL ? 6 : columns || globalStyleConfig.base.numColumns;

    if (isViewOnly && !containerId) {
      return (
        <ResponsiveGridLayout
          cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
          layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
          rowHeight={DASHBOARD_ROW_HEIGHT}
          {...sharedLayoutProps}>
          {gridLayoutChildren}
        </ResponsiveGridLayout>
      );
    }

    /**
     * This width will only be used for container layouts.
     * The height that we get from react-sizeme in DashboardContainerElement includes the padding and border widths of DashboardLayout
     */
    const containerWidth =
      width &&
      width -
        globalStyleConfig.container.padding.default * 2 -
        2 * (globalStyleConfig.container.outline.weight || 0);

    return (
      <GridLayout
        cols={cols}
        draggableHandle={`.${DRAGGABLE_HANDLE_CLASS}`}
        layout={layout}
        onDragStart={(_layout, _oldItem, newItem, _placeholder, e) => {
          // If dragging onto a child container, only the child should start dragging
          // stopPropagation shouldn't be used because react-grid-layout needs to handle the event to update the placeholder
          // Instead, we return false to tell react-grid-layout (react-draggable) to not drag the item
          if (e.defaultPrevented) return false;
          e.preventDefault();

          this.selectItemOnStart(newItem.i);
          this.setState({ isDragging: true });
        }}
        onDragStop={() => this.setState({ isDragging: false })}
        onLayoutChange={(newLayout) => this.handleLayoutChanged(newLayout, layout)}
        onResizeStart={(_layout, item) => {
          this.selectItemOnStart(item.i);
          this.setState({ isResizing: true });
        }}
        onResizeStop={(newLayout) => {
          this.handleLayoutChanged(newLayout, layout, true);
          this.setState({ isResizing: false });
        }}
        ref={this.gridLayout}
        resizeHandle={<ResizeHandle />}
        rowHeight={
          containerId
            ? (DASHBOARD_ROW_HEIGHT * numRows -
                globalStyleConfig.base.spacing.default -
                2 * globalStyleConfig.container.padding.default) /
              (numRows - 1)
            : DASHBOARD_ROW_HEIGHT
        }
        /**
         * In order to account for internal container padding, we need to dynamically calculate
         * the rowHeight so the vertical spacing will be symmetric
         */
        width={containerId && containerWidth ? containerWidth : width || 0}
        {...sharedLayoutProps}>
        {gridLayoutChildren}
      </GridLayout>
    );
  };

  dashboardIsEmpty = () => {
    return this.props.dataPanels.length === 0;
  };

  onDrop = (layout: Layout[], layoutItem: Layout, event: Event) => {
    if (layoutItem === undefined) return;

    // If dropping onto a child container, only the child should call onDrop
    // stopPropagation shouldn't be used because react-grid-layout needs to handle the event to update the placeholder
    // Instead, we return false to tell react-grid-layout (react-draggable) to not drop the item
    if (event.defaultPrevented) return false;
    event.preventDefault();

    this.props.onDropThunk(layout, layoutItem.i, this.props.containerId);
  };

  renderDataPanel = (dataPanel: DataPanel) => {
    const { containerLayout, dashboardLayout, globalStyleConfig } = this.props;

    const dpEndsOnRightSide = doesDpEndOnRightHalfOfPage(
      dashboardLayout,
      dataPanel.id,
      globalStyleConfig.base.numColumns,
      dataPanel.visualize_op.operation_type,
      containerLayout?.x,
    );

    return (
      <DataPanelWrapper
        dashboardElements={this.props.dashboardElements}
        dataPanel={dataPanel}
        dataPanelId={dataPanel.id}
        datasetNamesToId={this.props.datasetNamesToId}
        datasets={this.props.datasets}
        dpEndsOnRightSide={dpEndsOnRightSide}
        isDemoCustomer={this.props.isDemoCustomer}
        isDragging={this.state.isDragging}
        isEditing={this.props.editableDashboard}
        isInContainer={!!this.props.containerId}
        isResizing={this.state.isResizing}
        isViewOnly={this.props.isViewOnly}
        key={dataPanel.id}
        onSelect={() => this.props.selectItemOnDashboardThunk(dataPanel.id)}
        pageType={this.props.pageType}
        stopDragging={this.stopDragging}
        variables={this.props.variables}
      />
    );
  };

  stopDragging = () => {
    this.setState({ isDragging: false });
  };

  getDashboardElement = (elem: DashboardElement, elementNamesById: Record<string, string>) => {
    const { dashboardLayout, containerLayout, globalStyleConfig } = this.props;

    const elementStartsOnRightSide = doesElementStartOnRightHalfOfPage(
      dashboardLayout,
      elem.id,
      globalStyleConfig.base.numColumns,
      containerLayout?.x,
    );

    const elementGridLayoutProps: PassedProps | undefined =
      // Only container elements need these props
      elem.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER
        ? {
            ...this.props,
            // We don't want to pass styles, and allows us to use easy syntax above
            ...{ classes: undefined },
          }
        : undefined;

    return (
      <ElementWrapper
        datasetNamesToId={this.props.datasetNamesToId}
        element={elem}
        elementGridLayoutProps={elementGridLayoutProps}
        elementNamesById={elementNamesById}
        elementStartsOnRightSide={elementStartsOnRightSide}
        isDragging={this.state.isDragging}
        isInContainer={!!this.props.containerId}
        isMobileView={this.isMobileView()}
        isResizing={this.state.isResizing}
        key={elem.id}
        stopDragging={this.stopDragging}
        variables={this.props.variables}
      />
    );
  };

  renderDroppingElement = () => {
    const { dashboardLayout, draggingElementType } = this.props;
    if (!dashboardLayout || !draggingElementType) return;

    const elementId = droppingElementId(draggingElementType);
    const droppingElement = dashboardLayout.find((layoutItem) => layoutItem.i === elementId);
    if (droppingElement) return <div key={droppingElement.i} />;
  };

  handleLayoutChanged = (layout: Layout[], oldLayout: Layout[], ignoreResize = false) => {
    const {
      containerId,
      draggingElementType,
      dataPanels,
      dashboardElements,
      viewMode,
      editableDashboard,
    } = this.props;

    //LayoutChange gets called on load and don't want to update until after initial load
    if (this.state.initialLayoutChange) {
      this.setState({ initialLayoutChange: false });
      return;
    }
    //We now call handleLayoutChange on onResizeStop because sometimes on quick resizing handleLayoutChange
    //is not called. But since sometimes handleLayoutChange is called once resizing is done
    //then we ignore that call with the ignoreResize logic
    if (draggingElementType || !editableDashboard || (this.state.isResizing && !ignoreResize))
      return;

    if (!containerId) {
      this.props.updateLayoutThunk(layout);
      return;
    } else if (layout.length === 1 && layout[0].i === 'containerPlaceholder') {
      //When container is dropped in, layout changed gets called and the placeholder
      //is inserted in layout which is not wanted
      return;
    }

    const gridLayoutChildren = (
      filterForContainerElements(dataPanels, containerId).elemsInContainer as (
        | DashboardElement
        | DataPanel
      )[]
    ).concat(filterForContainerElements(dashboardElements, containerId, true).elemsInContainer);

    if (gridLayoutChildren.length === 0 && !draggingElementType) {
      this.gridLayout?.current?.setState({
        layout: [],
      });
    }

    const containerElement = dashboardElements.find((element) => element.id === containerId);
    const containerConfig = containerElement?.config as ContainerElemConfig;

    const newConfig = { ...containerConfig };

    switch (viewMode) {
      case VIEW_MODE.PDF:
        newConfig.pdfLayout = layout;
        break;
      case VIEW_MODE.EMAIL:
        newConfig.emailLayout = layout;
        break;
      case VIEW_MODE.MOBILE:
        newConfig.mobileLayout = layout;
        break;
      default: {
        newConfig.layout = layout;
      }
    }

    this.props.updateElementConfig({ elementId: containerId, config: newConfig });
  };
}

const mapStateToProps = (state: DashboardStates) => ({
  draggingElementType: state.dashboardInteractions.draggingElementType,
  viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
  editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
});

const mapDispatchToProps = {
  updateElementConfig,
  onDropThunk,
  updateLayoutThunk,
  selectItemOnDashboardThunk,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withWidth(withStyles(styles)(ElementGridLayout)));

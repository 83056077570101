import { forwardRef, InputHTMLAttributes, DetailedHTMLProps, useEffect, useState } from 'react';
import { camelCase } from 'utils/standard';
import cx from 'classnames';

import { Icon, IconButton, Intent, Label, Spinner, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import * as styles from './index.css';

type UncontrolledProps = {
  onChange?: never;
  value?: never;
  onEnter?: never;
  onBlur?: never;

  showInputButton?: boolean;
  defaultValue?: string;
  onSubmit: (value: string) => void;
};

type ControlledProps = {
  defaultValue?: never;
  onSubmit?: never;
  showInputButton?: never;

  value: string | undefined;
  onChange: (value: string) => void;
  onEnter?: (value: string) => void;
  onBlur?: (value: string) => void;
};

type StateProps = UncontrolledProps | ControlledProps;

type LabelProps = { text: string; infoText?: string; variableInput?: boolean };

interface InputProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'onSubmit' | 'ref'
  > {
  descriptiveText?: string;
  errorText?: string;
  fillWidth?: boolean;
  leftIcon?: IconName;
  intent?: Intent;
  label?: string | LabelProps;
  keepFocus?: boolean;
  showLoadingSpinner?: boolean;
}

export type Props = InputProps & StateProps;

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      defaultValue,
      descriptiveText,
      disabled,
      errorText,
      fillWidth,
      leftIcon,
      onChange,
      onSubmit,
      placeholder,
      showInputButton,
      showLoadingSpinner,
      value,
      label,
      style,
      intent,
      onEnter,
      keepFocus,
      onKeyDown,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue || value || '');
    const [isFocused, setIsFocused] = useState(props.autoFocus);

    const currLabel = typeof label === 'string' ? label : label?.text;
    const name = currLabel ? camelCase(currLabel) : '';

    useEffect(() => {
      setInputValue(defaultValue || value || '');
    }, [defaultValue, value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      onChange?.(newValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(event);
      if (event.key !== 'Enter') return;

      if (onSubmit) onSubmit(inputValue);
      else onEnter?.(inputValue);
    };

    const handleIconButtonClick = () => {
      // Should only be called for uncontrolled inputs
      if (!onSubmit) return;

      if (isFocused) {
        setIsFocused(false);
        onSubmit(inputValue);
      } else {
        setInputValue('');
        onSubmit('');
      }
    };

    const renderLabel = () => {
      if (currLabel === undefined || label === undefined) return null;
      const labelClass = cx(styles.label, {
        [styles.fakeLabel]: currLabel === '',
      });
      const sharedProps = { htmlFor: name, className: labelClass };
      if (typeof label === 'string') return <Label {...sharedProps}>{currLabel}</Label>;

      return (
        <Label forVariableInput={label.variableInput} infoText={label.infoText} {...sharedProps}>
          {currLabel}
        </Label>
      );
    };

    return (
      <div
        className={cx(className, {
          [sprinkles({ width: 'fill' })]: fillWidth,
        })}
        style={style}>
        {renderLabel()}
        <div className={sprinkles({ position: 'relative', width: fillWidth ? 'fill' : undefined })}>
          <input
            {...props}
            className={cx(styles.base, {
              [styles.error]: !!errorText || intent === Intent.ERROR,
              [styles.success]: intent === Intent.SUCCESS,
              [styles.disabled]: disabled,
              [sprinkles({ paddingLeft: 'sp4' })]: leftIcon,
              [sprinkles({ paddingRight: 'sp3.5' })]:
                (showInputButton || showLoadingSpinner) && !disabled,
            })}
            disabled={disabled}
            id={name}
            onBlur={({ target }) => {
              onSubmit?.(inputValue);
              onBlur?.(inputValue);
              keepFocus ? target.focus() : setIsFocused(false);
            }}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            ref={ref}
            value={inputValue}
          />
          {leftIcon ? (
            <div className={styles.iconContainer}>
              <Icon className={styles.icon} name={leftIcon} />
            </div>
          ) : null}
          {!disabled && showInputButton ? (
            <IconButton
              className={styles.inputButton}
              name={isFocused ? 'enter-key' : 'cross'}
              onClick={handleIconButtonClick}
              onFocus={() => setIsFocused(true)}
              variant="tertiary"
            />
          ) : null}
          {!disabled && showLoadingSpinner ? (
            <Spinner className={styles.inputSpinner} size="md" />
          ) : null}
        </div>
        {errorText ? (
          <span className={cx(styles.additionalText, sprinkles({ color: 'red' }))} role="alert">
            {errorText}
          </span>
        ) : descriptiveText ? (
          <span className={styles.additionalText}>{descriptiveText}</span>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';

import { FC } from 'react';
import { camelCase } from 'utils/standard';

import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { Label } from '../Label';
import { Icon, IconName } from '../Icon';
import { sprinkles } from '../sprinkles.css';
import * as styles from './index.css';

export type Props = {
  className?: string;
  label?: string;
  labelHelpText?: string;
  selectedValue: string | undefined;
  onValueChange: (value: string) => void;
};

export const Toggle: FC<Props> = ({
  className,
  selectedValue,
  onValueChange,
  label,
  labelHelpText,
  children,
}) => {
  const toggleId = label ? camelCase(label) : undefined;

  return (
    <div className={className}>
      {label && toggleId ? (
        <Label htmlFor={toggleId} infoText={labelHelpText}>
          {label}
        </Label>
      ) : null}
      <ToggleGroup.Root
        className={styles.root}
        id={toggleId}
        // If selected item is clicked it returns empty string so need to check
        onValueChange={(newValue) => newValue && onValueChange(newValue)}
        type="single"
        value={selectedValue}>
        {children}
      </ToggleGroup.Root>
    </div>
  );
};

type ItemProps = { value: string; icon?: IconName; disabled?: boolean };

export const ToggleItem: FC<ItemProps> = ({ value, children, disabled, icon }) => {
  return (
    <ToggleGroup.Item className={styles.item} disabled={disabled} value={value}>
      {icon ? (
        <Icon className={sprinkles({ marginRight: children ? 'sp1' : undefined })} name={icon} />
      ) : null}
      {children || icon ? children : value}
    </ToggleGroup.Item>
  );
};

type BooleanProps = {
  className?: string;
  label?: string;
  selectedValue: boolean;
  onValueChange: (value: boolean) => void;
  trueText: string;
  falseText: string;
};

export const BooleanToggle: FC<BooleanProps> = ({
  className,
  label,
  selectedValue,
  onValueChange,
  trueText,
  falseText,
}) => {
  return (
    <Toggle
      className={className}
      label={label}
      onValueChange={(newVal) => onValueChange(newVal === 'true')}
      selectedValue={selectedValue ? 'true' : 'false'}>
      <ToggleItem value="false">{falseText}</ToggleItem>
      <ToggleItem value="true">{trueText}</ToggleItem>
    </Toggle>
  );
};

import { FC } from 'react';
import cx from 'classnames';

import { Icon, sprinkles } from 'components/ds';

export type Props = {
  // Classname override
  className?: string;
  // Text to be displayed in the link
  text: string;
  // External URL location to open when clicked on (i.e. https://www.google.com/)
  url: string;
};

export const CalloutLink: FC<Props> = ({ className, text, url }) => {
  return (
    <a
      className={cx(rootClass, className)}
      href={url}
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
      target="_blank">
      <span>{text}</span>
      <Icon className={sprinkles({ marginLeft: 'sp1.5' })} name="arrow-right" />
    </a>
  );
};

const rootClass = sprinkles({
  flexItems: 'alignCenterBetween',
  color: { default: 'active', hover: 'activeHovered', active: 'activePressed' },
  backgroundColor: { default: 'elevationHigh', hover: 'elevationMid', active: 'elevationLow' },
  borderRadius: 4,
  cursor: 'pointer',
  padding: 'sp1.5',
});

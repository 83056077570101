import { FC } from 'react';
import { useDrag } from 'react-dnd';
import { Icon } from '@blueprintjs/core';

import { sprinkles } from 'components/ds';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { ItemTypes } from 'constants/dragAndDrop';

type Props = {
  name: string;
  type: string;
  disableDrag?: boolean;
  displayName: string;
};

export const DraggableColumn: FC<Props> = ({ name, type, disableDrag, displayName }) => {
  const dragData = useDrag({
    item: { type: ItemTypes.CONFIGURATION_COLUMN, data: { name, type } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disableDrag,
  });

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        cursor: 'grab',
        paddingY: 'sp.5',
        paddingX: 'sp1',
        borderRadius: 3,
        backgroundColor: { hover: 'lightBlue' },
      })}
      ref={dragData[1]}>
      <Icon icon={SCHEMA_DATA_TYPES_BY_ID[type].icon} />
      <div
        className={sprinkles({ marginLeft: 'sp1' })}
        // Using this because displayName could contain bolding
        dangerouslySetInnerHTML={{ __html: displayName }}
      />
    </div>
  );
};

import { FC, useMemo } from 'react';
import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';

import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { AddColumnMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddColumnMenu';
import { DataPanelList } from './DataPanelList';
import { NoDataAlert } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { GroupByListItem } from 'pages/ReportBuilder/ReportView/DataPanel/GroupByListItem';

import { PivotAgg } from 'types/dateRangeTypes';
import { DraggableColumnInfo, getGroupByUniqueId } from 'utils/customerReportUtils';
import { COLS_SECTION_ID, DataPanelData } from './constants';
import { CustomerReportGroupBy } from 'actions/customerReportActions';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';

type Props = {
  bucketsByCol: Record<string, Set<PivotAgg>>;
  columnConfigs?: ReportBuilderColConfigs;
  columnGroupBys: CustomerReportGroupBy[];
  disabled?: boolean;
  columns: DraggableColumnInfo[];
  onAddColumn: (column: DraggableColumnInfo) => void;
};

export const ColumnGroupBySection: FC<Props> = ({
  bucketsByCol,
  columnConfigs,
  columnGroupBys,
  disabled,
  columns,
  onAddColumn,
}) => {
  const { over, isOver, setNodeRef, active } = useDroppable(DROPPABLE_ARGS);

  const selectedColumns = useMemo(() => columnGroupBys.map((agg) => agg.column), [columnGroupBys]);

  const sortableIds = useMemo(
    () => columnGroupBys.map((item) => `${COLS_SECTION_ID}-${getGroupByUniqueId(item)}`),
    [columnGroupBys],
  );

  const isOverContainer = isOverSection(COLS_SECTION_ID, isOver, over, sortableIds);
  const isOverSelf = active?.data.current?.section === COLS_SECTION_ID;

  const isPopoverOpen = isOverContainer && disabled && !isOverSelf;
  const errorText = 'Requires Values and Group Bys';

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={COLS_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon="table-columns"
        popoverChildren={isPopoverOpen ? errorText : undefined}
        title="Breakdown">
        <AddColumnMenu
          columnConfigs={columnConfigs}
          columns={columns}
          disabled={disabled}
          onAddColumn={onAddColumn}
          selectedColumns={selectedColumns}
          tooltipText={disabled ? errorText : undefined}
        />
      </DataPanelSubHeader>

      {columnGroupBys.length === 0 ? <NoDataAlert name="breakdown" /> : null}

      {columnGroupBys.map((columnGroupBy) => (
        <GroupByListItem
          bucketsByCol={bucketsByCol}
          columnConfigs={columnConfigs}
          disabled={disabled}
          groupBy={columnGroupBy}
          key={getGroupByUniqueId(columnGroupBy)}
          section={COLS_SECTION_ID}
        />
      ))}
    </DataPanelList>
  );
};

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: COLS_SECTION_ID,
  data: { id: COLS_SECTION_ID, section: COLS_SECTION_ID } as DataPanelData,
};

import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { DataPanelListItem } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelListItem';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/ColumnSection.css';

import { CustomerReportGroupBy } from 'actions/customerReportActions';
import {
  BucketsByCol,
  getGroupByUniqueId,
  getGroupByBucketOptions,
} from 'utils/customerReportUtils';
import { PIVOT_AGG_TYPES, PivotAgg } from 'types/dateRangeTypes';
import { deleteGroupBy, updateGroupBy } from 'reportBuilderContent/reducers/reportEditingReducer';
import { COLS_SECTION_ID } from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { getGroupByDisplay } from 'utils/V2ColUtils';

type Props = {
  groupBy: CustomerReportGroupBy;
  bucketsByCol: BucketsByCol;
  columnConfigs?: ReportBuilderColConfigs;
  section: string;
  disabled?: boolean;
};

export const GroupByListItem: FC<Props> = ({
  groupBy,
  bucketsByCol,
  columnConfigs,
  section,
  disabled,
}) => {
  const dispatch = useDispatch();
  const isColumnGroupBy = section === COLS_SECTION_ID;

  const id = getGroupByUniqueId(groupBy);
  const { column, bucket } = groupBy;
  const bucketOptions = getGroupByBucketOptions(groupBy, bucketsByCol);
  const selectedBucket = bucket ? PIVOT_AGG_TYPES[bucket].name : '';

  const handleUpdateGroupBy = (newPivotAgg: string) =>
    dispatch(
      updateGroupBy({
        id,
        isColumnGroupBy,
        groupBy: { ...groupBy, bucket: newPivotAgg as PivotAgg },
      }),
    );

  return (
    <DataPanelListItem
      actionButton={{
        variant: 'tertiary',
        icon: 'trash',
        onClick: () => dispatch(deleteGroupBy({ id, isColumnGroupBy })),
        className: styles.eyeIcon,
      }}
      column={column}
      disabled={disabled}
      icon={SCHEMA_TYPE_ICON_MAP[groupBy.column.type]}
      id={id}
      name={getGroupByDisplay(groupBy, columnConfigs)}
      section={section}>
      {bucketOptions.length > 0 ? (
        <EmbeddedDropdownMenu menuOptions={bucketOptions} onClick={handleUpdateGroupBy}>
          <EmbeddedDropdownButton selectedName={selectedBucket} />
        </EmbeddedDropdownMenu>
      ) : null}
    </DataPanelListItem>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { BooleanToggle } from 'components/ds';

import { TextInputElemConfig } from 'types/dashboardTypes';

type Props = {
  config: TextInputElemConfig;

  updateInputConfig: (config: TextInputElemConfig) => void;
};

export const TextInputValuesConfig: FC<Props> = ({ config, updateInputConfig }) => {
  const updateConfig = (limitEntriesToNumbers: boolean) => {
    const newConfig = produce(config, (draft) => {
      draft.limitEntriesToNumbers = limitEntriesToNumbers;
    });
    updateInputConfig(newConfig);
  };

  return (
    <BooleanToggle
      falseText="All characters"
      label="Allowable entries"
      onValueChange={updateConfig}
      selectedValue={!!config.limitEntriesToNumbers}
      trueText="Only numbers"
    />
  );
};

import { useSelector, shallowEqual } from 'react-redux';
import { useMemo } from 'react';

import { Tag, InfoIcon, sprinkles } from 'components/ds';

import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { OPERATION_TYPES } from 'constants/types';
import { getListOfExtraVarsForElement } from 'utils/extraVariableUtils';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { getCustomerVariables } from 'utils/customerUtils';

import * as styles from './styles.css';

type Props = {
  variables: DashboardVariableMap;
  dashboardInputElements: DashboardElement[];
  dataPanelsWithDrilldown: DataPanelTemplate[];
  dataPanelsWithColorCategories: DataPanelTemplate[];
  dashboardParams: Record<string, DashboardParam> | undefined;
};

export default function RawVariableList({
  variables,
  dashboardInputElements,
  dataPanelsWithDrilldown,
  dataPanelsWithColorCategories,
  dashboardParams,
}: Props) {
  const { selectedCustomer, archetypeProperties } = useSelector(
    (state: ReduxState) => ({
      selectedCustomer: getSelectedCustomer(state.customers),
      archetypeProperties: state.teamData.data?.archetype_properties,
    }),
    shallowEqual,
  );

  const viewVariable = (
    variableName: string,
    value: DashboardVariable,
    toStringify = true,
    isInherited = false,
  ) => {
    const val = toStringify ? JSON.stringify(value) : value;
    return (
      <div className={styles.variable} key={variableName}>
        <div className={styles.variableName} title={variableName}>
          <div className={styles.variableNameText}>{variableName}</div>
          {isInherited ? <Tag className={styles.tag}>Inherited</Tag> : null}
        </div>
        <div className={sprinkles({ padding: 'sp1' })} title={val?.toString()}>
          <div className={styles.variableValueText}>{val}</div>
        </div>
      </div>
    );
  };

  const [filteredCustomerVariables, inheritedProperties] = useMemo(() => {
    const filteredVars: Record<string, string> = {};
    const inheritedProps = new Set();

    if (!selectedCustomer) return [filteredVars, inheritedProps];
    const customerVariables = getCustomerVariables(
      selectedCustomer,
      new Set(archetypeProperties?.map((prop) => prop.name)),
    );
    const deprecatedCustomerVariables = new Set(
      archetypeProperties?.filter((property) => property.deprecated).map((prop) => prop.name),
    );
    const childProperties = new Set(Object.keys(selectedCustomer?.properties ?? []));

    Object.entries(customerVariables).forEach(([key, value]) => {
      if (
        !deprecatedCustomerVariables.has(key) &&
        !key.startsWith('user_group.') &&
        !key.startsWith('customer.')
      ) {
        filteredVars[key] = value;
        if (key.startsWith('properties.') && !childProperties.has(key.replace('properties.', ''))) {
          inheritedProps.add(key);
        }
      }
    });
    return [filteredVars, inheritedProps];
  }, [selectedCustomer, archetypeProperties]);

  const inheritedArchetypes = useMemo(() => {
    const parentArchetypes = new Set();
    if (!archetypeProperties || !selectedCustomer) return parentArchetypes;
    archetypeProperties.forEach((property) => {
      if (property.hierarchy_level_id !== selectedCustomer?.hierarchy_level_id)
        parentArchetypes.add(property.name);
    });
    return parentArchetypes;
  }, [archetypeProperties, selectedCustomer]);

  return (
    <div className={styles.variablesContainer}>
      <div className={styles.sectionContainer}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}>
          <div className={styles.sectionHeader}>Customer</div>
          <InfoIcon
            className={sprinkles({ textTransform: 'capitalize' })}
            text='All "user_group" variables are deprecated and have been replaced by archetype property variables. Custom properties are now stored in the "properties" object'
          />
        </div>
        {filteredCustomerVariables
          ? Object.entries(filteredCustomerVariables).map(([key, value]) => {
              return viewVariable(
                key,
                value,
                true,
                inheritedArchetypes.has(key) || inheritedProperties.has(key),
              );
            })
          : null}
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>Dashboard Elements</div>
        {dashboardInputElements.map((element) => {
          const value = variables[element.name];

          if (value && element.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
            const valueObj = value as Record<string, DashboardVariable>;
            return (
              <div key={`variable-${element.name}`}>
                {Object.keys(valueObj).map((key) =>
                  viewVariable(`${element.name}.${key}`, valueObj[key]),
                )}
              </div>
            );
          }

          return [
            viewVariable(element.name, value),
            getListOfExtraVarsForElement(element.name, element.element_type).map((extraVar) => {
              const extraValue = variables[extraVar];
              return extraValue !== undefined ? viewVariable(extraVar, extraValue) : null;
            }),
          ];
        })}
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>Data Panels</div>
        {dataPanelsWithColorCategories.map((dpt) => {
          const varName = dpt.provided_id + COLOR_CATEGORY_FILTER_SUFFIX;
          const value = variables[varName];
          return viewVariable(varName, value ? JSON.stringify(value) : '', false);
        })}
        {dataPanelsWithDrilldown.map((dpt) => {
          const isTable = dpt.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE;
          const dptId = dpt.provided_id;
          const value = variables[dptId];

          if (!value || (isTable && typeof value !== 'object')) {
            return viewVariable(dptId, value, false);
          }

          const valueObj = value as Record<string, DashboardVariable>;

          return Object.keys(valueObj).map((key) => viewVariable(`${dptId}.${key}`, valueObj[key]));
        })}
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>Custom Variables</div>

        {Object.values(dashboardParams ?? {}).map((param) => {
          const value = variables[param.name];
          return viewVariable(param.name, value);
        })}
      </div>
    </div>
  );
}

import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedButton } from 'components/embed';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import { CustomerReportConfig } from 'actions/customerReportActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { openModalType } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportModalType } from 'reportBuilderContent/reducers/types';
import { saveCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';

interface Props {
  currentConfig: CustomerReportConfig;
  reportId: number;
  hasUnsavedChanges: boolean;
}

export const SaveButton: FC<Props> = ({ reportId, currentConfig, hasUnsavedChanges }) => {
  const dispatch = useDispatch();

  const isPreview = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.isPreview,
  );

  const menuItems = useMemo(
    () => [
      {
        disabled: isPreview || !hasUnsavedChanges,
        name: 'Save',
        onClick: () =>
          dispatch(saveCustomerReportThunk({ report_id: reportId, config: currentConfig })),
      },
      {
        disabled: isPreview,
        name: 'Save as',
        onClick: () =>
          dispatch(openModalType({ type: ReportModalType.SAVE_AS, reportId: reportId })),
      },
    ],
    [currentConfig, dispatch, hasUnsavedChanges, isPreview, reportId],
  );

  return (
    <EmbeddedDropdownMenu menuOptions={menuItems}>
      <EmbedButton variant="secondary">Save</EmbedButton>
    </EmbeddedDropdownMenu>
  );
};

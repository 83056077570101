import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Modal, sprinkles } from 'components/ds';

import { EditableSectionChart } from 'types/dashboardVersionConfig';
import {
  getEditPageDataPanels,
  setEditableSectionModal,
  updateEditableSectionChartInfo,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';

type Props = { chart: EditableSectionChart };

export const EditChartInfoModal: FC<Props> = ({ chart }) => {
  const dispatch = useDispatch();

  const dataPanels = useSelector((state: ReduxState) =>
    getEditPageDataPanels(state.dashboardEditConfig, true),
  );

  const [dpProvidedId, setDpProvidedId] = useState(chart.data_panel.provided_id);
  const [chartName, setChartName] = useState(chart.name);

  const existingProvidedIds = useMemo(
    () => new Set(dataPanels.map((dp) => dp.provided_id)),
    [dataPanels],
  );

  const onClose = () => dispatch(setEditableSectionModal(null));

  const trimmedName = chartName.trim();
  const trimmedProvidedId = dpProvidedId.trim();

  const errorText =
    chart.data_panel.provided_id !== dpProvidedId && existingProvidedIds.has(dpProvidedId.trim())
      ? 'Data Panel IDs must be unique'
      : undefined;

  const disabled =
    (chart.data_panel.provided_id === trimmedProvidedId && trimmedName === chart.name) ||
    !!errorText;

  return (
    <Modal
      isOpen
      contentClassName={sprinkles({ paddingX: 'sp2', paddingY: 'sp1' })}
      onClose={onClose}
      primaryButtonProps={{
        disabled,
        text: 'Save',
        onClick: () => {
          if (disabled) return;
          dispatch(
            updateEditableSectionChartInfo({ name: trimmedName, providedId: trimmedProvidedId }),
          );
        },
      }}
      size="small"
      title={`Edit ${chart.name} Info`}>
      <Input
        className={sprinkles({ marginBottom: 'sp2' })}
        defaultValue={dpProvidedId}
        label="Chart Id"
        onSubmit={setDpProvidedId}
      />
      <Input defaultValue={chartName} label="Chart Name" onSubmit={setChartName} />
    </Modal>
  );
};

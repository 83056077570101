import { keyBy, sortBy } from 'utils/standard';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  fetchAllSchemaTablesActions,
  fetchUsedParentSchemasActions,
} from 'actions/parentSchemaActions';
import { TableDataset, ParentSchema, connectDataSourceSuccess } from 'actions/dataSourceActions';
import { createEmbeddoDataSource } from './thunks/connectDataSourceThunks';
import { ReduxState } from './rootReducer';
import { fetchAllTables } from './thunks/syncSchemaFlowThunks';
import { syncSchemaThunk } from './thunks/schemaManagementThunks';

// <SchemaId, <DatasetId, TableDataset>>
export type SchemaTablesMap = Record<string, Record<number, TableDataset>>;
interface ParentSchemaReducerState {
  usedParentSchemas: RD.ResponseData<ParentSchema[]>;
  schemaTablesMap: RD.ResponseData<SchemaTablesMap>;
}

const initialState: ParentSchemaReducerState = {
  usedParentSchemas: RD.Idle(),
  schemaTablesMap: RD.Idle(),
};

const parentSchemasSlice = createSlice({
  name: 'parentSchemas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsedParentSchemasActions.requestAction, (state) => {
        state.usedParentSchemas = RD.Loading();
      })
      .addCase(fetchUsedParentSchemasActions.errorAction, (state) => {
        state.usedParentSchemas = RD.Error('Error loading parent schemas');
      })
      .addCase(fetchUsedParentSchemasActions.successAction, (state, { payload }) => {
        state.usedParentSchemas = RD.Success(sortBy(payload.parent_schemas, (schema) => schema.id));
      })
      .addCase(fetchAllSchemaTablesActions.requestAction, (state) => {
        state.schemaTablesMap = RD.Loading();
      })
      .addCase(fetchAllTables.pending, (state) => {
        state.schemaTablesMap = RD.Loading();
      })
      .addCase(fetchAllSchemaTablesActions.errorAction, (state) => {
        state.schemaTablesMap = RD.Error('Error loading schema tables');
      })
      .addCase(fetchAllTables.rejected, (state) => {
        state.schemaTablesMap = RD.Error('Error loading schema tables');
      })
      .addCase(fetchAllSchemaTablesActions.successAction, (state, { payload }) => {
        const schemaTablesMap: SchemaTablesMap = {};
        for (const key in payload.schema_tables) {
          schemaTablesMap[key.toString()] = keyBy(payload.schema_tables[key], 'id');
        }
        state.schemaTablesMap = RD.Success(schemaTablesMap);
      })
      .addCase(fetchAllTables.fulfilled, (state, { payload }) => {
        const schemaTablesMap: SchemaTablesMap = {};
        for (const key in payload.schema_tables) {
          schemaTablesMap[key.toString()] = keyBy(payload.schema_tables[key], 'id');
        }
        state.schemaTablesMap = RD.Success(schemaTablesMap);
      })
      .addCase(syncSchemaThunk.fulfilled, (state, { payload }) => {
        state.usedParentSchemas = RD.Success(sortBy(payload.parent_schemas, (schema) => schema.id));
      })
      .addCase(connectDataSourceSuccess, (state, { payload }) => {
        if (!payload.new_schema || !RD.isSuccess(state.usedParentSchemas)) return;
        state.usedParentSchemas.data.push(payload.new_schema);
      })
      .addCase(createEmbeddoDataSource.fulfilled, (state, { payload }) => {
        if (!payload.schema || !RD.isSuccess(state.usedParentSchemas)) return;
        state.usedParentSchemas.data.push(payload.schema);
      });
  },
});

export const parentSchemasReducer = parentSchemasSlice.reducer;

// This is used to get the list of parent schemas in places where it does not care
// about the loading state
export const getParentSchemasList = createSelector(
  (state: ReduxState) => state.currentUser.team?.feature_flags.use_fido,
  (state: ReduxState) => state.fido.fidoDaos,
  (state: ReduxState) => state.parentSchemas.usedParentSchemas,
  (use_fido, fidoDaos, schemas) =>
    use_fido
      ? RD.isSuccess(fidoDaos)
        ? fidoDaos.data.namespaces
        : []
      : RD.getOrDefault(schemas, []),
);

export const areParentSchemasLoading = createSelector(
  (state: ReduxState) => state.parentSchemas.usedParentSchemas,
  (state: ReduxState) => state.parentSchemas.schemaTablesMap,
  (schemas, map) => RD.isLoadingMulti([schemas, map], true),
);

import { FC, Fragment } from 'react';
import cx from 'classnames';
import { Popover, Position, Menu, MenuItem, Tooltip } from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { APP_PORTAL_ID, IconButton, sprinkles } from 'components/ds';
import { DroppedFormula } from './DroppedFormula';
import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import * as styles from './styles.css';

import { Aggregation } from 'constants/types';
import { getColBucketName, getColDisplayText, getGroupOptions } from 'utils/dataPanelColUtils';
import { DashboardElement } from 'types/dashboardTypes';
import { PivotAgg } from 'types/dateRangeTypes';
import { AGGREGATIONS_TYPES } from 'constants/dataConstants';
import { OldConfigurableColInfo, AggregationType } from 'types/columnTypes';

type Props = {
  column: OldConfigurableColInfo;
  canSelectSmartGrouping?: boolean;
  draggingClass?: string;
  onColAdded: (newCol: DatasetColumn, oldColName?: string, oldColAggType?: AggregationType) => void;

  onColOptionChanged: (option: { id: string; name: string; formula?: string }) => void;
  onRemoveCol: () => void;
  disableEdits?: boolean;
  dashboardElements?: DashboardElement[];
  includeFirst?: boolean;
  isCollapsibleListAgg?: boolean;
  schema: DatasetSchema;
};

export const DroppedAggregationColumn: FC<Props> = ({
  column,
  canSelectSmartGrouping,
  draggingClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  dashboardElements,
  includeFirst,
  isCollapsibleListAgg,
  schema,
}) => {
  const groupOptions = getGroupOptions(
    column,
    dashboardElements,
    includeFirst,
    isCollapsibleListAgg,
  );

  const dateTime = DateTime.local();

  if (groupOptions.length === 0) {
    return (
      <div
        className={cx(
          sprinkles({
            display: 'flex',
            marginBottom: 'sp.5',
            backgroundColor: 'lightBlue',
            borderRadius: 3,
          }),
          draggingClass,
        )}>
        <div className={sprinkles({ width: 'fill', overflow: 'hidden', flex: 1 })}>
          <ColumnOptionsPopover
            rightDivider
            displayName={getColDisplayText(column)}
            onColChange={(newCol) => onColAdded(newCol, column.column.name, column.agg)}
            schema={schema}
          />
        </div>
        <IconButton className={styles.iconBtn} name="cross" onClick={onRemoveCol} />
      </div>
    );
  }

  if (column.agg?.id === Aggregation.FORMULA) {
    return (
      <DroppedFormula
        disableEdits={!!disableEdits}
        draggingClass={draggingClass}
        formula={column.agg.formula}
        onColOptionChanged={onColOptionChanged}
        onRemoveCol={onRemoveCol}
      />
    );
  }

  const menuItem = (option: {
    id: string;
    name: string;
    getExample?: (fmt: DateTime) => string | undefined;
  }) => (
    <MenuItem
      className={styles.menuItemOption}
      disabled={(option.id === PivotAgg.DATE_SMART && !canSelectSmartGrouping) || disableEdits}
      label={option.getExample?.(dateTime)}
      onClick={() => !disableEdits && onColOptionChanged({ id: option.id, name: option.name })}
      text={option.name}
    />
  );

  const aggName = column?.agg ? AGGREGATIONS_TYPES[column.agg.id].name : undefined;

  const containerStyles = cx(
    sprinkles({
      display: 'flex',
      marginBottom: 'sp.5',
      backgroundColor: 'lightBlue',
      borderRadius: 3,
    }),
    draggingClass,
  );

  return (
    <div className={containerStyles}>
      <div
        className={sprinkles({
          flex: 3,
          width: 'fill',
          overflow: 'hidden',
        })}>
        <ColumnOptionsPopover
          rightDivider
          displayName={column.column.name}
          onColChange={(newCol) => onColAdded(newCol, column.column.name, column.agg)}
          schema={schema}
        />
      </div>
      <div className={sprinkles({ flex: 2, width: 'fill', overflow: 'hidden' })}>
        <Popover
          captureDismiss
          fill
          minimal
          content={
            <Menu>
              {groupOptions.map((group, i) => (
                <Fragment key={`dropped-column-groups-${i}`}>
                  {group.title && (
                    <li className="bp3-menu-header">
                      <h6 className="bp3-heading">{group.title}</h6>
                    </li>
                  )}
                  {group.options.map((option, i) => (
                    <Fragment key={`dropped-column-group=options-${i}`}>
                      {option.id === PivotAgg.DATE_SMART && !canSelectSmartGrouping ? (
                        <Tooltip
                          content="Date Range Picker component must be configured as a filter in the section below to select Smart Grouping"
                          openOnTargetFocus={false}>
                          {menuItem(option)}
                        </Tooltip>
                      ) : (
                        menuItem(option)
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Menu>
          }
          portalContainer={document.getElementById(APP_PORTAL_ID) ?? undefined}
          position={Position.BOTTOM_RIGHT}>
          <div className={cx(styles.popoverTargetContainer, sprinkles({ cursor: 'pointer' }))}>
            <div className={styles.colName}>
              {aggName?.toUpperCase() || getColBucketName(column?.bucket?.id).toUpperCase()}
            </div>
            <IconButton className={styles.iconBtnWithDivider} name="caret-down" />
          </div>
        </Popover>
      </div>
      <IconButton
        className={styles.iconBtn}
        disabled={disableEdits}
        name="cross"
        onClick={() => !disableEdits && onRemoveCol()}
      />
    </div>
  );
};

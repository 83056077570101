import { ConfirmCloseBuiltInModal } from 'pages/ReportBuilder/ModalViews/ConfirmCloseBuiltInModal';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ConfirmCloseReportModal } from 'pages/ReportBuilder/ModalViews/ConfirmCloseReportModal';
import { SaveBuiltInAsReportModal } from 'pages/ReportBuilder/ModalViews/SaveBuiltInAsReportModal';
import { DataSelectionModal } from 'pages/ReportBuilder/ModalViews/DataSelection/DataSelectionModal';
import { EditBuiltInModal } from 'pages/ReportBuilder/ModalViews/EditBuiltInModal';
import { SaveAsModal } from 'pages/ReportBuilder/ModalViews/SaveAsModal';
import { ScheduleExportModal } from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ScheduleExportModal';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportModalType } from 'reportBuilderContent/reducers/types';
import { ReportAIModal } from 'pages/ReportBuilder/ModalViews/ReportAIModal';
import { CustomerReportDataInfo } from 'actions/customerReportActions';
import { ExportNowModal } from 'pages/ReportBuilder/ReportView/ExportButton/ExportNowModal';

interface Props {
  reportName: string;
  dataInfo: CustomerReportDataInfo | undefined;
  isIframe: boolean;
}

export const ModalViews: FC<Props> = ({ reportName, dataInfo, isIframe }) => {
  const openModal = useSelector((state: ReportBuilderReduxState) => state.reportEditing.openModal);

  if (!openModal) return null;

  switch (openModal.type) {
    case ReportModalType.SAVE_AS:
      return (
        <SaveAsModal isIframe={isIframe} reportId={openModal.reportId} reportName={reportName} />
      );
    case ReportModalType.DATA_SELECTION:
      return <DataSelectionModal datasetId={openModal.datasetId} isIframe={isIframe} />;
    case ReportModalType.SCHEDULE_EXPORT:
      return <ScheduleExportModal isIframe={isIframe} reportName={reportName} />;
    case ReportModalType.EXPORT_NOW:
      return <ExportNowModal isIframe={isIframe} reportName={reportName} />;
    case ReportModalType.EDIT_BUILT_IN:
      return <EditBuiltInModal />;
    case ReportModalType.SAVE_BUILT_IN_AS_REPORT:
      return (
        <SaveBuiltInAsReportModal
          builtInId={openModal.builtInId}
          builtInName={reportName}
          isIframe={isIframe}
        />
      );
    case ReportModalType.CONFIRM_CLOSE_REPORT:
      return (
        <ConfirmCloseReportModal
          isIframe={isIframe}
          reportId={openModal.reportId}
          reportName={reportName}
        />
      );
    case ReportModalType.CONFIRM_CLOSE_BUILT_IN:
      return (
        <ConfirmCloseBuiltInModal
          builtInId={openModal.builtInId}
          builtInName={reportName}
          isIframe={isIframe}
        />
      );
    case ReportModalType.GENERATE_AI_VIEW:
      return <ReportAIModal dataInfo={dataInfo} isIframe={isIframe} />;
    default:
      return null;
  }
};

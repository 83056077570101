import { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import cx from 'classnames';
import { camelCase } from 'utils/standard';

import { Icon, Input, Label, sprinkles } from 'components/ds';

import * as styles from './index.css';
import { createDebouncedFn } from 'utils/general';
import { IconName } from 'components/ds/Icon';

export type SelectValues<T> = {
  label?: string;
  secondaryLabel?: string;
  value: T;
  icon?: IconName;
}[];

type FilterProps = {
  maxValues?: number;
  isLoading?: boolean;
  placeholder?: string;
  onFilter?: (searchString: string) => void;
  selectedLabel?: string;
};

export type Props<T> = {
  className?: string;
  disabled?: boolean;
  label?: string;
  infoText?: string;
  placeholder?: string;
  portalContainerId?: string;
  onChange: (value: T) => void;
  selectedValue: T | undefined;
  values: SelectValues<T>;
  // Parent container to create a boundary for the Radix dropdown menu
  collisionBoundary?: HTMLElement | null;
  side?: 'top' | 'bottom' | 'right' | 'left';
  inModal?: boolean;
  onCancel?: () => void;
  filterProps?: FilterProps;
};

const debounceFn = createDebouncedFn(400);

export const Select: FC<Props<string>> = ({
  className,
  disabled,
  infoText,
  label,
  onChange,
  placeholder,
  portalContainerId,
  selectedValue,
  values,
  collisionBoundary,
  inModal,
  side,
  onCancel,
  filterProps,
}) => {
  const [inputValue, setInputValue] = useState<string>();
  // used to force rerender in on cancel and when selectedLabel becomes undefined
  const [key, setKey] = useState<number>(+new Date());

  const content = (
    <RadixSelect.Content
      className={cx(
        styles.content,
        inModal ? sprinkles({ zIndex: 'popoversInModals' }) : undefined,
      )}
      collisionBoundary={collisionBoundary}
      collisionPadding={{ top: 24, right: 16 }}
      position="popper"
      side={side}
      sideOffset={8}
      style={{ maxHeight: filterProps ? 300 : 200 }}>
      {filterProps ? (
        <FilterContent
          filterProps={filterProps}
          inputValue={inputValue}
          setInputValue={setInputValue}
          values={values}
        />
      ) : (
        <RadixSelect.Viewport>
          {values.map(({ icon, value, label, secondaryLabel }) => (
            <SelectItem
              icon={icon}
              key={value}
              label={label ?? value}
              secondaryLabel={secondaryLabel}
              value={value}
            />
          ))}
          {values.length === 0 ? <div className={styles.nonSelectItem}>No results.</div> : null}
        </RadixSelect.Viewport>
      )}
    </RadixSelect.Content>
  );

  const renderCancelButton = () => {
    if (!onCancel || !selectedValue || disabled) return null;
    return (
      <div
        className={styles.cancelButton}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCancel();
        }}>
        <Icon name="cross" />
      </div>
    );
  };

  const selectedLabel = useMemo(() => {
    if (!selectedValue) return;
    const val = values.find((v) => v.value === selectedValue);
    return (
      val?.label ??
      val?.value ??
      (filterProps ? filterProps.selectedLabel ?? selectedValue : undefined)
    );
  }, [filterProps, selectedValue, values]);

  useEffect(() => {
    if (selectedLabel === undefined) setKey(+new Date());
  }, [selectedLabel]);

  return (
    <div className={className}>
      {label ? (
        <Label htmlFor={camelCase(label)} infoText={infoText}>
          {label}
        </Label>
      ) : null}
      <RadixSelect.Root
        disabled={disabled}
        key={key}
        onValueChange={onChange}
        value={selectedValue}>
        <RadixSelect.Trigger className={styles.trigger} id={camelCase(label)}>
          <span className={sprinkles({ truncateText: 'ellipsis' })}>
            <RadixSelect.Value>{selectedLabel ?? placeholder ?? 'Select...'}</RadixSelect.Value>
          </span>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', marginLeft: 'sp.5' })}>
            {renderCancelButton()}
            <RadixSelect.Icon>
              <Icon className={sprinkles({ color: 'contentTertiary' })} name="caret-down" />
            </RadixSelect.Icon>
          </div>
        </RadixSelect.Trigger>

        {portalContainerId ? (
          <RadixSelect.Portal container={document.getElementById(portalContainerId)}>
            {content}
          </RadixSelect.Portal>
        ) : (
          content
        )}
      </RadixSelect.Root>
    </div>
  );
};

type SelectItemProps<T> = {
  value: T;
  label: string;
  secondaryLabel?: string;
  icon?: IconName;
};

const SelectItem: FC<SelectItemProps<string>> = forwardRef<HTMLDivElement, SelectItemProps<string>>(
  ({ value, label, secondaryLabel, icon, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item className={styles.item} ref={forwardedRef} value={String(value)} {...props}>
        <div className={sprinkles({ overflow: 'hidden', display: 'flex' })}>
          <div
            className={sprinkles({ truncateText: 'ellipsis', flexGrow: 1 })}
            title={label.toString()}>
            {icon ? <Icon className={sprinkles({ marginRight: 'sp1' })} name={icon} /> : undefined}
            <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
          </div>
          <RadixSelect.ItemIndicator className="SelectItemIndicator">
            <Icon
              className={sprinkles({ color: 'active', paddingLeft: 'sp.5' })}
              name="check"
              size="sm"
            />
          </RadixSelect.ItemIndicator>
        </div>
        {secondaryLabel ? (
          <div className={sprinkles({ color: 'contentTertiary' })}>{secondaryLabel}</div>
        ) : null}
      </RadixSelect.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';

type FilterContentProps<T> = {
  filterProps: FilterProps;
  values: SelectValues<T>;
  inputValue: string | undefined;
  setInputValue: (inputValue?: string) => void;
};

const FilterContent: FC<FilterContentProps<string>> = ({
  filterProps,
  values,
  inputValue,
  setInputValue,
}) => {
  const [filteredValues, setFilteredValues] = useState<SelectValues<string>>(values);

  useEffect(() => setFilteredValues(values), [values]);

  const { placeholder, isLoading } = filterProps;
  const maxValues = filterProps.maxValues ?? 50;

  const handleFiltering = (input: string) => {
    debounceFn(() => {
      const loweredInput = input.toLowerCase();
      filterProps.onFilter
        ? filterProps.onFilter(input)
        : setFilteredValues(
            values.filter(({ value, label }) =>
              (label ?? value).toLowerCase().includes(loweredInput),
            ),
          );
      setInputValue(input);
    });
  };

  return (
    <>
      <Input
        autoFocus
        fillWidth
        keepFocus
        className={styles.nonSelectItem}
        leftIcon="search"
        onChange={handleFiltering}
        placeholder={placeholder}
        showLoadingSpinner={isLoading}
        value={inputValue}
      />
      <RadixSelect.Viewport>
        {filteredValues.slice(0, maxValues).map(({ icon, value, label, secondaryLabel }) => (
          <SelectItem
            icon={icon}
            key={value}
            label={label ?? value}
            secondaryLabel={secondaryLabel}
            value={value}
          />
        ))}
        {filteredValues.length === 0 ? (
          <div className={styles.nonSelectItem}>No results.</div>
        ) : null}
        {filteredValues.length >= maxValues ? (
          <div className={styles.nonSelectItem}>Filter for more results.</div>
        ) : null}
      </RadixSelect.Viewport>
    </>
  );
};

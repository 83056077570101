import { FC } from 'react';

import { GradientColorPicker } from 'components/ColumnFormatConfigs/NumberFormatConfig/GradientColorPicker';
import { GoalInputRow } from 'components/ColumnFormatConfigs/NumberFormatConfig/GoalInputRow';
import { Select, sprinkles } from 'components/ds';

import {
  GradientType,
  V2TwoDimensionChartInstructions,
  HeatMapFormat,
  GradientPointType,
  GradientOptions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  instructions: V2TwoDimensionChartInstructions;
  updateHeatMapInstructions: (updates: HeatMapFormat) => void;
};

const gradientTypeOptions = [{ value: GradientType.DIVERGING }, { value: GradientType.LINEAR }];

export const HeatMapGradientConfiguration: FC<Props> = ({
  globalStyleConfig,
  instructions,
  updateHeatMapInstructions,
}) => {
  const heatMapInstructions = instructions.chartSpecificFormat?.heatMap;

  const gradient = heatMapInstructions?.gradient;
  const gradientType = heatMapInstructions?.gradientType ?? GradientType.LINEAR;
  const defaultDivergingGradient = globalStyleConfig.visualizations.divergingPalette;
  const defaultLinearGradient = globalStyleConfig.visualizations.gradientPalette;
  const { hue1, hue2, hue3 } = {
    hue1: gradient?.hue1 || defaultDivergingGradient.hue1,
    hue2:
      gradient?.hue2 ||
      (gradientType === GradientType.LINEAR
        ? defaultLinearGradient.hue1
        : defaultDivergingGradient.hue2),
    hue3:
      gradient?.hue3 ||
      (gradientType === GradientType.LINEAR
        ? defaultLinearGradient.hue2
        : defaultDivergingGradient.hue3),
  };
  const hues = gradientType === GradientType.DIVERGING ? [hue1, hue2, hue3] : [hue2, hue3];

  const getOnHueChanged = (hue: 'hue1' | 'hue2' | 'hue3') => (newHue: string) => {
    const newGradient = {
      ...gradient,
      [hue]: newHue,
    };
    updateHeatMapInstructions({ gradient: newGradient });
  };

  const gradientOptions = heatMapInstructions?.gradientOptions;
  const { maxpoint, midpoint, minpoint } = gradientOptions ?? {};

  const updateGradientOptions = (updates: GradientOptions) => {
    updateHeatMapInstructions({
      gradientOptions: {
        ...gradientOptions,
        ...updates,
      },
    });
  };

  return (
    <div>
      <Select
        label="Gradient Type"
        onChange={(value) => {
          updateHeatMapInstructions({ gradientType: value as GradientType });
        }}
        selectedValue={gradientType}
        values={gradientTypeOptions}
      />
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        <GradientColorPicker
          hues={hues}
          onHuesChanged={
            gradientType === GradientType.DIVERGING
              ? [getOnHueChanged('hue1'), getOnHueChanged('hue2'), getOnHueChanged('hue3')]
              : [getOnHueChanged('hue2'), getOnHueChanged('hue3')]
          }
        />
        <div className={sprinkles({ marginLeft: 'sp1' })}>
          <GoalInputRow
            exactValue={minpoint?.value}
            setGradientPointType={(newType) =>
              updateGradientOptions({ minpoint: { ...minpoint, type: newType } })
            }
            type="min"
            updateExactValue={(newValue) => {
              updateGradientOptions({ minpoint: { ...minpoint, value: newValue } });
            }}
            usesExactValue={minpoint?.type === GradientPointType.NUMBER}
          />
          {gradientType === GradientType.DIVERGING ? (
            <GoalInputRow
              hideLabels
              exactValue={midpoint?.value}
              setGradientPointType={(newType) =>
                updateGradientOptions({ midpoint: { ...midpoint, type: newType } })
              }
              type="mid"
              updateExactValue={(newValue) => {
                updateGradientOptions({ midpoint: { ...midpoint, value: newValue } });
              }}
              usesExactValue={midpoint?.type === GradientPointType.NUMBER}
            />
          ) : null}
          <GoalInputRow
            hideLabels
            exactValue={maxpoint?.value}
            setGradientPointType={(newType) =>
              updateGradientOptions({ maxpoint: { ...maxpoint, type: newType } })
            }
            type="max"
            updateExactValue={(newValue) => {
              updateGradientOptions({ maxpoint: { ...maxpoint, value: newValue } });
            }}
            usesExactValue={maxpoint?.type === GradientPointType.NUMBER}
          />
        </div>
      </div>
    </div>
  );
};

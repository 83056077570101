import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';

import { BaseCol } from 'types/columnTypes';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { getCurrentView, getFilterableRawColumns } from 'utils/customerReportUtils';
import { makeThunkRequest } from 'utils/thunkUtils';
import {
  getDistinctColumnBody,
  getAggBody,
  getViewRequestParams,
  isPivotView,
  useFidoForReportBuilderRequest,
  getTotalBody,
  getDrilldownView,
} from './utils';
import { createJob } from 'components/JobQueue/createJob';
import { enqueueReportBuilderJobsThunk } from './jobThunks';
import {
  FetchReportBuilderDataBody,
  FetchReportBuilderDatasetData,
  FetchReportBuilderDatasetBody,
} from '../apiTypes';
import { JobDefinition } from 'actions/jobQueueActions';
import { CustomerReportView } from 'actions/customerReportActions';
import { createApiRequestConfig } from 'actions/actionUtils';
import { AxiosRequestConfig } from 'axios';
import * as RD from 'remotedata';
import { isSuccess } from 'remotedata';
import {
  getDistinctDataId,
  getAggDataId,
  getAiDataId,
  getDatasetDataId,
  getTotalDataId,
  getDrilldownDataId,
} from 'reportBuilderContent/reducers/reportEditingUtils';
import {
  fetchFidoReportBuilderQueryPreview,
  fetchFidoReportBuilderView,
} from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import { generateReportBuilderComputations } from 'utils/fido/fidoInstructionShims';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { validateColumnsThunk } from 'reportBuilderContent/thunks/dataThunks';
import { getTimezone } from 'utils/timezoneUtils';
import { DashboardTimezones } from 'constants/dashboardConstants';
import { EmbeddedReportBuilderReducerState } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

/**
 * Thunks for fetching customer report data in the Explo App's Report Builder Editor
 */

export const fetchDashboardDatasetModalData =
  (datasetId: string): Thunk =>
  (dispatch, getState) => {
    const { customers, embeddedReportBuilder, currentUser, fido } = getState();

    const customerId = customers.selectedGroupId;
    const dataset = embeddedReportBuilder.reportBuilderVersion?.config.datasets[datasetId];
    if (!dataset || !customerId) return;

    const postData: FetchReportBuilderDataBody = {
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      column_configs: dataset.columnConfigs,
      page: 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      page: 1,
      id: getDatasetDataId(datasetId),
    };

    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: { pagingConfiguration: { page: 0 } },
            queryContext: {},
            computation: null,
          },
          customerId,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

const getAppRequest = (postData: FetchReportBuilderDataBody): AxiosRequestConfig =>
  createApiRequestConfig('report_builder/get_dataset_data/', 'POST', postData);

const getRowCountRequestConfig = (postData: FetchReportBuilderDataBody) =>
  createApiRequestConfig('report_builder/get_dataset_row_count/', 'POST', postData);

export const fetchAppDataset = createAsyncThunk<
  void,
  FetchReportBuilderDatasetBody,
  { state: ReduxState }
>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW,
  async ({ datasetId, page, save, viewParams }, { getState, dispatch }) => {
    const { reportBuilderEdit, customers, currentUser, fido, embeddedReportBuilder } = getState();

    const customerId = customers.selectedGroupId;
    const dataset = RD.isSuccess(reportBuilderEdit.config)
      ? reportBuilderEdit.config.data.datasets[datasetId]
      : undefined;
    if (!dataset || !customerId) return;

    const query = dataset.queryDraft ?? dataset.query;
    const postData: FetchReportBuilderDataBody = {
      ...viewParams,
      query,
      column_configs: dataset.columnConfigs,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      page: page || 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderQueryPreview({
          dataset: dataset,
          body: {
            query,
            dataRequestParameters: {
              includeTotalResults: page === undefined,
              pagingConfiguration: { page },
            },
            queryContext: {},
            computation: null,
          },
          customerId,
          save,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppDatasetJob({ postData, datasetId, page, save }));
    } else {
      dispatch(fetchReportBuilderDatasetApi({ postData, datasetId, page, save }));
    }
  },
  {
    condition: ({ switchedToDataset, datasetId }, { getState }) => {
      const { datasetData, config } = getState().reportBuilderEdit;

      // If dataset has already been loaded don't need to call again
      if (switchedToDataset && datasetId in datasetData) return false;

      const dataset = RD.isSuccess(config) ? config.data.datasets[datasetId] : undefined;
      if (!dataset) return false;

      // If no query then no need to make request
      const query = dataset.queryDraft ?? dataset.query;
      return query.trim() !== '';
    },
  },
);

export const fetchReportBuilderDatasetApi = createAsyncThunk<
  FetchReportBuilderDatasetData,
  FetchReportBuilderDatasetArgs,
  { state: ReduxState }
>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_API,
  async ({ postData, datasetId, page }, { dispatch }) => {
    const requestConfig = getAppRequest(postData);
    const onSuccess =
      page == null ? () => dispatch(fetchAppDatasetRowCount({ datasetId, postData })) : undefined;

    return makeThunkRequest(requestConfig, 'Error loading dataset preview data', { onSuccess });
  },
);

const fetchAppDatasetJob =
  ({ postData, datasetId, page, save }: FetchReportBuilderDatasetArgs): Thunk =>
  (dispatch) => {
    const jobs: Record<string, JobDefinition> = {};
    const dataJob = createJob({
      job_type: ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB,
      job_args: { ...postData, datasetId, save },
    });
    Object.assign(jobs, dataJob);

    if (page === undefined) {
      const rowCountJob = createJob({
        job_type: ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB,
        job_args: { ...postData, datasetId },
      });
      Object.assign(jobs, rowCountJob);
    }
    dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

type FetchReportBuilderDatasetArgs = {
  postData: FetchReportBuilderDataBody;
  datasetId: string;
  save: boolean | undefined;
  page: number | undefined;
};

export const fetchAppDatasetRowCount = createAsyncThunk<
  { row_count: number },
  { datasetId: string; postData: FetchReportBuilderDataBody },
  { state: ReduxState }
>(ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT, async ({ postData }) => {
  const requestConfig = getRowCountRequestConfig(postData);
  return makeThunkRequest(requestConfig, 'Error loading row count for report builder dataset');
});

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 */
export const fetchAppReportData =
  (page: number | undefined): Thunk =>
  (dispatch, getState) => {
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    if (!viewConfig) return;

    dispatch(fetchAppData(page, dataset, viewConfig, viewConfig.id));
  };

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 */
export const fetchAppDrilldownData =
  (page: number | undefined): Thunk =>
  (dispatch, getState) => {
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView, drilldownConfigs } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    const currentConfigs = currentView ? drilldownConfigs[currentView] : null;
    if (!viewConfig || !currentConfigs?.filters) return;

    const filteredConfig: CustomerReportView = getDrilldownView(
      viewConfig,
      currentConfigs.filters,
      currentConfigs.sort,
    );
    dispatch(fetchAppData(page, dataset, filteredConfig, getDrilldownDataId(viewConfig.id)));
  };

const getReportBuilderTimezone = ({
  reportBuilder,
  timezone,
}: EmbeddedReportBuilderReducerState) => {
  const passedDashboardTimezone =
    timezone &&
    Object.values(DashboardTimezones).find(
      (t) => t.toLowerCase() === timezone?.trim().toLowerCase(),
    );
  if (passedDashboardTimezone) return getTimezone(passedDashboardTimezone);

  const reportBuilderTimezone = isSuccess(reportBuilder) && reportBuilder.data.default_timezone;
  return getTimezone(reportBuilderTimezone || DashboardTimezones.UTC);
};

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 * @param dataset
 * @param viewConfig
 * @param dataId
 */
export const fetchAppData =
  (
    page: number | undefined,
    dataset: ReportBuilderDataset,
    viewConfig: CustomerReportView,
    dataId: string,
  ): Thunk =>
  (dispatch, getState) => {
    const { customers, currentUser, fido, embeddedReportBuilder } = getState();
    const customerId = customers.selectedGroupId;
    if (!customerId) return;

    const viewParams = getViewRequestParams(viewConfig, dataset);
    const postData: FetchReportBuilderDataBody = {
      ...viewParams,
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      column_configs: dataset.columnConfigs,
      page: page || 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    // If first page data is requested and not a pivot table also get row count
    const shouldFetchRowCount = page === undefined && !isPivotView(viewConfig);
    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      shouldFetchRowCount,
      page,
      id: dataId,
    };

    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: {
              includeTotalResults: shouldFetchRowCount,
              pagingConfiguration: { page: (page || 1) - 1 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(viewParams),
          },
          customerId,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppReportDataApi = createAsyncThunk<
  FetchReportBuilderDatasetData,
  FetchAppReportDataArgs,
  DashboardState
>(
  ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA,
  async ({ postData, shouldFetchRowCount, id }, { dispatch }) => {
    const requestConfig = getAppRequest(postData);
    const onSuccess = shouldFetchRowCount
      ? () => dispatch(fetchAppReportRowCount({ postData, id }))
      : undefined;
    return makeThunkRequest(requestConfig, 'Error loading preview data', { onSuccess });
  },
);

const fetchAppReportDataJob =
  ({ postData, shouldFetchRowCount, id }: FetchAppReportDataArgs): Thunk =>
  (dispatch) => {
    const jobs: Record<string, JobDefinition> = {};
    const dataJob = createJob({
      job_type: ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB,
      job_args: { ...postData, id },
    });
    Object.assign(jobs, dataJob);

    if (shouldFetchRowCount) {
      const rowCountJob = createJob({
        job_type: ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB,
        job_args: { ...postData, id },
      });
      Object.assign(jobs, rowCountJob);
    }

    dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

export const fetchAppReportRowCount = createAsyncThunk<
  { row_count: number },
  FetchAppRowCountArgs,
  DashboardState
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT, async ({ postData }) => {
  const requestConfig = getRowCountRequestConfig(postData);
  return makeThunkRequest(requestConfig, 'Error loading row count report builder preview');
});

export const fetchAppDistinctColumnData =
  (column: BaseCol): Thunk =>
  (dispatch, getState) => {
    const { customers, embeddedReportBuilder, reportEditing, currentUser, fido } = getState();
    const { currentConfig, currentView } = reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    const customerId = customers.selectedGroupId;
    const dataset =
      embeddedReportBuilder.reportBuilderVersion?.config.datasets[
        currentConfig?.dataInfo?.datasetId ?? ''
      ];
    if (!dataset || !customerId || !viewConfig) return;

    const viewParams = getDistinctColumnBody(column);
    const postData: FetchReportBuilderDataBody = {
      ...viewParams,
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      column_configs: dataset.columnConfigs,
      page: 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      page: 1,
      id: getDistinctDataId(column),
    };

    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderView({
          id: fetchArgs.id,
          dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(viewParams),
          },
          customerId,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppAggData =
  (view: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { customers, embeddedReportBuilder, reportEditing, currentUser, fido } = getState();
    const { reportBuilderVersion } = embeddedReportBuilder;
    const customerId = customers.selectedGroupId;
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId;
    const dataset = datasetId && reportBuilderVersion?.config.datasets[datasetId];
    if (!dataset || !customerId) return;

    const columns = getFilterableRawColumns(view.columnOrder, dataset);
    const body = getAggBody(columns);
    if (!body.aggs.length) return;

    const postData: FetchReportBuilderDataBody = {
      ...body,
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      column_configs: dataset.columnConfigs,
      page: 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    const aggDataId = getAggDataId(dataset.id);
    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      page: 1,
      id: aggDataId,
    };

    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderView({
          id: aggDataId,
          dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(body),
          },
          customerId,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppTotalData =
  (view: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { customers, embeddedReportBuilder, reportEditing, currentUser, fido } = getState();
    const { reportBuilderVersion } = embeddedReportBuilder;
    const customerId = customers.selectedGroupId;
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId;
    const dataset = datasetId && reportBuilderVersion?.config.datasets[datasetId];
    const showTotals = reportBuilderVersion?.config?.general?.showTotals;
    if (!dataset || !customerId || !showTotals || !view.totals) return;

    const columns = getFilterableRawColumns(view.columnOrder, dataset);
    const body = getTotalBody(columns, view.filters, view.totals);
    if (!body.aggs.length) return;

    const postData: FetchReportBuilderDataBody = {
      ...body,
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      column_configs: dataset.columnConfigs,
      page: 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    const totalDataId = getTotalDataId(view.id);
    const fetchArgs: FetchAppReportDataArgs = { postData, page: 1, id: totalDataId };
    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderView({
          id: totalDataId,
          dataset,
          body: {
            dataRequestParameters: { pagingConfiguration: { page: 0 } },
            queryContext: {},
            computation: generateReportBuilderComputations(body),
          },
          customerId,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppAiData =
  (viewConfig: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { customers, embeddedReportBuilder, reportEditing, currentUser, fido } = getState();
    const customerId = customers.selectedGroupId;
    const dataset =
      embeddedReportBuilder.reportBuilderVersion?.config.datasets[
        reportEditing.currentConfig?.dataInfo?.datasetId ?? ''
      ];
    if (!dataset || !customerId) return;

    const viewParams = getViewRequestParams(viewConfig, dataset);
    const postData: FetchReportBuilderDataBody = {
      ...viewParams,
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: customerId,
      column_configs: dataset.columnConfigs,
      page: 1,
      timezone: getReportBuilderTimezone(embeddedReportBuilder),
    };

    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      shouldFetchRowCount: false,
      page: 1,
      id: getAiDataId(),
    };

    if (useFidoForReportBuilderRequest(customerId, customers.groups, currentUser, fido, dataset)) {
      dispatch(
        fetchFidoReportBuilderView({
          id: getAiDataId(),
          dataset: dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(viewParams),
          },
          customerId,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

type FetchAppReportDataArgs = {
  postData: FetchReportBuilderDataBody;
  shouldFetchRowCount?: boolean;
  id: string; // id is used in the reducer
  page: number | undefined; // page is used in the reducer
};

type FetchAppRowCountArgs = {
  postData: FetchReportBuilderDataBody;
  id: string; // id is used in the reducer
};

type DashboardState = {
  state: ReduxState;
};

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

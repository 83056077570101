import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedButton } from 'components/embed';
import * as styles from './index.css';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { SelectedReportType } from 'reportBuilderContent/reducers/types';
import { favoriteCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';

export type Props = { isStarred: boolean; reportInfo: SelectedReportType };

export const StarStatus: FC<Props> = ({ isStarred, reportInfo }) => {
  const dispatch = useDispatch();

  const isPreview = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.isPreview,
  );

  return (
    <EmbedButton
      className={isStarred ? styles.activeStarIcon : undefined}
      disabled={isPreview}
      icon={isStarred ? 'star' : 'regular-star'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(favoriteCustomerReportThunk(isStarred, reportInfo));
      }}
      variant="tertiary"
    />
  );
};

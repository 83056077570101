import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { DataPanelListItem } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelListItem';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/ColumnSection.css';

import { DraggableColumnInfo } from 'utils/customerReportUtils';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { showColumn, hideColumn } from 'reportBuilderContent/reducers/reportEditingReducer';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { COL_LIST_SECTION_ID } from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { getColDisplay } from 'utils/V2ColUtils';

type ColumnItemProps = {
  column: DraggableColumnInfo;
  columnConfigs?: ReportBuilderColConfigs;
};

export const ColumnItem: FC<ColumnItemProps> = ({ columnConfigs, column }) => {
  const dispatch = useDispatch();

  return (
    <DataPanelListItem
      actionButton={{
        variant: 'tertiary',
        className: styles.eyeIcon,
        icon: column.isHidden ? 'eye-closed' : 'eye-open',
        // For performance, data is not refetched even if you hide the column you're sorting by
        onClick: () => dispatch((column.isHidden ? showColumn : hideColumn)(column.name)),
      }}
      column={column}
      icon={SCHEMA_TYPE_ICON_MAP[column.type]}
      id={column.name}
      name={getColDisplay(column, columnConfigs)}
      section={COL_LIST_SECTION_ID}
    />
  );
};

import { FC } from 'react';
import * as RadixLabel from '@radix-ui/react-label';
import cx from 'classnames';

import { InfoIcon, Tooltip, Icon, sprinkles } from 'components/ds';

export type Props = {
  children: string;
  className?: string;
  htmlFor: string;
  infoText?: string;

  // This is true when the input this label is attached to accepts variable entry
  forVariableInput?: boolean;
};

export const Label: FC<Props> = ({ className, htmlFor, infoText, children, forVariableInput }) => {
  return (
    <RadixLabel.Root
      className={cx(
        sprinkles({
          flexItems: 'alignCenter',
          color: 'contentSecondary',
          body: 'b2',
          marginBottom: 'sp.5',
        }),
        className,
      )}
      htmlFor={htmlFor}>
      {children}
      {infoText ? (
        <InfoIcon className={sprinkles({ paddingLeft: 'sp.5' })} text={infoText} />
      ) : null}
      {forVariableInput ? (
        <Tooltip side="right" text="This field can take in variables">
          <Icon
            className={sprinkles({ color: 'gray8', marginLeft: 'sp.5' })}
            name="code"
            size="sm"
          />
        </Tooltip>
      ) : null}
    </RadixLabel.Root>
  );
};

import { FC } from 'react';
import * as styles from './CredentialsSection.css';
import copy from 'copy-to-clipboard';
import cx from 'classnames';

import { sprinkles, Switch, InfoIcon, TextArea, Select } from 'components/ds';
import { DataSourcePropertyOption, SupportedDataSource } from 'actions/dataSourceActions';
import { DBConnectionConfig } from 'pages/ConnectDataSourceFlow/types';
import InputWithTag from 'shared/InputWithTag';

import { getHelpText, getStatusInfo } from '../../utils';
import { showSuccessToast } from 'shared/sharedToasts';
import { DataSourceConfiguration } from 'actions/dataSourceActions';

type Props = {
  config: DBConnectionConfig;
  dataSource?: SupportedDataSource;
  propertyNames: string[];
  updateConfig: (newconfig: DBConnectionConfig) => void;
  userViewableCredentials?: DataSourceConfiguration;
  headerClassName?: string;
};

export const CredentialsSection: FC<Props> = ({
  config,
  dataSource,
  propertyNames,
  updateConfig,
  userViewableCredentials,
  headerClassName,
}: Props) => {
  const selectedDataSource = config.selectedDataSource ?? dataSource;
  if (!selectedDataSource) return null;

  const configurationSchema = selectedDataSource.configuration_schema;
  const configuration = config.dataSourceConfig || {};

  const renderProperty = (
    propertyName: string,
    property: DataSourcePropertyOption,
  ): JSX.Element | null => {
    if (
      property.depends_on &&
      !(configuration[property.depends_on] ?? userViewableCredentials?.[property.depends_on])
    ) {
      return null;
    }

    const userViewableCredentialProperty = userViewableCredentials?.[propertyName];
    const configValue = configuration[propertyName];

    const isEncryptedSavedValue = userViewableCredentialProperty === '_';

    const placeholder = userViewableCredentialProperty
      ? isEncryptedSavedValue && configValue === undefined
        ? `${propertyName} will be unmodified`
        : ''
      : property.placeholder;

    const savedValue =
      userViewableCredentialProperty && !isEncryptedSavedValue
        ? String(userViewableCredentialProperty)
        : undefined;

    if (property.type === 'checkbox') {
      const configBooleanValue = configValue as boolean;
      const savedToggleValue = userViewableCredentialProperty as boolean;
      return (
        <div
          className={cx(sprinkles({ display: 'flex' }), styles.credentialInput)}
          key={`add_source_field_${propertyName}`}>
          <Switch
            onChange={() => {
              const newDataSourceConfig = {
                ...configuration,
                [propertyName]: !(configBooleanValue ?? savedToggleValue),
              };
              updateConfig({ ...config, dataSourceConfig: newDataSourceConfig });
            }}
            switchOn={configBooleanValue ?? savedToggleValue}
          />
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', marginLeft: 'sp1' })}>
            <div className={sprinkles({ color: 'gray12', body: 'b2' })}>{property.label}</div>
            <InfoIcon text={property.help_tooltip} />
          </div>
        </div>
      );
    } else if (property.type === 'textarea') {
      const displayValue = (configValue ?? savedValue ?? '') as string;

      if (property.readonly) {
        return (
          <TextArea
            readOnly
            className={styles.publicKeyContainer}
            defaultValue={property.placeholder}
            key={`add_source_field_${propertyName}`}
            label={property.label}
            onClick={() => {
              copy(property.placeholder);
              showSuccessToast('Public key copied to clipboard');
            }}
          />
        );
      } else {
        return (
          <TextArea
            defaultValue={displayValue}
            key={`add_source_field_${propertyName}`}
            label={property.label}
            onSubmit={(newValue) => {
              const newDataSourceConfig = { ...configuration, [propertyName]: newValue };
              updateConfig({ ...config, dataSourceConfig: newDataSourceConfig });
            }}
            placeholder={placeholder}
          />
        );
      }
    } else if (property.type === 'grouping') {
      if (!property.options) return null;

      const options = Object.keys(property.options ?? {}).map((option) => ({
        value: option,
      }));

      if (!configValue && !userViewableCredentialProperty) {
        const newDataSourceConfig = { ...configuration, [propertyName]: options[0].value };
        updateConfig({ ...config, dataSourceConfig: newDataSourceConfig });
      }

      const selectedOption =
        options.find(
          (option) => option.value === (configValue ?? userViewableCredentialProperty),
        ) ?? options[0];

      return (
        <div key={`add_source_field_${propertyName}`}>
          <div className={headerClassName ?? styles.sectionHeader}>{property.label}</div>
          <Select
            className={styles.credentialInput}
            onChange={(value) => {
              const newDataSourceConfig = { ...configuration, [propertyName]: value };
              updateConfig({ ...config, dataSourceConfig: newDataSourceConfig });
            }}
            selectedValue={selectedOption.value}
            values={options}
          />
          {Object.entries(property.options[selectedOption.value]).map(([name, option]) =>
            renderProperty(name, option),
          )}
        </div>
      );
    } else {
      const displayValue = (configValue ?? savedValue ?? '') as string;

      const isValidDisplayValue =
        displayValue || property?.optional || (isEncryptedSavedValue && configValue === undefined);

      const isEmptyField =
        configValue === undefined && savedValue === undefined && !isEncryptedSavedValue;

      return (
        <InputWithTag
          className={styles.credentialInput}
          data-testid={`connect-datasource-${property.label}`}
          helpText={getHelpText(dataSource, property)}
          key={`add_source_field_${propertyName}`}
          label={property.label}
          onChange={(value) => {
            const newDataSourceConfig = {
              ...configuration,
              [propertyName]: property.type === 'number' ? parseInt(value) : value,
            };
            updateConfig({
              ...config,
              dataSourceConfig: newDataSourceConfig,
            });
          }}
          placeholder={placeholder}
          statusInfo={getStatusInfo(isEmptyField, Boolean(isValidDisplayValue))}
          type={property.type}
          value={displayValue}
        />
      );
    }
  };

  return (
    <>
      {propertyNames.map((propertyName: string) =>
        renderProperty(propertyName, configurationSchema.properties[propertyName]),
      )}
    </>
  );
};

import { FC } from 'react';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { Icon } from 'components/ds';
import * as styles from './index.css';

export type Props = {
  disabled?: boolean;
  isChecked: boolean;

  onChange: () => void;
};

export const EmbedCheckbox: FC<Props> = ({ disabled, isChecked, onChange }) => {
  return (
    <RadixCheckbox.Root
      checked={isChecked}
      className={styles.checkbox}
      disabled={disabled}
      onCheckedChange={onChange}>
      <RadixCheckbox.Indicator>
        <Icon name="check" size="sm" />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};

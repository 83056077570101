import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { loginGoogleSuccess, samlSignInSuccess, verifyEmail } from 'actions/authAction';
import {
  logInUserSuccess,
  updateUserNameSuccess,
  updateHasSeenHierarchyBannerSuccess,
  FetchProfileData,
  User,
} from 'actions/userActions';

import { userLoginAnalytics } from 'utils/landingPageUtils';
import { assignUserRoleSuccess } from 'actions/rolePermissionActions';
import { getUserPermissions } from 'utils/permissionUtils';
import { saveFromEmailNameSuccess } from 'actions/teamActions';

const initialUser: User = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  has_team: false,
  team: null,
  logged_in: false,
  email_verified: false,
  is_sso_account: false,
  is_superuser: false,
  has_seen_welcome_modal: false,
  has_seen_hierarchy_banner: false,
  can_create_team: false,
  permissions: {},
};

function loginUser(user: FetchProfileData): User {
  return {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    has_team: user.has_team,
    team: user.team,
    logged_in: true,
    email_verified: user.email_verified,
    is_sso_account: user.is_sso_account,
    is_superuser: user.is_superuser,
    has_seen_welcome_modal: user.profile_data?.has_seen_welcome_modal || false,
    has_seen_hierarchy_banner: user.profile_data?.has_seen_hierarchy_banner || false,
    can_create_team: user.can_create_team ?? false,
    permissions: getUserPermissions(user.roles),
  };
}

const authSlice = createSlice({
  name: 'dashboardLayout',
  initialState: () => initialUser,
  reducers: {
    toggleUseFido: (state) => {
      if (!state.team) return;
      state.team.feature_flags.use_fido = !state.team?.feature_flags.use_fido;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInUserSuccess, (state, { payload }) => {
        userLoginAnalytics(payload);
        state = loginUser(payload);
        return state;
      })
      .addCase(verifyEmail, (state) => {
        state.email_verified = true;
      })
      .addCase(updateUserNameSuccess, (state, { payload }) => {
        state.first_name = payload.first_name;
        state.last_name = payload.last_name;
      })
      .addCase(updateHasSeenHierarchyBannerSuccess, (state) => {
        state.has_seen_hierarchy_banner = true;
      })
      .addCase(assignUserRoleSuccess, (state, { payload }) => {
        if (state.id === payload.user_id) {
          const userRoleNames = new Set(payload.role_names);
          const userRoles = (state.team?.roles ?? []).filter((role) =>
            userRoleNames.has(role.name),
          );
          state.permissions = getUserPermissions(userRoles);
        }
      })
      .addCase(saveFromEmailNameSuccess, (state, { payload }) => {
        if (state.team) state.team.configuration.email_from_name = payload.postData.name;
      })
      .addMatcher(isAnyOf(loginGoogleSuccess, samlSignInSuccess), (state, { payload }) => {
        userLoginAnalytics(payload.user);
        state = loginUser(payload.user);
        return state;
      })
      .addDefaultCase((state) => state);
  },
});

export const { toggleUseFido } = authSlice.actions;
export const authReducer = authSlice.reducer;

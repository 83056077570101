import { FC, useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '@radix-ui/react-use-previous';
import { motion, animate, useMotionValue, useTransform } from 'framer-motion';
import { createDebouncedFn } from 'utils/general';

import { sprinkles, Tabs } from 'components/ds';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import { DataTab } from 'pages/ReportBuilder/ReportView/DataPanel/DataTab';
import { FormatTab } from 'pages/ReportBuilder/ReportView/DataPanel/FormatTab';
import { PanelSide, PanelHandle } from 'pages/ReportBuilder/ReportView/PanelHandle';
import * as styles from './index.css';

import { OPERATION_TYPES } from 'constants/types';
import {
  OPERATION_ICON_MAP,
  OPERATION_NAME_MAP,
  REPORT_BUILDER_VISUALIZATION_TYPES,
} from 'pages/ReportBuilder/constants';
import {
  toggleDataPanelOpen,
  setVisualization,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import {
  CustomerReportDataInfo,
  CustomerReportView,
  CustomerReportVisualization,
} from 'actions/customerReportActions';
import {
  setLocalMotionValue,
  getLocalMotionValue,
} from 'pages/ReportBuilder/ReportView/persistentMotionValue';

const MAX_WIDTH = 600;
const MIN_WIDTH = 250;
const DEFAULT_WIDTH = 328;
const DATA_PANEL_WIDTH_ID = 'data_panel_width'; // For local storage

type Props = { dataInfo: CustomerReportDataInfo; view: CustomerReportView };

export const DataPanel: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();

  const [debouncedWidth, setDebouncedWidth] = useState(0);
  const [tab, setTab] = useState(DataPanelTab.Data);

  const { versionConfig, isDataPanelOpen } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      isDataPanelOpen: state.reportEditing.isDataPanelOpen,
    }),
    shallowEqual,
  );

  // Negative since offsets are in cartesian coordinates from the handle's starting pos
  const motionValue = useMotionValue(-DEFAULT_WIDTH);
  const motionWidth = useTransform(motionValue, (v) => -v);
  useEffect(() => {
    const width = getLocalMotionValue(DATA_PANEL_WIDTH_ID, DEFAULT_WIDTH);
    motionValue.set(-width);
    setDebouncedWidth(width); // For some reason the onChange isn't called when motionValue.set is called here
  }, [motionValue]);

  const dataset = dataInfo ? versionConfig?.datasets[dataInfo.datasetId] : undefined;
  const isHidden = !versionConfig || !isDataPanelOpen || !dataset;
  const prevIsHidden = usePrevious(isHidden);
  useEffect(() => {
    // Animate the drilldown panel opening and closing
    if (prevIsHidden !== isHidden) {
      const to = isHidden ? 0 : -getLocalMotionValue(DATA_PANEL_WIDTH_ID, DEFAULT_WIDTH);
      animate(motionValue, to);
    }
  }, [motionValue, isHidden, prevIsHidden]);

  useEffect(
    () => motionWidth.onChange((latest) => debounceFn(() => setDebouncedWidth(latest))),
    [motionWidth],
  );

  const handleStopResizing = useCallback(() => {
    const value = motionValue.get();
    setLocalMotionValue(DATA_PANEL_WIDTH_ID, -value); // Offset is negative, invert for width

    // Automatically close the drilldown panel if it's close to the bottom
    if (!isHidden && Math.abs(value) < 10) dispatch(toggleDataPanelOpen());
  }, [dispatch, isHidden, motionValue]);

  const visualization = view.visualization || OPERATION_TYPES.VISUALIZE_TABLE;

  return (
    <>
      <div style={{ width: debouncedWidth }} />
      <motion.div
        className={styles.dataPanel}
        initial=""
        // CSS transforms are more performant, but width seems to be the only way to resize
        style={{ width: motionWidth }}>
        <div style={{ minWidth: 200, width: debouncedWidth }}>
          <DataPanelSection className={styles.subHeaderClass}>
            <EmbeddedDropdownMenu
              fillContainer
              menuOptions={REPORT_BUILDER_VISUALIZATION_MENU_OPTIONS}
              onClick={(value) => dispatch(setVisualization(value as CustomerReportVisualization))}>
              <EmbeddedDropdownButton
                className={sprinkles({ width: 'fill' })}
                icon={OPERATION_ICON_MAP[visualization]}
                selectedName={OPERATION_NAME_MAP[visualization]}
              />
            </EmbeddedDropdownMenu>
          </DataPanelSection>
          <Tabs
            onTabSelect={(tab) => setTab(tab as DataPanelTab)}
            selectedTabId={tab}
            tabs={TABS_LIST}
          />
          {tab === DataPanelTab.Data ? (
            <DataTab dataInfo={dataInfo} view={view} />
          ) : tab === DataPanelTab.Format ? (
            <FormatTab view={view} />
          ) : null}
        </div>
      </motion.div>
      <PanelHandle
        isVisible
        dragConstraints={{ left: -MAX_WIDTH, right: -MIN_WIDTH }}
        motionValue={motionValue}
        onStop={handleStopResizing}
        side={PanelSide.Left}
      />
    </>
  );
};

enum DataPanelTab {
  Data = 'Data',
  Format = 'Format',
}

const TABS_LIST = Object.values(DataPanelTab);

const REPORT_BUILDER_VISUALIZATION_MENU_OPTIONS = REPORT_BUILDER_VISUALIZATION_TYPES.map(
  (type) => ({
    value: type,
    name: OPERATION_NAME_MAP[type] ?? type,
    icon: OPERATION_ICON_MAP[type],
  }),
);

const debounceFn = createDebouncedFn(150);

import { FC } from 'react';
import cx from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';

import { Icon, sprinkles } from 'components/ds';
import { ResourceConfigurationMenu } from 'shared/ExploResource/ResourceConfigurationMenu';
import * as styles from './styles.css';

import { ReduxState } from 'reducers/rootReducer';
import { Dashboard } from 'actions/dashboardActions';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { canUserViewResourceConfigurationMenu } from 'utils/permissionUtils';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { isCreateResourceDisabled } from 'utils/paymentPlanUtils';

type Props = {
  className?: string;
  createResourceDisabled?: boolean;
  dashboardAttributes?: DashboardAttribute[];
  resource: Dashboard | ReportBuilder;
  emailCadence?: ExploreEmailCadence;
  pageType: ResourcePageType;
  showDeveloperSettings?: boolean;

  openConfigurabilityModal?: () => void;
};

export const EditResourceBannerDropdown: FC<Props> = ({
  className,
  resource,
  emailCadence,
  dashboardAttributes,
  pageType,
  showDeveloperSettings,
  openConfigurabilityModal,
}) => {
  const { currentUser, resources } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      resources: state.dashboard.dashboardList,
    }),
    shallowEqual,
  );

  const createResourceDisabled = isCreateResourceDisabled(
    pageType === ResourcePageType.EXPLORE,
    resources?.length || 0,
    currentUser.team?.payment_plan,
  );

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const title = (
    <div className={sprinkles({ truncateText: 'ellipsis', heading: 'h2' })}>{resource.name}</div>
  );

  if (!canUserViewResourceConfigurationMenu(currentUser, resourcePermissionEntity)) {
    return <div className={cx(styles.resourceBannerDropdown, className)}>{title}</div>;
  }

  const trigger = (
    <div
      className={cx(
        styles.resourceBannerDropdown,
        sprinkles({
          cursor: 'pointer',
          backgroundColor: { hover: 'elevationMid', active: 'elevationLow' },
        }),
        className,
      )}>
      {title}
      <Icon name="caret-down" />
    </div>
  );

  return (
    <ResourceConfigurationMenu
      createResourceDisabled={createResourceDisabled}
      dashboardAttributes={dashboardAttributes}
      emailCadence={emailCadence}
      onDelete={() => (window.location.href = '/home')}
      openConfigurabilityModal={openConfigurabilityModal}
      pageType={pageType}
      resource={resource}
      resourcePermissionEntity={resourcePermissionEntity}
      showDeveloperSettings={!!showDeveloperSettings}
      trigger={trigger}
    />
  );
};

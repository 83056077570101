import './global.css';
import { Intent, baseThemeName, vars } from './vars.css';
import { sprinkles } from './sprinkles.css';

import { AlertModal } from './AlertModal';
import { Breadcrumbs } from './Breadcrumbs';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { DataGrid } from './DataGrid';
import { ErrorFallback } from './ErrorFallback';
import { Icon } from './Icon';
import { IconButton } from './IconButton';
import { InfoIcon } from './InfoIcon';
import { Input } from './Input';
import { Label } from './Label';
import { Link } from './Link';
import {
  Menu,
  MenuSub,
  MenuActionItem,
  MenuSwitchItem,
  MenuLabel,
  MenuSeparator,
  MenuItemCustom,
} from './Menu';
import { Modal } from './Modal';
import { NavigationToggle } from './NavigationToggle';
import { PageError } from './PageError';
import { Popover } from './Popover';
import { Select } from './Select';
import { SideSheet } from './SideSheet';
import { Spinner } from './Spinner';
import { Switch } from './Switch';
import { Tabs, Tab } from './Tabs';
import { Tag } from './Tag';
import { Tooltip } from './Tooltip';
import { Toggle, ToggleItem, BooleanToggle } from './Toggle';
import { CalloutLink } from './CalloutLink';
import { TextArea } from './TextArea';

export {
  baseThemeName,
  sprinkles,
  vars,
  AlertModal,
  Breadcrumbs,
  Button,
  Checkbox,
  DataGrid,
  ErrorFallback,
  Icon,
  IconButton,
  InfoIcon,
  Input,
  Intent,
  Label,
  Link,
  Menu,
  MenuSub,
  MenuActionItem,
  MenuSwitchItem,
  MenuItemCustom,
  MenuLabel,
  MenuSeparator,
  Modal,
  PageError,
  Popover,
  Select,
  Spinner,
  Switch,
  Tag,
  Tooltip,
  SideSheet,
  NavigationToggle,
  Tabs,
  Tab,
  Toggle,
  ToggleItem,
  CalloutLink,
  BooleanToggle,
  TextArea,
};

export const APP_PORTAL_ID = 'explo-app';

export type Colors = keyof typeof vars.colors;

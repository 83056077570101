import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import parse from 'url-parse';

import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { ROUTES } from 'constants/routes';

import { samlSignIn } from 'actions/authAction';
import { sendPing } from 'actions/pingActions';
import { PingTypes } from 'constants/types';
import { pingCustomerOnlineMessage } from 'utils/landingPageUtils';

export const SamlSignInPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const urlQueryParams = parse(window.location.href, true).query;
  const token = urlQueryParams.token;

  useEffect(() => {
    dispatch(
      samlSignIn(
        { postData: { token: token ?? '' } },
        (response) => {
          Cookies.set('spheres_auth_token', response.token, { sameSite: 'Strict' });
          dispatch(
            sendPing({
              postData: {
                message: pingCustomerOnlineMessage(response.user),
                message_type: PingTypes.PING_ONLINE,
              },
            }),
          );
          window.location.href = '/home';
        },
        () => history.push(ROUTES.LOGIN),
      ),
    );
  }, [history, token, dispatch]);

  return (
    <OnboardingFlowPage
      helpLinks={[{ name: 'Need Support?', url: 'https://docs.explo.co/' }]}
      rightContentTitle={'Logging you in... Sit tight!'}
      rightPanelContent={<></>}
    />
  );
};

import { FC, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { EditableVariableList } from './EditableVariableList';
import RawVariableList from './RawVariableList';
import { CustomVariablesModal } from './CustomVariablesModal';
import { Button, sprinkles } from 'components/ds';

import { filterDpsWithDrilldowns } from 'utils/drilldownUtils';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { isChartUsingMultipleColorCategories } from 'utils/colorColUtils';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  dashboardId: number;
  timezone: string;
};

export const VariablesDebugger: FC<Props> = ({ dashboardId, timezone }) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [isCustomVariableModalOpen, setIsCustomVariableModalOpen] = useState(false);

  const { elements, variables, params, dataPanels } = useSelector(
    (state: ReduxState) => ({
      elements: state.dashboardEditConfig.config?.elements ?? {},
      variables: state.dashboardData.variables ?? {},
      params: state.dashboardEditConfig.config?.params ?? {},
      dataPanels: state.dashboardEditConfig.config?.data_panels ?? {},
    }),
    shallowEqual,
  );

  const filterElems = useMemo(
    () => Object.values(elements).filter((elem) => FILTER_ELEMENTS.has(elem.element_type)),
    [elements],
  );

  const dpsWithDrilldowns = useMemo(
    () => filterDpsWithDrilldowns(Object.values(dataPanels)),
    [dataPanels],
  );

  const dataPanelsWithColorCategories = useMemo(
    () =>
      Object.values(dataPanels).filter((dp) =>
        isChartUsingMultipleColorCategories(dp.visualize_op),
      ),
    [dataPanels],
  );

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <div className={buttonContainerClass}>
        <Button className={sprinkles({ flex: 1 })} onClick={() => setInEditMode(!inEditMode)}>
          {inEditMode ? 'Preview' : 'Edit'}
        </Button>
        <Button
          className={sprinkles({ marginLeft: 'sp1', flex: 3 })}
          onClick={() => setIsCustomVariableModalOpen(true)}>
          Manage Custom Variables
        </Button>
      </div>
      {inEditMode ? (
        <EditableVariableList
          dashboardInputElements={filterElems}
          dashboardParams={params}
          timezone={timezone}
          variables={variables}
        />
      ) : (
        <RawVariableList
          dashboardInputElements={filterElems}
          dashboardParams={params}
          dataPanelsWithColorCategories={dataPanelsWithColorCategories}
          dataPanelsWithDrilldown={dpsWithDrilldowns}
          variables={variables}
        />
      )}
      {isCustomVariableModalOpen ? (
        <CustomVariablesModal
          dashboardId={dashboardId}
          dashboardParams={params}
          onClose={() => setIsCustomVariableModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

const buttonContainerClass = sprinkles({
  flexItems: 'alignCenterBetween',
  padding: 'sp1.5',
});

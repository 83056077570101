import { FC, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Icon, Popover, Tooltip, sprinkles, vars } from 'components/ds';
import { EmbedButton, EmbedInfoIcon } from 'components/embed';
import { EditableSectionLayout } from './EditableSectionLayout';
import ResizeObserver from 'react-resize-observer';
import * as styles from './index.css';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { ResourceDataset } from 'types/exploResource';
import { DashboardElement, VIEW_MODE } from 'types/dashboardTypes';
import { EditingLayout, setIsEditingEditableSection } from 'reducers/dashboardInteractionsReducer';
import { getChartIcon } from 'constants/chartIcons';
import { getLayoutMargin } from 'utils/layoutUtils';
import * as utils from 'utils/editableSectionUtils';
import { getEditableSectionConfig, getEditableSectionLayout } from 'reducers/selectors';
import {
  addChartToEditableSectionThunk,
  removeChartFromEditableSectionThunk,
} from 'reducers/thunks/editableSectionThunks';
import { Customer, EmbedCustomer } from 'actions/teamActions';
import { DashboardStates } from 'reducers/rootReducer';
import { handleEditableSectionClickThunk } from 'reducers/thunks/dashboardSelectionThunks';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';

type Props = {
  customer: EmbedCustomer | Customer | undefined;
  datasets: Record<string, ResourceDataset>;
  elements: DashboardElement[];
  globalStyleConfig: GlobalStyleConfig;
  isEditingDashboard: boolean;
  viewMode: VIEW_MODE;
};

export const EditableSection: FC<Props> = ({
  customer,
  elements,
  datasets,
  globalStyleConfig,
  isEditingDashboard,
  viewMode,
}) => {
  const dispatch = useDispatch();

  // Better to use the layout and config from store so only this part rerenders
  const layout = useSelector(getEditableSectionLayout);
  const config = useSelector(getEditableSectionConfig);

  const { editingLayout, isEditing, isEditingDisabled, variables } = useSelector(
    (state: DashboardStates) => ({
      editingLayout: state.dashboardInteractions.editingLayout,
      isEditing: state.dashboardInteractions.isEditingEditableSection,
      isEditingDisabled: state.dashboardInteractions.interactionsInfo.disableEditingEditableSection,
      variables: state.dashboardData.variables,
    }),
    shallowEqual,
  );

  const [isChartMenuOpen, setIsChartMenuOpen] = useState(false);
  const [isMouseInSection, setIsMouseInSection] = useState(false);
  const [width, setWidth] = useState<number>();

  const shouldRender = utils.shouldRenderEditableSection(layout, viewMode);

  const isLayoutEmpty = !layout?.length;

  // If view mode or editing mode changes turn off editing mode
  useEffect(() => {
    dispatch(setIsEditingEditableSection(false));
  }, [dispatch, viewMode, isEditingDashboard]);

  // If is editing set as false if layout becomes empty
  useEffect(() => {
    if (!isEditing || !isLayoutEmpty) return;
    dispatch(setIsEditingEditableSection(false));
  }, [dispatch, isEditing, isLayoutEmpty]);

  const chartsInLayout = useMemo(() => new Set(layout?.map((elem) => elem.i) ?? []), [layout]);

  const chartsToAdd = useMemo(
    () => utils.filterChartsForCustomer(config?.charts, customer),
    [config?.charts, customer],
  );

  // Config should always be defined up to this point but checking for TS
  if (!config || !shouldRender) return null;

  const disableAddCharts = chartsToAdd.length === 0;
  const margin = getLayoutMargin(viewMode, globalStyleConfig);
  const isEditingAllowed = utils.canEditEditableSection(viewMode) && !isEditingDisabled;
  const cols = globalStyleConfig.base.numColumns;

  const renderEmptySection = () => {
    return (
      <div style={{ padding: margin }}>
        <div
          className={styles.emptySection}
          style={{ height: utils.getEmptySectionHeight(margin) }}>
          <div className={embedSprinkles({ body: 'secondary' })}>
            {isEditingDashboard
              ? 'Add a chart to set the default layout for your customers'
              : 'Add a chart to customize this section of your dashboard.'}
          </div>
        </div>
      </div>
    );
  };

  const renderAddChartMenu = () => {
    return (
      <div
        className={cx(
          styles.addChartMenu,
          embedSprinkles({
            backgroundColor: 'containerFill',
            body: 'primary',
            borderRadius: 'container',
          }),
          GLOBAL_STYLE_CLASSNAMES.container.outline.border,
          GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
        )}>
        {chartsToAdd.map(({ data_panel: dp, name }) => {
          const isInLayout = chartsInLayout.has(dp.id);
          return (
            <div
              className={cx(
                styles.chartMenuOption,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownItemHover,
              )}
              key={dp.id}
              onClick={() => {
                if (isEditingDashboard) return;
                if (!isInLayout) {
                  dispatch(addChartToEditableSectionThunk(layout, dp, cols));
                } else if (layout) {
                  dispatch(removeChartFromEditableSectionThunk(layout, dp.id));
                }
                setIsChartMenuOpen(false);
              }}>
              <div
                className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', overflow: 'hidden' })}>
                {getChartIcon(
                  dp.visualize_op.operation_type,
                  24,
                  vars.customTheme.colors.action,
                  vars.customTheme.colors.interaction,
                )}
                <div className={styles.chartName} title={name}>
                  {name}
                </div>
              </div>
              <div
                className={cx(
                  styles.iconStyle,
                  isInLayout ? styles.checkIconStyle : styles.plusIconStyle,
                )}>
                <Icon name={isInLayout ? 'tick' : 'plus'} size="sm" />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const wrapInTooltip = (button: JSX.Element, tooltipText: string | undefined) => {
    if (!tooltipText) return button;
    return <Tooltip text={tooltipText}>{button}</Tooltip>;
  };

  const renderEditButtons = () => (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
      {wrapInTooltip(
        <EmbedButton
          disabled={isLayoutEmpty || isEditingDashboard}
          icon={isEditing ? 'tick' : 'up-down-left-right'}
          onClick={() => dispatch(setIsEditingEditableSection(!isEditing))}
          variant="primary">
          {isEditing ? 'Done Editing' : 'Edit'}
        </EmbedButton>,
        isEditingDashboard
          ? 'To edit the default layout use the config panel on the left.'
          : undefined,
      )}
      {wrapInTooltip(
        <div>
          <Popover
            align="end"
            isOpen={isChartMenuOpen}
            onOpenChange={(isOpen) => setIsChartMenuOpen(isOpen && !disableAddCharts)}
            trigger={
              <EmbedButton disabled={disableAddCharts} icon="plus" variant="primary">
                Add Chart
              </EmbedButton>
            }
            width="large">
            {renderAddChartMenu()}
          </Popover>
        </div>,
        isEditingDashboard
          ? 'To toggle charts from the default layout use the config panel on the left.'
          : undefined,
      )}
    </div>
  );

  // This section is used to highlight when editable section is being edited. Needed to
  // be like this because hover states are funky with react grid layout
  const renderBorderSection = () => {
    const isEditingLayout = editingLayout === EditingLayout.EDITABLE_SECTION;
    // Dividing by two to move the border away from the edge of the dashboard.
    // The two pixels is so that data panel outlines don't touch the border.
    // Came to this solution with Carly.
    const borderMargin = margin / 2 - 2;

    return (
      <div
        className={cx(styles.borderContainer, {
          [styles.selectedBorderContainer]: isEditingLayout,
          [styles.hoverBorderContainer]: isMouseInSection && !isEditingLayout,
        })}
        style={{
          // Needed the opposite for the top margin so this is the reverse of borderMargin
          marginTop: -(margin / 2 + 2),
          marginLeft: borderMargin,
          marginRight: borderMargin,
          marginBottom: borderMargin,
        }}
      />
    );
  };

  const settings = config.settings;

  // Used for border section above
  const onMouseEnter = isEditingDashboard ? () => setIsMouseInSection(true) : undefined;
  const onMouseLeave = isEditingDashboard ? () => setIsMouseInSection(false) : undefined;

  return (
    <div
      className={cx(
        embedSprinkles({ backgroundColor: 'background' }),
        sprinkles({ position: 'relative' }),
      )}
      onClick={(e) => {
        dispatch(handleEditableSectionClickThunk());
        e.stopPropagation();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {/* Used to show selection state of section */}
      {isEditingDashboard ? renderBorderSection() : null}
      <div className={styles.headerContainer} style={{ margin, marginBottom: 0 }}>
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <div className={embedSprinkles({ heading: 'h1' })}>
            {replaceVariablesInString(settings.title, variables)}
          </div>
          {settings.tooltipText ? <EmbedInfoIcon text={settings.tooltipText} /> : null}
        </div>
        {isEditingAllowed ? renderEditButtons() : null}
      </div>
      {!isLayoutEmpty ? (
        <EditableSectionLayout
          cols={cols}
          config={config}
          datasets={datasets}
          elements={elements}
          isEditing={isEditing}
          isEditingDashboard={isEditingDashboard}
          isViewOnly={!isEditingAllowed}
          layout={layout}
          margin={margin}
          variables={variables}
          viewMode={viewMode}
          width={width}
        />
      ) : (
        renderEmptySection()
      )}
      <ResizeObserver onResize={(resize) => setWidth(resize.width)} />
    </div>
  );
};

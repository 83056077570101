import { FC } from 'react';

import { ConfigSection } from 'components/resource/ConfigSection';
import { DatasetColumnFormatItem } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetColumnFormatItem';
import * as sharedStyles from 'components/resource/index.css';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

type Props = {
  dataset: ReportBuilderDataset;
  updateConfig: (col: string, updates: { name?: string; description?: string }) => void;
};

export const DatasetColumnFormat: FC<Props> = ({ dataset, updateConfig }) => {
  return (
    <ConfigSection title="Column formatting">
      <div className={sharedStyles.configSection}>
        <p className={sharedStyles.sectionText}>
          Customize how data will be formatted and displayed.
        </p>
        {dataset.schema?.map((col) => {
          const columnConfig = dataset.columnConfigs[col.name];
          if (!columnConfig?.isVisible) return null;

          return (
            <DatasetColumnFormatItem
              col={col}
              columnConfig={columnConfig}
              dataset={dataset}
              key={col.name}
              updateColumnInfo={(updates) => updateConfig(col.name, updates)}
            />
          );
        })}
      </div>
    </ConfigSection>
  );
};

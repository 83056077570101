import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';

import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { Input, Button, sprinkles, Link } from 'components/ds';

import { createTeam, addUserToTeam, getUserTeamInvite, logOutUser } from 'actions/authAction';
import { logInUserSuccess } from 'actions/userActions';
import { isUUID } from 'utils/general';
import { createLoadingSelector } from 'reducers/api/selectors';
import { pageView } from 'analytics/exploAnalytics';
import { ROUTES } from 'constants/routes';
import { ACTION } from 'actions/types';
import { showErrorToast } from 'shared/sharedToasts';
import { fetchProfile } from 'auth/userAuth';

enum PAGE_ACTION {
  JOIN_TEAM,
  CREATE_TEAM,
}

export const JoinTeamPage: FC = () => {
  const { currentUser, addUserToTeamLoading, createTeamLoading } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      addUserToTeamLoading: createLoadingSelector([ACTION.ADD_USER_TO_TEAM], false)(state),
      createTeamLoading: createLoadingSelector([ACTION.CREATE_TEAM], false)(state),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [inviteHash, setInviteHash] = useState('');
  const [fetchedTeamName, setFetchedTeamName] = useState('');
  const [numTeamUsers, setNumTeamUsers] = useState(0);
  const [pageAction, setPageAction] = useState(PAGE_ACTION.JOIN_TEAM);

  useEffect(() => {
    pageView('Join Team');

    dispatch(
      getUserTeamInvite({}, (response) => {
        if (response.team_name) {
          setInviteHash(response.invite_hash);
          setFetchedTeamName(response.team_name);
          setNumTeamUsers(response.num_users);
        }
      }),
    );
  }, [dispatch]);

  const onSuccess = () => {
    fetchProfile(
      (user) => {
        dispatch(logInUserSuccess(user));
        history.push(ROUTES.TELL_US_ABOUT_YOU);
      },
      () => dispatch(logOutUser()),
    );
  };

  const onJoinTeam = () => {
    if (currentUser.id && (isUUID(inviteCode) || inviteHash)) {
      dispatch(
        addUserToTeam(
          currentUser.id,
          inviteCode,
          onSuccess,
          (errorMsg) => {
            showErrorToast(
              errorMsg && errorMsg.status === 403
                ? `You are already logged in with ${currentUser.email}. 
                   If this is the wrong account, please logout with the link in the top right.`
                : 'Incorrect invite code. Please ensure there are no spaces or tabs.',
              10,
            );
          },
          inviteHash,
        ),
      );
    } else {
      showErrorToast('Invalid Invite Code', 10);
    }
  };

  const renderTeamBody = () => (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
      <Input
        data-testid="sign-up-team-input"
        defaultValue={teamName}
        onSubmit={setTeamName}
        placeholder="Team name"
      />
      <Button
        fillWidth
        data-testid="sign-up-team-submit"
        disabled={teamName === '' || addUserToTeamLoading}
        loading={createTeamLoading}
        onClick={() =>
          dispatch(createTeam(teamName, onSuccess, (errorMsg) => showErrorToast(errorMsg, 10)))
        }>
        Create a Team
      </Button>
    </div>
  );

  const renderRequestTrialBody = () => (
    <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp1', marginTop: 'sp2' })}>
      <div className={sprinkles({ heading: 'h2', color: 'contentPrimary' })}>
        Start your 7 day free trial
      </div>
      <Link fillWidth to="https://www.explo.co/request-a-trial" variant="primary">
        Request free trial
      </Link>
    </div>
  );

  const renderCreateTeamButton = () => (
    <Button
      fillWidth
      className={sprinkles({ marginTop: 'sp1.5' })}
      data-testid="sign-up-create-team"
      disabled={addUserToTeamLoading}
      loading={addUserToTeamLoading}
      onClick={() => setPageAction(PAGE_ACTION.CREATE_TEAM)}>
      Create a New Team
    </Button>
  );

  const renderDivider = () => (
    <div className={sprinkles({ flex: 1, backgroundColor: 'gray6' })} style={{ height: 1 }} />
  );

  const renderJoinBody = () => (
    <>
      {fetchedTeamName ? (
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <div
            className={sprinkles({
              flexItems: 'alignCenterBetween',
              flex: 1,
              padding: 'sp1',
              borderRadius: 4,
              backgroundColor: 'elevationMid',
              marginBottom: 'sp1',
            })}>
            <div>{fetchedTeamName}</div>
            <div className={sprinkles({ color: 'active' })}>
              {numTeamUsers} {numTeamUsers === 1 ? 'member' : 'members'}
            </div>
          </div>
          <Button loading={addUserToTeamLoading} onClick={onJoinTeam}>
            Join
          </Button>
        </div>
      ) : null}
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <Input
          className={sprinkles({ flex: 1 })}
          defaultValue={inviteCode}
          id="invite_code"
          name="invite_code"
          onSubmit={setInviteCode}
          placeholder="000-000-000-000"
        />
        <Button loading={addUserToTeamLoading} onClick={onJoinTeam}>
          Join
        </Button>
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        {renderDivider()}
        <div className={sprinkles({ textAlign: 'center', color: 'gray10' })}>or</div>
        {renderDivider()}
      </div>
      {currentUser.can_create_team ? renderCreateTeamButton() : renderRequestTrialBody()}
    </>
  );

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Log Out', onClick: () => dispatch(logOutUser()) },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      rightContentTitle={
        pageAction === PAGE_ACTION.CREATE_TEAM
          ? 'Create a new team and begin your 7 day trial!'
          : 'Join an existing team'
      }
      rightPanelContent={
        <div className={sprinkles({ width: 'fill' })}>
          {pageAction === PAGE_ACTION.CREATE_TEAM ? renderTeamBody() : renderJoinBody()}
        </div>
      }
    />
  );
};

import { Customer, SummaryCustomer } from 'actions/teamActions';
import * as RD from 'remotedata';
import { CustomersReducerState } from './customersReducer';

export const updateInheritedFields = (
  currentCustomer: Customer | SummaryCustomer,
  updatedCustomer: Customer | SummaryCustomer,
) => {
  currentCustomer.computed_group_tags = updatedCustomer.computed_group_tags;
  currentCustomer.computed_properties = updatedCustomer.computed_properties;
  currentCustomer.computed_parent_schema_datasource_mapping =
    updatedCustomer.computed_parent_schema_datasource_mapping;
  currentCustomer.parent_provided_id = updatedCustomer.parent_provided_id;
  currentCustomer.parent_names = updatedCustomer.parent_names;
};

export const updateHierarchyWithNewCustomer = (
  state: CustomersReducerState,
  payloadCustomer: Customer,
) => {
  // Get new hierarchy level counts
  RD.update(state.hierarchyMetadata, (metadata) => {
    const hierarchyLevelData = metadata.levelCounts.find(
      (data) => data.hierarchy_level_id === payloadCustomer.hierarchy_level_id,
    );
    if (hierarchyLevelData) {
      hierarchyLevelData.count = hierarchyLevelData.count + 1;
    } else {
      metadata.levelCounts.push({
        hierarchy_level_id: payloadCustomer.hierarchy_level_id,
        count: 1,
      });
    }
  });

  // Increment parent's child count
  if (payloadCustomer.parent_provided_id !== undefined)
    RD.update(state.filteredCustomerData, (customerData) => {
      const parent = customerData.customers.find(
        (customer) =>
          customer.provided_id === payloadCustomer.parent_provided_id &&
          customer.access_group_id === payloadCustomer.access_group_id,
      );
      if (parent) parent.children_count = parent.children_count + 1;
    });
};

export const updateHierarchyWithDeletedCustomer = (
  state: CustomersReducerState,
  deletedCustomerId: string | number,
  hierarchyGroupId: number | undefined,
  parentProvidedId: string | undefined,
  accessGroupId: number | undefined,
) => {
  // Update hierarchy counts
  if (hierarchyGroupId !== undefined)
    RD.update(state.hierarchyMetadata, (metadata) => {
      const hierarchyGroup = metadata.levelCounts.find(
        (data) => data.hierarchy_level_id === hierarchyGroupId,
      );
      if (hierarchyGroup) hierarchyGroup.count = hierarchyGroup.count - 1;
    });

  // Update parent's child count
  if (parentProvidedId !== undefined)
    RD.update(state.filteredCustomerData, (customerData) => {
      const parent = customerData.customers.find(
        (customer) =>
          customer.provided_id === parentProvidedId && customer.access_group_id === accessGroupId,
      );
      if (parent) parent.children_count = parent.children_count - 1;
    });
};

export const updateHierarchyWithEditedCustomer = (
  state: CustomersReducerState,
  payloadCustomer: Customer,
  oldHierarchyGroupId: number | undefined,
) => {
  // Update hierarchy level counts
  RD.update(state.hierarchyMetadata, (metadata) => {
    const newHierarchyGroupLevelId = payloadCustomer.hierarchy_level_id;
    if (oldHierarchyGroupId === newHierarchyGroupLevelId) return;
    const newHierarchyGroup = metadata.levelCounts.find(
      (data) => data.hierarchy_level_id === newHierarchyGroupLevelId,
    );
    const oldHierarchyGroup = metadata.levelCounts.find(
      (data) => data.hierarchy_level_id === oldHierarchyGroupId,
    );

    if (oldHierarchyGroup) {
      oldHierarchyGroup.count = oldHierarchyGroup.count - 1;
    }

    if (newHierarchyGroup) {
      newHierarchyGroup.count = newHierarchyGroup.count + 1;
    } else {
      metadata.levelCounts.push({
        hierarchy_level_id: newHierarchyGroupLevelId,
        count: 1,
      });
    }
  });
};

import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { sortBy } from 'utils/standard';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';

import * as RD from 'remotedata';

import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import { ErrorState } from 'components/ErrorState';
import { Button, DataGrid, Icon, Intent, Select, Spinner, sprinkles } from 'components/ds';

import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';

import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { parseErrorMessage } from 'utils/queryUtils';
import { ROUTES } from 'constants/routes';
import { showCustomToast } from 'shared/sharedToasts';
import { getNamespaces, fetchFidoTablePreview } from 'reducers/thunks/fidoThunks';
import { FidoTableView } from 'reducers/fidoReducer';
import { ReduxState } from 'reducers/rootReducer';

const SIDEBAR_WIDTH = 250;

export const ManageFidoDataTablesPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    params: { dataSourceId },
  } = useRouteMatch<{ dataSourceId: string }>();

  const [selectedDataset, setSelectedDataset] = useState<FidoTableView>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { fidoDaos, dataSources, parentSchemas, tableView, tableViewData } = useSelector(
    (state: ReduxState) => ({
      fidoDaos: state.fido.fidoDaos,
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      tableView: state.fido.tableView,
      tableViewData: state.fido.tableViewData,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getNamespaces());
  }, [dispatch, parentSchemas, dataSources]);

  useEffect(() => {
    if (RD.isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  useEffect(() => {
    if (RD.isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  const { namespaces, fidoDataSources, tables } = useMemo(
    () =>
      RD.isSuccess(fidoDaos)
        ? {
            namespaces: fidoDaos.data.namespaces,
            fidoDataSources: fidoDaos.data.dataSources,
            tables: fidoDaos.data.tables,
          }
        : { namespaces: [], fidoDataSources: [], tables: [], schemaTablesMap: {} },
    [fidoDaos],
  );

  const dataSource = useMemo(() => {
    return fidoDataSources.find((ds) => ds.id === parseInt(dataSourceId));
  }, [fidoDataSources, dataSourceId]);

  const namespace = useMemo(() => {
    if (!dataSource) return;
    return namespaces.find((ns) => ns.id === dataSource.parent_schema_id);
  }, [dataSource, namespaces]);

  useEffect(() => {
    if (RD.isSuccess(fidoDaos) && fidoDaos.data.tables.length === 0 && namespace) {
      showCustomToast(
        <div>
          This schema has not had its tables synced to Explo.
          <br />
          <br />
          This means that you cannot view your data in this view and that autocomplete and schema
          inferring will not work in your dashboard editors.
          <br />
          <br />
          Click{' '}
          <a href={`/sync-tables/${namespace.id}`} rel="noopener noreferrer" target="_blank">
            here
          </a>{' '}
          to sync tables
        </div>,
        { timeoutInSeconds: 10, icon: 'info-sign', intent: Intent.SUCCESS },
      );
    }
  }, [fidoDaos, namespace]);

  const dataSourceOptions = useMemo(() => {
    if (!namespace) return;
    return fidoDataSources
      .filter((ds) => ds.parent_schema_id === namespace.id)
      .map((dataSource) => ({
        value: dataSource.id.toString(),
        label: dataSource.name,
      }));
  }, [namespace, fidoDataSources]);

  const tableOptions = useMemo(() => {
    if (RD.isSuccess(fidoDaos)) {
      const datasets = sortBy(
        Object.values(tables.filter((t) => t.namespaceId === namespace?.fido_id)),
        (t) => t.tableName,
      );
      if (datasets.length > 0) setSelectedDataset(datasets[0]);
      return datasets;
    }
    return [];
  }, [tables, namespace, fidoDaos]);

  const getTablePreview = useCallback(
    (tableIdentifier: string, page: number, newDataSourceId?: string) => {
      const dataSourceId = newDataSourceId ? newDataSourceId : dataSource?.fido_id;

      if (!dataSourceId || !namespace?.fido_id || tableOptions.length === 0) return;
      dispatch(
        fetchFidoTablePreview({
          dataSourceId,
          namespaceId: namespace.fido_id,
          body: {
            tableIdentifier,
            dataRequestParameters: {
              pagingConfiguration: { perPage: 50, page: page - 1 },
              includeTotalResults: true,
            },
          },
        }),
      );
    },
    [dataSource, namespace, tableOptions, dispatch],
  );

  useEffect(() => {
    if (RD.isIdle(tableView) && tableOptions.length > 0) {
      getTablePreview(tableOptions[0].tableName, currentPage);
    }
  }, [currentPage, getTablePreview, tableOptions, tableView]);

  if (RD.isError(fidoDaos) || RD.isError(dataSources) || RD.isError(parentSchemas)) {
    return (
      <ErrorState text="An error has occurred fetching data sources and tables for this schema." />
    );
  }

  const renderDatasetsSidebar = () => {
    if (
      RD.isLoadingMulti([fidoDaos, dataSources, parentSchemas]) ||
      !fidoDataSources ||
      !dataSourceOptions
    ) {
      return <Spinner fillContainer size="lg" />;
    }

    // Should never get here
    if (!namespace || !dataSource) return;

    return (
      <>
        <div className={sprinkles({ padding: 'sp1' })}>
          <span className={sprinkles({ heading: 'h4' })}>{namespace.name.toUpperCase()}</span>
          <Select
            className={sprinkles({ marginTop: 'sp1' })}
            onChange={(value) => {
              history.replace(`/datasources/${value}`);
              const newDataSource = fidoDataSources.find((ds) => ds.id === parseInt(value));
              if (!newDataSource || !selectedDataset) return;
              getTablePreview(selectedDataset.tableName, currentPage, newDataSource.fido_id);
            }}
            selectedValue={dataSource.id.toString()}
            values={dataSourceOptions}
          />
        </div>
        <div
          className={sprinkles({ backgroundColor: 'outline', marginY: 'sp.5' })}
          style={{ minHeight: 1 }}
        />
        <div
          className={sprinkles({
            padding: 'sp1',
            flex: 1,
            flexItems: 'column',
            overflow: 'hidden',
          })}>
          <div className={sprinkles({ heading: 'h4', marginBottom: 'sp1' })}>Source Datasets</div>
          <div className={sprinkles({ flexItems: 'column', overflowY: 'auto', flex: 1 })}>
            {tableOptions.map((dataset) => (
              <DatasetMenuItem
                active={selectedDataset?.id === dataset.id}
                key={`dataset-navbar-item-${dataset.id}`}
                name={dataset.tableName}
                onClick={() => {
                  setSelectedDataset(dataset);
                  getTablePreview(dataset.tableName, currentPage);
                  trackEvent(EVENTS.SELECTED_DATASET, {
                    dataset_id: dataset.id,
                    dataset_name: dataset.tableName,
                  });
                }}
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderDatasetViewer = () => {
    if (RD.isError(tableView)) {
      const errorDescription = parseErrorMessage(tableView.error);
      return (
        <div
          className={sprinkles({ backgroundColor: 'white', flexItems: 'center', height: 'fill' })}>
          <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp2' })}>
            <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="xl" />
            <div className={sprinkles({ heading: 'h2' })}>
              There was an error fetching the results
            </div>
            {errorDescription ? (
              <div className={sprinkles({ body: 'b2' })}>{errorDescription}</div>
            ) : null}
            <Button
              icon="arrow-up-right-from-square"
              onClick={() => alert(tableView.error)}
              variant="tertiary">
              View full error
            </Button>
          </div>
        </div>
      );
    }

    if (tableViewData === undefined) return <DataGrid loading />;

    // Should never get here
    if (!namespace || !dataSource) return;

    if (RD.isSuccess(fidoDaos) && tables.length === 0) {
      return (
        <div className={unSyncedTablesClass}>
          <Link to={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${namespace.id}`}>
            <h2>No source datasets were found, you may need to first sync your tables.</h2>
          </Link>
        </div>
      );
    }

    const paginatorProps: PaginatorProps = {
      totalRowCount: tableViewData.totalResults || undefined,
      currentPage: currentPage,
      loading: RD.isLoading(tableView),
      goToPage: ({ page }) => {
        if (!selectedDataset) return;
        getTablePreview(selectedDataset.tableName, page);
        setCurrentPage(page);
      },
    };

    return (
      <DataGrid
        loading={RD.isLoading(tableView)}
        paginatorProps={paginatorProps}
        rows={tableViewData.rows}
        schema={tableViewData.schema}
      />
    );
  };

  return (
    <div className={rootClass}>
      <div className={sidebarClass} style={{ width: SIDEBAR_WIDTH }}>
        {renderDatasetsSidebar()}
      </div>
      <div className={datasetViewerClass}>{renderDatasetViewer()}</div>
    </div>
  );
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  flexItems: 'alignCenter',
  justifyContent: 'flex-start',
  overflow: 'auto',
});

const datasetViewerClass = sprinkles({ parentContainer: 'fill', overflow: 'auto' });

const unSyncedTablesClass = sprinkles({
  flexItems: 'center',
  textAlign: 'center',
  parentContainer: 'fill',
  heading: 'h4',
});

const sidebarClass = sprinkles({
  height: 'fill',
  backgroundColor: 'white',
  overflowY: 'auto',
  borderRight: 1,
  borderColor: 'gray3',
});

import React, { useEffect, useState } from 'react';
import { isLoading, isSuccess } from 'remotedata';
import { DownloadInfo, clearDownloads } from 'reducers/dashboardLayoutReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardStates } from 'reducers/rootReducer';
import { ExportConfig } from 'constants/types';

export type WindowState = { popupBlocked: boolean; downloadBlocked: boolean };

const InitWindowState: WindowState = { downloadBlocked: false, popupBlocked: false };

/**
 * Automatically downloads any file exports for a given data panel
 *
 * @param dataPanelId - If undefined, uses dashboardExport instead of a specific dataPanelId
 */
export function useDownload(dataPanelId: string | undefined, downloadDisabled?: boolean) {
  const dispatch = useDispatch();
  const [windowState, setWindowState] = useState<WindowState>(InitWindowState);
  const downloadInfo = useSelector((state: DashboardStates) =>
    dataPanelId
      ? state.dashboardLayout.dpDownloads[dataPanelId]
      : state.dashboardLayout.dashboardExport,
  );

  useEffect(() => {
    if (downloadDisabled) return;
    return () => {
      dispatch(clearDownloads(dataPanelId));
    };
  }, [dispatch, dataPanelId, downloadDisabled]);

  useEffect(() => {
    if (downloadDisabled) return;
    handleDownload(downloadInfo, setWindowState);
  }, [downloadInfo, downloadDisabled]);

  return { windowState, downloadInfo };
}

function handleDownload(
  download: DownloadInfo | undefined,
  setStateFunc: React.Dispatch<React.SetStateAction<WindowState>>,
): void {
  if (download === undefined) return;
  if (isSuccess(download.status) && download.type === 'url') {
    const openedWindow = window.open(download.status.data);
    if (openedWindow === null) setStateFunc({ downloadBlocked: false, popupBlocked: true });
    handleDownloadBlocked(
      () => setStateFunc({ popupBlocked: false, downloadBlocked: true }),
      openedWindow,
    );
  } else if (isLoading(download.status)) {
    setStateFunc(InitWindowState);
  }
}

const handleDownloadBlocked = (
  updateStateOnDownloadBlocked: () => void,
  openedWindow: Window | null,
) =>
  /*
   * If the dashboard is run in an iframe sandbox, the popup will appear but the
   * actual download will be blocked, so we need to manually prompt the user to download it.
   * 1 second seems like a good enough window since if the download goes through the window
   * should almost immediately open and then close.
   */

  setTimeout(function () {
    if (openedWindow && !openedWindow.closed) {
      updateStateOnDownloadBlocked();
      openedWindow.close();
    }
  }, 1000);

export const isChartExportEnabled = (config: ExportConfig | undefined, isTable: boolean) => {
  if (!config) return true;

  // All exports disabled
  if (config.isDownloadButtonHidden) return false;

  // Neither download nor email is turned on
  if (config.disableDownloadExport && !config.enableEmailExport) return false;

  // At least one csv export is enabled
  if (!config.disableCsvExport || !config.disableXlsxExport) return true;

  // If here, only enabled if its a table and pdf is enabled
  return isTable && !config.disablePdfDownload;
};

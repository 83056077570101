import { FC } from 'react';
import produce from 'immer';
import cx from 'classnames';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { sprinkles } from 'components/ds';
import { EmbedCheckbox, EmbedInput } from 'components/embed';

import { UserTransformedSchema } from 'constants/types';
import * as styles from './index.css';

type Props = {
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

export const TransformationSettingsMenu: FC<Props> = ({ schema, updateSchema }) => {
  return (
    <div
      className={cx(sprinkles({ padding: 'sp1', overflowY: 'auto' }))}
      style={{ maxHeight: 350 }}>
      <SortableList
        getIdFromElem={(col) => col.name}
        onListUpdated={updateSchema}
        sortableItems={schema}>
        {schema.map((col, index) => (
          <SortableListItem key={col.name} sortId={col.name}>
            <TransformationRow
              index={index}
              key={`${index}-${col}`}
              schema={schema}
              updateSchema={updateSchema}
            />
          </SortableListItem>
        ))}
      </SortableList>
    </div>
  );
};

type TransformationRowProps = {
  index: number;
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

const TransformationRow: FC<TransformationRowProps> = ({ index, schema, updateSchema }) => {
  const column = schema[index];

  return (
    <div
      className={sprinkles({
        padding: 'sp.5',
        flexItems: 'alignCenter',
        gap: 'sp.5',
      })}>
      <SortableListItemDragHandle className={sprinkles({ color: 'contentTertiary' })} />
      <EmbedCheckbox
        isChecked={column.isVisible}
        onChange={() => {
          const newSchema = produce(schema, (draft) => {
            draft[index].isVisible = !draft[index].isVisible;
          });

          updateSchema(newSchema);
        }}
      />
      <EmbedInput
        className={styles.transformationRowInput}
        defaultValue={column.friendly_name}
        onSubmit={(newColumnName) => {
          const newSchema = produce(schema, (draft) => {
            draft[index].friendly_name = newColumnName;
          });

          updateSchema(newSchema);
        }}
      />
    </div>
  );
};

import { FC, useState } from 'react';

import { Input, Modal, sprinkles } from 'components/ds';

type Props = {
  buttonName: string;
  closeModal: () => void;
  getErrorMessage?: (textFieldVal?: string) => string | undefined;
  loading?: boolean;
  modalOpen: boolean;
  modalTitle: string;
  onSubmit: (text: string) => void;
  portalContainerId?: string;
  resourceName?: string;
  textFieldPlaceholder: string;
};

export const TextFieldModal: FC<Props> = ({
  buttonName,
  closeModal,
  getErrorMessage,
  portalContainerId,
  loading,
  modalOpen,
  modalTitle,
  onSubmit,
  resourceName,
  textFieldPlaceholder,
}) => {
  const [textFieldVal, setTextFieldVal] = useState(resourceName ?? '');

  const errorMsg = getErrorMessage?.(textFieldVal);

  const onButtonClick = () => {
    if (getErrorMessage?.(textFieldVal)) return;
    if (resourceName === textFieldVal) return closeModal();
    onSubmit(textFieldVal);
    setTextFieldVal('');
  };

  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      portalContainerId={portalContainerId}
      primaryButtonProps={{
        disabled: !!errorMsg || !textFieldVal.trim() || loading,
        loading: loading,
        onClick: onButtonClick,
        text: buttonName,
      }}
      size="small"
      title={modalTitle}>
      <div className={sprinkles({ paddingX: 'sp3' })} style={{ minHeight: 56 }}>
        <Input
          errorText={errorMsg}
          onChange={setTextFieldVal}
          placeholder={textFieldPlaceholder}
          value={textFieldVal}
        />
      </div>
    </Modal>
  );
};

import {
  MouseEvent as ReactMouseEvent,
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  forwardRef,
  Ref,
} from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import * as styles from './index.css';
import { sprinkles } from 'components/ds';
import { Icon, IconName } from 'components/ds/Icon';
import { Tooltip, Props as TooltipProps } from 'components/ds/Tooltip';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'destructive';

type BaseProps = {
  // Text to display in button
  children?: string;
  icon?: IconName;
  // React ref to pass for interacting with DOM instance
  parentRef?: Ref<HTMLButtonElement | HTMLAnchorElement>;
  fillWidth?: boolean;
  // Shows an arrow to the right of the content for links
  linkIcon?: boolean;
  tooltipProps?: TooltipProps;
  // Determines style of the button
  variant?: ButtonVariant;
};

// Allows component to take in any props that an <a> can take
// Omits props that we use for this component
type HTMLAnchorProps = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'onClick' | 'disabled' | 'children' | 'ref'
>;

export interface LinkProps extends BaseProps, HTMLAnchorProps {
  disabled?: never;
  loading?: never;
  onClick?: never;
  // URL to link to. https:// is an anchor, otherwise is relative React Router
  to: string;
}

// Allows component to take in any props a <button> can take
// Omits props that we use for this component
type HTMLButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick' | 'disabled' | 'children' | 'ref'
>;
export interface ButtonProps extends BaseProps, HTMLButtonProps {
  disabled?: boolean;
  href?: never;
  loading?: boolean;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  to?: never;
}

export type Props = ButtonProps | LinkProps;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      className,
      children,
      disabled,
      fillWidth,
      icon,
      linkIcon,
      loading,
      onClick,
      parentRef,
      to,
      tooltipProps,
      variant = 'primary',
      ...props
    },
    ref,
  ) => {
    const elementRef = parentRef ?? ref;
    const iconOnly = icon && !children;
    const sharedProps = {
      ...props,
      className: cx(
        styles.base({
          disabled: disabled || loading,
          fillWidth,
          iconOnly,
          variant,
        }),
        className,
      ),
    };

    const childElements = (
      <>
        {icon ? (
          <Icon
            className={!iconOnly ? sprinkles({ paddingRight: 'sp1' }) : undefined}
            name={icon}
            size="md"
          />
        ) : null}
        {children}
        {linkIcon ? (
          <Icon className={sprinkles({ paddingLeft: 'sp1' })} name="arrow-right" size="md" />
        ) : null}
      </>
    );

    let baseElement = null;
    if (to) {
      const isAnchor = /^https?:\/\//.test(to);
      if (isAnchor) {
        baseElement = (
          <a
            {...(sharedProps as LinkProps)}
            href={to}
            ref={elementRef as Ref<HTMLAnchorElement>}
            rel="noopener noreferrer"
            target="_blank">
            {childElements}
          </a>
        );
      } else {
        baseElement = (
          <Link {...(sharedProps as LinkProps)} ref={elementRef as Ref<HTMLAnchorElement>} to={to}>
            {childElements}
          </Link>
        );
      }
    } else {
      baseElement = (
        <button
          {...(sharedProps as ButtonProps)}
          aria-label={`${icon} icon`}
          disabled={disabled || loading}
          onClick={onClick}
          ref={elementRef as Ref<HTMLButtonElement>}>
          {loading ? (
            <>
              <div className={sprinkles({ position: 'absolute' })}>
                <Icon spin name="spinner" size="md" />
              </div>
              <div className={sprinkles({ opacity: 0 })}>{childElements}</div>
            </>
          ) : (
            childElements
          )}
        </button>
      );
    }

    if (tooltipProps?.text) {
      return (
        <Tooltip {...tooltipProps}>
          {disabled ? <span tabIndex={0}>{baseElement}</span> : baseElement}
        </Tooltip>
      );
    }

    return baseElement;
  },
);

Button.displayName = 'Button';

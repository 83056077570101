import { createSlice } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import { fetchAiSuggestion } from './thunks/aiThunks';
import { fetchEditorDatasetPreviewPrimaryData } from './thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { fetchEditorDatasetPreviewRequest } from 'actions/datasetActions';

type Suggestion = {
  revisedQuery: string | null;
  explanation: string;
};

interface AiReducerState {
  suggestion: RD.ResponseData<Suggestion>;
}

const initialState: AiReducerState = {
  suggestion: RD.Idle(),
};

const aiReducerSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // on dataset fetch in the editor, clear the state
      .addCase(fetchEditorDatasetPreviewPrimaryData.pending, () => initialState)
      .addCase(fetchEditorDatasetPreviewRequest, () => initialState)
      .addCase(fetchAiSuggestion.fulfilled, (state, { payload }) => {
        state.suggestion = RD.Success({
          revisedQuery: payload.query ?? null,
          explanation: payload.explanation,
        });
      })
      .addCase(fetchAiSuggestion.rejected, (state, { error }) => {
        state.suggestion = RD.Error(error.message ?? 'Something went wrong');
      })
      .addCase(fetchAiSuggestion.pending, (state) => {
        state.suggestion = RD.Loading();
      });
  },
});

export const aiReducer = aiReducerSlice.reducer;

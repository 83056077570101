import { useMemo } from 'react';
import produce from 'immer';

import { PivotTotal } from 'pages/ReportBuilder/ReportView/PivotTotal';

import { vars } from 'components/ds';
import { generatePivotColumns, PivotColumns } from 'components/embed/EmbedPivotTable/pivotUtils';
import { DatasetSchema } from 'types/datasets';
import { TotalAccumulator } from 'actions/customerReportActions';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import { ColumnConfigs } from 'types/columnTypes';

import { EmbedPivotTableRow } from 'components/embed/EmbedPivotTable';

type Params = {
  columnConfigs: ColumnConfigs;
  schema: DatasetSchema;
  pivotColumns?: string[];
  groupByColumns?: string[];
  totalAccumulator?: TotalAccumulator;
};

export const useReportPivotColumns = ({
  columnConfigs,
  groupByColumns,
  pivotColumns,
  schema,
  totalAccumulator,
}: Params): PivotColumns | undefined => {
  const gridColumns = useMemo(() => {
    if (!groupByColumns) return;

    const { columns, groupColumn } = generatePivotColumns({
      columnConfigs,
      groupByColumns,
      pivotColumns,
      schema,
    });

    const paddedGroupColumn = produce(groupColumn, (groupColumn) => {
      if (!groupColumn.headerProps) groupColumn.headerProps = { style: {} };
      if (!groupColumn.headerProps.style) groupColumn.headerProps.style = {};
      groupColumn.headerProps.style.paddingLeft = vars.spacing['sp.5']; // Padding gets applied 4 times for the header for some reason
      if (!groupColumn.style) groupColumn.style = {};
      groupColumn.style.paddingLeft = vars.spacing.sp2;
    });

    const totalColumns: TypeColumn[] = produce(columns, (existingColumns) => {
      for (const column of existingColumns) {
        const { render } = column;
        column.render = (params: RenderColumnParams) => {
          const { data, cellProps } = params;
          if (data.leaf) return render?.(params);

          // Grouped cells display aggregated totals for their children
          return (
            <PivotTotal
              cellId={cellProps.id}
              columnConfigs={columnConfigs}
              keyPath={data.keyPath}
              pivotColumnPath={cellProps.pivotColumnPath}
              schema={schema}
              totalAccumulator={totalAccumulator}
            />
          );
        };
      }
    });

    return {
      columns: totalColumns,
      groupColumn: paddedGroupColumn,
    };
  }, [columnConfigs, groupByColumns, pivotColumns, totalAccumulator, schema]);

  return gridColumns;
};

type RenderColumnParams = {
  value: string;
  row: EmbedPivotTableRow;
  data: {
    leaf: boolean; // If false, this is a group cell
    keyPath: string[]; // Row's key. i.e. keyPath is ['Ontario'] for first group, ['Ontario', 'Toronto'] for second group, etc.
    fieldPath: string[]; // For pivots, column names for the grouping. i.e. keyPath is ['Province'] for first group, ['Province', 'City'] for second group, etc.
  };
  cellProps: {
    id: string; // id of the column. If pivot, format is <group_by_column_name>:<group_by_column_value>-<pivot_column_name>-<pivot_column_value>. i.e. "neighbourhood_group:Bronx-host_id_sum"
    pivotColumnPath?: string[]; // Only for pivots. If pivoting by province and city, then it's ['province', 'city']. i.e. ['ontario', 'toronto']
  };
};

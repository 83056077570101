import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AlertModal } from 'components/ds';

import { ReduxState } from 'reducers/rootReducer';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { deleteDashboardElement, deleteDataPanel } from 'actions/dashboardV2Actions';
import { EVENTS, trackEvent } from 'analytics/exploAnalytics';
import { setDeleteSelectedItemModalOpen } from 'reducers/dashboardInteractionsReducer';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';

export const DeleteElementModal: FC = () => {
  const dispatch = useDispatch();

  const { modalOpen, selectedItem } = useSelector(
    (state: ReduxState) => ({
      modalOpen: state.dashboardInteractions.deleteSelectedItemModalOpen,
      selectedItem: state.dashboardInteractions.selectedItem,
    }),
    shallowEqual,
  );

  if (!modalOpen || !selectedItem) return null;

  const isDataPanel = selectedItem.type === DASHBOARD_ELEMENT_TYPES.DATA_PANEL;
  const modalTitle = `Are you sure you want to delete this ${
    isDataPanel ? 'data panel?' : 'element?'
  }`;

  const onDelete = () => {
    if (isDataPanel) {
      dispatch(deleteDataPanel({ id: selectedItem.id }));
      trackEvent(EVENTS.DELETED_DATA_PANEL, {
        data_panel_template_id: selectedItem.id,
      });
    } else {
      dispatch(
        deleteDashboardElement({ elementId: selectedItem.id, elementType: selectedItem.type }),
      );
      trackEvent(EVENTS.DELETED_DASHBOARD_ELEMENT, {
        element_id: selectedItem.id,
        element_type: selectedItem.type,
      });
    }

    dispatch(clearSelectedItemThunk());
  };

  return (
    <AlertModal
      isOpen
      actionButtonProps={{ onClick: onDelete }}
      onClose={() => dispatch(setDeleteSelectedItemModalOpen(false))}
      title={modalTitle}
    />
  );
};

import { FC, useMemo } from 'react';

import { NeedsConfigurationPanel } from '../../needsConfigurationPanel';
import { ExplorePivotTable } from 'components/embed/EmbedPivotTable/ExplorePivotTable';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { isPivotTableV2ReadyToDisplay } from 'utils/dataPanelConfigUtils';
import { DataPanelData } from 'types/dataPanelTemplate';
import { getExplorePivotSchemaAndConfigs } from 'utils/V2ColUtils';

type Props = {
  instructions: V2PivotTableInstructions | undefined;
  data: DataPanelData | undefined;
};

export const PivotTableV2: FC<Props> = ({ instructions, data }) => {
  const { schema, columnConfigs, pivotColumns, groupByColumns } = useMemo(
    () => getExplorePivotSchemaAndConfigs(instructions),
    [instructions],
  );

  if (!isPivotTableV2ReadyToDisplay(instructions)) {
    return <NeedsConfigurationPanel instructionsNeedConfiguration />;
  }

  return (
    <ExplorePivotTable
      columnConfigs={columnConfigs}
      groupByColumns={groupByColumns ?? []}
      loading={data?.loading ?? true}
      pivotColumns={pivotColumns ?? []}
      rows={data?.rows}
      schema={schema}
    />
  );
};

import { FC, useMemo, useState } from 'react';
import { sortBy } from 'utils/standard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Modal, sprinkles } from 'components/ds';
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

import { ReduxState } from 'reducers/rootReducer';
import { Dataset } from 'actions/datasetActions';
import { titleCase } from 'utils/graphUtils';
import { setAddDataPanelState } from 'reducers/dashboardInteractionsReducer';
import { createDashboardDataPanel } from 'actions/dashboardV2Actions';
import { KPI_VIZ_OPS } from 'constants/dataConstants';
import { EVENTS, trackEvent } from 'analytics/exploAnalytics';
import { getEditableDatasets } from 'reducers/selectors';

type Props = {
  dashboardId: number;
};

export const AddDataPanelModal: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();

  const { addDataPanelState, datasets } = useSelector(
    (state: ReduxState) => ({
      addDataPanelState: state.dashboardInteractions.addDataPanelState,
      datasets: getEditableDatasets(state),
    }),
    shallowEqual,
  );

  const orderedDatasets = useMemo(
    () => sortBy(Object.values(datasets ?? {}), 'table_name'),
    [datasets],
  );

  if (!addDataPanelState) return null;

  const renderDatasetItem = (dataset: Dataset) => {
    if (!dataset.schema?.length) return null;

    return (
      <MenuItem
        className={
          dataset.id === selectedDatasetId ? sprinkles({ backgroundColor: 'lightBlue' }) : undefined
        }
        icon="th"
        key={`dataset-add-data-panel-${dataset.id}`}
        onClick={() => setSelectedDatasetId(dataset.id)}
        text={dataset.table_name || 'Untitled'}
      />
    );
  };

  const onCreateClicked = () => {
    const dataset = datasets?.[selectedDatasetId ?? ''];
    if (!dataset) return;

    const { opType, layout, containerId } = addDataPanelState;

    const id = `dash${dashboardId}-${uuidv4()}`;
    // we want the title to be undefined for KPIs so that we can make the title
    // dynamically default to the aggregation selected by the user
    const name = KPI_VIZ_OPS.has(opType) ? undefined : titleCase(dataset.table_name);

    dispatch(
      createDashboardDataPanel({
        id,
        name,
        newLayout: layout,
        datasetId: dataset.id,
        vizType: opType,
        containerId,
      }),
    );
    trackEvent(EVENTS.ADDED_DATA_PANEL, {
      dashboard_template_id: dashboardId,
      selected_dataset_id: dataset.id,
    });
  };

  const closeModal = () => dispatch(setAddDataPanelState(null));

  return (
    <Modal
      isOpen
      onClose={closeModal}
      primaryButtonProps={{
        disabled: !selectedDatasetId,
        onClick: onCreateClicked,
        text: 'Create',
      }}
      secondaryButtonProps={{
        onClick: closeModal,
        text: 'Cancel',
      }}
      size="small"
      title="Add a new data panel">
      <div className={sprinkles({ paddingX: 'sp3', flexItems: 'column', margin: 'sp0' })}>
        <div className={sprinkles({ marginBottom: 'sp2.5', heading: 'h3' })}>Select a table</div>
        <div className={modalBodyListClass} style={{ height: '40vh' }}>
          <Menu>
            <MenuDivider title="Dashboard Datasets" />
            {orderedDatasets.length === 0 ? (
              <MenuItem disabled text="No datasets. Create one!" />
            ) : (
              orderedDatasets.map(renderDatasetItem)
            )}
          </Menu>
        </div>
      </div>
    </Modal>
  );
};

const modalBodyListClass = sprinkles({
  backgroundColor: 'white',
  overflowY: 'auto',
  border: 1,
  borderColor: 'gray2',
  borderRadius: 4,
  marginBottom: 'sp3',
});

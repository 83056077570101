import { Middleware, Dispatch } from 'redux';

import { Action, ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import {
  updateColorCategoryTracker,
  saveDataInfo,
  applyFilter,
  deleteFilter,
  swapSelectedColumns,
  updateSort,
  setVisualization,
  createView,
  openBuiltInForEdit,
  openBuiltIn,
  openCustomerReport,
  deleteView,
  setCurrentView,
  reorderColumns,
  deleteAgg,
  updateAgg,
  addAgg,
  deleteGroupBy,
  updateGroupBy,
  addGroupBy,
  setViewPage,
  updateSortCol,
  setColumnTotalAgg,
  updateDrilldownFilters,
  setDrilldownPage,
  updateDrilldownSort,
  toggleDrilldownPanelOpen,
  showColumn,
} from '../reducers/reportEditingReducer';
import { getCurrentView } from 'utils/customerReportUtils';
import {
  fetchAppReportData,
  fetchAppAggData,
  fetchAppTotalData,
  fetchAppDrilldownData,
} from 'reportBuilderContent/thunks/appDataThunks';
import {
  fetchEmbeddedReportData,
  fetchEmbeddedAggData,
  fetchEmbeddedTotalData,
  fetchEmbeddedDrilldownData,
} from 'reportBuilderContent/thunks/embeddedDataThunks';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { SetViewPage } from 'actions/customerReportActions';
import {
  getAggDataId,
  getTotalDataId,
  getDrilldownDataId,
} from 'reportBuilderContent/reducers/reportEditingUtils';
import { createCustomerReport } from 'reportBuilderContent/thunks/reportThunks';
import { ReportData } from 'reportBuilderContent/reducers/types';

// Actions after new data is fetched
const FETCH_DATASET_ACTIONS = new Set([
  `${ACTION.FETCH_CUSTOMER_DATASET_PREVIEW}/fulfilled`,
  `${ACTION.FETCH_REPORT_BUILDER_PREVIEW_MODAL_DATA}/fulfilled`,
  `${ACTION.FETCH_CUSTOMER_REPORT_DATA}/fulfilled`,
  `${ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA}/fulfilled`,
  `${ACTION.FETCH_CUSTOMER_REPORT_MIN_MAX_COLUMN}/fulfilled`,
  `${ACTION.FETCH_REPORT_BUILDER_PREVIEW_DISTINCT_COLUMN}/fulfilled`,
  `${ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB}_SUCCESS`,
  `${ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB}_SUCCESS`,
]);

// Actions that don't modify the view config so if the data is loaded, don't reload it
const READ_VIEW_ACTIONS = new Set([
  openCustomerReport.type,
  createCustomerReport.fulfilled.type,
  openBuiltInForEdit.type,
  openBuiltIn.type,
  createView.type,
  deleteView.type,
  setCurrentView.type,
]);

// Actions that modify the view config so if the data is loaded, clear it and reload it
const UPDATE_DATA_ACTIONS = new Set([
  saveDataInfo.type,
  applyFilter.type,
  deleteFilter.type,
  swapSelectedColumns.type,
  updateSort.type,
  setVisualization.type,
  reorderColumns.type,
  deleteAgg.type,
  updateAgg.type,
  addAgg.type,
  deleteGroupBy.type,
  updateGroupBy.type,
  addGroupBy.type,
  setViewPage.type,
  updateSortCol.type,
  showColumn.type, // hideColumn doesn't need to reload data
]);

// Actions that modify the dataset
const UPDATE_DATASET_ACTIONS = new Set([saveDataInfo.type]);

// Actions that don't affect totals so if the data is loaded, don't reload it
const READ_TOTAL_ACTIONS = new Set([
  ...READ_VIEW_ACTIONS,
  saveDataInfo.type,
  swapSelectedColumns.type,
  updateSort.type,
  setVisualization.type,
  reorderColumns.type,
  deleteGroupBy.type,
  updateGroupBy.type,
  addGroupBy.type,
  setViewPage.type,
  updateSortCol.type,
  deleteAgg.type,
  updateAgg.type,
  addAgg.type,
]);

// Actions that modify the view config so if the data is loaded, clear it and reload it
const UPDATE_TOTAL_ACTIONS = new Set([applyFilter.type, deleteFilter.type, setColumnTotalAgg.type]);

// Actions that don't affect totals so if the data is loaded, don't reload it
const READ_DRILLDOWN_ACTIONS = new Set([saveDataInfo.type, toggleDrilldownPanelOpen.type]);

// Actions that modify the view config so if the data is loaded, clear it and reload it
const UPDATE_DRILLDOWN_ACTIONS = new Set([
  setDrilldownPage.type,
  updateDrilldownFilters.type,
  updateDrilldownSort.type,
  showColumn.type, // hideColumn doesn't need to reload data
]);

export const reportBuilderSharedMiddleware: Middleware<{}, ReportBuilderReduxState> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const response = next(action);
    if (FETCH_DATASET_ACTIONS.has(action.type)) {
      // Color category tracker uses state from both the data rows and embeddedReportBuilder, so it needs to be a middleware
      const { styleConfig, reportBuilderVersion } = getState().embeddedReportBuilder;
      dispatch(
        updateColorCategoryTracker({
          rows: action.payload.rows,
          globalStyleConfig: styleConfig,
          datasets: reportBuilderVersion?.config.datasets,
        }),
      );
    }

    return response;
  };

export const reportBuilderDashboardMiddleware: Middleware<{}, ReduxState> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const response = next(action);

    const { isInApp } = getState().embeddedReportBuilder;
    const shouldFetch = shouldFetchViewData(getState, action.type);
    if (shouldFetch) {
      const page = getViewPage(action);
      if (isInApp) fetchAppReportData(page)(dispatch, getState, {}); // Dashboard
      else fetchEmbeddedReportData(page)(dispatch, getState, {}); // iFrame
    }

    const aggView = shouldFetchAggData(getState, action.type);
    if (aggView) {
      if (isInApp) fetchAppAggData(aggView)(dispatch, getState, {});
      else fetchEmbeddedAggData(aggView)(dispatch, getState, {});
    }

    const totalView = shouldFetchTotalData(getState, action.type);
    if (totalView) {
      if (isInApp) fetchAppTotalData(totalView)(dispatch, getState, {});
      else fetchEmbeddedTotalData(totalView)(dispatch, getState, {});
    }

    const drilldownView = shouldFetchDrilldownData(getState, action.type);
    if (drilldownView) {
      const page = getDrilldownPage(action);
      if (isInApp) fetchAppDrilldownData(page)(dispatch, getState, {});
      else fetchEmbeddedDrilldownData(page)(dispatch, getState, {});
    }

    return response;
  };

export const reportBuilderEmbeddedDataMiddleware: Middleware<{}, ReportBuilderReduxState> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const response = next(action);
    const shouldFetch = shouldFetchViewData(getState, action.type);
    if (shouldFetch) {
      const page = getViewPage(action);
      fetchEmbeddedReportData(page)(dispatch, getState, {}); // Web Component
    }

    const aggView = shouldFetchAggData(getState, action.type);
    if (aggView) {
      fetchEmbeddedAggData(aggView)(dispatch, getState, {});
    }

    const totalView = shouldFetchTotalData(getState, action.type);
    if (totalView) {
      fetchEmbeddedTotalData(totalView)(dispatch, getState, {});
    }

    const drilldownView = shouldFetchDrilldownData(getState, action.type);
    if (drilldownView) {
      const page = getDrilldownPage(action);
      fetchEmbeddedDrilldownData(page)(dispatch, getState, {});
    }

    return response;
  };

const shouldFetchViewData = (getState: () => ReportBuilderReduxState, actionType: string) => {
  const isRead = READ_VIEW_ACTIONS.has(actionType);
  if (isRead) {
    const { reportData, currentView } = getState().reportEditing;
    const viewData = currentView ? reportData[currentView] : undefined;
    const isLoaded = isDataLoaded(viewData);
    if (isLoaded) return false;
  }

  const isUpdate = UPDATE_DATA_ACTIONS.has(actionType);
  if (isRead || isUpdate) {
    const { currentConfig, currentView } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    return !!viewConfig;
  }

  return false;
};

const shouldFetchAggData = (getState: () => ReportBuilderReduxState, actionType: string) => {
  const isRead = READ_VIEW_ACTIONS.has(actionType);
  const isUpdate = UPDATE_DATASET_ACTIONS.has(actionType);
  if (!isRead && !isUpdate) return;

  const {
    embeddedReportBuilder: { reportBuilderVersion },
    reportEditing: { reportData, currentView, currentConfig },
  } = getState();
  const datasetId = currentConfig?.dataInfo?.datasetId;
  if (!datasetId) return;

  if (isRead) {
    const aggData = currentView ? reportData[getAggDataId(datasetId)] : undefined;
    const isLoaded = isDataLoaded(aggData);
    if (isLoaded) return;
  }

  const dataset = datasetId ? reportBuilderVersion?.config.datasets?.[datasetId] : undefined;
  const view = currentConfig && getCurrentView(currentConfig?.views, currentView);
  if (view && dataset) return view;
};

const shouldFetchTotalData = (getState: () => ReportBuilderReduxState, actionType: string) => {
  const isRead = READ_TOTAL_ACTIONS.has(actionType);
  const isUpdate = UPDATE_TOTAL_ACTIONS.has(actionType);
  if (!isRead && !isUpdate) return;

  const {
    embeddedReportBuilder: { reportBuilderVersion },
    reportEditing: { currentView, currentConfig },
  } = getState();
  const datasetId = currentConfig?.dataInfo?.datasetId;
  if (!datasetId) return;

  if (isRead) {
    const { reportData, currentView } = getState().reportEditing;
    const viewData = currentView ? reportData[getTotalDataId(currentView)] : undefined;
    const isLoaded = isDataLoaded(viewData);
    if (isLoaded) return;
  }

  const dataset = datasetId ? reportBuilderVersion?.config.datasets?.[datasetId] : undefined;
  const view = currentConfig && getCurrentView(currentConfig?.views, currentView);
  if (view && dataset) return view;
};

const shouldFetchDrilldownData = (getState: () => ReportBuilderReduxState, actionType: string) => {
  const isRead = READ_DRILLDOWN_ACTIONS.has(actionType);
  const isUpdate = UPDATE_DRILLDOWN_ACTIONS.has(actionType);
  if (!isRead && !isUpdate) return;

  const {
    embeddedReportBuilder: { reportBuilderVersion },
    reportEditing: { currentView, currentConfig },
  } = getState();
  const datasetId = currentConfig?.dataInfo?.datasetId;
  if (!datasetId) return;

  if (isRead) {
    const { reportData, currentView } = getState().reportEditing;
    const viewData = currentView ? reportData[getDrilldownDataId(currentView)] : undefined;
    const isLoaded = isDataLoaded(viewData);
    if (isLoaded) return;
  }

  const dataset = datasetId ? reportBuilderVersion?.config.datasets?.[datasetId] : undefined;
  const view = currentConfig && getCurrentView(currentConfig?.views, currentView);
  if (view && dataset) return view;
};

const getViewPage = (action: Action) =>
  action.type === setViewPage.type ? (action as SetViewPage).payload : undefined;

const getDrilldownPage = (action: Action) =>
  action.type === setDrilldownPage.type ? (action as SetViewPage).payload : undefined;

const isDataLoaded = (viewData?: ReportData) =>
  viewData?.rows || viewData?.error || viewData?.isLoading;

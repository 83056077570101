import {
  AggregateProperty,
  Aggregation,
  And,
  BaseComputation,
  Filter,
  SourceProperty,
} from '@explo-tech/fido-api';

import {
  FilterOperationInstructions,
  GROUPED_OPERATION_TYPES,
  GROUPED_STACKED_OPERATION_TYPES,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeCollapsibleListInstructions,
  VisualizeGeospatialChartInstructions,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';

import { DataPanel } from 'types/exploResource';
import {
  aggregationMap,
  getEmptyComputation,
  getEmptyPivot,
  getGrouping,
  getTrendGrouping,
  processFilter,
  processSort,
} from './fidoInstructionShimUtils';
import { getDateBetweenFilterValues } from 'utils/dateTimeUtils';
import { PeriodComparisonRangeTypes, TrendGroupingOptions } from 'types/dateRangeTypes';
import { FilterOperator } from 'types/filterOperations';
import { SortInfo_DEPRECATED } from 'types/dataPanelTemplate';
import { ViewRequestParams } from 'reportBuilderContent/thunks/utils';

export const generateFidoVisualizeTableInstructions = (
  instructions: VisualizeTableInstructions,
) => {
  const computation = getEmptyComputation();

  instructions.baseSchemaList?.forEach((col) => {
    const schemaChange = instructions.changeSchemaList.find((change) => change.col === col.name);

    if (!schemaChange || (schemaChange.keepCol && !schemaChange.hideCol)) {
      const property: SourceProperty = {
        propertyId: col.name,
        // TODO FIDO make this actually not required
        targetPropertyId: col.name,
        '@type': 'source',
      };

      if (schemaChange?.newColName) {
        property.targetPropertyId = schemaChange.newColName;
      }

      computation.properties.push(property);
    }
  });

  return [computation];
};

export const generateFidoTwoDimensionChartInstructions = (
  instructions: V2TwoDimensionChartInstructions,
  operationType: OPERATION_TYPES,
) => {
  const computation = getEmptyComputation();

  const { aggColumns, groupingColumn, categoryColumn, colorColumnOptions } = instructions;

  if (!aggColumns || !categoryColumn) return null;

  aggColumns.forEach((col) => {
    const { agg, aggOption } = aggregationMap[col.agg.id];
    const property: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: col.column.name ?? '',
      targetPropertyId: null,
      aggregation: agg,
      aggregationOption: aggOption,
    };
    computation.properties.push(property);
  });

  // grouping has a strict ordering. groupingColumn is used for grouped stack
  // bar charts, so it gets grouped on first in the query. Then, we group by
  // the x axis value. Finally, we group by the color categories, which are
  // set in the chart config
  if (
    groupingColumn &&
    (GROUPED_OPERATION_TYPES.has(operationType) ||
      GROUPED_STACKED_OPERATION_TYPES.has(operationType))
  ) {
    const grouping = getGrouping(groupingColumn);
    if (grouping) computation.groupings.push(grouping);
  }

  const xAxisGroup = getGrouping(categoryColumn);
  if (xAxisGroup) computation.groupings.push(xAxisGroup);

  if (colorColumnOptions) {
    colorColumnOptions.forEach(({ column, bucket, selected }) => {
      // undefined is the default, selected state
      if (selected === false) return;

      const grouping = getGrouping({ column, bucket });
      if (grouping) computation.groupings.push(grouping);
    });
  }

  return [computation];
};

export const generateFidoGeospatialChartInstructions = (
  instructions: VisualizeGeospatialChartInstructions,
) => {
  const computation = getEmptyComputation();
  const { latitudeColumn, longitudeColumn, tooltipColumns } = instructions;

  if (!latitudeColumn || !longitudeColumn) return null;

  let property: SourceProperty = {
    '@type': 'source',
    propertyId: latitudeColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  property = {
    '@type': 'source',
    propertyId: longitudeColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  tooltipColumns?.forEach((column) => {
    const tooltipProperty: SourceProperty = {
      '@type': 'source',
      propertyId: column.name ?? '',
      targetPropertyId: null,
    };
    computation.properties.push(tooltipProperty);
  });
  return [computation];
};

export const generateFidoScatterPlotInstructions = (instructions: V2ScatterPlotInstructions) => {
  const computation = getEmptyComputation();

  const { xAxisColumn, groupingColumn, yAxisColumn } = instructions;

  if (!xAxisColumn || !yAxisColumn) return null;

  // ordering matters for these
  let property: SourceProperty = {
    '@type': 'source',
    propertyId: xAxisColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  property = {
    '@type': 'source',
    propertyId: yAxisColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  if (groupingColumn) {
    property = {
      '@type': 'source',
      propertyId: groupingColumn.name ?? '',
      targetPropertyId: null,
    };
    computation.properties.push(property);
  }

  return [computation];
};

export const generateFidoKPIInstructions = (instructions: V2KPIChartInstructions) => {
  const computation = getEmptyComputation();

  const { aggColumn } = instructions;

  if (!aggColumn) return null;
  const { agg, aggOption } = aggregationMap[aggColumn.agg.id];

  const property: AggregateProperty = {
    '@type': 'aggregate',
    propertyId: aggColumn.column.name ?? '',
    targetPropertyId: null,
    aggregation: agg,
    aggregationOption: aggOption,
  };
  computation.properties.push(property);

  return [computation];
};

const generateFidoKPITrendInstructions = (instructions: V2KPITrendInstructions) => {
  // calculates the actual grouped trend data that populates the main body of the chart
  const trendComputation = getEmptyComputation();

  const { aggColumn, periodColumn, periodComparisonRange, trendGrouping } = instructions;

  if (!aggColumn || !periodColumn) return null;
  const { agg, aggOption } = aggregationMap[aggColumn.agg.id];

  const property: AggregateProperty = {
    '@type': 'aggregate',
    propertyId: aggColumn.column.name ?? '',
    targetPropertyId: null,
    aggregation: agg,
    aggregationOption: aggOption,
  };
  trendComputation.properties.push(property);

  const grouping = getTrendGrouping({
    column: periodColumn?.column,
    grouping: (trendGrouping ?? TrendGroupingOptions.WEEKLY) as TrendGroupingOptions,
  });
  if (grouping) trendComputation.groupings.push(grouping);

  const { startDate: entirePeriodStartDate, endDate: entirePeriodEndDate } =
    getDateBetweenFilterValues(
      periodColumn,
      periodComparisonRange ?? PeriodComparisonRangeTypes.PREVIOUS_PERIOD,
    );

  const entirePeriodFilter = processFilter({
    filterClauses: [
      {
        filterColumn: {
          name: periodColumn.column.name || '',
          friendly_name: periodColumn.column.friendly_name,
          type: periodColumn.column.type || 'DATE',
        },
        filterValue: {
          startDate: entirePeriodStartDate.toISO(),
          endDate: entirePeriodEndDate.toISO(),
        },
        filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
      },
    ],
    matchOnAll: false,
  });
  if (entirePeriodFilter) trendComputation.filter = entirePeriodFilter;

  // calculates the aggregate data over the current period that populates the header of the chart
  const currentPeriodAggregateComputation = getEmptyComputation();
  currentPeriodAggregateComputation.properties.push({
    ...property,
    targetPropertyId: 'current_period_agg',
  });

  const { startDate: currentPeriodStartDate, endDate: currentPeriodEndDate } =
    getDateBetweenFilterValues(periodColumn);

  const currentPeriodFilter = processFilter({
    filterClauses: [
      {
        filterColumn: {
          name: periodColumn.column.name || '',
          friendly_name: periodColumn.column.friendly_name,
          type: periodColumn.column.type || 'DATE',
        },
        filterValue: {
          startDate: currentPeriodStartDate.toISO(),
          endDate: currentPeriodEndDate.toISO(),
        },
        filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
      },
    ],
    matchOnAll: false,
  });
  if (currentPeriodFilter) currentPeriodAggregateComputation.filter = currentPeriodFilter;

  const computations = [trendComputation, currentPeriodAggregateComputation];

  // calculates the aggregate data over the comparison period that populates the header of the chart
  if (periodComparisonRange !== PeriodComparisonRangeTypes.NO_COMPARISON) {
    const previousPeriodAggregateComputation = getEmptyComputation();
    previousPeriodAggregateComputation.properties.push({
      ...property,
      targetPropertyId: 'previous_period_agg',
    });

    const previousPeriodFilter = processFilter({
      filterClauses: [
        {
          filterColumn: {
            name: periodColumn.column.name || '',
            friendly_name: periodColumn.column.friendly_name,
            type: periodColumn.column.type || 'DATE',
          },
          filterValue: {
            startDate: entirePeriodStartDate.toISO(),
            endDate: currentPeriodStartDate.minus({ days: 1 }).toISO(),
          },
          filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
        },
      ],
      matchOnAll: false,
    });
    if (previousPeriodFilter) previousPeriodAggregateComputation.filter = previousPeriodFilter;
    computations.push(previousPeriodAggregateComputation);
  }

  return computations;
};

export const generateFidoCollapsibleListInstructions = (
  instructions: VisualizeCollapsibleListInstructions,
) => {
  const computation = getEmptyComputation();

  const { aggregations, rowColumns } = instructions;

  if (!aggregations || !rowColumns) return null;

  rowColumns.forEach((col) => {
    const grouping = getGrouping(col);
    if (grouping) computation.groupings.push(grouping);
  });

  aggregations.forEach((col) => {
    const { agg, aggOption } = aggregationMap[col.agg.id];

    const property: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: col.column.name ?? '',
      targetPropertyId: null,
      aggregation: agg,
      aggregationOption: aggOption,
    };
    computation.properties.push(property);
  });

  return [computation];
};

export const generateFidoPivotTableInstructions = (
  instructions: VisualizePivotTableInstructions,
) => {
  const computation = getEmptyPivot();

  const { rowColumn, colColumn, aggregation } = instructions;

  if (!rowColumn || !colColumn || !aggregation) return null;

  const rows = getGrouping(rowColumn);
  if (rows) computation.rows.push(rows);
  const { agg } = aggregationMap[aggregation.agg.id];

  computation.values.push({
    propertyId: aggregation.column.name ?? '',
    aggregation: agg,
    // TODO this is missing the option in FIDO
  });

  computation.columns.push({ propertyId: colColumn.column.name ?? '' });

  return [computation];
};

export const generateFidoBoxPlotInstructions = (instructions: V2BoxPlotInstructions) => {
  const computation = getEmptyComputation();
  const boxPlotPercentileValues = [0.25, 0.5, 0.75];

  const { groupingColumn, calcColumns } = instructions;

  if (!groupingColumn || !calcColumns) return null;

  const grouping = getGrouping(groupingColumn);
  if (grouping) computation.groupings.push(grouping);

  calcColumns.forEach((col) => {
    boxPlotPercentileValues.forEach((v) => {
      const aggregation: AggregateProperty = {
        '@type': 'aggregate',
        propertyId: col.name ?? '',
        // our box plot expects the 50th percentile to be called median
        targetPropertyId: v == 0.5 ? `${col.name ?? ''}_median` : null,
        aggregation: Aggregation.PERCENTILE,
        aggregationOption: {
          decimalValue: v,
        },
      };
      computation.properties.push(aggregation);
    });
    const min: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: col.name ?? '',
      targetPropertyId: null,
      aggregation: Aggregation.MIN,
    };
    computation.properties.push(min);
    const max: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: col.name ?? '',
      targetPropertyId: null,
      aggregation: Aggregation.MAX,
    };
    computation.properties.push(max);
  });

  return [computation];
};

export const generateComputations = (
  { visualize_op: visualizeOp, filter_op: filterOp }: Pick<DataPanel, 'visualize_op' | 'filter_op'>,
  adHocInstructions: {
    sortInfo: SortInfo_DEPRECATED[] | undefined;
    filterInfo: FilterOperationInstructions | undefined;
  },
): BaseComputation[] | undefined | null => {
  let computations: BaseComputation[] | null = null;

  switch (visualizeOp.operation_type) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
      if (!visualizeOp.instructions.VISUALIZE_TABLE) return null;
      computations = generateFidoVisualizeTableInstructions(
        visualizeOp.instructions.VISUALIZE_TABLE,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
    case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
    case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
      if (!visualizeOp.instructions.V2_TWO_DIMENSION_CHART) return null;
      computations = generateFidoTwoDimensionChartInstructions(
        visualizeOp.instructions.V2_TWO_DIMENSION_CHART,
        visualizeOp.operation_type,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
      if (!visualizeOp.instructions.V2_SCATTER_PLOT) return null;
      computations = generateFidoScatterPlotInstructions(visualizeOp.instructions.V2_SCATTER_PLOT);
      break;
    case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
    case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      if (!visualizeOp.instructions.V2_KPI) return null;
      computations = generateFidoKPIInstructions(visualizeOp.instructions.V2_KPI);
      break;
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      if (!visualizeOp.instructions.V2_KPI_TREND) return null;
      computations = generateFidoKPITrendInstructions(visualizeOp.instructions.V2_KPI_TREND);
      break;
    case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
      if (!visualizeOp.instructions.VISUALIZE_COLLAPSIBLE_LIST) return null;
      computations = generateFidoCollapsibleListInstructions(
        visualizeOp.instructions.VISUALIZE_COLLAPSIBLE_LIST,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      if (!visualizeOp.instructions.VISUALIZE_PIVOT_TABLE) return null;
      computations = generateFidoPivotTableInstructions(
        visualizeOp.instructions.VISUALIZE_PIVOT_TABLE,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      if (!visualizeOp.instructions.V2_BOX_PLOT) return null;
      computations = generateFidoBoxPlotInstructions(visualizeOp.instructions.V2_BOX_PLOT);
      break;
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      if (!visualizeOp.instructions.VISUALIZE_GEOSPATIAL_CHART) return null;
      computations = generateFidoGeospatialChartInstructions(
        visualizeOp.instructions.VISUALIZE_GEOSPATIAL_CHART,
      );
      break;
    default:
      computations = null;
  }

  if (!computations) return null;

  const { sortInfo, filterInfo } = adHocInstructions;

  // we don't want to apply adhoc sorts to secondary computations, like for kpi trends
  const primaryComputation = computations[0];

  if (sortInfo) {
    // have to do some shimming here to go from SortInfo_DEPRECATED to SortInfo
    processSort(
      sortInfo.map((colInfo) => ({ column: { name: colInfo.column_name }, order: colInfo.order })),
      primaryComputation,
    );
  }

  const configuredFilter = processFilter(filterOp?.instructions);
  const adHocFilter = processFilter(filterInfo);

  const filters: Filter[] = [];
  if (configuredFilter) filters.push(configuredFilter);
  if (adHocFilter) filters.push(adHocFilter);

  computations.forEach((c) => {
    const computationFilters = c.filter ? [...filters, c.filter] : filters;

    if (computationFilters.length === 1) {
      c.filter = computationFilters[0];
    } else if (computationFilters.length > 1) {
      const andFilter: And = {
        values: computationFilters,
        '@type': 'and',
      };
      c.filter = andFilter;
    }
  });

  return computations;
};

export const generateReportBuilderComputations = ({
  aggs,
  group_bys: groupBys,
  sort: sorts,
  filters,
}: ViewRequestParams) => {
  const computation = getEmptyComputation();

  aggs.forEach((aggColumn) => {
    const { agg, aggOption } = aggregationMap[aggColumn.agg.id];

    const property: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: aggColumn.column.name ?? '',
      targetPropertyId: null,
      aggregation: agg,
      aggregationOption: aggOption,
    };
    computation.properties.push(property);
  });

  groupBys.forEach((groupBy) => {
    const grouping = getGrouping(groupBy);
    if (grouping) computation.groupings.push(grouping);
  });

  processSort(sorts, computation);

  computation.filter = processFilter({ filterClauses: filters, matchOnAll: true });

  return computation;
};

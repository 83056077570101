import { FC, useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { baseThemeName, sprinkles } from 'components/ds';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { format } from 'utils/localizationUtils';
import { formatValue, FormatValueOptions } from 'pages/dashboardPage/charts/utils';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { getFontFamilyName } from 'globalStyles/utils';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { GlobalStyleConfig } from 'globalStyles/types';
import * as styles from './index.css';

export type PointData = {
  color: string | undefined;
  name: string;
  value?: number;
  format?: Omit<FormatValueOptions, 'value'>;
  selected?: boolean;
  unit?: string;
  formattedValue?: string;
};

export type Props = {
  globalStyleConfig: GlobalStyleConfig;
  header: string | undefined;
  points: PointData[];
  includePct?: boolean;
};

export const ChartTooltip: FC<Props> = ({
  header,
  points,
  includePct,
  globalStyleConfig,
  children,
}) => {
  const globalStyleVars = getGlobalStyleVars(globalStyleConfig);
  const font =
    globalStyleConfig.text.overrides.smallBody?.font || globalStyleConfig.text.secondaryFont;

  const layoutTagId = useContext(DashboardLayoutContext).dashboardLayoutTagId;

  const totalValue = useMemo(
    () => points.reduce((acc, curr) => acc + (curr.value || 0), 0),
    [points],
  );

  const renderPercent = useCallback(
    (value: number) => (
      <td className={cx(styles.pointData, styles.value)}>
        {format('.2f')((value / totalValue) * 100)}%
      </td>
    ),
    [totalValue],
  );

  const getFormattedValue = useCallback(({ value, format, formattedValue }: PointData) => {
    if (formattedValue) return formattedValue;
    if (value === undefined) return '-';

    return formatValue({
      value,
      decimalPlaces: format?.decimalPlaces ?? 2,
      significantDigits: format?.significantDigits ?? 3,
      formatId: format?.formatId || V2_NUMBER_FORMATS.NUMBER.id,
      hasCommas: true,
      timeFormatId: format?.timeFormatId,
      customTimeFormat: format?.customTimeFormat,
      multiplier: format?.multiplier,
      units: format?.units,
    });
  }, []);

  const tooltipContent = (
    <span className="explo-embed">
      <div
        className={cx(styles.root, { [cx(baseThemeName, globalStyleVars)]: !children })}
        style={{
          fontFamily: font ? getFontFamilyName(font) : undefined,
        }}>
        {header ? <div className={styles.header}>{header}</div> : null}
        {points.length > 0 ? (
          <div className={sprinkles({ padding: 'sp.5' })}>
            <table className={styles.pointsTable}>
              <tbody>
                {points.map((point) => (
                  <tr
                    className={cx({
                      [sprinkles({ backgroundColor: 'gray2' })]: point.selected,
                    })}
                    key={point.name}>
                    <td className={cx(styles.pointData, sprinkles({ flexItems: 'alignCenter' }))}>
                      {point.color ? (
                        <div
                          className={styles.pointColor}
                          style={{ backgroundColor: point.color }}
                        />
                      ) : null}
                      <div className={sprinkles({ marginRight: 'sp.5' })}>{point.name}</div>
                    </td>
                    <td className={cx(styles.pointData, styles.value)}>
                      {getFormattedValue(point)}
                    </td>
                    {includePct ? renderPercent(point.value || 0) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </span>
  );
  // If header is undefined and there are no points, there is nothing to draw
  if (!header && points.length === 0) return null;

  // If no children, assume tooltip is being rendered statically for highcharts
  if (!children) return tooltipContent;

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal container={document.getElementById(layoutTagId)}>
          <RadixTooltip.Content className={sprinkles({ zIndex: 'tooltips' })} sideOffset={8}>
            {tooltipContent}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

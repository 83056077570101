import { FC, useState } from 'react';

import { DatasetModal } from 'components/DatasetModal';
import { sprinkles, Button } from 'components/ds';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

type Props = {
  datasets: ReportBuilderDataset[];

  onSubmit: (name: string, parentSchemaId: number) => void;
};

export const AddDatasetButton: FC<Props> = ({ datasets, onSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const getModalError = (newVal: string) => {
    const datasetWithSameName = datasets.find((dataset) => dataset.name === newVal);
    if (datasetWithSameName === undefined) return { isErrorState: false };

    return {
      isErrorState: true,
      errorMsg:
        'There is already a dataset with this name. Please choose another name for the dataset.',
    };
  };

  const renderAddModal = () => {
    if (!modalOpen) return null;

    return (
      <DatasetModal
        modalOpen
        buttonTitle="Create new Dataset"
        errorState={getModalError}
        onClose={() => setModalOpen(false)}
        onSubmit={onSubmit}
        title="New Dataset"
      />
    );
  };

  return (
    <>
      <Button
        fillWidth
        className={sprinkles({ marginBottom: 'sp1' })}
        icon="plus"
        onClick={() => setModalOpen(true)}
        variant="primary">
        New Dataset
      </Button>
      {renderAddModal()}
    </>
  );
};

import { FC } from 'react';
import { Input } from 'components/ds';
import * as styles from './ColumnFormatItem.css';

type ContentProps = {
  colName: string;
  updateColumnInfo: (updates: { name?: string; description?: string }) => void;
};

export const ColumnFormatItem: FC<ContentProps> = ({ children, colName, updateColumnInfo }) => (
  <div className={styles.formattingTabClass}>
    <Input
      fillWidth
      defaultValue={colName}
      label="Name"
      onSubmit={(newName) => updateColumnInfo({ name: newName })}
    />
    {children}
  </div>
);

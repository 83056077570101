import { forwardRef } from 'react';
import { ButtonProps, LinkProps } from 'components/ds/Button';

import { Button } from 'components/ds';
import { IconName } from 'components/ds/Icon';

export type Props = (
  | Omit<ButtonProps, 'children' | 'name'>
  | Omit<LinkProps, 'children' | 'name'>
) & {
  // Icon Buttons don't have children because they just render the icon
  children?: never;
  name: IconName;
};

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  ({ name, variant, ...props }, ref) => {
    return <Button {...props} icon={name} ref={ref} variant={variant ?? 'tertiary'} />;
  },
);

IconButton.displayName = 'IconButton';

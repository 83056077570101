import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';

import { Checkbox, Input, Toggle, ToggleItem, sprinkles } from 'components/ds';
import {
  ConfigSubSectionTitle,
  ConfigSubSectionWithSwitch,
  ConfigSubSectionWrapper,
} from 'components/PanelComponents/ConfigSubSection';
import { TablePDFConfig } from './TablePdfConfig';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import {
  ExportConfig,
  OPERATION_TYPES,
  VISUALIZE_TABLE_OPERATIONS_SET,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  dpProvidedId: string;
  options: VisualizeOperationGeneralFormatOptions;
  visualizationType: OPERATION_TYPES;
};

enum ExportType {
  Download = 'Download',
  Email = 'Email',
  Both = 'Both',
}

export const ExportsConfig: FC<Props> = ({ options, visualizationType, dpProvidedId }) => {
  const dispatch = useDispatch();

  const shouldUseJobQueue = useSelector(
    (state: ReduxState) => !!state.currentUser.team?.feature_flags.use_job_queue,
  );

  const config = options.export ?? {};
  const { csvFormat } = config;

  const setExportOptions = (updates: Partial<ExportConfig>) => {
    const newOptions = produce(options, (draft) => {
      draft.export = { ...draft.export, ...updates };
    });
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  const isDownloadOn = !config.disableDownloadExport;
  const isEmailOn = !!config.enableEmailExport;

  let currExportType = undefined;
  if (isDownloadOn) {
    currExportType = isEmailOn ? ExportType.Both : ExportType.Download;
  } else if (isEmailOn) currExportType = ExportType.Email;

  const renderCsvExportOptions = () => {
    return (
      <>
        <ConfigSubSectionWithSwitch
          label="CSV"
          onChange={() => setExportOptions({ disableCsvExport: !config.disableCsvExport })}
          switchOn={!config.disableCsvExport}>
          <Checkbox
            isChecked={!!csvFormat?.tsvEnabled}
            label="Tab delimited file"
            onChange={() =>
              setExportOptions({
                csvFormat: { ...csvFormat, tsvEnabled: !csvFormat?.tsvEnabled },
              })
            }
          />
        </ConfigSubSectionWithSwitch>
        <ConfigSubSectionWithSwitch
          label="XLSX"
          onChange={() => setExportOptions({ disableXlsxExport: !config.disableXlsxExport })}
          switchOn={!config.disableXlsxExport}
        />
      </>
    );
  };

  const renderTableExportOptions = () => {
    return (
      <>
        <TablePDFConfig options={options} />
        {renderCsvExportOptions()}
      </>
    );
  };

  return (
    <>
      <ConfigSubSectionTitle
        isOn={!config.isDownloadButtonHidden}
        onChange={() =>
          setExportOptions({ isDownloadButtonHidden: !config.isDownloadButtonHidden })
        }
        title="Exports"
      />
      {!config.isDownloadButtonHidden ? (
        <ConfigSubSectionWrapper>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
            {shouldUseJobQueue ? (
              <Toggle
                label="Format"
                onValueChange={(newType) =>
                  setExportOptions({
                    disableDownloadExport: newType === ExportType.Email,
                    enableEmailExport: newType !== ExportType.Download,
                  })
                }
                selectedValue={currExportType}>
                <ToggleItem value={ExportType.Download} />
                <ToggleItem value={ExportType.Email} />
                <ToggleItem value={ExportType.Both} />
              </Toggle>
            ) : null}
            <Input
              defaultValue={config.downloadFileName || dpProvidedId}
              label="File name"
              onSubmit={(newVal) => setExportOptions({ downloadFileName: newVal })}
            />
          </div>
          {VISUALIZE_TABLE_OPERATIONS_SET.has(visualizationType) &&
          visualizationType !== OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2
            ? renderTableExportOptions()
            : renderCsvExportOptions()}
        </ConfigSubSectionWrapper>
      ) : null}
    </>
  );
};

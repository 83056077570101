import parse from 'parse-duration';

import {
  PropertyType,
  RequestTelemetry,
  DataPage,
  QueryExecutionResponse,
  PropertyValue,
  ComputedView,
  PropertySchema,
  DataSourceConfiguration,
  NamespaceResponse,
  DataSource,
  Namespace,
  TableView,
  SSHTunnel,
} from '@explo-tech/fido-api';

import { QueryTiming } from 'actions/responseTypes';
import { DatasetColumn, DatasetRow, DatasetSchema } from 'types/datasets';
import { ResourceDataset } from 'types/exploResource';
import {
  BOOLEAN,
  DATE,
  DATETIME,
  FLOAT,
  INTEGER_DATA_TYPE,
  STRING,
  TIMESTAMP,
} from 'constants/dataConstants';
import {
  DataSource as EmbeddoDataSource,
  ParentSchema,
  DataSourceConfiguration as EmbeddoDataSourceConfiguration,
} from 'actions/dataSourceActions';
import {
  FIDO_TYPES,
  DATABASES,
  TUNNEL,
  FIDO_TYPE_KEY,
  DATA_SOURCE_AUTH,
  SSH_AUTH_TYPE,
} from 'pages/ConnectDataSourceFlow/constants';
import { ComputedViewWithIds } from './fidoUtils';
import { FidoDaos, FidoTableView, NamespaceTableViewMap } from 'reducers/fidoReducer';
import {
  DATA_SOURCE_CONFIG_KEYS,
  FidoAuthentication,
  FidoDataSourceConfig,
  FidoSSHTunnelAuthentication,
  MiscSSHConfig,
  SSH_CONFIG_KEYS,
} from 'pages/ConnectDataSourceFlow/types';
import { Dataset } from 'actions/datasetActions';
import { SchemaTablesMap } from 'reducers/parentSchemaReducer';

const convertFidoTypeToDatabase = (type: string) => {
  switch (type) {
    case FIDO_TYPES.POSTGRES:
      return DATABASES.POSTGRES;
    case FIDO_TYPES.MYSQL:
      return DATABASES.MYSQL;
    case FIDO_TYPES.SQLSERVER:
      return DATABASES.SQLSERVER;
    default:
      return 'not supported';
  }
};

const getEmbeddoTypeFromFidoType = (type: PropertyType) => {
  switch (type) {
    case PropertyType.BOOLEAN:
      return BOOLEAN;
    case PropertyType.DATE:
      return DATE;
    case PropertyType.DATETIME:
      return DATETIME;
    case PropertyType.DECIMAL:
      return FLOAT;
    case PropertyType.DOUBLE:
      return FLOAT;
    case PropertyType.INTEGER:
      return INTEGER_DATA_TYPE;
    case PropertyType.LONG:
      return INTEGER_DATA_TYPE;
    case PropertyType.STRING:
      return STRING;
    case PropertyType.UNSUPPORTED:
      return STRING;
  }
};

const getFidoTypeFromEmbeddoType = (type: string) => {
  if (type == BOOLEAN) return PropertyType.BOOLEAN;
  if (type == DATE) return PropertyType.DATE;
  if (type == DATETIME) return PropertyType.DATETIME;
  if (type == TIMESTAMP) return PropertyType.DATETIME;
  if (type == FLOAT) return PropertyType.DECIMAL;
  if (type == INTEGER_DATA_TYPE) return PropertyType.INTEGER;
  else return PropertyType.STRING;
};

export const getEmbeddoSchemaFromFidoSchema = (schema: PropertySchema[]) =>
  schema.map((s) => {
    return {
      name: s.id,
      type: getEmbeddoTypeFromFidoType(s.type),
      friendly_name: s.name ?? s.id,
    } as DatasetColumn;
  });

export const getFidoSchemaFromEmbeddoSchema = (schema: DatasetSchema) =>
  schema.map((s) => ({
    id: s.name,
    name: s.friendly_name,
    type: getFidoTypeFromEmbeddoType(s.type),
  }));

const getEmbeddoDataFromFidoData = (data: DataPage) => {
  return data.dataRecords.map((record) => {
    const row: Record<string, Array<PropertyValue>> = record.propertyValues;
    const _row: DatasetRow = {};

    Object.keys(row).map((col) => (_row[col] = row[col][0].value.toString()));

    return _row;
  });
};

const parseTiming = (value: string | null | undefined) => {
  if (!value) return undefined;

  const parsed = parse(value);

  if (!parsed) return undefined;

  return String(Math.round(parsed * 1000) / 1000);
};

const getQueryInformationFromFidoData = (query?: string, telemetry?: RequestTelemetry | null) => {
  const queryTiming: QueryTiming = {
    time_to_run: parseTiming(telemetry?.queryTime),
    time_to_process: parseTiming(telemetry?.processingTime),
    total_time: parseTiming(telemetry?.requestTime),
  };

  return {
    _query_timing: queryTiming,
    _query: query ?? '',
  };
};

export const getEmbeddoResponseFromFidoResponse = (response: QueryExecutionResponse) => {
  const { data, meta, requestTelemetry } = response;
  const { schema, renderedQuery, totalResults } = meta;

  return {
    rows: getEmbeddoDataFromFidoData(data),
    schema: getEmbeddoSchemaFromFidoSchema(schema.propertySchema),
    totalResults,
    queryInformation: getQueryInformationFromFidoData(renderedQuery ?? '', requestTelemetry),
  };
};

export const getEmbeddoDatasetConfigFromFidoComputedView = (views: ComputedViewWithIds[]) => {
  const datasets: Record<string, ResourceDataset> = {};

  views.forEach((view) => {
    const dataset: ResourceDataset = {
      id: view.id,
      query: view.query,
      table_name: view.name,
      // @ts-ignore
      parent_schema_id: view.namespaceId,
      schema: getEmbeddoSchemaFromFidoSchema(view.columnDefinitions),
    };

    datasets[view.id] = dataset;
  });

  return datasets;
};

export const getDatasetConfigFromView = (view: ComputedView, viewData: Dataset) => {
  return {
    id: viewData.id,
    table_name: view.name,
    parent_schema_id: viewData.namespace_id ?? view.namespaceId,
    query: view.query,
    drilldownColumnConfigs: viewData.drilldownColumnConfigs,
    drilldownConfig: viewData.drilldownConfig,
  };
};

export const parseSchema = (namespace: Namespace, schema: ParentSchema): ParentSchema => {
  return {
    id: schema.id,
    name: namespace.name,
    fido_id: namespace.id,
  };
};

export const parseDataSource = (
  fidoDataSource: DataSource,
  embeddoDataSource: EmbeddoDataSource,
): EmbeddoDataSource => {
  return {
    id: embeddoDataSource.id,
    name: fidoDataSource.name,
    parent_schema_id: embeddoDataSource.parent_schema_id,
    provided_id: fidoDataSource.externalId,
    access_groups: embeddoDataSource.access_groups,
    source_type: convertFidoTypeToDatabase(
      (fidoDataSource.configuration as FidoDataSourceConfig)[FIDO_TYPE_KEY],
    ),
    user_viewable_credentials: getEmbeddoCredentialsFromFidoCredentials(
      fidoDataSource.configuration,
    ),
    fido_id: fidoDataSource.id,
  };
};

export const parseFidoTableViews = (views: TableView[]): Record<string, FidoTableView> => {
  const viewsMap: Record<string, FidoTableView> = {};
  views.forEach(
    (table) =>
      (viewsMap[table.id ?? ''] = {
        tableName: table.tableName,
        id: table.id,
        namespaceId: table.namespaceId,
        schema: getEmbeddoSchemaFromFidoSchema(table.columnDefinitions),
      }),
  );
  return viewsMap;
};

export const parseListNamespacesWithMetaResponse = (
  namespaceResponses: NamespaceResponse[],
  fetchedEmbeddoParentSchemas: ParentSchema[],
  fetchedEmbeddoDataSources: EmbeddoDataSource[],
  fetchedEmbeddoSchemaTablesMap: SchemaTablesMap,
): FidoDaos => {
  const parsedNamespaces: ParentSchema[] = [];
  const parsedDataSources: EmbeddoDataSource[] = [];
  const tables: FidoTableView[] = [];
  const schemaTablesMap: NamespaceTableViewMap = {};

  namespaceResponses.forEach((namespaceResponse) => {
    const ns = namespaceResponse.namespace;
    const dataSources = namespaceResponse.meta?.dataSources;
    const views = namespaceResponse.meta?.views;
    const embeddoParentSchema = fetchedEmbeddoParentSchemas.find((ps) => ps.fido_id === ns.id);

    if (!embeddoParentSchema) {
      console.error('Namespace with fido_id', ns.id, 'not found');
      return;
    }

    const embeddoSchemaTablesMapEntry =
      fetchedEmbeddoSchemaTablesMap[embeddoParentSchema?.id.toString()];

    parsedNamespaces.push(parseSchema(ns, embeddoParentSchema));

    if (dataSources !== undefined && dataSources != null) {
      dataSources.forEach((fidoDataSource) => {
        const embeddoDataSource = fetchedEmbeddoDataSources.find(
          (eds) => eds.fido_id === fidoDataSource.id,
        );

        if (!embeddoDataSource) {
          console.error('Data Source with fido_id', fidoDataSource.id, 'not found');
          return;
        }

        parsedDataSources.push(parseDataSource(fidoDataSource, embeddoDataSource));
      });
    }

    const tableViews = (views ?? []).filter(
      (view) => (view as TableView | ComputedView)[FIDO_TYPE_KEY] === 'table-view',
    ) as TableView[];

    if (tableViews.length > 0) {
      schemaTablesMap[ns.id] = parseFidoTableViews(tableViews);
      tables.push(...Object.values(schemaTablesMap[ns.id]));
    } // try to pull from embeddo if we don't have table views saved in FIDO
    else {
      const map: Record<string, FidoTableView> = {};
      Object.entries(embeddoSchemaTablesMapEntry).forEach(([id, entry]) => {
        map[id.toString()] = { ...entry, tableName: entry.table_name, id: entry.id.toString() };
      });
      schemaTablesMap[ns.id] = map;
    }
  });

  return { namespaces: parsedNamespaces, dataSources: parsedDataSources, tables, schemaTablesMap };
};

const getEmbeddoCredentialsFromFidoCredentials = (
  configuration: DataSourceConfiguration,
): EmbeddoDataSourceConfiguration => {
  const config = configuration as FidoDataSourceConfig;
  const port = config.port;

  if (port) {
    let sshConfig = {};
    if (config.tunnel[FIDO_TYPE_KEY] === TUNNEL.SSH) {
      sshConfig = {
        [SSH_CONFIG_KEYS.HOST]: config.tunnel.host,
        [SSH_CONFIG_KEYS.PORT]: config.tunnel.port,
        [SSH_CONFIG_KEYS.SSH_AUTH_TYPE]: (
          config.tunnel.authentication as FidoSSHTunnelAuthentication
        )[FIDO_TYPE_KEY],
        [SSH_CONFIG_KEYS.USERNAME]: config.tunnel.authentication.username,
      };
    }
    return {
      [DATA_SOURCE_CONFIG_KEYS.DATABASE]: config.database,
      [DATA_SOURCE_CONFIG_KEYS.HOST]: config.host,
      [DATA_SOURCE_CONFIG_KEYS.PORT]: port,
      [DATA_SOURCE_CONFIG_KEYS.USER]: config.authentication.username,
      [DATA_SOURCE_CONFIG_KEYS.TUNNEL_TYPE]: config.tunnel[FIDO_TYPE_KEY],
      ...sshConfig,
    };
  } else return {};
};

export const getCommonDataSourceConfigInfo = (
  payload: EmbeddoDataSourceConfiguration,
):
  | {
      database: string;
      port: number;
      host: string;
      authentication: FidoAuthentication;
      tunnelType: string;
    }
  | undefined => {
  const commonConfigIsSafe = Object.values(DATA_SOURCE_CONFIG_KEYS).every((key) => key in payload);
  if (!commonConfigIsSafe) return;

  return {
    database: payload[DATA_SOURCE_CONFIG_KEYS.DATABASE] as string,
    port: payload[DATA_SOURCE_CONFIG_KEYS.PORT] as number,
    host: payload[DATA_SOURCE_CONFIG_KEYS.HOST] as string,
    authentication: {
      username: payload[DATA_SOURCE_CONFIG_KEYS.USER] as string,
      '@type': DATA_SOURCE_AUTH.USERNAME_PASSWORD,
    } as FidoAuthentication,
    tunnelType: payload[DATA_SOURCE_CONFIG_KEYS.TUNNEL_TYPE] as string,
  };
};

export const getFidoReducerSSHConfigFromCredentials = (
  payload: EmbeddoDataSourceConfiguration,
): (Partial<SSHTunnel> & MiscSSHConfig) | undefined => {
  const commonSSHIsSafe = Object.values(SSH_CONFIG_KEYS).every((key) => key in payload);
  if (!commonSSHIsSafe) return;

  const authType = payload[SSH_CONFIG_KEYS.SSH_AUTH_TYPE];
  if (authType !== SSH_AUTH_TYPE.VENDOR && authType !== SSH_AUTH_TYPE.TENANT) return;
  return {
    tunnel: { '@type': TUNNEL.PUBLIC_INTERNET },
    host: payload[SSH_CONFIG_KEYS.HOST] as string,
    port: payload[SSH_CONFIG_KEYS.PORT] as number,
    authentication: {
      '@type': authType,
      username: payload[SSH_CONFIG_KEYS.USERNAME] as string,
    },
    sshAuthMethod: authType,
    useSSH: true,
  };
};

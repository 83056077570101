import { FC } from 'react';

import { EmbedButton } from 'components/embed';
import { IconName } from 'components/ds/Icon';

type Props = {
  children: string;
  icon?: IconName;
  selected: boolean;

  onClick: () => void;
};

export const ToggleButton: FC<Props> = ({ icon, selected, onClick, children }) => {
  return (
    <EmbedButton
      fillWidth
      icon={icon}
      onClick={onClick}
      variant={selected ? 'primary' : 'secondary'}>
      {children}
    </EmbedButton>
  );
};

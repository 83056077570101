import { MutableRefObject, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { TypeFooterRow } from '@inovua/reactdatagrid-community/types';

import { ReportChartTotal } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChartTotal';
import { TotalAccumulator } from 'actions/customerReportActions';
import { DatasetSchema } from 'types/datasets';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ColumnConfigs } from 'types/columnTypes';
import { getCurrentView } from 'reportBuilderContent/reducers/reportEditingReducer';

/**
 * Renders the aggregated total values from useTotalAccumulator as a React Data Grid Footer
 */
export const useTotalFooter = (
  schema: DatasetSchema,
  columnConfigs: ColumnConfigs,
  portalRef: MutableRefObject<HTMLElement | null>,
  totalAccumulator: TotalAccumulator,
) => {
  const { showTotals, canShowTotals } = useSelector(
    (state: ReportBuilderReduxState) => ({
      showTotals: getCurrentView(state.reportEditing)?.showTotals,
      canShowTotals: state.embeddedReportBuilder.reportBuilderVersion?.config.general?.showTotals,
    }),
    shallowEqual,
  );

  const footerRows = useMemo<TypeFooterRow[]>(
    () =>
      canShowTotals
        ? [
            {
              render: ({ column }) =>
                // When showTotals is false, render null rather than set footerRows=[] because
                // react-data-grid won't re-render correctly and will leave a blank space where the footer should be
                showTotals ? (
                  <ReportChartTotal
                    columnConfigs={columnConfigs}
                    columnName={column.name || ''}
                    groupColumn={column.groupColumn}
                    pivotColumnPath={column.pivotColumnPath}
                    portalRef={portalRef}
                    schema={schema}
                    totalAccumulator={totalAccumulator}
                  />
                ) : null,
            },
          ]
        : [],
    [columnConfigs, showTotals, portalRef, schema, canShowTotals, totalAccumulator],
  );

  return footerRows;
};

import { FC, Suspense, lazy, useMemo } from 'react';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';

import { DatasetSchema, DatasetRow } from 'types/datasets';
import * as styles from './index.css';
import * as dataGridStyles from '../EmbedDataGrid/index.css';

import '@inovua/reactdatagrid-enterprise/index.css';
import { generatePivotColumns, transformRowsWithCellStyles } from './pivotUtils';
import { PIVOT_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { ColumnConfigs } from 'types/columnTypes';

const ReactDataGrid = lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@inovua/reactdatagrid-enterprise'),
);

type Props = {
  // Column configs for formatting
  columnConfigs: ColumnConfigs;
  groupByColumns: string[];
  loading: boolean;
  pivotColumns: string[];
  rows: DatasetRow[] | undefined;
  schema: DatasetSchema | undefined;
};

export const ExplorePivotTable: FC<Props> = ({
  columnConfigs,
  loading,
  schema,
  rows,
  groupByColumns,
  pivotColumns,
}) => {
  const { columns, groupColumn } = useMemo(
    () => generatePivotColumns({ columnConfigs, schema, pivotColumns, groupByColumns }),
    [columnConfigs, schema, pivotColumns, groupByColumns],
  );

  const transformedRows = useMemo(
    () => transformRowsWithCellStyles(rows, schema, columnConfigs),
    [rows, schema, columnConfigs],
  );

  const renderLoadMask = ({ visible }: { visible: boolean }) => {
    return visible ? <EmbedSpinner fillContainer className={dataGridStyles.loadMask} /> : null;
  };

  return (
    <div
      className={cx(
        dataGridStyles.container,
        embedSprinkles({ backgroundColor: 'containerFill' }),
        sprinkles({ parentContainer: 'fill', flexItems: 'column' }),
        styles.explorePivotTableContainer,
      )}>
      <Suspense fallback={<EmbedSpinner fillContainer />}>
        <ReactDataGrid
          disableGroupByToolbar
          activeIndex={-1}
          className={sprinkles({ flex: 1 })}
          columnOrder={schema?.map((col) => col.name)}
          columns={columns}
          dataSource={transformedRows}
          groupBy={groupByColumns}
          groupColumn={groupColumn}
          idProperty="id"
          licenseKey={process.env.REACT_APP_DATAGRID_KEY}
          loading={loading}
          pivot={pivotColumns}
          renderLoadMask={renderLoadMask}
          reorderColumns={false}
          rowClassName={cx(dataGridStyles.row, dataGridStyles.hoverRow)}
          showColumnMenuTool={false}
          showHoverRows={false}
          sortable={false}
        />
        {transformedRows.length >= PIVOT_ROW_LIMIT ? (
          <div
            className={cx(
              styles.rowLimitContainer,
              embedSprinkles({ body: 'secondary', borderColor: 'primary' }),
            )}>
            Pivot row limit reached, please filter your data or reduce the number of groups.
          </div>
        ) : null}
      </Suspense>
    </div>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { Switch, Input, sprinkles } from 'components/ds';

import { ImageElemConfig } from 'types/dashboardTypes';

const configInputClass = sprinkles({ marginTop: 'sp1.5' });

type Props = {
  config: ImageElemConfig;
  updateConfig: (newConfig: ImageElemConfig) => void;
};

export const ImageElementConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  const updateImageConfig = (update: (config: ImageElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  return (
    <div>
      <Input
        className={configInputClass}
        defaultValue={config.imageUrl}
        label={{ text: 'Image URL', variableInput: true }}
        onSubmit={(newValue) => updateImageConfig((draft) => (draft.imageUrl = newValue))}
      />
      <Switch
        className={configInputClass}
        label="Fit entire image in container"
        onChange={() =>
          updateImageConfig((draft) => (draft.fitEntireImage = !draft.fitEntireImage))
        }
        switchOn={config.fitEntireImage}
      />
    </div>
  );
};

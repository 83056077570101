import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createRef,
  useMemo,
} from 'react';
import { DndContext, DragOverlay, useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';

import { NoDataSelected } from 'pages/ReportBuilder/ReportView/NoDataSelected';
import { SortableViewTab, ViewTab } from 'pages/ReportBuilder/ReportView/Tabs/ViewTab';
import { ViewContent } from 'pages/ReportBuilder/ReportView/ViewContent';
import { EmbedButton } from 'components/embed';
import { DocsPopover } from 'pages/ReportBuilder/Onboarding/DocsPopover';
import * as styles from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs.css';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import {
  createView,
  setCurrentView,
  swapViews,
  getCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

const SCROLL_AMOUNT = 100;
const TOOLTIP_DELAY = 1000;

interface Props {
  views: CustomerReportView[]; // Views that may have been modified on the frontend
  savedViews: CustomerReportView[]; // Views that have been saved to the DB
  dataInfo?: CustomerReportDataInfo;
}

export const ViewTabs: FC<Props> = ({ dataInfo, views, savedViews }) => {
  const [canScroll, setCanScroll] = useState(false);
  const [activeView, setActiveView] = useState<CustomerReportView | undefined>();
  const tabsListRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const currentView = useSelector((state: ReportBuilderReduxState) =>
    getCurrentView(state.reportEditing),
  );

  const tabRefs = useMemo(() => {
    const refs: Record<string, RefObject<HTMLDivElement>> = {};
    for (const view of views) {
      refs[view.id] = createRef<HTMLDivElement>();
    }
    return refs;
  }, [views]);

  // After the view list is re-rendered, toggle the scroll buttons if needed
  useLayoutEffect(() => {
    setCanScroll(
      views.length > 0 &&
        !!tabsListRef.current &&
        tabsListRef.current.scrollWidth > tabsListRef.current.offsetWidth,
    );
  }, [views]);

  const handleScrollLeft = useCallback(() => {
    tabsListRef.current?.scrollBy(-SCROLL_AMOUNT, 0);
  }, []);

  const handleScrollRight = useCallback(() => {
    tabsListRef.current?.scrollBy(SCROLL_AMOUNT, 0);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Only drag if the user moves the tab, otherwise the drag would prevent selection and renaming the tab
        distance: 2,
      },
    }),
  );

  // Scroll the current view tab into view. scrollIntoView can't be used since we only want to scroll horizontally
  useEffect(() => {
    if (!currentView?.id) return;

    for (const view of views) {
      if (currentView.id !== view.id) continue;

      const tab = tabRefs[currentView.id]?.current;
      const tabsList = tabsListRef.current;
      if (!tab || !tabsList) return;

      const scrollOffset = getScrollOffset(tab, tabsList);
      if (scrollOffset !== 0) {
        tabsList.scrollBy({
          left: scrollOffset,
          behavior: 'smooth',
        });
      }
      return;
    }
  }, [currentView?.id, tabRefs, views]);

  return (
    <div className={styles.tabsRoot}>
      <div aria-label="Views" className={styles.tabsListContainer}>
        <EmbedButton
          icon="plus"
          onClick={() => dispatch(createView())}
          tooltipProps={{ align: 'start', side: 'bottom', text: 'Create View' }}
          variant="tertiary"
        />
        <div className={styles.viewTabsContainer} ref={tabsListRef}>
          <div className={styles.viewTabs}>
            <DndContext
              modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
              onDragCancel={() => setActiveView(undefined)}
              onDragEnd={({ active, over }) => {
                const oldId = String(active.id);
                if (over && oldId !== over.id) {
                  const newId = String(over.id);
                  dispatch(swapViews({ oldId, newId }));
                  dispatch(setCurrentView(oldId));
                }
              }}
              onDragStart={({ active }) => {
                const view = views.find((view) => view.id === active.id);
                if (view) setActiveView(view);
              }}
              sensors={sensors}>
              <SortableContext items={views} strategy={horizontalListSortingStrategy}>
                {views.map((view) => (
                  <SortableViewTab
                    key={view.id}
                    ref={tabRefs[view.id]}
                    savedViews={savedViews}
                    view={view}
                  />
                ))}
              </SortableContext>
              <DragOverlay dropAnimation={null}>
                {activeView ? <ViewTab view={activeView} /> : null}
              </DragOverlay>
            </DndContext>
          </div>
        </div>
        <EmbedButton
          disabled={!canScroll}
          icon="angle-left"
          onClick={handleScrollLeft}
          tooltipProps={{
            align: 'start',
            side: 'bottom',
            text: 'Scroll left',
            delayDuration: TOOLTIP_DELAY,
          }}
          variant="tertiary"
        />
        <EmbedButton
          disabled={!canScroll}
          icon="angle-right"
          onClick={handleScrollRight}
          tooltipProps={{
            align: 'end',
            side: 'bottom',
            text: 'Scroll right',
            delayDuration: TOOLTIP_DELAY,
          }}
          variant="tertiary"
        />
        <DocsPopover />
      </div>
      {currentView && dataInfo ? (
        <ViewContent dataInfo={dataInfo} view={currentView} />
      ) : (
        <NoDataSelected />
      )}
    </div>
  );
};

/**
 * Returns how much to scroll container by so that child is just visible
 */
function getScrollOffset(child: HTMLElement, container: HTMLElement) {
  const left = child.offsetLeft;
  const listLeft = container.scrollLeft;
  const leftScroll = left - listLeft;
  if (leftScroll < 0) return leftScroll;

  const width = child.offsetWidth;
  const right = left + width;
  const listRight = listLeft + container.clientWidth;
  const rightScroll = right - listRight;
  if (rightScroll > 0) return rightScroll;

  return 0;
}

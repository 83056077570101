import { FC, useState, DetailedHTMLProps, HTMLAttributes } from 'react';
import cx from 'classnames';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

import * as styles from './index.css';
import { IconButton } from 'components/ds';
import { IconName } from 'components/ds/Icon';

type HTMLDivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>;

export type NavigationItem = {
  iconName: IconName;
  value: string;
};

export type Props =
  | (ToggleGroup.ToggleGroupSingleProps | ToggleGroup.ToggleGroupMultipleProps) &
      HTMLDivProps & {
        'data-testid'?: string;
        items: NavigationItem[];
        type?: 'single' | 'multiple';
        valueRequired?: boolean;
      };

export const NavigationToggle: FC<Props> = ({
  items,
  className,
  type,
  defaultValue,
  valueRequired,
  onValueChange,
  value: valueProp,
  'data-testid': testId,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue ?? valueProp);
  if (items.length === 0) return null;

  const sharedProps = {
    ...props,
    className: cx(styles.baseContainer, className),
    'data-testid': testId,
  };

  // Different prop types required for single vs multiple
  let rootProps;
  if (type === 'multiple') {
    rootProps = {
      ...sharedProps,
      onValueChange: (value) => {
        if (valueRequired && value.length === 0) return;
        setValue(value);
        onValueChange?.(value);
      },
      type: 'multiple',
      value,
    } as ToggleGroup.ToggleGroupMultipleProps;
  } else {
    rootProps = {
      ...sharedProps,
      onValueChange: (value) => {
        if (valueRequired && !value) return;
        setValue(value);
        onValueChange?.(value);
      },
      type: 'single',
      value,
    } as ToggleGroup.ToggleGroupSingleProps;
  }

  return (
    <ToggleGroup.Root {...rootProps}>
      {items.map((item, index) => {
        const { iconName, value } = item;
        return (
          <ToggleGroup.Item asChild key={`${iconName}-${index}`} value={value}>
            <IconButton
              className={styles.item}
              data-testid={`${testId}-${value}`}
              name={iconName}
            />
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { Select, TextArea, Button, sprinkles } from 'components/ds';
import { HorizontalAlignmentToggle, VerticalAlignmentToggle } from 'components/AlignmentToggles';

import {
  TextDashboardElemConfig,
  TEXT_ELEM_SIZES,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  TEXT_ELEM_VERTICAL_ALIGNMENTS,
} from 'types/dashboardTypes';
import { TEXT_ELEM_SIZE_CONFIGS } from 'constants/dashboardConstants';

type Props = {
  config: TextDashboardElemConfig;

  updateConfig: (newConfig: TextDashboardElemConfig) => void;
};

const configInputClass = sprinkles({ marginTop: 'sp1.5', width: 'fill' });

const textSizeOptions = [
  TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.SMALL],
  TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.MEDIUM],
  TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.LARGE],
];

export const TextElementConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  const updateTextConfig = (update: (config: TextDashboardElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  const horizAlignment = config.alignmentHoriz || TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT;
  const vertAlignment = config.alignmentVertical || TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP;

  return (
    <div>
      <TextArea
        className={configInputClass}
        defaultValue={config.text}
        label={{ text: 'Text', variableInputLabel: true }}
        onSubmit={(newValue) => updateTextConfig((draft) => (draft.text = newValue))}
      />
      <div className={sprinkles({ body: 'b3', color: 'gray10' })}>We now support markdown!</div>
      <Select
        className={configInputClass}
        label="Text Size"
        onChange={(value) =>
          updateTextConfig((draft) => (draft.textSize = value as TEXT_ELEM_SIZES))
        }
        placeholder=""
        selectedValue={config.textSize ?? TEXT_ELEM_SIZES.SMALL}
        values={textSizeOptions}
      />
      <HorizontalAlignmentToggle
        className={configInputClass}
        selectedAlignment={horizAlignment}
        updateAlignment={(alignment) =>
          updateTextConfig((draft) => (draft.alignmentHoriz = alignment))
        }
      />
      <VerticalAlignmentToggle
        className={configInputClass}
        selectedAlignment={vertAlignment}
        updateAlignment={(alignment) =>
          updateTextConfig((draft) => (draft.alignmentVertical = alignment))
        }
      />
      <div
        className={sprinkles({ flexItems: 'alignCenterBetween', gap: 'sp3', marginTop: 'sp1.5' })}>
        <Button
          fillWidth
          icon="bold"
          onClick={() => updateTextConfig((draft) => (draft.text = `**${draft.text}**`))}
          variant="secondary">
          Bold Text
        </Button>

        <Button
          fillWidth
          icon="italic"
          onClick={() => updateTextConfig((draft) => (draft.text = `*${draft.text}*`))}
          variant="secondary">
          Italicize Text
        </Button>
      </div>
    </div>
  );
};

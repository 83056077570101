import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DashboardPage } from './dashboardPage';

import { ReduxState } from 'reducers/rootReducer';
import { GlobalStylesProvider } from 'globalStyles';
import { loadFonts } from 'globalStyles/utils';

export const DashboardPageWrapper: FC = () => {
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const team = useSelector((state: ReduxState) => state.currentUser.team);

  useEffect(() => {
    if (team) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {() => <DashboardPage />}
    </GlobalStylesProvider>
  );
};

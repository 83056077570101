import { FC } from 'react';
import cx from 'classnames';

import { sprinkles, Icon } from 'components/ds';
import { IconName } from 'components/ds/Icon';

type Props = {
  text: string | JSX.Element;
  className?: string;
  error?: boolean;
  noTopMargin?: boolean;
  leftIcon?: IconName;
};

export const InfoCard: FC<Props> = ({ className, error, noTopMargin, text, leftIcon }) => {
  return (
    <div
      className={cx(
        sprinkles({ borderRadius: 4, padding: 'sp1', body: 'b3' }),
        sprinkles({
          backgroundColor: error ? 'red3' : 'blue3',
          color: error ? 'red9' : 'blue9',
          marginTop: noTopMargin ? undefined : 'sp1',
        }),
        className,
      )}
      data-testid="error-infocard">
      {leftIcon ? <Icon className={sprinkles({ marginRight: 'sp1' })} name={leftIcon} /> : null}
      {text}
    </div>
  );
};

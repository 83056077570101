import { Component } from 'react';
import cx from 'classnames';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Button, Position, Popover, Intent, Icon } from '@blueprintjs/core';

import { APP_PORTAL_ID, sprinkles, Tag, Tooltip } from 'components/ds';

const styles = (theme: Theme) => ({
  menuItem: {
    height: 34,
    padding: `0px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 3,
    '&:hover .action-menu': {
      visibility: 'visible',
    },
  },
  datasetActionMenu: {
    visibility: 'hidden' as const,
  },
  menuItemErrorTag: {
    // red 8%
    backgroundColor: 'rgba(229, 29, 0, 0.08)',
    color: theme.palette.ds.red,
  },
  menuItemDraftTag: {
    // black 8%
    backgroundColor: 'rgba(10, 17, 30, 0.08)',
    color: theme.palette.ds.grey800,
  },
  menuItemActiveTag: {
    // white 16%
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    color: theme.palette.ds.white,
  },
  menuItemTextContainerSelected: {
    backgroundColor: theme.palette.ds.blue,
    color: theme.palette.ds.white,
  },
  menuItemTextContainerNotSelected: {
    '&:hover': {
      backgroundColor: theme.palette.ds.grey300,
    },
  },
  activeMenu: {
    '& .bp3-icon': {
      color: theme.palette.ds.black,
    },
  },
});

type PassedProps = {
  onClick: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  active: boolean;
  draft?: boolean;
  error?: boolean;
  used?: boolean;
  name: string;
  loading?: boolean;
  noCustomerFilter?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  actionsMenuOpen: boolean;
};

class DatasetMenuItem extends Component<Props, State> {
  state: State = {
    actionsMenuOpen: false,
  };

  render() {
    const { onClick, active, classes } = this.props;
    return (
      <div
        className={cx(classes.menuItem, {
          [classes.menuItemTextContainerSelected]: active,
          [classes.menuItemTextContainerNotSelected]: !active,
        })}
        onClick={onClick}>
        {this.renderDatasetItemText()}
      </div>
    );
  }

  renderDatasetItemText = () => {
    const { name, used } = this.props;
    return (
      <div className={sprinkles({ flexItems: 'alignCenter', width: 'fill' })}>
        {used && (
          <Icon
            className={sprinkles({ paddingRight: 'sp.5' })}
            icon="full-circle"
            iconSize={6} // 6 is the size of the dot icon
            intent={Intent.PRIMARY}
          />
        )}
        <Tooltip portalContainerId={APP_PORTAL_ID} side="right" text={name}>
          <div className={sprinkles({ truncateText: 'ellipsis', marginRight: 'sp1' })}>{name}</div>
        </Tooltip>
        {this.renderDatasetItemTags()}
        {this.renderDatasetActionsMenu()}
      </div>
    );
  };

  renderDatasetActionsMenu = () => {
    const { classes, onEditClicked, onDeleteClicked, loading } = this.props;
    const { actionsMenuOpen } = this.state;

    if (!onDeleteClicked && !onEditClicked) return null;

    return (
      <Popover
        className={sprinkles({ marginLeft: 'auto' })}
        disabled={loading}
        hoverCloseDelay={0}
        inheritDarkTheme={false}
        isOpen={actionsMenuOpen}
        onClose={() => this.setState({ actionsMenuOpen: false })}
        position={Position.BOTTOM}>
        <Button
          minimal
          small
          className={cx(classes.datasetActionMenu, 'action-menu', {
            'bp3-dark': loading,
            [classes.activeMenu]: this.props.active,
          })}
          icon="more"
          loading={loading}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ actionsMenuOpen: !actionsMenuOpen });
          }}
        />
        <Menu>
          {onEditClicked ? <MenuItem icon="edit" onClick={onEditClicked} text="Edit Name" /> : null}
          {onDeleteClicked ? (
            <MenuItem icon="cross" onClick={onDeleteClicked} text="Delete" />
          ) : null}
        </Menu>
      </Popover>
    );
  };

  renderDatasetItemTags = () => {
    const { classes, active } = this.props;

    const sharedClasses = cx(
      sprinkles({ marginRight: 'sp1' }),
      active ? classes.menuItemActiveTag : undefined,
    );

    return (
      <>
        {this.props.noCustomerFilter ? (
          <Tooltip
            portalContainerId={APP_PORTAL_ID}
            side="right"
            text="This dataset is not filtered by customer_id. Click to learn more.">
            <a
              href="https://docs.explo.co/creating-dashboards/variables/tips"
              rel="noreferrer"
              target="_blank">
              <Tag
                className={cx(sharedClasses, { [classes.menuItemDraftTag]: !active })}
                leftIcon="user-slash"
              />
            </a>
          </Tooltip>
        ) : null}
        {this.props.draft && (
          <Tag className={cx(sharedClasses, { [classes.menuItemDraftTag]: !active })}>Draft</Tag>
        )}
        {this.props.error && (
          <Tag className={cx(sharedClasses, { [classes.menuItemErrorTag]: !active })}>Error</Tag>
        )}
      </>
    );
  };
}

export default withStyles(styles)(DatasetMenuItem);

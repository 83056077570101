import { FC } from 'react';

import { RangeInput } from 'components/ChartConfigs/RangeInput';
import { Switch, Input } from 'components/ds';

import { YAxisFormat } from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { NumberFormatToggle } from 'components/NumberFormatToggle';

type Props = {
  configInputClass: string;
  isNormalized: boolean;
  yAxisFormat: YAxisFormat | undefined;

  updateAxisFormat: (yAxisFormat: YAxisFormat) => void;
};

export const SharedYAxisConfigs: FC<Props> = ({
  configInputClass,
  isNormalized,
  updateAxisFormat,
  yAxisFormat,
}) => {
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  return (
    <>
      <Switch
        className={configInputClass}
        label="Axis Title"
        onChange={() => updateAxisFormat({ showTitle: !yAxisFormat?.showTitle })}
        switchOn={yAxisFormat?.showTitle}
      />
      {yAxisFormat?.showTitle ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={yAxisFormat?.title}
          onSubmit={(newValue) => updateAxisFormat({ title: newValue })}
        />
      ) : null}

      <NumberFormatToggle
        disableAbbr
        disableTime
        className={configInputClass}
        label="Value Format"
        labelHelpText={isNormalized ? 'Formatting will only affect the value on hover' : undefined}
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateAxisFormat({ numberFormat: { id: numberFormat } })}
      />
      <Switch
        className={configInputClass}
        label="Show Axis Decimals"
        onChange={() => updateAxisFormat({ showDecimals: !yAxisFormat?.showDecimals })}
        switchOn={yAxisFormat?.showDecimals}
      />
      {yAxisFormat?.showDecimals ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(yAxisFormat?.decimalPlaces ?? 2)}
          label={{
            text: 'Decimal Places',
            infoText:
              "This will only apply to the data tooltip unless 'Show Axis Decimals' is enabled.",
          }}
          onSubmit={(newValue: string) => {
            const intValue = parseInt(newValue);
            updateAxisFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      <Switch
        className={configInputClass}
        label="Use Logarithmic Scale"
        onChange={() => {
          let min = yAxisFormat?.min;
          // log scales do not allow minimumValue of <= 0
          if (min !== undefined && min <= 0) min = undefined;

          updateAxisFormat({ useLogScale: !yAxisFormat?.useLogScale, min });
        }}
        switchOn={yAxisFormat?.useLogScale}
      />
      <RangeInput
        className={configInputClass}
        endVal={String(yAxisFormat?.max ?? '')}
        onNewRange={(newStart?: string, newEnd?: string) => {
          const min = parseFloat(newStart ?? '');
          const max = parseFloat(newEnd ?? '');

          const validIfLog = !(yAxisFormat?.useLogScale && min <= 0);

          updateAxisFormat({
            min: min >= 0 && validIfLog ? min : undefined,
            max: max >= 0 ? max : undefined,
          });
        }}
        startVal={String(yAxisFormat?.min ?? '')}
      />
      <Switch
        className={configInputClass}
        label="Axis Labels"
        onChange={() => updateAxisFormat({ hideAxisLabels: !yAxisFormat?.hideAxisLabels })}
        switchOn={!yAxisFormat?.hideAxisLabels}
      />
      <Switch
        className={configInputClass}
        label="Grid Lines"
        onChange={() => updateAxisFormat({ hideGridLines: !yAxisFormat?.hideGridLines })}
        switchOn={!yAxisFormat?.hideGridLines}
      />
      <Switch
        className={configInputClass}
        label="Reverse Axis"
        onChange={() => updateAxisFormat({ reverseAxis: !yAxisFormat?.reverseAxis })}
        switchOn={yAxisFormat?.reverseAxis}
      />
    </>
  );
};

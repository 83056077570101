import { FC, useMemo } from 'react';
import { uniqueId } from 'utils/standard';
import cx from 'classnames';
import produce from 'immer';

import {
  APP_PORTAL_ID,
  Button,
  IconButton,
  Input,
  Select,
  sprinkles,
  Tooltip,
} from 'components/ds';
import { ColorPaletteDisplay } from 'components/ColorPaletteDisplay';
import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import * as styles from './styles.css';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { InfoCard } from 'components/InfoCard';

import {
  ColorFormat,
  ColorPalette,
  ColorPaletteV2,
  ColorZone,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DEFAULT_PALETTE_COLOR } from 'pages/dashboardPage/charts/utils';

type Props = {
  configInputClass: string;
  globalStyleConfig: GlobalStyleConfig;
  zonesAllowed?: boolean;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions;

  updateColorFormat: (colorFormat: ColorFormat) => void;
};

const COLOR_ZONES = 'Color Zones';

export const SharedColorConfigs: FC<Props> = ({
  configInputClass,
  zonesAllowed,
  instructions,
  updateColorFormat,
  globalStyleConfig,
}) => {
  const colorFormat = instructions.colorFormat;
  const isUsingZones = zonesAllowed && colorFormat?.useZones;

  const selectedPalette = colorFormat?.selectedPalette || ColorPaletteV2.CATEGORICAL;
  const selectedPaletteOption = isUsingZones ? COLOR_ZONES : selectedPalette;

  const paletteOptions = useMemo(() => {
    const options: { value: string }[] = [];
    addPaletteToList(options, Object.values(ColorPalette));
    addPaletteToList(options, Object.values(ColorPaletteV2));
    if (zonesAllowed) addPaletteToList(options, [COLOR_ZONES]);
    return options;
  }, [zonesAllowed]);

  const colorZones = colorFormat?.colorZones || [];

  const updateZoneAtIndex = (index: number, updateZone: (colorZoneDraft: ColorZone) => void) => {
    updateColorFormat({
      colorZones: produce(colorZones, (draft) => {
        updateZone(draft[index]);
      }),
    });
  };

  return (
    <>
      <Select
        className={configInputClass}
        label="Color Palette"
        onChange={(value) => {
          if (value === COLOR_ZONES) {
            updateColorFormat({ useZones: true });
          } else {
            updateColorFormat({
              selectedPalette: value as ColorPalette | ColorPaletteV2,
              useZones: false,
            });
          }
        }}
        placeholder="Select Palette"
        selectedValue={selectedPaletteOption}
        values={paletteOptions}
      />
      {isUsingZones ? (
        <div className={configInputClass}>
          <InfoCard
            className={sprinkles({ marginBottom: 'sp1' })}
            text="Entries should be sorted from lowest to highest. For each zone, the threshold defines the upper-bound of when that color will be used"
          />
          {colorZones.length ? (
            <ColorZoneList
              colorZones={colorZones}
              updateColorZones={(zones) => updateColorFormat({ colorZones: zones })}
              updateZoneAtIndex={updateZoneAtIndex}
            />
          ) : null}

          <Button
            fillWidth
            icon="plus"
            onClick={() => {
              const newColorZone = {
                zoneColor: DEFAULT_PALETTE_COLOR,
                zoneId: uniqueId('color-zone'),
              };
              updateColorFormat({ colorZones: colorZones.concat([newColorZone]) });
            }}
            variant="secondary">
            Add Zone
          </Button>
        </div>
      ) : (
        <ColorPaletteDisplay
          className={cx(configInputClass, sprinkles({ marginY: 'sp2' }))}
          customColors={colorFormat?.customColors}
          globalStyleConfig={globalStyleConfig}
          palette={selectedPalette}
          saveCustomColorConfig={(colorPalette) =>
            updateColorFormat({
              selectedPalette: ColorPalette.CUSTOM,
              customColors: colorPalette.join(','),
            })
          }
        />
      )}
    </>
  );
};

type ColorZoneListProps = {
  colorZones: ColorZone[];
  updateColorZones: (zones: ColorZone[]) => void;
  updateZoneAtIndex: (idx: number, updateZone: (colorZone: ColorZone) => void) => void;
};

const ColorZoneList: FC<ColorZoneListProps> = ({
  colorZones,
  updateColorZones,
  updateZoneAtIndex,
}) => {
  const numZones = colorZones.length;
  return (
    <SortableList
      getIdFromElem={(item) => item.zoneId}
      onListUpdated={updateColorZones}
      sortableItems={colorZones}>
      {colorZones.map((zone, i) => {
        const isLastZone = numZones === i + 1;

        let zoneThresholdInput = (
          <Input
            className={sprinkles({ marginRight: 'sp1' })}
            defaultValue={zone.zoneThreshold}
            disabled={isLastZone}
            onSubmit={(newValue) =>
              updateZoneAtIndex(i, (newZone) => (newZone.zoneThreshold = newValue))
            }
            placeholder="Threshold"
          />
        );

        if (isLastZone) {
          zoneThresholdInput = (
            <Tooltip
              align="center"
              portalContainerId={APP_PORTAL_ID}
              side="bottom"
              text={
                <div className={sprinkles({ textAlign: 'center' })}>
                  The last zone fills the remaining vertical space
                </div>
              }>
              <span>{zoneThresholdInput}</span>
            </Tooltip>
          );
        }

        return (
          <SortableListItem key={zone.zoneId} sortId={zone.zoneId}>
            <div className={styles.colorZoneContainer}>
              <SortableListItemDragHandle className={sprinkles({ marginRight: 'sp1' })} />
              <CustomStylesColorPicker
                fill
                className={sprinkles({ marginRight: 'sp1' })}
                color={zone.zoneColor}
                onClose={(newColor) =>
                  updateZoneAtIndex(i, (newZone) => (newZone.zoneColor = newColor))
                }
              />
              {zoneThresholdInput}
              <IconButton
                className={styles.colorZoneTrashButton}
                name="trash"
                onClick={() =>
                  updateColorZones(
                    produce(colorZones, (draft) => {
                      draft.splice(i, 1);
                    }),
                  )
                }
                variant="destructive"
              />
            </div>
          </SortableListItem>
        );
      })}
    </SortableList>
  );
};

const addPaletteToList = (list: { value: string }[], palettes: string[]) =>
  palettes.forEach((palette) => list.push({ value: palette }));

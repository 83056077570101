import { FC } from 'react';

import { GoalInputRow } from './GoalInputRow';
import { Input, Select, sprinkles, Switch, Toggle, ToggleItem } from 'components/ds';
import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';

import { getCellAlignment } from 'components/dataTable/utils';
import { DatasetColumn } from 'types/datasets';
import {
  GradientPointType,
  NumberDisplayFormat,
  NumberDisplayOptions,
  OPERATION_TYPES,
} from 'constants/types';
import { FLOAT, TIME_DIFF_FORMATS, TIME_DIFF_FORMAT_SELECT_OPTIONS } from 'constants/dataConstants';
import { getCurrentNumberFormat } from 'utils/formatConfigUtils';

type Props = {
  updateNumberOptions: (newFields: NumberDisplayOptions) => void;
  column: DatasetColumn;
  displayOptions: NumberDisplayOptions | undefined;
  operationType:
    | OPERATION_TYPES.VISUALIZE_TABLE
    | OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST
    | OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2
    | 'REPORT_BUILDER';
};

export const NumberFormatConfig: FC<Props> = ({
  updateNumberOptions,
  column,
  displayOptions,
  operationType,
}) => {
  const {
    decimalPlaces,
    multiplier,
    hasCommas,
    goal,
    useColumnMaxForGoal,
    timeFormat,
    zeroCharacter,
    displayNegativeValuesWithParentheses,
  } = displayOptions ?? {};
  const currentFormat = getCurrentNumberFormat(displayOptions);
  const currentAlignment = getCellAlignment(displayOptions, column.type);

  const onFormatButtonClick = (newFormat: NumberDisplayFormat) => {
    if (newFormat === currentFormat) return;

    updateNumberOptions({ format: newFormat });
  };

  return (
    <>
      <Toggle
        className={inputClass}
        label="Formatting"
        onValueChange={(format) => onFormatButtonClick(format as NumberDisplayFormat)}
        selectedValue={currentFormat}>
        <ToggleItem value={NumberDisplayFormat.NORMAL}>#</ToggleItem>
        <ToggleItem value={NumberDisplayFormat.CURRENCY}>$</ToggleItem>
        <ToggleItem value={NumberDisplayFormat.PERCENT}>%</ToggleItem>
        <ToggleItem value={NumberDisplayFormat.TIME}>00:00</ToggleItem>
      </Toggle>
      {currentFormat !== NumberDisplayFormat.TIME ? (
        <>
          <Switch
            className={inputClass}
            label="Include thousands-separators"
            onChange={() => updateNumberOptions({ hasCommas: !hasCommas })}
            switchOn={hasCommas}
          />
          <div className={sideBySideInputsClass}>
            <Input
              fillWidth
              showInputButton
              defaultValue={String(decimalPlaces ? decimalPlaces : column.type === FLOAT ? 2 : 0)}
              label="Decimal places"
              onSubmit={(newValue) => {
                const newNum = Number(newValue);
                if (isNaN(newNum)) return;

                updateNumberOptions({ decimalPlaces: newNum });
              }}
            />
            <Input
              fillWidth
              showInputButton
              defaultValue={String(multiplier ?? 1)}
              label="Multiplier"
              onSubmit={(newValue) => {
                const newNum = Number(newValue);
                if (isNaN(newNum)) return;

                updateNumberOptions({ multiplier: newNum });
              }}
            />
          </div>
          <Switch
            className={inputClass}
            label="Display negative values with parentheses"
            onChange={() =>
              updateNumberOptions({
                displayNegativeValuesWithParentheses: !displayNegativeValuesWithParentheses,
              })
            }
            switchOn={displayNegativeValuesWithParentheses}
          />
          <Switch
            className={inputClass}
            label="Replace 0 value with a character"
            onChange={() => updateNumberOptions({ zeroCharacter: zeroCharacter ? undefined : '-' })}
            switchOn={zeroCharacter !== undefined}
          />
          {zeroCharacter ? (
            <Input
              fillWidth
              showInputButton
              className={sprinkles({ paddingTop: 'sp1' })}
              defaultValue={zeroCharacter}
              onSubmit={(newValue) => updateNumberOptions({ zeroCharacter: newValue })}
            />
          ) : null}
        </>
      ) : null}
      {currentFormat === NumberDisplayFormat.TIME && (
        <>
          <Select
            className={inputClass}
            label="Time Format"
            onChange={(value) => updateNumberOptions({ timeFormat: { id: value } })}
            selectedValue={timeFormat?.id ?? TIME_DIFF_FORMATS.STANDARD.id}
            values={TIME_DIFF_FORMAT_SELECT_OPTIONS}
          />
          {displayOptions?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id ? (
            <Input
              showInputButton
              className={inputClass}
              defaultValue={displayOptions?.timeCustomFormat}
              label="Custom Time Format"
              onSubmit={(newValue) => updateNumberOptions({ timeCustomFormat: newValue })}
              placeholder="DD days HH:mm:ss"
            />
          ) : null}
        </>
      )}

      {currentFormat === NumberDisplayFormat.PERCENT ? (
        <GoalInputRow
          disableColumnCalculation={operationType !== OPERATION_TYPES.VISUALIZE_TABLE}
          exactValue={goal}
          label="Denominator"
          setGradientPointType={(newType) =>
            updateNumberOptions({ useColumnMaxForGoal: newType === GradientPointType.COMPUTED })
          }
          type="max"
          updateExactValue={(newValue) => updateNumberOptions({ goal: newValue })}
          usesExactValue={!useColumnMaxForGoal}
        />
      ) : null}
      <HorizontalAlignmentToggle
        className={inputClass}
        label="Alignment"
        selectedAlignment={currentAlignment}
        updateAlignment={(alignment) => updateNumberOptions({ alignment })}
      />
    </>
  );
};

const inputClass = sprinkles({ marginTop: 'sp1' });
const sideBySideInputsClass = sprinkles({
  marginTop: 'sp1',
  flexItems: 'alignCenterBetween',
  gap: 'sp1',
});

import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AlertModal, IconButton, Input, Modal, Select, sprinkles } from 'components/ds';

import { ReduxState } from 'reducers/rootReducer';
import { createNewTheme, setEditingTheme } from 'reducers/styleConfigReducer';
import { GlobalStyleConfig } from 'globalStyles/types';
import { deleteAdditionalStyle } from 'actions/styleConfigActions';

const DEFAULT_STYLE_CONFIG = 'default';

const themePattern = /^[a-z_-]+$/;

type Props = { currentConfig: GlobalStyleConfig; disableDropdown: boolean; isNew: boolean };

export const AdditionalStylesSection: FC<Props> = ({ currentConfig, disableDropdown, isNew }) => {
  const dispatch = useDispatch();

  const [newThemeName, setNewThemeName] = useState<string>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { editingThemeName, additionalStyleConfigOptions } = useSelector(
    (state: ReduxState) => ({
      editingThemeName: state.styleConfig.editingThemeName,
      additionalStyleConfigOptions: state.dashboardStyles.additionalStyleConfigOptions,
    }),
    shallowEqual,
  );

  const selectedItem = editingThemeName === null ? DEFAULT_STYLE_CONFIG : editingThemeName;

  const options = useMemo(() => {
    const dropdownOptions: { value: string }[] = [{ value: DEFAULT_STYLE_CONFIG }];

    Object.keys(additionalStyleConfigOptions).forEach((theme) =>
      dropdownOptions.push({ value: theme }),
    );

    if (editingThemeName && !additionalStyleConfigOptions[editingThemeName]) {
      dropdownOptions.push({ value: editingThemeName });
    }

    return dropdownOptions;
  }, [editingThemeName, additionalStyleConfigOptions]);

  const optionSet = useMemo(() => new Set(options.map((option) => option.value)), [options]);

  const renderNewThemeModal = () => {
    if (newThemeName === undefined) return;
    const isEmpty = newThemeName.trim() === '';
    const errorText = optionSet.has(newThemeName)
      ? 'This theme name is already used'
      : !isEmpty && newThemeName.match(themePattern) === null
      ? 'Name can only contain lowercase letters, -, or _'
      : undefined;

    return (
      <Modal
        isOpen
        onClose={() => setNewThemeName(undefined)}
        primaryButtonProps={{
          disabled: isEmpty || !!errorText,
          onClick: () => dispatch(createNewTheme({ name: newThemeName, config: currentConfig })),
          text: 'Submit',
        }}
        size="small"
        title="Create new theme">
        <div className={sprinkles({ paddingX: 'sp2' })}>
          <Input
            defaultValue={newThemeName}
            errorText={errorText}
            label="Theme Name"
            onSubmit={setNewThemeName}
            placeholder="new_theme"
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className={containerClass}>
      <div className={headerClass}>
        <div className={sprinkles({ heading: 'h4' })}>Theme to edit</div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <IconButton
            name="plus"
            onClick={() => setNewThemeName('')}
            tooltipProps={{ text: 'Create new theme', side: 'right' }}
            variant="primary"
          />
          <IconButton
            disabled={editingThemeName === null || isNew}
            name="trash"
            onClick={() => setIsDeleteModalOpen(true)}
            tooltipProps={
              editingThemeName
                ? { text: `Delete ${editingThemeName} theme`, side: 'right' }
                : undefined
            }
            variant="destructive"
          />
        </div>
      </div>
      <Select
        className={sprinkles({ flex: 1 })}
        disabled={disableDropdown}
        onChange={(value) =>
          dispatch(setEditingTheme(value === DEFAULT_STYLE_CONFIG ? null : value))
        }
        selectedValue={selectedItem}
        values={options}
      />
      {renderNewThemeModal()}
      {isDeleteModalOpen && editingThemeName ? (
        <AlertModal
          isOpen
          actionButtonProps={{
            onClick: () =>
              dispatch(deleteAdditionalStyle({ postData: { theme_name: editingThemeName } })),
          }}
          onClose={() => setIsDeleteModalOpen(false)}
          title={`Are you sure you want to delete ${editingThemeName} theme?`}
        />
      ) : null}
    </div>
  );
};

const containerClass = sprinkles({
  padding: 'sp3',
  width: 'fill',
  borderBottom: 1,
  borderColor: 'gray7',
});

const headerClass = sprinkles({
  flexItems: 'alignCenterBetween',
  marginBottom: 'sp2',
});

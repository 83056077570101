import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Button, Icon } from '@blueprintjs/core';
import { EmbedInput } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { sprinkles } from 'components/ds';
import * as styles from './styles.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      '& .bp3-input': {
        fontSize: 12,
        height: 20,
      },
    },
  },
  input: {
    [theme.breakpoints.down('xs')]: {
      width: 30,
    },
  },
}));

type Props = {
  className?: string;
  currentPage: number;
  maxPageNumber: number;
  onNewPage: (newPage: string) => void;
};

export default function TablePager({ currentPage, maxPageNumber, onNewPage }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        minimal
        disabled={currentPage === 1}
        icon={<Icon className={styles.pagerButton} icon="chevron-left" />}
        onClick={() => currentPage !== 1 && onNewPage(String(currentPage - 1))}
      />
      <div
        className={cx(sprinkles({ marginRight: 'sp.5' }), embedSprinkles({ body: 'secondary' }))}>
        Page
      </div>
      <EmbedInput
        className={cx(classes.input, styles.pagerInput)}
        defaultValue={String(currentPage)}
        onSubmit={onNewPage}
      />
      <div
        className={cx(sprinkles({ whiteSpace: 'nowrap' }), embedSprinkles({ body: 'secondary' }))}>
        {`of ${maxPageNumber}`}
      </div>
      <Button
        minimal
        disabled={currentPage === maxPageNumber}
        icon={<Icon className={styles.pagerButton} icon="chevron-right" />}
        onClick={() => currentPage !== maxPageNumber && onNewPage(String(currentPage + 1))}
      />
    </div>
  );
}

import { FC, useState } from 'react';
import ReactQuill from '@explo-tech/react-quill';

import { IconButton, Input, Select, sprinkles } from 'components/ds';

type Props = { quill: ReactQuill | null; closeInput: () => void };

export const ImageVariableInputToolbar: FC<Props> = ({ quill, closeInput }) => {
  const [inputType, setInputType] = useState('URL');
  const [inputValue, setInputValue] = useState('');

  return (
    <div className={rootClass} style={{ width: '70%' }}>
      <Select
        className={sprinkles({ flex: 2, overflow: 'hidden' })}
        onChange={setInputType}
        placeholder="Select input type"
        selectedValue={inputType}
        values={[{ value: 'URL' }, { value: 'Variable' }]}
      />
      <Input
        className={sprinkles({ flex: 5 })}
        onChange={setInputValue}
        placeholder={inputType === 'URL' ? 'Link to your image' : 'e.g. end_user.header_image'}
        value={inputValue}
      />
      <IconButton
        className={sprinkles({ marginLeft: 'sp.5' })}
        disabled={!inputValue}
        name="cross"
        onClick={() => {
          if (!inputValue || !quill) return closeInput();

          const editor = quill.getEditor();
          editor.insertEmbed(
            editor.getSelection()?.index ?? 0,
            'emailImage',
            inputType === 'URL'
              ? { src: inputValue }
              : {
                  src: 'https://explo-email.s3.us-west-1.amazonaws.com/placeholder+image.png',
                  explo_variable: `{{ ${inputValue} }}`,
                },
          );
        }}
        variant="secondary"
      />
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'alignCenterBetween',
  borderRadius: 4,
  padding: 'sp.5',
  marginTop: 'sp.25',
  backgroundColor: 'white',
  boxShadow: 'sm',
});

import { ThunkAction, AnyAction } from '@reduxjs/toolkit';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { validateColumns } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

/**
 * Ensures that the view config is valid, if not removes invalid aggs, group bys, filters, etc.
 */
export const validateColumnsThunk =
  (): ThunkAction<ReportBuilderDataset | undefined, ReportBuilderReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing } = getState();
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId ?? '';
    const dataset = embeddedReportBuilder.reportBuilderVersion?.config.datasets?.[datasetId];
    if (!dataset) return;
    dispatch(validateColumns({ dataset }));
    return dataset;
  };

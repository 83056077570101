import { FC, useState, useMemo } from 'react';
import cx from 'classnames';
import validator from 'validator';
import { sprinkles, Button, Tag, Input, InfoIcon } from 'components/ds';
import { showErrorToast } from 'shared/sharedToasts';
import type { CustomerContentProps } from '.';
import * as styles from '../styles.css';

export const EmailSection: FC<Pick<CustomerContentProps, 'editorGroup' | 'setEditorGroup'>> = ({
  editorGroup,
  setEditorGroup,
}) => {
  const [currentEmail, setCurrentEmail] = useState<string>();

  const currentEmails = useMemo(() => new Set(editorGroup.emails), [editorGroup.emails]);
  const isValidEmail =
    currentEmail && validator.isEmail(currentEmail) && !currentEmails.has(currentEmail);

  const handleAddEmail = () => {
    if (!currentEmail || !validator.isEmail(currentEmail)) {
      return showErrorToast(`Invalid email: ${currentEmail}`);
    }
    if (currentEmails.has(currentEmail)) return showErrorToast('Email already added');

    setEditorGroup((draft) => {
      draft.emails = [...editorGroup.emails, currentEmail];
    });
    setCurrentEmail(undefined);
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1', marginBottom: 'sp2' })}>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })}>
        <div className={cx(sprinkles({ gap: 'sp.5' }), styles.label)}>
          Emails
          <InfoIcon
            text="Use this field to add multiple emails to each customer. They are used for scheduled dashboard emails."
            tooltipSide="right"
          />
        </div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <Input
            className={styles.flexGrowContainer}
            onChange={setCurrentEmail}
            onEnter={handleAddEmail}
            placeholder="Emails"
            value={currentEmail ?? ''}
          />
          <Button
            disabled={!isValidEmail}
            onClick={handleAddEmail}
            tooltipProps={!isValidEmail && currentEmail ? { text: 'Invalid Email' } : undefined}
            variant="primary">
            Add
          </Button>
        </div>
      </div>

      <div className={styles.tagOutputContainer}>
        {editorGroup.emails.length > 0 ? (
          <div className={styles.emailTagsContainer}>
            {editorGroup.emails.map((email) => (
              <Tag
                intent="active"
                key={email}
                onClose={() =>
                  setEditorGroup((draft) => {
                    draft.emails = draft.emails.filter((e) => e !== email);
                  })
                }>
                {email}
              </Tag>
            ))}
          </div>
        ) : (
          <div className={styles.emptyItemsText}>No emails configured for this customer</div>
        )}
      </div>
    </div>
  );
};

import { IconSize } from 'components/ds/Icon';
import {
  GlobalStyleConfig,
  GlobalStyleTextConfig,
  GlobalStyleComponentsConfig,
  GlobalStylePanelErrorConfig,
} from './types';

export const INTERACTION_STATE_OPACITY = 0.1;
export const ACTION_COLOR_OUTLINE_OPACITY = 0.3;
export const DROP_SHADOW_OPACITY = 0.25;
export const BACKGROUND_COLOR_OFFSET = 0.02;
export const BACKGROUND_COLOR_DEEPER_OFFSET = 0.05;
export const PLACEHOLDER_TEXT_OPACITY = 0.7;

export const TEXT_SIZE_OFFSET_MAP: Record<keyof GlobalStyleTextConfig['overrides'], number> = {
  h1: 8,
  h2: 6,
  h3: 2,
  kpiTitle: 6,
  kpiValue: 22,
  tableColumnHeader: 2,
  body: 0,
  smallHeading: -2,
  smallBody: -2,
  filterLabel: 0,
};

export const DEFAULT_OUTLINE_COLOR = '#eeeeee';

export const DEFAULT_PANEL_ERROR_CONFIG: GlobalStylePanelErrorConfig = {
  icon: { color: '#E5484D', size: 'lg' },
};

export const DEFAULT_COMPONENTS_CONFIG: GlobalStyleComponentsConfig = {
  spinner: {
    size: 'xl',
    width: 'thick',
    color: '#6f6f6f',
  },
  panelError: DEFAULT_PANEL_ERROR_CONFIG,
};

export const DEFAULT_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = {
  base: {
    numColumns: 12,
    actionColor: { default: '#664df1' },
    backgroundColor: '#fdfdfd',
    spacing: { default: 30 },
  },
  container: {
    fill: '#ffffff',
    shadow: { size: 1, color: '#777777', enabled: true },
    outline: { color: DEFAULT_OUTLINE_COLOR, weight: 1, enabled: true },
    padding: { default: 25 },
    lineWidth: { default: 2 },
    cornerRadius: { default: 8, inputFields: 4 },
  },
  text: {
    textSize: 14,
    overrides: {
      smallBody: { color: '#777777' },
    },
    primaryFont: 'Manrope',
    primaryColor: '#0a0a0a',
    secondaryFont: 'Manrope',
    secondaryColor: '#777777',
  },
  visualizations: {
    gradientPalette: { hue1: '#ffffff', hue2: '#2196f3' },
    divergingPalette: { hue1: '#30cebb', hue2: '#FFFFFF', hue3: '#2196f3' },
    categoricalPalette: {
      hue1: '#664df1',
      hue2: '#f13663',
      hue3: '#ffc107',
      hue4: '#30cebb',
      hue5: '#f89368',
      hue6: '#2196f3',
    },
  },
  components: DEFAULT_COMPONENTS_CONFIG,
};

export const GLOBAL_STYLE_CLASSNAMES = {
  base: {
    actionColor: {
      default: {
        dropdownInputMenuItem: 'explo-base-actionColor-default_dropdownInputMenuItem',
        dropdownInputBorder: 'explo-base-actionColor-default_dropdownInputBorder',
        dropdownFilterInputOutline: 'explo-base-actionColor-default_dropdownFilterInputOutline',
        multiSelectInputOutlineActive: 'explo-base-actionColor-default_multiSelectInputOutline',
        multiSelectInputTag: 'explo-base-actionColor-default_multiSelectInputTag',
        buttonBorderActive: 'explo-base-actionColor-default_buttonBorderActive',
      },
      buttonColor: {
        buttonBackgroundColor: 'explo-base-actionColor-buttonColor_buttonBackgroundColor',
        buttonMinimalColor: 'explo-base-actionColor-buttonColor_buttonMinimalColor',
        buttonColor: 'explo-base-actionColor-buttonColor_buttonColor',
        toggleSwitchInteraction: 'explo-base-actionColor-buttonColor_toggleSwitchInteraction',
      },
      interactionStates: {
        dropdownItemHover: 'explo-base-actionColor-interactionStates_dropdownItemHover',
        dropdownInputBorderHover:
          'explo-base-actionColor-interactionStates_dropdownInputBorderHover',
        multiSelectInputBorderHover:
          'explo-base-actionColor-interactionStates_multiSelectInputBorderHover',
        textInputBorderHover: 'explo-base-actionColor-interactionStates_textInputBorderHover',
        buttonBorderHover: 'explo-base-actionColor-default_buttonBorderHover',
      },
    },
  },
  container: {
    fill: {
      offsetBackgroundColor: 'explo-container-fill_offsetBackgroundColor',
      deeperOffsetBackgroundColor: 'explo-container-fill_deeperOffsetBackgroundColor',
    },
    outline: {
      border: 'explo-container-outline_border',
      dropdownInputBorder: 'explo-container-outline_dropdownInputBorder',
      popoverBorder: 'explo-container-outline_popoverBorder',
      multiSelectInputBorder: 'explo-container-outline_multiSelectInputBorder',
      dropdownFilterInputBorder: 'explo-container-outline_dropdownFilterInputBorder',
      buttonBorder: 'explo-container-outline_buttonBorder',
    },
    shadow: {
      dropShadow: 'explo-container-shadow_dropShadow',
      multiSelectInputShadow: 'explo-container-outline_multiSelectInputShadow',
      buttonShadow: 'explo-container-outline_buttonShadow',
    },
    cornerRadius: {
      default: {
        popoverCornerRadius: 'explo-container-cornerRadius-default_popoverCornerRadius',
      },
      inputFields: {
        defaultBorderRadius: 'explo-container-cornerRadius-inputFields_defaultBorderRadius',
        textElementInputBorderRadius: 'explo-container-cornerRadius-default_inputFieldBorderRadius',
        multiselectBorderRadius: 'explo-container-cornerRadius-default_multiselectBorderRadius',
      },
    },
  },
  text: {
    body: {
      dropdownMenuItem: 'explo-text-body_dropdownMenuItem',
      input: 'explo-text-body_input',
    },
  },
};

export const ICON_SIZES: { label: string; value: IconSize }[] = [
  { label: 'Small', value: 'sm' },
  { label: 'Medium', value: 'md' },
  { label: 'Large', value: 'lg' },
  { label: 'Extra Large', value: 'xl' },
];

export enum STYLE_TABS {
  OVERVIEW = 'Overview',
  COMPONENTS = 'Components',
}

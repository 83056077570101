import { FC, useRef, useCallback, MouseEvent as ReactMouseEvent } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FidoDashboardDatasetEditorDataFetcher } from './fidoDashboardDatasetEditorDataFetcher';
import { DashboardDatasetEditorDataFetcher } from './dashboardDatasetEditorDataFetcher';

import { ReduxState } from 'reducers/rootReducer';
import { DATASET_EDITOR_MODE } from 'types/dashboardTypes';
import { SIDE_PANE_HEADER_HEIGHT, SIDE_PANE_WIDTH } from '../editDashboardPage.css';
import { MENU_HEIGHT } from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';
import {
  setDatasetEditorHeight,
  setDatasetEditorMode,
} from 'reducers/dashboardInteractionsReducer';

const useStyles = makeStyles((theme: Theme) => ({
  datasetEditorContainer: {
    right: 0,
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  datasetEditorDivider: {
    cursor: 'row-resize',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.25),
    opacity: 0,
  },
  datasetEditorHidden: {
    height: `0 !important`,
  },
  datasetEditorClosed: {
    height: `${MENU_HEIGHT}px !important`,
  },
  datasetEditorExpanded: {
    height: `90vh !important`,
  },
  datasetEditorExpandedWithLeftPanel: {
    left: `-${SIDE_PANE_WIDTH}px !important`,
  },
  pageOverlay: {
    zIndex: 0,
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
}));

type Props = {
  left?: number;
  dashboardId: number;
  inEditMode?: boolean;
  leftPaneIsOpen: boolean;
  pageWidth: number | null;
};

type DragProps = { dragStartY: number; startHeight: number };

export const ResizableDatasetEditor: FC<Props> = ({
  dashboardId,
  inEditMode,
  leftPaneIsOpen,
  pageWidth,
  left,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const dragProps = useRef<DragProps | null>(null);

  const resizableDatasetEditor = useRef<HTMLDivElement>(null);

  const { datasetEditorMode, editorHeight, shouldUseFido } = useSelector(
    (state: ReduxState) => ({
      dashboardVars: state.dashboardData.variables ?? {},
      datasetEditorMode: state.dashboardInteractions.datasetEditorMode,
      editorHeight: state.dashboardInteractions.datasetEditorHeight,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const isOpen = datasetEditorMode !== DATASET_EDITOR_MODE.COLLAPSED;
  const isDefaultMode = datasetEditorMode === DATASET_EDITOR_MODE.DEFAULT;
  const isExpandedMode = datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED;

  const onMouseMove = useCallback((e: MouseEvent) => {
    if (!dragProps.current) return;
    const newHeight = getCurrentHeight(dragProps.current, e.clientY);

    if (!resizableDatasetEditor.current) return;
    resizableDatasetEditor.current.style.height = `${newHeight}px`;
  }, []);

  const onMouseUp = useCallback(
    (e: MouseEvent) => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      if (dragProps.current) {
        dispatch(setDatasetEditorHeight(getCurrentHeight(dragProps.current, e.clientY)));
        dragProps.current = null;
      }
    },
    [dispatch, onMouseMove],
  );

  const initializeDrag = useCallback(
    (e: ReactMouseEvent) => {
      dragProps.current = { dragStartY: e.clientY, startHeight: editorHeight };

      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    },
    [editorHeight, onMouseMove, onMouseUp],
  );

  const renderEditor = () => {
    const sharedProps = { pageWidth };

    return shouldUseFido ? (
      <FidoDashboardDatasetEditorDataFetcher {...sharedProps} />
    ) : (
      <DashboardDatasetEditorDataFetcher {...sharedProps} dashboardId={dashboardId} />
    );
  };

  return (
    <>
      <div
        className={isExpandedMode ? classes.pageOverlay : undefined}
        onClick={() => dispatch(setDatasetEditorMode(DATASET_EDITOR_MODE.DEFAULT))}
      />
      <div
        className={cx(classes.datasetEditorContainer, {
          [classes.datasetEditorHidden]: !inEditMode,
          [classes.datasetEditorClosed]: inEditMode && !isOpen,
          [classes.datasetEditorExpanded]:
            inEditMode && datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED,
          [classes.datasetEditorExpandedWithLeftPanel]:
            inEditMode && datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED && leftPaneIsOpen,
        })}
        ref={resizableDatasetEditor}
        style={{ left: left ?? 0, height: editorHeight }}>
        {isDefaultMode ? (
          <div className={classes.datasetEditorDivider} onMouseDown={initializeDrag} />
        ) : null}
        {renderEditor()}
      </div>
    </>
  );
};

const getCurrentHeight = (dragProps: DragProps, clientY: number): number => {
  return Math.min(
    Math.max(dragProps.startHeight + (dragProps.dragStartY - clientY), 200),
    window.innerHeight - SIDE_PANE_HEADER_HEIGHT,
  );
};

import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { ReduxState } from 'reducers/rootReducer';

import {
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeGeospatialChartInstructions,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ChoroplethMapFormat, MapTooltipFormat, LocationMarkerMapFormat } from 'types/maps';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

const getNewChoroplethMapInstructions = (
  mapInstructions: V2TwoDimensionChartInstructions,
  updates: Partial<ChoroplethMapFormat>,
) => {
  return produce(mapInstructions, (draft) => {
    if (!draft.chartSpecificFormat) draft.chartSpecificFormat = {};
    if (!draft.chartSpecificFormat.choroplethMap) draft.chartSpecificFormat.choroplethMap = {};
    draft.chartSpecificFormat.choroplethMap = {
      ...draft.chartSpecificFormat.choroplethMap,
      ...updates,
    };
  });
};

const getNewLocationMarkerMapInstructions = (
  mapInstructions: VisualizeGeospatialChartInstructions,
  updates: Partial<LocationMarkerMapFormat>,
) => {
  return produce(mapInstructions, (draft) => {
    if (!draft.mapFormat) draft.mapFormat = {};
    draft.mapFormat = { ...draft.mapFormat, ...updates };
  });
};

const getNewLocationMarkerMapTooltipFormatting = (
  mapInstructions: VisualizeGeospatialChartInstructions,
  newFormatting: Partial<MapTooltipFormat>,
  colName: string | undefined,
) => {
  if (colName === undefined) return mapInstructions;
  return produce(mapInstructions, (draft) => {
    if (!draft.mapFormat) draft.mapFormat = {};
    if (!draft.mapFormat.tooltipFormat) draft.mapFormat.tooltipFormat = {};
    draft.mapFormat.tooltipFormat[colName] = {
      ...draft.mapFormat.tooltipFormat[colName],
      ...newFormatting,
    };
  });
};

export const updateMapFormat =
  (
    dataPanelId: string,
    newMapFormat: Partial<LocationMarkerMapFormat | ChoroplethMapFormat>,
  ): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const dataPanelVizOp = state.dashboardEditConfig?.config?.data_panels[dataPanelId].visualize_op;
    const requestInfo = state.dashboardLayout.requestInfo;

    if (requestInfo.type === 'embedded' || dataPanelVizOp?.operation_type === undefined) return;

    const operationType = dataPanelVizOp.operation_type;

    const newInstructions =
      operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP
        ? getNewLocationMarkerMapInstructions(
            dataPanelVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART || {},
            newMapFormat as Partial<LocationMarkerMapFormat>,
          )
        : getNewChoroplethMapInstructions(
            dataPanelVizOp.instructions.V2_TWO_DIMENSION_CHART || {},
            newMapFormat as Partial<ChoroplethMapFormat>,
          );

    dispatch(updateVisualizeOperation(newInstructions, operationType));
  };

export const updateMapTooltipFormatting =
  (dataPanelId: string, newFormatting: MapTooltipFormat, colName?: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const dataPanelVizOp = state.dashboardEditConfig?.config?.data_panels[dataPanelId].visualize_op;
    const requestInfo = state.dashboardLayout.requestInfo;

    if (requestInfo.type === 'embedded' || dataPanelVizOp?.operation_type === undefined) return;

    const operationType = dataPanelVizOp.operation_type;

    const newInstructions =
      operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP
        ? getNewLocationMarkerMapTooltipFormatting(
            dataPanelVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART || {},
            newFormatting,
            colName,
          )
        : getNewChoroplethMapInstructions(
            dataPanelVizOp.instructions.V2_TWO_DIMENSION_CHART || {},
            { tooltipFormat: newFormatting },
          );

    dispatch(updateVisualizeOperation(newInstructions, operationType));
  };

import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'utils/standard';

import { APP_PORTAL_ID, sprinkles, Modal, Select, Input } from './ds';

import { ParentSchema } from 'actions/dataSourceActions';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';

type Props = {
  modalOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string, parentSchemaId: number) => void;
  defaultParentSchema?: ParentSchema;
  errorState?: (textFieldVal: string) => { isErrorState: boolean; errorMsg?: string };
  datasetName?: string;
  title: string;
  buttonTitle: string;
};

export const DatasetModal: FC<Props> = ({
  errorState,
  defaultParentSchema,
  datasetName,
  onSubmit,
  onClose,
  modalOpen,
  title,
  buttonTitle,
}) => {
  const parentSchemas = useSelector(getParentSchemasList);

  const [textFieldVal, setTextFieldVal] = useState(datasetName || '');
  const [parentSchemaVal, setParentSchemaVal] = useState(
    // autoPopulate if there already is a schema or if there's only one schema to select
    defaultParentSchema
      ? defaultParentSchema
      : parentSchemas.length === 1
      ? parentSchemas[0]
      : undefined,
  );
  const { isErrorState, errorMsg } = errorState?.(textFieldVal) ?? {};

  const submitDisabled = isErrorState || !textFieldVal?.trim() || parentSchemaVal === undefined;

  const options = useMemo(
    () =>
      sortBy(
        parentSchemas.map((parentSchema) => ({
          value: String(parentSchema.id),
          label: parentSchema.name,
        })),
        (opt) => opt.label.toLowerCase(),
      ),
    [parentSchemas],
  );

  const onCreate = () => {
    if (submitDisabled) return;

    onSubmit(textFieldVal || '', parentSchemaVal?.id || 0);
    setTextFieldVal('');
    setParentSchemaVal(defaultParentSchema);
    onClose();
  };

  return (
    <Modal
      isOpen={modalOpen}
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{
        text: buttonTitle,
        onClick: onCreate,
        disabled: submitDisabled,
      }}
      size="small"
      title={title}>
      <div className={sprinkles({ paddingX: 'sp2' })}>
        <Input
          className={sprinkles({ marginBottom: 'sp1' })}
          defaultValue={textFieldVal}
          errorText={errorMsg}
          onSubmit={setTextFieldVal}
          placeholder="Dataset Name"
        />
        <Select
          className={sprinkles({ width: 'fill' })}
          onChange={(value) => {
            const schemaId = parseInt(value);
            const parentSchema = parentSchemas.find((schema) => schema.id === schemaId);
            setParentSchemaVal(parentSchema);
          }}
          placeholder="Select Schema"
          selectedValue={parentSchemaVal?.id.toString()}
          values={options}
        />
      </div>
    </Modal>
  );
};

import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedButton } from 'components/embed';
import { sprinkles } from 'components/ds';

import { ApplyFilterElemConfig } from 'types/dashboardTypes';
import { applyFiltersThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DashboardStates } from 'reducers/rootReducer';

type Props = {
  config: ApplyFilterElemConfig;
};

export const DashboardApplyFilterElement: FC<Props> = ({ config }) => {
  const dispatch = useDispatch();

  const blockedVariables = useSelector(
    (state: DashboardStates) => state.dashboardData.blockedVariables,
  );

  const blockedElements = useMemo(
    () =>
      Object.keys(blockedVariables).filter((blockedElemId) => config.elementIds?.[blockedElemId]),
    [blockedVariables, config.elementIds],
  );

  return (
    <div className={sprinkles({ paddingTop: 'sp2.5' })}>
      <EmbedButton
        fillWidth
        icon={config.hideRefreshIcon ? undefined : 'filter'}
        onClick={() => {
          if (blockedElements.length === 0) return;
          dispatch(applyFiltersThunk(blockedElements));
        }}
        variant="primary">
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <div className={sprinkles({ truncateText: 'ellipsis', flex: 1 })}>
            {config.buttonText || 'Apply Filters'}
          </div>
          {!config.hideNumFiltersIcon && blockedElements.length > 0 ? (
            <div className={blockedTagClass} style={{ height: 20, width: 20 }}>
              {blockedElements.length}
            </div>
          ) : null}
        </div>
      </EmbedButton>
    </div>
  );
};

const blockedTagClass = sprinkles({
  flexItems: 'center',
  backgroundColor: 'gray10',
  color: 'gray3',
  borderRadius: 'round',
});

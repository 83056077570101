import { FC } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import TableSetupImg from 'constants/images/report-builder/table-setup.gif';
import TableSetupAggsImg from 'constants/images/report-builder/table-setup-aggs.gif';
import TableSetupTotalsImg from 'constants/images/report-builder/table-setup-totals.gif';
import { ImageAndText } from '../Blocks/ImageAndText';

export const TableSetupDoc: FC = () => (
  <>
    <ImageAndText alt="Table Setup" img={TableSetupImg}>
      <EmbedText body="b2">
        All columns will be shown in tables by default. You can adjust the order of the columns by
        directly rearranging the columns in the Report Data section. You can also use the eye icons
        in this section to show and hide columns.
      </EmbedText>
    </ImageAndText>
    <ImageAndText alt="Table Aggregations" img={TableSetupAggsImg}>
      <EmbedText body="b2">
        To create aggregations, drag specific columns in the Values section, and select the
        aggregation type in the dropdown. You can also use the plus icon to the right of the section
        header to add columns to each section. Note that you may find custom aggregations via the
        Values “add” button. <br />
        <br />
        You can also group the data using the Group By and Breakdown sections. Columns placed in the
        group by section will determine the different rows in the report, and columns placed in the
        breakdown section will determine the different columns in the report.
      </EmbedText>
    </ImageAndText>
    <ImageAndText alt="Table Totals" img={TableSetupTotalsImg}>
      <EmbedText body="b2">
        Totals and subtotals can be turned on and off on each row. You can change the aggregation
        method by clicking each of the columns, except in the case of the Values in the grouped by
        format. These aggregations can be changed in the data panel.
      </EmbedText>
    </ImageAndText>
  </>
);

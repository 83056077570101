import { isEmpty } from 'utils/standard';

import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

export function clearEmptyPanels(configuration: DashboardVersionConfig): void {
  const currentPanels = configuration.data_panels;
  // PD-995: For some reason we're getting empty data panel templates sometimes, mitigate this by
  // checking for emptyness until we can figure out why
  configuration.data_panels = Object.keys(currentPanels).reduce<Record<string, DataPanelTemplate>>(
    (acc, dpId) => {
      if (isEmpty(currentPanels[dpId])) return acc;
      acc[dpId] = currentPanels[dpId];

      return acc;
    },
    {},
  );
}

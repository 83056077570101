import { FC } from 'react';

import TableIcon from 'constants/images/report-builder/table-icon.svg';
import AiIcon from 'constants/images/report-builder/ai-icon.svg';
import ExportIcon from 'constants/images/report-builder/export-icon.svg';
import FiltersIcon from 'constants/images/report-builder/filters-icon.svg';
import ViewsIcon from 'constants/images/report-builder/views-icon.svg';
import VizIcon from 'constants/images/report-builder/viz-icon.svg';
import DataSelectionIcon from 'constants/images/report-builder/dataset-selection-icon.svg';
import { TableSetupDoc } from 'pages/ReportBuilder/Onboarding/Docs/TableSetup';
import { VisualizationsDoc } from 'pages/ReportBuilder/Onboarding/Docs/Visualizations';
import { FiltersDoc } from 'pages/ReportBuilder/Onboarding/Docs/Filters';
import { ReportAIDoc } from 'pages/ReportBuilder/Onboarding/Docs/ReportAI';
import { ViewsDoc } from 'pages/ReportBuilder/Onboarding/Docs/Views';
import { ExportingDoc } from 'pages/ReportBuilder/Onboarding/Docs/Exporting';
import { DataSelectionDoc } from 'pages/ReportBuilder/Onboarding/Docs/DataSelection';

export enum Doc {
  DataSelection = 'Data selection',
  TableSetup = 'Table setup',
  Exporting = 'Exporting reports',
  Visualizations = 'Visualizations',
  Views = 'Views',
  Filters = 'Filters',
  ReportAI = 'Report AI',
}

export enum DocSection {
  GettingStarted = 'Getting started',
  ReportFunctionality = 'Report functionality',
  Features = 'Features',
}

export const DOC_PAGES: Record<Doc, DocPage> = {
  [Doc.DataSelection]: {
    section: DocSection.GettingStarted,
    description: 'Select the data you want to use in your report',
    img: DataSelectionIcon,
    component: DataSelectionDoc,
  },
  [Doc.TableSetup]: {
    section: DocSection.GettingStarted,
    description: 'Select the data you want to use in your report',
    img: TableIcon,
    component: TableSetupDoc,
  },
  [Doc.Exporting]: {
    section: DocSection.ReportFunctionality,
    description: 'Select the data you want to use in your report',
    img: ExportIcon,
    component: ExportingDoc,
  },
  [Doc.Visualizations]: {
    section: DocSection.ReportFunctionality,
    description: 'Select the data you want to use in your report',
    img: VizIcon,
    component: VisualizationsDoc,
  },
  [Doc.Views]: {
    section: DocSection.ReportFunctionality,
    description: 'Select the data you want to use in your report',
    img: ViewsIcon,
    component: ViewsDoc,
  },
  [Doc.Filters]: {
    section: DocSection.ReportFunctionality,
    description: 'Select the data you want to use in your report',
    img: FiltersIcon,
    component: FiltersDoc,
  },
  [Doc.ReportAI]: {
    section: DocSection.Features,
    description: 'Select the data you want to use in your report',
    img: AiIcon,
    component: ReportAIDoc,
  },
};

export type DocPage = {
  section: string;
  description: string;
  img: string;
  component: FC;
};

import { FC } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';

import { ManageDataTablesPage } from './manageDataTablesPage';
import { ManageFidoDataTablesPage } from './manageFidoDataTablesPage';

export const ManageDataTablesPageWrapper: FC = () => {
  const {
    params: { dataSourceId },
  } = useRouteMatch<{ dataSourceId: string }>();

  const { shouldUseFido, dataSources } = useSelector(
    (state: ReduxState) => ({
      shouldUseFido: !!state.currentUser.team?.feature_flags.use_fido,
      dataSources: state.dataSource.dataSources,
    }),
    shallowEqual,
  );

  return shouldUseFido &&
    !!RD.getOrDefault(dataSources, []).find((d) => d.id.toString() === dataSourceId)?.fido_id ? (
    <ManageFidoDataTablesPage />
  ) : (
    <ManageDataTablesPage />
  );
};

import { FC } from 'react';

import { Button, Input, sprinkles } from 'components/ds';

import { SortableExpandableCard } from 'components/ChartConfigs/SortableExpandableCard';
import { SortableList, SortableListItem } from 'components/SortableList/SortableList';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

import { ManualSortCategory } from 'constants/types';

type Props = {
  orderConfig?: ManualSortCategory[];
  updateOrder: (newOrder: ManualSortCategory[]) => void;
  visibleCategories: Set<string>;
};

export const SortableCategoriesConfig: FC<Props> = ({
  orderConfig,
  updateOrder,
  visibleCategories,
}) => {
  const newConfig = [...(orderConfig || [])];
  const presetCategories = new Set(newConfig.map((order) => order.category));

  visibleCategories.forEach(
    (category) =>
      !presetCategories.has(category) && newConfig.push({ category, displayName: category }),
  );

  return (
    <div className={sprinkles({ width: 'fill' })}>
      <SortableList
        getIdFromElem={(item) => item.category}
        onListUpdated={(newList) => updateOrder(newList)}
        sortableItems={newConfig}>
        {newConfig.map((order, i) => (
          <SortableListItem key={order.category} sortId={order.category}>
            <SortableExpandableCard key={`manual-sort-${order.category}`} name={order.category}>
              <div className={sprinkles({ paddingY: 'sp1.5' })}>
                <Input
                  showInputButton
                  className={configInputClass}
                  defaultValue={order.category}
                  label="Category"
                  onSubmit={(newValue) => {
                    newConfig[i] = { ...order, category: newValue };
                    updateOrder(newConfig);
                  }}
                  placeholder="Category"
                />
                <Input
                  showInputButton
                  className={configInputClass}
                  defaultValue={order.displayName}
                  label="Display Name"
                  onSubmit={(newValue) => {
                    newConfig[i] = { ...order, displayName: newValue };
                    updateOrder(newConfig);
                  }}
                  placeholder="Display Name"
                />
                <div className={sprinkles({ paddingX: 'sp1.5' })}>
                  <Button
                    fillWidth
                    icon="trash"
                    onClick={() => updateOrder(newConfig.filter((_, index) => index !== i))}
                    variant="destructive">
                    Delete
                  </Button>
                </div>
              </div>
            </SortableExpandableCard>
          </SortableListItem>
        ))}
      </SortableList>
    </div>
  );
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import * as Redux from 'redux';
import { createAction } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { FetchProfileData, logInUserSuccess } from 'actions/userActions';
import { Team } from 'actions/teamActions';

import {
  defineAuthAction,
  defineAPIPostAction,
  defineAPIAction,
  createRequestAction,
  createErrorAction,
  createSuccessAction,
} from 'actions/actionUtils';
import { pingCustomerOnlineMessage, pingUserWithoutTeamMessage } from 'utils/landingPageUtils';
import { resetUser } from 'analytics/exploAnalytics';
import { SIGNUP_INFO_NEXT } from 'constants/onboardingConstants';
import { PingTypes } from 'constants/types';
import { sendPing } from 'actions/pingActions';
import { setUser, clearUser } from 'analytics/datadog';
import { showSuccessToast } from 'shared/sharedToasts';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

export const setSignupInfoCollectionStep = createAction<SIGNUP_INFO_NEXT>(
  ACTION.SET_SIGNUP_INFO_COLLECTION_STEP,
);

export const verifyEmail = createAction(ACTION.VERIFY_EMAIL);

const logInUserRequest = createRequestAction(ACTION.LOGIN_USER);
const logInUserError = createErrorAction<string>(ACTION.LOGIN_USER);

export const clearState = createAction(ACTION.CLEAR_STATE);

const VERIFICATION_EMAIL_SENT = 'Verification e-mail sent.';

type LoginErrorResponse = {
  non_field_errors: string[];
  email?: string[];
  detail?: string;
};

export function logInUser(
  email: string,
  password: string,
  successCallback: (user: FetchProfileData) => void,
  errorCallback: (response: LoginErrorResponse) => void,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(logInUserRequest({}));
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.key) {
          dispatch(logInUserError(''));
          errorCallback(data);
        } else {
          Cookies.set('spheres_auth_token', data.key, { sameSite: 'Strict' });
          dispatch(logInUserSuccess(data.user));

          setUser({
            email: data.user.email,
            teamId: data.user.team?.id,
            teamName: data.user.team?.team_name,
          });

          dispatch(
            sendPing({
              postData: {
                message: pingCustomerOnlineMessage(data.user),
                message_type:
                  data.user.team?.payment_plan === PLAN_TYPES.LAUNCH
                    ? PingTypes.PING_ONLINE_LAUNCH
                    : PingTypes.PING_ONLINE,
              },
            }),
          );
          if (!data.user.team) {
            dispatch(
              sendPing({
                postData: {
                  message: pingUserWithoutTeamMessage(data.user),
                  message_type: PingTypes.PING_USER_WITHOUT_TEAM,
                },
              }),
            );
          }
          successCallback(data.user);
        }
      });
  };
}

export function logOutUser() {
  const actionFn: () => void = () => {
    const token = Cookies.get('spheres_auth_token');

    if (!token) return async () => null;

    // if they have no token, then this is a no-op
    return (dispatch: Redux.Dispatch) =>
      fetch(process.env.REACT_APP_API_URL + 'rest-auth/logout/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // if we don't send the token on logout, then django treats
          // the request as anonymous and doesn't invalidate the token
          Authorization: 'Token ' + token,
        },
        // there's a state where the logout request fails for some transient reason,
        // which technically puts us in a bad state where this auth_token is still
        // valid. Not much to do there, though, so I'm just logging that here
      }).finally(() => {
        resetUser();
        clearUser();
        Cookies.remove('spheres_auth_token');
        dispatch(clearState());
      });
  };

  return actionFn();
}

const registerUserRequest = createRequestAction(ACTION.REGISTER_USER);
const registerUserError = createErrorAction<string>(ACTION.REGISTER_USER);
const registerUserSuccess = createSuccessAction<FetchProfileData>(ACTION.REGISTER_USER);

export type RegisterUserSuccessCallback = (
  first_name: string,
  last_name: string,
  email: string,
  password1: string,
  password2: string,
  errorCallback: (response: Record<string, string>) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (dispatch: Redux.Dispatch<any>) => Promise<void>;

export function registerUser(
  first_name: string,
  last_name: string,
  email: string,
  password1: string,
  password2: string,
  errorCallback: (response: Record<string, string>) => void,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(registerUserRequest({}));
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/registration/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        password1: password1,
        password2: password2,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        // not really much we can do here without in-housing our account management backend
        if (data.detail !== VERIFICATION_EMAIL_SENT) {
          dispatch(registerUserError(''));
          errorCallback(data);
        } else {
          showSuccessToast(
            'A verification email has been sent to your email address, if this account does not already exist.',
          );
          dispatch(registerUserSuccess(data.user));
        }
      });
  };
}

const createTeamRequest = createRequestAction(ACTION.CREATE_TEAM);
const createTeamError = createErrorAction(ACTION.CREATE_TEAM);
const createTeamSuccess = createSuccessAction<{ team: Team }>(ACTION.CREATE_TEAM);

export const createTeam = (
  teamName: string,
  onSuccess: () => void,
  onError: (errorMsg: string) => void,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(createTeamRequest({}));
    return fetch(process.env.REACT_APP_API_URL + 'teams/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
      body: JSON.stringify({
        team_name: teamName,
        join_team: true,
      }),
    })
      .then((resp) => {
        if (resp.status && resp.status >= 400) {
          throw resp.statusText;
        }
        return resp.json();
      })
      .then((data) => {
        if (!data.success) {
          dispatch(createTeamError({}));
          onError(data.msg);
        } else {
          dispatch(createTeamSuccess(data));

          // Ping #bd-ping-trials-started that a new team was created.
          dispatch(
            sendPing({
              postData: {
                message: `${data.user.first_name} ${data.user.last_name} (${data.user.email}) just created team: ${data.team.team_name}`,
                message_type: PingTypes.PING_TEAM_CREATION,
              },
            }),
          );

          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(createTeamError({}));
        onError(error);
      });
  };
};

const addUserToTeamRequest = createRequestAction(ACTION.ADD_USER_TO_TEAM);
const addUserToTeamError = createErrorAction(ACTION.ADD_USER_TO_TEAM);
const addUserToTeamSuccess = createSuccessAction<{ team: Team }>(ACTION.ADD_USER_TO_TEAM);

export const addUserToTeam = (
  userId: number | string,
  inviteCode: string,
  onSuccess: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (errorMsg: any) => void,
  inviteHash?: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(addUserToTeamRequest({}));
    return (
      axios
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .request<any>({
          url: process.env.REACT_APP_API_URL + 'teams/add_team_member/',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
          },
          data: {
            invite_code: inviteCode,
            user_id: userId,
            invite_hash: inviteHash,
          },
        })
        .then((response: AxiosResponse) => {
          const { data } = response;
          if (!data.success) {
            dispatch(addUserToTeamError({}));
            onError(data.msg);
          } else {
            dispatch(addUserToTeamSuccess(data));
            onSuccess();
          }
        })
        .catch((error: AxiosError) => {
          dispatch(addUserToTeamError({}));
          onError(error.response);
        })
    );
  };
};

export const { actionFn: passwordResetRequest } = defineAuthAction<{ email: string }, {}>(
  ACTION.PASSWORD_RESET_REQUEST,
  'password_reset',
  '',
  'POST',
);

export const { actionFn: passwordResetConfirm } = defineAuthAction<
  { token: string; password: string },
  {}
>(ACTION.PASSWORD_RESET_CONFIRM, 'password_reset', 'confirm', 'POST');

type FetchSignupEmailBody = {
  invite_hash: string;
};

type FetchSignupEmailData = {
  email: string;
  invite_code: string;
  team_name: string;
  invite_accepted: boolean;
};

export const { actionFn: fetchSignupEmail } = defineAPIPostAction<
  FetchSignupEmailBody,
  FetchSignupEmailData
>(ACTION.FETCH_SIGNUP_EMAIL, 'settings', 'get_signup_info', 'POST');

type GetUserTeamInviteData = {
  team_name: string;
  invite_hash: string;
  num_users: number;
  invite_accepted: boolean;
};

export const { actionFn: getUserTeamInvite } = defineAPIAction<GetUserTeamInviteData>(
  ACTION.GET_USER_TEAM_INVITE,
  'settings',
  'get_user_team_invite',
  'GET',
);

export const { actionFn: customerPortalSignIn } = defineAuthAction<{ email: string }, {}>(
  ACTION.END_USER_PORTAL_SIGN_IN,
  'auth',
  'end_user_portal_sign_in',
  'POST',
);

type EndUserPortalAuthenticationData = {
  dashboard_logo_url?: string;
  dashboard_name: string;
  share_id: string;
};

export const { actionFn: customerPortalAuthentication } = defineAuthAction<
  { token: string; isEmailReroute: boolean },
  EndUserPortalAuthenticationData
>(ACTION.END_USER_PORTAL_AUTHENTICATION, 'auth', 'end_user_portal_authenticate', 'POST');

type EndUserPortalMetadata = {
  dashboard_logo_url?: string;
  favicon_url?: string;
  portal_site_title?: string;
  portal_login_text?: string;
};

export const { actionFn: fetchEndUserPortalMetadata } = defineAuthAction<{}, EndUserPortalMetadata>(
  ACTION.FETCH_END_USER_PORTAL_METADATA,
  'auth',
  'fetch_end_user_portal_metadata',
  'GET',
);

export const { actionFn: resendEmailVerification } = defineAuthAction<{ email: string }, {}>(
  ACTION.RESEND_EMAIL_VERIFICATION,
  'auth',
  'resend_email_confirmation',
  'POST',
);

type GoogleOAuthVerificationBody = {
  authorization_code: string | undefined;
  invite_hash?: string;
};

type ExternalAuthData = {
  token: string;
  user: FetchProfileData;
};

export const { actionFn: googleOAuthVerification, successAction: loginGoogleSuccess } =
  defineAPIPostAction<
    GoogleOAuthVerificationBody,
    ExternalAuthData & { is_first_time_sso_login_for_existing_account: boolean }
  >(ACTION.GOOGLE_OAUTH_VERIFICATION, 'auth', 'google_oauth_verification', 'POST');

export const { actionFn: samlSignIn, successAction: samlSignInSuccess } = defineAPIPostAction<
  { token: string },
  ExternalAuthData
>(ACTION.SAML_SIGN_IN, 'saml', 'saml_authenticate', 'POST');

export const { actionFn: samlGetRedirectUrl } = defineAPIPostAction<
  { email: string },
  { redirect_url: string }
>(ACTION.SAML_INITIATE_AUTH, 'saml', 'initiate_sp_login', 'POST');

import { FC } from 'react';

import * as styles from '../styles.css';
import { Switch } from 'components/ds';

import { TimePeriodDropdownElemConfig } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: TimePeriodDropdownElemConfig;

  updateDropdownConfig: (config: TimePeriodDropdownElemConfig) => void;
};

export const TimePeriodEditInteractionsConfig: FC<Props> = ({ config, updateDropdownConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateDropdownConfig);

  return (
    <>
      <Switch
        className={styles.configInput}
        label="Enable filtering"
        onChange={() => updateConfig((draft) => (draft.enableSearch = !draft.enableSearch))}
        switchOn={config.enableSearch}
      />
      <Switch
        className={styles.configInput}
        label="Enable clearing selection"
        onChange={() => updateConfig((draft) => (draft.enableCancel = !draft.enableCancel))}
        switchOn={config.enableCancel}
      />
    </>
  );
};

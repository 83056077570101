import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchCustomerReportsSuccess } from 'actions/customerReportActions';
import { embedFetchDashboardActions } from 'actions/shareActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { AnalyticsMetadata, Metadata } from 'utils/analyticsUtils';

export type AnalyticsEmbedType = EmbeddedDashboardType | 'chart';

interface AnalyticsReducerState {
  analyticsMetadata: AnalyticsMetadata | null;
  analyticsToken: string | null;
  visitorId: string | null;
  embedType: AnalyticsEmbedType | null;
  analyticsProperties?: Metadata;
  environment?: string;
}

const initialState: AnalyticsReducerState = {
  analyticsMetadata: null,
  analyticsToken: null,
  visitorId: null,
  embedType: null,
};

type SetAnalyticsVars = {
  analyticsProperties: Metadata | undefined;
  environment: string | undefined;
  embedType: AnalyticsEmbedType;
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearAnalyticsSlice: () => {
      return initialState;
    },
    setVisitorId: (state, { payload }: PayloadAction<string>) => {
      state.visitorId = payload;
    },
    setAnalyticsVars: (state, { payload }: PayloadAction<SetAnalyticsVars>) => {
      state.analyticsProperties = payload.analyticsProperties;
      state.embedType = payload.embedType;
      state.environment = payload.environment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerReportsSuccess, (state, { payload }) => {
        const { customer, report_builder, team } = payload;
        state.analyticsMetadata = {
          team_id: team.id,
          team_name: team.name,
          customer_id: customer.id,
          customer_name: customer.name,
          customer_is_demo: customer.is_demo_group,
          customer_provided_id: customer.provided_id,
          hub_id: report_builder.id,
          hub_name: report_builder.name,
        };
        state.analyticsToken = team.explo_analytics_token;
      })
      .addCase(embedFetchDashboardActions.successAction, (state, { payload }) => {
        const { team, customer, dashboard_template } = payload;
        state.analyticsMetadata = {
          team_id: team.id,
          team_name: team.team_name,
          customer_id: customer.id,
          customer_name: customer.name,
          customer_provided_id: customer.provided_id,
          customer_is_demo: customer.is_demo_group,
          dashboard_template_id: dashboard_template.id,
          dashboard_template_name: dashboard_template.name,
        };
        state.analyticsToken = team.explo_analytics_token;
      });
  },
});

export const { clearAnalyticsSlice, setVisitorId, setAnalyticsVars } = analyticsSlice.actions;

export const analyticsReducer = analyticsSlice.reducer;

export const DASHBOARD_CACHE_OPTIONS = [
  {
    value: '0,15,30,45 * * * *',
    label: 'Every 15 minutes',
  },
  {
    value: '0,30 * * * *',
    label: 'Every 30 minutes',
  },
  {
    value: '0 * * * *',
    label: 'Every 1 hour',
  },
  {
    value: '0 0,2,4,6,8,10,12,14,16,18,20,22 * * *',
    label: 'Every 2 hours',
  },
  {
    value: '0 0,4,8,12,16,20 * * *',
    label: 'Every 4 hours',
  },
  {
    value: '0 0,6,12,18 * * *',
    label: 'Every 6 hours',
  },
  {
    value: '0 0,12 * * *',
    label: 'Every 12 hours',
  },
  {
    value: '0 0 * * *',
    label: 'Every 24 hours',
  },
];

export const DEFAULT_CRON = '0 * * * *';

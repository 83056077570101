import { FC, useCallback, useMemo } from 'react';
import { sortBy } from 'utils/standard';
import { useDispatch } from 'react-redux';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import GridLayout, {
  Layout,
  ReactGridLayoutProps,
  Responsive as ResponsiveGridLayout,
} from '@explo-tech/react-grid-layout';
import { ResizeHandle } from 'components/ResizeHandle';
import { DataPanelWrapper } from 'components/DashboardLayout/DataPanelWrapper';
import { vars } from 'components/ds';
import * as wrapperStyles from 'components/DashboardLayout/wrapperStyles.css';

import { EditableSectionChart, EditableSectionConfig } from 'types/dashboardVersionConfig';
import {
  DASHBOARD_ROW_HEIGHT,
  DRAGGABLE_HANDLE_CLASS,
  PDF_EDITOR_MARGIN_SIZE,
} from 'constants/dashboardConstants';
import { DashboardElement, DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { ResourceDataset } from 'types/exploResource';
import { didLayoutChange, getEmptySectionHeight } from 'utils/editableSectionUtils';
import { doesDpEndOnRightHalfOfPage } from 'utils/dashboardUtils';
import {
  removeChartFromEditableSectionThunk,
  setEditableSectionLayoutThunk,
} from 'reducers/thunks/editableSectionThunks';
import { selectItemOnDashboardThunk } from 'reducers/thunks/dashboardSelectionThunks';
import { getDatasetNamesToId } from 'utils/datasetUtils';

type Props = {
  cols: number;
  config: EditableSectionConfig;
  datasets: Record<string, ResourceDataset>;
  elements: DashboardElement[];
  isEditing: boolean;
  isEditingDashboard: boolean;
  isViewOnly: boolean;
  layout: Layout[];
  margin: number;
  width: number | undefined;
  viewMode: VIEW_MODE;
  variables: DashboardVariableMap | null;
};

export const EditableSectionLayout: FC<Props> = ({
  cols,
  config,
  elements,
  datasets,
  layout,
  isEditing,
  isViewOnly,
  margin,
  width,
  viewMode,
  isEditingDashboard,
  variables,
}) => {
  const dispatch = useDispatch();

  const sortedLayout = useMemo(() => sortBy(layout, (elem) => -(elem.y * elem.x)), [layout]);
  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  const onLayoutChange = useCallback(
    (newLayout: Layout[]) => {
      if (!didLayoutChange(layout, newLayout)) return;
      dispatch(setEditableSectionLayoutThunk(newLayout));
    },
    [dispatch, layout],
  );

  const renderCharts = () => {
    return sortedLayout.map(({ i }) => {
      const chart: EditableSectionChart | undefined = config.charts[i];

      const dpEndsOnRightSide = doesDpEndOnRightHalfOfPage(
        layout,
        i,
        cols,
        chart?.data_panel.visualize_op.operation_type,
      );

      return (
        <DataPanelWrapper
          dashboardElements={elements}
          dataPanel={chart?.data_panel}
          dataPanelId={i}
          datasetNamesToId={datasetNamesToId}
          datasets={datasets}
          dpEndsOnRightSide={dpEndsOnRightSide}
          hideEditingElements={isEditingDashboard && !isEditing}
          isDragging={false}
          isEditing={isEditingDashboard || isEditing}
          isResizing={false}
          isViewOnly={isViewOnly}
          key={i}
          onDelete={() => dispatch(removeChartFromEditableSectionThunk(layout, i))}
          onSelect={() =>
            dispatch(selectItemOnDashboardThunk(i, { setEditableSection: isEditingDashboard }))
          }
          pageType={PAGE_TYPE.EXPLO_APP}
          variables={variables ?? {}}
        />
      );
    });
  };

  const marginBottom =
    viewMode === VIEW_MODE.PDF
      ? -(PDF_EDITOR_MARGIN_SIZE / 2)
      : isEditing
      ? getEmptySectionHeight(margin)
      : 0;

  let style = { marginBottom };
  if (isEditing) style = { ...style, ...wrapperVars };

  const sharedProps: Partial<ReactGridLayoutProps> = {
    margin: [margin, margin],
    rowHeight: DASHBOARD_ROW_HEIGHT,
    style,
    width: width ?? 0,
    isDraggable: isEditing,
    isResizable: isEditing,
  };

  if (isViewOnly) {
    const [half, xs] = viewMode == VIEW_MODE.EMAIL ? [6, 6] : [Math.ceil(cols / 2), 1];
    return (
      <ResponsiveGridLayout
        {...sharedProps}
        cols={{ lg: cols, md: cols, sm: half, xs, xxs: xs }}
        layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}>
        {renderCharts()}
      </ResponsiveGridLayout>
    );
  }

  return (
    <GridLayout
      {...sharedProps}
      cols={cols}
      draggableHandle={`.${DRAGGABLE_HANDLE_CLASS}`}
      layout={layout}
      onDragStart={(_, item) =>
        dispatch(selectItemOnDashboardThunk(item.i, { setEditableSection: isEditingDashboard }))
      }
      onLayoutChange={onLayoutChange}
      resizeHandle={<ResizeHandle />}>
      {renderCharts()}
    </GridLayout>
  );
};

const wrapperVars = assignInlineVars({
  [wrapperStyles.activeColor]: vars.colors.active,
  [wrapperStyles.activeHoverColor]: vars.colors.activeHovered,
  [wrapperStyles.activeContrastColor]: vars.colors.activeContrast,
  [wrapperStyles.activeSubduedColor]: vars.colors.activeSubdued,
  [wrapperStyles.activeSubduedContrastColor]: vars.colors.activeSubduedContrast,
});

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { setTestimonialIndex } from 'actions/onboardingActions';
import { isMobile } from 'react-device-detect';
import { ReduxState } from 'reducers/rootReducer';

import TestimonialLayout from './TestimonialLayout';
import { sprinkles } from 'components/ds';

import { TESTIMONIALS, TESTIMONIAL_COMPANIES } from 'constants/onboardingConstants';
import ExploLogo from 'images/branding/text_logo.svg';
import ReferralLogo from 'images/branding/referral_logo.svg';

const LOGO_HEIGHT = 24;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.grey200,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 600,
  },
  rootMobile: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  leftSide: { width: '50%' },
  rightSide: {
    width: '50%',
    height: '100%',
  },
  fullWidth: { width: '100%' },
  contentContainer: {
    borderRadius: 8,
    backgroundColor: theme.palette.ds.white,
    width: 'calc(100% - 80px)',
    height: 'calc(100% - 80px)',
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8),
  },
  contentContainerMobile: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 24px',
    height: '100%',
  },
  exploLogoMobile: {
    width: '100%',
    height: LOGO_HEIGHT,
  },
  exploLogoImg: { height: LOGO_HEIGHT },
  rightPanelContainerContainer: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    overflow: 'hidden',
  },
  rightPanelContainerContainerMobile: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    marginBottom: theme.spacing(6),
    width: '100%',
  },
  rightPanelContentTitle: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.ds.grey900,
    marginBottom: theme.spacing(2),
  },
  rightPanelContentSubtitle: {
    fontSize: 12,
    color: theme.palette.ds.grey800,
  },
  helpLinksContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  link: {
    fontSize: 12,
    color: theme.palette.ds.grey900,
    textDecoration: 'underline',
  },
  linkContainer: {
    marginRight: theme.spacing(2),
  },
}));

type HelpLinkConfig = {
  name: string;
  to?: string;
  url?: string;
  onClick?: () => void;
};

type Props = {
  helpLinks?: HelpLinkConfig[];
  rightContentTitle: JSX.Element | string;
  rightContentSubTitle?: string;
  rightPanelContent: JSX.Element;
};

const testimonials = Object.values(TESTIMONIAL_COMPANIES);

export const OnboardingFlowPage: FC<Props> = ({
  helpLinks,
  rightContentTitle,
  rightPanelContent,
  rightContentSubTitle,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const testimonialIndex = useSelector((state: ReduxState) => state.onboarding.testimonialIndex);

  useEffect(() => {
    if (testimonialIndex === null) {
      const randomNum = Math.random();

      const newIndex = randomNum > 0.5 ? -1 : Math.floor(randomNum * 2 * testimonials.length);
      dispatch(setTestimonialIndex(newIndex));
    }
  }, [dispatch, testimonialIndex]);

  const renderLeftSide = () => {
    if (testimonialIndex === null) return;
    if (testimonialIndex >= 0) {
      return <TestimonialLayout testimonial={TESTIMONIALS[testimonials[testimonialIndex]]} />;
    }

    return (
      <div className={sprinkles({ flexItems: 'centerColumn' })}>
        <img alt="explo logo" src={ReferralLogo} />
        <div className={sprinkles({ heading: 'h2', marginY: 'sp1' })}>
          Earn rewards by sharing Explo with your friends
        </div>
        <div className={sprinkles({ body: 'b2' })}>
          Earn $250 for yourself and your company when a company you refer joins Explo
        </div>
        <a
          className={sprinkles({ marginTop: 'sp1', body: 'b2' })}
          href="https://www.explo.co/referral-program"
          rel="noopener noreferrer"
          target="_blank">
          Refer now
        </a>
      </div>
    );
  };

  return (
    <div className={isMobile ? classes.rootMobile : classes.root}>
      {!isMobile ? <div className={classes.leftSide}>{renderLeftSide()}</div> : null}
      <div className={isMobile ? classes.fullWidth : classes.rightSide}>
        <div className={isMobile ? classes.contentContainerMobile : classes.contentContainer}>
          <div
            className={isMobile ? classes.exploLogoMobile : undefined}
            style={{ marginBottom: 16 }}>
            <img alt="explo logo" className={classes.exploLogoImg} src={ExploLogo} />
          </div>
          <div
            className={
              isMobile
                ? classes.rightPanelContainerContainerMobile
                : classes.rightPanelContainerContainer
            }>
            <div className={classes.titleContainer}>
              <div className={classes.rightPanelContentTitle}>{rightContentTitle}</div>
              {rightContentSubTitle && (
                <div className={classes.rightPanelContentSubtitle}>{rightContentSubTitle}</div>
              )}
            </div>
            {rightPanelContent}
          </div>
          <div className={classes.helpLinksContainer}>
            {helpLinks?.map((linkConfig) => (
              <div className={classes.linkContainer} key={`link-bottom-config-${linkConfig.name}`}>
                {linkConfig.to ? (
                  <Link className={classes.link} to={linkConfig.to}>
                    {linkConfig.name}
                  </Link>
                ) : linkConfig.onClick ? (
                  <>
                    {/*  eslint-disable-next-line */}
                    <a className={classes.link} href="#" onClick={linkConfig.onClick}>
                      {linkConfig.name}
                    </a>
                  </>
                ) : (
                  <a
                    className={classes.link}
                    href={linkConfig.url}
                    rel="noopener noreferrer"
                    target="_blank">
                    {linkConfig.name}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState, useEffect, useCallback, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import parse from 'url-parse';
import { sha256 } from 'js-sha256';
import { GoogleLogin } from 'react-google-login';
import { useLocalStorage } from 'usehooks-ts';
import cx from 'classnames';

import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { PasswordInput } from 'components/PasswordInput';
import { InfoCard } from 'components/InfoCard';
import { Input, Button, sprinkles } from 'components/ds';
import * as styles from './SignInPage.css';

import { ReduxState } from 'reducers/rootReducer';
import { logInUser } from 'actions/authAction';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import { fetchSignupEmail, googleOAuthVerification } from 'actions/authAction';
import {
  googleOAuthVerificationOnSuccess,
  googleOAuthVerificationOnFailure,
  googleOAuthVerificationOnScriptFailure,
  pingCustomerOnlineMessage,
  pingUserWithoutTeamMessage,
} from 'utils/landingPageUtils';
import { ROUTES } from 'constants/routes';
import { PingTypes } from 'constants/types';
import { sendPing } from 'actions/pingActions';
import { enableSSO } from 'flags/flags';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

const LOGIN_ERROR_TO_INPUT_ERRORS: Record<string, string> = {
  'Must include "email" and "password".': 'Please enter an email and password to log in.',
  'Enter a valid email address.': 'Please enter a valid email to log in.',
  'Unable to log in with provided credentials.':
    'Unable to log in with the provided email and password.',
};

export const EMAIL_LOCAL_STORAGE_KEY = 'verify_email';

export const SignInPage: FC = () => {
  const [signupEmail, setSignupEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [disableSSOForIncognito, setDisableSSOForIncognito] = useState(false);

  // when the user signs in, if that email isn't verified we send them to the check your email page
  // and use local storage to store the email they used to sign in, which that page needs
  const setLocalStorageEmail = useLocalStorage(EMAIL_LOCAL_STORAGE_KEY, '')[1];

  const { loginLoading, ssoLoginLoading } = useSelector(
    (state: ReduxState) => ({
      loginLoading: createLoadingSelector([ACTION.LOGIN_USER], false)(state),
      ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const urlQueryParams = parse(window.location.href, true).query;

  const onSubmit = useCallback(
    (email: string, password: string) => {
      dispatch(
        logInUser(
          email,
          sha256(password),
          () => {
            setSignupEmail('');
            setPassword('');
            history.push('/home');
          },
          (response) => {
            const lockoutError = response.detail;

            if (lockoutError !== '' || Object.keys(response).length > 0) {
              let error;
              if (lockoutError) {
                error = lockoutError;
              } else if (response.email) {
                error = response.email[0];
              } else if (response.non_field_errors) {
                error = response.non_field_errors[0];
              }

              if (error) {
                if (error === 'E-mail is not verified.') {
                  setLocalStorageEmail(email);
                  history.push(ROUTES.CHECK_YOUR_EMAIL);
                }

                setErrorMsg(LOGIN_ERROR_TO_INPUT_ERRORS[error] || error);
              }
            }
          },
        ),
      );
    },
    [history, dispatch, setLocalStorageEmail],
  );

  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      dispatch(
        fetchSignupEmail(
          {
            postData: {
              invite_hash: urlQueryParams.invite_hash,
            },
          },
          (response) => {
            setSignupEmail(response.email);
            setErrorMsg(
              'This invitation has already been used to create an account. Please sign in.',
            );
          },
        ),
      );
    }
    pageView('Login');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmit(signupEmail, password);
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [urlQueryParams.invite_hash, history, password, signupEmail, onSubmit, dispatch]);

  const location = useLocation();
  const isSessionExpired = location.hash === '#expired';

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Sign Up', to: ROUTES.SIGNUP },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      rightContentSubTitle={
        isSessionExpired ? 'Your session has expired. Please sign in again.' : undefined
      }
      rightContentTitle="Sign in"
      rightPanelContent={
        <div className={sprinkles({ width: 'fill' })}>
          <Input
            fillWidth
            className={sprinkles({ marginBottom: 'sp2' })}
            data-testid="sign-in-email-input"
            label="Work email"
            onChange={setSignupEmail}
            value={signupEmail}
          />

          <PasswordInput
            data-testid="sign-in-password-input"
            label="Password"
            onChange={setPassword}
            value={password}
          />
          <div className={sprinkles({ marginY: 'sp1.5' })}>
            <Link
              className={sprinkles({
                body: 'b3',
                color: 'contentPrimary',
                textDecoration: 'underline',
              })}
              to={ROUTES.FORGOT_PASSWORD}>
              Forgot your password?
            </Link>
          </div>
          {errorMsg ? (
            <InfoCard
              error
              className={sprinkles({ height: 'fitContent', marginBottom: 'sp1.5' })}
              text={errorMsg}
            />
          ) : null}
          <Button
            fillWidth
            className={cx(sprinkles({ marginBottom: 'sp1.5' }), styles.signInButton)}
            loading={loginLoading || ssoLoginLoading}
            onClick={() => onSubmit(signupEmail, password)}>
            Sign In
          </Button>
          {enableSSO ? (
            <GoogleLogin
              accessType="offline"
              buttonText="Sign in with Google"
              className={styles.googleButton}
              clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
              disabled={loginLoading || ssoLoginLoading || disableSSOForIncognito}
              onFailure={(response) =>
                dispatch(googleOAuthVerificationOnFailure(response?.details))
              }
              onScriptLoadFailure={() => {
                setDisableSSOForIncognito(true);
                googleOAuthVerificationOnScriptFailure();
              }}
              onSuccess={(response) => {
                dispatch(
                  googleOAuthVerification(
                    {
                      postData: {
                        authorization_code: response.code,
                      },
                    },
                    (response) => {
                      googleOAuthVerificationOnSuccess(
                        response.token,
                        response.is_first_time_sso_login_for_existing_account,
                      );
                      dispatch(
                        sendPing({
                          postData: {
                            message: pingCustomerOnlineMessage(response.user),
                            message_type:
                              response.user.team?.payment_plan === PLAN_TYPES.LAUNCH
                                ? PingTypes.PING_ONLINE_LAUNCH
                                : PingTypes.PING_ONLINE,
                          },
                        }),
                      );
                      if (!response.user.team) {
                        dispatch(
                          sendPing({
                            postData: {
                              message: pingUserWithoutTeamMessage(response.user),
                              message_type: PingTypes.PING_USER_WITHOUT_TEAM,
                            },
                          }),
                        );
                      }
                    },
                    (response) => googleOAuthVerificationOnFailure(response?.error_msg),
                  ),
                );
              }}
              redirectUri={`${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI}`}
              responseType="code"
            />
          ) : null}
          <Button
            fillWidth
            className={styles.samlButton}
            disabled={loginLoading}
            onClick={() => history.push(ROUTES.SAML_INITIATE_AUTH)}>
            Sign In with SAML SSO
          </Button>
        </div>
      }
    />
  );
};

import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { SettingHeader } from 'components/SettingHeader';
import { InfoIcon, Switch, sprinkles } from 'components/ds';
import * as styles from './styles.css';

import { ReduxState } from 'reducers/rootReducer';
import { editableSectionSvg, stickyHeaderSvg } from './constants';
import { EditingLayout, setEditingLayout } from 'reducers/dashboardInteractionsReducer';
import { toggleEditableSection, toggleStickyHeader } from 'reducers/dashboardEditConfigReducer';

type Props = { isStickyHeaderEnabled: boolean; isEditableSectionEnabled: boolean };

export const LayoutSelectionSection: FC<Props> = ({
  isStickyHeaderEnabled,
  isEditableSectionEnabled,
}) => {
  const dispatch = useDispatch();

  const { headerOn, dashboardId, editableSectionOn } = useSelector(
    (state: ReduxState) => ({
      headerOn:
        state.dashboardEditConfig.config?.dashboard_page_layout_config?.stickyHeader?.enabled,
      editableSectionOn: state.dashboardEditConfig.config?.editable_section?.enabled,

      dashboardId: state.dashboard.currentDashboardId,
    }),
    shallowEqual,
  );

  if (!dashboardId || (!isStickyHeaderEnabled && !isEditableSectionEnabled)) return null;

  return (
    <>
      <SettingHeader name="Layout" />
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5', padding: 'sp2' })}>
        {isStickyHeaderEnabled ? (
          <LayoutOption
            isStickyHeader
            isOn={!!headerOn}
            onChange={() => dispatch(toggleStickyHeader(dashboardId))}
          />
        ) : null}
        {isEditableSectionEnabled ? (
          <LayoutOption
            isOn={!!editableSectionOn}
            onChange={() => dispatch(toggleEditableSection())}
          />
        ) : null}
      </div>
    </>
  );
};

type OptionProps = {
  isStickyHeader?: boolean;
  isOn: boolean;

  onChange: (newValue: boolean) => void;
};

const LayoutOption: FC<OptionProps> = ({ isStickyHeader, isOn, onChange }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.layoutOption}>
      <div className={styles.layoutOptionHeader}>
        <div>
          {isStickyHeader ? 'Header' : 'Customer editable section'}
          <InfoIcon
            className={sprinkles({ marginLeft: 'sp1' })}
            text={
              isStickyHeader
                ? 'Keep filters at the top of the page, and let customers choose to collapse or expand them.'
                : 'Enable your customers to select charts and add them to their dashboard.'
            }
            tooltipSide="right"
          />
        </div>
        <Switch onChange={onChange} switchOn={isOn} />
      </div>
      <div
        className={isStickyHeader ? styles.stickyHeaderClass : styles.editableClass}
        onClick={() =>
          dispatch(
            setEditingLayout(
              isStickyHeader ? EditingLayout.STICKY_HEADER : EditingLayout.EDITABLE_SECTION,
            ),
          )
        }
        style={{ opacity: isOn ? undefined : '0.4' }}>
        {isStickyHeader ? stickyHeaderSvg : editableSectionSvg}
      </div>
    </div>
  );
};

import { FC, useMemo } from 'react';
import produce from 'immer';
import { keyBy } from 'utils/standard';
import cx from 'classnames';

import { Switch, sprinkles, Select } from 'components/ds';

import {
  TableJoinColumnConfig,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { Dataset } from 'actions/datasetActions';
import { DatasetColumn } from 'types/datasets';
import { createColSelectOptionsWithIcon } from 'utils/general';

type Props = {
  column: DatasetColumn;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  updateInstructions: (
    newInstructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
  ) => void;
  dashboardDatasets: Record<string, Dataset>;
};

const configInputClass = sprinkles({ marginTop: 'sp1' });

export const EnrichColumnConfiguration: FC<Props> = ({
  column,
  instructions,
  updateInstructions,
  dashboardDatasets,
}) => {
  const config = instructions.schemaDisplayOptions?.[column.name];

  const savedDatasets = Object.values(dashboardDatasets).filter((dataset) => !!dataset.schema);
  const selectedDataset = config?.joinTable && dashboardDatasets[config.joinTable.id];

  const schemaByName = selectedDataset?.schema ? keyBy(selectedDataset.schema, 'name') : {};
  const selectedJoinColumn = config?.joinColumn && schemaByName[config.joinColumn.name];

  const updateSchemaDisplayOptions = (updates: TableJoinColumnConfig) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.schemaDisplayOptions) return;
      draft.schemaDisplayOptions[column.name] = {
        ...draft.schemaDisplayOptions[column.name],
        ...updates,
      };
    });

    updateInstructions(newInstructions);
  };

  const options = useMemo(
    () => createColSelectOptionsWithIcon(selectedDataset?.schema),
    [selectedDataset],
  );

  return (
    <div className={sprinkles({ width: 'fill' })}>
      <Switch
        className={configInputClass}
        label="Join"
        onChange={() => updateSchemaDisplayOptions({ joinOn: !config?.joinOn })}
        switchOn={config?.joinOn}
      />

      {config?.joinOn && (
        <>
          <Select
            className={configInputClass}
            label="Dataset"
            onCancel={() => updateSchemaDisplayOptions({ joinTable: undefined })}
            onChange={(datasetId) => {
              const dataset = dashboardDatasets[datasetId];
              if (!dataset) return;
              updateSchemaDisplayOptions({ joinTable: { id: datasetId } });
            }}
            placeholder="Select dataset"
            selectedValue={selectedDataset?.id}
            values={savedDatasets.map((dataset) => ({
              value: String(dataset.id),
              label: dataset.table_name || 'Untitled',
            }))}
          />
          <Select
            className={configInputClass}
            disabled={selectedDataset === undefined}
            label="Join Column"
            onCancel={() => updateSchemaDisplayOptions({ joinColumn: undefined })}
            onChange={(colName) => updateSchemaDisplayOptions({ joinColumn: { name: colName } })}
            placeholder="Select a column"
            selectedValue={config.joinColumn?.name}
            values={options}
          />
          <Select
            className={cx(configInputClass, sprinkles({ paddingBottom: 'sp1.5' }))}
            disabled={selectedDataset === undefined || selectedJoinColumn === undefined}
            label="Display Column"
            onCancel={() => updateSchemaDisplayOptions({ joinDisplayColumn: undefined })}
            onChange={(colName) =>
              updateSchemaDisplayOptions({
                joinDisplayColumn: {
                  name: colName,
                  column: { type: schemaByName[colName].type },
                },
              })
            }
            placeholder="Select a column"
            selectedValue={config.joinDisplayColumn?.name}
            values={options}
          />
        </>
      )}
    </div>
  );
};

import { FC, useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { ReportChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChart';
import * as styles from './Export.css';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import {
  fetchCustomerReports,
  CustomerReportFilter,
  CustomerReportGroupBy,
  CustomerReportAgg,
  CustomerReportVisualization,
  CustomerReportTotals,
} from 'actions/customerReportActions';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { setVariables } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { getQueryVariables } from 'utils/variableUtils';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import {
  getCurrentViewData,
  updateCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { loadFonts } from 'globalStyles/utils';
import { getFilterClauseValueText } from 'utils/customerReportUtils';
import { ReportBuilderPoller } from 'pages/ReportBuilder/ReportBuilderPoller';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { SortInfo } from 'constants/types';
import { openCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';
import { Icon } from 'components/ds';

type Props = {
  customerToken?: string;
  embedType: EmbeddedDashboardType;
  environment?: string;
  reportBuilderEmbedId?: string;
  datasetId?: string;
  embedJwt?: string;
  title?: string;
  visualization: CustomerReportVisualization;
  filters: CustomerReportFilter[];
  sort?: SortInfo[];
  groupBys?: CustomerReportGroupBy[];
  columnGroupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  totals?: CustomerReportTotals;
  hiddenColumns: string[];
  columns: string[];
  variables?: DashboardVariableMap;
};

export const Export: FC<Props> = ({
  customerToken,
  environment,
  reportBuilderEmbedId,
  embedType,
  embedJwt,
  visualization,
  sort,
  columnGroupBys,
  filters,
  groupBys,
  aggregations,
  totals,
  hiddenColumns,
  columns,
  variables,
  datasetId,
  title,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables(embedType));
  const [isLoaded, setIsLoaded] = useState(false);

  const { versionConfig, viewData, globalStyleConfig, team } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      viewData: getCurrentViewData(state.reportEditing),
      team: state.embeddedReportBuilder.team,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!team?.payment_plan) return;
    loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  const view = useMemo(
    () => ({
      id: 'exportViewId',
      name: '',
      filters,
      columnGroupBys,
      aggregations,
      totals,
      groupBys,
      hiddenColumns,
      columnOrder: columns,
      visualization,
      sort,
    }),
    [
      aggregations,
      columnGroupBys,
      columns,
      filters,
      groupBys,
      hiddenColumns,
      sort,
      visualization,
      totals,
    ],
  );

  const dataset = datasetId && versionConfig?.datasets[datasetId];
  useEffect(() => {
    if (dataset)
      dispatch(
        openCustomerReportThunk({
          viewId: view.id,
          name: '',
          is_starred: false,
          modified: '',
          id: 0,
          config: { views: [view], dataInfo: { datasetId, columns } },
        }),
      );
  }, [columns, dataset, datasetId, dispatch, view]);

  useEffect(() => {
    dispatch(
      fetchCustomerReports({
        customerToken,
        jwt: embedJwt,
        postData: {
          environment: environment ?? null,
          resource_embed_id: reportBuilderEmbedId,
        },
      }),
    );
  }, [dispatch, customerToken, embedJwt, environment, reportBuilderEmbedId]);

  useEffect(() => {
    dispatch(setVariables({ ...urlVariables, ...variables }));
  }, [dispatch, variables, urlVariables]);

  useEffect(() => {
    if (Object.values(totals || {}).length) dispatch(updateCurrentView({ showTotals: true }));
  }, [dispatch, totals]);

  // For exporting PDFs, loading is only complete after layout
  useLayoutEffect(() => {
    setIsLoaded(!!viewData.rows);
  }, [viewData.rows]);

  if (!dataset) return <div className="data-panel-error">Missing dataset</div>;

  return (
    <div
      className={cx(styles.viewContainer, { 'explo-dashboard-loaded': isLoaded })}
      ref={containerRef}>
      <div className={styles.reportContainer}>
        <div className={styles.reportHeader}>
          {title ? <EmbedText heading="h2">{title}</EmbedText> : null}
          {filters ? (
            <div className={styles.filterContainer}>
              {filters.map((filter) => {
                return (
                  <div className={styles.filterTag} key={filter.id}>
                    <Icon name="filter" />
                    <EmbedText body="b3">
                      {filter.filterColumn.name}&nbsp;{getFilterClauseValueText(filter)}
                    </EmbedText>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        {/* overflow: hidden is necessary for Highcharts to resize when the layout changes */}
        <div className={styles.chartContainer} id="shared-explo-dashboard">
          <ReportChart
            containerRef={containerRef}
            dataset={dataset}
            reportData={viewData}
            view={view}
          />
        </div>
      </div>
      <ReportBuilderPoller />
    </div>
  );
};

import { FC } from 'react';

import { Toggle, ToggleItem } from './ds';
import { IconName } from './ds/Icon';

import { TEXT_ELEM_HORIZ_ALIGNMENTS, TEXT_ELEM_VERTICAL_ALIGNMENTS } from 'types/dashboardTypes';

type Props<T> = {
  className?: string;
  label?: string;

  selectedAlignment: T;
  updateAlignment: (newAlignment: T) => void;
};

const HorizontalOptions: { id: TEXT_ELEM_HORIZ_ALIGNMENTS; icon: IconName }[] = [
  { id: TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT, icon: 'align-left' },
  { id: TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER, icon: 'align-center' },
  { id: TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT, icon: 'align-right' },
];

export const HorizontalAlignmentToggle: FC<Props<TEXT_ELEM_HORIZ_ALIGNMENTS>> = ({
  className,
  label,
  selectedAlignment,
  updateAlignment,
}) => {
  return (
    <Toggle
      className={className}
      label={label}
      onValueChange={(alignment) => updateAlignment(alignment as TEXT_ELEM_HORIZ_ALIGNMENTS)}
      selectedValue={selectedAlignment}>
      {HorizontalOptions.map((alignment) => (
        <ToggleItem icon={alignment.icon} key={alignment.id} value={alignment.id} />
      ))}
    </Toggle>
  );
};

const VerticalOptions: { id: TEXT_ELEM_VERTICAL_ALIGNMENTS; icon: IconName }[] = [
  { id: TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP, icon: 'align-top' },
  { id: TEXT_ELEM_VERTICAL_ALIGNMENTS.CENTER, icon: 'align-center-vertical' },
  { id: TEXT_ELEM_VERTICAL_ALIGNMENTS.BOTTOM, icon: 'align-bottom' },
];

export const VerticalAlignmentToggle: FC<Props<TEXT_ELEM_VERTICAL_ALIGNMENTS>> = ({
  className,
  label,
  selectedAlignment,
  updateAlignment,
}) => {
  return (
    <Toggle
      className={className}
      label={label}
      onValueChange={(alignment) => updateAlignment(alignment as TEXT_ELEM_VERTICAL_ALIGNMENTS)}
      selectedValue={selectedAlignment}>
      {VerticalOptions.map((alignment) => (
        <ToggleItem icon={alignment.icon} key={alignment.id} value={alignment.id} />
      ))}
    </Toggle>
  );
};

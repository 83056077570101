import { FC, forwardRef, Ref, DetailedHTMLProps, HTMLAttributes } from 'react';
import cx from 'classnames';

import { Icon, sprinkles } from 'components/ds';
import { IconSize } from 'components/ds/Icon';

type HTMLSVGProps = Omit<DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>, 'ref'>;
type HTMLDivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>;
type SpinnerProps = {
  // Determine whether spinner should fill container or be inline
  fillContainer?: boolean;
  // Size of the spinner relative to font size
  size?: IconSize;
};

export type Props = SpinnerProps & (HTMLSVGProps | HTMLDivProps);

export const Spinner: FC<Props> = forwardRef<SVGSVGElement | HTMLDivElement, Props>(
  ({ className, fillContainer, size = 'xl', ...props }, ref) => {
    if (fillContainer) {
      return (
        <div
          {...(props as HTMLDivProps)}
          className={cx(
            sprinkles({ color: 'contentSecondary', parentContainer: 'fill', flexItems: 'center' }),
            className,
          )}
          ref={ref as Ref<HTMLDivElement>}>
          <Icon spin name="spinner" size={size} />
        </div>
      );
    }

    return (
      <Icon
        {...(props as HTMLSVGProps)}
        spin
        className={cx(sprinkles({ color: 'contentSecondary' }), className)}
        name="spinner"
        parentRef={ref as Ref<SVGSVGElement>}
        size={size}
      />
    );
  },
);

Spinner.displayName = 'Spinner';

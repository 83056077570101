import { FC, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { EmbedText } from '../EmbedText';
import { StarStatus } from '../StarStatus';
import * as styles from 'pages/ReportBuilder/HomeView/BuiltInReportCardClass.css';

import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportType } from 'reportBuilderContent/reducers/types';
import { openBuiltInThunk } from 'reportBuilderContent/thunks/reportThunks';

type Props = {
  builtInReport: BuiltInReportConfig;
  className?: string;
};

export const BuiltInReportCard: FC<Props> = ({ className, builtInReport }) => {
  const dispatch = useDispatch();

  const favoriteBuiltIns = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.favoriteBuiltIns,
  );

  const onClick = () => dispatch(openBuiltInThunk(builtInReport));

  const isFavorited = useMemo(
    () => favoriteBuiltIns.includes(builtInReport.id),
    [favoriteBuiltIns, builtInReport.id],
  );

  return (
    <div
      className={cx(styles.builtInReportCardClass, className)}
      onClick={(e) => !e.defaultPrevented && onClick()}>
      <div
        className={sprinkles({
          flexItems: 'alignCenterBetween',
          gap: 'sp.5',
        })}>
        <EmbedText
          className={sprinkles({ truncateText: 'ellipsis', marginX: 'sp.5' })}
          color="contentPrimary"
          heading="h3">
          {builtInReport.name}
        </EmbedText>
        <StarStatus
          isStarred={isFavorited}
          reportInfo={{ type: ReportType.BUILT_IN, id: builtInReport.id }}
        />
      </div>
      <EmbedText
        body="b2"
        className={sprinkles({ marginX: 'sp.5', overflow: 'hidden' })}
        color="contentPrimary">
        {builtInReport.description}
      </EmbedText>
    </div>
  );
};

import { useCallback, useState, useMemo, FC } from 'react';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';

import { Button } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';

import {
  CategoryChartColumnInfo,
  DateDisplayOptions,
  DisplayOptions,
  NumberDisplayDisplayType,
  NumberDisplayOptions,
  Total,
} from 'constants/types';
import { formatNumberValue, formatValue } from 'pages/dashboardPage/charts/utils';
import { TableProgressBar } from 'components/TableProgressBar';
import { DATE_TYPES, NUMBER_TYPES, V2_NUMBER_FORMATS, STRING } from 'constants/dataConstants';
import { formatDateField } from 'pages/dashboardPage/charts/utils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { resolveNumberInputWithVariables } from 'utils/variableUtils';
import { MIN_ROW_HEIGHT } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/formatSections/CollapsibleListRowsConfig';
import { getFlexAlignments } from 'components/ds/DataGrid/utils';
import { DEFAULT_RENDER_COUNT } from './DashboardCollapsibleList';
import { DatasetDataObject } from 'actions/datasetActions';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type Props = {
  category: CategoryChartColumnInfo;
  categoryAggName: string;
  name: string;
  totals: Total[];
  children: JSX.Element[];
  columnWidth: number;
  showCategories?: boolean;
  index: number;
  groupByDisplayOptions?: Record<string, DisplayOptions>;
  numberDisplayOptions?: Record<string, NumberDisplayOptions>;
  ignoreInvalidDates?: boolean;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  rowHeight?: number;
};

export const DashboardCollapsibleListItem: FC<Props> = ({
  name,
  columnWidth,
  categoryAggName,
  children,
  groupByDisplayOptions,
  numberDisplayOptions,
  ignoreInvalidDates,
  totals,
  showCategories,
  category,
  index,
  variables,
  datasetData,
  datasetNamesToId,
  rowHeight,
}) => {
  const [isOpen, toggleItemOpen] = useState(false);
  const [rowsToRenderCount, setRowsToRenderCount] = useState(DEFAULT_RENDER_COUNT);
  const theme = useTheme();

  const formatNumberField = useCallback(
    (value: number, numberFormatOption: NumberDisplayOptions | undefined) => {
      if (numberFormatOption) {
        const formattedValue = formatNumberValue(
          numberFormatOption,
          value,
          resolveNumberInputWithVariables(
            numberFormatOption.goal,
            variables,
            datasetNamesToId,
            datasetData,
          ),
        );

        if (numberFormatOption.displayType === NumberDisplayDisplayType.PROGRESS_BAR) {
          const progressBarGoal = resolveNumberInputWithVariables(
            numberFormatOption.displayTypeOptions?.progressBarGoal,
            variables,
            datasetNamesToId,
            datasetData,
          );

          return (
            <TableProgressBar
              backgroundColor={theme.palette.ds.lightBlue}
              color={theme.palette.ds.blue}
              disableTooltip={numberFormatOption.disableHoverTooltip}
              formattedValue={formattedValue}
              progressBarGoal={progressBarGoal}
              value={value}
            />
          );
        }

        return formattedValue;
      } else {
        return formatValue({
          value: Number(value),
          decimalPlaces: 0,
          formatId: V2_NUMBER_FORMATS.NUMBER.id,
          hasCommas: false,
        });
      }
    },
    [datasetNamesToId, datasetData, theme.palette.ds, variables],
  );

  const formatTotalValue = (t: Total) => {
    if (t.category) return t.category;

    return formatNumberField(Number(t.total) || 0, numberDisplayOptions?.[t.name]);
  };

  const mainText = useMemo(() => {
    const category_type = category.column.type;

    if (category_type === undefined || category_type === STRING) return name;

    if (DATE_TYPES.has(category_type)) {
      return formatDateField(
        name,
        category_type,
        groupByDisplayOptions?.[category.column.name ?? ''] as DateDisplayOptions,
        ignoreInvalidDates,
      );
    }

    if (NUMBER_TYPES.has(category_type)) {
      return formatNumberField(
        Number(name),
        groupByDisplayOptions?.[categoryAggName] as NumberDisplayOptions,
      );
    }

    return name;
  }, [
    category.column,
    categoryAggName,
    formatNumberField,
    groupByDisplayOptions,
    ignoreInvalidDates,
    name,
  ]);

  // TODO: Use custom styles
  const tableLine = `${theme.palette.ds.grey400} 0px -1px 0px inset, 0px 0px 0px inset`;

  const renderedChildren = useMemo(
    () => children.slice(0, rowsToRenderCount),
    [children, rowsToRenderCount],
  );

  if (totals.every((t) => t.total === 0)) return null;

  return (
    <div className={sprinkles({ flexDirection: 'column' })}>
      <div
        className={sprinkles({ flexItems: 'alignCenterBetween' })}
        style={{ boxShadow: tableLine }}>
        <div
          className={cx(
            sprinkles({
              flexItems: 'alignCenter',
              overflow: 'hidden',
            }),
            rowHeight
              ? sprinkles({ margin: 'sp0' })
              : sprinkles({ marginX: 'sp0', marginY: 'sp1' }),
            embedSprinkles({ body: 'primary' }),
          )}
          style={{
            paddingLeft: `${index * theme.spacing(6) + theme.spacing(1)}px`,
            height: rowHeight ?? MIN_ROW_HEIGHT,
            width: `${columnWidth}%`,
          }}>
          {children.length > 0 && (
            <Button
              minimal
              icon={isOpen ? 'chevron-down' : 'chevron-right'}
              onClick={() => toggleItemOpen(!isOpen)}
            />
          )}
          <div
            className={cx(
              sprinkles({ truncateText: 'ellipsis' }),
              embedSprinkles({ body: 'primary' }),
              {
                [sprinkles({ paddingLeft: 'sp2' })]: children.length === 0,
              },
            )}
            title={typeof mainText === 'string' ? mainText : undefined}>
            {showCategories && (
              <span className={embedSprinkles({ color: 'secondaryFont' })}>
                {(category.column.friendly_name || category.column.name) + ': '}
              </span>
            )}
            {mainText}
          </div>
        </div>

        <div
          className={cx(
            sprinkles({ display: 'flex', justifyContent: 'space-between', paddingY: 'sp1.5' }),
          )}
          style={{ width: `${columnWidth * totals.length}%` }}>
          {totals.map((t) => (
            <div
              className={cx(
                sprinkles({
                  flexItems: 'alignCenter',
                  width: 'fill',
                  // TODO: Refactor how we get the agg cols so that we can pass the correct type here
                  justifyContent: getFlexAlignments(numberDisplayOptions?.[t.name], ''),
                  paddingX: 'sp1',
                }),
                embedSprinkles({ body: 'primary' }),
              )}
              key={t.name}>
              {formatTotalValue(t)}
            </div>
          ))}
        </div>
      </div>
      <div>
        {isOpen ? (
          <>
            {renderedChildren}
            {renderedChildren.length < children.length ? (
              <div
                className={cx(
                  sprinkles({
                    cursor: 'pointer',
                    width: 'fill',
                    paddingY: 'sp2',
                    textAlign: 'center',
                  }),
                  embedSprinkles({ body: 'primary' }),
                )}
                onClick={() =>
                  setRowsToRenderCount((prevCount) => prevCount + DEFAULT_RENDER_COUNT)
                }
                style={{ boxShadow: tableLine }}>
                Load more
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

import { FC } from 'react';
import { cloneDeep } from 'utils/standard';
import cx from 'classnames';

import { ColorPickerButton } from 'components/ColorPickerButton';
import { IconButton } from 'components/ds';

import { ColorPalette, ColorPaletteV2 } from 'constants/types';
import { PALETTE_TO_COLORS } from 'constants/colorConstants';
import { constructCustomPalette } from 'pages/dashboardPage/charts/utils';
import { getCategoricalColors, getDivergingColors, getGradientColors } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import * as styles from './index.css';

type Props = {
  className?: string;
  palette: ColorPalette | ColorPaletteV2;
  customColors?: string;
  globalStyleConfig: GlobalStyleConfig;
  saveCustomColorConfig: (colorPalette: string[]) => void;
};

export const ColorPaletteDisplay: FC<Props> = ({
  className,
  palette,
  customColors,
  globalStyleConfig,
  saveCustomColorConfig,
}) => {
  let colorList: string[] | undefined;

  const categoricalColors = getCategoricalColors(globalStyleConfig);

  switch (palette) {
    case ColorPalette.CUSTOM:
      colorList = constructCustomPalette(customColors);
      break;
    case ColorPaletteV2.CATEGORICAL:
      colorList = categoricalColors;
      break;
    case ColorPaletteV2.DIVERGING:
      colorList = getDivergingColors(globalStyleConfig);
      break;
    case ColorPaletteV2.GRADIENT:
      colorList = getGradientColors(globalStyleConfig);
      break;
    default:
      colorList = PALETTE_TO_COLORS[palette];
  }

  return (
    <div className={cx(className, styles.root)}>
      {colorList
        ? colorList.map((colorHex, index) => (
            <ColorPickerButton
              btnClassName={styles.colorButton}
              color={colorHex}
              colorPalette={categoricalColors}
              key={`color-palette-display-${index}-${colorHex}`}
              onCancel={() => {
                const palette = cloneDeep(colorList) || [];
                palette.splice(index, 1);
                saveCustomColorConfig(palette);
              }}
              onColorChange={(newColor) => {
                const palette = cloneDeep(colorList) || [];
                palette[index] = newColor;
                saveCustomColorConfig(palette);
              }}
            />
          ))
        : null}
      <IconButton
        className={styles.colorButton}
        name="plus"
        onClick={() =>
          saveCustomColorConfig(colorList ? [...(colorList || []), '#0069ED'] : ['#0069ED'])
        }
      />
    </div>
  );
};

import { FC } from 'react';

import { Input, Select, Switch } from 'components/ds';
import { NumberFormatToggle } from 'components/NumberFormatToggle';

import { KPIValueFormat, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import {
  NUMBER_FORMATS_WITH_DECIMALS,
  TIME_DIFF_FORMATS,
  TIME_DIFF_FORMAT_SELECT_OPTIONS,
  V2_NUMBER_FORMATS,
} from 'constants/dataConstants';

type Props = {
  configInputClass: string;
  instructions: V2KPIChartInstructions;
  operationType: OPERATION_TYPES.VISUALIZE_NUMBER_V2 | OPERATION_TYPES.VISUALIZE_PROGRESS_V2;
  updateValueFormat: (generalFormat: KPIValueFormat) => void;
};

export const SharedKPIValueConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  operationType,
  updateValueFormat,
}) => {
  const valueFormat = instructions.valueFormat;
  const selectedFormat = valueFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const selectedTimeFormat = valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;

  return (
    <>
      <NumberFormatToggle
        className={configInputClass}
        label="Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateValueFormat({ numberFormat: { id: numberFormat } })}
      />
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id ? (
        <>
          <Select
            className={configInputClass}
            label="Time Format"
            onChange={(timeFormatId) => updateValueFormat({ timeFormat: { id: timeFormatId } })}
            placeholder="Standard"
            selectedValue={selectedTimeFormat.id}
            values={TIME_DIFF_FORMAT_SELECT_OPTIONS}
          />
          {valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id ? (
            <Input
              showInputButton
              className={configInputClass}
              defaultValue={valueFormat?.timeCustomerFormat}
              label="Custom Time Format"
              onSubmit={(newValue) => updateValueFormat({ timeCustomerFormat: newValue })}
              placeholder="DD days HH:mm:ss"
            />
          ) : null}
        </>
      ) : null}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.significantDigits ?? 3)}
          label="Significant Digits"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      ) : null}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.decimalPlaces ?? 2)}
          label="Decimal Places"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      {operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ||
      (NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) &&
        operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.multiplyFactor ?? 1)}
          label="Multiplier"
          onSubmit={(newValue) => {
            const floatValue = parseFloat(newValue);
            updateValueFormat({
              multiplyFactor: isNaN(floatValue) ? undefined : floatValue,
            });
          }}
        />
      ) : null}
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={valueFormat?.units}
        label="Unit"
        onSubmit={(newValue) => updateValueFormat({ units: newValue })}
      />
      <Switch
        className={configInputClass}
        label="Include Unit Padding"
        onChange={() => updateValueFormat({ unitPadding: !valueFormat?.unitPadding })}
        switchOn={valueFormat?.unitPadding}
      />
      {operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ? (
        <>
          <Switch
            className={configInputClass}
            label="Bold Value"
            onChange={() => updateValueFormat({ bold: !valueFormat?.bold })}
            switchOn={valueFormat?.bold}
          />
          <Switch
            className={configInputClass}
            label="Italicize Value"
            onChange={() => updateValueFormat({ italic: !valueFormat?.italic })}
            switchOn={valueFormat?.italic}
          />
        </>
      ) : null}
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={valueFormat?.imageUrl}
        label={{ text: 'Image to Display Next to Value', variableInput: true }}
        onSubmit={(newValue) => updateValueFormat({ imageUrl: newValue })}
        placeholder="Image URL"
      />
    </>
  );
};

import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { sprinkles, Input } from 'components/ds';
import {
  EmbedModal,
  EmbedModalHeader,
  EmbedModalClose,
  EmbedModalFooter,
  EmbedModalContent,
} from 'components/embed';

import { updateCustomerReportName } from 'actions/customerReportActions';
import {
  clearSelectedReport,
  closeReportModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { saveCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';

type Props = { reportId: number; reportName: string; isIframe: boolean };

/**
 * Shown when the user tries to close a report with unsaved changes
 */
export const ConfirmCloseReportModal: FC<Props> = ({ reportId, reportName, isIframe }) => {
  const dispatch = useDispatch();
  const [newReportName, setNewReportName] = useState(reportName || 'Untitled Report');

  const { customerToken, currentConfig, jwt } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      jwt: state.embeddedReportBuilder.requestInfo.embedJwt,
      currentConfig: state.reportEditing.currentConfig,
    }),
    shallowEqual,
  );

  const handleSaveAndClose = () => {
    if (currentConfig)
      dispatch(saveCustomerReportThunk({ report_id: reportId, config: currentConfig }));

    if (newReportName.trim() && newReportName !== reportName) {
      dispatch(
        updateCustomerReportName({
          customerToken,
          jwt,
          postData: { report_id: reportId, name: newReportName },
        }),
      );
    }

    dispatch(clearSelectedReport());
  };

  return (
    <EmbedModal
      isOpen
      isIframe={isIframe}
      onClose={() => dispatch(closeReportModal())}
      size="medium">
      <EmbedModalHeader title="You have unsaved changes on this report" />
      {reportName.trim() !== '' ? (
        <EmbedModalContent
          className={sprinkles({ flexItems: 'column', gap: 'sp.5', paddingX: 'sp3' })}
          size="medium">
          <EmbedText body="b2" color="contentPrimary">
            Either save your changes to this report before closing it or discard the changes.
          </EmbedText>
        </EmbedModalContent>
      ) : (
        <EmbedModalContent
          className={sprinkles({ flexItems: 'column', gap: 'sp.5', paddingX: 'sp3' })}
          size="small">
          <EmbedText body="b3" color="contentPrimary">
            Report name
          </EmbedText>
          <Input onChange={setNewReportName} placeholder="Untitled Report" value={newReportName} />
        </EmbedModalContent>
      )}
      <EmbedModalFooter>
        <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
        <EmbedModalClose onClick={() => dispatch(clearSelectedReport())} variant="secondary">
          Close without saving
        </EmbedModalClose>
        <EmbedModalClose onClick={handleSaveAndClose} variant="primary">
          Save and close
        </EmbedModalClose>
      </EmbedModalFooter>
    </EmbedModal>
  );
};

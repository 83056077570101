import { ThunkAction, AnyAction } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { createRequestAction, createSuccessAction, createErrorAction } from 'actions/actionUtils';
import { createJob } from 'components/JobQueue/createJob';
import { enqueueReportBuilderJobsThunk } from 'reportBuilderContent/thunks/jobThunks';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import {
  GenerateReportBuilderViewSuggestionsAIJobParams,
  GenerateReportBuilderViewAIJobParams,
  GenerateReportBuilderViewAIJobRequest,
  GenerateReportBuilderViewAIJobSuccess,
  GenerateReportBuilderViewSuggestionsAIJobRequest,
  GenerateReportBuilderViewSuggestionsAIJobSuccess,
  GenerateReportBuilderViewSuggestionsAIJob,
  GenerateReportBuilderViewAIJob,
  ReportBuilderJobError,
} from 'components/JobQueue/types';
import { openModalType } from 'reportBuilderContent/reducers/reportEditingReducer';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { ReportModalType } from 'reportBuilderContent/reducers/types';

export const openAiModalThunk = (): Thunk => (dispatch, getState) => {
  const { selectedReport, currentConfig } = getState().reportEditing;
  dispatch(openModalType({ type: ReportModalType.GENERATE_AI_VIEW }));
  dispatch(
    sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_AI_OPENED, {
      customer_report_id: selectedReport?.id,
      dataset_id: currentConfig?.dataInfo?.datasetId,
    }),
  );
};

export const fetchGenerateAiViewJob =
  (params: GenerateReportBuilderViewAIJobParams): Thunk =>
  (dispatch, getState) => {
    const job = createJob({
      job_type: ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB,
      job_args: params,
    });
    dispatch(enqueueReportBuilderJobsThunk(job));

    const { selectedReport, currentConfig } = getState().reportEditing;
    dispatch(
      sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_AI_PROMPTED, {
        customer_report_id: selectedReport?.id,
        dataset_id: currentConfig?.dataInfo?.datasetId,
        query: params.query,
      }),
    );
  };

export const fetchGenerateAiViewRequest =
  createRequestAction<GenerateReportBuilderViewAIJobRequest>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB,
  );

export const fetchGenerateAiViewSuccess =
  createSuccessAction<GenerateReportBuilderViewAIJobSuccess>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB,
  );

export const fetchGenerateAiViewError = createErrorAction<
  ReportBuilderJobError<GenerateReportBuilderViewAIJob>
>(ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB);

export const fetchGenerateAiViewSuggestionsJob =
  (params: GenerateReportBuilderViewSuggestionsAIJobParams): Thunk =>
  (dispatch) => {
    const job = createJob({
      job_type: ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB,
      job_args: params,
    });

    dispatch(enqueueReportBuilderJobsThunk(job));
  };

export const fetchGenerateAiViewSuggestionRequest =
  createRequestAction<GenerateReportBuilderViewSuggestionsAIJobRequest>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB,
  );

export const fetchGenerateAiViewSuggestionSuccess =
  createSuccessAction<GenerateReportBuilderViewSuggestionsAIJobSuccess>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB,
  );

export const fetchGenerateAiViewSuggestionError = createErrorAction<
  ReportBuilderJobError<GenerateReportBuilderViewSuggestionsAIJob>
>(ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB);

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import {
  Customer,
  GroupTag,
  EndUser,
  HierarchyLevel,
  HierarchyLevelCountInfo,
  SummaryCustomer,
} from 'actions/teamActions';
import { ACTION } from './types';

// Move Customer Type in here

type FetchCustomersData = {
  results: Customer[];
  count: number;
};

type FetchCustomerPotentialParentsData = {
  results: SummaryCustomer[];
  count: number;
};

export const {
  actionFn: fetchCustomers,
  requestAction: fetchCustomersRequest,
  errorAction: fetchCustomersError,
  successAction: fetchCustomersSuccess,
} = defineAPIAction<FetchCustomersData>(ACTION.FETCH_CUSTOMERS, 'customers', '', 'GET');

export const {
  actionFn: fetchFilteredCustomers,
  requestAction: fetchFilteredCustomersRequest,
  errorAction: fetchFilteredCustomersError,
  successAction: fetchFilteredCustomersSuccess,
} = defineAPIAction<FetchCustomersData>(ACTION.FETCH_FILTERED_CUSTOMERS, 'customers', '', 'GET');

export const {
  actionFn: fetchSelectorCustomers,
  requestAction: fetchSelectorCustomersRequest,
  errorAction: fetchSelectorCustomersError,
  successAction: fetchSelectorCustomersSuccess,
} = defineAPIAction<FetchCustomersData>(ACTION.FETCH_SELECTOR_CUSTOMERS, 'customers', '', 'GET');

export const {
  actionFn: fetchCustomerPotentialParents,
  requestAction: fetchCustomerPotentialParentsRequest,
  errorAction: fetchCustomerPotentialParentsError,
  successAction: fetchCustomerPotentialParentsSuccess,
} = defineAPIAction<FetchCustomerPotentialParentsData>(
  ACTION.FETCH_CUSTOMER_POTENTIAL_PARENTS,
  'customers',
  'list_customer_potential_parents',
  'GET',
);

type CustomerBody = {
  name: string;
  provided_id: string;
  mapping: Record<string, string>;
  access_group_id: number;
  is_demo_group: boolean;
  properties: Record<string, string>;
  group_tag_ids?: number[];
  end_users?: EndUser[];
  permissioned_dashboard_id?: number;
  emails?: string[];
  hierarchy_level_id?: number;
  parent_provided_id?: string | null;
};

type CustomerData = {
  customer: Customer;
  customer_portal_users: EndUser[];
  is_parent: boolean;
  updated_children: SummaryCustomer[];
};

type AddCustomerData = CustomerData;

export const { actionFn: addCustomer, successAction: addCustomerSuccess } = defineAPIPostAction<
  CustomerBody,
  AddCustomerData
>(ACTION.CREATE_CUSTOMER, 'customers', '', 'POST');

export const { actionFn: deleteCustomer, successAction: deleteCustomersSuccess } = defineAPIAction(
  ACTION.DELETE_CUSTOMER,
  'customers',
  '',
  'DELETE',
);

export const { actionFn: editCustomer, successAction: editCustomersSuccess } = defineAPIPostAction<
  CustomerBody,
  CustomerData
>(ACTION.EDIT_CUSTOMER, 'customers', 'update_user_group', 'POST');

export const {
  actionFn: generateEmbedJwt,
  successAction: generateEmbedJwtSuccess,
  errorAction: generateEmbedJwtError,
} = defineAPIPostAction<
  {
    customer_id: number;
    dashboard_embed_id: string;
  },
  {
    jwt: string;
  }
>(ACTION.GENERATE_EMBED_JWT, 'customers', 'generate_embed_jwt', 'POST');

type GroupTagData = {
  group_tag: GroupTag;
};

type AddGroupTagBody = {
  name: string;
};

export const { actionFn: addGroupTag, successAction: addGroupTagSuccess } = defineAPIPostAction<
  AddGroupTagBody,
  GroupTagData
>(ACTION.ADD_GROUP_TAG, 'group_tags', 'create_group_tag', 'POST');

type DeleteGroupTagBody = {
  group_tag_id: number;
};

export const { actionFn: deleteGroupTag, successAction: deleteGroupTagSuccess } =
  defineAPIPostAction<DeleteGroupTagBody, DeleteGroupTagBody>(
    ACTION.DELETE_GROUP_TAG,
    'group_tags',
    'delete_group_tag',
    'POST',
  );

type RenameGroupTagBody = {
  group_tag_id: number;
  name: string;
};

export const { actionFn: renameGroupTag, successAction: renameGroupTagSuccess } =
  defineAPIPostAction<RenameGroupTagBody, GroupTagData>(
    ACTION.RENAME_GROUP_TAG,
    'group_tags',
    'rename_group_tag',
    'POST',
  );

type FetchGroupTagsData = {
  group_tags: GroupTag[];
};

export const {
  actionFn: fetchGroupTags,
  requestAction: fetchGroupTagsRequest,
  errorAction: fetchGroupTagsError,
  successAction: fetchGroupTagsSuccess,
} = defineAPIAction<FetchGroupTagsData>(ACTION.FETCH_GROUP_TAGS, 'group_tags', '', 'GET');

type FetchHierarchyMetadata = {
  hierarchy_levels: HierarchyLevel[];
  hierarchy_level_counts: HierarchyLevelCountInfo[];
};

export const {
  actionFn: fetchHierarchyMetadata,
  requestAction: fetchHierarchyMetadataRequest,
  errorAction: fetchHierarchyMetadataError,
  successAction: fetchHierarchyMetadataSuccess,
} = defineAPIAction<FetchHierarchyMetadata>(
  ACTION.FETCH_HIERARCHY_METADATA,
  'hierarchy_levels',
  'get_hierarchy_metadata',
  'GET',
);

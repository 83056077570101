import { useCallback, useEffect, useState, useRef, FC, useMemo } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ResizeObserver from 'react-resize-observer';
import * as RD from 'remotedata';
import { useIntersectionObserver } from 'usehooks-ts';

import { embedFetchDashboard } from 'actions/shareActions';
import { pageView } from 'analytics/exploAnalytics';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EmbeddedDashboardType, shouldUseUrlParams } from './types';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { Metadata, useSetupAnalytics } from 'utils/analyticsUtils';
import { loadLocale } from 'utils/localizationUtils';
import { getTimezone } from 'utils/timezoneUtils';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { EmbedSpinner } from 'components/embed';
import { loadFonts } from 'globalStyles/utils';
import UsePageVisibility from 'components/HOCs/usePageVisibility';

import { showExploBranding } from 'utils/paymentPlanUtils';
import { MOBILE_BREAKPOINT_WIDTH } from 'constants/dashboardConstants';
import { setUser, reportError } from 'analytics/datadog';
import { sprinkles } from 'components/ds';
import {
  filterHiddenElements,
  filterHiddenPanels,
  getQueryVariables,
  getRefreshMinutes,
  getValueOrDefault,
  isVariableTrue,
} from 'utils/variableUtils';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { useDashboardInteractionsInfo } from 'utils/hookUtils';
import {
  getCurrentTheme,
  setCustomStylesPageOverwrite,
  setDashboardTheme,
} from 'reducers/dashboardStylesReducer';
import {
  UPDATE_EXPLO_DASHBOARD_VARIABLE,
  UpdateExploDashboardVariablePayload,
  sendDashboardReadyToLoadEvent,
} from 'utils/customEventUtils';
import { setHiddenElements, toggleElementVisibility } from 'reducers/embedDashboardReducer';
import { setVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';

declare global {
  interface WindowEventMap {
    [UPDATE_EXPLO_DASHBOARD_VARIABLE]: CustomEvent<UpdateExploDashboardVariablePayload>;
  }
}

const ErrorFallback: FC<FallbackProps> = ({ error }) => (
  <div className={errorMessageStyle} role="alert">
    {error && error.message
      ? error.message
      : 'There was an error loading the dashboard. Please contact your support team for help.'}
  </div>
);

type Props = {
  dashboardEmbedId: string;
  viewMode: VIEW_MODE;
  embedType: EmbeddedDashboardType;
  customerToken: string | undefined;
  customStyles?: GlobalStyleConfig;
  embeddedVariables?: DashboardVariableMap;
  environment?: string;
  versionNumber?: number;
  isProduction?: string;
  isStrict?: boolean;
  refreshMinutes?: number;
  updateUrlParams?: boolean;
  localeCode?: string;
  currencyCode?: string;
  timezone?: string;
  analyticsProperties?: Metadata;
  embedJwt?: string | undefined;
  dashboardTheme?: string;
  disableEditableSectionEditing?: boolean;
  hideEditableSection?: boolean;
};

const EmbeddedDashboardWrapper: FC<Props> = ({
  customStyles,
  dashboardTheme,
  ...dashboardProps
}) => {
  const dispatch = useDispatch();

  const globalStyleConfig = useSelector((state: EmbedReduxState) =>
    getCurrentTheme(state.dashboardStyles),
  );
  const team = useSelector((state: EmbedReduxState) => state.embedDashboard.team);

  useEffect(() => {
    const theme = getValueOrDefault('theme', dashboardTheme);
    dispatch(setDashboardTheme(typeof theme === 'string' ? theme : undefined));
  }, [dashboardTheme, dispatch]);

  useEffect(() => {
    if (!customStyles) return;
    dispatch(setCustomStylesPageOverwrite(customStyles));
  }, [dispatch, customStyles]);

  useEffect(() => {
    if (!team) return;
    loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  useEffect(() => {
    const updateExploDashboardVariable = (e: CustomEvent<UpdateExploDashboardVariablePayload>) => {
      if (typeof e.detail.varName !== 'string') return;
      dispatch(toggleElementVisibility({ varName: e.detail.varName, value: e.detail.value }));
      dispatch(setVariableThunk({ varName: e.detail.varName, value: e.detail.value }));
    };

    window.addEventListener(UPDATE_EXPLO_DASHBOARD_VARIABLE, updateExploDashboardVariable);

    return () => {
      window.removeEventListener(UPDATE_EXPLO_DASHBOARD_VARIABLE, updateExploDashboardVariable);
    };
  }, [dispatch]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
      <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
        {(globalStylesClassName) => (
          <EmbeddedDashboard globalStylesClassName={globalStylesClassName} {...dashboardProps} />
        )}
      </GlobalStylesProvider>
    </ErrorBoundary>
  );
};

export default EmbeddedDashboardWrapper;

const EmbeddedDashboard: FC<Props & { globalStylesClassName: string }> = (props) => {
  const {
    viewMode,
    embedType,
    dashboardEmbedId,
    embeddedVariables,
    environment: environmentProp,
    versionNumber,
    isProduction: isProductionProp,
    isStrict,
    refreshMinutes,
    updateUrlParams,
    customerToken,
    localeCode,
    currencyCode,
    timezone: passedTimezone,
    globalStylesClassName,
    analyticsProperties,
    embedJwt,
    disableEditableSectionEditing,
    hideEditableSection,
  } = props;
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const observer = useIntersectionObserver(containerRef, {});

  const [urlVariables] = useState<DashboardVariableMap>(
    getQueryVariables(embedType, updateUrlParams),
  );
  const [width, setWidth] = useState<number | null>(null);

  const { dashboard, dashboardVersion, team, customer, hiddenElements } = useSelector(
    (state: EmbedReduxState) => ({
      dashboard: state.embedDashboard.dashboard,
      dashboardVersion: state.embedDashboard.dashboardVersion,
      team: state.embedDashboard.team,
      customer: state.embedDashboard.customer,
      hiddenElements: state.embedDashboard.hiddenElements,
    }),
    shallowEqual,
  );

  const isDashboardLoading = RD.isLoading(dashboard, true);
  const dashboardData = RD.isSuccess(dashboard) ? dashboard.data : null;

  const { version_number: dashboardVersionNumber, configuration: dashboardConfig } =
    dashboardVersion ?? {};

  const sendInitialPageView = useCallback(() => {
    switch (embedType) {
      case 'shared':
        if (isStrict) pageView('Shared Dashboard Page - Strict Format');
        else pageView('Shared Dashboard Page');
        break;
      case 'iframe':
        if (isStrict) pageView('Iframe Dashboard - Strict Format');
        else pageView('Iframe Dashboard');
        break;
      default:
        break;
    }
  }, [embedType, isStrict]);

  const { environment, isProduction } = useMemo(() => {
    const queryVariables = getQueryVariables(embedType, updateUrlParams);
    const environment = environmentProp || queryVariables['environment'];
    const isProduction = isProductionProp ?? queryVariables['is_production'];
    return { environment, isProduction };
  }, [embedType, updateUrlParams, environmentProp, isProductionProp]);

  const fetchDashboardData = useCallback(() => {
    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          jwt: embedJwt,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
            is_preview: embedType === 'preview',
          },
        },
        (data) => {
          setUser({
            endUserId: data.customer.id,
            endUserName: data.customer.name,
            teamId: data.team.id,
            teamName: data.team.team_name,
          });

          loadLocale({
            passedCurrencyCode: getValueOrDefault('currency_code', currencyCode),
            passedLocaleCode: getValueOrDefault('locale_code', localeCode),
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  }, [
    customerToken,
    embedJwt,
    dashboardEmbedId,
    dispatch,
    localeCode,
    versionNumber,
    environment,
    currencyCode,
    embedType,
  ]);

  const analyticsReady = useSetupAnalytics({
    pageViewEvent: getPageViewType(embedType),
    environment: environment as string | undefined,
    embedType,
    isProduction,
    analyticsProperties,
  });

  const onLoad = () => {
    sendInitialPageView();
    fetchDashboardData();
  };
  useEffect(onLoad, [
    dashboardEmbedId,
    customerToken,
    embedJwt,
    embeddedVariables,
    sendInitialPageView,
    fetchDashboardData,
  ]);

  const defaultVariables = useMemo(
    () => ({ ...urlVariables, ...embeddedVariables }),
    [urlVariables, embeddedVariables],
  );

  const hiddenElementSet = useMemo(() => new Set(hiddenElements), [hiddenElements]);

  useEffect(() => {
    dispatch(setHiddenElements(defaultVariables));
  }, [dispatch, defaultVariables]);

  const dashboardElements = useMemo(
    () => filterHiddenElements(dashboardConfig?.elements, hiddenElementSet),
    [dashboardConfig?.elements, hiddenElementSet],
  );

  const dataPanels = useMemo(
    () => Object.values(filterHiddenPanels(dashboardConfig?.data_panels, hiddenElementSet)),
    [dashboardConfig?.data_panels, hiddenElementSet],
  );

  const isVisible = UsePageVisibility();

  const dashboardTimezone = getTimezone(
    dashboardData?.default_timezone,
    getValueOrDefault('timezone', passedTimezone),
  );

  const disableEditingEditableSection = useMemo(
    () =>
      isVariableTrue(
        getValueOrDefault('disable-editable-section-editing', disableEditableSectionEditing),
      ),
    [disableEditableSectionEditing],
  );

  const shouldHideEditableSection = useMemo(
    () => isVariableTrue(getValueOrDefault('hide-editable-section', hideEditableSection)),
    [hideEditableSection],
  );

  const requestInfo: DashboardLayoutRequestInfo | undefined = useMemo(() => {
    if (!dashboardVersionNumber) return;
    return {
      type: 'embedded',
      embedType,
      resourceEmbedId: dashboardEmbedId,
      versionNumber: dashboardVersionNumber,
      timezone: dashboardTimezone,
      useJobQueue: team?.feature_flags?.use_job_queue ?? false,
      customerToken,
      jwt: embedJwt,
      environment: environment as string | undefined,
      useFido: team?.feature_flags?.use_fido ?? false,
    };
  }, [
    dashboardEmbedId,
    dashboardVersionNumber,
    dashboardTimezone,
    team,
    customerToken,
    embedJwt,
    environment,
    embedType,
  ]);

  // Update the width when the container changes being in view
  useEffect(() => {
    if (width === observer?.boundingClientRect.width) return;

    setWidth(observer?.boundingClientRect.width ?? null);
  }, [width, observer?.boundingClientRect.width]);

  const calculatedViewMode = useMemo(
    () =>
      viewMode !== VIEW_MODE.EMAIL &&
      viewMode !== VIEW_MODE.PDF &&
      width &&
      width < MOBILE_BREAKPOINT_WIDTH
        ? VIEW_MODE.MOBILE
        : viewMode,
    [viewMode, width],
  );

  const interactionsInfo = useDashboardInteractionsInfo({
    viewMode: calculatedViewMode,
    updateUrlParams: shouldUseUrlParams(embedType, updateUrlParams),
    disableFiltersWhileLoading: dashboardData?.disable_filters_while_loading,
    disableInputs: isStrict,
    supportEmail: team?.support_email ?? undefined,
    disableEditingEditableSection,
  });

  const hasError = !dashboardData || !dashboardConfig || !requestInfo || !customer;
  const didDashboardError = RD.isError(dashboard);
  const isLoading = isDashboardLoading || !analyticsReady;

  useEffect(() => {
    if (hasError || didDashboardError || isLoading) return;
    sendDashboardReadyToLoadEvent(embedType === 'iframe');
  }, [hasError, isLoading, didDashboardError, embedType]);

  if (didDashboardError) {
    return (
      <div className={errorMessageStyle} role="alert">
        {dashboard.error}
      </div>
    );
  } else if (isLoading) {
    return <EmbedSpinner fillContainer size="xl" style={{ height: '100vh' }} />;
  } else if (hasError) {
    throw Error(
      'There was an error loading the dashboard. Please contact your support team for help.',
    );
  }

  const shouldFillViewport =
    dashboardConfig.dashboard_page_layout_config?.stickyHeader?.enabled &&
    (embedType === 'iframe' || embedType === 'shared');

  const isEditableSectionEnabled =
    team?.entitlements.enable_editable_section &&
    dashboardConfig.editable_section?.enabled &&
    !shouldHideEditableSection;

  return (
    <div
      className={cx(
        sprinkles({ height: shouldFillViewport ? 'fillViewport' : 'fill' }),
        globalStylesClassName,
      )}
      id={embedType === 'embedded' ? undefined : 'shared-explo-dashboard'}
      ref={containerRef}>
      <DashboardLayout
        isViewOnly
        archetypeProperties={team?.archetype_properties}
        customer={customer}
        dashboardElements={dashboardElements}
        dashboardLayout={getLayoutFromDashboardVersionConfig(
          dashboardConfig,
          interactionsInfo.viewMode,
        )}
        dataPanels={dataPanels}
        datasets={dashboardConfig.datasets}
        isEditableSectionEnabled={isEditableSectionEnabled}
        isVisible={isVisible}
        pageLayoutConfig={dashboardConfig.dashboard_page_layout_config}
        pageType={embedType === 'shared' ? PAGE_TYPE.SHARED : PAGE_TYPE.EMBEDDED}
        params={dashboardConfig.params}
        refreshMinutes={getRefreshMinutes(refreshMinutes)}
        requestInfo={requestInfo}
        resourceId={dashboardData.id}
        showExploBranding={showExploBranding(team?.payment_plan)}
        variablesDefaultValues={defaultVariables}
        width={width}
      />
      <ResizeObserver onResize={(resize) => setWidth(resize.width)} />
    </div>
  );
};

function getPageViewType(embedType: EmbeddedDashboardType) {
  switch (embedType) {
    case 'shared':
      return REPORTED_ANALYTIC_ACTION_TYPES.SHARED_DASHBOARD_PAGE_VIEWED;
    case 'portal':
      return REPORTED_ANALYTIC_ACTION_TYPES.PORTAL_DASHBOARD_PAGE_VIEWED;
    default:
      return REPORTED_ANALYTIC_ACTION_TYPES.DASHBOARD_PAGE_VIEWED;
  }
}

const errorMessageStyle = sprinkles({
  margin: 'sp2',
  padding: 'sp2',
  heading: 'h1',
  borderRadius: 8,
  backgroundColor: 'errorSubdued',
});

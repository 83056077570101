import { useEffect } from 'react';
import axios from 'axios';

import { PAGE_EVENTS, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { getEnvironment } from './environmentUtils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AllStates } from 'reducers/rootReducer';
import getFingerprintUser from 'analytics/fingerprint';
import {
  clearAnalyticsSlice,
  setVisitorId,
  AnalyticsEmbedType,
  setAnalyticsVars,
} from 'reducers/analyticsReducer';
import { DashboardVariable } from 'types/dashboardTypes';

interface PostData {
  event_name: REPORTED_ANALYTIC_ACTION_TYPES;
  user_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_properties?: any;
}

const sendAnalyticsEvent = (
  eventType: REPORTED_ANALYTIC_ACTION_TYPES,
  userId: string,
  analyticsToken: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_properties?: any,
) => {
  const postData: PostData = {
    event_name: eventType,
    user_id: userId,
    properties,
    custom_properties,
  };

  const isPageEvent = PAGE_EVENTS.has(eventType);

  const url = isPageEvent ? 'page_event' : 'track_event';

  sendAnalyticsEventHelper(url, postData, analyticsToken);
};

type DashboardInfo =
  | { dashboard_template_id: number; dashboard_template_name: string }
  | { blueprint_id: number; blueprint_name: string }
  | { hub_id: number; hub_name: string };

export type AnalyticsMetadata = {
  team_id: number;
  team_name: string;
  customer_id: number;
  customer_name: string;
  customer_provided_id: string;
  customer_is_demo: boolean;
} & DashboardInfo;

export type Metadata = Record<string, number | string | null | undefined>;

export const sendAnalyticsEventWrapper = (
  userId: string,
  dashboardType: string,
  embedSource: string,
  analyticsToken: string | null | undefined,
  eventName: REPORTED_ANALYTIC_ACTION_TYPES,
  analyticsMetadata: AnalyticsMetadata | undefined,
  metadata: Metadata | undefined,
  customProperties: Metadata | undefined,
  environment: string | undefined,
) => {
  const properties = {
    dashboard_type: dashboardType,
    embed_source: embedSource,
    environment,
    ...analyticsMetadata,
    ...metadata,
  };

  sendAnalyticsEvent(eventName, userId, analyticsToken, properties, customProperties);
};

export const isValidHttpUrl = (s: string) => {
  let url;

  try {
    url = new URL(s);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const sendAnalyticsEventHelper = (
  url: string,
  postData: PostData,
  analytics_token: string | null | undefined,
) => {
  // only send off analytics events in production
  if (process.env.REACT_APP_ENVIRONMENT && getEnvironment() === 'production')
    axios({
      url: `${process.env.REACT_APP_API_URL}analytics/${url}/`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Analytics-Token': analytics_token,
      },
      data: postData,
    }).catch(() => {
      // we don't need to do anything with this error, but we need to catch
      // so that the page doesn't break if the request fails
      return;
    });
};

export const getEmbedSource = (embedType: EmbeddedDashboardType | 'chart') =>
  embedType === 'shared' || embedType === 'chart' ? 'share' : embedType;

type AnalyticsProps = {
  pageViewEvent: REPORTED_ANALYTIC_ACTION_TYPES;
  environment: string | undefined;
  embedType: AnalyticsEmbedType;
  isProduction?: DashboardVariable;
  isStrict?: boolean;
  analyticsProperties?: Metadata;
};

export function useSetupAnalytics({
  environment,
  embedType,
  pageViewEvent,
  isProduction,
  isStrict,
  analyticsProperties,
}: AnalyticsProps) {
  const dispatch = useDispatch();

  const { analyticsMetadata, analyticsToken, visitorId } = useSelector(
    (state: AllStates) => ({
      analyticsMetadata: state.analytics.analyticsMetadata,
      analyticsToken: state.analytics.analyticsToken,
      visitorId: state.analytics.visitorId,
    }),
    shallowEqual,
  );

  const noAnalyticsNeeded = shouldNotSendAnalytics(embedType);

  useEffect(() => {
    return () => {
      dispatch(clearAnalyticsSlice());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAnalyticsVars({ embedType, environment, analyticsProperties }));
  }, [dispatch, embedType, environment, analyticsProperties]);

  useEffect(() => {
    if (noAnalyticsNeeded || !analyticsMetadata || !analyticsToken) return;
    const sendPageViewAnalytics = async () => {
      const fingerprintUser = await getFingerprintUser();
      dispatch(setVisitorId(fingerprintUser.visitorId));
      sendAnalyticsEvent(
        pageViewEvent,
        fingerprintUser.visitorId,
        analyticsToken,
        {
          ...analyticsMetadata,
          is_strict: isStrict ? { isStrict: true } : undefined,
          is_production: isProduction ? isProduction === 'true' : undefined,
          environment,
          embed_source: getEmbedSource(embedType),
        },
        analyticsProperties,
      );
    };

    sendPageViewAnalytics();
  }, [
    dispatch,
    pageViewEvent,
    analyticsMetadata,
    embedType,
    environment,
    analyticsToken,
    noAnalyticsNeeded,
    isProduction,
    isStrict,
    analyticsProperties,
  ]);

  return noAnalyticsNeeded || visitorId !== null;
}

const allowedAnalyticsSet: Set<AnalyticsEmbedType> = new Set([
  'embedded',
  'iframe',
  'shared',
  'chart',
  'portal',
]);

export function shouldNotSendAnalytics(embedType: AnalyticsEmbedType): boolean {
  return !allowedAnalyticsSet.has(embedType);
}

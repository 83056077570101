import KPIAggregation from '../KPIAggregation';

import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { DatasetSchema } from 'types/datasets';

type Props = {
  instructions: V2KPIChartInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function KPIVizConfig({ instructions, chartType, loading, schema }: Props) {
  return (
    <KPIAggregation
      chartType={chartType}
      instructions={instructions}
      loading={loading}
      schema={schema}
    />
  );
}

import { FC, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { DashboardTextElement } from './dashboardTextElement';
import { DashboardDropdownElement } from './dashboardDropdownElement';
import { DashboardMultiSelectElement } from './dashboardMultiSelectElement';
import { DashboardDatepickerElement } from './dashboardDatepickerElement';
import { DashboardExportElement } from './dashboardExportElement';
import DashboardToggleElement from './dashboardToggleElement';
import { DashboardDateRangePickerElement } from './DashboardDateRangePickerElement';
import { DashboardImageElement } from './DashboardImageElement';
import DashboardDateGroupSwitchElement from './DashboardDateGroupSwitchElement';
import DashboardTimePeriodDropdownElement from './DashboardTimePeriodDropdownElement';
import DashboardSwitchElement from './dashboardSwitchElement';
import { DashboardApplyFilterElement } from './DashboardApplyFilterElement';
import { DashboardTextInputElement } from './dashboardTextInputElement';
import { DashboardIframeElement } from './DashboardIframeElement';
import { DashboardRefreshElement } from './DashboardRefreshElement';
import DashboardContainerElement from './DashboardContainerElement';
import { sprinkles } from 'components/ds';

import {
  DashboardElement,
  TextDashboardElemConfig,
  SelectElemConfig,
  DatepickerElemConfig,
  ExportElemConfig,
  ContainerElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  ImageElemConfig,
  DateGroupToggleConfig,
  VIEW_MODE,
  TimePeriodDropdownElemConfig,
  SwitchElementConfig,
  ApplyFilterElemConfig,
  TextInputElemConfig,
  DateRangePickerElemConfig,
  IframeElemConfig,
  DashboardVariable,
  DashboardVariableMap,
  DashboardButtonElemConfig,
} from 'types/dashboardTypes';
import { PassedProps as ElementGridLayoutProps } from 'components/DashboardLayout/ElementGridLayout';
import { isElemDisabledByDependency } from 'utils/dashboardUtils';
import { getSelectFilterDatasetId } from 'utils/filterUtils';
import { DashboardStates } from 'reducers/rootReducer';
import { VariableSelectOptions } from 'utils/extraVariableUtils';
import { sendVariableUpdatedEvent } from 'utils/customEventUtils';
import { setVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { getAreFiltersDisabled } from 'reducers/selectors';
import { getIsIframe } from 'reducers/dashboardLayoutReducer';

type Props = {
  dashboardElement: DashboardElement;
  datasetNamesToId: Record<string, string>;
  elementNamesById: Record<string, string>;
  elementGridLayoutProps?: ElementGridLayoutProps;
  elementStartsOnRightSide: boolean;
  isInContainer?: boolean;
  isMobileView?: boolean;
  isResizing?: boolean;
  editableDashboard: boolean;
  variables: DashboardVariableMap;
};

export const DashboardElementView: FC<Props> = ({
  dashboardElement,
  elementNamesById,
  elementGridLayoutProps,
  elementStartsOnRightSide,
  variables,
  isResizing,
  isMobileView,
  editableDashboard,
  datasetNamesToId,
  isInContainer,
}) => {
  const dispatch = useDispatch();

  const { requestInfo, viewMode, blockedVariables, disableInputs, datasetData, isIframe } =
    useSelector(
      (state: DashboardStates) => ({
        requestInfo: state.dashboardLayout.requestInfo,
        viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
        blockedVariables: state.dashboardData.blockedVariables,
        disableInputs: getAreFiltersDisabled(state),
        datasetData: state.dashboardData.datasetData,
        isIframe: getIsIframe(state.dashboardLayout),
      }),
      shallowEqual,
    );
  const { timezone } = requestInfo;

  const value =
    dashboardElement.id in blockedVariables
      ? blockedVariables[dashboardElement.id]
      : variables[dashboardElement.name];

  const onNewValueSelect = useCallback(
    (newValue: DashboardVariable, options?: VariableSelectOptions) => {
      sendVariableUpdatedEvent(isIframe, dashboardElement.name, newValue);
      dispatch(
        setVariableThunk({
          varName: dashboardElement.name,
          value: newValue,
          elementId: dashboardElement.id,
          options,
        }),
      );
    },
    [dispatch, isIframe, dashboardElement.name, dashboardElement.id],
  );

  const elemDisabled = isElemDisabledByDependency(
    dashboardElement.config,
    variables,
    elementNamesById,
  );
  const areInputsDisabled = disableInputs || elemDisabled;

  const renderElement = () => {
    switch (dashboardElement.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <DashboardTextElement
            config={dashboardElement.config as TextDashboardElemConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SPACER:
        return <div className={editableDashboard ? editableSpacerClass : undefined} />;
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        return (
          <DashboardDropdownElement
            config={dashboardElement.config as SelectElemConfig}
            datasetData={datasetData}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        return (
          <DashboardTimePeriodDropdownElement
            config={dashboardElement.config as TimePeriodDropdownElemConfig}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value as number}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        return (
          <DashboardMultiSelectElement
            config={dashboardElement.config as SelectElemConfig}
            datasetData={datasetData}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
            variables={variables}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
        return (
          <DashboardDatepickerElement
            config={dashboardElement.config as DatepickerElemConfig}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            timezone={timezone}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        return (
          <DashboardDateRangePickerElement
            isEmbed
            config={dashboardElement.config as DateRangePickerElemConfig}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            timezone={timezone}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <DashboardExportElement
            config={dashboardElement.config as ExportElemConfig}
            exportVars={variables}
            isSharedView={viewMode === VIEW_MODE.SHARE}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <DashboardImageElement
            config={dashboardElement.config as ImageElemConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            variables={variables}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.TOGGLE: {
        const config = dashboardElement.config as SelectElemConfig;
        let loading = false;
        const filterDatasetId = getSelectFilterDatasetId(config);
        if (filterDatasetId) {
          const data = datasetData[filterDatasetId];
          loading = data ? data.loading || !data.rows : true;
        }
        return (
          <DashboardToggleElement
            config={config}
            datasetData={datasetData}
            disabled={areInputsDisabled}
            loading={loading}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      }
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        return (
          <DashboardDateGroupSwitchElement
            config={dashboardElement.config as DateGroupToggleConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        return (
          <DashboardSwitchElement
            config={dashboardElement.config as SwitchElementConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return elementGridLayoutProps ? (
          <DashboardContainerElement
            config={dashboardElement.config as ContainerElemConfig}
            elementGridLayoutProps={elementGridLayoutProps}
            id={dashboardElement.id}
            isMobileView={isMobileView}
            isResizing={!!isResizing}
            viewMode={viewMode}
          />
        ) : null;
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        return (
          <DashboardApplyFilterElement config={dashboardElement.config as ApplyFilterElemConfig} />
        );
      case DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON:
        return (
          <DashboardRefreshElement config={dashboardElement.config as DashboardButtonElemConfig} />
        );
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        return (
          <DashboardTextInputElement
            config={dashboardElement.config as TextInputElemConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IFRAME:
        return (
          <DashboardIframeElement
            config={dashboardElement.config as IframeElemConfig}
            variables={variables}
          />
        );
    }
  };

  return <div className={sprinkles({ parentContainer: 'fill' })}>{renderElement()}</div>;
};

const editableSpacerClass = sprinkles({
  height: 'fill',
  border: 2,
  borderColor: 'outline',
  borderRadius: 4,
});

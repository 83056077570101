import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'utils/standard';

import { BooleanToggle, Select } from 'components/ds';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, YAxisFormat } from 'constants/types';
import { YAxisConfig } from './YAxisConfig';
import {
  updateYAxisFormat,
  getAggColDisplayName,
  getYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  yAxisFormatId?: string;
};

/**
 * This component is used to display and set the YAxisOptions for charts that can format multiple Y-Axes
 * It has some extra options that don't make sense on a single Y Axis config such as matching the visualization color
 */
export const MultiYAxisConfig: FC<Props> = ({ visualizationType, instructions, yAxisFormatId }) => {
  const dispatch = useDispatch();

  const yAxisFormat = getYAxisFormat(yAxisFormatId, instructions);

  const getColorSelectedItem = () => {
    const mappedAggColumn = (instructions.aggColumns || []).find((aggColumn) =>
      isEqual(aggColumn, yAxisFormat?.colorFromAggColumn),
    );
    if (!mappedAggColumn) return undefined;
    return `${mappedAggColumn.agg.id}-${mappedAggColumn.column.name}`;
  };

  const updateYAxis = (updates: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...updates };
    dispatch(
      updateVisualizeOperation(updateYAxisFormat(newYAxisFormat, instructions), visualizationType),
    );
  };

  return (
    <div className={configRootClass}>
      <YAxisConfig
        instructions={instructions}
        visualizationType={visualizationType}
        yAxisFormatId={yAxisFormatId}
      />
      <Select
        className={configInputClass}
        label="Match Axis Color"
        onChange={(newItem) => {
          const aggColumn = (instructions.aggColumns || []).find((aggColumn) => {
            // newItem.id will always be "aggType-aggColumn"
            const idParts = newItem.split('-');
            return idParts[0] === aggColumn.agg.id && idParts[1] === aggColumn.column.name;
          });
          if (!aggColumn) return;
          updateYAxis({ colorFromAggColumn: aggColumn });
        }}
        placeholder="Select Axis Color"
        selectedValue={getColorSelectedItem()}
        values={(instructions.aggColumns ?? []).map((aggColumn) => {
          return {
            value: `${aggColumn.agg.id}-${aggColumn.column.name}`,
            label: getAggColDisplayName(aggColumn),
          };
        })}
      />
      <BooleanToggle
        className={configInputClass}
        falseText="Left"
        label="Axis placements"
        onValueChange={(oppositeAligned) => updateYAxis({ oppositeAligned })}
        selectedValue={!!yAxisFormat?.oppositeAligned}
        trueText="Right"
      />
    </div>
  );
};

import { FC } from 'react';

import { Select, sprinkles } from 'components/ds';
import { SortOrderToggle } from 'components/SortDirectionToggles';

import { DefaultSortColumn, SortOrder } from 'constants/types';

type Props = {
  label: string;
  order: SortOrder;
  selectedCol: string | undefined;
  sortOptions: { value: string; label: string }[];

  updateDefaultSort: (sortColumn: DefaultSortColumn) => void;
};

export const ColumnSortingConfig: FC<Props> = ({
  label,
  order,
  selectedCol,
  sortOptions,
  updateDefaultSort,
}) => {
  return (
    <div className={rootClass}>
      <Select
        className={sprinkles({ flex: 1 })}
        label={label}
        onChange={(newValue) => updateDefaultSort({ column: newValue })}
        placeholder="Select column"
        selectedValue={selectedCol}
        values={sortOptions}
      />
      <SortOrderToggle
        order={order}
        updateOrder={(newOrder) => updateDefaultSort({ order: newOrder })}
      />
    </div>
  );
};

const rootClass = sprinkles({
  marginTop: 'sp1.5',
  marginBottom: 'sp1',
  display: 'flex',
  alignItems: 'flex-end',
  gap: 'sp1',
});

import { FC, useMemo, useState } from 'react';
import { sortBy } from 'utils/standard';

import { DraggableColumn } from './DraggableColumn';
import { Input, sprinkles } from 'components/ds';

import { DatasetSchema } from 'types/datasets';

type Props = {
  schema: DatasetSchema;
  loading?: boolean;
};

export const VisualizationConfigTableColumns: FC<Props> = ({ schema, loading }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const isSearching = !!searchQuery.trim();

  const filteredSchemas = useMemo(() => {
    if (!isSearching) return sortBy(schema, 'name');
    const loweredSearch = searchQuery.toLowerCase();
    return sortBy(
      schema.filter((col) => col.name.toLowerCase().includes(loweredSearch)),
      'name',
    );
  }, [schema, isSearching, searchQuery]);

  return (
    <div
      className={sprinkles({ paddingX: 'sp.5', flexItems: 'column' })}
      style={{ minHeight: 200 }}>
      <Input
        leftIcon="search"
        onChange={setSearchQuery}
        placeholder="Search..."
        value={searchQuery}
      />
      <div className={sprinkles({ overflowY: 'scroll' })}>
        {filteredSchemas.map((col) => (
          <DraggableColumn
            disableDrag={loading}
            displayName={
              isSearching ? col.name.replaceAll(searchQuery, `<b>${searchQuery}</b>`) : col.name
            }
            key={col.name}
            name={col.name}
            type={col.type}
          />
        ))}
      </div>
    </div>
  );
};

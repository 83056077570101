import { FC } from 'react';
import { map } from 'utils/standard';
import cx from 'classnames';
import * as RD from 'remotedata';

import { ConnectDataSourceContainer } from 'pages/ConnectDataSourceFlow/ConnectDataSourceContainer';
import InputWithTag from 'shared/InputWithTag/InputWithTag';
import DropdownSelect from 'shared/DropdownSelect';
import { sprinkles, Checkbox, Spinner } from 'components/ds';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { DataSource, ParentSchema } from 'actions/dataSourceActions';
import { AccessGroup } from 'actions/teamActions';
import { getStatusInfo } from '../utils';
import * as styles from './shared/CredentialsSection.css';

type Props = {
  disabled?: boolean;
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  parentSchemas: RD.ResponseData<ParentSchema[]>;
  selectedSchema?: ParentSchema;
  setSelectedSchema?: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
  accessGroups?: AccessGroup[];
  selectedAccessGroupIds: number[];
  setSelectedAccessGroupIds: (accessGroupIds: number[]) => void;
  placeholderName?: string;
  placeholderProvidedId?: string;
  headerClassName?: string;
  isEditing?: boolean;
};

export const GettingStarted: FC<Props> = ({ config, parentSchemas, onNextClicked, ...props }) => {
  return (
    <ConnectDataSourceContainer
      bodyContent={
        !RD.isSuccess(parentSchemas) ? (
          <Spinner fillContainer />
        ) : (
          <GettingStartedBody {...props} config={config} parentSchemas={parentSchemas.data} />
        )
      }
      currentStep={ConnectDataSourceStep.GETTING_STARTED}
      headerSubtitle="Choose how you'd like to reference your database in Explo. You can change this later on"
      headerTitle="Getting started"
      primaryActionConfig={{
        text: 'Next',
        disabled:
          !config.name ||
          !config.providedId ||
          !props.selectedSchema ||
          !!config.providedIdError ||
          props.selectedAccessGroupIds.length === 0,
        onClick: onNextClicked,
      }}
    />
  );
};

type BodyProps = Omit<Props, 'onNextClicked' | 'parentSchemas'> & {
  parentSchemas?: ParentSchema[];
};

export const GettingStartedBody: FC<BodyProps> = ({
  disabled,
  config,
  updateConfig,
  parentSchemas,
  selectedSchema,
  setSelectedSchema,
  existingDataSources,
  accessGroups,
  selectedAccessGroupIds,
  setSelectedAccessGroupIds,
  placeholderName,
  placeholderProvidedId,
  headerClassName,
  isEditing,
}) => {
  const existingDSProvidedIds = new Set(map(existingDataSources, 'provided_id'));

  const displayName = config.name === undefined ? placeholderName ?? '' : config.name;

  const displayProvidedId =
    config.providedId === undefined ? placeholderProvidedId ?? '' : config.providedId;

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
      <div className={inputRowClass}>
        <InputWithTag
          className={leftInputClass}
          data-testid="connect-datasource-database-nickname"
          disabled={disabled}
          helpText="This is how we'll refer to your database in Explo."
          label="Nickname"
          onChange={(newName) => {
            if (!isEditing) {
              const oldGeneratedId = generateProvidedId(config.name ?? '');
              const providedId = config.providedId ?? '';
              const newProvidedId =
                oldGeneratedId === providedId ? generateProvidedId(newName) : providedId;

              updateConfig({
                ...config,
                name: newName,
                providedId: newProvidedId,
                providedIdError:
                  existingDSProvidedIds.has(newProvidedId) &&
                  placeholderProvidedId !== newProvidedId
                    ? 'An existing data source has this ID'
                    : undefined,
              });
            } else {
              updateConfig({ ...config, name: newName });
            }
          }}
          placeholder={!isEditing ? 'Explo DB' : ''}
          statusInfo={getStatusInfo(
            config.name === undefined && placeholderName === undefined,
            displayName.length > 0,
          )}
          value={displayName}
        />
        <InputWithTag
          className={sprinkles({ width: 'fill' })}
          disabled={disabled}
          helpText="This is how you'll reference the database when setting up user groups."
          label="ID"
          onChange={(value) => {
            updateConfig({
              ...config,
              providedId: value,
              providedIdError:
                existingDSProvidedIds.has(value) && placeholderProvidedId !== value
                  ? 'An existing data source has this provided ID'
                  : undefined,
            });
          }}
          placeholder={!isEditing ? 'explo-db' : ''}
          statusInfo={getStatusInfo(
            config.providedId === undefined &&
              (placeholderProvidedId === undefined || placeholderProvidedId === null),
            displayProvidedId.length > 0,
            config.providedIdError,
          )}
          value={displayProvidedId}
        />
      </div>
      {setSelectedSchema ? (
        <div className={inputRowClass}>
          <DropdownSelect
            fillWidth
            ignoreCustomStyles
            minimal
            containerClassName={leftInputClass}
            createItemPlaceholderText="Enter new schema name"
            createItemText="Create a new schema"
            dataTestid="connect-datasource-dropdown-select-schema"
            disableOnNoItems={false}
            disabled={disabled}
            filterable={false}
            label="Schema"
            labelHelpText="All data sources in a schema should have the same underlying database tables"
            noSelectionText="Select a schema"
            onChange={(item) => {
              const itemId = parseInt(item.id);
              const selectedSchema = parentSchemas?.find((schema) => schema.id === itemId);
              if (selectedSchema) setSelectedSchema(selectedSchema);
            }}
            onCreateItem={(newSchemaName) => {
              if (newSchemaName) setSelectedSchema({ name: newSchemaName, id: -1 }, true);
            }}
            options={(parentSchemas || []).map((schema) => ({
              id: schema.id.toString(),
              name: schema.name,
            }))}
            selectedItem={
              selectedSchema && {
                id: selectedSchema.id.toString(),
                name: selectedSchema.name,
              }
            }
          />
        </div>
      ) : null}
      {accessGroups && accessGroups.length > 1 ? (
        <>
          <div
            className={
              isEditing
                ? cx(sprinkles({ marginBottom: 'sp3', marginTop: 'sp7' }), headerClassName)
                : sprinkles({ color: 'gray12', heading: 'h4' })
            }>
            Set Data Visibility
          </div>
          <div className={sprinkles({ color: 'gray11', body: 'b2' })}>
            {selectedSchema?.id === -1
              ? "Since this is a new schema, this database will become the default data source for any visibility groups it's part of. Defaults can be changed in the manage schemas modal in the data sources page."
              : 'This data source will become part of the selected visibility groups.'}
          </div>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
            {accessGroups.map((accessGroup) => {
              const isChecked = selectedAccessGroupIds.includes(accessGroup.id);
              return (
                <div className={styles.accessGroupOption} key={accessGroup.id}>
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => {
                      const newAccessGroupIds = isChecked
                        ? selectedAccessGroupIds.filter((id) => id !== accessGroup.id)
                        : [...selectedAccessGroupIds, accessGroup.id];
                      setSelectedAccessGroupIds(newAccessGroupIds);
                    }}
                  />
                  <div>{accessGroup.name}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

const generateProvidedId = (name: string) => name.toLowerCase().replace(/(\s+)/g, '-');

const inputRowClass = sprinkles({ flexItems: 'alignCenterBetween' });
const leftInputClass = sprinkles({ width: 'fill', marginRight: 'sp1.5' });

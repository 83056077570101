import { FC } from 'react';
import * as RD from 'remotedata';

import { FormattedQueryText } from './FormattedQueryText';

import { Icon, Button, Spinner } from 'components/ds';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './QuerySuggestion.css';
import { fetchAiSuggestion } from 'reducers/thunks/aiThunks';
import { ReduxState } from 'reducers/rootReducer';
import { EVENTS, trackEvent } from 'analytics/exploAnalytics';

type Props = {
  query: string;
  error?: string;
  onRun: () => void;
};

export const QuerySuggestion: FC<Props> = ({ query, error, onRun }) => {
  const { suggestion, enableAi, team } = useSelector(
    (state: ReduxState) => ({
      suggestion: state.ai.suggestion,
      enableAi: state.currentUser.team?.entitlements.enable_ai,
      team: state.teamData.data,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  if (!enableAi) return null;

  const renderRunButton = (buttonText: string) => (
    <Button
      className={styles.button}
      icon="wand"
      onClick={() => {
        onRun();
        trackEvent(error ? EVENTS.AI_DEBUGGED_QUERY : EVENTS.AI_OPTIMIZED_QUERY, {
          team_id: team?.id,
          team_name: team?.team_name,
        });
        dispatch(fetchAiSuggestion({ query, error }));
      }}
      tooltipProps={{
        text: 'Depending on the length of your query, this could take up to 30 seconds',
      }}>
      {buttonText}
    </Button>
  );

  const renderBody = () => {
    if (RD.isLoading(suggestion)) return <Spinner className={styles.spinner} size="lg" />;

    if (RD.isIdle(suggestion))
      return renderRunButton(error ? 'Resolve error with AI' : 'Optimize query with AI');

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon className={styles.icon} name="wand" />
          {error ? 'Error resolution suggestion' : 'Query optimization suggestion'}
        </div>
        {RD.isSuccess(suggestion) ? (
          <FormattedQueryText explanation={suggestion.data.explanation} />
        ) : null}
        {RD.isError(suggestion) ? renderRunButton('Try again') : null}
      </div>
    );
  };

  return <div className={styles.root}>{renderBody()}</div>;
};

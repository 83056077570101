import { FC } from 'react';
import { camelCase } from 'utils/standard';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import * as RadixLabel from '@radix-ui/react-label';
import { Icon, sprinkles } from 'components/ds';
import * as styles from './index.css';

export type Props = {
  disabled?: boolean;
  isChecked: boolean;
  label?: string;
  isIndeterminate?: boolean;
  onChange: () => void;
};

export const Checkbox: FC<Props> = ({ disabled, isChecked, isIndeterminate, onChange, label }) => {
  const name = label ? camelCase(label) : '';

  const checkbox = (
    <RadixCheckbox.Root
      checked={isChecked}
      className={styles.checkbox}
      disabled={disabled}
      id={name}
      onCheckedChange={onChange}>
      <RadixCheckbox.Indicator>
        <Icon name={isIndeterminate ? 'horizontal-rule' : 'check'} />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );

  if (!label) return checkbox;

  return (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1.5' })}>
      {checkbox}
      <CheckboxLabel htmlFor={name} label={label} />
    </div>
  );
};

type LabelProps = { label: string; htmlFor: string };

const CheckboxLabel: FC<LabelProps> = ({ label, htmlFor }) => {
  return (
    <RadixLabel.Root
      className={sprinkles({ body: 'b2', color: 'contentPrimary' })}
      htmlFor={htmlFor}>
      {label}
    </RadixLabel.Root>
  );
};

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { map } from 'utils/standard';
import { createStyles, Theme } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Menu } from '@blueprintjs/core';
import sqlFormatter from 'sql-formatter';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
import { usePrevious } from '@radix-ui/react-use-previous';

import {
  IconButton,
  Icon,
  Spinner,
  sprinkles,
  vars,
  APP_PORTAL_ID,
  Modal,
  Tabs,
  AlertModal,
  Select,
} from 'components/ds';
import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import { DatasetModal } from 'components/DatasetModal';
import { DrilldownDatasetColumns } from './DrilldownDatasetColumns';
import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import { SqlEditor } from 'components/SqlEditor';
import { SchemaViewer } from 'components/resource/SchemaViewer';
import { DrilldownDatasetColumnsFormatConfig } from './DrilldownDatasetColumnsFormatConfig';
import { InfoCard } from 'components/InfoCard';
import { DatasetPreview } from './DatasetPreview';
import { QueryRuntime } from '../DashboardDebugger/QueryRuntime';
import * as sharedStyles from './styles.css';

import { Dataset, DatasetData } from 'actions/datasetActions';
import { ParentSchema } from 'actions/dataSourceActions';
import {
  datasetEditorShowsDatasetsAsDropdown,
  datasetEditorShowsDatasetsAsList,
} from 'constants/dataPanelEditorConstants';
import { getDatasetName } from 'utils/naming';
import { isDatasetInUse } from 'utils/dashboardUtils';
import { ReduxState } from 'reducers/rootReducer';
import {
  SELECT_DATASET_FOR_DRILLDOWN_FORMATTING,
  DrilldownDatasetId,
} from 'utils/customEventUtils';
import { isQueryDependentOnVariable } from 'utils/variableUtils';
import { DatasetSchema } from 'types/datasets';
import { makeStyles } from '@material-ui/styles';
import { QuerySuggestion } from '../DashboardDebugger/QuerySuggestion';
import { DATASET_EDITOR_MODE } from 'types/dashboardTypes';
import { getSchemaTablesMap, getSelectedDpDatasetId } from 'reducers/selectors';
import { setDatasetEditorMode } from 'reducers/dashboardInteractionsReducer';
import { getEditPageDataPanels } from 'reducers/dashboardEditConfigReducer';
import { getSchemaNameInfo } from 'utils/queryUtils';
import { getArchetypeProperties } from 'reducers/selectors';

SyntaxHighlighter.registerLanguage('sql', sql);

const DATASET_LIST_WIDTH = 300;
export const MENU_HEIGHT = 56;

export enum DATASET_VIEWS {
  QUERY = 'Query',
  PREVIEW = 'Preview',
  FORMATTING = 'Formatting',
  DEBUGGER = 'Debugger',
}

const DatasetViewTabs = Object.values(DATASET_VIEWS);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    compactButton: {
      '&.bp3-button': {
        padding: `${vars.spacing['sp.5']}px ${vars.spacing['sp1']}px`,
      },
    },
    editor: {
      height: '100%',
      width: `calc(100% - ${DATASET_LIST_WIDTH}px)`,
    },
    editorTopBar: {
      borderBottom: `1px solid ${vars.colors.gray7}`,
      height: MENU_HEIGHT,
    },
    datasetDropdown: {
      width: `calc(100% - 80px)`,
    },
    editorContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
    },
    datasetListContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      overflowY: 'auto',
    },
    warningHeader: {
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      marginTop: `${theme.spacing(2)}px`,
    },
    queryDebugger: {
      margin: vars.spacing['sp1.5'],
      padding: vars.spacing['sp1'],
      overflow: 'auto',
      width: `calc(100% - ${vars.spacing['sp3']}px)`,
      backgroundColor: vars.colors.gray3,
      borderRadius: 4,

      '& pre': {
        backgroundColor: 'initial !important',
        marginTop: 0,
      },
    },
    queryDebuggerInfo: {
      margin: vars.spacing['sp1.5'],
      marginBottom: vars.spacing['sp1.5'],
      height: 'fit-content',
    },
  }),
);

type Props = {
  activeQuery: string;
  activeDatasetData: DatasetData | null;
  activeDatasetConfig: Dataset | null;
  activeDatasetSchema: DatasetSchema | null;
  // This is the saved schema which should be used for drilldown configuration
  activeDatasetSavedSchema: DatasetSchema | null;
  datasetConfigs: Record<string, Dataset>;
  fetchData: (query: string, pageNumber?: number) => void;
  onCreate: (name: string, parentSchemaId: number) => void;
  onDelete: (datasetId: string) => void;
  onEditName: (name: string) => void;
  onSave: (query: string) => void;
  onSaveDraft: (query: string | undefined) => void;
  onSelectSchema: (schemaId: number) => void;
  pageWidth: number | null;
  parentSchemas: ParentSchema[];
  selectedDatasetId: string | null;
  setSelectedDatasetId: (datasetId: string) => void;
};

const formatQuery = (query: string) => sqlFormatter.format(query, { indent: '    ' });

export const DashboardDatasetEditor: FC<Props> = ({
  activeQuery,
  activeDatasetData,
  activeDatasetConfig,
  activeDatasetSchema,
  activeDatasetSavedSchema,
  datasetConfigs,
  fetchData,
  onCreate,
  onDelete,
  onEditName,
  onSave,
  onSaveDraft,
  onSelectSchema,
  pageWidth,
  parentSchemas,
  selectedDatasetId,
  setSelectedDatasetId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editorView, setEditorView] = useState(DATASET_VIEWS.QUERY);
  const [createDatasetModalOpen, setCreateDatasetModalOpen] = useState(false);
  const [currentQuery, setCurrentQuery] = useState(activeQuery);
  const [editDatasetId, setEditDatasetId] = useState<string | null>(null);
  const [deleteDatasetId, setDeleteDatasetId] = useState<string | null>(null);
  const [actionLoadingDatasetId, setActionLoadingDatasetId] = useState<string | null>(null);

  const {
    showUnfilteredDatasetFlag,
    elements,
    dataPanels,
    selectedDpDatasetId,
    schemaTablesMap,
    datasetEditorMode,
    datasetData,
    archetypeProperties,
  } = useSelector(
    (state: ReduxState) => ({
      showUnfilteredDatasetFlag: state.currentUser.team?.feature_flags.show_unfiltered_dataset_flag,
      elements: state.dashboardEditConfig.config?.elements,
      dataPanels: getEditPageDataPanels(state.dashboardEditConfig, true),
      selectedDpDatasetId: getSelectedDpDatasetId(state),
      schemaTablesMap: getSchemaTablesMap(state),
      datasetEditorMode: state.dashboardInteractions.datasetEditorMode,
      datasetData: state.dashboardEditConfig.datasetData,
      archetypeProperties: getArchetypeProperties(state),
    }),
    shallowEqual,
  );
  const isOpen = datasetEditorMode !== DATASET_EDITOR_MODE.COLLAPSED;

  // on mount/unmount
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    // @ts-ignore
    window.addEventListener(
      SELECT_DATASET_FOR_DRILLDOWN_FORMATTING,
      selectDatasetForDrilldownFormatting,
    );

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      // @ts-ignore
      window.removeEventListener(
        SELECT_DATASET_FOR_DRILLDOWN_FORMATTING,
        selectDatasetForDrilldownFormatting,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevActiveQuery = usePrevious(activeQuery);
  useEffect(() => {
    if (prevActiveQuery !== activeQuery) {
      setCurrentQuery(activeQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuery]);

  const getFormattingPreview = useCallback(() => {
    // Check there is a quey no data has been requested
    if (!activeDatasetConfig?.query || activeDatasetData) return;

    fetchData(activeDatasetConfig.query);
  }, [activeDatasetConfig, activeDatasetData, fetchData]);

  useEffect(() => {
    if (editorView !== DATASET_VIEWS.FORMATTING) return;
    getFormattingPreview();
  }, [editorView, activeDatasetConfig, getFormattingPreview]);

  const toggleIsExpanded = useCallback(() => {
    dispatch(
      setDatasetEditorMode(
        datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED
          ? DATASET_EDITOR_MODE.DEFAULT
          : DATASET_EDITOR_MODE.EXPANDED,
      ),
    );
  }, [dispatch, datasetEditorMode]);

  const { tableNames, columnNames } = useMemo(
    () => getSchemaNameInfo(schemaTablesMap, activeDatasetConfig?.parent_schema_id?.toString()),
    [schemaTablesMap, activeDatasetConfig?.parent_schema_id],
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!selectedDatasetId) return;

    if (event.metaKey && event.shiftKey && event.key === 'f') {
      formatSqlQuery(currentQuery);
    } else if (event.metaKey && event.key === 'Enter') {
      getTablePreview();
    } else if (event.metaKey && event.shiftKey && event.key === 's') {
      onSave(currentQuery);
    }
  };

  const selectDatasetForDrilldownFormatting = (e: CustomEvent<DrilldownDatasetId>) => {
    if (e.detail.id !== selectedDatasetId) setSelectedDatasetId(e.detail.id);

    setEditorView(DATASET_VIEWS.FORMATTING);
    dispatch(setDatasetEditorMode(DATASET_EDITOR_MODE.EXPANDED));
  };

  const renderCollapseButton = () => {
    return (
      <IconButton
        name={isOpen ? 'chevron-down' : 'chevron-up'}
        onClick={() =>
          dispatch(
            setDatasetEditorMode(
              isOpen ? DATASET_EDITOR_MODE.COLLAPSED : DATASET_EDITOR_MODE.DEFAULT,
            ),
          )
        }
        tooltipProps={{ text: isOpen ? 'Hide Dataset Panel' : 'Show Dataset Panel' }}
      />
    );
  };

  const renderExpandButton = () => {
    return (
      <IconButton
        className={cx(sharedStyles.actionBtnClass, classes.compactButton)}
        name="expand"
        onClick={toggleIsExpanded}
        tooltipProps={{
          text: datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED ? 'Inline' : 'Full screen',
        }}
      />
    );
  };

  const renderSelectDatasetTopBar = () => {
    const dropdownInputs = map(datasetConfigs, (dataset) => ({
      label: getDatasetName(dataset),
      value: dataset.id,
    }));

    return (
      <div
        className={sprinkles({
          backgroundColor: 'white',
          flexItems: 'alignCenterBetween',
          borderBottom: 1,
          borderColor: 'gray7',
          paddingX: 'sp1.5',
        })}
        style={{ height: MENU_HEIGHT }}>
        <div className={classes.datasetDropdown}>
          <Select
            disabled={!isOpen}
            onChange={setSelectedDatasetId}
            placeholder="Select a dataset"
            selectedValue={selectedDatasetId ?? undefined}
            values={dropdownInputs}
          />
        </div>
        <div className={sprinkles({ display: 'flex' })}>
          <IconButton
            className={sharedStyles.actionBtnClass}
            disabled={!isOpen}
            name="plus"
            onClick={() => setCreateDatasetModalOpen(true)}
          />
          {renderCollapseButton()}
        </div>
      </div>
    );
  };

  const renderDatasetList = () => {
    return (
      <div
        className={sprinkles({
          height: 'fill',
          borderRight: 1,
          borderColor: 'gray7',
          backgroundColor: 'white',
        })}
        style={{ width: DATASET_LIST_WIDTH }}>
        {renderDatasetListHeader()}
        {isOpen ? (
          <Menu className={classes.datasetListContainer}>
            {Object.values(datasetConfigs).map((datasetConfig) => renderDatasetItem(datasetConfig))}
          </Menu>
        ) : null}
      </div>
    );
  };

  const renderDatasetListHeader = () => {
    return (
      <div
        className={sprinkles({
          fontWeight: 700,
          flexItems: 'alignCenterBetween',
          padding: 'sp1.5',
          borderBottom: 1,
          borderColor: 'gray7',
        })}
        style={{ height: MENU_HEIGHT }}>
        <div>Datasets</div>
        <div>
          <IconButton
            name="plus"
            onClick={() => {
              setCreateDatasetModalOpen(true);
              if (!isOpen) dispatch(setDatasetEditorMode(DATASET_EDITOR_MODE.DEFAULT));
            }}
          />
        </div>
      </div>
    );
  };

  const renderDatasetItem = (datasetConfig: Dataset) => {
    const datasetName = getDatasetName(datasetConfig);
    const query = datasetConfig.queryDraft ?? datasetConfig.query ?? '';

    const noCustomerFilter = showUnfilteredDatasetFlag
      ? !isQueryDependentOnVariable(archetypeProperties, datasetConfig)
      : false;

    return (
      <DatasetMenuItem
        active={selectedDatasetId === datasetConfig.id}
        draft={query != null && query !== datasetConfig.query}
        error={!!datasetData[datasetConfig.id]?.error}
        key={`dataset-navbar-item-${datasetConfig.id}`}
        loading={actionLoadingDatasetId === datasetConfig.id}
        name={datasetName}
        noCustomerFilter={noCustomerFilter}
        onClick={() => {
          setSelectedDatasetId(datasetConfig.id);
          if (editorView === DATASET_VIEWS.FORMATTING) getFormattingPreview();
        }}
        onDeleteClicked={() => setDeleteDatasetId(datasetConfig.id)}
        onEditClicked={() => setEditDatasetId(datasetConfig.id)}
        used={datasetConfig.id === selectedDpDatasetId}
      />
    );
  };

  const renderCreateDatasetModal = () => (
    <DatasetModal
      buttonTitle="Create"
      defaultParentSchema={parentSchemas.length === 1 ? parentSchemas[0] : undefined}
      errorState={errorWithDatasetNameEntry}
      modalOpen={createDatasetModalOpen}
      onClose={() => setCreateDatasetModalOpen(false)}
      onSubmit={(name, parent_schema_id) => onCreate(name, parent_schema_id)}
      title="Create Dataset Option"
    />
  );

  const errorWithDatasetNameEntry = (newVal?: string) => {
    const currentDatasetNames = Object.values(datasetConfigs).map((datasetConfig) =>
      getDatasetName(datasetConfig, false),
    );

    const editingDatasetName = editDatasetId
      ? getDatasetName(datasetConfigs[editDatasetId], false)
      : undefined;

    if (newVal && currentDatasetNames.includes(newVal) && editingDatasetName !== newVal) {
      return {
        isErrorState: true,
        errorMsg:
          'There is already a dataset with this name. Please choose another name for the dataset.',
      };
    }
    return { isErrorState: false };
  };

  const renderEditDatasetModal = () => {
    const editDataset = editDatasetId ? datasetConfigs[editDatasetId] : undefined;

    if (!editDatasetId || !editDataset) return;

    const defaultParentSchema = parentSchemas?.find(
      (schema) =>
        schema.id === editDataset.parent_schema_id ||
        schema.fido_id === String(editDataset.parent_schema_id),
    );

    return (
      <DatasetModal
        modalOpen
        buttonTitle="Save"
        datasetName={getDatasetName(editDataset)}
        defaultParentSchema={defaultParentSchema}
        errorState={errorWithDatasetNameEntry}
        onClose={() => setEditDatasetId(null)}
        onSubmit={(name) => {
          setActionLoadingDatasetId(editDatasetId);
          onEditName(name);
          setActionLoadingDatasetId(null);
          setEditDatasetId(null);
        }}
        title="Edit Dataset"
      />
    );
  };

  const renderDeleteDatasetModal = () => {
    const datasetToDelete = deleteDatasetId ? datasetConfigs[deleteDatasetId] : undefined;

    if (!deleteDatasetId || !datasetToDelete) return;

    const { dataPanelsInUse, elementsInUse } = isDatasetInUse(
      deleteDatasetId,
      Object.values(elements ?? {}),
      dataPanels,
    );

    if (dataPanelsInUse.length > 0 || elementsInUse.length > 0) {
      return renderDeleteDatasetInUse(dataPanelsInUse, elementsInUse);
    }

    const onDeleteDataset = () => {
      setActionLoadingDatasetId(deleteDatasetId);
      onDelete(deleteDatasetId);
      setActionLoadingDatasetId(null);
      setDeleteDatasetId(null);
    };

    return (
      <AlertModal
        isOpen
        actionButtonProps={{
          text: `Delete ${getDatasetName(datasetToDelete)}`,
          onClick: onDeleteDataset,
        }}
        onClose={() => setDeleteDatasetId(null)}
        title="Are you sure you want to delete this dataset?"
      />
    );
  };

  const renderDeleteDatasetInUse = (dataPanelsInUse: string[], elementsInUse: string[]) => {
    const onClose = () => setDeleteDatasetId(null);
    return (
      <Modal
        isOpen
        onClose={onClose}
        portalContainerId={APP_PORTAL_ID}
        secondaryButtonProps={{ text: 'Cancel', onClick: onClose }}
        size="small"
        title="This dataset cannot currently be deleted">
        <div className={sprinkles({ paddingX: 'sp3' })}>
          <div>
            This dataset is in use and cannot be deleted until the following elements are deleted.
          </div>
          {dataPanelsInUse.length > 0 ? (
            <>
              <div className={classes.warningHeader}>
                <Icon className={sharedStyles.warningIconClass} name="circle-exclamation" />
                <div>
                  The following <b>charts</b> are using this dataset:
                </div>
              </div>
              <ul>
                {dataPanelsInUse.map((dataPanelName) => (
                  <li key={`data-panel-delete-list-${dataPanelName}`}>{dataPanelName}</li>
                ))}
              </ul>
            </>
          ) : null}
          {elementsInUse.length > 0 ? (
            <>
              <div className={classes.warningHeader}>
                <Icon className={sharedStyles.warningIconClass} name="circle-exclamation" />
                <div>
                  The following <b>dashboard elements</b> are using this dataset:
                </div>
              </div>
              <ul>
                {elementsInUse.map((elementName) => (
                  <li key={`elem-delete-list-${elementName}`}>{elementName}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </Modal>
    );
  };

  const renderSchemaViewer = () => {
    if (editorView !== DATASET_VIEWS.QUERY || !selectedDatasetId || !activeDatasetConfig) return;

    return (
      <SchemaViewer
        onFormat={() => formatSqlQuery(currentQuery)}
        onPreview={!currentQuery || currentQuery.length === 0 ? undefined : getTablePreview}
        onRevertDraft={() => onSaveDraft(undefined)}
        onSave={
          !currentQuery || currentQuery.length === 0
            ? undefined
            : () => {
                onSave(currentQuery);
                openTab(DATASET_VIEWS.PREVIEW);
              }
        }
        onSelectSchema={onSelectSchema}
        saveText={currentQuery === activeDatasetConfig?.query ? 'Run' : 'Save & Run'}
        selectedDatasetSchemaId={activeDatasetConfig.parent_schema_id}
      />
    );
  };

  const renderDatasetEditor = () => {
    const editorMenu = (
      <div
        className={cx(
          sprinkles({
            backgroundColor: 'white',
            flexItems: 'alignCenterBetween',
            paddingRight: 'sp1.5',
            overflow: 'hidden',
          }),
          classes.editorTopBar,
        )}>
        {renderToggleEditorViewActions()}
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          {showDatasetsAsList() ? (
            <>
              {renderExpandButton()} {renderCollapseButton()}
            </>
          ) : null}
        </div>
      </div>
    );

    let editorBody;

    switch (editorView) {
      case DATASET_VIEWS.QUERY:
        editorBody = renderDatasetQueryEditor();
        break;
      case DATASET_VIEWS.PREVIEW:
        editorBody = renderDatasetPreview();
        break;
      case DATASET_VIEWS.FORMATTING:
        editorBody = renderDatasetFormattingBody();
        break;
      case DATASET_VIEWS.DEBUGGER:
        editorBody = renderGeneratedQueryBody();
    }

    return (
      <div
        className={showDatasetsAsList() ? classes.editor : sprinkles({ parentContainer: 'fill' })}>
        {pageWidth && datasetEditorShowsDatasetsAsDropdown(pageWidth)
          ? renderSelectDatasetTopBar()
          : null}
        {editorMenu}
        <div className={classes.editorContainer}>
          <div className={sprinkles({ parentContainer: 'fill' })}>{editorBody}</div>
        </div>
      </div>
    );
  };

  const getTablePreview = () => {
    fetchData(currentQuery);
    openTab(DATASET_VIEWS.PREVIEW);
  };

  const formatSqlQuery = (query: string) => onSaveDraft(formatQuery(query));

  const openTab = (tabId: string) => setEditorView(tabId as DATASET_VIEWS);

  const renderToggleEditorViewActions = () => {
    if (!isOpen) return <div></div>;

    return (
      <Tabs
        className={sharedStyles.datasetEditorTabs}
        onTabSelect={(tabId) => {
          openTab(tabId);
          if (tabId === DATASET_VIEWS.FORMATTING && selectedDatasetId) {
            getFormattingPreview();
          }
        }}
        selectedTabId={editorView}
        tabs={DatasetViewTabs}
      />
    );
  };

  const renderDatasetQueryEditor = () => {
    if (!activeDatasetConfig) return;

    return (
      <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
        <div className={sharedStyles.formattedDataTableClass}>
          <SqlEditor
            columnNames={columnNames}
            onChange={setCurrentQuery}
            onChangeDraft={(newQuery) =>
              onSaveDraft(newQuery === currentQuery ? undefined : newQuery)
            }
            query={currentQuery}
            tableNames={tableNames}
          />
        </div>
        {renderSchemaViewer()}
      </div>
    );
  };

  const renderQueryRedirectText = (text: string) => (
    <span
      className={sprinkles({ color: 'active', cursor: 'pointer' })}
      onClick={() => openTab(DATASET_VIEWS.QUERY)}>
      {text}
    </span>
  );

  const drilldownColumnConfigsValues = useMemo(
    () => Object.values(activeDatasetConfig?.drilldownColumnConfigs || []),
    [activeDatasetConfig?.drilldownColumnConfigs],
  );
  const noIncludedColumns = useMemo(
    () =>
      drilldownColumnConfigsValues.reduce((acc, config) => acc + (config.isVisible ? 1 : 0), 0) ===
        0 && !(drilldownColumnConfigsValues.length === 0),
    [drilldownColumnConfigsValues],
  );

  const renderDatasetFormattingBody = () => {
    if (!selectedDatasetId) return;

    const error = activeDatasetData?.error;
    if (error || !activeDatasetSavedSchema?.length || !activeDatasetConfig || !activeDatasetData) {
      if (!activeDatasetConfig || !activeDatasetData) {
        return (
          <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
            <div className={sharedStyles.formattedDataTableClass}>
              <DatasetEditorNonIdealState
                description="Formatting tools will appear after your SQL query is saved."
                icon={<Icon name="rectangle-terminal" size="lg" />}
                primaryContent={renderQueryRedirectText('Write a query')}
                title="No data to format"
              />
            </div>
          </div>
        );
      }

      return (
        <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
          <div className={sharedStyles.formattedDataTableClass}>
            {error ? (
              renderDatasetTableError(error)
            ) : (
              <DatasetEditorNonIdealState
                description="Formatting tools will appear after your SQL query is saved."
                icon={<Icon name="rectangle-terminal" size="lg" />}
                primaryContent={renderQueryRedirectText('Write a query')}
                title="No data to format"
              />
            )}
          </div>
          {datasetEditorMode !== DATASET_EDITOR_MODE.EXPANDED ? (
            <DrilldownDatasetColumnsFormatConfig
              datasetConfig={activeDatasetConfig}
              datasetData={activeDatasetData}
              datasetEditorMode={datasetEditorMode}
              datasetSchema={activeDatasetSavedSchema ?? []}
              toggleIsExpanded={toggleIsExpanded}
            />
          ) : null}
        </div>
      );
    }

    return (
      <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
        {datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED ? (
          <DrilldownDatasetColumns
            datasetConfig={activeDatasetConfig}
            datasetSchema={activeDatasetSavedSchema}
          />
        ) : null}
        <div className={sharedStyles.formattedDataTableClass}>
          {noIncludedColumns ? renderNoVisibleColumns() : renderDatasetPreview(true)}
        </div>
        <DrilldownDatasetColumnsFormatConfig
          datasetConfig={activeDatasetConfig}
          datasetData={activeDatasetData}
          datasetEditorMode={datasetEditorMode}
          datasetSchema={activeDatasetSavedSchema}
          toggleIsExpanded={toggleIsExpanded}
        />
      </div>
    );
  };

  const renderDatasetPreview = (isDrilldownPreview?: boolean) => (
    <DatasetPreview
      datasetConfig={activeDatasetConfig}
      datasetData={activeDatasetData}
      datasetQuery={activeQuery}
      datasetSchema={isDrilldownPreview ? activeDatasetSavedSchema : activeDatasetSchema}
      enableFormat={editorView === DATASET_VIEWS.FORMATTING}
      fetchDataset={fetchData}
      getTablePreview={getTablePreview}
      onRunSuggestion={() =>
        datasetEditorMode !== DATASET_EDITOR_MODE.EXPANDED && toggleIsExpanded()
      }
      openTab={openTab}
    />
  );

  const renderDatasetTableError = (error: string) => (
    <DatasetEditorNonIdealState
      description={error}
      icon={<Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="lg" />}
      primaryContent={renderQueryRedirectText('Revise your query')}
      title="There was a problem with your query"
    />
  );

  const renderNoVisibleColumns = () => (
    <DatasetEditorNonIdealState
      description={
        datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED
          ? 'Select some columns to get started.'
          : 'Open expanded view to add columns.'
      }
      title="No visible columns"
    />
  );

  const renderGeneratedQueryBody = () => {
    if (!selectedDatasetId || !activeDatasetConfig) {
      return <Spinner fillContainer />;
    }

    const queryInformation = activeDatasetData?.queryInformation;

    if (!queryInformation) {
      return (
        <DatasetEditorNonIdealState
          description="This panel will show the query constructed and ran based on variables in the dataset SQL"
          icon={<Icon name="rectangle-terminal" size="lg" />}
          primaryContent={renderQueryRedirectText('Write a query')}
          title="No query to debug"
        />
      );
    }

    return (
      <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
        <InfoCard
          className={classes.queryDebuggerInfo}
          text="This is the query that Explo generated and ran. All available variables have been inserted into the query."
        />
        <QueryRuntime queryInformation={queryInformation} />
        <div className={classes.queryDebugger}>
          <SyntaxHighlighter showLineNumbers language="sql">
            {formatQuery(queryInformation._query || '')}
          </SyntaxHighlighter>
        </div>
        <div className={sprinkles({ display: 'flex', justifyContent: 'center' })}>
          <QuerySuggestion
            error={activeDatasetData?.error}
            onRun={() => datasetEditorMode !== DATASET_EDITOR_MODE.EXPANDED && toggleIsExpanded()}
            query={currentQuery}
          />
        </div>
      </div>
    );
  };

  const showDatasetsAsList = () => {
    return pageWidth && datasetEditorShowsDatasetsAsList(pageWidth);
  };

  return (
    <div
      className={sprinkles({
        height: 'fill',
        borderColor: 'gray7',
        backgroundColor: 'white',
        borderTop: 1,
      })}>
      <div className={sprinkles({ height: 'fill', flexItems: 'alignCenter' })}>
        {showDatasetsAsList() && renderDatasetList()}
        {renderDatasetEditor()}
      </div>
      {renderCreateDatasetModal()}
      {renderEditDatasetModal()}
      {renderDeleteDatasetModal()}
    </div>
  );
};

import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, sprinkles } from 'components/ds';

import { deleteAllowlistDomain } from 'actions/allowlistDomainActions';
import { AllowlistDomain } from 'actions/teamActions';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

type Props = {
  rule: AllowlistDomain;
};

export const SettingsDomainAllowlistRule: FC<Props> = ({ rule }) => {
  const dispatch = useDispatch();

  const [deleteClicked, setDeleteClicked] = useState(false);

  return (
    <div className={sprinkles({ flexItems: 'alignCenterBetween', paddingY: 'sp1' })}>
      <div>{rule.name}</div>
      <Button
        loading={deleteClicked}
        onClick={() => {
          dispatch(
            deleteAllowlistDomain({ id: rule.id }, undefined, (response) => {
              showErrorContactSupportToast(response.error_msg);
              setDeleteClicked(false);
            }),
          );
          setDeleteClicked(true);
        }}
        variant="destructive">
        Remove
      </Button>
    </div>
  );
};

import { FC, useState, useMemo } from 'react';
import cx from 'classnames';

import {
  Icon,
  Spinner,
  Tag,
  Menu,
  MenuActionItem,
  sprinkles,
  IconButton,
  Breadcrumbs,
} from 'components/ds';

import { Customer, GroupTag, HierarchyLevel } from 'actions/teamActions';
import * as styles from './styles.css';

export type ParentSummary = {
  providedId: string;
  name: string;
  levelName: string | undefined;
  accessGroupId: number;
};

type GridMetadata = {
  isFirstRow: boolean;
  isLastRow: boolean;
  showLevelColumn: boolean;
  showChildrenColumn: boolean;
};

type Props = {
  customer: Customer;
  groupTags: GroupTag[];
  deleteLoading: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  resetVisibilityGroup?: boolean;
  levels: HierarchyLevel[];
  onCustomerClick: (parent: ParentSummary) => void;
  gridMetadata: GridMetadata;
};

export const CustomerListItem: FC<Props> = ({
  customer,
  groupTags,
  deleteLoading,
  onDelete,
  onEdit,
  resetVisibilityGroup,
  levels,
  onCustomerClick,
  gridMetadata,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const emailText = useMemo(() => customer.emails.join(', '), [customer]);

  const level = useMemo(
    () => levels.find((l) => l.id === customer.hierarchy_level_id),
    [levels, customer],
  );

  const { isFirstRow, isLastRow, showLevelColumn, showChildrenColumn } = gridMetadata;

  const hasChildren = customer.children_count > 0;

  const customerGroupTags = useMemo(
    () => groupTags.filter((tag) => customer.computed_group_tags.includes(tag.id)),
    [groupTags, customer],
  );

  const renderGroupTags = () =>
    customerGroupTags.map((tag) => (
      <Tag
        className={sprinkles({ whiteSpace: 'nowrap' })}
        key={`group-tag-${tag.id}`}
        leftIcon="users">
        {tag.name}
      </Tag>
    ));

  const renderDropdown = () => {
    if (!onEdit && !onDelete)
      return (
        <div className={styles.schemaEllipsisMenu}>
          <IconButton
            disabled
            name="ellipsis-vertical"
            tooltipProps={{
              text: 'You do not have permission to edit or delete customers.',
              className: sprinkles({ body: 'b2' }),
            }}
          />
        </div>
      );

    return (
      <div className={styles.schemaEllipsisMenu} onClick={(e) => e.stopPropagation()}>
        <Menu
          align="end"
          isOpen={isDropdownOpen}
          onOpenChange={(open) => {
            if (!deleteLoading) setIsDropdownOpen(open);
          }}
          trigger={
            deleteLoading ? (
              <div className={styles.rightIcon}>
                <Spinner size="md" />
              </div>
            ) : (
              <div className={styles.rightIcon}>
                <Icon name="ellipsis-vertical" />
              </div>
            )
          }
          width="small">
          {onEdit ? (
            <MenuActionItem
              onSelect={() => {
                setIsDropdownOpen(false);
                onEdit();
              }}
              text="Edit"
            />
          ) : null}
          {onDelete ? (
            <MenuActionItem
              isDestructive
              disabled={hasChildren}
              onSelect={() => {
                setIsDropdownOpen(false);
                onDelete();
              }}
              text="Delete"
            />
          ) : null}
        </Menu>
      </div>
    );
  };

  const displayId = customer.provided_id ?? customer.id;

  return (
    <div
      className={cx(styles.rowWrapper, {
        [styles.topRowWrapper]: isFirstRow,
        [styles.bottomRowWrapper]: isLastRow,
        [styles.rowHasChildren]: hasChildren,
      })}
      onClick={() => {
        if (!hasChildren) return;
        onCustomerClick({
          providedId: customer.provided_id,
          name: customer.name,
          levelName: level?.name,
          accessGroupId: customer.access_group_id,
        });
      }}>
      <div className={styles.customerNameContainer} title={customer.name}>
        {customer.parent_names && customer.parent_names.length > 0 ? (
          <div className={styles.parentChildNames}>
            <Breadcrumbs items={customer.parent_names.map((name) => ({ text: name }))} />
            {customer.name}
          </div>
        ) : (
          customer.name
        )}
      </div>
      {showChildrenColumn ? (
        <div className={styles.customerGroupTagContainer}>
          <Tag
            className={(customer.children_count ?? 0) === 0 ? styles.zeroChildrenTag : undefined}
            leftIcon="list-tree">
            {customer.children_count}
          </Tag>
        </div>
      ) : null}
      <div className={styles.customerIdContainer} title={displayId}>
        {displayId}
      </div>
      {showLevelColumn ? <div className={styles.customerIdContainer}>{level?.name}</div> : null}

      <div className={styles.customerGroupTagContainer}>{renderGroupTags()}</div>
      <div className={styles.customerEmailContainer}>
        <div className={styles.customerEmailText} title={emailText}>
          {emailText}
        </div>
        {resetVisibilityGroup ? (
          <div className={sprinkles({ paddingY: 'sp1.5' })}>
            <Tag intent="error">Reassign Visibility Group</Tag>
          </div>
        ) : null}
      </div>

      {renderDropdown()}
    </div>
  );
};

import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { ReportBuilderPreview } from './Preview';
import { DatasetEditor } from './DatasetEditor';
import { sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { ReportBuilderPoller } from '../ReportBuilder/ReportBuilderPoller';

import { ReportBuilderBuiltIns } from 'pages/ReportBuilderEditor/BuiltIns';
import { loadFonts } from 'globalStyles/utils';
import { fetchReportBuilderVersions } from 'actions/reportBuilderVersionActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { getCurrentReportBuilder } from 'reducers/reportBuilderReducer';
import { Header } from './Header';
import { isIdle, isSuccess } from 'remotedata';
import { fetchReportBuilders } from 'actions/reportBuilderActions';
import { VIEW_MODE } from './types';
import { useLoadEditMetadata } from 'utils/hookUtils';
import { Settings } from 'luxon';

Settings.defaultZone = 'UTC';

export const ReportBuilderEditorPage: FC = () => {
  const dispatch = useDispatch();
  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();
  const { reportBuilder, reportBuildersNotLoaded, config, team, globalStyleConfig, portalId } =
    useSelector(
      (state: ReduxState) => ({
        reportBuilder: getCurrentReportBuilder(state.reportBuilder),
        reportBuildersNotLoaded: isIdle(state.reportBuilder.reportBuilders),
        config: state.reportBuilderEdit.config,
        team: state.currentUser?.team,
        globalStyleConfig: state.dashboardStyles.globalStyleConfig,
        portalId: state.embeddedReportBuilder.portalId,
        shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      }),
      shallowEqual,
    );

  const [initialViewIds, setInitialViewIds] = useState<string[]>();

  const metadataLoading = useLoadEditMetadata(initialViewIds);

  useEffect(() => {
    if (!team) return;
    loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  useEffect(() => {
    const id = parseInt(reportBuilderId);

    if (!isNaN(id))
      dispatch(
        fetchReportBuilderVersions({ id }, (data) => {
          const version = [...data.versions].sort((a, b) => b.version_number - a.version_number)[0];
          setInitialViewIds(Object.values(version.config.datasets).map((v) => v.fido_id ?? ''));
        }),
      );
  }, [dispatch, reportBuilderId]);

  useEffect(() => {
    if (reportBuildersNotLoaded) dispatch(fetchReportBuilders());
  }, [dispatch, reportBuildersNotLoaded]);

  const renderPage = (reportBuilderId: number, embedId: string, config: ReportBuilderConfig) => {
    switch (view) {
      case VIEW_MODE.BUILT_INS:
        return <ReportBuilderBuiltIns />;
      case VIEW_MODE.SETTINGS:
        return <ReportBuilderPreview />;
      default:
        return <DatasetEditor config={config} reportBuilderId={reportBuilderId} />;
    }
  };

  const renderReportBuilderEditor = () => {
    if (!reportBuilder || !isSuccess(config) || metadataLoading) {
      return <ExploLoadingSpinner />;
    }

    return (
      <div className={containerClass}>
        <Header reportBuilder={reportBuilder} />
        <div className={sprinkles({ display: 'flex', flex: 1, width: 'fill', overflow: 'hidden' })}>
          {renderPage(reportBuilder.id, reportBuilder.embed_id, config.data)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={sprinkles({ display: 'flex', parentContainer: 'fill', overflowX: 'auto' })}
      id={portalId}>
      {renderReportBuilderEditor()}
      <ReportBuilderPoller />
    </div>
  );
};

const containerClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  position: 'relative',
  overflow: 'hidden',
  widthConstraints: 'minOnly',
});

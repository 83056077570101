import { FC, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BuiltInInfoTooltip } from 'pages/ReportBuilder/HomeView/BuiltInInfoTooltip';
import { BuiltInReportCard } from 'pages/ReportBuilder/HomeView/BuiltInReportCard';
import { SortButton } from 'pages/ReportBuilder/HomeView/SortButton';

import { sprinkles } from 'components/ds';
import { SortOrder } from 'constants/types';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { orderBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { getOrderedBuiltIns } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

import * as styles from 'pages/ReportBuilder/HomeView/BuiltInReportTab.css';

export const BuiltInReportTab: FC = () => {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);

  const { builtInReports, favoriteBuiltIns } = useSelector(
    (state: ReportBuilderReduxState) => ({
      builtInReports: getOrderedBuiltIns(state.embeddedReportBuilder),
      favoriteBuiltIns: state.embeddedReportBuilder.favoriteBuiltIns,
    }),
    shallowEqual,
  );

  const sortedBuiltIns = useMemo(() => {
    const sortOrder = sortOption.sortOrder === SortOrder.DESC ? 'desc' : 'asc';
    if (sortOption.name === SORT_OPTION_NAME.FAVORITES_FIRST) {
      return orderBy(builtInReports, (builtIn) => favoriteBuiltIns.includes(builtIn.id), sortOrder);
    } else if (sortOption.name === SORT_OPTION_NAME.LAST_MODIFIED) {
      return orderBy(
        builtInReports,
        (builtIn) => getTimezoneAwareUnix(builtIn.modified),
        sortOrder,
      );
    }

    return orderBy(builtInReports, (builtIn) => getTimezoneAwareUnix(builtIn.created), sortOrder);
  }, [builtInReports, favoriteBuiltIns, sortOption]);

  return (
    <div
      className={sprinkles({
        paddingY: 'sp2',
        paddingX: 'sp8',
        overflowY: 'auto',
        flex: 1,
        backgroundColor: 'elevationMid',
      })}>
      <div
        className={sprinkles({
          flexItems: 'alignCenterBetween',
        })}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <EmbedText
            className={sprinkles({ truncateText: 'ellipsis' })}
            color="contentPrimary"
            heading="h3">
            Built In Reports
          </EmbedText>
          <BuiltInInfoTooltip />
        </div>
        <SortButton
          onChange={setSortOption}
          sortOption={sortOption}
          sortOptions={Object.values(SORT_OPTIONS)}
        />
      </div>
      {!sortedBuiltIns.length ? (
        <div className={styles.emptyState}>
          <EmbedText body="b1" color="contentPrimary">
            No built ins
          </EmbedText>
        </div>
      ) : (
        <div className={styles.builtInReportsGrid}>
          {sortedBuiltIns.map((builtIn) => (
            <BuiltInReportCard builtInReport={builtIn} key={builtIn.id} />
          ))}
        </div>
      )}
    </div>
  );
};

enum SORT_OPTION_NAME {
  FAVORITES_FIRST = 'Favorites First',
  LAST_MODIFIED = 'Last Modified',
  LAST_CREATED = 'Last Created',
  FIRST_CREATED = 'First Created',
}

const SORT_OPTIONS = [
  { name: SORT_OPTION_NAME.FAVORITES_FIRST, sortOrder: SortOrder.DESC },
  { name: SORT_OPTION_NAME.LAST_MODIFIED, sortOrder: SortOrder.DESC },
  { name: SORT_OPTION_NAME.LAST_CREATED, sortOrder: SortOrder.DESC },
  { name: SORT_OPTION_NAME.FIRST_CREATED, sortOrder: SortOrder.ASC },
];

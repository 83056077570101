import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { Icon, Tooltip, sprinkles } from 'components/ds';
import { TooltipSide } from '../Tooltip';

export type Props = {
  text: ReactNode;
  className?: string;
  portalContainerId?: string;
  tooltipSide?: TooltipSide;
};

export const InfoIcon: FC<Props> = ({ className, tooltipSide, portalContainerId, text }) => {
  return (
    <Tooltip portalContainerId={portalContainerId} side={tooltipSide} text={text}>
      <Icon className={cx(sprinkles({ color: 'gray8' }), className)} name="circle-info" size="sm" />
    </Tooltip>
  );
};

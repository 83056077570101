import { FC, useEffect, useState } from 'react';
import { map } from 'utils/standard';
import { ReduxState } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Input, sprinkles } from 'components/ds';

import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { saveAnalyticsConfig } from 'actions/teamActions';
import { isValidHttpUrl } from 'utils/analyticsUtils';
import { createDebouncedFn } from 'utils/general';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';

const actionTypes: string[] = Object.values(REPORTED_ANALYTIC_ACTION_TYPES);

const debounceFn = createDebouncedFn(2000);

export const SettingsAnalyticsSection: FC = () => {
  const dispatch = useDispatch();

  const team = useSelector((state: ReduxState) => state.teamData.data);

  const [analyticsReportingUrl, setAnalyticsReportingUrl] = useState(team?.analytics_reporting_url);
  const [analyticsReportingActions, setAnalyticsReportingActions] = useState(
    new Set(map(team?.analytics_reporting_actions || [], 'name')),
  );
  const [analyticsReportingToken, setAnalyticsReportingToken] = useState(
    team?.analytics_reporting_token,
  );

  useEffect(() => {
    setAnalyticsReportingUrl(team?.analytics_reporting_url);
    setAnalyticsReportingActions(new Set(map(team?.analytics_reporting_actions || [], 'name')));
    setAnalyticsReportingToken(team?.analytics_reporting_token);
  }, [team]);

  const onConfigUpdated = (
    actions: Set<string>,
    url: string | null | undefined,
    token: string | null | undefined,
  ) => {
    debounceFn(() => {
      if (url && !isValidHttpUrl(url)) {
        showErrorToast('The input URL must be a valid URL.');
        return;
      }
      dispatch(
        saveAnalyticsConfig(
          {
            postData: {
              analytics_reporting_actions: Array.from(actions),
              analytics_reporting_url: url,
              analytics_reporting_token: token,
            },
          },
          () => showSuccessToast('Analytics settings saved successfully'),
        ),
      );
    });
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
      <div className={sprinkles({ heading: 'h2' })}>Analytics</div>
      <div className={sprinkles({ color: 'contentTertiary' })}>
        Send analytics about your customer&apos;s dashboard usage to your database
      </div>
      <Input
        defaultValue={analyticsReportingUrl ?? ''}
        label="URL"
        onSubmit={(value) => {
          setAnalyticsReportingUrl(value);
          onConfigUpdated(analyticsReportingActions, value, analyticsReportingToken);
        }}
      />
      <Input
        defaultValue={analyticsReportingToken ?? ''}
        label="Authorization Token"
        onSubmit={(value) => {
          setAnalyticsReportingToken(value);
          onConfigUpdated(analyticsReportingActions, analyticsReportingUrl, value);
        }}
      />
      <div className={sprinkles({ flexItems: 'column', marginTop: 'sp1', gap: 'sp.5' })}>
        {actionTypes.map((action) => (
          <Checkbox
            isChecked={analyticsReportingActions.has(action)}
            key={action}
            label={action}
            onChange={() => {
              const newState = new Set(analyticsReportingActions);
              if (analyticsReportingActions.has(action)) {
                newState.delete(action);
              } else {
                newState.add(action);
              }
              setAnalyticsReportingActions(newState);
              onConfigUpdated(newState, analyticsReportingUrl, analyticsReportingToken);
            }}
          />
        ))}
      </div>
    </div>
  );
};

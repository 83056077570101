import { FC, ReactNode, useState } from 'react';
import cx from 'classnames';

import { sprinkles, Icon, IconButton } from 'components/ds';

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * content to go inside of group when expanded
   */

  children: ReactNode;
  /**
   * title of group to show up on the top button
   */
  title: string;
  /**
   * if passed in, the delete button won't show up
   */
  isNotDeletable?: boolean;
  /**
   * handle when the user selects to delete
   */
  onDelete?: () => void;
};

export const CollapsibleGroup: FC<Props> = ({
  className,
  title,
  children,
  isNotDeletable,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cx(sprinkles({ borderRadius: 4, backgroundColor: 'elevationHigh' }), className)}>
      <div
        className={sprinkles({
          cursor: 'pointer',
          flexItems: 'alignCenterBetween',
          borderRadius: 4,
          paddingX: 'sp1',
          backgroundColor: {
            default: isOpen ? 'elevationLow' : 'elevationMid',
            hover: 'elevationLow',
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
        style={{ height: 32 }}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', overflow: 'hidden' })}>
          <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} />
          <div
            className={sprinkles({
              color: 'contentPrimary',
              heading: 'h4',
              truncateText: 'ellipsis',
              flex: 1,
            })}>
            {title}
          </div>
        </div>
        {isNotDeletable || !onDelete ? null : (
          <IconButton
            name="trash"
            onClick={(e) => {
              onDelete();
              e.stopPropagation();
            }}
          />
        )}
      </div>
      {isOpen ? children : null}
    </div>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { Input, sprinkles } from 'components/ds';

import { IframeElemConfig } from 'types/dashboardTypes';

type Props = {
  config: IframeElemConfig;

  updateConfig: (newConfig: IframeElemConfig) => void;
};

export const IframeElementConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  return (
    <Input
      showInputButton
      className={sprinkles({ marginTop: 'sp1.5' })}
      defaultValue={config.iframeUrl}
      label={{ text: 'Iframe URL', variableInput: true }}
      onSubmit={(newValue) => {
        const newConfig = produce(config, (draft) => {
          draft.iframeUrl = newValue;
        });
        updateConfig(newConfig);
      }}
    />
  );
};

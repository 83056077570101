import { defineAPIAction, defineAPIPostAction } from './actionUtils';

import { DashboardTimezones } from 'constants/dashboardConstants';
import { ACTION } from './types';

export interface ReportBuilder extends EmbedReportBuilder {
  team_id: number;
  embed_id: string;
  latest_versions: {
    is_draft: boolean;
    version_number: number;
    modified: string;
  }[];
  entry_id: number;
}

export interface EmbedReportBuilder {
  id: number;
  embed_id?: string;
  name: string;
  default_timezone: DashboardTimezones | null;
}

type FetchReportBuildersData = {
  report_builders: ReportBuilder[];
};

export const { actionFn: fetchReportBuilders, ...fetchReportBuildersActions } =
  defineAPIAction<FetchReportBuildersData>(
    ACTION.FETCH_REPORT_BUILDERS,
    'report_builder',
    'get_report_builders',
    'GET',
  );

export const { actionFn: createReportBuilder, successAction: createReportBuilderSuccess } =
  defineAPIPostAction<{ name: string; parent_id?: number }, { report_builder: ReportBuilder }>(
    ACTION.CREATE_REPORT_BUILDER,
    'report_builder',
    'create_report_builder',
    'POST',
  );

export const { actionFn: deleteReportBuilder, successAction: deleteReportBuilderSuccess } =
  defineAPIAction(ACTION.DELETE_REPORT_BUILDER, 'report_builder', '', 'DELETE');

export const { actionFn: renameReportBuilder, successAction: renameReportBuilderSuccess } =
  defineAPIPostAction<{ name: string }, { name: string }>(
    ACTION.RENAME_REPORT_BUILDER,
    'report_builder',
    'rename',
    'POST',
  );

export const { actionFn: getReportsUsingDataset } = defineAPIPostAction<
  { dataset_id: string },
  { report_count: number }
>(ACTION.GET_REPORTS_USING_DATASET, 'report_builder', 'get_dataset_usage_count', 'POST');

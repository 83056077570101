import { FC } from 'react';
import cx from 'classnames';
import { Icon, IconName } from '@blueprintjs/core';

import { sprinkles } from 'components/ds';
import { EmbedInfoIcon } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { STRING } from 'constants/dataConstants';
import { StringFormat } from 'constants/types';
import { formatLabel } from 'pages/dashboardPage/charts/utils';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { flexAlignments } from 'components/ds/DataGrid/utils';

type Props = {
  header: string;
  rightIcon: IconName | undefined;
  isSortable: boolean;
  alignment: TEXT_ELEM_HORIZ_ALIGNMENTS;
  tooltipText?: string;
  stringFormat?: StringFormat;
  isInitialSortDesc?: boolean;
};

export const ColumnHeaderText: FC<Props> = ({
  header,
  rightIcon,
  isSortable,
  alignment,
  tooltipText,
  stringFormat,
  isInitialSortDesc,
}) => {
  const justifyContent = flexAlignments[alignment];
  return (
    <div
      className={cx(
        'bp3-table-truncated-text',
        sprinkles({ flexItems: 'alignCenter', height: 'fill', justifyContent: 'space-between' }),
        embedSprinkles({ otherText: 'tableColumnHeader' }),
      )}
      title="">
      <div className={cx(sprinkles({ flexItems: 'alignCenter', width: 'fill', justifyContent }))}>
        {formatLabel(header, STRING, undefined, undefined, undefined, stringFormat)}
        {tooltipText ? <EmbedInfoIcon text={tooltipText} /> : null}
      </div>
      {rightIcon ? (
        <Icon className={sprinkles({ marginLeft: 'sp.5' })} icon={rightIcon} iconSize={12} />
      ) : isSortable ? (
        <Icon
          className={cx(
            'columnSortIcon',
            sprinkles({ marginLeft: 'sp.5', opacity: 0.5, visibility: 'hidden' }),
          )}
          icon={isInitialSortDesc ? 'arrow-down' : 'arrow-up'}
          iconSize={12}
        />
      ) : null}
    </div>
  );
};

import { FC, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { isSuccess } from 'remotedata';

import { VersionPanel } from './VersionPanel';
import { DeleteConfirmationButton } from 'components/DeleteConfirmationButton';
import { Spinner, TextArea, Button, Modal, APP_PORTAL_ID, sprinkles, Input } from 'components/ds';

import {
  deleteCurrentDashboardDraft,
  publishNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
} from 'actions/dashboardV2Actions';
import { ACTION } from 'actions/types';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { createLoadingSelector } from 'reducers/api/selectors';
import { removeUnsavedDashboardConfigFields } from 'utils/dashboardUtils';
import { showWarningToast } from 'shared/sharedToasts';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import {
  deleteCurrentReportBuilderDraft,
  publishNewReportBuilderVersion,
} from 'actions/reportBuilderVersionActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { cloneComputedViews } from 'reducers/thunks/fidoThunks';
import { createReportBuilderDraft } from 'reducers/thunks/resourceSaveThunks';

type Props = {
  closeModal: () => void;
  pageType: ResourcePageType;
};

export const VersionControlModal: FC<Props> = ({ closeModal, pageType }) => {
  const dispatch = useDispatch();
  const [draftChangeComment, setDraftChangeComment] = useState('');

  const isExplore = pageType === ResourcePageType.EXPLORE;

  const {
    currentResourceId,
    resourceConfig,
    deleteLoading,
    publishLoading,
    tags,
    versions,
    viewingResourceVersion,
    permissions,
    shouldUseFido,
  } = useSelector((state: ReduxState) => {
    const sharedState = {
      tags: state.environmentTags.tags,
      permissions: state.currentUser.permissions,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    };

    if (isExplore) {
      return {
        ...sharedState,
        currentResourceId: state.dashboard.currentDashboardId,
        resourceConfig: state.dashboardEditConfig.config,
        deleteLoading: createLoadingSelector([ACTION.DELETE_CURRENT_DASHBOARD_DRAFT], false)(state),
        publishLoading: createLoadingSelector([ACTION.PUBLISH_NEW_DASHBOARD_VERSION], false)(state),
        versions: state.dashboardVersions.versions,
        viewingResourceVersion: state.dashboardEditConfig.versionInfo,
      };
    } else {
      return {
        ...sharedState,
        currentResourceId: state.reportBuilder.currentReportBuilder,
        resourceConfig: isSuccess(state.reportBuilderEdit.config)
          ? state.reportBuilderEdit.config.data
          : undefined,
        deleteLoading: createLoadingSelector(
          [ACTION.DELETE_CURRENT_REPORT_BUILDER_DRAFT],
          false,
        )(state),
        publishLoading: createLoadingSelector(
          [ACTION.PUBLISH_NEW_REPORT_BUILDER_VERSION],
          false,
        )(state),
        versions: state.reportBuilderEdit.versions,
        viewingResourceVersion: state.reportBuilderEdit.versionInfo,
      };
    }
  }, shallowEqual);

  const mostRecentVersion = versions[0];
  const previousVersion = versions[1];

  const permissionResource = getPermissionEntity(pageType);

  const handlePublish = () => {
    if (!publishLoading && draftChangeComment) {
      if (currentResourceId === undefined || !resourceConfig) return;

      if (isExplore) {
        dispatch(
          publishNewDashboardVersion(
            {
              id: currentResourceId,
              postData: {
                config: removeUnsavedDashboardConfigFields(
                  resourceConfig as DashboardVersionConfig,
                ),
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => setDraftChangeComment(''),
          ),
        );
      } else {
        dispatch(
          publishNewReportBuilderVersion(
            {
              id: currentResourceId,
              postData: {
                config: resourceConfig as ReportBuilderConfig,
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => {
              setDraftChangeComment('');

              if (!shouldUseFido) return;

              dispatch(
                cloneComputedViews({
                  configuration: resourceConfig,
                  onSuccess: (configuration) =>
                    dispatch(
                      createReportBuilderDraft({ config: configuration as ReportBuilderConfig }),
                    ),
                }),
              );
            },
          ),
        );
      }
    }
  };

  const handleDelete = () => {
    const args = {
      id: currentResourceId,
      postData: { version_number: mostRecentVersion.version_number },
    };
    const handleError = () => {
      showWarningToast(
        `There was an error deleting the current draft. Please try again and contact ${DEFAULT_SUPPORT_EMAIL} if the problem persists.`,
      );
    };
    if (isExplore) {
      dispatch(
        deleteCurrentDashboardDraft(
          args,
          () => {
            if (viewingResourceVersion?.version_number === mostRecentVersion.version_number)
              dispatch(
                switchCurrentlyEditingDashboardVersion({
                  dashboardVersion: versions[1] as DashboardVersion,
                }),
              );
          },
          handleError,
        ),
      );
    } else {
      dispatch(deleteCurrentReportBuilderDraft(args, undefined, handleError));
    }
  };

  if (!currentResourceId) return null;

  return (
    <Modal
      isOpen
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      size="large"
      title="Changelog">
      {versions.length > 0 ? (
        <div className={sprinkles({ paddingX: 'sp3' })}>
          {doesUserHavePermission(permissions[permissionResource], PERMISSIONED_ACTIONS.UPDATE) ? (
            mostRecentVersion.is_draft ? (
              <div className={draftContainerClass}>
                <div className={sprinkles({ heading: 'h4', marginBottom: 'sp1.5' })}>
                  Current Draft
                </div>
                <div
                  className={sprinkles({ display: 'flex', alignItems: 'flex-start', gap: 'sp1' })}>
                  <Input
                    disabled
                    defaultValue={`${mostRecentVersion.version_number}`}
                    label="Version"
                    onSubmit={() => null}
                    style={{ width: 70 }}
                  />

                  <TextArea
                    noResize
                    className={sprinkles({ flex: 1 })}
                    label="Description"
                    onChange={setDraftChangeComment}
                    placeholder="Describe what is different"
                    style={{ height: 72 }}
                    value={draftChangeComment}
                  />
                  <div
                    className={sprinkles({ flexItems: 'column', gap: 'sp1', paddingTop: 'sp2.5' })}>
                    <Button
                      disabled={!draftChangeComment || publishLoading}
                      loading={publishLoading}
                      onClick={handlePublish}
                      variant="primary">
                      Save Version
                    </Button>

                    {previousVersion &&
                    (!('deprecated' in previousVersion) || !previousVersion.deprecated) ? (
                      <DeleteConfirmationButton
                        fillWidth
                        loading={deleteLoading}
                        onDelete={handleDelete}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className={cx(draftContainerClass, sprinkles({ flexItems: 'center' }))}>
                No current draft
              </div>
            )
          ) : null}

          {versions.map((version) => {
            if (version.is_draft) return null;
            return (
              <VersionPanel
                canRevert={version.version_number !== mostRecentVersion.version_number}
                closeModal={closeModal}
                isDraft={mostRecentVersion.is_draft}
                key={`version-modal-version-${version.id}`}
                pageType={pageType}
                resourceId={currentResourceId}
                tags={tags}
                userPermissions={permissions[permissionResource]}
                version={version}
              />
            );
          })}
        </div>
      ) : (
        <Spinner fillContainer size="lg" />
      )}
    </Modal>
  );
};

const draftContainerClass = sprinkles({
  backgroundColor: 'elevationMid',
  padding: 'sp1.5',
  borderRadius: 4,
  marginBottom: 'sp1.5',
});

import { Route, Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { ROUTES } from 'constants/routes';

import { PageWithNavbar } from 'components/pages/pagewithNavBar';
import { SIGNUP_INFO_NEXT } from 'constants/onboardingConstants';
import { User } from 'actions/userActions';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageComponent: any;
  permissionFn?: (user: User) => boolean;
  withNavigation?: boolean;
  noMatch?: boolean;
  activeTabId?: string;
  exact?: boolean;
  path?: string;
};

const PrivateRoute = ({
  pageComponent: Component,
  withNavigation,
  noMatch,
  permissionFn,
  activeTabId,
  exact,
  path,
}: Props) => {
  const { currentUser, signupInfoCollectionStep } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      signupInfoCollectionStep: state.onboarding.signupInfoCollectionStep,
    }),
    shallowEqual,
  );

  const routeComponent = (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        // if the user isn't logged in, send them to the login page if they're trying to go to a page
        // that requires a session
        if (!currentUser.logged_in) {
          if (
            props.location.pathname === ROUTES.LOGIN ||
            props.location.pathname === ROUTES.CHECK_YOUR_EMAIL ||
            props.location.pathname === ROUTES.VERIFY_EMAIL
          )
            return <Component {...props} />;

          // otherwise redirect them to login
          return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        }
        // otherwise if the user doesn't have a team, send them to the join team page
        else if (!currentUser.has_team) {
          if (props.location.pathname === ROUTES.JOIN_TEAM) return <Component {...props} />;
          return <Redirect to={{ pathname: ROUTES.JOIN_TEAM }} />;
        }
        // otherwise if the user's team is deactivated, send them to the billing page
        else if (currentUser.team?.payment_plan === PLAN_TYPES.DEACTIVATED) {
          if (props.location.pathname === ROUTES.BILLING_PAGE) return <Component {...props} />;
          return <Redirect to={{ pathname: ROUTES.BILLING_PAGE }} />;
        }
        // otherwise if the user hasn't finished the signup survey, send them to the sign up survey
        else if (signupInfoCollectionStep !== SIGNUP_INFO_NEXT.DONE) {
          if (props.location.pathname === ROUTES.TELL_US_ABOUT_YOU) return <Component {...props} />;
          return <Redirect to={{ pathname: ROUTES.TELL_US_ABOUT_YOU }} />;
        }
        // otherwise if the user is trying to hit the sign up form, send them home
        else if (props.location.pathname === ROUTES.TELL_US_ABOUT_YOU) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        }
        // otherwise, if the user is going to the login page, send them to the home page
        else if (props.location.pathname === ROUTES.LOGIN) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        }
        // otherwise, check generic permissions on the page to see if they can go there
        else if (permissionFn) {
          if (!permissionFn(currentUser)) {
            return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
          }

          return <Component {...props} />;
        }
        // otherwise, if they're not going to a valid page, send them to the home page
        else if (noMatch) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        }
        // otherwise, send them to whatever page they were going to
        else {
          return <Component {...props} />;
        }
      }}
    />
  );

  return withNavigation ? (
    <PageWithNavbar activeTabId={activeTabId}>{routeComponent}</PageWithNavbar>
  ) : (
    routeComponent
  );
};

export default PrivateRoute;

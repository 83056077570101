import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseSaveModal } from 'pages/ReportBuilder/ModalViews/BaseSaveModal';

import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { setSelectedTab } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { createCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';

type Props = { builtInId: string; builtInName: string; isIframe: boolean };

export const SaveBuiltInAsReportModal: FC<Props> = ({ builtInName, builtInId, isIframe }) => {
  const dispatch = useDispatch();

  const currentConfig = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.currentConfig,
  );

  if (!currentConfig) return null;

  const handleSubmit = (reportName: string) => {
    dispatch(setSelectedTab({ tab: ReportBuilderTab.ALL_REPORTS }));
    dispatch(closeReportModal());
    dispatch(
      createCustomerReportThunk({
        name: reportName,
        config: currentConfig,
        builtInId,
      }),
    );
  };

  return (
    <BaseSaveModal
      allowSameName
      isIframe={isIframe}
      onSubmit={handleSubmit}
      reportName={`${builtInName} Copy`}
    />
  );
};

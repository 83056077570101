import {
  OPERATION_TYPES,
  CHART_VISUALIZATION_OPERATIONS_SET,
  VisualizeOperationInstructions,
} from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { replaceVariablesInString } from './dataPanelConfigUtils';

export const convertHexToRGBA = (hexString: string, alpha: number) => {
  const hex = hexString.substr(1, 10);
  let c;
  let rgbaString = 'rgba(';
  for (let i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    rgbaString += `${c},`;
  }
  rgbaString += `${alpha})`;
  return rgbaString;
};

export const titleCase = (s: string | number) => {
  if (!s) return '';

  s = String(s);

  s = s.replace(/_/g, ' ');

  // WIP - camel case to title case
  // if (s.indexOf(' ') === -1) {
  //   s = s.replace(/([A-Z])/g, ' $1');
  // }
  return capitalizeEachWord(s);
};

const capitalizeEachWord = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getChartTooltipText = (
  opType: OPERATION_TYPES,
  vizInstructions: VisualizeOperationInstructions,
  variables: DashboardVariableMap,
): string | undefined => {
  let format: { showTooltip?: boolean; tooltipText?: string } | undefined;
  if (CHART_VISUALIZATION_OPERATIONS_SET.has(opType)) {
    if (
      opType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      format = vizInstructions.V2_KPI?.generalFormat;
    } else if (opType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      format = vizInstructions.V2_KPI_TREND?.generalFormat;
    } else if (opType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      format = vizInstructions.V2_SCATTER_PLOT?.generalFormat;
    } else if (opType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      format = vizInstructions.V2_BOX_PLOT?.generalFormat;
    } else if (opType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP) {
      format = vizInstructions.VISUALIZE_GEOSPATIAL_CHART?.generalFormat;
    } else {
      format = vizInstructions.V2_TWO_DIMENSION_CHART?.generalFormat;
    }
  } else if (opType === OPERATION_TYPES.VISUALIZE_TABLE) {
    format = vizInstructions.VISUALIZE_TABLE.generalFormat;
  } else if (opType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
    format = vizInstructions.VISUALIZE_PIVOT_TABLE?.generalFormat;
  } else if (opType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
    format = vizInstructions.VISUALIZE_COLLAPSIBLE_LIST?.generalFormat;
  } else if (opType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
    format = vizInstructions.VISUALIZE_PIVOT_TABLE_V2?.generalFormat;
  }
  if (!format?.showTooltip || !format.tooltipText) return;

  return replaceVariablesInString(format.tooltipText, variables);
};

import { FC, useEffect, useState } from 'react';
import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import { Icon, Checkbox, Intent, Tag, sprinkles, Select } from 'components/ds';

import { ParentSchema, DataSource } from 'actions/dataSourceActions';
import { AccessGroup } from 'actions/teamActions';
import { updateAccessGroups } from 'actions/rolePermissionActions';
import { showErrorToast } from 'shared/sharedToasts';

type Props = {
  accessGroup: AccessGroup;
  scheme: ParentSchema;
  dataSources: DataSource[];
  defaultDataSources: number[];
};

export const SettingsAccessGroupsSchemaSection: FC<Props> = ({
  scheme,
  dataSources,
  defaultDataSources,
  accessGroup,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [accessGroupDataSourceIds, setAccessGroupDataSourceIds] = useState(
    accessGroup.data_source_ids,
  );
  const [defaultDataSourceIds, setDefaultDataSourceIds] = useState(defaultDataSources);

  useEffect(
    () => setAccessGroupDataSourceIds(accessGroup.data_source_ids),
    [accessGroup.data_source_ids],
  );
  useEffect(() => setDefaultDataSourceIds(defaultDataSources), [defaultDataSources]);

  const dispatch = useDispatch();

  const dataSourceOptions = (dataSources || []).map((dataSource) => ({
    value: dataSource.id.toString(),
    label: dataSource.name,
  }));

  const defaultDataSource = dataSourceOptions.find((dataSource) =>
    defaultDataSourceIds.includes(parseInt(dataSource.value)),
  );

  const updateDefaultDataSource = (newDefaultId: string) => {
    const newDefaults = cloneDeep(defaultDataSourceIds);
    if (defaultDataSource?.value !== undefined) {
      // remove old default
      newDefaults.splice(defaultDataSourceIds.indexOf(parseInt(defaultDataSource.value)), 1);
    }

    newDefaults.push(parseInt(newDefaultId));

    dispatch(
      updateAccessGroups(
        {
          postData: {
            access_groups: [
              {
                access_group_id: accessGroup.id,
                default_data_source_ids: newDefaults,
              },
            ],
          },
        },
        undefined,
        () => {
          showErrorToast('Something went wrong editing your access group. Please try again', 5);
          setDefaultDataSourceIds(defaultDataSourceIds);
        },
      ),
    );

    // optimistically display that the save worked, we revert if the save fails
    setDefaultDataSourceIds(newDefaults);
  };

  return (
    <div key={scheme.id}>
      <div
        className={sprinkles({ cursor: 'pointer', paddingBottom: 'sp1', heading: 'h4' })}
        onClick={() => setIsExpanded(!isExpanded)}>
        {scheme.name}
        <Icon
          className={sprinkles({ marginLeft: 'sp.5' })}
          name={isExpanded ? 'caret-down' : 'caret-right'}
        />
      </div>
      {isExpanded ? (
        <div>
          <Select
            infoText={
              defaultDataSource
                ? undefined
                : 'This access group does not have a default datasource selected. If no default is selected, this may result in errors loading data.'
            }
            label="Default Datasource"
            onChange={(item) => updateDefaultDataSource(item)}
            placeholder="Select a datasource"
            selectedValue={defaultDataSource?.value ?? undefined}
            values={dataSourceOptions}
          />
          {(dataSources ?? []).map((dataSource) => {
            const isDefault = defaultDataSource
              ? dataSource.id === parseInt(defaultDataSource.value)
              : false;

            const isChecked = accessGroupDataSourceIds.includes(dataSource.id);

            return (
              <div
                className={sprinkles({
                  padding: 'sp.5',
                  flexItems: 'alignCenter',
                  gap: 'sp1',
                  backgroundColor: { hover: 'blue4' },
                })}
                key={dataSource.id}>
                <Checkbox
                  disabled={isDefault}
                  isChecked={isDefault || isChecked}
                  label={dataSource.name}
                  onChange={() => {
                    const newDataSources = cloneDeep(accessGroupDataSourceIds);

                    if (isChecked) {
                      const oldIndex = newDataSources.indexOf(dataSource.id);

                      if (oldIndex !== undefined) newDataSources.splice(oldIndex, 1);
                    } else {
                      newDataSources.push(dataSource.id);
                    }

                    dispatch(
                      updateAccessGroups(
                        {
                          postData: {
                            access_groups: [
                              { access_group_id: accessGroup.id, data_source_ids: newDataSources },
                            ],
                          },
                        },
                        undefined,
                        () => {
                          showErrorToast(
                            'Something went wrong editing your visibility group. Please try again',
                            5,
                          );
                          setAccessGroupDataSourceIds(accessGroupDataSourceIds);
                        },
                      ),
                    );

                    // optimistically display that the save worked, we revert if the save fails
                    setAccessGroupDataSourceIds(newDataSources);
                  }}
                />
                <div className={sprinkles({ color: 'contentTertiary' })}>
                  {dataSource.source_type}
                </div>
                {isDefault ? <Tag intent={Intent.ACTIVE}>Default</Tag> : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from './types';
import { AllowlistDomain } from 'actions/teamActions';

type FetchAllowlistDomainsData = {
  whitelist_domains: AllowlistDomain[];
};

export const { actionFn: fetchAllowlistDomains, ...fetchAllowlistDomainsActions } =
  defineAPIAction<FetchAllowlistDomainsData>(
    ACTION.FETCH_ALLOWLIST_DOMAIN,
    'whitelist_domains',
    '',
    'GET',
  );

type CreateAllowlistDomainBody = {
  name: string;
  team_id: number;
};

type CreateAllowlistDomainData = {
  whitelist_domain: AllowlistDomain;
};

export const { actionFn: createAllowlistDomain, successAction: createAllowlistDomainSuccess } =
  defineAPIPostAction<CreateAllowlistDomainBody, CreateAllowlistDomainData>(
    ACTION.CREATE_ALLOWLIST_DOMAIN,
    'whitelist_domains',
    '',
    'POST',
  );

export const { actionFn: deleteAllowlistDomain, successAction: deleteAllowlistDomainSuccess } =
  defineAPIAction(ACTION.DELETE_ALLOWLIST_DOMAIN, 'whitelist_domains', '', 'DELETE');

import { FC, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { DatePickerInput } from 'components/DatePickerInput';
import { sprinkles } from 'components/ds';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardVariable, DateRangePickerElemConfig } from 'types/dashboardTypes';
import { DEFAULT_DATE_RANGES, DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES } from 'types/dateRangeTypes';
import { getDefaultRangeValues } from 'utils/dateUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { DateTime } from 'luxon';
import { REPORTED_ANALYTIC_ACTION_TYPES, SelectedDropdownInputItem } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { getValidDateRanges } from 'utils/dateRangeUtils';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

import * as styles from './DashboardDateRangePickerElement.css';

type Props = {
  className?: string;
  config: DateRangePickerElemConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  disabled?: boolean;
  withPortal?: boolean;
  noDropdown?: boolean;
  openElementToLeft?: boolean;
  timezone: string;
  isInContainer?: boolean;
  isEmbed?: boolean;
};

export const DashboardDateRangePickerElement: FC<Props> = ({
  className,
  config,
  disabled,
  isEmbed,
  isInContainer,
  noDropdown,
  onNewValueSelect,
  openElementToLeft,
  timezone,
  withPortal,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState<{
    startDate?: string | DateTime;
    endDate?: string | DateTime;
  }>(value);
  const [selectedDateRange, setSelectedDateRange] = useState<DEFAULT_DATE_RANGES>();
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);

  const dispatch = useDispatch();

  const startDate = currentValue?.startDate;
  const endDate = currentValue?.endDate;

  const startDateParsed =
    typeof startDate === 'string' ? dateTimeFromISOString(startDate) : startDate;
  const endDateParsed = typeof endDate === 'string' ? dateTimeFromISOString(endDate) : endDate;

  useEffect(() => {
    if (!value) return;
    const { startDate, endDate } = value;
    if (!startDate || !endDate || noDropdown) return;

    const preselectedRange = getPreselectedRange(startDateParsed, endDateParsed);

    setSelectedDateRange(preselectedRange);
    // Only want this to run on mount to set initial date range
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const getPreselectedRange = (startDate?: DateTime, endDate?: DateTime) => {
    if (!startDate || !endDate) return;

    const endDateTrunc = +endDate.startOf('day');
    return getValidDateRanges(config).find((range) => {
      const { startDate: rangeStart, endDate: rangeEnd } = getDefaultRangeValues(
        range,
        config.endDateEndOfDay,
        timezone,
      );

      return +rangeStart === +startDate && +rangeEnd.startOf('day') === endDateTrunc;
    });
  };

  const renderDropdown = () => {
    const options: SelectedDropdownInputItem[] = [];

    getValidDateRanges(config, true).forEach((range) =>
      options.push({ id: range, name: DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[range] ?? range }),
    );

    return (
      <DropdownSelect
        fillWidth
        isEmbed
        minimal
        showIcon
        containerClassName={cx(styles.rangeDropdown, {
          [styles.rangeOnly]: config.excludeDatePicker,
          [styles.comboControl]: !config.excludeDatePicker,
        })}
        disabled={disabled}
        filterable={false}
        label={config.label}
        labelHelpText={config.showTooltip ? config.infoTooltipText : undefined}
        noSelectionText="Select a range"
        onCancelClick={() => {
          setCurrentValue({});
          setSelectedDateRange(undefined);
          onNewValueSelect(undefined);
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED));
        }}
        onChange={(newValue) => {
          // eslint-disable-next-line prefer-const
          let { startDate: newStart, endDate: newEnd } = getDefaultRangeValues(
            newValue.id as DEFAULT_DATE_RANGES,
            config.endDateEndOfDay,
            timezone,
          );

          if (config.endDateEndOfDay) newEnd = newEnd.endOf('day');

          setCurrentValue({ startDate: newStart, endDate: newEnd });
          setSelectedDateRange(newValue.id as DEFAULT_DATE_RANGES);

          onNewValueSelect({ startDate: newStart, endDate: newEnd });
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED));
        }}
        options={options}
        selectedItem={
          selectedDateRange
            ? {
                id: selectedDateRange,
                name:
                  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[selectedDateRange] ?? selectedDateRange,
              }
            : undefined
        }
        showCancelBtn={!config.disableCancel && config.excludeDatePicker}
        usePortal={isInContainer}
      />
    );
  };

  const portalId: string | undefined = isInContainer ? dashboardLayoutTagId : undefined;

  return (
    <div className={cx(sprinkles({ flexItems: 'alignCenterBetween' }), className)}>
      {config.includeRangeDropdown && !noDropdown ? renderDropdown() : null}
      {!config.excludeDatePicker ? (
        <DatePickerInput
          selectsRange
          className={cx(styles.datePicker, {
            [styles.datePickerInput]: config.includeRangeDropdown,
          })}
          disabled={disabled}
          endDate={endDateParsed?.toLocal()}
          isEmbed={isEmbed}
          label={config.includeRangeDropdown || !isEmbed ? undefined : config.label}
          labelHelpText={
            config.showTooltip && !config.includeRangeDropdown ? config.infoTooltipText : undefined
          }
          monthsShown={2}
          onCalendarClose={() => {
            const readyToCompute = !!(startDateParsed && endDateParsed);

            if (!readyToCompute) setCurrentValue(value);
          }}
          onNewValueSelect={(date) => {
            if (!date) {
              setCurrentValue({});
              setSelectedDateRange(undefined);
              onNewValueSelect(undefined);
              return;
            }

            const startDate = Array.isArray(date) ? date[0] : date;
            let endDate = Array.isArray(date) ? date[1] : undefined;
            if (endDate && config.endDateEndOfDay) endDate = endDate.endOf('day');

            setCurrentValue({ startDate: startDate, endDate: endDate });
            setSelectedDateRange(undefined);

            if (startDate && endDate) {
              onNewValueSelect({ startDate: startDate.toUTC(), endDate: endDate.toUTC() });
            }
          }}
          openElementToLeft={openElementToLeft}
          portalId={portalId}
          selectedValue={startDateParsed?.toLocal()}
          showCancelBtn={!config.disableCancel}
          startDate={startDateParsed?.toLocal()}
          withPortal={!portalId ? withPortal : undefined}
        />
      ) : undefined}
    </div>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { Switch } from 'components/ds';

import { AreaChartFormat, V2TwoDimensionChartInstructions } from 'constants/types';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions;
  updateInstructions: (instructions: V2TwoDimensionChartInstructions) => void;
};

export const SharedAreaChartConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  updateInstructions,
}) => {
  const { chartSpecificFormat } = instructions;
  const { reverseGroupOrder, useGradientFill } = chartSpecificFormat?.areaChart ?? {};

  const updateAreaChartFormat = (areaChartUpdates: AreaChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        areaChart: { ...draft.chartSpecificFormat?.areaChart, ...areaChartUpdates },
      };
    });
    updateInstructions(newInstructions);
  };

  return (
    <>
      <Switch
        className={configInputClass}
        label="Reverse Group Order"
        onChange={() => updateAreaChartFormat({ reverseGroupOrder: !reverseGroupOrder })}
        switchOn={reverseGroupOrder}
      />
      <Switch
        className={configInputClass}
        label="Use Gradient Fill"
        onChange={() => updateAreaChartFormat({ useGradientFill: !useGradientFill })}
        switchOn={useGradientFill}
      />
    </>
  );
};

import * as RD from 'remotedata';

import { ReduxState } from 'reducers/rootReducer';
import { ComputedView, Namespace, TableView } from '@explo-tech/fido-api';
import { FidoReducerState } from 'reducers/fidoReducer';
import { ParentSchema, DataSource } from 'actions/dataSourceActions';
import { Dataset } from 'actions/datasetActions';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

// TODO potentially these utils can get moved to the fido reducer
export const getDefaultDataSource = (
  namespaceId: string,
  { teamData, dataSource, parentSchemas }: ReduxState,
) => {
  const schemas = parentSchemas.usedParentSchemas;
  const dataSources = dataSource.dataSources;
  const team = teamData.data;

  if (!RD.isSuccess(schemas) || !RD.isSuccess(dataSources) || !team) return null;

  const currentSchema = schemas.data.find((s) => s.fido_id === namespaceId);

  if (!currentSchema) return null;

  // We're fetching a preview, so just use an arbitrary access group to get the data source
  // to use. When we do permissioning, this will likely change
  const defaultDataSourceIds = team.access_groups[0].default_data_source_ids;

  return (
    dataSources.data.find(
      (d) => d.parent_schema_id === currentSchema?.id && defaultDataSourceIds?.includes(d.id),
    ) ?? null
  );
};

export const getDataSource = (
  {
    namespaceId,
    parentSchemaDataSourceMapping,
    dataSources,
    schemas,
    type,
  }: {
    namespaceId: string;
    parentSchemaDataSourceMapping?: Record<string, string>;
    dataSources?: DataSource[];
    schemas?: ParentSchema[];
    type: 'embedded' | 'app';
  },
  fido?: FidoReducerState,
) => {
  if (type === 'embedded') {
    return fido?.dataSourceMapping[namespaceId];
  }

  if (!schemas || !dataSources || !parentSchemaDataSourceMapping) return null;

  const schema = schemas.find((s) => s.fido_id === namespaceId);

  if (!schema) return null;

  const dataSourceId = parentSchemaDataSourceMapping[schema.id.toString()];
  return dataSources.find((d) => d.id.toString() === dataSourceId)?.fido_id;
};

export const getViewFromDatasetId = (
  computedViews: RD.ResponseData<ComputedViewWithIds[]>,
  datasets: Record<string, Dataset> | undefined,
  datasetId: string,
) => {
  const fidoId = Object.values(datasets ?? {}).find((d) => d.id === datasetId)?.fido_id;

  if (!fidoId || !RD.isSuccess(computedViews)) return null;

  return computedViews.data.find((view) => view.id === fidoId) ?? null;
};

/**
 * Returns true if the team is configured to use FIDO and the requested data source is configured to use FIDO.
 * This allows us to only use FIDO for certain data sources, even if the team is set to use FIDO, which helps with
 * rollout.
 */
export const useFidoForRequest = (
  requestInfo: Pick<
    DashboardLayoutRequestInfo,
    'useFido' | 'type' | 'parentSchemaDataSourceMapping'
  >,
  fido: FidoReducerState,
  dataset: Pick<Dataset | ReportBuilderDataset, 'parent_schema_id' | 'namespace_id'>,
) => {
  if (!requestInfo.useFido) return false;

  if (requestInfo.type === 'app') {
    // this shouldn't be true, but for safety
    if (!requestInfo.parentSchemaDataSourceMapping) return false;
    const dataSourceId = requestInfo.parentSchemaDataSourceMapping[dataset.parent_schema_id];

    return !!fido.embeddoDaos.dataSources?.find((d) => d.id.toString() === dataSourceId)?.fido_id;
  } else {
    return !!fido.dataSourceMapping[dataset.namespace_id ?? ''];
  }
};

export const shouldUseFidoForDefaultDataSoure = (
  schemaId: string,
  {
    teamData,
    dataSource,
    currentUser,
  }: Pick<ReduxState, 'teamData' | 'dataSource' | 'currentUser'>,
) => {
  if (!currentUser.team?.feature_flags.use_fido) return;

  const dataSources = dataSource.dataSources;
  const team = teamData.data;

  if (!RD.isSuccess(dataSources) || !team) return false;

  // We're fetching a preview, so just use an arbitrary access group to get the data source
  // to use. When we do permissioning, this will likely change
  const defaultDataSourceIds = team.access_groups[0].default_data_source_ids;

  return !!(
    dataSources.data.find(
      (d) => d.parent_schema_id.toString() === schemaId && defaultDataSourceIds?.includes(d.id),
    ) ?? null
  )?.fido_id;
};

// a little hackey, but the response type is always guaranteed to have id (and namespaceId) set, but the generated types don't
// extend this guarantee to the actual object in the response type
export type ComputedViewWithIds = ComputedView & { id: string; namespaceId: string };
export type TableViewWithIds = TableView & { id: string; namespaceId: string };
export type DataSourceWithIds = DataSource & { id: string; namespaceId: string };
export type NamespaceWithIds = Namespace & { id: string };

import * as RD from 'remotedata';

import { CustomerReportDataBody } from '../apiTypes';
import {
  Aggregation,
  CategoryChartColumnInfo,
  OPERATION_TYPES,
  SortInfo,
  SortOrder,
  StringDisplayOptions,
  StringDisplayFormat,
} from 'constants/types';
import {
  CustomerReportAgg,
  CustomerReportFilter,
  CustomerReportGroupBy,
  CustomerReportView,
  CustomerReportTotals,
} from 'actions/customerReportActions';
import { KPI_VIZ_OPS, NUMBER_TYPES } from 'constants/dataConstants';
import { BaseCol, AggColInfo } from 'types/columnTypes';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { useFidoForRequest } from 'utils/fido/fidoUtils';
import { Customer } from 'actions/teamActions';
import { User } from 'actions/userActions';
import { FidoReducerState } from 'reducers/fidoReducer';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

export const isTableVisualization = (visualization?: OPERATION_TYPES) =>
  !visualization || visualization === OPERATION_TYPES.VISUALIZE_TABLE;

/**
 * Tables with column group bys must always be pivoted
 * Otherwise, if usePivot is not set, pivot if there are group bys
 * Otherwise, pivot if usePivot is set to true and there are group bys
 */
export const isPivotView = (view: CustomerReportView) =>
  isTableVisualization(view.visualization) &&
  (!!view.columnGroupBys?.length || (view.usePivot !== false && !!view.groupBys?.length));

// Different types of visualization support different aggs and group bys
export const filterViewParams = (
  view: Pick<CustomerReportView, 'aggregations' | 'visualization' | 'groupBys' | 'columnGroupBys'>,
): FilteredViewParams => {
  let groupBys: CustomerReportGroupBy[] = [];
  const isTable = isTableVisualization(view.visualization);
  if (!view.visualization || isTable) {
    groupBys = view.groupBys || [];
  } else if (view.visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2) {
    groupBys = view.groupBys?.slice(0, 2) || [];
  } else if (!KPI_VIZ_OPS.has(view.visualization)) {
    groupBys = view.groupBys?.slice(0, 1) || [];
  }

  const aggs = view.aggregations || [];
  const canPivot = isTable && groupBys.length > 0 && aggs.length > 0;
  const columnGroupBys = (canPivot && view.columnGroupBys) || [];

  return {
    columnGroupBys,
    aggs,
    groupBys,
  };
};

type FilteredViewParams = {
  aggs: CustomerReportAgg[];
  groupBys: CustomerReportGroupBy[];
  columnGroupBys: CustomerReportGroupBy[];
};

export const CHART_ROW_LIMIT = 500;
export const PIVOT_ROW_LIMIT = 5000;

export type ViewRequestParams = {
  sort: SortInfo[];
  filters: CustomerReportFilter[];
  group_bys: CategoryChartColumnInfo[];
  columns: string[];
  hidden_columns: string[];
  aggs: CustomerReportAgg[];
  limit?: number;
};

export const getViewRequestParams = (
  view: CustomerReportView,
  dataset: ReportBuilderDataset,
): ViewRequestParams => {
  const { visualization, filters, hiddenColumns, columnOrder } = view;
  const { groupBys, columnGroupBys, aggs } = filterViewParams(view);

  const sort = isTableVisualization(visualization) ? view.sort : undefined;

  const limit = isPivotView(view)
    ? PIVOT_ROW_LIMIT
    : isTableVisualization(visualization)
    ? undefined
    : CHART_ROW_LIMIT;

  const customAggs: AggColInfo[] = [];
  aggs.forEach((agg) => {
    // For custom aggregations, use the latest formula in the dataset
    if (agg.agg.id === Aggregation.FORMULA) {
      const customAgg = dataset.customAggregations?.find(
        (customAgg) => customAgg.column.name === agg.column.name,
      );
      if (customAgg) customAggs.push(customAgg);
    } else customAggs.push(agg);
  });

  groupBys.forEach((groupBy) => {
    const displayFormatting = dataset.columnConfigs[groupBy.column.name]?.displayFormatting;
    const stringDisplayOptions = displayFormatting as StringDisplayOptions;
    if (
      stringDisplayOptions?.format !== StringDisplayFormat.LINK ||
      !stringDisplayOptions?.urlColumnName
    ) {
      return;
    }

    // For grouped linked columns, use the first linked value as the display name
    const urlCol = dataset.schema?.find((col) => col.name === stringDisplayOptions.urlColumnName);
    if (urlCol) customAggs.push({ column: urlCol, agg: { id: Aggregation.MAX } });
  });

  return {
    // Only pass complete filter clauses
    filters: filters.filter((clause) => !isFilterClauseIncomplete(clause)),
    group_bys: prepareGroupBysForFetch([...groupBys, ...columnGroupBys]),
    aggs: customAggs,
    sort: sort ?? [],
    columns: columnOrder,
    hidden_columns: hiddenColumns,
    limit,
  };
};
const prepareGroupBysForFetch = (groupBys: CustomerReportGroupBy[]): CategoryChartColumnInfo[] =>
  groupBys.map(({ column, bucket }) => {
    const colInfo: CategoryChartColumnInfo = { column };
    if (bucket) colInfo.bucket = { id: bucket };
    return colInfo;
  });

export const DISTINCT_COLUMN_LIMIT = 1000;
export function getDistinctColumnBody(column: BaseCol): CustomerReportDataBody {
  return {
    hidden_columns: [],
    group_bys: [{ column }],
    sort: [{ column, order: SortOrder.ASC }],
    filters: [],
    aggs: [],
    columns: [column.name],
    page: 1,
    limit: DISTINCT_COLUMN_LIMIT,
  };
}

export function getAggBody(columns: BaseCol[]): CustomerReportDataBody {
  return {
    hidden_columns: [],
    group_bys: [],
    sort: [],
    filters: [],
    aggs: columns.flatMap((column) =>
      NUMBER_TYPES.has(column.type)
        ? [
            { agg: { id: Aggregation.MIN }, column },
            { agg: { id: Aggregation.MAX }, column },
          ]
        : [],
    ),
    columns: columns.map((column) => column.name),
    page: 1,
    limit: 1,
  };
}

export function getTotalBody(
  columns: BaseCol[],
  filters: CustomerReportFilter[],
  totals: CustomerReportTotals,
): CustomerReportDataBody {
  return {
    hidden_columns: [],
    group_bys: [],
    sort: [],
    filters: filters.filter((clause) => !isFilterClauseIncomplete(clause)),
    aggs: columns
      .map((column) => ({ agg: { id: totals[column.name] }, column }))
      .filter((agg) => agg.agg.id),
    columns: columns.map((column) => column.name),
    page: 1,
    limit: 1,
  };
}

export const getDrilldownView = (
  viewConfig: CustomerReportView,
  drilldownFilters: CustomerReportFilter[],
  drilldownSort?: SortInfo[],
): CustomerReportView => ({
  ...viewConfig,
  name: `${viewConfig.name} Drilldown`,
  visualization: OPERATION_TYPES.VISUALIZE_TABLE,
  sort: drilldownSort,
  aggregations: [],
  groupBys: [],
  columnGroupBys: [],
  filters: [...viewConfig.filters, ...drilldownFilters],
});

export const useFidoForReportBuilderRequest = (
  customerId: number,
  customers: RD.ResponseData<Customer[]>,
  currentUser: User,
  fido: FidoReducerState,
  dataset: Pick<ReportBuilderDataset, 'fido_id' | 'parent_schema_id'>,
) => {
  const customer = RD.isSuccess(customers)
    ? customers.data.find((c) => c.id === customerId)
    : undefined;

  return useFidoForRequest(
    {
      type: 'app',
      useFido: currentUser?.team?.feature_flags.use_fido,
      parentSchemaDataSourceMapping: customer?.computed_parent_schema_datasource_mapping,
    },
    fido,
    dataset,
  );
};

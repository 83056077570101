import { cloneDeep, compact, isNumber, keyBy, map, orderBy, partition } from 'utils/standard';
import parse from 'url-parse';
import ReactGridLayout from '@explo-tech/react-grid-layout';
import produce from 'immer';

import {
  DashboardPageLayoutConfig,
  DashboardParam,
  DashboardVersionConfig,
} from 'types/dashboardVersionConfig';
import { DatasetSchema, DatasetColumn, DatasetRow } from 'types/datasets';
import { COLOR_CATEGORY_FILTER_SUFFIX, SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import {
  Aggregation,
  FilterValueSourceType,
  NumberDisplayOptions,
  OPERATION_TYPES,
  PivotOperationAggregation,
  UserTransformedSchema,
  VisualizeTableInstructions,
  VisualizePivotTableInstructions,
  SortOption,
  TrendGroupToggleOptionId,
} from 'constants/types';
import {
  ApplyFilterElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardElementConfig,
  DashboardVariable,
  DashboardVariableMap,
  DateGroupToggleConfig,
  DropdownValuesConfig,
  MetricsByColumn,
  NumberColumnMetrics,
  SelectElemConfig,
  TextDashboardElemConfig,
  VIEW_MODE,
  DASHBOARD_LAYOUT_CONFIG,
  ImageElemConfig,
} from 'types/dashboardTypes';
import { AGGREGATIONS_TYPES, DATE_PART_INPUT_AGG, NUMBER_TYPES } from 'constants/dataConstants';
import { areRequiredVariablesSet } from 'pages/dashboardPage/charts/utils/trendUtils';
import {
  getQueryTablesReferencedByText,
  isSecondaryDataRequiredForTableCol,
} from './dataPanelConfigUtils';
import { titleCase } from 'utils/graphUtils';
import {
  PeriodRangeTypes,
  PivotAgg,
  TREND_GROUP_OPTION_TO_PIVOT_AGG,
  TREND_GROUPING_OPTIONS,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { findDrilldownValue, isDrilldownVar } from 'utils/drilldownUtils';
import { dateTimeFromISOString } from './dateUtils';
import { DateTime } from 'luxon';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getDataPanelDatasetId } from './exploResourceUtils';
import {
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NUMBER,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperator,
} from 'types/filterOperations';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import { getSelectFilterDatasetId } from './filterUtils';
import {
  getDataPanelsUsingDataset,
  getDatasetsByName,
  getElementsUsingDataset,
} from './datasetUtils';
import { attachLinkFiltersToDp } from './filterLinking';
import { formatSmartBucket } from './smartGroupingUtils';
import { DatasetDataObject } from 'actions/datasetActions';
import { ArchetypeProperty } from 'actions/teamActions';

export const elemIdFromDropId = (dropId: string) => {
  return dropId.split('-element-')[1];
};

export const dataPanelsAdded = (oldDPs: DataPanel[], newDPs: DataPanel[]): DataPanel[] => {
  // They should have same referential equality so no need to check ids
  if (oldDPs === newDPs) return [];
  const oldIds = new Set(map(oldDPs, 'id'));
  return newDPs.filter((dp) => !oldIds.has(dp.id) && dp.id !== DRILLDOWN_DATA_PANEL_ID);
};

export const dashboardElementsAdded = (
  oldElems: DashboardElement[],
  newElems: DashboardElement[],
) => {
  if (oldElems === newElems) return [];
  const oldIds = new Set(map(oldElems, 'id'));

  return newElems.filter((elem) => !oldIds.has(elem.id));
};

export const getDatasetIdsFromElems = (elems: DashboardElement[]) =>
  elems.reduce<string[]>((acc, elem) => {
    if (SELECT_ELEMENT_SET.has(elem.element_type)) {
      const datasetId = getSelectFilterDatasetId(elem.config as SelectElemConfig);
      if (datasetId) acc.push(datasetId);
    }
    return acc;
  }, []);

export const datasetsChanged = (oldElems: DashboardElement[], newElems: DashboardElement[]) => {
  if (oldElems === newElems) return [];
  const oldDatasetIds = getDatasetIdsFromElems(oldElems);
  const newDatasetsIds = getDatasetIdsFromElems(newElems);

  const oldIdsSet = new Set(oldDatasetIds);
  return newDatasetsIds.filter((newId) => !oldIdsSet.has(newId));
};

export const getDashboardElemsWithDefaultQueryValues = (elems: DashboardElement[]) => {
  return elems.filter((elem) => {
    if (!SELECT_ELEMENT_SET.has(elem.element_type)) return false;

    const config = elem.config as SelectElemConfig;
    const datasetId = getSelectFilterDatasetId(config);
    return datasetId && config.valuesConfig.queryDefaultFirstValue;
  });
};

export const getDashboardElemsUsingDatasets = (
  elems: DashboardElement[],
  datasets: ResourceDataset[],
) => {
  const datasetIds = new Set(datasets.map((dataset) => dataset.id));
  return elems.filter((elem) => {
    if (!SELECT_ELEMENT_SET.has(elem.element_type)) return false;

    const filterDatasetId = getSelectFilterDatasetId(elem.config as SelectElemConfig);
    return filterDatasetId && datasetIds.has(filterDatasetId);
  });
};

export const getDatasetIdsForElems = (
  elems: DashboardElement[],
  datasets: Record<string, ResourceDataset>,
  excludeDefaultValueDatasets?: boolean,
) => {
  const datasetIdsToFetchForFilterElems: string[] = [];
  const datasetIdsToFetchForOtherElems: string[] = [];

  elems.forEach((dashboardElement) => {
    if (SELECT_ELEMENT_SET.has(dashboardElement.element_type)) {
      const config = dashboardElement.config as SelectElemConfig;
      const datasetId = getSelectFilterDatasetId(config);
      if (
        datasetId &&
        (!excludeDefaultValueDatasets || !config.valuesConfig.queryDefaultFirstValue) &&
        datasets[datasetId] // if dataset is no longer present, don't try to fetch it
      ) {
        datasetIdsToFetchForFilterElems.push(datasetId);
      }
    }

    if (dashboardElement.element_type === DASHBOARD_ELEMENT_TYPES.TEXT) {
      const text = (dashboardElement.config as TextDashboardElemConfig).text;
      getQueryTablesReferencedByText(text, getDatasetsByName(datasets)).forEach((id) =>
        datasetIdsToFetchForOtherElems.push(id),
      );
    }

    if (dashboardElement.element_type === DASHBOARD_ELEMENT_TYPES.IMAGE) {
      const url = (dashboardElement.config as ImageElemConfig).imageUrl;
      getQueryTablesReferencedByText(url, getDatasetsByName(datasets)).forEach((id) =>
        datasetIdsToFetchForOtherElems.push(id),
      );
    }
  });

  if (excludeDefaultValueDatasets) {
    const elemsWithDefault = getDashboardElemsWithDefaultQueryValues(elems || []);
    const datasetsWithDefaults = extractDatasetIdsFromElems(elemsWithDefault);
    const filteredFilterElemDatasets = datasetIdsToFetchForFilterElems.filter(
      (x) => !datasetsWithDefaults.has(x),
    );
    return new Set(filteredFilterElemDatasets.concat(datasetIdsToFetchForOtherElems));
  } else {
    return new Set(datasetIdsToFetchForFilterElems.concat(datasetIdsToFetchForOtherElems));
  }
};

export const extractDatasetIdsFromElems = (elems: DashboardElement[]) => {
  return new Set(
    compact(
      elems.map((elem) => {
        const config = elem.config as SelectElemConfig;
        return config.valuesConfig.queryTable?.id;
      }),
    ),
  );
};

export const findRowWithValue = (
  rows: DatasetRow[],
  key: string,
  value: DashboardVariable,
): DatasetRow | undefined => {
  if (!value) return;

  return rows.find((row) => row[key] === value);
};

export const getUrlSanitizedDashboardVars = (
  exportVars: DashboardVariableMap,
  archetypePropertyNames: Set<string>,
) => {
  const vars: { [key: string]: string } = {};

  Object.keys(exportVars).forEach((key) => {
    const variable = exportVars[key];
    if (
      variable === undefined ||
      archetypePropertyNames.has(key) ||
      key.startsWith('user_group.') ||
      key.startsWith('customer.') ||
      key.startsWith('properties.')
    )
      return;

    vars[key] = JSON.stringify(variable);
  });

  return vars;
};

export const getUrlParamStringFromDashVars = (
  exportVars: DashboardVariableMap,
  archetypePropertyNames: Set<string>,
): string => {
  const sanitizedVars = getUrlSanitizedDashboardVars(exportVars, archetypePropertyNames);

  // first convert the variable dictionary into a URL
  let dummyUrl = parse('https://example.com');
  dummyUrl.set('query', sanitizedVars);

  // then read in the generated URL and pull out the unparsed query
  dummyUrl = parse(dummyUrl.toString());
  return (dummyUrl.query as unknown as string) || '?';
};

function addBaseSchemaToTable<T extends DataPanel>(
  dp: T,
  datasets: Record<string, ResourceDataset>,
  datasetData: DatasetDataObject,
) {
  const datasetId = getDataPanelDatasetId(dp);
  const dataset = datasets[datasetId];
  const data = datasetData[datasetId];

  dp.visualize_op.instructions.VISUALIZE_TABLE.baseSchemaList =
    dataset?.schema ?? data?.schema ?? [];

  return dp;
}

export const removeUnsavedDashboardConfigFields = (config: DashboardVersionConfig) => {
  const cleanConfig = cloneDeep(config);

  if (DRILLDOWN_DATA_PANEL_ID in cleanConfig.data_panels) {
    delete cleanConfig.data_panels[DRILLDOWN_DATA_PANEL_ID];
  }

  cleanConfig.dashboard_layout?.forEach((layout) => {
    Object.keys(layout).forEach((layoutKey) => {
      // @ts-ignore
      if (layout[layoutKey] === undefined) delete layout[layoutKey];
    });
  });

  return cleanConfig;
};

export function prepareDataPanel(
  variables: DashboardVariableMap,
  dp: DataPanel,
  datasets: Record<string, ResourceDataset>,
  datasetData: DatasetDataObject,
  elements: DashboardElement[],
): DataPanel {
  return prepareDataPanelForFetch(variables, dp, datasets, datasetData, elements);
}

export function prepareDataPanelForFetch<T extends DataPanel>(
  variables: DashboardVariableMap,
  dp: T,
  datasets: Record<string, ResourceDataset>,
  datasetData: DatasetDataObject,
  elements: DashboardElement[],
  isSecondaryDataRequest?: boolean,
): T {
  return produce(dp, (draft) => {
    processUserInputConfig(variables, draft, datasets, elements || []);
    if (elements) attachLinkFiltersToDp(draft, datasets, elements, variables);
    if (
      !isSecondaryDataRequest &&
      (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE ||
        dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER)
    ) {
      addBaseSchemaToTable(draft, datasets, datasetData);
    }
  });
}

function processUserInputConfig<T extends DataPanel>(
  variables: DashboardVariableMap,
  dp: T,
  datasets: Record<string, ResourceDataset>,
  dashboardElements: DashboardElement[],
): T {
  const filterClauses = dp.filter_op?.instructions.filterClauses;
  filterClauses?.forEach((filterClause) => {
    if (filterClause.filterValueSource === FilterValueSourceType.VARIABLE) {
      const filterVarId = filterClause.filterValueVariableId;
      if (!filterVarId) {
        filterClause.filterValue = undefined;
        return;
      }

      let value = isDrilldownVar(filterVarId)
        ? findDrilldownValue(variables, filterVarId)
        : variables[filterVarId];

      if (filterClause.filterValueVariableProperty) {
        value = value ? value[filterClause.filterValueVariableProperty] : undefined;
      }

      if (value === undefined) {
        // if the variable value is undefined, meaning unset, then it doesn't matter what type
        // the operation or column is, just set the filter value to undefined
        filterClause.filterValue = undefined;
      } else if (filterClause.filterOperation && filterClause.filterColumn) {
        const filterOpId = filterClause.filterOperation.id;
        if (FILTER_OPS_DATE_PICKER.has(filterOpId)) {
          // operations that do date filters on a single date (ie date is after X)
          // have values that take the form of { startDate: x }
          filterClause.filterValue = {
            startDate: value as string,
          };
        } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOpId)) {
          const dateRangeValue = value as {
            startDate: string;
            endDate: string;
          };
          filterClause.filterValue = dateRangeValue;
        } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOpId)) {
          // There is no variable element that supports what the relative date picker does
          // so always make the value undefined if it is trying to use a variable for this filter
          filterClause.filterValue = undefined;
        } else if (
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_IN.id ||
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_NOT_IN.id
        ) {
          filterClause.filterValue = value as string[];
        } else if (
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.NUMBER_IS_IN.id ||
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.NUMBER_IS_NOT_IN.id
        ) {
          filterClause.filterValue = value as number[];
        } else {
          if (NUMBER_TYPES.has(filterClause.filterColumn.type)) {
            // if the column being filtered is a number, confirm the value is a number and set it.
            // otherwise set the value to undefined. If the value is not a number, that would result in
            // the SQL code crashing since we'd be trying to filter a number column with a non-number val
            if (isNumber(value) && !isNaN(value)) {
              filterClause.filterValue = value as number;
            } else {
              filterClause.filterValue = undefined;
            }
          } else {
            // similarly, if it is not a number string, ie a char field, then cast the value to a string
            // to prevent type errors on the SQL query when ran
            filterClause.filterValue = String(value);
          }
        }
      }
    }
  });

  const instructions = dp.visualize_op.instructions;
  const twoDimensionInstructions = instructions.V2_TWO_DIMENSION_CHART;

  const colorCategory = variables[dp.provided_id + COLOR_CATEGORY_FILTER_SUFFIX];
  if (twoDimensionInstructions?.colorColumnOptions?.length && colorCategory !== undefined) {
    twoDimensionInstructions.colorColumnOptions.forEach(
      (colOption) => (colOption.selected = colOption.column.name === colorCategory),
    );
  }

  if (twoDimensionInstructions?.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG) {
    if (twoDimensionInstructions.categoryColumn?.bucketElemId) {
      const selectedGroupInput = variables[twoDimensionInstructions.categoryColumn.bucketElemId];
      if (selectedGroupInput) {
        twoDimensionInstructions.categoryColumn.bucket = {
          id: TREND_GROUP_OPTION_TO_PIVOT_AGG[selectedGroupInput as TrendGroupingOptions]?.id,
        };
      }
    }
  }

  if (twoDimensionInstructions?.categoryColumn?.bucket?.id === PivotAgg.DATE_SMART) {
    formatSmartBucket(
      dp,
      datasets,
      dashboardElements,
      variables,
      filterClauses,
      twoDimensionInstructions.categoryColumn,
    );
  }

  if (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    const config = instructions.V2_KPI_TREND;
    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT &&
      config.periodColumn.rangeElemId
    ) {
      const rangeVariableValue = variables[config.periodColumn.rangeElemId] as {
        startDate: string;
        endDate: string;
      };

      if (rangeVariableValue) {
        // this should be coming in as a string generated by DateTime.toISO(), but just for safety
        // convert it back to a datetime and back to ISO to make sure the format is correct. fromISO()
        // can take a variety of formats, so this will standardize it
        config.periodColumn.customStartDate = dateTimeFromISOString(
          rangeVariableValue.startDate,
        ).toISO();
        config.periodColumn.customEndDate = dateTimeFromISOString(
          rangeVariableValue.endDate,
        ).toISO();
      } else {
        config.periodColumn.customStartDate = undefined;
        config.periodColumn.customEndDate = undefined;
      }
    } else if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN &&
      config.periodColumn.timePeriodElemId
    ) {
      const timePeriodVariableValue = variables[config.periodColumn.timePeriodElemId] as number;

      if (timePeriodVariableValue) {
        config.periodColumn.customStartDate = DateTime.local()
          .minus({ minutes: timePeriodVariableValue })
          .toISO();
        config.periodColumn.customEndDate = DateTime.local().toISO();
      } else {
        config.periodColumn.customStartDate = undefined;
        config.periodColumn.customEndDate = undefined;
      }
    }

    if (config?.trendGrouping === TrendGroupToggleOptionId) {
      if (config.trendGroupingElementId) {
        const newTrend = variables[config.trendGroupingElementId];
        config.trendGrouping = (newTrend ?? TrendGroupingOptions.WEEKLY) as TrendGroupingOptions;
      }
    }

    return dp;
  }

  return dp;
}

export const areRequiredUserInputsSet = (variables: DashboardVariableMap, dp: DataPanel) => {
  if (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    return areRequiredVariablesSet(variables, dp.visualize_op.instructions.V2_KPI_TREND);
  }

  return true;
};

export const getAsynchronousSecondaryDataInstructions = (
  dataPanel: DataPanel,
  dataset: ResourceDataset,
): DataPanel[] => {
  switch (dataPanel.visualize_op.operation_type) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      return getSecondaryDataInstructionsForDataTable(dataPanel, dataset);
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      return [getSecondaryDataForNumberTrendInstructions(dataPanel)];
    default:
      return [];
  }
};

const getSecondaryDataForNumberTrendInstructions = (dataPanel: DataPanel) => {
  const newInstructions = cloneDeep(dataPanel);
  newInstructions.visualize_op.instructions.V2_KPI_TREND = {
    ...newInstructions.visualize_op.instructions.V2_KPI_TREND,
    ...{ trendGrouping: undefined },
  };
  return newInstructions;
};

const getSecondaryDataInstructionsForDataTable = (
  dataPanel: DataPanel,
  dataset: ResourceDataset,
): DataPanel[] => {
  const displayOptions = dataPanel.visualize_op.instructions.VISUALIZE_TABLE.schemaDisplayOptions;

  if (!displayOptions) return [];

  const columnsWithDisplayOptions = Object.keys(displayOptions);
  const numberColumnsWithDisplayOptions = columnsWithDisplayOptions.filter((columnName) =>
    dataset.schema?.find((column) => column.name === columnName && NUMBER_TYPES.has(column.type)),
  );
  const columnNames = numberColumnsWithDisplayOptions.filter((column) => {
    const columnDisplayOptions = displayOptions[column] as NumberDisplayOptions;
    return isSecondaryDataRequiredForTableCol(columnDisplayOptions);
  });

  // If no columns match, don't need to get secondary data
  if (columnNames.length === 0) return [];

  const secondaryDataInstructions = columnNames.map((columnName) => ({
    columnName,
    aggregations: [Aggregation.MIN, Aggregation.AVG, Aggregation.MAX],
  }));

  const aggregationDPT = cloneDeep(dataPanel);
  const pivotAggregations = getPivotAggregationsForSecondaryData(
    dataset,
    secondaryDataInstructions,
  );
  aggregationDPT.group_by_op.instructions.aggregations = pivotAggregations;

  return [aggregationDPT];
};

export const getSynchronousSecondaryDataInstructions = (
  dataPanel: DataPanel,
  sourceType: string | undefined,
  forJobQueue = false,
): DataPanel[] => {
  if (dataPanel.visualize_op.operation_type !== OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) return [];

  return getSynchronousSecondaryDataInstructionsForBoxPlot(dataPanel, sourceType, forJobQueue);
};

const getSynchronousSecondaryDataInstructionsForBoxPlot = (
  dataPanel: DataPanel,
  sourceType: string | undefined,
  forJobQueue: boolean,
): DataPanel[] => {
  /**
   * For BoxPlots backed by a Redshift DB or MySQL, we need to fetch batches of metrics for different calc columns
   * one at a time. So we don't want to overwrite secondaryData, but append to it instead.
   */
  if (sourceType !== 'redshift' && sourceType !== 'mysql' && !forJobQueue) {
    return [];
  }

  const calcColumns = dataPanel.visualize_op.instructions.V2_BOX_PLOT?.calcColumns;

  if (!calcColumns || calcColumns.length < 2) return [];

  const additionalCalcColumnsToFetch = calcColumns.slice(1);

  return additionalCalcColumnsToFetch.map((calcColumn) => {
    const dataPanelTemplateForCalcColumn = cloneDeep(dataPanel);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dataPanelTemplateForCalcColumn.visualize_op.instructions.V2_BOX_PLOT!.calcColumns = [
      calcColumn,
    ];

    return dataPanelTemplateForCalcColumn;
  });
};

const getPivotAggregationsForSecondaryData = (
  dataset: ResourceDataset,
  secondaryDataInstructions: {
    columnName: string;
    aggregations: Aggregation[];
  }[],
) => {
  const aggregations: PivotOperationAggregation[] = [];

  secondaryDataInstructions.forEach((instruction) => {
    const columnInfo = dataset.schema?.find((column) => column.name === instruction.columnName);

    instruction.aggregations.forEach((aggregation) => {
      aggregations.push({
        aggedOnColumn: columnInfo ?? null,
        type: AGGREGATIONS_TYPES[aggregation],
      });
    });
  });

  return aggregations;
};

export const updateUserInputFieldsWithNewElemName = (
  config: DashboardVersionConfig,
  oldName: string,
  newName: string,
) => {
  Object.values(config.data_panels).forEach((dpt) => {
    if (dpt.visualize_op?.instructions.V2_KPI_TREND?.periodColumn?.rangeElemId === oldName) {
      dpt.visualize_op.instructions.V2_KPI_TREND.periodColumn.rangeElemId = newName;
    }
    dpt.filter_op?.instructions.filterClauses.forEach((filterClause) => {
      if (filterClause.filterValueVariableId === oldName) {
        filterClause.filterValueVariableId = newName;
      }
    });

    if (
      dpt.visualize_op?.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucketElemId ===
      oldName
    ) {
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketElemId = newName;
    }
  });
};

export const updateUserInputFieldsWithDeletedElem = (
  config: DashboardVersionConfig,
  deletedElemIds: string[],
) => {
  if (deletedElemIds.length === 0) return;

  const isDeleted = (elemId: string | undefined): boolean => {
    if (elemId === undefined) return false;
    return deletedElemIds.includes(elemId);
  };
  /* eslint-disable  @typescript-eslint/no-non-null-assertion */
  Object.values(config.data_panels).forEach((dpt) => {
    if (isDeleted(dpt.visualize_op?.instructions.V2_KPI_TREND?.periodColumn?.rangeElemId)) {
      dpt.visualize_op.instructions.V2_KPI_TREND!.periodColumn!.rangeElemId = undefined;
      dpt.visualize_op.instructions.V2_KPI_TREND!.periodColumn!.periodRange =
        PeriodRangeTypes.LAST_4_WEEKS;
    }

    dpt.filter_op?.instructions.filterClauses.forEach((filterClause) => {
      if (isDeleted(filterClause.filterValueVariableId)) {
        filterClause.filterValueVariableId = undefined;
        filterClause.filterValueVariableProperty = undefined;
      }
    });

    if (
      isDeleted(dpt.visualize_op?.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucketElemId)
    ) {
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART!.categoryColumn!.bucketElemId =
        undefined;
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART!.categoryColumn!.bucket = {
        id: PivotAgg.DATE_MONTH,
      };
    }
  });
  /* eslint-enable  @typescript-eslint/no-non-null-assertion */
};

export const getMetricsByColumn = (secondaryData: DatasetRow[]): MetricsByColumn => {
  const metrics = secondaryData[0];
  if (!metrics) return {};

  const metricsByColumn: { [columnName: string]: NumberColumnMetrics } = {};

  Object.keys(metrics).forEach((columnNameAgg) => {
    const stringArr = columnNameAgg.split('_');
    const columnName = stringArr.slice(0, stringArr.length - 1).join('_');
    const agg = stringArr[stringArr.length - 1];

    metricsByColumn[columnName] = {
      ...metricsByColumn[columnName],
      [agg]: metrics[columnNameAgg],
    };
  });

  return metricsByColumn;
};

export const filterForValidFilterElementsBasedOnType = (
  dashboardElements?: DashboardElement[],
  dashboardParams?: Record<string, DashboardParam>,
  filterOperator?: FilterOperator,
  archetypeProperties?: ArchetypeProperty[],
) => {
  if (!filterOperator || !dashboardElements || !dashboardParams) return [];
  const params = Object.values(dashboardParams);
  const elemOptions: { id: string; name: string }[] = [];
  let dashElems: DashboardElement[] = [];
  let customVars: DashboardParam[] = [];

  if (FILTER_OPS_DATE_PICKER.has(filterOperator)) {
    dashboardElements.forEach((elem) => {
      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER) {
        dashElems.push(elem);
      } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
        const elemName = elem.name;
        elemOptions.push({ id: `${elemName}.startDate`, name: `${elemName}.startDate` });
        elemOptions.push({ id: `${elemName}.endDate`, name: `${elemName}.endDate` });
      }
    });
    customVars = params.filter((elem) => elem.type === 'TIMESTAMP');
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
    );
  } else if (FILTER_OPS_MULTISELECT.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT,
    );
  } else if (FILTER_OPS_STRING.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) =>
        elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.TOGGLE ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.TEXT_INPUT,
    );
    customVars = params.filter((elem) => elem.type === 'STRING');

    if (archetypeProperties) {
      archetypeProperties.forEach((prop) => {
        if (!prop.deprecated) elemOptions.push({ id: prop.name, name: prop.name });
      });
    }
  } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) =>
        elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.TOGGLE,
    );
    customVars = params.filter((elem) => elem.type === 'FLOAT' || elem.type === 'INTEGER');

    if (archetypeProperties) {
      archetypeProperties.forEach((prop) => {
        if (!prop.deprecated) elemOptions.push({ id: prop.name, name: prop.name });
      });
    }
  }
  const elems = dashElems.map((elem) => ({ id: elem.name, name: elem.name }));
  const custom = customVars.map((elem) => ({ id: elem.name, name: elem.name }));
  return elemOptions.concat(elems.concat(custom));
};

export const newOperatorShouldClearSelectedVariable = (
  newOperator?: FilterOperator,
  oldOperator?: FilterOperator,
) => {
  if (!newOperator || !oldOperator) return true;
  if (FILTER_OPS_DATE_PICKER.has(newOperator) !== FILTER_OPS_DATE_PICKER.has(oldOperator))
    return true;
  if (
    FILTER_OPS_DATE_RANGE_PICKER.has(newOperator) !== FILTER_OPS_DATE_RANGE_PICKER.has(oldOperator)
  )
    return true;
  if (FILTER_OPS_RELATIVE_PICKER.has(newOperator) !== FILTER_OPS_RELATIVE_PICKER.has(oldOperator))
    return true;
  return false;
};

export const newOperatorDoesntHaveVariableOption = (filterOperator?: FilterOperator) => {
  return !filterOperator || FILTER_OPS_RELATIVE_PICKER.has(filterOperator);
};

export const getDateGroupSwitchOptions = (config: DateGroupToggleConfig) => {
  return compact(
    Object.values(TREND_GROUPING_OPTIONS).map((groupingOption) => {
      const configForOption = config.groupingOptionByType?.[groupingOption.id];

      if (configForOption?.exclude) return null;

      return {
        name: configForOption?.name || groupingOption.name,
        id: groupingOption.id,
      };
    }),
  );
};

export const getDefaultValueForNewElem = (elem: DashboardElement) => {
  if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
    return TrendGroupingOptions.MONTHLY;
  } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH) {
    return 'false';
  }
};

export const getUserTransformedSchema = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions,
): UserTransformedSchema => {
  const changedSchema = getChangedSchema(schema, instructions);
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  const userTransformedSchema = changedSchema.map((column) => ({
    ...column,
    isVisible: !changeSchemaDictionary[column.name]?.hideCol,
  }));
  return userTransformedSchema;
};

export const getChangedSchema = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
) => {
  //Produce (can't change schema from state)
  const clonedSchema = cloneDeep(schema);

  const changeSchemaList = instructions.changeSchemaList || [];
  const changedSchema: DatasetSchema = [];
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  clonedSchema.forEach((columnInfo) => {
    const col = changeSchemaDictionary[columnInfo.name];
    const keepCol = col?.keepCol ?? true;
    if (col) {
      if (keepCol) {
        if (col.newColName !== null && col.newColName !== '') {
          columnInfo.friendly_name = col.newColName;
        } else {
          columnInfo.friendly_name = titleCase(columnInfo.name);
        }
        changedSchema.push(columnInfo);
      }
    } else {
      columnInfo.friendly_name = titleCase(columnInfo.name);
      changedSchema.push(columnInfo);
    }
  });
  return changedSchema;
};

export const getExcludedColumns = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions,
) => {
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  return schema.filter((columnInfo: DatasetColumn) => {
    const col = changeSchemaDictionary[columnInfo.name];
    if (col) return !col.keepCol;

    return false;
  });
};

export const getHiddenColumns = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions,
) => {
  const hiddenSet = new Set<string>();
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  schema.forEach((columnInfo) => {
    if (changeSchemaDictionary[columnInfo.name]?.hideCol) {
      hiddenSet.add(columnInfo.name);
    }
  });
  return hiddenSet;
};

export const removeUserDisabledColumns = (schema: UserTransformedSchema) => {
  return schema.filter((column) => column.isVisible);
};

export const getLayoutFromDashboardVersionConfig = (
  config: DashboardVersionConfig,
  viewMode: VIEW_MODE,
) => {
  const { pdf_layout, email_layout, mobile_layout } = config;
  if (viewMode === VIEW_MODE.PDF && pdf_layout) return pdf_layout;
  if (viewMode === VIEW_MODE.EMAIL && email_layout) return email_layout;
  if (viewMode === VIEW_MODE.MOBILE && mobile_layout) return mobile_layout;
  return config.dashboard_layout;
};

export const elementBlockedOnApplyButton = (
  dashboardElements: DashboardElement[],
  elemId?: string,
): string | undefined => {
  if (!elemId) return;

  for (let i = 0; i < dashboardElements.length; i++) {
    const elem = dashboardElements[i];
    if (elem.element_type === DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON) {
      const config = elem.config as ApplyFilterElemConfig;
      if (config.elementIds?.[elemId]) return elemId;
    }
  }
};

export const isElemDisabledByDependency = (
  config: DashboardElementConfig,
  variables: DashboardVariableMap,
  elementNamesById: Record<string, string>,
): boolean => {
  if (!config.dependencyElementIds) return false;

  const dependencyIds = Object.keys(config.dependencyElementIds).filter(
    (elemId) => config.dependencyElementIds?.[elemId],
  );

  if (dependencyIds.length === 0) return false;

  return dependencyIds.some((id) => {
    const elementName = elementNamesById[id];
    return elementName ? variables[elementName] === undefined : false;
  });
};

export const resetDependedElements = (
  elementId: string,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
): void => {
  elements.forEach((elem) => {
    const dependencies = elem.config.dependencyElementIds;
    if (dependencies && dependencies[elementId]) {
      if (variables[elem.name] !== undefined) {
        variables[elem.name] = undefined;
        resetDependedElements(elem.id, elements, variables);
      }
    }
  });
};

export const resetDependentBlockedElements = (
  elementId: string,
  elements: DashboardElement[],
  blockedVariables: DashboardVariableMap,
): void => {
  elements.forEach((elem) => {
    const dependencies = elem.config.dependencyElementIds;
    if (dependencies && dependencies[elementId]) {
      const elemNotInBlockedVars = !(elem.id in blockedVariables);
      if (elemNotInBlockedVars || blockedVariables[elem.id] !== undefined) {
        // produce doesn't actually set it as undefined if no value was set for it already
        // and we need the distinction for setting vars as undefined after applied
        if (elemNotInBlockedVars) blockedVariables[elem.id] = 'temp';

        blockedVariables[elem.id] = undefined;
        resetDependentBlockedElements(elem.id, elements, blockedVariables);
      }
    }
  });
};

export const isDatasetInUse = (
  datasetId: string,
  dashboardElements: DashboardElement[],
  dataPanels: DataPanelTemplate[],
) => {
  const elementsInUse = getElementsUsingDataset(dashboardElements, datasetId).map(
    (elem) => elem.name,
  );

  const dataPanelsInUse = getDataPanelsUsingDataset(dataPanels, datasetId).map(
    (dp) => dp.provided_id,
  );

  return { dataPanelsInUse, elementsInUse };
};

export const doesElementStartOnRightHalfOfPage = (
  dashboardLayout: ReactGridLayout.Layout[],
  elementId: string,
  dashboardWidth: number,
  containerXValue?: number,
) => {
  const layoutElem = dashboardLayout.find((elem) => elem.i === elementId);
  if (!layoutElem) return false;

  return layoutElem.x + (containerXValue ?? 0) >= dashboardWidth / 2;
};

export const doesDpEndOnRightHalfOfPage = (
  dashboardLayout: ReactGridLayout.Layout[],
  dpId: string,
  dashboardColumns: number,
  operationType: OPERATION_TYPES | undefined,
  containerXValue?: number,
) => {
  // Only necessary for table elements
  if (operationType !== OPERATION_TYPES.VISUALIZE_TABLE) return;
  const layoutElem = dashboardLayout.find((elem) => elem.i === dpId);
  if (!layoutElem) return false;

  return layoutElem.x + layoutElem.w + (containerXValue ?? 0) > dashboardColumns / 2;
};

export const getDefaultValueFromRows = (
  { querySortOption, queryDisplayColumn, queryValueColumn, querySortByValue }: DropdownValuesConfig,
  rows: DatasetRow[],
): { defaultValue?: string | number; defaultDisplay?: string } => {
  if (!queryValueColumn || rows.length === 0) return {};
  let sortedRows = rows;

  const searchColumn =
    queryDisplayColumn && !querySortByValue ? queryDisplayColumn.name : queryValueColumn.name;
  if (querySortOption && querySortOption !== SortOption.DEFAULT) {
    sortedRows = orderBy(
      rows,
      (row) => row[searchColumn],
      querySortOption === SortOption.ASC ? 'asc' : 'desc',
    );
  }

  return getValueFromRow(sortedRows[0], queryValueColumn.name, searchColumn);
};

export const getValueFromRow = (
  row: DatasetRow,
  valueColumn: string,
  displayColumn: string | undefined,
): { defaultValue: string | number; defaultDisplay: string } => {
  const defaultValue = row[valueColumn];
  const defaultDisplay = String(row[displayColumn ?? valueColumn]);
  return { defaultValue, defaultDisplay };
};

// returns True if the element was found and removed
export const removeElemFromStickyHeader = (
  config: DashboardPageLayoutConfig | undefined,
  elemIdToRemove: string,
) => {
  if (!config?.stickyHeader?.headerContentOrder) return;

  const removedColIndex = config.stickyHeader.headerContentOrder.findIndex(
    (elemId) => elemIdToRemove === elemId,
  );
  if (removedColIndex !== -1) {
    config.stickyHeader.headerContentOrder.splice(removedColIndex, 1);
  }

  return removedColIndex >= 0;
};

export function filterForContainerElements<T extends DashboardElement | DataPanel>(
  elements: T[],
  containerId?: string,
  filterElemsNotOnBody?: boolean,
) {
  const [elemsInContainer, elemsNotInContainer] = partition(elements, (element) => {
    if (filterElemsNotOnBody) {
      const elemConfig = element as DashboardElement;

      // checking if not in the body rather than if is in the header so that in the future
      // when we have multiple non-body interfaces, this still works
      if (
        elemConfig.elemLocation !== undefined &&
        elemConfig.elemLocation !== DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
      )
        return false;
    }

    return containerId
      ? element.container_id === containerId
      : element.container_id === undefined || element.container_id === null;
  });

  return {
    elemsInContainer,
    elemsNotInContainer,
  };
}

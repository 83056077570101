import { FC } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ImageAndText } from 'pages/ReportBuilder/Onboarding/Blocks/ImageAndText';
import ViewsImg from 'constants/images/report-builder/views.gif';

export const ViewsDoc: FC = () => (
  <ImageAndText alt="Views" img={ViewsImg}>
    <EmbedText body="b2">
      Each of your reports can have multiple views. Each view can contain one single table or
      visualization . Views all pull from the same data selection (link to data selection info).
      When you export or share your view, you can include all views in the same report together.
    </EmbedText>
  </ImageAndText>
);

import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

import { AllStates } from 'reducers/rootReducer';
import {
  Metadata,
  getEmbedSource,
  sendAnalyticsEventWrapper,
  shouldNotSendAnalytics,
} from 'utils/analyticsUtils';

type Thunk = ThunkAction<void, AllStates, unknown, AnyAction>;

export const sendAnalyticsEvent =
  (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata): Thunk =>
  async (_, getState) => {
    const {
      analyticsMetadata,
      analyticsToken,
      visitorId,
      embedType,
      analyticsProperties,
      environment,
    } = getState().analytics;
    if (
      !analyticsToken ||
      !analyticsMetadata ||
      !visitorId ||
      !embedType ||
      shouldNotSendAnalytics(embedType)
    )
      return;

    sendAnalyticsEventWrapper(
      visitorId,
      embedType,
      getEmbedSource(embedType),
      analyticsToken,
      eventName,
      analyticsMetadata,
      metadata,
      analyticsProperties,
      environment,
    );
  };

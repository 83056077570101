import { defineAPIAction, defineAPIPostAction } from './actionUtils';

import { ACTION } from './types';

export interface EmbedSecret {
  id: string;
  access_group_id: number;
}

export interface RawEmbedSecret {
  id: string;
  value: string;
}

export const { actionFn: fetchEmbedSecrets, ...fetchEmbedSecretsActions } = defineAPIAction<{
  embed_secrets: EmbedSecret[];
}>(ACTION.FETCH_EMBED_SECRETS, 'embed_secrets', '', 'GET');

export const { actionFn: createEmbedSecret, successAction: createEmbedSecretSuccess } =
  defineAPIPostAction<{ access_group_id: number }, { embed_secret: RawEmbedSecret }>(
    ACTION.CREATE_EMBED_SECRET,
    'embed_secrets',
    '',
    'POST',
  );

export const { actionFn: deleteEmbedSecret, successAction: deleteEmbedSecretSuccess } =
  defineAPIAction(ACTION.DELETE_EMBED_SECRET, 'embed_secrets', '', 'DELETE');

import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { EmbedText } from '../EmbedText';
import { Icon, sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';

import { openDataModal } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = {
  datasetDeleted?: boolean;
};

export const NoDataSelected: FC<Props> = ({ datasetDeleted }) => {
  const dispatch = useDispatch();

  const noDataText = datasetDeleted
    ? 'The dataset for this report is not available, please select a new dataset'
    : 'No data selected';
  const selectDataText = datasetDeleted ? 'Select a new dataset' : 'Select data';

  return (
    <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
      <Icon
        className={sprinkles({ marginBottom: 'sp2', color: 'contentTertiary' })}
        name="empty-set"
        size="lg"
      />
      <EmbedText body="b1" color="contentTertiary">
        {noDataText}
      </EmbedText>
      <EmbedButton onClick={() => dispatch(openDataModal(null))} variant="tertiary">
        {selectDataText}
      </EmbedButton>
    </div>
  );
};

import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { EmbedButton, EmbedPopover } from 'components/embed';
import { FilterDropdownMenu } from './FilterDropdownMenu';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { EMPTY_FILTER_CLAUSE } from 'constants/dataPanelEditorConstants';
import { FilterClause, FilterOperationInstructions, FilterValueType } from 'constants/types';
import { isFilterClauseIncomplete, listsAreDifferent } from 'utils/dataPanelConfigUtils';
import { FilterOperator } from 'types/filterOperations';

type Props = {
  isDrilldownModal: boolean;
  adHocFilterInfo: FilterOperationInstructions | undefined;
  error: boolean | undefined;
  loading: boolean | undefined;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  openPopoverToRight: boolean;
  schema: DatasetSchema;
};

export const FilterButton: FC<Props> = ({
  isDrilldownModal,
  adHocFilterInfo,
  error,
  loading,
  onAdHocFilterInfoUpdate,
  openPopoverToRight,
  schema,
}) => {
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [filterConfigs, setFilterConfigs] = useState<FilterClause[]>(
    adHocFilterInfo?.filterClauses?.length
      ? adHocFilterInfo.filterClauses
      : [{ ...EMPTY_FILTER_CLAUSE }],
  );

  const getValidFilters = useCallback(() => {
    return filterConfigs.filter((filter) => !isFilterClauseIncomplete(filter));
  }, [filterConfigs]);

  useEffect(() => {
    const validFilters = getValidFilters();
    if (adHocFilterInfo && listsAreDifferent(adHocFilterInfo.filterClauses, validFilters)) {
      onAdHocFilterInfoUpdate({ ...adHocFilterInfo, filterClauses: validFilters });
    }
  }, [filterConfigs, onAdHocFilterInfoUpdate, adHocFilterInfo, getValidFilters]);

  const onColumnSelect = (column: DatasetColumn, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      if (column.type !== prevState[idx].filterColumn?.type) {
        newState[idx] = { ...EMPTY_FILTER_CLAUSE, filterColumn: column };
      } else {
        newState[idx] = { ...newState[idx], filterColumn: column };
      }
      return newState;
    });
  };
  const onOperatorSelect = (operator: FilterOperator, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], filterOperation: { id: operator } };
      return newState;
    });
  };
  const onFilterValueUpdate = (value: FilterValueType, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], filterValue: value };
      return newState;
    });
  };
  const addFilter = () => {
    setFilterConfigs((prevState) => [...prevState, { ...EMPTY_FILTER_CLAUSE }]);
  };
  const deleteFilter = (idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState.splice(idx, 1);
      return newState;
    });
  };

  const validFilters = getValidFilters();

  return (
    <EmbedPopover
      align={openPopoverToRight ? 'start' : 'end'}
      disabled={!schema}
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      portalContainerId={isDrilldownModal ? undefined : dashboardLayoutTagId}
      trigger={
        <EmbedButton
          disabled={loading || !schema || (error && validFilters.length === 0)}
          icon="filter"
          variant={validFilters.length > 0 ? 'primary' : 'tertiary'}>
          {validFilters.length || ''}
        </EmbedButton>
      }>
      <FilterDropdownMenu
        addFilter={addFilter}
        deleteFilter={deleteFilter}
        filterConfigs={filterConfigs}
        onColumnSelect={onColumnSelect}
        onFilterValueUpdate={onFilterValueUpdate}
        onOperatorSelect={onOperatorSelect}
        schema={schema}
      />
    </EmbedPopover>
  );
};

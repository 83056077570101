import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconName } from 'components/ds/Icon';
import validator from 'validator';
import cx from 'classnames';
import { isLoading } from 'remotedata';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { DownloadStatus } from 'components/ExportOptions/DownloadStatus';
import { WebShareOption } from 'components/ExportOptions/WebShareOption';
import { ExportOptionItem } from 'components/ExportOptions/ExportOptionItem';
import { EmbedRadioGroup, EmbedButton } from 'components/embed';
import { Icon as BPIcon } from '@blueprintjs/core';
import { Icon, sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import * as styles from './exportStyles.css';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { DashboardVariableMap, ExportElemConfig } from 'types/dashboardTypes';
import { DownloadInfo, clearDownloads } from 'reducers/dashboardLayoutReducer';
import { WindowState } from 'utils/exportUtils';
import { DownloadSpreadsheetFunc, DownloadPanelFunc, ExportType } from 'actions/exportActions';
import { fetchDrilldownDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { AllStates } from 'reducers/rootReducer';
import { inputContainerBackgroundClass } from 'globalStyles/sharedStyles.css';
import { ExportConfig } from 'constants/types';

enum SHARE_METHODS {
  WEB = 'Share to Web',
  DOWNLOAD = 'Download',
  EMAIL = 'Send to email',
}

type ShareMethodType = {
  icon: IconName;
  shareMethod: SHARE_METHODS;
  shouldRender: boolean;
};

type ExportInfo = {
  currentView: SHARE_METHODS | undefined;
  type: ExportType | undefined;
};

export type WebShareProps = {
  config: ExportElemConfig;
  exportVars: DashboardVariableMap;
  isSharedView?: boolean;
};

type Props = {
  windowState: WindowState;
  downloadInfo: DownloadInfo | undefined;
  dataPanelId?: string;
  downloadDashboardImage?: (email?: string) => void;
  downloadDashboardPdf?: (email?: string) => void;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadPanelFunc;
  webShareProps?: WebShareProps;
  enableDrilldownModal?: boolean;
  exportConfig?: ExportConfig;
};

export const ExportOptions: FC<Props> = ({
  dataPanelId,
  downloadDashboardImage,
  downloadDashboardPdf,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  downloadInfo,
  webShareProps,
  windowState,
  enableDrilldownModal,
  exportConfig,
}: Props) => {
  const dispatch = useDispatch();

  const shouldUseJobQueue = useSelector((state: AllStates) =>
    'dashboardLayout' in state ? state.dashboardLayout.requestInfo.useJobQueue : false,
  );

  const [inputEmail, setInputEmail] = useState('');
  const [exportInfo, setExportInfo] = useState<ExportInfo>({
    currentView:
      downloadInfo?.type === 'email'
        ? SHARE_METHODS.EMAIL
        : downloadInfo?.type === 'url'
        ? SHARE_METHODS.DOWNLOAD
        : undefined,
    type: downloadInfo?.exportType,
  });

  const isDownloadLoading = isLoading(downloadInfo?.status);
  const isShareComponent = webShareProps !== undefined;
  const isExportEnabled = isShareComponent || !!exportConfig;

  const areBothShareExportsDisabled =
    webShareProps?.config.disableImageDownload && webShareProps?.config.disablePdfDownload;

  const canEmail = isShareComponent
    ? !areBothShareExportsDisabled
    : !!exportConfig?.enableEmailExport;

  const canDownload = isShareComponent
    ? !areBothShareExportsDisabled
    : !exportConfig?.disableDownloadExport;

  const shareMethods: ShareMethodType[] = [
    {
      icon: 'globe',
      shareMethod: SHARE_METHODS.WEB,
      shouldRender: isShareComponent && !webShareProps?.config?.disableShareLink,
    },
    {
      icon: 'arrow-down-to-bracket',
      shareMethod: SHARE_METHODS.DOWNLOAD,
      shouldRender: isExportEnabled && canDownload,
    },
    {
      icon: 'envelope',
      shareMethod: SHARE_METHODS.EMAIL,
      shouldRender: shouldUseJobQueue && isExportEnabled && canEmail,
    },
  ];
  const availableShareMethods = shareMethods.filter((method) => method.shouldRender);

  const getExportFunc = (): DownloadPanelFunc | undefined => {
    switch (exportInfo.type) {
      case ExportType.PDF:
        return isShareComponent ? downloadDashboardPdf : onDownloadPanelPdf;
      case ExportType.Image:
        return downloadDashboardImage;
      case ExportType.CSV:
        return (email) => onDownloadPanelSpreadsheet?.(ExportType.CSV, email);
      case ExportType.XLSX:
        return (email) => onDownloadPanelSpreadsheet?.(ExportType.XLSX, email);
    }
  };

  const renderOptions = () => {
    const exportOptions: ExportType[] = [];
    if (isShareComponent) {
      if (!webShareProps.config?.disablePdfDownload) exportOptions.push(ExportType.PDF);
      if (!webShareProps.config?.disableImageDownload) exportOptions.push(ExportType.Image);
    } else if (exportConfig) {
      if (!exportConfig.disablePdfDownload && (onDownloadPanelPdf || downloadDashboardPdf)) {
        exportOptions.push(ExportType.PDF);
      }
      if (!exportConfig.disableCsvExport) exportOptions.push(ExportType.CSV);
      if (!exportConfig.disableXlsxExport) exportOptions.push(ExportType.XLSX);
    }

    if (!exportOptions.length) return null;

    const defaultType = exportOptions.length > 0 ? exportOptions[0] : undefined;

    if (exportInfo.type === undefined) {
      setExportInfo({ currentView: exportInfo.currentView, type: defaultType });
    }

    return (
      <EmbedRadioGroup
        defaultValue={defaultType}
        disabled={isDownloadLoading}
        onValueChange={(newType) => setExportInfo({ ...exportInfo, type: newType as ExportType })}
        renderValue={(value) => value.toLocaleUpperCase()}
        value={exportInfo?.type}
        values={exportOptions}
      />
    );
  };

  const downloadHeader = () => (
    <div className={cx(styles.title, embedSprinkles({ body: 'primary' }))}>
      {isDownloadLoading ? null : (
        <Icon
          className={styles.backButton}
          name="arrow-left"
          onClick={() => {
            dispatch(clearDownloads(dataPanelId));
            setExportInfo({ currentView: undefined, type: undefined });
          }}
        />
      )}
      {exportInfo.currentView}
    </div>
  );

  const renderShareBody = () => {
    if (exportInfo.currentView === SHARE_METHODS.WEB && isShareComponent) {
      return (
        <WebShareOption
          onBackButtonClick={() => setExportInfo({ currentView: undefined, type: undefined })}
          webShareProps={webShareProps}
        />
      );
    } else if (
      exportInfo.currentView === SHARE_METHODS.DOWNLOAD ||
      exportInfo.currentView === SHARE_METHODS.EMAIL
    ) {
      const shouldEmail = exportInfo.currentView === SHARE_METHODS.EMAIL;

      const downloadFunc = getExportFunc();
      return (
        <div className={styles.root}>
          {downloadHeader()}
          {renderOptions()}
          {shouldEmail && (
            <InputGroup
              fill
              className={cx(
                sprinkles({ marginRight: 'sp.5' }),
                inputContainerBackgroundClass,
                GLOBAL_STYLE_CLASSNAMES.text.body.input,
              )}
              leftElement={<BPIcon icon="envelope" />}
              onInputChange={setInputEmail}
              placeholder="Enter an email..."
              value={inputEmail}
            />
          )}
          <EmbedButton
            fillWidth
            disabled={
              isDownloadLoading || !downloadFunc || (shouldEmail && !validator.isEmail(inputEmail))
            }
            onClick={() => downloadFunc?.(shouldEmail ? inputEmail : undefined)}
            variant="primary">
            {shouldEmail ? 'Send email' : 'Download'}
          </EmbedButton>
          <DownloadStatus downloadInfo={downloadInfo} windowState={windowState} />
        </div>
      );
    } else {
      return (
        <div
          className={cx(
            styles.menuContent,
            embedSprinkles({ backgroundColor: 'containerFill', color: 'primaryFont' }),
          )}>
          {enableDrilldownModal && dataPanelId ? (
            <ExportOptionItem
              icon="table"
              onClick={() => dispatch(fetchDrilldownDataThunk({ dataPanelId }))}
              option="Expose Underlying Data"
            />
          ) : null}
          {availableShareMethods.map(({ icon, shareMethod }) => (
            <ExportOptionItem
              rightArrow
              icon={icon}
              key={shareMethod}
              onClick={() => setExportInfo({ currentView: shareMethod, type: undefined })}
              option={shareMethod}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className={cx(
        embedSprinkles({ backgroundColor: 'containerFill', borderRadius: 'container' }),
        sprinkles({ overflow: 'hidden' }), //So that white background border radius doesn't cover outline
      )}>
      {renderShareBody()}
    </div>
  );
};

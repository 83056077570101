import { FC, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { AlertModal, APP_PORTAL_ID, Menu, MenuActionItem, MenuSeparator } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import * as styles from './styles.css';

import { ReduxState } from 'reducers/rootReducer';

import { ResourcePageType } from 'types/exploResource';
import { deleteFolder, Folder, renameFolder } from 'reducers/thunks/resourceThunks';

type FolderModal = 'rename' | 'delete';

type Props = {
  trigger: JSX.Element;
  pageType: ResourcePageType;
  folder: Folder;

  setLoadingStateForFolder?: (isLoading: boolean) => void;
};

export const FolderConfigurationMenu: FC<Props> = ({
  folder,
  pageType,
  setLoadingStateForFolder,
  trigger,
}) => {
  const dispatch = useDispatch();

  const { dashboardList, reportBuilders, folders } = useSelector(
    (state: ReduxState) => ({
      dashboardList: state.dashboard.dashboardList || [],
      reportBuilders: RD.getOrDefault(state.reportBuilder.reportBuilders, []),
      folders: state.folder.folders,
    }),
    shallowEqual,
  );

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const uniqueNames = useMemo(
    () =>
      new Set(
        (isExploreProduct ? [...dashboardList, ...folders] : [...reportBuilders, ...folders]).map(
          (r) => r.name,
        ),
      ),
    [dashboardList, reportBuilders, isExploreProduct, folders],
  );
  const [openModal, setOpenModal] = useState<FolderModal>();

  const onDeleteForAllUsers = () => {
    setLoadingStateForFolder?.(true);
    setOpenModal(undefined);
    dispatch(deleteFolder(folder.id));
  };

  return (
    <>
      <Menu
        align="end"
        className={styles.resourceConfigMenu}
        portalContainerId={APP_PORTAL_ID}
        trigger={trigger}>
        <MenuActionItem iconName="edit" onSelect={() => setOpenModal('rename')} text="Rename" />
        <MenuActionItem iconName="arrow-right" onSelect={() => undefined} text="Move" />
        <>
          <MenuSeparator />
          <MenuActionItem
            isDestructive
            disabled={folder.num_folders + folder.num_resources > 0}
            iconName="trash"
            onSelect={() => setOpenModal('delete')}
            text="Delete"
          />
        </>
      </Menu>
      <TextFieldModal
        buttonName="Save"
        closeModal={() => setOpenModal(undefined)}
        getErrorMessage={(input) => {
          if (input && input !== folder.name && uniqueNames.has(input)) {
            return 'Name cannot be duplicate name';
          }
        }}
        modalOpen={openModal === 'rename'}
        modalTitle={`Rename ${isExploreProduct ? 'Dashboard' : 'Report Builder'}`}
        onSubmit={(name) => {
          setLoadingStateForFolder?.(true);
          setOpenModal(undefined);
          dispatch(
            renameFolder({
              id: folder.id,
              name,
              onSuccess: () => setLoadingStateForFolder?.(false),
            }),
          );
        }}
        portalContainerId={APP_PORTAL_ID}
        resourceName={folder.name}
        textFieldPlaceholder="Dashboard name"
      />
      <AlertModal
        actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteForAllUsers }}
        isOpen={openModal === 'delete'}
        onClose={() => setOpenModal(undefined)}
        portalContainerId={APP_PORTAL_ID}
        title={`Are you sure you want to delete this ${
          isExploreProduct ? 'dashboard' : 'report builder'
        }?`}
      />
    </>
  );
};

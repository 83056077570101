import { DateTime } from 'luxon';

import { DATE_RELATIVE_OPTION } from 'constants/dataPanelEditorConstants';
import { KPIPeriodColumnInfo } from 'constants/types';
import { PeriodRangeTypes, PeriodComparisonRangeTypes } from 'types/dateRangeTypes';

/**
 * Returns the start and end date for the DateRelativeOption filter, which is configured
 * via the data panel config tab. Computes the previous/next offset calendar units of time.
 * This means that for weeks, months, and years, this computes the previous/next calendar
 * weeks (starting on Mondays), months, or years. For example, the next 2 weeks from
 * Tuesday August 15 2023, the start date would be Monday August 21, and the end date
 * would be EOD Sunday September 3.
 */
export const getDatesFromDateRelativeOption = (
  option: DATE_RELATIVE_OPTION,
  offset: number,
  isPrevious: boolean,
) => {
  const now = DateTime.now();

  let startDate: DateTime;
  let endDate: DateTime;
  switch (option) {
    case DATE_RELATIVE_OPTION.DAYS:
      if (isPrevious) {
        startDate = now.minus({ days: offset });
        endDate = now.minus({ days: 1 });
      } else {
        startDate = now.plus({ days: 1 });
        endDate = now.plus({ days: offset });
      }
      break;
    case DATE_RELATIVE_OPTION.WEEKS:
      if (isPrevious) {
        startDate = now.startOf('week').minus({ weeks: offset });
        endDate = now.endOf('week').minus({ weeks: 1 });
      } else {
        startDate = now.startOf('week').plus({ weeks: 1 });
        endDate = now.endOf('week').plus({ weeks: offset });
      }
      break;
    case DATE_RELATIVE_OPTION.MONTHS:
      if (isPrevious) {
        startDate = now.startOf('month').minus({ months: offset });
        endDate = now.endOf('month').minus({ months: 1 });
      } else {
        startDate = now.startOf('month').plus({ months: 1 });
        endDate = now.endOf('month').plus({ months: offset });
      }
      break;
    case DATE_RELATIVE_OPTION.YEARS:
      if (isPrevious) {
        startDate = now.startOf('year').minus({ years: offset });
        endDate = now.endOf('year').minus({ years: 1 });
      } else {
        startDate = now.startOf('year').plus({ years: 1 });
        endDate = now.endOf('year').plus({ years: offset });
      }
      break;
  }

  return { startDate: startDate.startOf('day'), endDate: endDate.endOf('day') };
};

/**
 * Given the current date, returns the end date of the period for a KPI trend. If this is a
 * custom range, then the user has provided an end date and we return that instead
 */
export const getPeriodEndDate = (periodCol: KPIPeriodColumnInfo, currentDate: DateTime) => {
  const periodRange = periodCol.periodRange;
  let datetime = currentDate;
  if (
    periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ||
    periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
  )
    return DateTime.fromISO(periodCol.customEndDate ?? '');
  if (periodRange === PeriodRangeTypes.PREVIOUS_MONTH)
    datetime = datetime.minus({ month: 1 }).endOf('month');

  return datetime
    .minus({ day: periodCol.trendDateOffset ?? 0 })
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
};

/**
 * Given the current date, returns the start date of the period for a KPI trend. If this is a
 * customer range, then the user has provided a start date and we return that instead. If
 * this is calculating the comparison period's start date, then this offsets the returned date
 * by the period. For example, if the start date is last week, but the comparison period is
 * previous year, this returns last week minus one year.
 */
export const getPeriodStartDate = (
  periodCol: KPIPeriodColumnInfo,
  currentDate: DateTime,
  comparison?: PeriodComparisonRangeTypes,
) => {
  const periodRange = periodCol.periodRange;
  let datetime = currentDate;

  switch (periodRange) {
    case PeriodRangeTypes.LAST_7_DAYS:
      datetime = datetime.minus({ day: 6 });
      break;
    case PeriodRangeTypes.LAST_4_WEEKS:
      datetime = datetime.minus({ week: 4 });
      break;
    case PeriodRangeTypes.PREVIOUS_MONTH:
      datetime = datetime.minus({ month: 1 }).startOf('month');
      break;
    case PeriodRangeTypes.LAST_3_MONTHS:
      datetime = datetime.minus({ month: 3 });
      break;
    case PeriodRangeTypes.LAST_12_MONTHS:
      datetime = datetime.minus({ month: 12 });
      break;
    case PeriodRangeTypes.MONTH_TO_DATE:
      datetime = datetime.startOf('month');
      break;
    case PeriodRangeTypes.YEAR_TO_DATE:
      datetime = datetime.startOf('year');
      break;
    case PeriodRangeTypes.CUSTOM_RANGE:
    case PeriodRangeTypes.DATE_RANGE_INPUT:
    case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
      datetime = DateTime.fromISO(periodCol.customStartDate ?? '');
  }

  switch (comparison) {
    case PeriodComparisonRangeTypes.PREVIOUS_YEAR:
      datetime = datetime.minus({ year: 1 });
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_MONTH:
      datetime = datetime.minus({ month: 1 });
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_PERIOD:
      datetime = datetime.minus({ days: Math.floor(currentDate.diff(datetime, 'days').days) });
      break;
    default:
      break;
  }

  // don't need to do anything else if it's a time period
  if (periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) return datetime;

  return datetime
    .minus({ day: periodCol.trendDateOffset ?? 0 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

/**
 * Calculates the period for a KPI trend. If a comparison range is passed, returns the
 * ENTIRE period, from the beginning of the comparison to the end of the current period
 */
export const getDateBetweenFilterValues = (
  periodCol: KPIPeriodColumnInfo,
  comparison?: PeriodComparisonRangeTypes,
) => {
  const currentDate = DateTime.now();

  // note that if a comparison is provided, then this is the start date of the
  // whole period, not just the current
  const startDate = getPeriodStartDate(periodCol, currentDate, comparison);
  const endDate = getPeriodEndDate(periodCol, currentDate);

  return { startDate, endDate };
};

import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchJobResults, JobQueueResult } from 'actions/jobQueueActions';
import { Jobs } from './types';
import { handleJobQueueResults } from 'utils/jobQueueUtils';

const DEFAULT_POLLING_INTERVAL_MILLISECONDS = 250;
const MAX_POLLING_INTERVAL_MILLISECONDS = 1000;

type Props = {
  updateJobResult: (
    jobResults: string[],
    onComplete: () => void,
    results: Record<string, JobQueueResult>,
  ) => void;
  awaitedJobs: Record<string, Jobs>;
  customerToken?: string;
  jwt?: string;
};

/**
 * A component that handles polling for job results. When there are jobs awaited (jobs with
 * null data), then the poller hits the backend to try to get those results. It then updates
 * the parent component's state of job results
 */
export const Poller: FC<Props> = ({ updateJobResult, awaitedJobs, customerToken, jwt }) => {
  const [isPolling, setIsPolling] = useState(false);
  const [pollingIntervalMs, setPollingIntervalMs] = useState(DEFAULT_POLLING_INTERVAL_MILLISECONDS);

  const dispatch = useDispatch();

  useEffect(() => {
    const awaitedJobIds = Object.keys(awaitedJobs);

    // only poll if we have awaited jobs and we're not currently waiting for results
    if (!isPolling && awaitedJobIds.length > 0) {
      setIsPolling(true);
      setPollingIntervalMs(Math.min(pollingIntervalMs * 2, MAX_POLLING_INTERVAL_MILLISECONDS));

      dispatch(
        fetchJobResults(
          { job_ids: awaitedJobIds, customerToken, jwt },
          ({ results }) =>
            updateJobResult(
              handleJobQueueResults(
                results,
                awaitedJobs,
                dispatch,
                customerToken !== undefined || jwt !== undefined,
              ),
              () => {
                setIsPolling(false);
                setPollingIntervalMs(DEFAULT_POLLING_INTERVAL_MILLISECONDS);
              },
              results,
            ),
          () => () => {
            setIsPolling(false);
            setPollingIntervalMs(DEFAULT_POLLING_INTERVAL_MILLISECONDS);
          },
          pollingIntervalMs,
        ),
      );
    }
  }, [awaitedJobs, isPolling, dispatch, customerToken, updateJobResult, pollingIntervalMs, jwt]);

  return null;
};

import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import { DataPanelListItem } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelListItem';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/ColumnSection.css';

import { CustomerReportAgg } from 'actions/customerReportActions';
import { Aggregation } from 'constants/types';
import { getAggUniqueId, getReportAggOptions } from 'utils/customerReportUtils';
import { AGGREGATIONS_TYPES, FLOAT, UNSIGNED_INTEGER } from 'constants/dataConstants';
import { deleteAgg, updateAgg } from 'reportBuilderContent/reducers/reportEditingReducer';
import { AGGS_SECTION_ID } from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { getAggColDisplay, getAggColConfig } from 'utils/V2ColUtils';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';

type AggregationItemProps = {
  aggregation: CustomerReportAgg;
  aggsByCol: Record<string, Set<Aggregation>>;
  columnConfigs?: ReportBuilderColConfigs;
};

export const AggItem: FC<AggregationItemProps> = ({ aggregation, aggsByCol, columnConfigs }) => {
  const dispatch = useDispatch();

  const id = getAggUniqueId(aggregation);
  const aggId = aggregation.agg.id;
  const selectedAgg = AGGREGATIONS_TYPES[aggId];
  const aggOptions = getReportAggOptions(aggregation, aggsByCol);
  const isCount = aggId === Aggregation.COUNT || aggId === Aggregation.COUNT_DISTINCT;
  const isFormula = aggId === Aggregation.FORMULA;

  return (
    <DataPanelListItem
      actionButton={{
        variant: 'tertiary',
        icon: 'trash',
        onClick: () => dispatch(deleteAgg(id)),
        className: styles.eyeIcon,
      }}
      column={aggregation.column}
      columnConfigs={columnConfigs}
      description={getAggColConfig(aggregation, columnConfigs)?.description}
      disabled={isFormula}
      icon={SCHEMA_TYPE_ICON_MAP[isCount ? UNSIGNED_INTEGER : FLOAT]}
      id={id}
      name={getAggColDisplay(aggregation, columnConfigs)}
      section={AGGS_SECTION_ID}>
      {isFormula ? null : (
        <EmbeddedDropdownMenu
          canSearch
          menuOptions={aggOptions}
          onClick={(newAgg) =>
            dispatch(updateAgg({ id, agg: { ...aggregation, agg: { id: newAgg as Aggregation } } }))
          }>
          <EmbeddedDropdownButton selectedName={selectedAgg.name} />
        </EmbeddedDropdownMenu>
      )}
    </DataPanelListItem>
  );
};

import { Layout } from '@explo-tech/react-grid-layout';

import { ACTION } from './types';
import { createAction } from '@reduxjs/toolkit';

// Dashboard

export const updateDashboardLayout = createAction<Layout[]>(ACTION.UPDATE_DASHBOARD_LAYOUT);

export const updateDashboardPdfLayout = createAction<Layout[]>(ACTION.UPDATE_DASHBOARD_PDF_LAYOUT);

export const updateDashboardEmailLayout = createAction<Layout[]>(
  ACTION.UPDATE_DASHBOARD_EMAIL_LAYOUT,
);

export const updateDashboardEmailText = createAction<{ html: string; isHeader: boolean }>(
  ACTION.UPDATE_DASHBOARD_EMAIL_TEXT,
);

export const updateDashboardMobileLayout = createAction<Layout[]>(
  ACTION.UPDATE_DASHBOARD_MOBILE_LAYOUT,
);

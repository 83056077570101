import { FC } from 'react';
import produce from 'immer';

import { Switch, Input, sprinkles } from 'components/ds';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

import { DashboardElementConfig, DashboardButtonElemConfig } from 'types/dashboardTypes';

type Props = {
  config: DashboardButtonElemConfig;

  updateConfig: (newConfig: DashboardElementConfig) => void;
  defaultText: string;
};

export const EmbedButtonConfigPanel: FC<Props> = ({ config, updateConfig, defaultText }) => {
  const updateButtonConfig = (update: (config: DashboardButtonElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  return (
    <>
      <div className={sprinkles({ marginY: 'sp1.5' })}>
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={config.buttonText ?? defaultText}
          label="Button Text"
          onSubmit={(newValue) => updateButtonConfig((draft) => (draft.buttonText = newValue))}
        />
        <Switch
          className={configInputClass}
          label="Show icon"
          onChange={() =>
            updateButtonConfig((draft) => (draft.hideRefreshIcon = !draft.hideRefreshIcon))
          }
          switchOn={!config.hideRefreshIcon}
        />
      </div>
    </>
  );
};

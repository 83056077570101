import { sha256 } from 'js-sha256';
import Cookies from 'js-cookie';
import Redux from 'redux';
import { parseDomain, ParseResultType } from 'parse-domain';

import { identify } from 'analytics/exploAnalytics';
import { validatePassword } from './passwordUtils';
import { RegisterUserSuccessCallback } from 'actions/authAction';
import { FetchProfileData } from 'actions/userActions';
import { showCustomToast, showErrorToast } from 'shared/sharedToasts';
import { getEnvironment } from 'utils/environmentUtils';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { Intent } from '@blueprintjs/core';

const submitHubspotSignUp = (signupEmail: string, firstName: string, lastName: string) => {
  if (getEnvironment() !== 'production') return;

  fetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/' +
      process.env.REACT_APP_HUBSPOT_PORTAL_ID +
      '/' +
      process.env.REACT_APP_HUBSPOT_ACCOUNT_SIGNUP_FORM_GUID,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value: signupEmail,
          },
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
        ],
        context: {
          hutk: getHubspotCookie(),
          pageUri: 'https://explo.co/',
          pageName: 'Explo',
        },
      }),
    },
  );
};

export const getHubspotCookie = () => {
  const cookies = '; ' + document.cookie;
  const cookiePart = cookies.split('; ' + process.env.REACT_APP_HUBSPOT_COOKIE_NAME + '=').pop();
  if (cookiePart) {
    return cookiePart.split(';').shift();
  } else {
    return '';
  }
};

export const pingCustomerOnlineMessage = (user: FetchProfileData) => {
  const deactivatedText =
    user.team?.payment_plan === PLAN_TYPES.DEACTIVATED ? '[DEACTIVATED] ' : '';
  const name = `${user.first_name} ${user.last_name} (${user.email})`;
  const teamName = user.team?.team_name || '';
  const env = process.env.REACT_APP_ENVIRONMENT;

  return `${name} from ${deactivatedText}${teamName} just logged into Explo (${env})`;
};

export const pingUserWithoutTeamMessage = (user: FetchProfileData) => {
  return `${user.first_name} ${user.last_name} (${user.email}) just logged into Explo without a team (${process.env.REACT_APP_ENVIRONMENT})`;
};

export const googleOAuthVerificationOnSuccess = (
  token: string,
  isFirstTimeSSOOnExistingAccount: boolean,
) => {
  // setting the user's token so they are authenticated for this browser
  Cookies.set('spheres_auth_token', token, { sameSite: 'Strict' });
  window.location.href = '/home';
  if (isFirstTimeSSOOnExistingAccount) {
    showCustomToast(
      <div>
        Our records show that you&rsquo;re setting up SSO with an existing account. If this
        isn&rsquo;t expected,{' '}
        <a href="/settings/profile" rel="noopener noreferrer" target="_blank">
          click here
        </a>{' '}
        to go to the settings page and reset your password.
      </div>,
      { timeoutInSeconds: 10, icon: 'info-sign', intent: Intent.NONE },
    );
  }
};

export const googleOAuthVerificationOnFailure = (errorMessage?: string) => {
  showErrorToast(errorMessage ?? 'SSO login failed');
};

export const googleOAuthVerificationOnScriptFailure = () => {
  showCustomToast(
    'You are in incognito mode, which means that Google SSO will not work. Please log in manually or exit incognito mode before using SSO',
    { timeoutInSeconds: 3 },
  );
};

export const userLoginAnalytics = (user: FetchProfileData) => {
  identify(user.id, {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    has_team: user.has_team,
  });
};

export const onRegistrationSubmit = (
  password: string,
  password2: string,
  signupEmail: string,
  firstName: string,
  lastName: string,
  registerUser: RegisterUserSuccessCallback,
  dispatch: Redux.Dispatch,
  setError: (errorMsg: string) => void,
  onError?: (errorMsg: string) => void,
) => {
  const passwordError = validatePassword(password, password2);

  if (!password || !password2 || !signupEmail || !firstName || !lastName) {
    setError('Please fill out all fields before submitting.');
  } else if (passwordError) {
    setError(passwordError);
  } else {
    submitHubspotSignUp(signupEmail, firstName, lastName);

    registerUser(
      firstName,
      lastName,
      signupEmail,
      sha256(password),
      sha256(password2),
      (response) => {
        if (Object.keys(response).length > 0) {
          const key = Object.keys(response)[0];
          onError && onError(response[key][0]);
          setError(response[key][0]);
        }
      },
    )(dispatch);
  }
};

const URL_REDIRECTS: Record<string, string> = {
  'agency.tydo.co': 'https://agency.tydo.co/portal',
  'portal.rethinkfood.org': 'https://portal.rethinkfood.org/portal',
  'portal.nostra.ai': 'https://portal.nostra.ai/portal',
  'portal.spekit.com': 'https://portal.spekit.com/portal',
  'app.roster.co': 'https://app.roster.co/portal',
  'explo.co': 'https://app.explo.co',
  'analytics.jupiter.co': 'https://analytics.jupiter.co/portal',
  'portal.tryonce.com': 'https://portal.tryonce.com/portal',
  'dashboard.bmg360.com': 'https://dashboard.bmg360.com/portal',
  'exploportal.anglehealth.com': 'https://exploportal.anglehealth.com/portal',
  'reporting.withtandem.com': 'https://reporting.withtandem.com/portal',
  'partner.withtandem.com': 'https://partner.withtandem.com/portal',
  'analytics.fermatcommerce.com': 'https://analytics.fermatcommerce.com/share',
};

export const redirectIfInvalidUrl = () => {
  // Custom subdomains should only be able to access the /share/ or /portal links, this ensures that.
  if (
    // for safety, defaulting this to a true value so that we're more likely to redirect
    getEnvironment() === 'production' &&
    process.env.REACT_APP_URL &&
    !window.location.href.includes(process.env.REACT_APP_URL) &&
    // the slash here but not on portal matters, unfortunately
    !window.location.pathname.includes('/share/') &&
    !window.location.pathname.includes('/portal')
  ) {
    const hostname = window.location.hostname;
    const urlRedirect = Object.keys(URL_REDIRECTS).find((url) => hostname.includes(url));

    // the most likely case, a domain that we've set to point to explo
    if (urlRedirect) {
      window.location.href = URL_REDIRECTS[urlRedirect];
    } else {
      const parseResult = parseDomain(hostname);

      if (parseResult.type === ParseResultType.Listed) {
        const newDomain = [parseResult.domain, ...parseResult.topLevelDomains].join('.');
        // base case, send them to their computed base domain
        window.location.href = `${window.location.protocol}//${newDomain}`;
      } else {
        // for safety, redirect to explo if there is no valid domain
        window.location.href = 'https://app.explo.co';
      }
    }
  }
};

import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { sprinkles, Button } from 'components/ds';

import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { selectDatasetForDrilldownFormatting } from 'utils/customEventUtils';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';

type Props = {
  options: VisualizeOperationGeneralFormatOptions;
  datasetId: string | number;
};

export const RawDataDrilldownConfig: FC<Props> = ({ options, datasetId }) => {
  const dispatch = useDispatch();

  return (
    <ConfigSubSectionWithSwitch
      label="Open a modal with a table of the underlying data"
      onChange={() => {
        const newFormat = produce(options, (draft) => {
          draft.enableRawDataDrilldown = !draft.enableRawDataDrilldown;
        });
        dispatch(updateGeneralFormatOptions(newFormat));
      }}
      switchOn={!!options.enableRawDataDrilldown}>
      <div className={sprinkles({ body: 'section' })}>Adjust the formatting of the data</div>
      <Button
        linkIcon
        onClick={() => {
          dispatch(clearSelectedItemThunk());
          selectDatasetForDrilldownFormatting(String(datasetId));
        }}
        variant="secondary">
        Open the data formatting panel
      </Button>
    </ConfigSubSectionWithSwitch>
  );
};

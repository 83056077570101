import { cloneDeep, pickBy, uniqBy } from 'utils/standard';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { Layout } from 'react-grid-layout';

import { clearEmptyPanels } from './utils';
import {
  DashboardParam,
  DashboardStickyHeaderConfig,
  DashboardVersionConfig,
  EditableSectionSettings,
} from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  OPERATION_TYPES,
  VisualizeTableInstructions,
  V2TwoDimensionChartInstructions,
  V2KPIChartInstructions,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2KPITrendInstructions,
  VisualizePivotTableInstructions,
  GROUPED_STACKED_OPERATION_TYPES,
  VisualizeCollapsibleListInstructions,
  BAR_CHART_TYPES,
  VisualizeGeospatialChartInstructions,
  COLUMN_FITS,
} from 'constants/types';
import {
  switchCurrentlyEditingDashboardVersion,
  publishNewDashboardVersionSuccess,
  clearDashboardConfigReducer,
  createDashboardDataPanel,
  duplicateDashboardItem,
  deleteDataPanel,
  createDashboardElement,
  updateElementConfig,
  deleteDashboardElement,
  saveDashboardElementUpdates,
  createDashboardDataset,
  toggleElementVisibilityForSecondaryLayout,
  toggleFilterLink,
  updateElementLocation,
  updateElementContainerLocation,
} from 'actions/dashboardV2Actions';
import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import {
  UpdateVisualizeOperationPayload,
  updateSelectedChart,
  updateVisualizeOperationAction,
  createFilterClause,
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
  updateFilterValue,
  updateFilterValueSource,
  updateFilterValueVariable,
  updateGeneralFormatOptions,
} from 'actions/dataPanelConfigActions';
import {
  BASE_CONFIG_BY_DASH_ELEM,
  DRAGGING_ITEM_CONFIG_BY_TYPE,
  EMPTY_DATA_PANEL_STATE,
} from 'constants/dashboardConstants';

import { EMPTY_FILTER_CLAUSE } from 'constants/dataPanelEditorConstants';
import {
  updateUserInputFieldsWithNewElemName,
  updateUserInputFieldsWithDeletedElem,
  newOperatorShouldClearSelectedVariable,
  newOperatorDoesntHaveVariableOption,
  getLayoutFromDashboardVersionConfig,
  removeElemFromStickyHeader,
} from 'utils/dashboardUtils';
import * as layoutUtils from 'utils/layoutUtils';
import * as namingUtils from 'utils/naming';
import {
  ContainerElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  VIEW_MODE,
  DATASET_EDITOR_MODE,
} from 'types/dashboardTypes';
import { fetchDashboardRequest, fetchDashboardSuccess } from 'actions/dashboardActions';
import {
  saveDatasetQuery,
  saveDraftDatasetQuery,
  deleteDataset,
  editDatasetName,
  updateDatasetDrilldownColConfig,
  updateDatasetDrilldownColConfigs,
  updateDashboardDatasetSchema,
  saveDraftComputedViewQuery,
  DatasetData,
  DatasetDataObject,
  Dataset,
  fetchEditorDatasetPreviewRequest,
  fetchEditorDatasetPreviewSuccess,
  fetchEditorDatasetPreviewError,
  fetchEditorDatasetRowCountSuccess,
  fetchEditorDatasetRowCountError,
  DrilldownConfig,
} from 'actions/datasetActions';
import {
  updateDashboardEmailLayout,
  updateDashboardEmailText,
  updateDashboardMobileLayout,
  updateDashboardPdfLayout,
  updateDashboardLayout,
} from 'actions/layoutActions';
import { VersionInfo } from 'types/exploResource';
import {
  addElementToLayout,
  getElementsById,
  removeElementsFromLayoutById,
} from 'utils/layoutResolverUtil';
import { saveResourceConfig } from 'utils/customEventUtils';
import { fetchDatasetSuccessDrilldownConfig, initConfig } from 'utils/drilldownDatasetUtils';
import { isValidOperationForFilter } from 'utils/filterOperations';
import { removeDataPanelsFromLinks, removeDatasetFromLinks } from 'utils/filterLinking';
import { saveExploreDraft } from './thunks/resourceSaveThunks';
import { createComputedView, deleteComputedView, saveComputedView } from './thunks/fidoThunks';
import {
  getEmbeddoResponseFromFidoResponse,
  getEmbeddoSchemaFromFidoSchema,
} from 'utils/fido/fidoShims';
import {
  clearSelectedDashboardItem,
  setDatasetEditorMode,
  setSelectedDashboardItem,
} from './dashboardInteractionsReducer';
import { fetchFidoViewPreview } from './thunks/dashboardDataThunks/fetchFidoDataThunks';
import {
  CustomerPermissionsForObject,
  initCustomerPermissionsForObject,
} from 'types/permissionTypes';
import { V2_VIZ_INSTRUCTION_TYPE } from 'constants/dataConstants';
import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import {
  fetchEditorDatasetPreviewPrimaryData,
  fetchEditorDatasetPreviewRowCount,
} from './thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { revertResourceToVersionThunk } from './thunks/versionManagementThunks';

export const DRILLDOWN_DATA_PANEL_ID = '_drilldown_data_panel';

type AddEditableSectionChartPayload = {
  id: string;
  name: string;
  datasetId: string;
  vizType: OPERATION_TYPES;
};

type EditableSectionModal =
  | { type: 'AddChart' }
  | { type: 'DeleteChart'; chartId: string }
  | { type: 'EditChartInfo'; chartId: string }
  | { type: 'EditChartPermissions'; chartId: string }
  | null;

interface DashboardEditConfigReducerState {
  config?: DashboardVersionConfig;
  versionInfo?: VersionInfo;
  editableSectionModal: EditableSectionModal;
  editingDataPanelId: string | null;
  editableSectionLayout: Layout[] | null;
  // Used for dataset editor
  datasetData: DatasetDataObject;
}

const initialState: DashboardEditConfigReducerState = {
  editableSectionModal: null,
  editingDataPanelId: null,
  editableSectionLayout: null,
  datasetData: {},
};

function actionWithEditingDPT(
  { config, editingDataPanelId }: DashboardEditConfigReducerState,
  actionFn: (editingDPT: DataPanelTemplate) => void,
) {
  if (!config || !editingDataPanelId) return;

  const dpToEdit =
    config.data_panels[editingDataPanelId] ??
    config.editable_section?.charts[editingDataPanelId]?.data_panel;

  if (!dpToEdit) return;
  actionFn(dpToEdit);
  dashboardUpdated();
}

const updateDatasetData = (
  state: DashboardEditConfigReducerState,
  id: string,
  func: (data: DatasetData) => void,
) => {
  const datasetData = state.datasetData[id];

  if (datasetData) func(datasetData);
};

const dashboardEditConfigSlice = createSlice({
  name: 'dashboardEditConfig',
  initialState,
  reducers: {
    updateDataPanelProvidedId: (state, { payload }: PayloadAction<string>) => {
      actionWithEditingDPT(state, (dp) => (dp.provided_id = payload));
    },
    updateDashboardParams: (state, { payload }: PayloadAction<Record<string, DashboardParam>>) => {
      if (!state.config) return;
      state.config.params = payload;
      dashboardUpdated();
    },
    updateDashboardCategoryColors: (state, { payload }: PayloadAction<Record<string, string>>) => {
      if (!state.config) return;
      state.config.category_colors = payload;
      dashboardUpdated();
    },
    updateStickyHeaderElementOrder: (state, { payload }: PayloadAction<string[]>) => {
      if (!state.config?.dashboard_page_layout_config?.stickyHeader) return;
      state.config.dashboard_page_layout_config.stickyHeader.headerContentOrder = payload;

      dashboardUpdated();
    },
    toggleStickyHeader: (state, { payload: dashboardId }: PayloadAction<number>) => {
      if (!state.config) return;
      if (!state.config.dashboard_page_layout_config) {
        state.config.dashboard_page_layout_config = {};
      }

      const hasStickyHeader = !!state.config.dashboard_page_layout_config.stickyHeader;

      const stickyHeader = state.config.dashboard_page_layout_config.stickyHeader ?? {};
      if (stickyHeader.enabled === undefined) stickyHeader.enabledExpandableFilterRow = true;
      stickyHeader.enabled = !stickyHeader.enabled;

      if (!hasStickyHeader) {
        const exportId = `dash${dashboardId}-${uuidv4()}`;

        const exportButtonConfig = {
          id: exportId,
          name: namingUtils.getDefaultElementName(DASHBOARD_ELEMENT_TYPES.EXPORT, state.config),
          element_type: DASHBOARD_ELEMENT_TYPES.EXPORT,
          config: { ...BASE_CONFIG_BY_DASH_ELEM[DASHBOARD_ELEMENT_TYPES.EXPORT] },
          elemLocation: DASHBOARD_LAYOUT_CONFIG.HEADER,
        };
        state.config.elements[exportId] = exportButtonConfig;
        stickyHeader.headerContentOrder = [exportId];
      }
      state.config.dashboard_page_layout_config.stickyHeader = stickyHeader;

      dashboardUpdated();
    },
    updateStickyHeader: (state, { payload }: PayloadAction<DashboardStickyHeaderConfig>) => {
      if (!state.config?.dashboard_page_layout_config) return;
      state.config.dashboard_page_layout_config.stickyHeader = payload;
      dashboardUpdated();
    },
    toggleEditableSection: (state) => {
      if (!state.config) return;
      if (!state.config.editable_section) {
        state.config.editable_section = {
          enabled: true,
          charts: {},
          default_layout: [],
          settings: { title: 'Your Overview' },
        };
      } else {
        state.config.editable_section.enabled = !state.config.editable_section.enabled;
      }

      dashboardUpdated();
    },
    setEditableSectionModal: (state, { payload }: PayloadAction<EditableSectionModal>) => {
      state.editableSectionModal = payload;
    },
    addEditableSectionChart: (
      state,
      { payload }: PayloadAction<AddEditableSectionChartPayload>,
    ) => {
      if (!state.config?.editable_section) return;

      const newDataPanel = EMPTY_DATA_PANEL_STATE(
        payload.id,
        payload.datasetId,
        payload.vizType,
        namingUtils.getDefaultPanelProvidedId(payload.vizType, state.config),
        payload.name,
      );

      state.config.editable_section.charts[payload.id] = {
        name: payload.name,
        permissions: initCustomerPermissionsForObject(),
        data_panel: newDataPanel,
      };
      state.editableSectionModal = null;
      state.editingDataPanelId = payload.id;

      dashboardUpdated();
    },
    deleteEditableSectionChart: (state) => {
      if (!state.config?.editable_section || state.editableSectionModal?.type !== 'DeleteChart')
        return;
      const chartId = state.editableSectionModal.chartId;
      const charts = state.config.editable_section.charts;
      if (chartId in charts) delete charts[chartId];
      state.editableSectionModal = null;
      if (state.editingDataPanelId === chartId) state.editingDataPanelId = null;
      state.config.editable_section.default_layout =
        state.config.editable_section.default_layout.filter((elem) => elem.i !== chartId);
      dashboardUpdated();
    },
    duplicateEditableSectionChart: (
      state,
      { payload }: PayloadAction<{ chartId: string; newId: string }>,
    ) => {
      if (!state.config?.editable_section) return;
      const chart = state.config.editable_section.charts[payload.chartId];
      if (!chart) return;

      const newDp = {
        ...chart.data_panel,
        id: payload.newId,
        provided_id: namingUtils.getDefaultPanelProvidedId(
          chart.data_panel.visualize_op.operation_type,
          state.config,
        ),
      };

      state.config.editable_section.charts[payload.newId] = {
        name: `${chart.name} (Copy)`,
        data_panel: newDp,
        permissions: chart.permissions,
      };

      dashboardUpdated();
    },
    updateEditableSectionChartInfo: (
      state,
      // Provided id is not passed in when editing through format tab
      { payload }: PayloadAction<{ name: string; providedId?: string }>,
    ) => {
      let chartId: string;
      if (payload.providedId) {
        if (state.editableSectionModal?.type !== 'EditChartInfo') return;
        chartId = state.editableSectionModal.chartId;
      } else {
        if (!state.editingDataPanelId) return;
        chartId = state.editingDataPanelId;
      }

      const chart = state.config?.editable_section?.charts[chartId];
      if (!chart) return;

      chart.name = payload.name;

      if (payload.providedId) chart.data_panel.provided_id = payload.providedId;
      state.editableSectionModal = null;

      // All new charts will have this set to true, so we need to update the default
      if (chart.data_panel.visualize_op.generalFormatOptions?.headerConfig?.title !== undefined)
        chart.data_panel.visualize_op.generalFormatOptions.headerConfig.title = payload.name;

      dashboardUpdated();
    },
    updateEditableSectionChartPermissions: (
      state,
      { payload }: PayloadAction<CustomerPermissionsForObject>,
    ) => {
      if (
        !state.config?.editable_section ||
        state.editableSectionModal?.type !== 'EditChartPermissions'
      )
        return;

      const chartId = state.editableSectionModal.chartId;
      const chart = state.config.editable_section.charts[chartId];
      if (!chart) return;

      state.editableSectionModal = null;

      // If chart gets removed from all customers need to remove from default layout
      if (chart.permissions.allCustomers && !payload.allCustomers) {
        state.config.editable_section.default_layout =
          state.config.editable_section.default_layout.filter((elem) => elem.i !== chartId);
      }

      chart.permissions = payload;
      dashboardUpdated();
    },
    setEditableSectionLayout: (
      state,
      { payload }: PayloadAction<{ layout: Layout[]; isPreview: boolean }>,
    ) => {
      if (payload.isPreview) {
        state.editableSectionLayout = payload.layout;
        return;
      }

      if (!state.config?.editable_section) return;
      state.config.editable_section.default_layout = payload.layout;
      state.editableSectionLayout = null;
      dashboardUpdated();
    },
    updateEditableSectionSettings: (
      state,
      { payload }: PayloadAction<Partial<EditableSectionSettings>>,
    ) => {
      if (!state.config?.editable_section) return;
      state.config.editable_section.settings = {
        ...state.config.editable_section.settings,
        ...payload,
      };
      dashboardUpdated();
    },
    updateDatasetDrilldownConfig: (
      state,
      { payload }: PayloadAction<{ id: string; updates: Partial<DrilldownConfig> }>,
    ) => {
      const dataset = state.config?.datasets[payload.id];
      if (!dataset) return;
      dataset.drilldownConfig = { ...dataset.drilldownConfig, ...payload.updates };
      dashboardUpdated();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedDashboardItem, (state, { payload }) => {
        if (!state.config) return;
        if (payload.type && payload.type !== DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
        state.editingDataPanelId = payload.id;
      })
      .addCase(clearSelectedDashboardItem, (state) => {
        state.editingDataPanelId = null;
      })
      .addCase(setDatasetEditorMode, (state, { payload }) => {
        if (payload !== DATASET_EDITOR_MODE.EXPANDED) return;
        state.editingDataPanelId = null;
      })
      .addCase(switchCurrentlyEditingDashboardVersion, (state, { payload }) => {
        const { dashboardVersion } = payload;
        if (dashboardVersion.version_number === state.versionInfo?.version_number) return;
        state.config = dashboardVersion.configuration;
        state.versionInfo = {
          is_draft: dashboardVersion.is_draft,
          version_number: dashboardVersion.version_number,
          edit_version_number: dashboardVersion.edit_version_number,
          change_comments: dashboardVersion.change_comments,
        };
      })
      .addCase(revertResourceToVersionThunk.fulfilled, (state, { payload, meta }) => {
        const { new_version } = payload;

        if (!meta.arg.isExplore || !('configuration' in new_version)) return;

        state.config = new_version.configuration;
        state.versionInfo = {
          is_draft: new_version.is_draft,
          version_number: new_version.version_number,
          edit_version_number: new_version.edit_version_number,
          change_comments: new_version.change_comments,
        };
      })
      .addCase(publishNewDashboardVersionSuccess, (state, { payload }) => {
        const { dashboard_version } = payload;
        state.versionInfo = {
          is_draft: dashboard_version.is_draft,
          version_number: dashboard_version.version_number,
          edit_version_number: dashboard_version.edit_version_number,
          change_comments: dashboard_version.change_comments,
        };
      })
      .addCase(saveExploreDraft.fulfilled, (state, { payload }) => {
        if (state.versionInfo) state.versionInfo.edit_version_number = payload.edit_version_number;
      })
      // Data Panel Reducers
      .addCase(clearDashboardConfigReducer, () => {
        return initialState;
      })
      .addCase(fetchDashboardRequest, () => {
        return initialState;
      })
      .addCase(fetchDashboardSuccess, (state, { payload }) => {
        state.config = payload.dashboard_version.configuration;
        if (state.config) clearEmptyPanels(state.config);

        state.versionInfo = {
          is_draft: payload.dashboard_version.is_draft,
          version_number: payload.dashboard_version.version_number,
          edit_version_number: payload.dashboard_version.edit_version_number,
          change_comments: payload.dashboard_version.change_comments,
        };
      })
      .addCase(createDashboardDataPanel, (state, { payload }) => {
        if (!state.config) return;

        const newDataPanel = EMPTY_DATA_PANEL_STATE(
          payload.id,
          payload.datasetId,
          payload.vizType,
          namingUtils.getDefaultPanelProvidedId(payload.vizType, state.config),
          payload.name,
          payload.containerId,
        );
        state.config.data_panels[newDataPanel.id] = newDataPanel;
        state.editingDataPanelId = payload.id;

        layoutUtils.addItemToConfigLayouts(state.config, cloneDeep(payload), newDataPanel.id);
        dashboardUpdated();
      })
      .addCase(duplicateDashboardItem, (state, { payload }) => {
        if (!state.config) return;
        const { dashboardItem, itemType, dashId } = payload;
        const config = state.config;
        // either the whole dashboard or the container that holds this element
        let layout = config.dashboard_layout;

        if (dashboardItem.container_id) {
          const containerConfig = config.elements[dashboardItem.container_id]
            .config as ContainerElemConfig;

          // if we're in a container, then we want to be editing the container's layout
          layout = containerConfig.layout;
        }

        const elem = layout.find((item) => item.i === dashboardItem.id);
        if (!elem) return;

        const newElementConfig = cloneDeep(dashboardItem);

        const newElemId = layoutUtils.placeDuplicatedElementInLayout({
          newElementLayout: cloneDeep(elem),
          newElementConfig,
          layout: cloneDeep(layout),
          config,
          // place the cloned item at the bottom of the column that contains the parent element
          yStart: elem.y + elem.h - 1,
          dashId,
        });

        if (itemType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
          const dataPanel = dashboardItem as DataPanelTemplate;
          Object.values(config.elements).forEach(({ config }) => {
            const dataPanelsLinked = config.datasetLinks?.[dataPanel.table_id]?.dataPanels;
            if (dataPanelsLinked?.includes(dataPanel.id)) {
              dataPanelsLinked.push(newElemId);
            }
          });
        }

        if (itemType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
          // for container elements, we have to clone all of the contained elements
          const newContainerConfig = (newElementConfig as DashboardElement)
            .config as ContainerElemConfig;
          // clone this so we can iterate over it
          const oldLayout = cloneDeep(newContainerConfig.layout);
          // but clear the layout so that we can add to it fresh
          newContainerConfig.layout = [];

          oldLayout.forEach((newContainerElementLayout) => {
            // we have to clone deep here so that only the duplicated panel gets the new container_id below
            const newContainerElementConfig = cloneDeep(
              newContainerElementLayout.i in config.data_panels
                ? config.data_panels[newContainerElementLayout.i]
                : config.elements[newContainerElementLayout.i],
            );

            // we have to manually swap the container id over to our new duped container
            newContainerElementConfig.container_id = newElementConfig.id;
            layoutUtils.placeDuplicatedElementInLayout({
              newElementLayout: newContainerElementLayout,
              newElementConfig: newContainerElementConfig,
              layout: newContainerConfig.layout,
              config,
              yStart: newContainerElementLayout.y,
              dashId: dashId,
            });
          });
        }
        dashboardUpdated();
      })
      // Dataset Editor Reducers
      .addCase(fetchEditorDatasetPreviewPrimaryData.pending, (state, { meta }) => {
        state.datasetData[meta.arg.postData.dataset_id] = { loading: true };
      })
      .addCase(fetchEditorDatasetPreviewRequest, (state, { payload }) => {
        state.datasetData[payload.postData.dataset_id] = { loading: true };
      })
      .addCase(fetchEditorDatasetPreviewPrimaryData.fulfilled, (state, { meta, payload }) => {
        const datasetId = meta.arg.postData.dataset_id;
        const dataset = state.config?.datasets[datasetId];
        if (!dataset) return;
        dataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(
          dataset,
          payload.dataset_preview.schema,
        );

        updateDatasetData(state, datasetId, (data) => {
          data.schema = payload.dataset_preview.schema;
          data.rows = payload.dataset_preview._rows;
          data.queryInformation = payload.query_information;
          data.error = undefined;
          data.loading = false;
          data.unsupportedOperations = payload.dataset_preview._unsupported_operations;
        });

        // Need to call in case drilldownColumnConfigs changes
        dashboardUpdated();
      })
      .addCase(fetchEditorDatasetPreviewSuccess, (state, { payload }) => {
        const datasetId = payload.postData.dataset_id;

        const dataset =
          state.config?.datasets[datasetId] ??
          getDatasetFromFidoId(state.config?.datasets, payload.postData.dataset_id);
        if (!dataset) return;
        dataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(
          dataset,
          payload.dataset_preview.schema,
        );

        updateDatasetData(state, datasetId, (data) => {
          data.schema = payload.dataset_preview.schema;
          data.rows = payload.dataset_preview._rows;
          data.queryInformation = payload.query_information;
          data.error = undefined;
          data.loading = false;
          data.unsupportedOperations = payload.dataset_preview._unsupported_operations;
        });

        // Need to call in case drilldownColumnConfigs changes
        dashboardUpdated();
      })
      .addCase(fetchEditorDatasetPreviewPrimaryData.rejected, (state, { error, meta, payload }) => {
        const datasetId = meta.arg.postData.dataset_id;

        updateDatasetData(state, datasetId, (data) => {
          data.error = error.message ?? 'Internal Error';
          data.loading = false;
          data.queryInformation = payload?.query_information;
        });
      })
      .addCase(fetchEditorDatasetPreviewError, (state, { payload }) => {
        const datasetId = payload.postData.dataset_id;

        updateDatasetData(state, datasetId, (data) => {
          data.error = payload.error_msg ?? 'Internal Error';
          data.loading = false;
          data.queryInformation = payload.query_information;
        });
      })
      .addCase(fetchFidoViewPreview.pending, (state, { meta }) => {
        const dataset = getDatasetFromFidoId(state.config?.datasets, meta.arg.view.id);
        if (!dataset) return;
        state.datasetData[dataset.id] = { loading: true };
      })
      .addCase(fetchFidoViewPreview.fulfilled, (state, { payload, meta }) => {
        const dataset = getDatasetFromFidoId(state.config?.datasets, meta.arg.view.id);
        if (!dataset) return;

        const { schema, totalResults, rows, queryInformation } =
          getEmbeddoResponseFromFidoResponse(payload);
        dataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(dataset, schema);

        updateDatasetData(state, dataset.id, (data) => {
          data.schema = schema;
          data.rows = rows;
          data.queryInformation = queryInformation;
          data.error = undefined;
          data.loading = false;
          data.unsupportedOperations = undefined;
          data.totalRowCount = totalResults ?? undefined;
        });

        dashboardUpdated();
      })
      .addCase(fetchFidoViewPreview.rejected, (state, { meta, error }) => {
        const dataset = getDatasetFromFidoId(state.config?.datasets, meta.arg.view.id);
        if (!dataset) return;
        updateDatasetData(state, dataset.id, (data) => {
          data.error = error.message ?? 'Something went wrong';
          data.loading = false;
        });
      })
      .addCase(fetchEditorDatasetPreviewRowCount.fulfilled, (state, { payload, meta }) => {
        updateDatasetData(state, meta.arg.postData.dataset_id, (data) => {
          data.totalRowCount = payload._total_row_count;
        });
      })
      .addCase(fetchEditorDatasetPreviewRowCount.rejected, (state, { meta }) => {
        updateDatasetData(state, meta.arg.postData.dataset_id, (data) => {
          data.totalRowCount = undefined;
        });
      })
      .addCase(fetchEditorDatasetRowCountSuccess, (state, { payload }) => {
        updateDatasetData(state, payload.postData.dataset_id, (data) => {
          data.totalRowCount = payload._total_row_count;
        });
      })
      .addCase(fetchEditorDatasetRowCountError, (state, { payload }) => {
        updateDatasetData(state, payload.postData.dataset_id, (data) => {
          data.totalRowCount = undefined;
        });
      })
      .addCase(saveDatasetQuery, (state, { payload }) => {
        if (!state.config) return;

        state.config.datasets[payload.dataset_id].query = payload.query;
        state.config.datasets[payload.dataset_id].queryDraft = undefined;
        state.config.datasets[payload.dataset_id].schema = payload.schema;
        dashboardUpdated();
      })
      .addCase(saveDraftDatasetQuery, (state, { payload }) => {
        if (!state.config) return;

        state.config.datasets[payload.dataset_id].queryDraft = payload.queryDraft;
        dashboardUpdated();
      })
      .addCase(saveComputedView.fulfilled, (state, { meta }) => {
        if (!state.config) return;

        const dataset = getDatasetFromFidoId(state.config.datasets, meta.arg.id);

        if (!dataset) return;

        // the actual saving of this is handled in the fidoReducer
        dataset.queryDraft = undefined;
        dataset.query = meta.arg.query;
        dataset.schema = getEmbeddoSchemaFromFidoSchema(meta.arg.columnDefinitions);

        // these are more conditionally saved
        dataset.table_name = meta.arg.name;
        dataset.namespace_id = meta.arg.namespaceId;
        dashboardUpdated();
      })
      .addCase(saveDraftComputedViewQuery, (state, { payload }) => {
        if (!state.config || !state.config.datasets) return;

        const dataset = state.config.datasets[payload.viewId];

        if (!dataset) return;

        dataset.queryDraft = payload.queryDraft;
        dashboardUpdated();
      })
      .addCase(createDashboardDataset, (state, { payload }) => {
        if (!state.config) return;

        const newDataset: Dataset = {
          id: `dash${payload.dashId}-${uuidv4()}`,
          table_name: payload.name,
          parent_schema_id: payload.parentSchemaId,
          query: '',
          drilldownColumnConfigs: {},
          drilldownConfig: { columnFit: COLUMN_FITS.HEADER },
        };
        state.config.datasets[newDataset.id] = newDataset;
        dashboardUpdated();
      })
      .addCase(createComputedView.fulfilled, (state, { payload, meta }) => {
        if (!state.config) return;

        const id = uuidv4();

        state.config.datasets[id] = {
          id: id,
          fido_id: payload.view.id ?? undefined,
          namespace_id: payload.view.namespaceId ?? '',
          table_name: payload.view.name,
          parent_schema_id: meta.arg.namespace?.id ?? -1,
          drilldownColumnConfigs: {},
          drilldownConfig: { columnFit: COLUMN_FITS.HEADER },
          query: '',
        };
        dashboardUpdated();
      })
      .addCase(updateDashboardDatasetSchema, (state, { payload }) => {
        if (!state.config) return;

        state.config.datasets[payload.datasetId].parent_schema_id = payload.newParentSchemaId;
        dashboardUpdated();
      })
      .addCase(deleteDataset, (state, { payload }) => {
        if (!state.config) return;
        delete state.config.datasets[payload.datasetId];

        removeDatasetFromLinks(payload.datasetId, state.config.elements);
        dashboardUpdated();
      })
      .addCase(deleteComputedView.fulfilled, (state, { meta }) => {
        if (!state.config) return;

        const datasetId = Object.keys(state.config.datasets).find(
          (id) => state.config?.datasets[id].fido_id === meta.arg.viewId,
        );

        if (datasetId) {
          delete state.config.datasets[datasetId];
          removeDatasetFromLinks(datasetId, state.config.elements);
          dashboardUpdated();
        }
      })
      .addCase(editDatasetName, (state, { payload }) => {
        if (!state.config) return;
        state.config.datasets[payload.datasetId].table_name = payload.name;
        dashboardUpdated();
      })
      .addCase(updateDatasetDrilldownColConfig, (state, { payload }) => {
        if (!state.config) return;
        const dataset = state.config.datasets[payload.datasetId];
        if (!dataset.drilldownColumnConfigs) {
          dataset.drilldownColumnConfigs = {};
        }

        if (!dataset.drilldownColumnConfigs[payload.colName]) {
          if (payload.displayName === undefined || payload.index === undefined) return;
          dataset.drilldownColumnConfigs[payload.colName] = initConfig(
            payload.index,
            payload.displayName,
          );
        }

        const colConfig = dataset.drilldownColumnConfigs[payload.colName];

        if (payload.displayName !== undefined) colConfig.displayName = payload.displayName;
        if (payload.index !== undefined) colConfig.index = payload.index;
        if (payload.isIncluded !== undefined) colConfig.isIncluded = payload.isIncluded;
        if (payload.isVisible !== undefined) colConfig.isVisible = payload.isVisible;
        if (payload.displayFormatting !== undefined)
          colConfig.displayFormatting = payload.displayFormatting;

        dashboardUpdated();
      })
      .addCase(updateDatasetDrilldownColConfigs, (state, { payload }) => {
        if (!state.config) return;

        state.config.datasets[payload.datasetId].drilldownColumnConfigs = payload.newConfigs;
        dashboardUpdated();
      })
      // Edit Dashboard Reducers
      .addCase(updateDashboardLayout, (state, { payload }) => {
        if (!state.config) return;

        state.config.dashboard_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardPdfLayout, (state, { payload }) => {
        if (!state.config) return;
        state.config.pdf_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardEmailLayout, (state, { payload }) => {
        if (!state.config) return;
        state.config.email_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardMobileLayout, (state, { payload }) => {
        if (!state.config) return;
        state.config.mobile_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardEmailText, (state, { payload }) => {
        if (!state.config) return;

        if (payload.isHeader) {
          state.config.email_header_html = payload.html;
        } else {
          state.config.email_footer_html = payload.html;
        }
        dashboardUpdated();
      })
      .addCase(deleteDataPanel, (state, { payload }) => {
        if (!state.config) return;
        delete state.config.data_panels[payload.id];
        layoutUtils.updateSecondaryLayoutsAfterMainLayoutChange(state.config);

        removeDataPanelsFromLinks([payload.id], state.config.elements);

        dashboardUpdated();
      })
      .addCase(createDashboardElement, (state, { payload }) => {
        if (!state.config) return;
        const newElement = {
          id: payload.id,
          name: namingUtils.getDefaultElementName(payload.elementType, state.config),
          element_type: payload.elementType,
          config: { ...BASE_CONFIG_BY_DASH_ELEM[payload.elementType] },
          container_id: payload.containerId,
        };

        state.config.elements[newElement.id] = newElement;

        layoutUtils.addItemToConfigLayouts(state.config, payload, newElement.id);
        dashboardUpdated();
      })
      .addCase(updateElementConfig, (state, { payload }) => {
        const element = state.config?.elements[payload.elementId];
        if (!element) return;

        if (payload.config) element.config = payload.config;
        if (payload.newElementType) {
          if (
            element.config.operator &&
            !isValidOperationForFilter(element.config.operator, payload.newElementType)
          ) {
            element.config.operator = undefined;
          }

          element.element_type = payload.newElementType;
        }
        dashboardUpdated();
      })
      .addCase(deleteDashboardElement, (state, { payload }) => {
        if (!state.config) return;
        const { elementType, elementId } = payload;

        const elementNames: string[] = [];
        const idsToUnlink: string[] = [];
        if (elementType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
          state.config.elements = pickBy(state.config.elements, (elem) => {
            if (elem.id === elementId || elem.container_id === elementId) {
              elementNames.push(elem.name);
              return false;
            }
            return true;
          });
          state.config.data_panels = pickBy(state.config.data_panels, (panel) => {
            if (panel.container_id === elementId) {
              idsToUnlink.push(panel.id);
              return false;
            }
            return true;
          });
        } else {
          elementNames.push(state.config.elements[elementId].name);
          delete state.config.elements[elementId];

          removeElemFromStickyHeader(state.config.dashboard_page_layout_config, elementId);
        }

        removeDataPanelsFromLinks(idsToUnlink, state.config.elements);

        layoutUtils.updateSecondaryLayoutsAfterMainLayoutChange(state.config);
        updateUserInputFieldsWithDeletedElem(state.config, elementNames);
        dashboardUpdated();
      })
      .addCase(saveDashboardElementUpdates, (state, { payload }) => {
        if (payload.name && state.config) {
          const oldName = state.config.elements[payload.id].name;
          state.config.elements[payload.id].name = cloneDeep(payload.name);
          updateUserInputFieldsWithNewElemName(state.config, oldName, payload.name);
          dashboardUpdated();
        }
      })
      .addCase(updateVisualizeOperationAction, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDpt) => {
          updateVisualizeOperation_(editingDpt, payload);
        });
      })
      .addCase(updateSelectedChart, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDpt) => {
          updateSelectedChart_(editingDpt, payload.to);
        });
      })
      .addCase(createFilterClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          if (payload) {
            const clause = cloneDeep(EMPTY_FILTER_CLAUSE);
            clause.filterColumn = payload;
            editingDPT.filter_op.instructions.filterClauses.push(clause);
          } else {
            editingDPT.filter_op.instructions.filterClauses.push(EMPTY_FILTER_CLAUSE);
          }
        });
      })
      .addCase(deleteFilterClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses.splice(payload, 1);
        });
      })
      .addCase(selectFilterColumn, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          const oldColumn =
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn;
          if (oldColumn && oldColumn.type !== payload.column.type) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation =
              undefined;
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue = undefined;
          }
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn =
            payload.column;
        });
      })
      .addCase(selectFilterOperator, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          if (
            newOperatorShouldClearSelectedVariable(
              payload.operator,
              editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation?.id,
            )
          ) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueVariableId =
              undefined;
            editingDPT.filter_op.instructions.filterClauses[
              payload.index
            ].filterValueVariableProperty = undefined;
          }

          if (newOperatorDoesntHaveVariableOption(payload.operator)) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
              undefined;
          }

          editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation = {
            id: payload.operator,
          };
        });
      })
      .addCase(updateFilterValue, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue =
            payload.value;
        });
      })
      .addCase(updateFilterValueSource, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
            payload.newSource;
        });
      })
      .addCase(updateFilterValueVariable, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          const filterClause = editingDPT.filter_op.instructions.filterClauses[payload.index];
          if (!filterClause) return;
          filterClause.filterValueVariableId = payload.variableId;
          filterClause.filterValueVariableProperty = payload.property;
        });
      })
      .addCase(updateGeneralFormatOptions, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.visualize_op.generalFormatOptions = payload;
        });
      })
      .addCase(updateDrilldownDataPanel, (state, { payload }) => {
        if (!state.config) return;
        if (payload === undefined) {
          if (!(DRILLDOWN_DATA_PANEL_ID in state.config.data_panels)) return;
          delete state.config.data_panels[DRILLDOWN_DATA_PANEL_ID];
        } else {
          payload.dataPanel.id = DRILLDOWN_DATA_PANEL_ID;
          state.config.data_panels[DRILLDOWN_DATA_PANEL_ID] =
            payload.dataPanel as DataPanelTemplate;
        }
      })
      .addCase(toggleElementVisibilityForSecondaryLayout, (state, { payload }) => {
        if (!state.config) return;
        // Just safety checking to make sure we don't edit the main layout
        if (
          payload.layoutType !== VIEW_MODE.EMAIL &&
          payload.layoutType !== VIEW_MODE.PDF &&
          payload.layoutType !== VIEW_MODE.MOBILE
        )
          return;

        let newLayout = getLayoutFromDashboardVersionConfig(state.config, payload.layoutType);
        // For some reason some elements are duplicated in the layout, so we need to remove them
        // Should hopefully be able to remove after a bit
        newLayout = uniqBy(newLayout, (elem) => elem.i);

        const elem = newLayout.find((elem) => elem.i === payload.id);

        if (elem !== undefined) {
          newLayout = removeElementsFromLayoutById(newLayout, new Set([payload.id]));
        } else {
          const elementToAdd = getElementsById(
            state.config.dashboard_layout,
            new Set([payload.id]),
          );
          if (elementToAdd.length === 1) {
            newLayout = addElementToLayout(
              newLayout,
              elementToAdd[0],
              payload.layoutType === VIEW_MODE.MOBILE,
            );
          }
        }

        switch (payload.layoutType) {
          case VIEW_MODE.PDF:
            state.config.pdf_layout = newLayout;
            break;
          case VIEW_MODE.MOBILE:
            state.config.mobile_layout = newLayout;
            break;
          case VIEW_MODE.EMAIL:
            state.config.email_layout = newLayout;
            break;
        }
      })
      .addCase(toggleFilterLink, (state, { payload }) => {
        const dp =
          state.config?.data_panels?.[payload.dataPanelId] ??
          state.config?.editable_section?.charts[payload.dataPanelId]?.data_panel;
        const elem = state.config?.elements?.[payload.elementId];
        if (!elem || !dp) return;

        const datasetLinks = elem.config.datasetLinks?.[dp.table_id];
        if (!datasetLinks) return;
        if (!datasetLinks.dataPanels) datasetLinks.dataPanels = [];

        const dpIdx = datasetLinks.dataPanels.findIndex((id) => id === dp.id);
        if (dpIdx === -1) {
          datasetLinks.dataPanels.push(dp.id);
        } else {
          datasetLinks.dataPanels.splice(dpIdx, 1);
        }
        dashboardUpdated();
      })
      .addCase(updateElementLocation, (state, { payload }) => {
        if (!state.config) return;
        const element = state.config?.elements[payload.elementId];
        if (!element) return;

        if (!state.config.dashboard_page_layout_config)
          state.config.dashboard_page_layout_config = {};

        const layoutConfig = state.config.dashboard_page_layout_config;
        if (!layoutConfig.stickyHeader) layoutConfig.stickyHeader = {};
        if (!layoutConfig.stickyHeader.headerContentOrder)
          layoutConfig.stickyHeader.headerContentOrder = [];

        if (
          (!element.elemLocation ||
            element.elemLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY) &&
          payload.newLocation === DASHBOARD_LAYOUT_CONFIG.HEADER
        ) {
          layoutConfig.stickyHeader.headerContentOrder.push(payload.elementId);
          state.config.elements[payload.elementId].container_id = undefined;
        } else if (
          element.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER &&
          payload.newLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
        ) {
          const elemWasRemoved = removeElemFromStickyHeader(layoutConfig, payload.elementId);
          if (elemWasRemoved) {
            const { w, h } = DRAGGING_ITEM_CONFIG_BY_TYPE[element.element_type];
            state.config.dashboard_layout.unshift({
              i: element.id,
              x: 0,
              y: 0,
              w,
              h,
            });

            layoutUtils.addItemToConfigLayouts(
              state.config,
              { newLayout: state.config.dashboard_layout },
              element.id,
            );
          }
        }

        element.elemLocation = payload.newLocation;

        dashboardUpdated();
      })
      .addCase(updateElementContainerLocation, (state, { payload }) => {
        if (!state.config) return;

        const element: { id: string; container_id?: string } = payload.isDataPanel
          ? state.config.data_panels[payload.elementId]
          : state.config.elements[payload.elementId];

        if (payload.removeElem) {
          layoutUtils.moveElementFromContainerToBody(state.config, payload.containerId, element);
        } else {
          layoutUtils.moveElementIntoContainer(state.config, payload.containerId, element);
        }

        dashboardUpdated();
      });
  },
});

export const dashboardEditConfigReducer = dashboardEditConfigSlice.reducer;
export const {
  updateDashboardParams,
  updateStickyHeaderElementOrder,
  toggleStickyHeader,
  updateStickyHeader,
  toggleEditableSection,
  addEditableSectionChart,
  duplicateEditableSectionChart,
  setEditableSectionModal,
  deleteEditableSectionChart,
  updateDataPanelProvidedId,
  setEditableSectionLayout,
  updateEditableSectionChartInfo,
  updateEditableSectionChartPermissions,
  updateEditableSectionSettings,
  updateDatasetDrilldownConfig,
  updateDashboardCategoryColors,
} = dashboardEditConfigSlice.actions;

function dashboardUpdated() {
  saveResourceConfig();
}

const updateVisualizeOperation_ = (
  editingDPT: DataPanelTemplate,
  payload: UpdateVisualizeOperationPayload,
) => {
  if (!editingDPT?.visualize_op.instructions) return;

  if (payload.operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
    editingDPT.visualize_op.instructions.VISUALIZE_TABLE =
      payload.visualizeInstructions as VisualizeTableInstructions;
  } else if (
    V2_VIZ_INSTRUCTION_TYPE[payload.operationType] === 'Two-dimensional' ||
    payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 ||
    payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2
  ) {
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART =
      payload.visualizeInstructions as V2TwoDimensionChartInstructions;
  } else if (
    payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    payload.operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    editingDPT.visualize_op.instructions.V2_KPI =
      payload.visualizeInstructions as V2KPIChartInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    editingDPT.visualize_op.instructions.V2_BOX_PLOT =
      payload.visualizeInstructions as V2BoxPlotInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    editingDPT.visualize_op.instructions.V2_SCATTER_PLOT =
      payload.visualizeInstructions as V2ScatterPlotInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    editingDPT.visualize_op.instructions.V2_KPI_TREND =
      payload.visualizeInstructions as V2KPITrendInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
    editingDPT.visualize_op.instructions.VISUALIZE_PIVOT_TABLE =
      payload.visualizeInstructions as VisualizePivotTableInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
    editingDPT.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST =
      payload.visualizeInstructions as VisualizeCollapsibleListInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
    editingDPT.visualize_op.instructions.VISUALIZE_PIVOT_TABLE_V2 =
      payload.visualizeInstructions as V2PivotTableInstructions;
  } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP) {
    editingDPT.visualize_op.instructions.VISUALIZE_GEOSPATIAL_CHART =
      payload.visualizeInstructions as VisualizeGeospatialChartInstructions;
  }
};

const updateSelectedChart_ = (editingDPT: DataPanelTemplate, newOpType: OPERATION_TYPES) => {
  if (
    BAR_CHART_TYPES.has(editingDPT.visualize_op.operation_type) &&
    !BAR_CHART_TYPES.has(newOpType)
  ) {
    if (editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn)
      editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketSize =
        undefined;
  }

  if (
    GROUPED_STACKED_OPERATION_TYPES.has(editingDPT.visualize_op.operation_type) &&
    !GROUPED_STACKED_OPERATION_TYPES.has(newOpType) &&
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn
  ) {
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART.groupingColumn = undefined;
  }

  editingDPT.visualize_op.operation_type = newOpType;
};

export const getEditPageDataPanels = createSelector(
  (state: DashboardEditConfigReducerState) => state.config?.data_panels,
  (state: DashboardEditConfigReducerState, getRegardlessOfEnabled?: boolean) =>
    state.config?.editable_section?.enabled || getRegardlessOfEnabled
      ? state.config?.editable_section?.charts
      : undefined,
  (dataPanels, editableCharts) => {
    const editableDps = Object.values(editableCharts ?? {}).map((chart) => chart.data_panel);
    return Object.values(dataPanels ?? {}).concat(editableDps);
  },
);

const getDatasetFromFidoId = (datasets: Record<string, Dataset> | undefined, fidoId: string) =>
  Object.values(datasets ?? {}).find((dataset) => dataset.fido_id === fidoId);

import { FC } from 'react';

import * as styles from './ValuesConfig.css';
import { BooleanToggle, Tabs, Input, Select } from 'components/ds';
import { SortOptionToggle } from 'components/SortDirectionToggles';

import {
  DASHBOARD_ELEMENT_TYPES,
  SELECT_FILTER_TYPE,
  SelectElemConfig,
} from 'types/dashboardTypes';
import { INPUT_TYPES } from 'constants/types';
import { getDatasetName } from 'utils/naming';
import { ResourceDataset } from 'types/exploResource';
import { getUpdateConfigFunc } from '../utils';
import { createColSelectOptionsWithIcon } from 'utils/general';

type Props = {
  config: SelectElemConfig;
  datasets: Record<string, ResourceDataset>;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig, reRequestRows: boolean) => void;
};

export const SelectValuesConfig: FC<Props> = ({
  config,
  datasets,
  selectType,
  updateSelectConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  const isToggle = selectType === DASHBOARD_ELEMENT_TYPES.TOGGLE;

  const renderSideMessage = () => {
    if (!isToggle) return null;
    return <div className={styles.sideText}>A maximum of 5 options will be shown</div>;
  };

  const renderManualConfig = () => {
    const valuesArrStr = config.valuesConfig.manualValues || '';
    const displaysArrStr = config.valuesConfig.manualDisplays || '';

    return (
      <>
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={valuesArrStr}
          errorText={parseArrayString(valuesArrStr)}
          label="Values"
          onSubmit={(newValue) =>
            updateConfig((draft) => (draft.valuesConfig.manualValues = newValue))
          }
          placeholder={isToggle ? '["day", "week", "month"]' : '[1, 2, 3]'}
        />
        {renderSideMessage()}
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={displaysArrStr}
          errorText={parseArrayString(displaysArrStr)}
          label="Displays"
          onSubmit={(newValue) =>
            updateConfig((draft) => (draft.valuesConfig.manualDisplays = newValue))
          }
          placeholder={isToggle ? '["Day", "Week", "Month"]' : '["One", "Two", "Three"]'}
        />
      </>
    );
  };

  const renderQueryConfig = () => {
    const datasetOptions = Object.values(datasets).reduce<{ value: string; label: string }[]>(
      (acc, dataset) => {
        if (dataset.schema) acc.push({ value: dataset.id, label: getDatasetName(dataset) });
        return acc;
      },
      [],
    );
    const selectedDataset = datasets[config.valuesConfig.queryTable?.id ?? ''];

    const queryDisplayColName = config.valuesConfig.queryDisplayColumn?.name;

    const colOptions = createColSelectOptionsWithIcon(selectedDataset?.schema);

    return (
      <>
        <Select
          className={styles.configInput}
          label="Dataset"
          onCancel={() =>
            updateConfig((draft) => {
              draft.valuesConfig.queryValueColumn = undefined;
              draft.valuesConfig.queryDisplayColumn = undefined;
              draft.valuesConfig.queryTable = undefined;
            })
          }
          onChange={(datasetId) =>
            updateConfig((draft) => {
              draft.valuesConfig.queryValueColumn = undefined;
              draft.valuesConfig.queryDisplayColumn = undefined;
              draft.valuesConfig.queryTable = { id: datasetId };
            }, true)
          }
          placeholder="Select dataset"
          selectedValue={selectedDataset?.id}
          values={datasetOptions}
        />
        <Select
          className={styles.configInput}
          disabled={selectedDataset === undefined}
          label="Values"
          onCancel={() =>
            updateConfig((draft) => (draft.valuesConfig.queryValueColumn = undefined))
          }
          onChange={(colName) =>
            updateConfig((draft) => (draft.valuesConfig.queryValueColumn = { name: colName }), true)
          }
          placeholder="Select column"
          selectedValue={config.valuesConfig.queryValueColumn?.name}
          values={colOptions}
        />
        {renderSideMessage()}
        <Select
          className={styles.configInput}
          disabled={selectedDataset === undefined}
          label="Displays (optional)"
          onCancel={() =>
            updateConfig((draft) => (draft.valuesConfig.queryDisplayColumn = undefined))
          }
          onChange={(colName) =>
            updateConfig((draft) => {
              draft.valuesConfig.queryDisplayColumn = { name: colName };
            })
          }
          placeholder="Select column"
          selectedValue={queryDisplayColName}
          values={colOptions}
        />
        <SortOptionToggle
          allowNoSort
          className={styles.configInput}
          currentSort={config.valuesConfig?.querySortOption}
          label="Sort Dropdown"
          updateSort={(newSort) =>
            updateConfig((draft) => (draft.valuesConfig.querySortOption = newSort))
          }
        />

        {queryDisplayColName ? (
          <BooleanToggle
            className={styles.configInput}
            falseText="Display"
            label="Sort On"
            onValueChange={(value) =>
              updateConfig((draft) => (draft.valuesConfig.querySortByValue = value))
            }
            selectedValue={!!config.valuesConfig.querySortByValue}
            trueText="Values"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className={styles.valuesContainer}>
      <Tabs
        className={styles.toggleTabContainer}
        onTabSelect={(tabName) => {
          const inputType =
            tabName === INPUT_TYPES.MANUAL.name ? INPUT_TYPES.MANUAL.id : INPUT_TYPES.QUERY.id;
          updateConfig((draft) => (draft.valuesConfig.valuesSource = inputType));
        }}
        selectedTabId={INPUT_TYPES[config.valuesConfig.valuesSource].name}
        tabs={[INPUT_TYPES.MANUAL.name, INPUT_TYPES.QUERY.name]}
      />
      <div className={styles.section}>
        {config.valuesConfig.valuesSource === INPUT_TYPES.MANUAL.id
          ? renderManualConfig()
          : renderQueryConfig()}
      </div>
    </div>
  );
};

function parseArrayString(arrayString: string): string | undefined {
  let error;
  try {
    if (arrayString) {
      const displaysArr = JSON.parse(arrayString);
      if (!Array.isArray(displaysArr)) error = 'Must be valid javascript array.';
    }
  } catch {
    error = 'Must be valid javascript array.';
  }
  return error;
}

import { forwardRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

import { Props as EmbedButtonProps, EmbedButton } from 'components/embed/EmbedButton';

export const EmbedModalClose = forwardRef<HTMLButtonElement, EmbedButtonProps>((props, ref) => {
  return (
    <Dialog.Close asChild ref={ref}>
      <EmbedButton {...props} />
    </Dialog.Close>
  );
});

EmbedModalClose.displayName = 'EmbedModalClose';

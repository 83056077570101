import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sprinkles, Input } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  EmbedModal,
  EmbedModalHeader,
  EmbedModalClose,
  EmbedModalFooter,
  EmbedModalContent,
} from 'components/embed';

import {
  closeReportModal,
  clearSelectedReport,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { createCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';

type Props = { builtInId: string; builtInName: string; isIframe: boolean };

/**
 * Shown when the user tries to close a built in with unsaved changes
 */
export const ConfirmCloseBuiltInModal: FC<Props> = ({ builtInId, builtInName, isIframe }) => {
  const dispatch = useDispatch();
  const [newReportName, setNewReportName] = useState(`${builtInName} Copy`);

  const currentConfig = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.currentConfig,
  );

  const handleSaveAndClose = () => {
    if (newReportName && currentConfig) {
      dispatch(
        createCustomerReportThunk({ name: newReportName, config: currentConfig, builtInId }),
      );
    }
  };
  return (
    <EmbedModal
      isOpen
      isIframe={isIframe}
      onClose={() => dispatch(closeReportModal())}
      size="medium">
      <EmbedModalHeader title="You have unsaved changes on this Built In Report" />
      <EmbedModalContent
        className={sprinkles({ flexItems: 'column', gap: 'sp.5', paddingX: 'sp3' })}
        size="medium">
        <EmbedText body="b2" color="contentPrimary">
          Changes to Built in Reports cannot be saved, but you can save your changes to a new
          report.
        </EmbedText>
        <br />
        <EmbedText body="b3" color="contentPrimary">
          New report name
        </EmbedText>
        <Input onChange={setNewReportName} placeholder="Untitled Report" value={newReportName} />
      </EmbedModalContent>
      <EmbedModalFooter>
        <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
        <EmbedModalClose onClick={() => dispatch(clearSelectedReport())} variant="secondary">
          Close without saving
        </EmbedModalClose>
        <EmbedModalClose disabled={!newReportName} onClick={handleSaveAndClose} variant="primary">
          Save as new report and close
        </EmbedModalClose>
      </EmbedModalFooter>
    </EmbedModal>
  );
};

import { FC, useContext, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import * as styles from './PreviewChart.css';

import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { ReduxState } from 'reducers/rootReducer';
import { PAGE_TYPE } from 'types/dashboardTypes';
import { getEditableDatasets } from 'reducers/selectors';
import { GlobalStylesContext } from 'globalStyles';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { onNewDataPanelsAddedThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { getDatasetNamesToId } from 'utils/datasetUtils';

type Props = {
  dataPanel: DataPanelTemplate;
};

// Need to pass id in DashboardLayoutContext for portal id for chart popovers and menus
const previewContainerId = 'preview-container';

export const PreviewChart: FC<Props> = ({ dataPanel }) => {
  const dispatch = useDispatch();

  const { variables, datasets, dataPanelData } = useSelector(
    (state: ReduxState) => ({
      variables: state.dashboardData.variables,
      datasets: getEditableDatasets(state),
      dataPanelData: state.dashboardData.dataPanelData[dataPanel.id],
    }),
    shallowEqual,
  );

  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  useEffect(() => {
    if (dataPanelData?.loading || dataPanelData?.rows || dataPanelData?.error) return;
    dispatch(onNewDataPanelsAddedThunk([dataPanel]));
  }, [dispatch, dataPanel, dataPanelData]);

  const { globalStylesClassName, globalStyleVars } = useContext(GlobalStylesContext);

  return (
    <div className={styles.previewContainer}>
      <div className={styles.header}>Preview</div>
      <div
        className={cx(styles.chartContainer, globalStylesClassName)}
        id={previewContainerId}
        style={globalStyleVars}>
        <DashboardLayoutContext.Provider value={{ dashboardLayoutTagId: previewContainerId }}>
          <DashboardDatasetView
            editableChartPreview
            editableDashboard
            canDownloadDataPanel={false}
            dataPanel={dataPanel}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets}
            isViewOnly={false}
            pageType={PAGE_TYPE.EXPLO_APP}
            variables={variables ?? {}}
          />
        </DashboardLayoutContext.Provider>
      </div>
    </div>
  );
};

import { FC, useState } from 'react';

import { Tabs, sprinkles } from 'components/ds';
import { QueryDebugger } from './QueryDebugger';
import { DataDebugger } from './DataDebugger';
import { VariablesDebugger } from './VariablesDebugger';

enum DEBUGGER_TABS {
  QUERY = 'Query',
  VARIABLES = 'Variables',
  DATA = 'Data',
}

const TabNames = Object.values(DEBUGGER_TABS);

type Props = {
  dashboardId: number;
  timezone: string;
};

export const DashboardDebugger: FC<Props> = ({ dashboardId, timezone }) => {
  const [selectedTabId, setSelectedTabId] = useState<string>(DEBUGGER_TABS.QUERY);

  const renderTab = () => {
    if (selectedTabId === DEBUGGER_TABS.QUERY) return <QueryDebugger />;

    if (selectedTabId === DEBUGGER_TABS.DATA) return <DataDebugger />;

    return <VariablesDebugger dashboardId={dashboardId} timezone={timezone} />;
  };

  return (
    <div className={rootClass}>
      <Tabs onTabSelect={setSelectedTabId} selectedTabId={selectedTabId} tabs={TabNames} />
      <div className={sprinkles({ flex: 1, overflowY: 'auto', width: 'fill' })}>{renderTab()}</div>
    </div>
  );
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  overflow: 'hidden',
  flexItems: 'column',
  borderLeft: 1,
  borderColor: 'gray7',
});

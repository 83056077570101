import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { Switch, Select, Input } from 'components/ds';
import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { NumberFormatToggle } from 'components/NumberFormatToggle';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { KPIValueFormat, OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import {
  V2_NUMBER_FORMATS,
  TIME_DIFF_FORMATS,
  NUMBER_FORMATS_WITH_DECIMALS,
  TIME_DIFF_FORMAT_SELECT_OPTIONS,
} from 'constants/dataConstants';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export const KPITrendValueConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const { valueFormat } = instructions;

  const selectedFormat = valueFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const selectedTimeFormat = valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;
  const selectedAlignment = valueFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  const updateValueFormat = (valueFormat: KPIValueFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.valueFormat = { ...draft.valueFormat, ...valueFormat };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <NumberFormatToggle
        className={configInputClass}
        label="Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateValueFormat({ numberFormat: { id: numberFormat } })}
      />

      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id ? (
        <>
          <Select
            className={configInputClass}
            label="Time Format"
            onChange={(formatId) => updateValueFormat({ timeFormat: { id: formatId } })}
            placeholder="Standard"
            selectedValue={selectedTimeFormat.id}
            values={TIME_DIFF_FORMAT_SELECT_OPTIONS}
          />
          {valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id && (
            <Input
              showInputButton
              className={configInputClass}
              defaultValue={valueFormat?.timeCustomerFormat}
              label="Custom Time Format"
              onSubmit={(newValue) => updateValueFormat({ timeCustomerFormat: newValue })}
              placeholder="DD days HH:mm:ss"
            />
          )}
        </>
      ) : null}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.decimalPlaces ?? 2)}
          label="Decimal Places"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.significantDigits ?? 3)}
          label="Significant Digits"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      ) : null}
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={instructions.valueFormat?.units}
        label="Unit"
        onSubmit={(newValue) => updateValueFormat({ units: newValue })}
      />
      <Switch
        className={configInputClass}
        label="Include Unit Padding"
        onChange={() => updateValueFormat({ unitPadding: !instructions.valueFormat?.unitPadding })}
        switchOn={instructions.valueFormat?.unitPadding}
      />

      {instructions?.hideTrendLines ? (
        <>
          <Switch
            className={configInputClass}
            label="Bold Value"
            onChange={() => updateValueFormat({ bold: !valueFormat?.bold })}
            switchOn={valueFormat?.bold}
          />
          <Switch
            className={configInputClass}
            label="Italicize Value"
            onChange={() => updateValueFormat({ italic: !valueFormat?.italic })}
            switchOn={valueFormat?.italic}
          />
          <HorizontalAlignmentToggle
            className={configInputClass}
            selectedAlignment={selectedAlignment}
            updateAlignment={(horizAlignment) => updateValueFormat({ horizAlignment })}
          />
        </>
      ) : null}
    </div>
  );
};

import { FC } from 'react';

import { InfoCard } from 'components/InfoCard';
import { DatasetLink } from './DatasetLink';

import { DashboardElement, DashboardElementConfig } from 'types/dashboardTypes';
import { ResourceDataset } from 'types/exploResource';

type Props = {
  datasets: Record<string, ResourceDataset>;
  element: DashboardElement;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const ChartLinkConfig: FC<Props> = ({ datasets, element, updateConfig }) => {
  return (
    <>
      <InfoCard
        noTopMargin
        text="To link a chart, start by selecting a column from the dataset it uses."
      />
      {Object.values(datasets).map((dataset) => (
        <DatasetLink
          dataset={dataset}
          element={element}
          key={`${element.name}-${dataset.id}`}
          updateConfig={updateConfig}
        />
      ))}
    </>
  );
};

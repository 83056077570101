import { FC } from 'react';
import { sprinkles } from 'components/ds';

type Props = { img: string; alt: string };

export const DocImage: FC<Props> = ({ img, alt }) => (
  <img alt={alt} className={fitEntireClass} src={img} />
);

const fitEntireClass = sprinkles({ maxWidth: 'fill', maxHeight: 'fill' });

import { FC } from 'react';
import produce from 'immer';

import { Checkbox, sprinkles } from 'components/ds';
import { showWarningToast } from 'shared/sharedToasts';

import { DateGroupToggleConfig } from 'types/dashboardTypes';
import { TrendGroupingOptions, TREND_GROUPING_OPTIONS } from 'types/dateRangeTypes';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';

type Props = {
  config: DateGroupToggleConfig;

  updateToggleConfig: (config: DateGroupToggleConfig) => void;
};

export const DateGroupValuesConfig: FC<Props> = ({ config, updateToggleConfig }) => {
  const defaultOption = config.defaultGroupingOption ?? TrendGroupingOptions.MONTHLY;

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
      {Object.values(TREND_GROUPING_OPTIONS).map((groupingOption) => {
        const configOptionsForType = config.groupingOptionByType?.[groupingOption.id] ?? {
          exclude: false,
        };
        return (
          <div
            className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1.5' })}
            key={groupingOption.id}>
            <Checkbox
              isChecked={!configOptionsForType.exclude}
              onChange={() => {
                if (groupingOption.id === defaultOption) {
                  showWarningToast('The default option must be available.');
                  return;
                }

                const newConfig = produce(config, (draft) => {
                  if (!draft.groupingOptionByType) draft.groupingOptionByType = {};
                  draft.groupingOptionByType[groupingOption.id] = {
                    ...draft.groupingOptionByType[groupingOption.id],
                    exclude: !configOptionsForType.exclude,
                  };
                });

                if (getDateGroupSwitchOptions(newConfig).length < 2) {
                  showWarningToast('At least 2 date grouping options must be available');
                } else updateToggleConfig(newConfig);
              }}
            />
            <div className={sprinkles({ color: 'gray11', body: 'b2' })}>{groupingOption.name}</div>
          </div>
        );
      })}
    </div>
  );
};

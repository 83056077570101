import { FC, useMemo } from 'react';

import { EmbedButton } from 'components/embed';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import { DraggableColumnInfo } from 'utils/customerReportUtils';
import { BaseCol } from 'types/columnTypes';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { getColDisplay } from 'utils/V2ColUtils';

type Props = {
  disabled?: boolean;
  selectedColumns: BaseCol[];
  columns: DraggableColumnInfo[];
  onAddColumn: (column: DraggableColumnInfo) => void;
  columnConfigs?: ReportBuilderColConfigs;
  tooltipText?: string;
};

export const AddColumnMenu: FC<Props> = ({
  selectedColumns,
  columns,
  onAddColumn,
  disabled,
  columnConfigs,
  tooltipText,
}) => {
  const availableColumns = useMemo(() => {
    const groupBySet = new Set(selectedColumns.map((col) => col.name));
    return columns.filter((col) => !groupBySet.has(col.name));
  }, [columns, selectedColumns]);

  const menuOptions = useMemo(
    () =>
      availableColumns.map((col) => ({
        name: getColDisplay(col, columnConfigs),
        value: col.name,
        icon: SCHEMA_TYPE_ICON_MAP[col.type],
      })),
    [availableColumns, columnConfigs],
  );

  if (!availableColumns?.length) return null;

  return (
    <EmbeddedDropdownMenu
      canSearch
      disabled={disabled}
      menuOptions={menuOptions}
      onClick={(columnName) => {
        const column = availableColumns.find((col) => col.name === columnName);
        if (column) onAddColumn(column);
      }}>
      <EmbedButton
        disabled={disabled}
        icon="plus"
        tooltipProps={tooltipText ? { text: tooltipText } : undefined}
        variant="tertiary"
      />
    </EmbeddedDropdownMenu>
  );
};

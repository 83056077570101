import { FC } from 'react';
import cx from 'classnames';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { IconButton, sprinkles } from 'components/ds';
import { useDispatch } from 'react-redux';

import { OperatorDropdown } from 'pages/dashboardPage/DashboardDatasetView/Header/OperatorDropdown';
import { FilterConfigMenu } from './FilterConfigMenu';
import { ColumnOptionsPopover } from '../vizConfigs/droppable/ColumnOptionsPopover';
import * as styles from '../vizConfigs/droppable/styles.css';

import { DatasetSchema } from 'types/datasets';
import { FilterClause } from 'constants/types';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { FILTER_OPS_NO_VALUE } from 'types/filterOperations';
import {
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
} from 'actions/dataPanelConfigActions';

const useStyles = makeStyles((theme: Theme) => ({
  operatorDropdown: {
    '& .bp3-button': {
      width: '100%',
      backgroundColor: `${theme.palette.ds.hovered.lightBlue} !important`,
      borderRadius: 3,
      border: 'none !important',
      padding: `0px !important`,
      color: theme.palette.ds.hovered.blue,
      '&:disabled': {
        backgroundColor: `${theme.palette.ds.grey400} !important`,
        color: theme.palette.ds.grey800,
      },
    },
    '& .bp3-icon': {
      color: theme.palette.ds.blue,
      padding: `0px 8px !important`,
    },
    '& .bp3-button-text': {
      fontWeight: 500,
      marginLeft: theme.spacing(2),
      width: '100%',
    },
  },
}));

type Props = {
  clause: FilterClause;
  clauseIdx: number;
  dashboardElements: DashboardElement[];
  dashboardParams: Record<string, DashboardParam>;
  dpsWithDrilldown: DataPanelTemplate[];
  schema: DatasetSchema;
};

export const DroppedFilterColumn: FC<Props> = ({
  clause,
  clauseIdx,
  dashboardElements,
  dashboardParams,
  dpsWithDrilldown,
  schema,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showFilterConfigMenu =
    !clause.filterOperation || !FILTER_OPS_NO_VALUE.has(clause.filterOperation.id);

  return (
    <div
      className={cx(
        sprinkles({ paddingBottom: showFilterConfigMenu ? undefined : 'sp0' }),
        containerStyles,
      )}>
      <div className={sprinkles({ flexItems: 'column', flex: 1, overflow: 'hidden' })}>
        <div className={sprinkles({ display: 'flex', flex: 1, gap: 'sp.5' })}>
          <div className={sprinkles({ overflow: 'hidden', width: 'fill' })}>
            <ColumnOptionsPopover
              transparentIcon
              className={columnsPopoverTargetContainerClass}
              displayName={clause.filterColumn?.name}
              onColChange={(column) => dispatch(selectFilterColumn({ index: clauseIdx, column }))}
              schema={schema}
            />
          </div>
          <OperatorDropdown
            usePortal
            className={classes.operatorDropdown}
            onOperatorSelect={(operator) =>
              dispatch(selectFilterOperator({ index: clauseIdx, operator }))
            }
            selectedColumn={clause.filterColumn}
            selectedOperator={clause.filterOperation?.id}
          />
        </div>
        <div className={sprinkles({ marginTop: 'sp.5' })}>
          {showFilterConfigMenu ? (
            <FilterConfigMenu
              clause={clause}
              clauseIdx={clauseIdx}
              dashboardElements={dashboardElements}
              dashboardParams={dashboardParams}
              dataPanels={dpsWithDrilldown}
              operatorDropdownClass={classes.operatorDropdown}
            />
          ) : null}
        </div>
      </div>
      <IconButton
        className={cx(styles.iconBtn, sprinkles({ marginLeft: 'sp.5' }))}
        name="cross"
        onClick={() => dispatch(deleteFilterClause(clauseIdx))}
      />
    </div>
  );
};

const containerStyles = sprinkles({
  backgroundColor: 'lightBlue',
  padding: 'sp.5',
  borderRadius: 3,
  marginBottom: 'sp.5',
  flexItems: 'alignCenter',
});

const columnsPopoverTargetContainerClass = sprinkles({
  backgroundColor: 'blue3',
  flex: 1,
  flexItems: 'alignCenterBetween',
  overflow: 'hidden',
});

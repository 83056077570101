import { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { sha256 } from 'js-sha256';

import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { PasswordInput } from 'components/PasswordInput';
import { InfoCard } from 'components/InfoCard';
import { Button, sprinkles } from 'components/ds';

import { pageView } from 'analytics/exploAnalytics';
import { passwordResetConfirm } from 'actions/authAction';
import { validatePassword } from 'utils/passwordUtils';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';

export const ResetPasswordPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { resetToken } = useParams<{ resetToken: string }>();

  const [resetLoading, setResetLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  useEffect(() => {
    pageView('Forgot password');
  }, []);

  const onPasswordResetConfirmSubmitted = () => {
    setResetLoading(true);
    dispatch(
      passwordResetConfirm(
        { postData: { token: resetToken, password: sha256(password) } },
        () => {
          showSuccessToast('Password reset successfully.');
          setPassword('');
          setPassword2('');
          setResetLoading(false);
          history.push('/login');
        },
        () => {
          showErrorToast('Something went wrong. Please contact Explo support if this continues.');
          setResetLoading(false);
        },
      ),
    );
  };
  const passwordError = password && password2 ? validatePassword(password, password2) : '';

  return (
    <OnboardingFlowPage
      rightContentTitle="Password Reset"
      rightPanelContent={
        <div className={sprinkles({ flexItems: 'column', gap: 'sp2', width: 'fill' })}>
          <PasswordInput
            data-testid="reset-password"
            label="New password"
            onChange={setPassword}
            value={password}
          />
          <PasswordInput
            data-testid="reset-password-confirm"
            label="Confirm new password"
            onChange={setPassword2}
            value={password2}
          />

          {passwordError ? (
            <InfoCard error className={sprinkles({ height: 'fitContent' })} text={passwordError} />
          ) : null}
          <Button
            fillWidth
            data-testid="reset-password-submit"
            disabled={!password || !password2 || !!passwordError}
            loading={resetLoading}
            onClick={onPasswordResetConfirmSubmitted}>
            Set new password
          </Button>
        </div>
      }
    />
  );
};

import { FC } from 'react';
import cx from 'classnames';

import { IconButton, InfoIcon, Switch, Tooltip, sprinkles } from 'components/ds';
import { IconName } from './ds/Icon';

type ButtonProps = { icon: IconName; onClick: () => void; tooltipText?: string };

type Props = {
  className?: string;
  name: string;
  btnProps?: ButtonProps;
  switchProps?: { isOn: boolean; onChange: (newValue: boolean) => void };
  infoText?: string;
};

export const SettingHeader: FC<Props> = ({ className, name, btnProps, switchProps, infoText }) => {
  const renderButton = ({ icon, onClick, tooltipText }: ButtonProps) => {
    const button = <IconButton name={icon} onClick={() => onClick()} variant="secondary" />;
    if (tooltipText) {
      return (
        <Tooltip side="bottom" text={tooltipText}>
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  return (
    <div className={cx(rootClass, className)} style={{ height: 32 }}>
      {name}
      {btnProps ? (
        renderButton(btnProps)
      ) : switchProps ? (
        <Switch onChange={switchProps.onChange} switchOn={switchProps.isOn} />
      ) : infoText ? (
        <InfoIcon text={infoText} />
      ) : null}
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'alignCenterBetween',
  backgroundColor: 'gray2',
  body: 'section',
  paddingY: 'sp1',
  paddingX: 'sp1.5',
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeThunkRequest } from 'utils/thunkUtils';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { createApiRequestConfig } from 'actions/actionUtils';

export const fetchAiSuggestion = createAsyncThunk<
  {
    query: string | null;
    explanation: string;
  },
  {
    query: string;
    error?: string;
  },
  { state: ReduxState }
>(ACTION.FETCH_AI_SUGGESTION, async ({ query, error }) =>
  error
    ? makeThunkRequest(
        createApiRequestConfig('ai/debug/', 'POST', { query, error }),
        'Error debugging your query',
      )
    : makeThunkRequest(
        createApiRequestConfig('ai/optimize/', 'POST', { query }),
        'Error optimizing your query',
      ),
);

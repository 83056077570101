import { FC } from 'react';
import * as styles from './CredentialsSection.css';
import InputWithTag from 'shared/InputWithTag';

import { getStatusInfo } from '../../utils';

type StringProps = {
  label: string;
  value?: string | number | null;
  placeholder?: string;
  helpTooltip?: string;
  optional?: boolean;
  type?: string;
  updateConfig: (value: string) => void;
};

export const CredentialsInput: FC<StringProps> = ({
  label,
  value,
  placeholder,
  helpTooltip,
  optional,
  type,
  updateConfig,
}) => {
  return (
    <InputWithTag
      className={styles.credentialInput}
      helpText={helpTooltip}
      label={label}
      onChange={updateConfig}
      placeholder={placeholder}
      statusInfo={getStatusInfo(
        value === undefined,
        !!((value !== 0 && value !== '' && value !== null) || optional),
      )}
      type={type || 'text'}
      value={(value || '').toString()}
    />
  );
};

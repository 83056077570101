import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import { DatePickerInput } from 'components/DatePickerInput';

import { DashboardVariable, DatepickerElemConfig } from 'types/dashboardTypes';
import { DATE_RANGE_TYPES } from 'types/dateRangeTypes';
import { getDateMax, getDateMin } from 'utils/dateUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

type Props = {
  config: DatepickerElemConfig;
  value: DashboardVariable;
  onNewValueSelect: (newValue: DateTime | undefined) => void;
  disabled?: boolean;
  openElementToLeft: boolean;
  timezone: string;
  isInContainer?: boolean;
};

export const DashboardDatepickerElement: FC<Props> = ({
  config,
  value,
  onNewValueSelect,
  disabled,
  openElementToLeft,
  isInContainer,
  timezone,
}) => {
  const dispatch = useDispatch();
  const context = useContext(DashboardLayoutContext);

  const { relativeDateRange, dateRangeType } = config;

  const portalId = isInContainer ? context.dashboardLayoutTagId : undefined;

  return (
    <div>
      <DatePickerInput
        isEmbed
        disabled={disabled}
        label={config.label}
        labelHelpText={config.showTooltip ? config.infoTooltipText : undefined}
        maxDate={
          dateRangeType === DATE_RANGE_TYPES.EXACT
            ? config.maxValue
            : getDateMax(relativeDateRange, timezone)
        }
        minDate={
          dateRangeType === DATE_RANGE_TYPES.EXACT
            ? config.minValue
            : getDateMin(relativeDateRange, timezone)
        }
        onNewValueSelect={(date) => {
          // this is a date, not a date time, so leaving it in UTC
          onNewValueSelect((date as DateTime).toUTC());
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED));
        }}
        openElementToLeft={openElementToLeft}
        portalId={portalId}
        selectedValue={
          typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
        }
        showCancelBtn={!config.disableCancel}
        showTimeSelect={!config.hideTimeSelect}
      />
    </div>
  );
};

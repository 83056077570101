import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'utils/standard';
import * as RD from 'remotedata';

import { fetchDashboardAttributes } from 'actions/dashboardAttributesActions';
import { Button, IconButton, NavigationToggle, sprinkles } from 'components/ds';
import { NavigationItem } from 'components/ds/NavigationToggle';
import { SavingInfo } from './SavingInfo';
import { EditResourceBannerDropdown } from 'shared/ExploResource/EditResourceBannerDropdown';
import { VersionControlModal } from 'components/VersionControlModal';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';

import { SIDE_PANE_HEADER_HEIGHT } from './editDashboardPage.css';
import { DashboardVersion } from 'types/dashboardVersion';
import { Dashboard } from 'actions/dashboardActions';
import { VIEW_MODE } from 'types/dashboardTypes';
import { ReduxState } from 'reducers/rootReducer';
import { ResourcePageType, VersionInfo } from 'types/exploResource';
import { isEmailReportingEnabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import {
  setExploreInteractionsInfo,
  toggleVisiblePanes,
} from 'reducers/dashboardInteractionsReducer';
import {
  createNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
} from 'actions/dashboardV2Actions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { fetchEmailCadenceList } from 'actions/emailActions';
import { cloneComputedViews } from 'reducers/thunks/fidoThunks';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { fetchVersion } from 'reducers/thunks/versionManagementThunks';

type Props = {
  dashboardVersionInfo: VersionInfo;
  dashboard: Dashboard;
  inEditMode?: boolean;
  allDashboardVersions?: DashboardVersion[];
};

export const DashboardEditBanner: FC<Props> = ({
  allDashboardVersions,
  dashboard,
  dashboardVersionInfo,
  inEditMode,
}) => {
  const dispatch = useDispatch();
  const {
    viewMode,
    currentDashboardId,
    dashboardAttributes,
    currentUser,
    switchEditModeLoading,
    emailCadences,
    paneStates,
    useFido,
  } = useSelector(
    (state: ReduxState) => ({
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      currentDashboardId: state.dashboard.currentDashboardId,
      dashboardAttributes: state.dashboardAttributes.attributes,
      currentUser: state.currentUser,
      paneStates: state.dashboardInteractions.paneOpenStates,
      emailCadences: state.emailCadence.cadences,
      switchEditModeLoading: createLoadingSelector(
        [ACTION.CREATE_NEW_DASHBOARD_VERSION, ACTION.PUBLISH_NEW_DASHBOARD_VERSION],
        false,
      )(state),
      useFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );
  const [versionControlModalOpen, setVersionControlModalOpen] = useState(false);

  useEffect(() => {
    if (RD.isIdle(dashboardAttributes)) dispatch(fetchDashboardAttributes());
  }, [dashboardAttributes, dispatch]);

  useEffect(() => {
    if (!currentUser.team?.id || !RD.isIdle(emailCadences)) return;
    dispatch(fetchEmailCadenceList({ id: currentUser.team.id }));
  }, [emailCadences, dispatch, currentUser.team?.id]);

  const emailCadence = useMemo(
    () =>
      RD.getOrDefault(emailCadences, []).find(
        (cadence) => cadence.dashboard_template_id === dashboard.id,
      ),
    [emailCadences, dashboard.id],
  );

  const mostRecentVersion = useMemo(() => {
    if (!allDashboardVersions?.length) return;
    const sortedVersions = sortBy(allDashboardVersions, (version) => -version.version_number);
    return sortedVersions[0];
  }, [allDashboardVersions]);

  const onEditClicked = useCallback(() => {
    if (currentDashboardId === undefined) return;

    dispatch(setExploreInteractionsInfo({ isEditing: true }));
    if (dashboardVersionInfo.is_draft) return;

    // If sortedVersions is empty, the dashboard versions have not loaded yet, which means they
    // couldn't have switched to a different version
    if (mostRecentVersion?.is_draft) {
      dispatch(switchCurrentlyEditingDashboardVersion({ dashboardVersion: mostRecentVersion }));
    } else {
      if (!useFido) {
        dispatch(
          createNewDashboardVersion({ id: currentDashboardId, postData: {} }, (response) =>
            dispatch(
              switchCurrentlyEditingDashboardVersion({
                dashboardVersion: response.dashboard_version,
              }),
            ),
          ),
        );
      } else {
        if (!mostRecentVersion) return;

        dispatch(
          fetchVersion({
            isExplore: true,
            args: { id: currentDashboardId },
            onSuccess: ({ version }) => {
              if (!version || !('configuration' in version)) return;

              return dispatch(
                cloneComputedViews({
                  configuration: version.configuration,
                  onSuccess: (configuration) =>
                    dispatch(
                      createNewDashboardVersion(
                        {
                          id: currentDashboardId,
                          postData: { configuration: configuration as DashboardVersionConfig },
                        },
                        ({ dashboard_version }) =>
                          dispatch(
                            switchCurrentlyEditingDashboardVersion({
                              dashboardVersion: dashboard_version,
                            }),
                          ),
                      ),
                    ),
                }),
              );
            },
          }),
        );
      }
    }
  }, [dispatch, dashboardVersionInfo, currentDashboardId, mostRecentVersion, useFido]);

  const onReturnMostCurrentVersionClicked = useCallback(() => {
    if (currentDashboardId === undefined || !mostRecentVersion) return;

    dispatch(switchCurrentlyEditingDashboardVersion({ dashboardVersion: mostRecentVersion }));
  }, [dispatch, currentDashboardId, mostRecentVersion]);

  const setViewMode = (viewMode: VIEW_MODE) => dispatch(setExploreInteractionsInfo({ viewMode }));

  const renderViewModeButtonGroup = () => {
    const value = viewMode;
    const items: NavigationItem[] = [
      { iconName: 'desktop', value: VIEW_MODE.DEFAULT },
      { iconName: 'mobile-screen', value: VIEW_MODE.MOBILE },
      { iconName: 'file-pdf', value: VIEW_MODE.PDF },
    ];
    if (isEmailReportingEnabled(currentUser)) {
      items.push({ iconName: 'envelope', value: VIEW_MODE.EMAIL });
    }

    return (
      <NavigationToggle
        valueRequired
        items={items}
        onValueChange={(value) => setViewMode((value as VIEW_MODE) ?? VIEW_MODE.DEFAULT)}
        type="single"
        value={value}
      />
    );
  };

  const renderEditorLayoutButtonsGroup = () => {
    if (!inEditMode || viewMode !== VIEW_MODE.DEFAULT) return;

    const value = [];
    if (paneStates.left) value.push('left');
    if (paneStates.right) value.push('right');

    return (
      <NavigationToggle
        className={sprinkles({ marginRight: 'sp3' })}
        items={[
          { iconName: 'sidebar', value: 'left' },
          { iconName: 'sidebar-flip', value: 'right' },
        ]}
        onValueChange={(value) => dispatch(toggleVisiblePanes(value))}
        type="multiple"
        value={value}
      />
    );
  };

  const renderEditButtons = () => {
    return (
      <div className={sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp1' })}>
        <SavingInfo
          isDraft={dashboardVersionInfo.is_draft}
          resourceType={ResourcePageType.EXPLORE}
          versionNumber={dashboardVersionInfo.version_number}
        />
        <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
          {inEditMode ? (
            <>
              <IconButton
                name="refresh"
                onClick={() => dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }))}
                tooltipProps={{
                  text:
                    'Refresh Dashboard' +
                    (dashboard.is_cache_enabled ? '. This will also refresh the cache.' : ''),
                }}
                variant="primary"
              />
              <Button
                disabled={switchEditModeLoading}
                loading={switchEditModeLoading}
                onClick={() => dispatch(setExploreInteractionsInfo({ isEditing: false }))}
                variant="primary">
                Done Editing
              </Button>
            </>
          ) : (
            renderEditDashboardButton()
          )}
        </div>
      </div>
    );
  };

  const renderEditDashboardButton = () => {
    const isMostRecentVersion =
      dashboardVersionInfo.version_number !== allDashboardVersions?.length;

    // There should always be at least 1 dashboard version (the one created when the dashboard is created)
    // so if the length of `allDashboardVersions` is 0, the dashboard versions haven't loaded yet
    if (allDashboardVersions?.length && isMostRecentVersion) {
      return (
        <>
          <Button
            className={sprinkles({ marginRight: 'sp1' })}
            disabled={switchEditModeLoading}
            onClick={() => setVersionControlModalOpen(true)}
            variant="secondary">
            Version Control
          </Button>
          <Button
            disabled={switchEditModeLoading}
            loading={switchEditModeLoading}
            onClick={() => onReturnMostCurrentVersionClicked()}
            variant="primary">
            Return to Current Draft
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          className={sprinkles({ marginRight: 'sp1' })}
          disabled={switchEditModeLoading}
          onClick={() => !switchEditModeLoading && setVersionControlModalOpen(true)}
          variant="secondary">
          Version Control
        </Button>
        {doesUserHavePermission(
          currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
          PERMISSIONED_ACTIONS.UPDATE,
        ) ? (
          <Button
            disabled={switchEditModeLoading || allDashboardVersions?.length === 0}
            loading={switchEditModeLoading}
            onClick={() => !switchEditModeLoading && onEditClicked()}
            variant="primary">
            Edit
          </Button>
        ) : null}
      </>
    );
  };

  const renderVersionControlModal = () => {
    if (!versionControlModalOpen || currentDashboardId === undefined) return;

    return (
      <VersionControlModal
        closeModal={() => setVersionControlModalOpen(false)}
        pageType={ResourcePageType.EXPLORE}
      />
    );
  };

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenterBetween',
        backgroundColor: 'white',
        borderBottom: 1,
        borderBottomColor: 'gray7',
        paddingRight: 'sp1.5',
        width: 'fill',
      })}
      style={{ height: SIDE_PANE_HEADER_HEIGHT }}>
      <EditResourceBannerDropdown
        showDeveloperSettings
        dashboardAttributes={RD.getOrDefault(dashboardAttributes, [])}
        emailCadence={emailCadence}
        pageType={ResourcePageType.EXPLORE}
        resource={dashboard}
      />
      <div className={sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp2' })}>
        <div className={sprinkles({ marginRight: 'sp.5', width: 'maxContent' })}>Viewing as</div>
        <ViewingAsCustomerSelector parseUrl />
      </div>
      <div className={sprinkles({ display: 'flex', flex: 1, justifyContent: 'center' })}>
        {renderEditorLayoutButtonsGroup()}
        {renderViewModeButtonGroup()}
      </div>
      <div className={sprinkles({ display: 'flex', flex: 1, justifyContent: 'flex-end' })}>
        {renderEditButtons()}
      </div>
      {renderVersionControlModal()}
    </div>
  );
};

import { AggColInfo, ColumnConfigsWithName, GroupByColInfo } from 'types/columnTypes';
import { SortOrder, V2GeneralInstructions } from 'constants/types';

export type V2PivotTableInstructions = {
  rowGroupBys: GroupByColInfo[];
  // Currently only supporting one, but setting as a list incase we want
  // to allow more in the future
  colGroupBys: GroupByColInfo[];
  aggregations: AggColInfo[];
  columnConfigs: ColumnConfigsWithName;
  generalFormat?: V2GeneralInstructions;
  rowSortOrder?: SortOrder;
};

export const EmptyV2PivotInstructions: V2PivotTableInstructions = {
  rowGroupBys: [],
  colGroupBys: [],
  aggregations: [],
  columnConfigs: {},
};

import { Intent } from 'components/ds';

import { every } from 'utils/standard';
import { DataSourcePropertyOption, SupportedDataSource } from 'actions/dataSourceActions';
import { Props as InputWithTagProps } from 'shared/InputWithTag/InputWithTag';
import {
  DataSourceConfiguration,
  DataSourceConfigurationSchema,
  DataSourcePropertyOptions,
} from 'actions/dataSourceActions';

import {
  SSHTunnel,
  TenantPrivateKeyAuthentication,
  Postgres,
  MySql,
  MSS,
  Tunnel,
  Redshift,
} from '@explo-tech/fido-api';
import { FidoDataSourceConfig, MiscSSHConfig } from './types';
import { DATABASES, SSH_AUTH_TYPE, FIDO_TYPES, TUNNEL, DATA_SOURCE_AUTH } from './constants';
import { User } from 'actions/userActions';

export const areCredentialsComplete = (
  properties: DataSourcePropertyOptions,
  configuration: DataSourceConfiguration,
  isSecurityPage: boolean,
): boolean => {
  return every(
    Object.keys(properties).map((propertyName: string) => {
      const property = properties[propertyName];
      const configValue = configuration[propertyName];

      if (!property) return false;

      if (property.security_page === !isSecurityPage) return true;

      if ((property.depends_on && !configuration[property.depends_on || '']) || property.optional) {
        return true;
      }

      // Dropdowns have sub-properties that need to be recursively checked
      if (property.type === 'dropdown') {
        const dropdownValue = configValue as string;
        if (!property.options || !property.options[dropdownValue]) return false;

        return areCredentialsComplete(
          (property.options ?? {})[dropdownValue],
          configuration,
          isSecurityPage,
        );
      }

      // A checkbox field is configured since if it is not present, it is false
      if (property.type === 'checkbox') {
        return true;
      }

      return (
        configValue !== null &&
        configValue !== undefined &&
        configValue !== '' &&
        (typeof configValue !== 'number' || !isNaN(configValue))
      );
    }),
  );
};

export const getPropertyNames = (
  configurationSchema: DataSourceConfigurationSchema,
  isSecurityPage: boolean,
) =>
  configurationSchema.order.filter(
    (propertyName) =>
      !!configurationSchema.properties[propertyName].security_page === isSecurityPage,
  );

export const getHelpText = (
  dataSource: SupportedDataSource | undefined,
  property: DataSourcePropertyOption,
) => {
  const snowflakeAccountHelpText = (
    <>
      To learn more about Snowflake account identifiers{' '}
      <a
        href="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html#where-are-account-identifiers-used"
        rel="noreferrer"
        style={{ color: 'white' }}
        target="_blank">
        click here
      </a>
    </>
  );

  return dataSource?.name === DATABASES.SNOWFLAKE && property.label === 'Account'
    ? snowflakeAccountHelpText
    : property.help_tooltip;
};

export const getStatusInfo = (
  isEmpty: boolean,
  isDisplayValid: boolean,
  idError?: string,
): InputWithTagProps['statusInfo'] => {
  if (idError)
    return {
      statusIcon: 'cross',
      statusIntent: Intent.ERROR,
      statusText: 'ID not unique',
    };

  return {
    statusIcon: !isEmpty ? (isDisplayValid ? 'check' : 'cross') : undefined,
    statusIntent: !isEmpty ? (isDisplayValid ? Intent.SUCCESS : Intent.ERROR) : Intent.NONE,
    statusText: !isEmpty ? (isDisplayValid ? '' : `Can't be blank`) : undefined,
  };
};

export const buildConfigWithoutTunnel = (
  config: Partial<FidoDataSourceConfig> & { authMethod: string },
  requireEncryptedFields: boolean,
) => {
  const { host, port, database, authentication } = config;

  if (
    !host ||
    !port ||
    !database ||
    !authentication ||
    !authentication?.username ||
    (requireEncryptedFields && !authentication?.password)
  )
    return;

  return {
    host,
    port,
    database,
    authentication: {
      '@type': DATA_SOURCE_AUTH.USERNAME_PASSWORD,
      username: authentication.username,
      password: authentication.password,
    },
  };
};

export const buildTunnel = (
  sshConfig: Partial<SSHTunnel> & Partial<MiscSSHConfig>,
  requireEncryptedFields: boolean,
): Tunnel | undefined => {
  if (!sshConfig.useSSH) return { '@type': TUNNEL.PUBLIC_INTERNET };
  const { host, port, authentication } = sshConfig;

  if (
    !host ||
    !port ||
    !authentication?.username ||
    (sshConfig.sshAuthMethod === SSH_AUTH_TYPE.TENANT &&
      requireEncryptedFields &&
      !(authentication as TenantPrivateKeyAuthentication)?.privateKey)
  )
    return;

  return {
    '@type': TUNNEL.SSH,
    tunnel: { '@type': TUNNEL.PUBLIC_INTERNET },
    host,
    port,
    authentication:
      sshConfig.sshAuthMethod === SSH_AUTH_TYPE.TENANT
        ? { ...authentication, '@type': SSH_AUTH_TYPE.TENANT }
        : { ...authentication, '@type': SSH_AUTH_TYPE.VENDOR },
  };
};

/**
 * Need to distinguish between whether we are creating or updating.
 * Creating will require encrypted fields even though type says can be null.
 * Updating will allow encrypted fields to be optional.
 */
export const buildFinalFidoDataSourceConfig = (
  config: Partial<FidoDataSourceConfig> & { authMethod: string },
  sshConfig: Partial<SSHTunnel> & MiscSSHConfig,
  type: DATABASES | undefined,
  requireEncryptedFields: boolean,
): FidoDataSourceConfig | undefined => {
  if (!type) return;
  const configWithoutTunnel = buildConfigWithoutTunnel(config, requireEncryptedFields);
  const tunnel = buildTunnel(sshConfig, requireEncryptedFields);

  if (configWithoutTunnel !== undefined && tunnel !== undefined) {
    const sharedConfig = { ...configWithoutTunnel, tunnel };

    switch (type) {
      case DATABASES.POSTGRES:
        return {
          '@type': FIDO_TYPES.POSTGRES,
          ...sharedConfig,
        } as Postgres;
      case DATABASES.MYSQL:
        return {
          '@type': FIDO_TYPES.MYSQL,
          ...sharedConfig,
        } as MySql;
      case DATABASES.SQLSERVER:
        return {
          '@type': FIDO_TYPES.SQLSERVER,
          ...sharedConfig,
        } as MSS;
      case DATABASES.REDSHIFT:
        return {
          '@type': FIDO_TYPES.REDSHIFT,
          ...sharedConfig,
        } as Redshift;
      default:
        return;
    }
  }
};

const errorRegexes = [
  {
    regx: /Code: 210/i,
    description: (
      <>
        Explo only supports using the Clickhouse Native Protocol, which is commonly port 9440. Go to{' '}
        <a>https://clickhouse.com/docs/en/guides/sre/network-ports</a> for more information.
      </>
    ),
  },
];

export const parseErrorMessage = (errorMessage: string | undefined) =>
  errorRegexes.find((er) => errorMessage?.match(er.regx))?.description ?? errorMessage;

const userPingInfo = (currentUser: User) => {
  const { first_name, last_name, email, team } = currentUser;
  return `${first_name} ${last_name} (${email}) on team "${team?.team_name}"`;
};

const dbPingInfo = (
  isError: boolean | undefined,
  useFido: boolean | undefined,
  name: string | undefined,
  type: string | undefined,
) => {
  const errorMsg = isError ? 'that ERRORED' : '';
  const usingFidoInfo = useFido ? 'using fido' : '';
  return `data source connection ${errorMsg} (name: "${name}" and type "${type}") ${usingFidoInfo}`;
};

export const pingDataSourceConnectionMsg = (
  currentUser: User,
  name: string | undefined,
  type: string | undefined,
  didError: boolean | undefined,
  usingFido: boolean | undefined,
  isRetesting?: boolean,
  isJobQue?: boolean,
) => {
  const userInfo = userPingInfo(currentUser);
  const dbInfo = dbPingInfo(didError, usingFido, name, type);

  if (isJobQue) {
    if (isRetesting) {
      return `${userInfo}" is trying to re-test a ${dbInfo} using the job queue`;
    }
    return `${userInfo}" is trying to test a ${dbInfo} using the job queue`;
  }

  if (isRetesting) {
    if (didError) {
      return `${userInfo} tried to re-test an updated ${dbInfo}`;
    }
    return `${userInfo} successfully re-tested an updated ${dbInfo}`;
  }

  if (didError) {
    return `${userInfo} tried to test a ${dbInfo}`;
  }

  return `${userInfo} successfully tested a ${dbInfo}`;
};

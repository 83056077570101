import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { DASHBOARD_ELEMENT_TYPES, DATASET_EDITOR_MODE, VIEW_MODE } from 'types/dashboardTypes';
import { clearDashboardLayoutReducer } from './dashboardLayoutReducer';
import { Layout } from '@explo-tech/react-grid-layout';
import { OPERATION_TYPES } from 'constants/types';
import { createDashboardDataPanel, createDashboardElement } from 'actions/dashboardV2Actions';
import {
  addEditableSectionChart,
  duplicateEditableSectionChart,
} from './dashboardEditConfigReducer';
import { MENU_HEIGHT } from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';

const EXPLO_DATASET_EDITOR_KEY = 'explo_dataset_editor';

type SelectedDashboardItem = {
  id: string;
  type: DASHBOARD_ELEMENT_TYPES;
};

type AddingDataPanelState = {
  layout: Layout[];
  containerId: string | undefined;
  opType: OPERATION_TYPES;
};

type DashboardLayoutInteractionsInfo = {
  isEditing: boolean;
  viewMode: VIEW_MODE;
  disableInputs?: boolean;
  disableFiltersWhileLoading?: boolean;
  supportEmail?: string;
  disableEditingEditableSection?: boolean;
  updateUrlParams?: boolean;
};

export enum EditingLayout {
  STICKY_HEADER = 'STICKY_HEADER',
  EDITABLE_SECTION = 'EDITABLE_SECTION',
}

interface DashboardInteractionsReducer {
  interactionsInfo: DashboardLayoutInteractionsInfo;
  selectedItem: SelectedDashboardItem | null;
  isEditingEditableSection: boolean;

  // All below used exclusively for explore interactions
  editingLayout: EditingLayout | null;
  addDataPanelState: AddingDataPanelState | null;
  deleteSelectedItemModalOpen: boolean;
  draggingElementType: string | null;
  paneOpenStates: { left: boolean; right: boolean };
  datasetEditorMode: DATASET_EDITOR_MODE;
  datasetEditorHeight: number;
  // Used for showing hover states with link filters
  linkedElementHoverId: string | null;
}

const getDatasetEditorMode = (): DATASET_EDITOR_MODE => {
  try {
    const maybeMode = localStorage.getItem(EXPLO_DATASET_EDITOR_KEY);

    const currentMode = maybeMode
      ? Object.values(DATASET_EDITOR_MODE).find((mode) => mode === maybeMode)
      : undefined;
    return currentMode || DATASET_EDITOR_MODE.DEFAULT;
  } catch (e) {
    return DATASET_EDITOR_MODE.DEFAULT;
  }
};

const getInitialState = (): DashboardInteractionsReducer => ({
  interactionsInfo: { isEditing: false, viewMode: VIEW_MODE.DEFAULT },
  selectedItem: null,
  isEditingEditableSection: false,
  editingLayout: null,
  addDataPanelState: null,
  deleteSelectedItemModalOpen: false,
  draggingElementType: null,
  paneOpenStates: { left: true, right: false },
  datasetEditorMode: getDatasetEditorMode(),
  datasetEditorHeight: 300,
  linkedElementHoverId: null,
});

const dashboardInteractionsSlice = createSlice({
  name: 'dashboardInteractions',
  initialState: getInitialState(),
  reducers: {
    setInteractionsInfo: (state, { payload }: PayloadAction<DashboardLayoutInteractionsInfo>) => {
      if (state.interactionsInfo.isEditing !== payload.isEditing) resetEditState(state);
      state.interactionsInfo = payload;
    },
    setExploreInteractionsInfo: (
      state,
      { payload }: PayloadAction<Partial<DashboardLayoutInteractionsInfo>>,
    ) => {
      if (payload.isEditing !== undefined && state.interactionsInfo.isEditing !== payload.isEditing)
        resetEditState(state);

      if (payload.viewMode) state.interactionsInfo.viewMode = payload.viewMode;
      if (payload.isEditing !== undefined) state.interactionsInfo.isEditing = payload.isEditing;
      if (payload.disableFiltersWhileLoading !== undefined) {
        state.interactionsInfo.disableFiltersWhileLoading = payload.disableFiltersWhileLoading;
      }
      if (payload.supportEmail !== undefined)
        state.interactionsInfo.supportEmail = payload.supportEmail;
    },
    setSelectedDashboardItem: (
      state,
      { payload }: PayloadAction<{ id: string; type?: DASHBOARD_ELEMENT_TYPES }>,
    ) => {
      setSelectedItem(state, payload.id, payload.type, false);

      state.draggingElementType = null;
      state.deleteSelectedItemModalOpen = false;
    },
    clearSelectedDashboardItem: (state) => {
      state.selectedItem = null;
    },
    setEditingLayout: (state, { payload }: PayloadAction<EditingLayout | null>) => {
      state.editingLayout = payload;
      state.selectedItem = null;
    },
    setAddDataPanelState: (state, { payload }: PayloadAction<AddingDataPanelState | null>) => {
      state.addDataPanelState = payload;
    },
    setDraggingElementType: (state, { payload }: PayloadAction<string | null>) => {
      state.draggingElementType = payload;
    },
    setLinkedElementHover: (state, { payload }: PayloadAction<string | null>) => {
      state.linkedElementHoverId = payload;
    },
    setDeleteSelectedItemModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.deleteSelectedItemModalOpen = payload;
    },
    toggleVisiblePanes: (state, { payload }: PayloadAction<string[]>) => {
      state.paneOpenStates.right = payload.some((pane) => pane === 'right');
      state.paneOpenStates.left = payload.some((pane) => pane === 'left');
    },
    setDatasetEditorMode: (state, { payload }: PayloadAction<DATASET_EDITOR_MODE>) => {
      state.datasetEditorMode = payload;
      localStorage.setItem(EXPLO_DATASET_EDITOR_KEY, payload);
      if (payload === DATASET_EDITOR_MODE.EXPANDED) state.selectedItem = null;
    },
    setDatasetEditorHeight: (state, { payload }: PayloadAction<number>) => {
      state.datasetEditorHeight = payload;
    },
    setIsEditingEditableSection: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditingEditableSection = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearDashboardLayoutReducer, () => {
        return getInitialState();
      })
      .addCase(createDashboardElement, (state, { payload }) => {
        setSelectedItem(state, payload.id, payload.elementType);
      })
      .addCase(createDashboardDataPanel, (state, { payload }) => {
        setSelectedItem(state, payload.id);
      })
      .addCase(addEditableSectionChart, (state, { payload }) => {
        setSelectedItem(state, payload.id, undefined, false);
      })
      .addCase(duplicateEditableSectionChart, (state, { payload }) => {
        setSelectedItem(state, payload.newId, undefined, false);
      });
  },
});

export const {
  setInteractionsInfo,
  setSelectedDashboardItem,
  setEditingLayout,
  setAddDataPanelState,
  setDraggingElementType,
  setExploreInteractionsInfo,
  setLinkedElementHover,
  setDeleteSelectedItemModalOpen,
  toggleVisiblePanes,
  setDatasetEditorMode,
  setDatasetEditorHeight,
  setIsEditingEditableSection,
  clearSelectedDashboardItem,
} = dashboardInteractionsSlice.actions;

export const dashboardInteractionsReducer = dashboardInteractionsSlice.reducer;

const resetEditState = (state: DashboardInteractionsReducer) => {
  state.selectedItem = null;
  state.editingLayout = null;
  state.addDataPanelState = null;
  state.draggingElementType = null;
  state.linkedElementHoverId = null;
};

const setSelectedItem = (
  state: DashboardInteractionsReducer,
  itemId: string,
  type?: DASHBOARD_ELEMENT_TYPES,
  resetEditingLayout = true,
) => {
  state.selectedItem = { id: itemId, type: type ?? DASHBOARD_ELEMENT_TYPES.DATA_PANEL };
  state.draggingElementType = null;
  if (resetEditingLayout) state.editingLayout = null;
};

export const getIsFullPageDashboard = createSelector(
  (state: DashboardInteractionsReducer) => state.interactionsInfo,
  ({ isEditing, viewMode }) =>
    !isEditing ||
    viewMode === VIEW_MODE.PDF ||
    viewMode === VIEW_MODE.EMAIL ||
    viewMode === VIEW_MODE.MOBILE,
);

export const getDatasetEditorHeight = createSelector(
  (state: DashboardInteractionsReducer) => state.datasetEditorMode,
  (state: DashboardInteractionsReducer) => state.datasetEditorHeight,
  (mode, height) => (mode === DATASET_EDITOR_MODE.COLLAPSED ? MENU_HEIGHT : height),
);

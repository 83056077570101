import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';
import { Link } from 'react-router-dom';
import * as RD from 'remotedata';

import {
  APP_PORTAL_ID,
  Modal,
  sprinkles,
  IconButton,
  Icon,
  vars,
  Toggle,
  ToggleItem,
  Spinner,
} from 'components/ds';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import plaintext from 'react-syntax-highlighter/dist/esm/languages/hljs/plaintext';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import { CustomerSelector } from 'components/CustomerSelector';

import { showSuccessToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { fetchCustomer } from 'reducers/thunks/customerThunks';
import { ReduxState } from 'reducers/rootReducer';
import { generateEmbedJwt } from 'actions/customerActions';
import { SETTINGS_SUB_SECTION, SUB_SECTION_ROUTE_MAP } from 'constants/settingsPageConstants';
import { ROUTES } from 'constants/routes';

SyntaxHighlighter.registerLanguage('plaintext', plaintext);
SyntaxHighlighter.registerLanguage('javascript', javascript);

type Props = {
  onClose: () => void;
  embedId: string;
  embedName: string;
  pageType: ResourcePageType;
  modalOpen: boolean;
};

enum MethodType {
  WEB_COMPONENT = 'Web Component (recommended)',
  IFRAME = 'iFrame',
}

const EXPLO_LIBRARY_STEP_STATUS_KEY = 'explo_library_step_status';

export const ResourceEmbedModal: FC<Props> = ({
  onClose,
  embedId,
  embedName,
  pageType,
  modalOpen,
}) => {
  const dispatch = useDispatch();

  const { selectedCustomerId, selectedJwt } = useSelector(
    (state: ReduxState) => ({
      selectedCustomerId: state.customers.selectedGroupId,
      selectedJwt: state.customers.selectedCustomerJwt,
    }),
    shallowEqual,
  );

  const [method, setMethod] = useState(MethodType.WEB_COMPONENT);
  const [inputCustomerId, setInputCustomerId] = useState<number | null>(null);
  const [isExploLibraryStepComplete, setIsExploLibraryStepComplete] = useLocalStorage(
    EXPLO_LIBRARY_STEP_STATUS_KEY,
    false,
  );
  const [isCodeSnippetStepComplete, setIsCodeSnippetStepComplete] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomer({ customer_id: inputCustomerId, get_default: true }));
  }, [inputCustomerId, dispatch]);

  useEffect(() => {
    if (selectedCustomerId)
      dispatch(
        generateEmbedJwt({
          postData: { customer_id: selectedCustomerId, dashboard_embed_id: embedId },
        }),
      );
  }, [selectedCustomerId, embedId, dispatch]);

  if (!modalOpen) return null;

  const embed_asset_url = process.env.REACT_APP_EMBED_ASSET_URL
    ? `${process.env.REACT_APP_EMBED_ASSET_URL}bundle.js`
    : 'https://embed.explo.co/bundle.js';
  const importInstructionsCode = `<script src="${embed_asset_url}"></script>`;

  const copyToClipboard = (apiToken: string) => {
    navigator.clipboard.writeText(apiToken);
    showSuccessToast('Copied To Clipboard');
  };

  const renderEmbedCode = (jwt: string) => {
    return pageType === ResourcePageType.EXPLORE
      ? `<explo-dashboard\n  dash-jwt="${jwt}"\n  updateUrlParams={true}\n  isProduction={true}\n  environment="ENVIRONMENT"\n  refresh-minutes={10}\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n/>\n`
      : `<explo-report-builder\n  jwt="${jwt}"\n  environment="ENVIRONMENT"\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n/>\n`;
  };

  const renderIframeCode = (jwt: string) => {
    return pageType === ResourcePageType.REPORT_BUILDER
      ? `<iframe\n  src="https://app.explo.co/report-builder/iframe/${jwt}"\n style="width: 100%;border: none;height: 100vh;"\n</iframe> `
      : `<iframe\n  src="https://app.explo.co/iframe/${jwt}"\n  style="width: 100%;border: none;height: 100vh;"\n</iframe> `;
  };

  const renderCodeSection = () => {
    if (RD.isError(selectedJwt))
      return (
        <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp1' })}>
          <Icon
            className={sprinkles({ color: 'contentSecondary' })}
            name="circle-exclamation-reg"
            size="lg"
          />
          <div>
            Could not generate embed secret (JWT) for this customer. Make sure that an embed secret
            has been created for this customer&rsquo;s Data Visibility Group{' '}
            <Link style={{ textDecoration: 'none' }} to={ROUTES.SETTINGS_ACCESS_GROUPS}>
              here
            </Link>
            .
          </div>
        </div>
      );
    if (!RD.isSuccess(selectedJwt)) return <Spinner size="sm" />;
    const jwt = selectedJwt.data ?? '<JWT>';

    return renderCodeBlock(
      method === MethodType.IFRAME ? renderIframeCode(jwt) : renderEmbedCode(jwt),
      () => setIsCodeSnippetStepComplete(true),
    );
  };

  const renderCodeBlock = (code: string, setStatus?: () => void) => (
    <div
      className={codeBlockClass}
      onClick={() => {
        copyToClipboard(code);
        setStatus?.();
      }}>
      <SyntaxHighlighter
        customStyle={{
          padding: '8px',
          margin: '0px',
          backgroundColor: vars.colors.elevationMid,
          color: 'black',
        }}
        language="javascript"
        style={style}
        wrapLines={false}>
        {code}
      </SyntaxHighlighter>
      <Icon name="clipboard-reg" />
    </div>
  );

  const renderInlineCode = (code: string) => (
    <SyntaxHighlighter
      customStyle={{
        display: 'inline-flex',
        borderRadius: '3px',
        padding: '2px 4px',
        margin: '0px',
        backgroundColor: vars.colors.elevationMid,
        color: 'black',
      }}
      language="plaintext"
      style={style}>
      {code}
    </SyntaxHighlighter>
  );

  const renderDocsSection = (text: string, url: string) => {
    return (
      <a
        className={docsContainerClass}
        href={url}
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
        target="_blank">
        <div className={sprinkles({ color: 'black' })}>{text}</div>
        <div className={sprinkles({ flexItems: 'alignCenter', color: 'active', gap: 'sp1' })}>
          Docs
          <Icon name="book-blank" />
        </div>
      </a>
    );
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{ text: 'Done', onClick: onClose }}
      size="medium"
      title={`Embed ${embedName}`}>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
        <div className={textContentClass}>
          Before embedding for the first time you must{' '}
          <Link
            className={sprinkles({ body: 'b2', color: 'contentSecondary' })}
            style={{ textDecoration: 'none' }}
            to={`/settings/${SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING]}`}>
            <b>allowlist your domains in Explo</b> <Icon name="arrow-up-right" />
          </Link>{' '}
          and{' '}
          <a
            className={sprinkles({ body: 'b2', color: 'contentSecondary' })}
            href="https://docs.explo.co/embedding-documentation/add-explo-domains-to-csp"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            target="_blank">
            <b>add Explo&rsquo;s domains to your CSP</b>
            <Icon name="arrow-up-right" />.
          </a>
        </div>

        <ModalSection isComplete={isExploLibraryStepComplete} title="Import the Explo JS Library">
          <div className={sprinkles({ flexItems: 'column', paddingY: 'sp3', gap: 'sp1' })}>
            <div>
              In the {renderInlineCode('<head>')} of your {renderInlineCode('index.html')} include
              the following script imports
            </div>
            {renderCodeBlock(importInstructionsCode, () => setIsExploLibraryStepComplete(true))}
          </div>
        </ModalSection>
        <ModalSection
          isComplete={isCodeSnippetStepComplete}
          title="Generate and embed your component">
          <div className={sprinkles({ flexItems: 'column', paddingY: 'sp3', gap: 'sp3' })}>
            <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
              <div className={sprinkles({ heading: 'h4', color: 'contentPrimary' })}>
                Choose a method
              </div>
              <div className={sprinkles({ body: 'b2', color: 'contentSecondary' })}>
                We strongly recommend using a react component whenever possible, it is more reliable
                and fully featured.{' '}
              </div>
              <Toggle
                onValueChange={(newValue) => setMethod(newValue as MethodType)}
                selectedValue={method}>
                <ToggleItem value={MethodType.WEB_COMPONENT} />
                <ToggleItem value={MethodType.IFRAME} />
              </Toggle>
            </div>
            <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
              <div className={sprinkles({ heading: 'h4', color: 'contentPrimary' })}>
                Select a customer entity to test with
              </div>
              <CustomerSelector
                fillWidth
                onSelect={(id) => {
                  const customerId = parseInt(id);
                  if (isNaN(customerId)) return;
                  setInputCustomerId(customerId);
                }}
                selectedCustomerId={selectedCustomerId ?? undefined}
              />
            </div>
            <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
              <div className={sprinkles({ heading: 'h4', color: 'contentPrimary' })}>
                Preview the snippet
              </div>
              {renderCodeSection()}
              {renderDocsSection(
                'Using our web component',
                'https://docs.explo.co/embedding-documentation/option-1-web-component',
              )}
              {renderDocsSection(
                'Using iFrame',
                'https://docs.explo.co/embedding-documentation/option-2-iframe',
              )}
            </div>
          </div>
        </ModalSection>
        <ModalSection title="Dashboard Embed ID">
          <div className={sprinkles({ flexItems: 'column', paddingY: 'sp3', gap: 'sp1' })}>
            <div>
              If you use the workflow above, you do not need the dashboard embed ID. If you need it
              for API calls, you can still access it below.
            </div>
            {renderCodeBlock(embedId)}
          </div>
        </ModalSection>
      </div>
    </Modal>
  );
};

type SectionProps = { title: string; isComplete?: boolean; children: JSX.Element };

const ModalSection: FC<SectionProps> = ({ title, isComplete, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div className={headerClass} style={{ height: 52 }}>
        {title}
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          {isComplete !== undefined ? (
            <Icon
              className={sprinkles({ color: isComplete ? 'success' : 'gray8' })}
              name={isComplete ? 'circle-check' : 'circleRegular'}
            />
          ) : null}
          <IconButton
            name={isOpen ? 'chevron-down' : 'chevron-right'}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </div>
      </div>
      {isOpen ? <div className={textContentClass}>{children}</div> : null}
    </div>
  );
};

const codeBlockClass = sprinkles({
  cursor: 'pointer',
  backgroundColor: 'elevationMid',
  borderRadius: 4,
  flexItems: 'alignCenterBetween',
  color: 'black',
  paddingX: 'sp1',
});

const headerClass = sprinkles({
  borderTop: 1,
  borderColor: 'outline',
  width: 'fill',
  backgroundColor: 'elevationMid',
  heading: 'h3',
  paddingX: 'sp3',
  flexItems: 'alignCenterBetween',
});

const textContentClass = sprinkles({
  body: 'b2',
  paddingX: 'sp3',
  color: 'contentSecondary',
});

const docsContainerClass = sprinkles({
  height: 32,
  backgroundColor: 'brandPrimaryLight',
  borderRadius: 8,
  flexItems: 'alignCenterBetween',
  padding: 'sp1.5',
  fontSize: 14,
  fontWeight: 500,
});

import { FC } from 'react';

import { RangeInput } from 'components/ChartConfigs/RangeInput';
import { Select, Switch, Input, BooleanToggle } from 'components/ds';
import { DateFormatDescriptiveText } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/DateFormatDescriptiveText';
import { NumberFormatToggle } from 'components/NumberFormatToggle';

import {
  BAR_CHART_TYPES,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import {
  DATE_TYPES,
  INTEGER_DATA_TYPE,
  STRING,
  STRING_FORMATS,
  V2_NUMBER_FORMATS,
} from 'constants/dataConstants';

type Instructions =
  | V2TwoDimensionChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions;

type Props = {
  instructions: Instructions;
  operationType: OPERATION_TYPES;
  configInputClass: string;
  enableScrolling?: boolean;
  isHorizontal?: boolean;

  updateXAxisFormat: (xAxisFormat: XAxisFormat) => void;
};

const StringFormatOptions = Object.values(STRING_FORMATS).map((formatOption) => ({
  value: formatOption,
}));

export const SharedXAxisConfigs: FC<Props> = ({
  instructions,
  isHorizontal,
  enableScrolling,
  configInputClass,
  operationType,
  updateXAxisFormat,
}) => {
  const { xAxisFormat } = instructions;

  const selectedStringFormat = xAxisFormat?.stringFormat?.format || STRING_FORMATS.DEFAULT;
  const selectedNumberFormat = xAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  const isSpiderChart = operationType === OPERATION_TYPES.VISUALIZE_SPIDER_CHART;
  const isBarFunnel = operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  const areTotalValueConfigurable =
    isSpiderChart ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2;
  const areBarValuesConfigurable =
    BAR_CHART_TYPES.has(operationType) ||
    operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  let allowMaxCategories = false;
  let xAxisColType: string | undefined;
  if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    xAxisColType = (instructions as V2BoxPlotInstructions).groupingColumn?.column.type;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    xAxisColType = (instructions as V2ScatterPlotInstructions).xAxisColumn?.type;
  } else {
    const v2Instructions = instructions as V2TwoDimensionChartInstructions;
    xAxisColType = v2Instructions.categoryColumn?.column.type;
    if (BAR_CHART_TYPES.has(operationType) || isSpiderChart) {
      allowMaxCategories =
        (!DATE_TYPES.has(xAxisColType ?? '') || isSpiderChart) &&
        !v2Instructions.colorColumnOptions?.length;
    }
  }

  const isDateColType = DATE_TYPES.has(xAxisColType ?? '');
  const isIntColType = xAxisColType === INTEGER_DATA_TYPE;

  return (
    <>
      {isBarFunnel ? null : (
        <>
          <Switch
            className={configInputClass}
            label="Axis Title"
            onChange={() => updateXAxisFormat({ showTitle: !xAxisFormat?.showTitle })}
            switchOn={xAxisFormat?.showTitle}
          />
          {xAxisFormat?.showTitle ? (
            <Input
              showInputButton
              className={configInputClass}
              defaultValue={xAxisFormat?.title}
              onSubmit={(newValue) => updateXAxisFormat({ title: newValue })}
            />
          ) : null}
        </>
      )}
      {isIntColType && !isBarFunnel ? (
        <>
          <NumberFormatToggle
            disableAbbr
            disableTime
            className={configInputClass}
            label="Value Format"
            selectedFormat={selectedNumberFormat.id}
            updateFormat={(numberFormat) =>
              updateXAxisFormat({ numberFormat: { id: numberFormat } })
            }
          />
          <Switch
            className={configInputClass}
            label="Show Axis Decimals"
            onChange={() => updateXAxisFormat({ showDecimals: !xAxisFormat?.showDecimals })}
            switchOn={xAxisFormat?.showDecimals}
          />
          {xAxisFormat?.showDecimals ? (
            <Input
              showInputButton
              className={configInputClass}
              defaultValue={String(xAxisFormat?.decimalPlaces ?? 2)}
              label={{
                text: 'Decimal Places',
                infoText:
                  "This will only apply to the data tooltip unless 'Show Axis Decimals' is enabled.",
              }}
              onSubmit={(newValue) => {
                const intValue = parseInt(newValue);
                updateXAxisFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
              }}
            />
          ) : null}
          <Switch
            className={configInputClass}
            label="Use Logarithmic Scale"
            onChange={() => {
              let min = xAxisFormat?.min;
              // log scales do not allow minimumValue of <= 0
              if (min !== undefined && min <= 0) min = undefined;

              updateXAxisFormat({ useLogScale: !xAxisFormat?.useLogScale, min });
            }}
            switchOn={xAxisFormat?.useLogScale}
          />
          <RangeInput
            className={configInputClass}
            endVal={String(xAxisFormat?.max ?? '')}
            onNewRange={(newStart, newEnd) => {
              const min = parseFloat(newStart ?? '');
              const max = parseFloat(newEnd ?? '');

              const validIfLog = !(xAxisFormat?.useLogScale && min <= 0);

              updateXAxisFormat({
                min: min >= 0 && validIfLog ? min : undefined,
                max: max >= 0 ? max : undefined,
              });
            }}
            startVal={String(xAxisFormat?.min ?? '')}
          />
        </>
      ) : null}
      {!isSpiderChart && !isBarFunnel ? (
        <BooleanToggle
          className={configInputClass}
          falseText={isHorizontal ? 'Left' : 'Bottom'}
          label="Axis Placement"
          onValueChange={(flipAxis) => updateXAxisFormat({ flipAxis })}
          selectedValue={!!xAxisFormat?.flipAxis}
          trueText={isHorizontal ? 'Right' : 'Top'}
        />
      ) : null}
      {isDateColType ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={xAxisFormat?.dateFormat}
            label="Date Format"
            onSubmit={(newValue) => updateXAxisFormat({ dateFormat: newValue })}
          />
          <DateFormatDescriptiveText />
        </>
      ) : null}
      {xAxisColType === STRING ? (
        <>
          <Select
            className={configInputClass}
            label="String Format"
            onChange={(value) =>
              updateXAxisFormat({
                stringFormat: { ...xAxisFormat?.stringFormat, format: value as STRING_FORMATS },
              })
            }
            selectedValue={selectedStringFormat}
            values={StringFormatOptions}
          />
          <Switch
            className={configInputClass}
            label="Remove Underscores"
            onChange={() => {
              updateXAxisFormat({
                stringFormat: {
                  ...xAxisFormat?.stringFormat,
                  replaceUnderscores: !xAxisFormat?.stringFormat?.replaceUnderscores,
                },
              });
            }}
            switchOn={xAxisFormat?.stringFormat?.replaceUnderscores}
          />
        </>
      ) : null}
      {isBarFunnel ? null : (
        <Switch
          className={configInputClass}
          label="Axis Line"
          onChange={() => updateXAxisFormat({ hideAxisLine: !xAxisFormat?.hideAxisLine })}
          switchOn={!xAxisFormat?.hideAxisLine}
        />
      )}
      {!xAxisFormat?.hideAxisLine && !isBarFunnel ? (
        <>
          {!isSpiderChart ? (
            <Switch
              className={configInputClass}
              label="Axis Ticks"
              onChange={() => updateXAxisFormat({ hideAxisTicks: !xAxisFormat?.hideAxisTicks })}
              switchOn={!xAxisFormat?.hideAxisTicks}
            />
          ) : null}
          <Switch
            className={configInputClass}
            label="Axis Labels"
            onChange={() => updateXAxisFormat({ hideAxisLabels: !xAxisFormat?.hideAxisLabels })}
            switchOn={!xAxisFormat?.hideAxisLabels}
          />
        </>
      ) : null}
      {!isBarFunnel ? (
        <Switch
          className={configInputClass}
          label="Reverse Axis"
          onChange={() => updateXAxisFormat({ reverseAxis: !xAxisFormat?.reverseAxis })}
          switchOn={xAxisFormat?.reverseAxis}
        />
      ) : null}
      {!isBarFunnel && areTotalValueConfigurable ? (
        <>
          <Switch
            className={configInputClass}
            label="Show Total Values"
            onChange={() => updateXAxisFormat({ hideTotalValues: !xAxisFormat?.hideTotalValues })}
            switchOn={!xAxisFormat?.hideTotalValues}
          />
          {xAxisFormat?.hideTotalValues ? null : (
            <Input
              className={configInputClass}
              defaultValue={String(xAxisFormat?.labelPadding ?? 10)}
              label={{
                text: 'Label Padding',
                infoText:
                  'Adds padding (as a percent) to total values to prevent them from being cut off',
              }}
              onSubmit={(newValue) => {
                const labelPadding = parseInt(newValue);
                if (isNaN(labelPadding)) return;
                updateXAxisFormat({ labelPadding });
              }}
              placeholder="10"
            />
          )}
        </>
      ) : null}
      {!isBarFunnel && !isIntColType && allowMaxCategories ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(xAxisFormat?.maxCategories ?? '')}
          label="Max Categories"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateXAxisFormat({ maxCategories: intValue >= 0 ? intValue : undefined });
          }}
        />
      ) : null}
      {areBarValuesConfigurable ? (
        <>
          {isBarFunnel ? null : (
            <Switch
              className={configInputClass}
              label="Show Bar Values"
              onChange={() => updateXAxisFormat({ showBarValues: !xAxisFormat?.showBarValues })}
              switchOn={xAxisFormat?.showBarValues}
            />
          )}
          <Input
            className={configInputClass}
            defaultValue={String(xAxisFormat?.barCornerRadius || 0)}
            label="Bar Corner Radius"
            onSubmit={(newValue) => {
              const intValue = parseInt(newValue);
              updateXAxisFormat({ barCornerRadius: intValue >= 0 ? intValue : undefined });
            }}
          />
        </>
      ) : null}
      {!isBarFunnel && enableScrolling ? (
        <Switch
          className={configInputClass}
          label="Enable Scroll Bar"
          onChange={() => updateXAxisFormat({ enableScroll: !xAxisFormat?.enableScroll })}
          switchOn={xAxisFormat?.enableScroll}
        />
      ) : null}
    </>
  );
};

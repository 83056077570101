import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { produce } from 'immer';
import cx from 'classnames';

import { CollapsibleListItemConfig } from './CollapsibleListItemConfig';
import { sprinkles, Input } from 'components/ds';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import {
  OPERATION_TYPES,
  VisualizeTableInstructions,
  VisualizeCollapsibleListInstructions,
} from 'constants/types';

export const MIN_ROW_HEIGHT = 30;

type Props = {
  instructions: VisualizeCollapsibleListInstructions;
  schema: DatasetSchema;
  originalGroupByCols: DatasetSchema;
};

export const CollapsibleListRowsConfig: FC<Props> = ({
  originalGroupByCols,
  instructions,
  schema,
}) => {
  const dispatch = useDispatch();

  const updateInstructions = (instructions: VisualizeCollapsibleListInstructions) =>
    dispatch(updateVisualizeOperation(instructions, OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST));

  return (
    <>
      <Input
        className={cx(configInputClass, sprinkles({ marginTop: 'sp1.5' }))}
        defaultValue={`${instructions.rowHeight ?? ''}`}
        label={{
          text: 'Row Height',
          infoText: 'The minimum row height is 30. Margin is applied if no row height is set.',
        }}
        onSubmit={(value) => {
          const newHeight = parseInt(value);

          if (newHeight < MIN_ROW_HEIGHT || isNaN(newHeight)) return;

          const newInstructions = produce(instructions, (draft) => {
            draft.rowHeight = newHeight;
          });
          updateInstructions(newInstructions);
        }}
        placeholder="Enter a row height"
      />
      {originalGroupByCols.map((row) => {
        return (
          <CollapsibleListItemConfig
            isRowColumn
            column={row}
            instructions={instructions as VisualizeTableInstructions}
            key={row.name}
            schema={schema}
            updateInstructions={updateInstructions}
          />
        );
      })}
    </>
  );
};

import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import cx from 'classnames';

import { ElementShowHidePanel } from './ElementShowHidePanel';
import { PageHeaderConfig } from '../elementConfig/PageHeaderConfig/PageHeaderConfig';
import { DataPanelConfig } from '../DataPanelConfig';
import { ElementConfigPanel } from '../elementConfig/ElementConfigPanel';
import { EditDashboardPanel } from './EditDashboardPanel';
import { EditableSectionConfig } from './EditableSectionConfig';
import * as styles from './styles.css';

import { ReduxState } from 'reducers/rootReducer';
import { EditingLayout } from 'reducers/dashboardInteractionsReducer';
import { getEditableDatasets, getSelectedDataPanel, getSelectedElement } from 'reducers/selectors';
import { isDashboardDatasetSaved } from 'utils/onboarding';

type Props = {
  dashboardId: number;
  isFullPageDashboard: boolean;
  shareLinkTitle: string | null;
};

export const EditDashboardLeftPanel: FC<Props> = ({
  dashboardId,
  isFullPageDashboard,
  shareLinkTitle,
}) => {
  const { isEditing, viewMode, editingLayout, element, dataPanel, leftPaneOpen, hasSavedDataset } =
    useSelector(
      (state: ReduxState) => ({
        isEditing: state.dashboardInteractions.interactionsInfo.isEditing,
        viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
        editingLayout: state.dashboardInteractions.editingLayout,
        element: getSelectedElement(state),
        dataPanel: getSelectedDataPanel(state),
        leftPaneOpen: state.dashboardInteractions.paneOpenStates.left,
        hasSavedDataset: isDashboardDatasetSaved(Object.values(getEditableDatasets(state))),
      }),
      shallowEqual,
    );

  if (!isEditing || (!isFullPageDashboard && !leftPaneOpen)) return null;

  const isEditableSection =
    !isFullPageDashboard && editingLayout === EditingLayout.EDITABLE_SECTION;

  const renderPanel = () => {
    if (isFullPageDashboard) return <ElementShowHidePanel viewMode={viewMode} />;

    if (editingLayout === EditingLayout.STICKY_HEADER) {
      return <PageHeaderConfig dashboardId={dashboardId} />;
    }

    if (isEditableSection) return <EditableSectionConfig dashboardId={dashboardId} />;
    if (dataPanel)
      return <DataPanelConfig dashboardId={dashboardId} dataPanel={dataPanel} key={dataPanel.id} />;
    if (!element) return <EditDashboardPanel />;

    return (
      <ElementConfigPanel
        dashboardId={dashboardId}
        element={element}
        shareLinkTitle={shareLinkTitle}
      />
    );
  };

  return (
    <div
      className={cx(
        isEditableSection
          ? styles.editableSectionPanelWrapper
          : styles.editDashboardLeftPanelWrapper,
      )}
      style={{ overflow: !hasSavedDataset ? 'hidden' : undefined }}>
      {renderPanel()}
      {hasSavedDataset ? null : (
        <div className={styles.savedDatasetFirstPane}>Run your first query to get started.</div>
      )}
    </div>
  );
};

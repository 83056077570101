import { FC } from 'react';
import cx from 'classnames';

import * as styles from 'components/ds/Tabs/index.css';

import { sprinkles } from 'components/ds/sprinkles.css';

type Props = { isSelected: boolean; onClick?: () => void };

export const Tab: FC<Props> = ({ onClick, isSelected, children }) => (
  <button
    className={cx(styles.navTab, {
      [sprinkles({
        color: { default: 'contentTertiary', hover: 'active', focus: 'active' },
        borderColor: 'transparent',
      })]: !isSelected,
      [cx(styles.navTabActive, sprinkles({ color: 'active', borderColor: 'active' }))]: isSelected,
    })}
    onClick={onClick}>
    {children}
  </button>
);

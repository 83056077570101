import { createSlice } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  EnvironmentTag,
  createEnvironmentTagSuccess,
  updateEnvironmentTagSuccess,
  fetchEnvironmentTagsActions,
  deleteEnvironmentTagSuccess,
} from 'actions/environmentTagActions';

interface EnvironmentTagReducerState {
  tags: RD.ResponseData<EnvironmentTag[]>;
}

const initialState: EnvironmentTagReducerState = {
  tags: RD.Idle(),
};

const environmentTagSlice = createSlice({
  name: 'environmentTags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnvironmentTagsActions.requestAction, (state) => {
        state.tags = RD.Loading();
      })
      .addCase(fetchEnvironmentTagsActions.errorAction, (state, { payload }) => {
        state.tags = RD.Error(payload.error_msg || 'Error loading environment tags');
      })
      .addCase(fetchEnvironmentTagsActions.successAction, (state, { payload }) => {
        state.tags = RD.Success(payload.tags);
      })
      .addCase(updateEnvironmentTagSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.tags)) return;
        const tagIndex = state.tags.data.findIndex(({ id }) => id === payload.tag.id);
        if (tagIndex !== -1) state.tags.data[tagIndex] = payload.tag;
      })
      .addCase(createEnvironmentTagSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.tags)) return;
        state.tags.data.push(payload.tag);
      })
      .addCase(deleteEnvironmentTagSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.tags)) return;
        state.tags.data = state.tags.data.filter(({ id }) => id !== payload.id);
      });
  },
});

export const environmentTagsReducer = environmentTagSlice.reducer;

import { DataSourceConfiguration, SupportedDataSource } from 'actions/dataSourceActions';
import {
  Postgres,
  MySql,
  MSS,
  TenantPrivateKeyAuthentication,
  VendorPrivateKeyAuthentication,
  JdbcAuthentication,
  Redshift,
} from '@explo-tech/fido-api';

export type DBConnectionConfig = {
  name?: string;
  providedId?: string;
  providedIdError?: string;
  selectedDataSource?: SupportedDataSource;
  selectedDataSourceIsLocked?: boolean;
  dataSourceConfig?: DataSourceConfiguration;
};

export type FidoDataSourceConfig = Postgres | MySql | MSS | Redshift;

export type FidoSSHTunnelAuthentication =
  | TenantPrivateKeyAuthentication
  | VendorPrivateKeyAuthentication;

export type FidoAuthentication = JdbcAuthentication;

export type MiscSSHConfig = {
  useSSH?: boolean;
  localBindPort?: number;
  sshAuthMethod: string;
};

export type AwsAuthOptions = {
  username: string;
  region: string;
  accessKey: string;
  secretKey: string;
  sslCert: string;
};

/**
 * The following is for the purpose of extracting data from the EmbeddoDataSourceConfiguration after data has been shimmed from the FidoDataSourceConfiguration.
 * The EmbeddoDataSourceConfiguration has no typing guarentees, but we want to ensure safe extraction when updating a data source.
 */

export enum DATA_SOURCE_CONFIG_KEYS {
  DATABASE = 'database',
  PORT = 'port',
  HOST = 'host',
  USER = 'user',
  TUNNEL_TYPE = 'tunnelType',
}

export enum SSH_CONFIG_KEYS {
  SSH_AUTH_TYPE = 'sshAuthType',
  HOST = 'tunnelHost',
  PORT = 'tunnelPort',
  USERNAME = 'sshUsername',
}

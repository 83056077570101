import { PureComponent } from 'react';
import Highcharts from 'highcharts';
import ReactDOMServer from 'react-dom/server';
import { cloneDeep, groupBy, keyBy } from 'utils/standard';

import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DrilldownChart } from './shared/drilldownChart';
import { ChartTooltip } from 'components/embed';

import {
  getColorColNames,
  xAxisFormat,
  yAxisFormat,
  formatLegend,
  formatValue,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  shouldProcessColAsDate,
  getAxisNumericalValue,
} from './utils';
import {
  AggedChartColumnInfo,
  GradientPointOptions,
  GradientType,
  HeatMapFormat,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { Point } from 'highcharts';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { isSelectedColorDateType, getColorColumn } from 'utils/colorColUtils';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { DatasetDataObject } from 'actions/datasetActions';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import { getHeatmapStopPoint, needsToCalculateStopsForHeatmap } from 'utils/gradientUtils';

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  schema: DatasetSchema;
  selectedColorColName?: string;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  setChartMenu: (info: ChartMenuInfo | null) => void;
};

const opType = OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2;

type SeriesOptions = Highcharts.SeriesHeatmapOptions;

class HeatMap extends PureComponent<Props> {
  render() {
    const { generalOptions, instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions, opType);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }
    return (
      <DrilldownChart
        chartOptions={this._spec()}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelId={this.props.dataPanelTemplateId}
        instructions={instructions}
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      generalOptions,
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      setChartMenu,
      dataPanelTemplateId,
    } = this.props;
    if (!instructions?.aggColumns || schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();

    const { xCategories, yCategories, originalXCategories, originalYCategories } =
      this.getAxisCategories();
    const { aggColName } = getColorColNames(schema, opType);

    const { valueFormatId, decimalPlaces } = this.getValueFormat();

    const heatMapFormat = instructions.chartSpecificFormat?.heatMap;

    const data = this.transformData();

    const hasClickEvents =
      this.getUnderlyingDrilldownEnabled() || !!generalOptions?.customMenu?.enabled;

    return {
      chart: {
        type: 'heatmap',
        plotBorderWidth: 1,
        backgroundColor,
      },
      series: data,
      title: sharedTitleConfig,
      plotOptions: {
        heatmap: {
          dataLabels: {
            allowOverlap: true,
            enabled: heatMapFormat?.showCellLabels,
            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
            formatter: function () {
              const value = this.point.value ?? 0;
              if (heatMapFormat?.hideZeros && value === 0) return;
              return formatValue({
                value,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },
          },
        },
        series: {
          animation: false,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;
                const options = e.point.options;
                if (options.x === undefined || options.y === undefined || options.y === null)
                  return;

                setChartMenu({
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: originalXCategories[options.x],
                  subCategory: originalYCategories[options.y],
                });
              },
            },
          },
        },
      },
      yAxis: {
        endOnTick: false,
        categories: yCategories,
        ...yAxisFormat(globalStyleConfig, instructions?.yAxisFormats?.[0]),
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        categories: xCategories,
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      colorAxis: {
        stops: this.getStops(aggColName),
        startOnTick: false,
        endOnTick: false,
        marker: { color: globalStyleConfig.base.actionColor.default },
        labels: {
          formatter: function () {
            const value = (this.value as number) ?? 0;
            return formatValue({
              value,
              decimalPlaces,
              formatId: valueFormatId,
              hasCommas: true,
            });
          },
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          const xName = getPointCategoryName(this.point, 'x');
          const yName = getPointCategoryName(this.point, 'y');

          return ReactDOMServer.renderToStaticMarkup(
            <ChartTooltip
              globalStyleConfig={globalStyleConfig}
              header={`${xName}, ${yName}`}
              points={[
                {
                  color: String(this.point.color),
                  name: this.series.name,
                  value: this.point.value || 0,
                  format: { decimalPlaces, formatId: valueFormatId },
                },
              ]}
            />,
          );
        },
      },
    };
  };

  getStops = (aggColName: string): [number, string][] => {
    const { instructions, globalStyleConfig } = this.props;

    const heatMapFormat = instructions?.chartSpecificFormat?.heatMap;
    const gradientColors = heatMapFormat?.gradient;
    const { divergingPalette, gradientPalette } = globalStyleConfig.visualizations;

    const { colorAxisMin, colorAxisMid, colorAxisMax } = this.getColorAxisFloats(
      aggColName,
      heatMapFormat ?? {},
    );

    if (heatMapFormat?.gradientType === GradientType.DIVERGING) {
      return [
        [colorAxisMin, gradientColors?.hue1 || divergingPalette.hue1],
        [colorAxisMid, gradientColors?.hue2 || divergingPalette.hue2],
        [colorAxisMax, gradientColors?.hue3 || divergingPalette.hue3],
      ];
    }
    return [
      [colorAxisMin, gradientColors?.hue2 || gradientPalette.hue1],
      [colorAxisMax, gradientColors?.hue3 || gradientPalette.hue2],
    ];
  };

  getUnderlyingDrilldownEnabled = () => {
    return !!this.props.generalOptions?.enableRawDataDrilldown;
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      valueFormatId:
        instructions?.yAxisFormats?.[0]?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema || schema.length === 0)
      return;

    const xAxisColName = schema[0].name;
    const colorColName = getColorColNames(schema, opType).colorColName;

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;

      // If it's a number, it has already been converted to milliseconds
      if (categoryColIsDate && typeof row[xAxisColName] !== 'number')
        row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);

      if (colorColIsDate && typeof row[colorColName] !== 'number')
        row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
    });
  };

  getAxisCategories = () => {
    const { instructions, previewData, schema, selectedColorColName } = this.props;
    const { xAxisColName, colorColName } = getColorColNames(schema, opType);
    const colorColumn = getColorColumn(instructions, selectedColorColName);

    const originalXCategories = new Set<string>();
    const xCategories: string[] = [];
    const originalYCategories = new Set<string>();
    const yCategories: string[] = [];

    previewData.forEach((row) => {
      const originalCategory = String(row[xAxisColName]);
      if (originalXCategories.has(originalCategory)) return;
      originalXCategories.add(originalCategory);

      const formatted = formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      );
      xCategories.push(formatted);
    });

    const sortedArray = cloneDeep(previewData);
    if (sortedArray[0] && typeof sortedArray[0][colorColName] === 'number') {
      sortedArray.sort((a, b) => Number(a[colorColName]) - Number(b[colorColName]));
    }
    sortedArray.forEach((row) => {
      const originalCategory = String(row[colorColName]);
      if (originalYCategories.has(originalCategory)) return;
      originalYCategories.add(originalCategory);

      const formatted = formatLabel(
        row[colorColName],
        colorColumn?.column.type,
        colorColumn?.bucket?.id,
      );
      yCategories.push(formatted);
    });

    return {
      xCategories,
      yCategories,
      originalXCategories: Array.from(originalXCategories),
      originalYCategories: Array.from(originalYCategories),
    };
  };

  transformData = (): SeriesOptions[] => {
    const { instructions, schema } = this.props;

    if (!instructions?.aggColumns?.length || !schema?.length) return [];

    return this.transformColorData(schema, instructions.aggColumns[0]);
  };

  transformColorData = (schema: DatasetSchema, aggCol: AggedChartColumnInfo): SeriesOptions[] => {
    const { previewData, instructions, selectedColorColName, variables } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema, opType);
    const { xCategories, yCategories } = this.getAxisCategories();
    const colorColumn = getColorColumn(instructions, selectedColorColName);

    const dataByXAxis = groupBy(previewData, (row) =>
      formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      ),
    );

    if (!xCategories || !yCategories) return [];
    const data: [number, number, number][] = [];

    xCategories.forEach((xCategory, x) => {
      const dataByYAxis = keyBy(dataByXAxis[xCategory], (row) =>
        formatLabel(row[colorColName], colorColumn?.column.type, colorColumn?.bucket?.id),
      );
      yCategories.forEach((yCategory, y) => {
        if (dataByYAxis[yCategory]) {
          const row = dataByYAxis[yCategory];
          const value = getAxisNumericalValue(row[aggColName]);

          if (isNaN(value)) return;
          data.push([x, y, value]);
        } else {
          data.push([x, y, 0]);
        }
      });
    });

    const name = aggCol.column.friendly_name
      ? replaceVariablesInString(aggCol.column.friendly_name, variables)
      : getColDisplayText(aggCol) || aggColName;

    return [
      { type: 'heatmap', data, name, nullColor: '#FFFFFF', borderWidth: 1, borderColor: '#cccccc' },
    ];
  };

  getColorAxisFloats = (aggColName: string, { gradientOptions }: HeatMapFormat) => {
    const { previewData, variables, datasetData, datasetNamesToId } = this.props;

    if (
      !previewData.length ||
      !gradientOptions ||
      !needsToCalculateStopsForHeatmap(gradientOptions)
    ) {
      return { colorAxisMin: 0, colorAxisMid: 0.5, colorAxisMax: 1 };
    }

    let min = getAxisNumericalValue(previewData[0][aggColName]);
    let max = min;
    previewData.forEach((row) => {
      const aggCol = getAxisNumericalValue(row[aggColName]);
      min = Math.min(aggCol, min);
      max = Math.max(aggCol, max);
    });

    const getFloat = (opts: GradientPointOptions | undefined, defaultFloat: number) =>
      getHeatmapStopPoint(opts, min, max, defaultFloat, variables, datasetNamesToId, datasetData);

    const colorAxisMin = getFloat(gradientOptions.minpoint, 0);
    const colorAxisMid = getFloat(gradientOptions.midpoint, 0.5);
    const colorAxisMax = getFloat(gradientOptions.maxpoint, 1);

    return { colorAxisMin, colorAxisMid, colorAxisMax };
  };
}

function getPointCategoryName(point: Point, dimension: string) {
  const series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x'] || 0];
}

export { HeatMap };

import { FC } from 'react';

import { GoalInputRow } from './GoalInputRow';
import { GradientColorPicker } from './GradientColorPicker';
import { sprinkles, Switch, Toggle, ToggleItem } from 'components/ds';

import {
  NumberDisplayOptions,
  GradientType,
  GradientPointType,
  GradientPointOptions,
} from 'constants/types';
import { DEFAULT_GRADIENT } from 'constants/dataPanelEditorConstants';
import { isGradientEnabled } from 'utils/gradientUtils';

type Props = {
  updateNumberOptions: (newFields: NumberDisplayOptions) => void;
  displayOptions: NumberDisplayOptions | undefined;
};

export const GradientConfiguration: FC<Props> = ({ updateNumberOptions, displayOptions }) => {
  const { gradientType, gradient, gradientOptions } = displayOptions ?? {};

  const isEnabled = isGradientEnabled(gradientType);
  const { hue1, hue2, hue3 } = {
    hue1: gradient?.hue1 || DEFAULT_GRADIENT.hue1,
    hue2: gradient?.hue2 || DEFAULT_GRADIENT.hue2,
    hue3: gradient?.hue3 || DEFAULT_GRADIENT.hue3,
  };
  const { maxpoint, midpoint, minpoint } = gradientOptions ?? {};

  const getOnHueChanged = (hue: 'hue1' | 'hue2' | 'hue3') => (newHue: string) => {
    updateNumberOptions({ gradient: { ...gradient, [hue]: newHue } });
  };

  const updatePoint = (
    key: 'minpoint' | 'midpoint' | 'maxpoint',
    updates: Partial<GradientPointOptions>,
  ) => {
    updateNumberOptions({
      gradientOptions: {
        ...gradientOptions,
        [key]: { ...gradientOptions?.[key], ...updates },
      },
    });
  };

  return (
    <>
      <Switch
        label="Gradient"
        onChange={() =>
          updateNumberOptions({
            gradientType: isEnabled ? GradientType.NONE : GradientType.LINEAR,
          })
        }
        switchOn={isEnabled}
      />
      {isEnabled ? (
        <>
          <Toggle
            className={sprinkles({ marginTop: 'sp1' })}
            onValueChange={(value) => updateNumberOptions({ gradientType: value as GradientType })}
            selectedValue={gradientType}>
            <ToggleItem value={GradientType.LINEAR} />
            <ToggleItem value={GradientType.DIVERGING} />
          </Toggle>
          <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
            <GradientColorPicker
              hues={gradientType === GradientType.LINEAR ? [hue1, hue3] : [hue1, hue2, hue3]}
              onHuesChanged={
                gradientType === GradientType.LINEAR
                  ? [getOnHueChanged('hue1'), getOnHueChanged('hue3')]
                  : [getOnHueChanged('hue1'), getOnHueChanged('hue2'), getOnHueChanged('hue3')]
              }
            />
            <div className={sprinkles({ marginLeft: 'sp1' })}>
              <GoalInputRow
                exactValue={minpoint?.value}
                setGradientPointType={(newType) => updatePoint('minpoint', { type: newType })}
                type="min"
                updateExactValue={(newValue) => updatePoint('minpoint', { value: newValue })}
                usesExactValue={minpoint?.type === GradientPointType.NUMBER}
              />
              {gradientType === GradientType.DIVERGING ? (
                <GoalInputRow
                  hideLabels
                  exactValue={midpoint?.value}
                  setGradientPointType={(newType) => updatePoint('midpoint', { type: newType })}
                  type="avg"
                  updateExactValue={(newValue) => updatePoint('midpoint', { value: newValue })}
                  usesExactValue={midpoint?.type === GradientPointType.NUMBER}
                />
              ) : null}
              <GoalInputRow
                hideLabels
                exactValue={maxpoint?.value}
                setGradientPointType={(newType) => updatePoint('maxpoint', { type: newType })}
                type="max"
                updateExactValue={(newValue) => updatePoint('maxpoint', { value: newValue })}
                usesExactValue={maxpoint?.type === GradientPointType.NUMBER}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

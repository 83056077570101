import { FC } from 'react';

import { Toggle, ToggleItem } from './ds';

import { ASC_SORT_ICON, DEFAULT_SORT_ICON, DESC_SORT_ICON } from 'constants/iconConstants';
import { SortOption, SortOrder } from 'constants/types';

type Props = {
  className?: string;
  label?: string;
  // Some allow no sorting
  allowNoSort?: boolean;
  currentSort: SortOption | undefined;
  updateSort: (newSort: SortOption) => void;
};

export const SortOptionToggle: FC<Props> = ({
  className,
  label,
  allowNoSort,
  currentSort,
  updateSort,
}) => {
  return (
    <Toggle
      className={className}
      label={label}
      onValueChange={(val) => updateSort(val as SortOption)}
      selectedValue={currentSort ?? SortOption.DEFAULT}>
      {allowNoSort ? <ToggleItem value={SortOption.DEFAULT}>{DEFAULT_SORT_ICON}</ToggleItem> : null}
      <ToggleItem value={SortOption.ASC}>{ASC_SORT_ICON}</ToggleItem>
      <ToggleItem value={SortOption.DESC}>{DESC_SORT_ICON}</ToggleItem>
    </Toggle>
  );
};

type OrderProps = {
  order: SortOrder | undefined;
  updateOrder: (newOrder: SortOrder) => void;
};

// This component is used as a wrapper for above for places using SortOrder vs SortOption
export const SortOrderToggle: FC<OrderProps> = ({ order, updateOrder }) => {
  return (
    <SortOptionToggle
      currentSort={!order || order === SortOrder.ASC ? SortOption.ASC : SortOption.DESC}
      updateSort={(newSort) =>
        updateOrder(newSort === SortOption.ASC ? SortOrder.ASC : SortOrder.DESC)
      }
    />
  );
};

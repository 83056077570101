import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'utils/standard';

import * as RD from 'remotedata';

import { DashboardStates, ReduxState } from './rootReducer';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { ResourceDataset } from 'types/exploResource';
import { getDashboardLinkForDataset, isInvalidLinkCombo } from 'utils/filterLinking';
import { OPERATION_TYPES } from 'constants/types';

export const getEditableDatasets = createSelector(
  (state: ReduxState) => state.dashboardEditConfig.config?.datasets,

  (datasets) => {
    return datasets ?? {};
  },
);

export const getSelectedDataPanel = createSelector(
  (state: ReduxState) => state.dashboardInteractions.selectedItem,
  (state: ReduxState) => state.dashboardEditConfig.config?.data_panels,
  (selectedItem, dataPanels) => {
    if (selectedItem?.type !== DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
    return dataPanels?.[selectedItem.id];
  },
);

export const getSelectedDataPanelData = createSelector(
  (state: ReduxState) => state.dashboardInteractions.selectedItem,
  (state: ReduxState) => state.dashboardData.dataPanelData,
  (selectedItem, dataPanelData) => {
    if (selectedItem?.type !== DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
    return dataPanelData[selectedItem.id];
  },
);

export const getSelectedElement = createSelector(
  (state: ReduxState) => state.dashboardInteractions.selectedItem,
  (state: ReduxState) => state.dashboardEditConfig.config?.elements,
  (selectedItem, elements) => {
    if (!selectedItem || selectedItem.type === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
    return elements?.[selectedItem.id];
  },
);

export const getSelectedDpDatasetId = createSelector(
  (state: ReduxState) => getSelectedDataPanel(state),
  (state: ReduxState) => getEditableDatasets(state),
  (dataPanel, datasets) => (dataPanel ? datasets[dataPanel.table_id]?.id : undefined),
);

export const getAreFiltersDisabled = createSelector(
  (state: DashboardStates) => state.dashboardInteractions.interactionsInfo,
  (state: DashboardStates) => state.dashboardData.dataPanelData,
  (state: DashboardStates) => state.dashboardData.dashboardLoaded,
  (interactionsInfo, dataPanelData, dashboardLoaded) => {
    if (interactionsInfo.disableInputs) return true;
    if (!interactionsInfo.disableFiltersWhileLoading) return false;

    // Until initial load is done we know they should be disabled
    return (
      !dashboardLoaded ||
      Object.values(dataPanelData).some(
        (data) => data?.loading || !!data?.outstandingSecondaryDataRequests,
      )
    );
  },
);

export const getDatasetLinks = createSelector(
  (state: DashboardStates) =>
    'dashboardEditConfig' in state ? getSelectedElement(state) : undefined,
  (_: unknown, dataset: ResourceDataset | undefined) => dataset,
  (_: unknown, __: unknown, operationType: OPERATION_TYPES) => operationType,
  (selectedElement, dataset, operationType) => {
    if (
      !selectedElement ||
      !dataset ||
      isInvalidLinkCombo(selectedElement.element_type, operationType)
    )
      return;

    return getDashboardLinkForDataset(dataset, selectedElement);
  },
);

const getAppEditableSectionLayout = createSelector(
  (state: ReduxState) => state.dashboardEditConfig.config?.editable_section?.default_layout,
  (state: ReduxState) => state.dashboardEditConfig.editableSectionLayout,
  (state: ReduxState) => state.dashboardInteractions.interactionsInfo.isEditing,
  (defaultLayout, previewLayout, isEditingDashboard) =>
    isEditingDashboard ? defaultLayout : previewLayout ?? defaultLayout,
);

export const getEditableSectionLayout = createSelector(
  (state: DashboardStates) => {
    if (state.dashboardLayout.requestInfo.type === 'embedded') {
      return state.embedDashboard.currentEditableSectionLayout;
    }

    // Make sure state is of ReduxState
    return 'dashboardEditConfig' in state ? getAppEditableSectionLayout(state) : undefined;
  },
  (layout) => layout,
);

export const getEditableSectionConfig = createSelector(
  (state: DashboardStates) => {
    if (state.dashboardLayout.requestInfo.type === 'embedded') {
      return state.embedDashboard.dashboardVersion?.configuration.editable_section;
    }

    // Make sure state is of ReduxState
    return 'dashboardEditConfig' in state
      ? state.dashboardEditConfig.config?.editable_section
      : undefined;
  },
  (config) => config,
);

export const getDatasetsByFidoId = createSelector(
  (state: ReduxState) => {
    return keyBy(
      Object.values(state.dashboardEditConfig.config?.datasets ?? {}),
      (dataset) => dataset.fido_id ?? '',
    );
  },
  (datasetsByFidoId) => datasetsByFidoId,
);

export const getArchetypeProperties = createSelector(
  (state: DashboardStates) => {
    const archetypeProperties =
      state.dashboardLayout.requestInfo.type === 'embedded'
        ? state.embedDashboard.team?.archetype_properties
        : 'dashboardEditConfig' in state
        ? state.teamData.data?.archetype_properties
        : undefined;

    return new Set(archetypeProperties?.map((prop) => prop.name));
  },
  (properties) => properties,
);

export const getSchemaTablesMap = createSelector(
  (state: ReduxState) => !!state.currentUser.team?.feature_flags.use_fido,
  (state: ReduxState) => state.parentSchemas.schemaTablesMap,
  (state: ReduxState) => state.fido.fidoDaos,
  (useFido, schemaTablesMap, fidoDaos) => {
    if (!useFido) return RD.isSuccess(schemaTablesMap) ? schemaTablesMap.data : null;

    return RD.isSuccess(fidoDaos) ? fidoDaos.data.schemaTablesMap : null;
  },
);

import { Layout } from '@explo-tech/react-grid-layout';

import { ACTION } from 'actions/types';
import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { DashboardTimezones } from 'constants/dashboardConstants';
import { DashboardVersion } from '../types/dashboardVersion';
import { LatestVersionInfo } from 'types/exploResource';

// TYPES
type DashboardCachingConfigOptions = {
  is_cache_enabled?: boolean;
  cache_cron?: string;
};

export interface Dashboard extends EmbedDashboard, DashboardCachingConfigOptions {
  team_id: number;
  embed_id: string;
  disabled?: boolean;
  share_link_title: string | null;
  dashboard_attributes: { attribute_id: number; value_id: number }[];
  latest_versions: LatestVersionInfo[];
  entry_id: number;
}

export interface EmbedDashboard {
  id: number;
  name: string;
  disable_filters_while_loading: boolean;
  default_timezone: DashboardTimezones | undefined;
}

// This is the customers config of an editable section of a dashboard
export interface CustomerEditableSection {
  config: CustomerEditableSectionConfig;
  id: number;
  edit_version_number: number;
}

// While we only need layout right now.
// Leaving this as an interface for future expansion
export interface CustomerEditableSectionConfig {
  layout: Layout[];
}

// REQUESTS

type FetchDashboardData = {
  dashboard_template_name: string;
  dashboard_template_id: number;
  dashboard_version: DashboardVersion;
};

export const {
  actionFn: fetchDashboard,
  requestAction: fetchDashboardRequest,
  successAction: fetchDashboardSuccess,
  errorAction: fetchDashboardError,
} = defineAPIAction<FetchDashboardData>(
  ACTION.FETCH_DASHBOARD,
  'dashboards',
  'get_dashboard_template',
  'GET',
);

export const { actionFn: createDashboard, successAction: createDashboardSuccess } =
  defineAPIPostAction<{ name: string; parent_id?: number }, { new_dashboard_template: Dashboard }>(
    ACTION.CREATE_DASHBOARD,
    'dashboards',
    'create_dashboard_template',
    'POST',
  );

export const { actionFn: deleteDashboard, successAction: deleteDashboardSuccess } = defineAPIAction(
  ACTION.DELETE_DASHBOARD,
  'dashboards',
  '',
  'DELETE',
);

type ActivateDisabledDashboardPostData = { disable_dashboard_id: number };

export const {
  actionFn: activateDisabledDashboard,
  successAction: activateDisabledDashboardSuccess,
} = defineAPIPostAction<ActivateDisabledDashboardPostData, {}>(
  ACTION.ACTIVATE_DISABLED_DASHBOARD,
  'dashboards',
  'activate',
  'POST',
);

export const { actionFn: fetchDashboardList, successAction: fetchDashboardListSuccess } =
  defineAPIAction<{ dashboard_template_list: Dashboard[] }>(
    ACTION.FETCH_DASHBOARD_LIST,
    'dashboards',
    'get_dashboard_template_list',
    'GET',
  );

export const { actionFn: renameDashboard, successAction: renameDashboardSuccess } =
  defineAPIPostAction<{ name: string }, { name: string }>(
    ACTION.RENAME_DASHBOARD,
    'dashboards',
    'rename_dashboard_template',
    'POST',
  );

type UpdateDashboardDefaultTimezoneBody = {
  default_timezone: DashboardTimezones;
};

export const {
  actionFn: updateDashboardDefaultTimezone,
  successAction: updateDashboardDefaultTimezoneSuccess,
} = defineAPIPostAction<UpdateDashboardDefaultTimezoneBody, {}>(
  ACTION.UPDATE_DASHBOARD_DEFAULT_TIMEZONE,
  'dashboards',
  'update_default_timezone',
  'POST',
);

type UpdateDashboardDisableFiltersWhileLoadingBody = {
  disable_filters_while_loading: boolean;
};

export const {
  actionFn: updateDashboardDisableFiltersWhileLoading,
  successAction: updateDisableFiltersWhileLoadingSuccess,
} = defineAPIPostAction<UpdateDashboardDisableFiltersWhileLoadingBody, {}>(
  ACTION.UPDATE_DISABLE_FILTERS_WHILE_LOADING,
  'dashboards',
  'update_dashboard_disable_filters_while_loading',
  'POST',
);

export const {
  actionFn: updateDashboardCacheConfig,
  successAction: updateDashboardCacheConfigSuccess,
} = defineAPIPostAction<DashboardCachingConfigOptions, {}>(
  ACTION.UPDATE_DASHBOARD_CACHE_CONFIG,
  'dashboards',
  'update_caching_config',
  'POST',
);

// Share Dashboard Actions

type SaveShareLinkTitleBody = {
  share_link_title: string;
};

export const { actionFn: saveShareLinkTitle, requestAction: saveShareLinkTitleRequest } =
  defineAPIPostAction<SaveShareLinkTitleBody, {}>(
    ACTION.SAVE_SHARE_LINK_TITLE,
    'dashboards',
    'save_share_link_title',
    'POST',
  );

import { createSlice } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  createAttributeValueSuccess,
  createDashboardAttributeSuccess,
  deleteAttributeValueSuccess,
  deleteDashboardAttributeSuccess,
  fetchDashboardAttributesActions,
} from 'actions/dashboardAttributesActions';
import { DashboardAttribute } from 'actions/teamActions';

interface DashboardAttributesReducerState {
  attributes: RD.ResponseData<DashboardAttribute[]>;
}

const dashboardAttributesInitialState: DashboardAttributesReducerState = {
  attributes: RD.Idle(),
};

const dashboardAttributesSlice = createSlice({
  name: 'dashboardAttributes',
  initialState: dashboardAttributesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardAttributesActions.requestAction, (state) => {
        state.attributes = RD.Loading();
      })
      .addCase(fetchDashboardAttributesActions.errorAction, (state) => {
        state.attributes = RD.Error('Error loading dashboard attributes');
      })
      .addCase(fetchDashboardAttributesActions.successAction, (state, { payload }) => {
        state.attributes = RD.Success(payload.dashboard_attributes);
      })
      .addCase(createDashboardAttributeSuccess, (state, { payload }) => {
        RD.update(state.attributes, (attributes) => attributes.push(payload.dashboard_attribute));
      })
      .addCase(deleteDashboardAttributeSuccess, (state, { payload }) => {
        state.attributes = RD.map(state.attributes, (attributes) =>
          attributes.filter((attr) => attr.id !== payload.id),
        );
      })
      .addCase(createAttributeValueSuccess, (state, { payload }) => {
        RD.update(state.attributes, (attributes) =>
          attributes.forEach((attr) => {
            if (attr.id !== payload.value.attribute_id) return;
            attr.values.push({
              id: payload.value.id,
              color_hex: payload.value.color_hex,
              value: payload.value.value,
            });
          }),
        );
      })
      .addCase(deleteAttributeValueSuccess, (state, { payload }) => {
        RD.update(state.attributes, (attributes) =>
          attributes.forEach((attr) => {
            if (attr.id !== payload.postData.attrId) return;
            attr.values = attr.values.filter(({ id }) => id !== payload.postData.id);
          }),
        );
      });
  },
});

export const dashboardAttributesReducer = dashboardAttributesSlice.reducer;

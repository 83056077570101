import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  fetchGenerateAiViewError,
  fetchGenerateAiViewRequest,
  fetchGenerateAiViewSuccess,
  fetchGenerateAiViewSuggestionRequest,
  fetchGenerateAiViewSuggestionSuccess,
  fetchGenerateAiViewSuggestionError,
} from 'reportBuilderContent/thunks/aiThunks';

import {
  GenerateReportBuilderViewAIJobResponse,
  GenerateReportBuilderViewSuggestionsAIJobResponse,
} from 'reportBuilderContent/apiTypes';

interface ReportAiReducerState {
  datasetId: string;
  generatedView: RD.ResponseData<GenerateReportBuilderViewAIJobResponse>;
  suggestedViews: Record<
    string,
    RD.ResponseData<GenerateReportBuilderViewSuggestionsAIJobResponse>
  >;
}

const initialState: ReportAiReducerState = {
  datasetId: '',
  generatedView: RD.Idle(),
  suggestedViews: {},
};

const reportAiReducerSlice = createSlice({
  name: 'ai',
  initialState: initialState,
  reducers: {
    setDatasetId(state, { payload }: PayloadAction<string>) {
      if (payload === state.datasetId) return;
      state.datasetId = payload;
      state.generatedView = RD.Idle();
    },
    setGeneratedView(state, { payload }: PayloadAction<GenerateReportBuilderViewAIJobResponse>) {
      state.generatedView = RD.Success(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenerateAiViewSuccess, (state, { payload }) => {
        state.generatedView = RD.Success(payload);
      })
      .addCase(fetchGenerateAiViewError, (state, { payload }) => {
        state.generatedView = RD.Error(payload.error);
      })
      .addCase(fetchGenerateAiViewRequest, (state) => {
        state.generatedView = RD.Loading();
      })
      .addCase(
        fetchGenerateAiViewSuggestionSuccess,
        (state, { payload: { jobArgs, ...payload } }) => {
          state.suggestedViews[jobArgs.datasetId] = RD.Success(payload);
        },
      )
      .addCase(fetchGenerateAiViewSuggestionError, (state, { payload }) => {
        state.suggestedViews[payload.job.jobArgs.datasetId] = RD.Error(payload.error);
      })
      .addCase(fetchGenerateAiViewSuggestionRequest, (state, { payload: { postData } }) => {
        state.datasetId = postData.datasetId;
        state.suggestedViews[postData.datasetId] = RD.Loading();
      });
  },
});

export const reportAiReducer = reportAiReducerSlice.reducer;

export const { setGeneratedView, setDatasetId } = reportAiReducerSlice.actions;

import { DatasetColumn } from 'types/datasets';
import { NONE_CATEGORY_COLOR_VALUE } from 'constants/dashboardConstants';
import { DATE_TYPES } from 'constants/dataConstants';
import {
  ColorColumnOption,
  COLOR_CATEGORY_OPERATION_TYPES,
  SelectedDropdownInputItem,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { shouldProcessColAsDate } from 'pages/dashboardPage/charts/utils';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import { PivotAgg } from 'types/dateRangeTypes';

export const getColorColumns = (
  instructions: V2TwoDimensionChartInstructions | undefined,
): ColorColumnOption[] => {
  if (!instructions) return [];
  const { colorColumnOptions, defaultColorGroupingOff } = instructions;

  const optionCount = colorColumnOptions?.length ?? 0;
  if (!colorColumnOptions || optionCount === 0) return [];

  return optionCount > 1 || defaultColorGroupingOff ? colorColumnOptions : [];
};

export const getColorDropdownOptions = (
  instructions: V2TwoDimensionChartInstructions | undefined,
): SelectedDropdownInputItem[] => {
  const colorColumns = getColorColumns(instructions);
  if (colorColumns.length === 0) return [];

  const colorOptions: SelectedDropdownInputItem[] = colorColumns.map(({ column }) => ({
    name: column.friendly_name || column.name,
    id: column.name,
  }));

  if (instructions?.defaultColorGroupingOff) {
    colorOptions.push({ name: 'None', id: NONE_CATEGORY_COLOR_VALUE });
  }

  return colorOptions;
};

export const isSelectedColorDateType = ({
  colorColumnOptions,
  defaultColorGroupingOff,
}: V2TwoDimensionChartInstructions) => {
  if (!colorColumnOptions || colorColumnOptions.length === 0) return false;

  if (colorColumnOptions.length > 1 || defaultColorGroupingOff) {
    return shouldProcessColAsDate(colorColumnOptions.find((c) => c.selected));
  } else {
    return shouldProcessColAsDate(colorColumnOptions[0]);
  }
};

export const getColorColumn = (
  config: V2TwoDimensionChartInstructions | undefined,
  selectedColName?: string,
): ColorColumnOption | undefined => {
  if (!config) return;

  const colorOptions = config.colorColumnOptions ?? [];
  if (colorOptions.length === 0) return;

  if (!selectedColName) return colorOptions[0];

  const selectedIndex = colorOptions.findIndex((col) => col.column.name === selectedColName);
  return colorOptions[Math.max(0, selectedIndex)];
};

export const createColorCol = (newCol: DatasetColumn): ColorColumnOption => ({
  column: newCol,
  bucket: DATE_TYPES.has(newCol.type) ? { id: PivotAgg.DATE_MONTH } : undefined,
});

export const handleColorColumnAddition = (
  newCol: DatasetColumn,
  currentColumns?: ColorColumnOption[],
  enableMultiple?: boolean,
): ColorColumnOption[] => {
  if (!enableMultiple) return [createColorCol(newCol)];

  const columns = currentColumns || [];
  columns.push(createColorCol(newCol));
  return columns;
};

// A chart is only using multiple color categories if multiple color columns are selected and the operation
// type is one that uses a color category
export const isChartUsingMultipleColorCategories = (
  visualizeOperation: VisualizeOperation | undefined,
): boolean => {
  if (!visualizeOperation) return false;
  const { colorColumnOptions, defaultColorGroupingOff } =
    visualizeOperation.instructions.V2_TWO_DIMENSION_CHART ?? {};
  const colorColCount = colorColumnOptions?.length ?? 0;

  return (
    COLOR_CATEGORY_OPERATION_TYPES.has(visualizeOperation.operation_type) &&
    (colorColCount > 1 || (colorColCount > 0 && !!defaultColorGroupingOff))
  );
};

import { FilterableColumn } from 'utils/customerReportUtils';
import { CustomerReportFilter } from 'actions/customerReportActions';
import { NUMBER_TYPES, TIME_COLUMN_TYPES, STRING } from 'constants/dataConstants';
import { FilterOperator } from 'types/filterOperations';
import { SortInfo, SortOrder } from 'constants/types';
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';

// Given a column, determine the most suitable filter for it
export const getFilterColumn = (
  column: FilterableColumn,
  filterValue: string | number | null,
): CustomerReportFilter | undefined => {
  if (filterValue == null) return;

  const baseFilter: CustomerReportFilter = {
    filterColumn: { name: column.name, type: column.type },
    filterOperation: { id: FilterOperator.NUMBER_IS_IN },
    id: Math.round(Math.random() * 1e9), // Random ID to prevent collisions
    filterValue,
    isPostFilter: column.isPostFilter,
  };

  if (NUMBER_TYPES.has(column.type)) {
    return {
      ...baseFilter,
      filterOperation: { id: FilterOperator.NUMBER_IS_IN },
      filterValue: [Number(filterValue)],
    };
  }

  if (TIME_COLUMN_TYPES.has(column.type)) {
    return { ...baseFilter, filterOperation: { id: FilterOperator.DATE_IS } };
  }

  if (column.type === STRING) {
    return {
      ...baseFilter,
      filterOperation: {
        id: FilterOperator.STRING_IS_IN,
      },
      filterValue: [String(filterValue)],
    };
  }

  // Boolean
  return {
    ...baseFilter,
    filterOperation: {
      id: filterValue ? FilterOperator.BOOLEAN_IS_TRUE : FilterOperator.BOOLEAN_IS_FALSE,
    },
  };
};

export const getDataGridSort = (viewSort?: SortInfo[]): TypeSortInfo | null => {
  // Convert Explo's sort to reactdatagrid's sort: TypeSortInfo --> TypeSort
  return (
    viewSort?.map((sort) => ({
      name: sort.column.name,
      id: sort.column.name,
      dir: sort.order === SortOrder.ASC ? 1 : -1,
    })) ?? null
  );
};

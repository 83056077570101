import { FC, useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import {
  APP_PORTAL_ID,
  Intent,
  Tag,
  AlertModal,
  Menu,
  MenuActionItem,
  MenuSub,
  Icon,
  IconButton,
  sprinkles,
} from 'components/ds';
import { PublishToDropdown } from './PublishToDropdown';

import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { DashboardVersion } from 'types/dashboardVersion';
import { EnvironmentTag } from 'actions/environmentTagActions';
import { switchCurrentlyEditingDashboardVersion } from 'actions/dashboardV2Actions';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { ResourcePageType } from 'types/exploResource';
import { isVersionTagged } from 'utils/exploResourceUtils';
import * as styles from './index.css';
import { revertResourceToVersion } from 'reducers/thunks/versionManagementThunks';

type Props = {
  canRevert: boolean;
  closeModal: () => void;
  resourceId: number;
  isDraft: boolean;
  pageType: ResourcePageType;
  tags: RD.ResponseData<EnvironmentTag[]>;
  version: DashboardVersion | ReportBuilderVersion;
  userPermissions: string[];
};

export const VersionPanel: FC<Props> = ({
  canRevert,
  closeModal,
  resourceId,
  isDraft,
  pageType,
  tags,
  version,
  userPermissions,
}) => {
  const dispatch = useDispatch();
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);

  const isExplore = pageType === ResourcePageType.EXPLORE;

  const revertAction = isExplore
    ? ACTION.REVERT_TO_DASHBOARD_VERSION
    : ACTION.REVERT_TO_REPORT_BUILDER_VERSION;

  const revertLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([revertAction], false)(state),
  );

  const isDeprecated = 'deprecated' in version ? version.deprecated : false;
  const envTags = RD.isSuccess(tags) ? tags.data : undefined;

  const userCanEditResource = doesUserHavePermission(userPermissions, PERMISSIONED_ACTIONS.UPDATE);
  const userCanPublishResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.PUBLISH,
  );

  const handlePreview = () => {
    if (isExplore) {
      dispatch(
        switchCurrentlyEditingDashboardVersion({ dashboardVersion: version as DashboardVersion }),
      );
    }
    closeModal();
  };

  const handleRevert = () =>
    dispatch(
      revertResourceToVersion(
        isExplore,
        {
          id: resourceId,
          version_number: version.version_number,
        },
        () => closeModal(),
      ),
    );

  const renderRevertModal = () => (
    <AlertModal
      actionButtonProps={{
        onClick: handleRevert,
        loading: revertLoading,
        text: `Yes, revert to Version ${version.version_number}`,
      }}
      cancelButtonProps={{ onClick: () => setIsRevertModalOpen(false), text: 'Cancel' }}
      isOpen={isRevertModalOpen}
      onClose={() => setIsRevertModalOpen(false)}
      portalContainerId={APP_PORTAL_ID}
      title={`Are you sure you want to revert to Version ${version.version_number}?`}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {isDraft
          ? `This will discard your active draft and replace it with another draft matching version ${version.version_number}. Do you want to continue?`
          : `This will add a new draft matching version ${version.version_number}. Do you want to continue?`}
      </div>
    </AlertModal>
  );

  return (
    <div className={styles.versionPanelRoot}>
      <div className={sprinkles({ width: 'fill' })}>
        <div className={styles.versionPanelNameAndTags}>
          <div className={sprinkles({ whiteSpace: 'nowrap', marginRight: 'sp1' })}>
            Version {version.version_number}
          </div>
          <Tag className={sprinkles({ marginRight: 'sp1' })}>
            {`${getTimezoneAwareDate(version.version_saved_at || version.created)
              // set the locale to english for this specific instance because
              // we don't want the app builder itself to be translated yet
              .setLocale('en-us')
              .toRelative()}`}
          </Tag>
          {isDeprecated ? (
            <Tag
              inverted
              className={sprinkles({ marginRight: 'sp1' })}
              intent={Intent.ERROR}
              key={`deprecated-${version.id}`}>
              Deprecated
            </Tag>
          ) : null}
          {envTags?.map((tag) =>
            isVersionTagged(pageType, resourceId, version.id, tag) ? (
              <Tag
                inverted
                backgroundColor={tag.color_hex}
                className={sprinkles({ marginRight: 'sp1' })}
                key={`attached-tag-${tag.id}`}>
                {tag.name}
              </Tag>
            ) : null,
          )}
        </div>
        <div className={styles.changeComment}>{version.change_comments}</div>
      </div>
      {!isDeprecated ? (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
            {isExplore ? <MenuActionItem onSelect={handlePreview} text="Preview" /> : null}
            {canRevert && userCanEditResource ? (
              <MenuActionItem onSelect={() => setIsRevertModalOpen(true)} text="Revert" />
            ) : null}
            {userCanPublishResource && envTags ? (
              <MenuSub
                trigger={
                  <div>
                    <>Publish To</>
                    <Icon name="caret-right" />
                  </div>
                }
                width="small">
                <PublishToDropdown
                  pageType={pageType}
                  resourceId={resourceId}
                  tags={envTags}
                  versionId={version.id}
                />
              </MenuSub>
            ) : null}
          </Menu>
          {renderRevertModal()}
        </div>
      ) : null}
    </div>
  );
};

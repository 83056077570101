import { FC } from 'react';
import { useSelector } from 'react-redux';

import { EmbedButton } from 'components/embed';

import { LinkFormat } from 'constants/types';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import { AllStates } from 'reducers/rootReducer';

type Props = {
  linkFormat?: LinkFormat;
};

export const UrlClickThroughButton: FC<Props> = ({ linkFormat }) => {
  const variables = useSelector((state: AllStates) =>
    'dashboardData' in state ? state.dashboardData.variables : undefined,
  );

  if (!linkFormat?.link || !linkFormat?.url) return null;

  return (
    <EmbedButton
      icon={linkFormat?.openInNewTab ? 'arrow-up-right-from-square' : 'arrow-up-right'}
      openInNewTab={linkFormat?.openInNewTab}
      to={replaceVariablesInString(linkFormat.url, variables)}
      variant="tertiary"
    />
  );
};

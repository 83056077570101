import { createSlice, isAnyOf, Reducer, createSelector } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  Folder,
  Breadcrumb,
  listDashboardFolderContents,
  listReportFolderContents,
  moveEntry,
  deleteFolder,
  renameFolder,
  createFolder,
} from 'reducers/thunks/resourceThunks';
import { ReduxState } from 'reducers/rootReducer';
import { Resource } from 'shared/ExploResource/ResourceListPageV2';

interface FolderReducerState {
  folders: Folder[];
  resourcesStatus: RD.ResponseData<boolean>;
  breadcrumbs: Breadcrumb[]; // inclusive of root folder to current folder context
  totalCount: number;
}
const folderReducerInitialState: FolderReducerState = {
  folders: [],
  resourcesStatus: RD.Idle(),
  breadcrumbs: [],
  totalCount: 0,
};

const folderSlice = createSlice({
  name: 'folder',
  initialState: folderReducerInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(createFolder.fulfilled, (state, { payload }) => {
        state.folders.unshift(payload.folder);
      })
      .addCase(renameFolder.fulfilled, (state, { payload }) => {
        const folderToRename = state.folders.find((f) => f.id === payload.folder.id);
        if (folderToRename) {
          folderToRename.name = payload.folder.name;
        }
      })
      .addCase(moveEntry.fulfilled, (state, { payload }) => {
        /**
         * Move will only ever remove things from the current view
         * because we can folders into different folders (out of view)
         * or we can use the move modal on a folder currently in view
         * to move it into another folder, so out of view.
         */
        if (payload.type !== 'folder') return;
        state.folders = state.folders.filter(({ id }) => id !== payload.id);
      })
      .addCase(deleteFolder.fulfilled, (state, { payload }) => {
        state.folders = state.folders.filter((f) => f.id !== payload.id);
      })
      .addMatcher(
        isAnyOf(listDashboardFolderContents.pending, listReportFolderContents.pending),
        (state) => {
          state.resourcesStatus = RD.Loading();
        },
      )
      .addMatcher(
        isAnyOf(listDashboardFolderContents.rejected, listReportFolderContents.rejected),
        (state) => {
          state.resourcesStatus = RD.Error('Error Loading Folders');
        },
      )
      .addMatcher(
        isAnyOf(listDashboardFolderContents.fulfilled, listReportFolderContents.fulfilled),
        (state, { payload }) => {
          state.folders = payload.folders;
          state.resourcesStatus = RD.Success(true);
          state.breadcrumbs = payload.breadcrumbs;
          state.totalCount = payload.count;
        },
      ),
});

export const getResources = createSelector(
  (state: ReduxState) => state.dashboard.dashboardList,
  (state: ReduxState) => state.reportBuilder.reportBuilders,
  (_: unknown, isExploreProduct: boolean) => isExploreProduct,
  (dashboardList, reportBuilder, isExploreProduct) =>
    (isExploreProduct ? dashboardList || [] : RD.getOrDefault(reportBuilder, [])) as Resource[],
);

export const folderReducer = folderSlice.reducer as Reducer<FolderReducerState>;

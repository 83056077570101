import { FC } from 'react';

import * as styles from './styles.css';
import { Folder } from 'reducers/thunks/resourceThunks';
import { Icon, sprinkles } from 'components/ds';

type Props = {
  isCard: boolean;
  folder: Folder;
  dotsMenu: JSX.Element | null;
  openFolder: () => void;
};

export const FolderItem: FC<Props> = ({ isCard, folder, dotsMenu, openFolder }) => {
  if (!isCard) {
    return (
      <div className={styles.folderListItem} key={folder.id}>
        <div className={styles.resourceListColumnGroup} onClick={openFolder}>
          <div className={styles.folderListName}>
            <Icon
              className={sprinkles({ color: 'contentTertiary' })}
              name="folder"
              style={{ width: 26, height: 26 }}
            />
            <div className={sprinkles({ heading: 'h4' })}>{folder.name}</div>
            <div
              className={sprinkles({
                body: 'b2',
                color: 'contentSecondary',
                flexItems: 'alignCenter',
                gap: 'sp1',
              })}>
              <div>{`${folder.num_folders} folders`}</div>
              <div>{`${folder.num_resources} resources`}</div>
            </div>
          </div>
        </div>
        <div className={styles.resourceListEndGroup}>{dotsMenu}</div>
      </div>
    );
  }

  return (
    <div className={styles.resourceCardItem} key={folder.id} onClick={openFolder}>
      <div className={styles.folderCardInfo}>
        <div onClick={(e) => e.stopPropagation()}>{dotsMenu}</div>
        <Icon
          className={sprinkles({ color: 'contentTertiary' })}
          name="folder"
          style={{ width: 38, height: 38 }}
        />
        <div className={styles.folderCardName}>{folder.name}</div>
        <div
          className={sprinkles({
            body: 'b2',
            color: 'contentSecondary',
            flexItems: 'centerColumn',
          })}>
          <div> {`${folder.num_folders} folders`}</div>
          <div> {`${folder.num_resources} resources`}</div>
        </div>
      </div>
    </div>
  );
};

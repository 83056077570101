import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, sprinkles, Label, Select } from 'components/ds';

import { updateApiToken } from 'actions/rolePermissionActions';
import { ApiToken } from 'actions/teamActions';
import { showSuccessToast } from 'shared/sharedToasts';

type Props = {
  apiToken: ApiToken;
  setConfirmationModalOpen: () => void;
  setSelectedApiToken: () => void;
  setApiTokenAction: (action: 'refresh' | 'delete') => void;
};

const readOnlyAccessItem = { value: 'readonly', label: 'Read-Only' };
const readWriteAccessItem = { value: 'readwrite', label: 'Read-Write' };
const accessTypeOptions = [readOnlyAccessItem, readWriteAccessItem];

const copyToClipboard = (apiToken: string) => {
  navigator.clipboard.writeText(apiToken);
  showSuccessToast('API Token Copied To Clipboard');
};

export const SettingsAccessGroupsApiTokenSection: FC<Props> = ({
  apiToken,
  setConfirmationModalOpen,
  setSelectedApiToken,
  setApiTokenAction,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className={sprinkles({
        display: 'flex',
        gap: 'sp1',
        paddingBottom: 'sp1.5',
        alignItems: 'flex-end',
      })}
      key={apiToken.id}>
      <div className={sprinkles({ flex: 4, overflow: 'hidden' })}>
        <Label htmlFor="">Token</Label>
        <div className={apiTokenBoxClass} onClick={() => copyToClipboard(apiToken.value)}>
          <div className={sprinkles({ flex: 1, truncateText: 'ellipsis' })}>{apiToken.value}</div>
          <div className={sprinkles({ color: 'active', fontWeight: 500 })}>Copy</div>
        </div>
      </div>
      <div className={sprinkles({ flex: 1 })}>
        <Select
          label="Permissions"
          onChange={(item) =>
            dispatch(
              updateApiToken({
                postData: {
                  token_id: apiToken.id,
                  is_read_only: item === readOnlyAccessItem.value,
                },
              }),
            )
          }
          placeholder="Select an access type"
          selectedValue={
            apiToken.is_read_only ? readOnlyAccessItem.value : readWriteAccessItem.value
          }
          values={accessTypeOptions}
        />
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <IconButton
          name="refresh"
          onClick={() => {
            setConfirmationModalOpen();
            setSelectedApiToken();
            setApiTokenAction('refresh');
          }}
          variant="primary"
        />
        <IconButton
          name="trash"
          onClick={() => {
            setConfirmationModalOpen();
            setSelectedApiToken();
            setApiTokenAction('delete');
          }}
          variant="destructive"
        />
      </div>
    </div>
  );
};

const apiTokenBoxClass = sprinkles({
  backgroundColor: 'white',
  border: 1,
  borderColor: 'outline',
  borderRadius: 4,
  flexItems: 'alignCenterBetween',
  gap: 'sp.5',
  cursor: 'pointer',
  padding: 'sp1',
  flex: 1,
});

import { forwardRef } from 'react';
import cx from 'classnames';

import * as styles from './index.css';

export const ResizeHandle = forwardRef<HTMLDivElement>((props, ref) => {
  return <div {...props} className={cx('resizingHandle', styles.resizingHandle)} ref={ref} />;
});

ResizeHandle.displayName = 'ResizeHandle';

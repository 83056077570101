import { FC, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { useImmer } from 'use-immer';
import * as RD from 'remotedata';

import { sprinkles, AlertModal, Select, Tag, Icon } from 'components/ds';
import { EmptyPageActionCallout } from 'components/EmptyPageActionCallout';
import { PageHeader } from 'components/PageHeader';
import TablePager from 'components/dataTable/tablePager';
import { CustomersModal } from './customersModal';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { EditCustomerSideSheet } from './editCustomerSideSheet';
import { CustomerListItem, ParentSummary } from './customerListItem';

import {
  fetchFilteredCustomers,
  deleteCustomer,
  fetchGroupTags,
  fetchHierarchyMetadata,
} from 'actions/customerActions';
import { fetchEndUserList } from 'actions/endUserActions';
import { Customer, fetchUserTeam, HierarchyLevel } from 'actions/teamActions';
import { doesCustomerHaveInvalidAccessGroup } from 'utils/customerUtils';
import { partition } from 'utils/standard';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { isCreateCustomersDisabled } from 'utils/paymentPlanUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { showErrorToast } from 'shared/sharedToasts';
import { createDebouncedFn } from 'utils/general';
import { updateHasSeenHierarchyBanner } from 'actions/userActions';
import { clearPotentialParents } from 'reducers/customersReducer';

import * as styles from './styles.css';

const CUSTOMERS_PER_PAGE = 20;
const debounceFn = createDebouncedFn(400);

enum ModalStatus {
  CREATE_CUSTOMER = 'create_customer',
  DELETE_CUSTOMER = 'delete_customer',
  EDIT_CUSTOMER = 'edit_customer',
  CLOSED = 'closed',
}

type CustomerFilterInfo = {
  selectedGroupTagId?: number;
  pageNumber: number;
  searchString: string;
  selectedHierarchyLevelId: number;
  selectedParentProvidedIds: ParentSummary[];
};

const initialCustomerFilterInfo: CustomerFilterInfo = {
  pageNumber: 1,
  selectedGroupTagId: -1,
  searchString: '',
  selectedHierarchyLevelId: -1,
  selectedParentProvidedIds: [],
};

export const CustomersPage: FC = () => {
  const dispatch = useDispatch();

  const {
    filteredCustomerData,
    teamData,
    endUsers,
    groupTags,
    permissions,
    hierarchyMetadata,
    hasSeenHierarchyBanner,
  } = useSelector(
    (state: ReduxState) => ({
      filteredCustomerData: state.customers.filteredCustomerData,
      teamData: state.teamData.data,
      endUsers: state.endUsers.endUsers,
      groupTags: state.customers.groupTags,
      permissions: state.currentUser.permissions,
      hierarchyMetadata: state.customers.hierarchyMetadata,
      hasSeenHierarchyBanner: state.currentUser.has_seen_hierarchy_banner,
    }),
    shallowEqual,
  );

  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [customerFilterInfo, setCustomerFilterInfo] = useImmer(initialCustomerFilterInfo);

  useEffect(() => {
    const {
      selectedGroupTagId,
      pageNumber,
      searchString,
      selectedHierarchyLevelId,
      selectedParentProvidedIds,
    } = customerFilterInfo;
    const parentLength = selectedParentProvidedIds.length;
    const selectedParent =
      parentLength > 0 ? selectedParentProvidedIds[parentLength - 1] : undefined;

    dispatch(
      fetchFilteredCustomers({
        queryParams: {
          page: pageNumber,
          group_tag_id: selectedGroupTagId != -1 ? selectedGroupTagId : undefined,
          search_string: searchString || undefined,
          page_size: CUSTOMERS_PER_PAGE,
          hierarchy_level_id: selectedHierarchyLevelId != -1 ? selectedHierarchyLevelId : undefined,
          parent_provided_id: selectedParent?.providedId,
          access_group_id: selectedParent?.accessGroupId,
        },
      }),
    );
  }, [dispatch, customerFilterInfo]);

  useEffect(() => {
    if (RD.isIdle(endUsers)) dispatch(fetchEndUserList());
  }, [dispatch, endUsers]);
  useEffect(() => {
    if (RD.isIdle(groupTags)) dispatch(fetchGroupTags());
  }, [dispatch, groupTags]);
  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);
  useEffect(() => {
    if (RD.isIdle(hierarchyMetadata)) dispatch(fetchHierarchyMetadata());
  }, [hierarchyMetadata, dispatch]);

  const accessGroups = teamData?.access_groups;
  const paymentPlan = teamData?.payment_plan;

  const customerPermissions = permissions[PERMISSIONED_ENTITIES.CUSTOMER];

  const [filteredCustomers, totalFilteredCustomerCount] = useMemo(
    () =>
      RD.isSuccess(filteredCustomerData)
        ? [filteredCustomerData.data.customers, filteredCustomerData.data.totalCount]
        : [[], 0],
    [filteredCustomerData],
  );

  const [hierarchyLevels, hierarchyLevelCounts] = RD.isSuccess(hierarchyMetadata)
    ? [hierarchyMetadata.data.levels, hierarchyMetadata.data.levelCounts]
    : [[], []];

  const totalCustomerCount = useMemo(() => {
    if (hierarchyLevelCounts.length === 0) return 0;
    return hierarchyLevelCounts.reduce((prev, current) => ({
      hierarchy_level_id: -1,
      count: prev.count + current.count,
    })).count;
  }, [hierarchyLevelCounts]);

  const [invalidCustomers, validCustomers] = useMemo(() => {
    const accessGroupIdsSet = new Set((accessGroups ?? []).map((group) => group.id));
    return partition(filteredCustomers, (group) =>
      doesCustomerHaveInvalidAccessGroup(group, accessGroupIdsSet),
    );
  }, [accessGroups, filteredCustomers]);

  const isCreateCustomerDisabledForPaymentPlan = isCreateCustomersDisabled(
    filteredCustomers.length,
    paymentPlan,
  );

  const userCanEditCustomer =
    doesUserHavePermission(customerPermissions, PERMISSIONED_ACTIONS.UPDATE) &&
    paymentPlan !== PLAN_TYPES.LAUNCH;
  const userCanDeleteCustomer =
    doesUserHavePermission(customerPermissions, PERMISSIONED_ACTIONS.DELETE) &&
    paymentPlan !== PLAN_TYPES.LAUNCH;

  const isFiltering = !shallowEqual(initialCustomerFilterInfo, customerFilterInfo);

  const showLevelColumn =
    hierarchyLevels.length > 1 && customerFilterInfo.selectedHierarchyLevelId === -1;

  const showChildrenColumn = hierarchyLevels.length > 1;

  const renderAddGroupModal = () => {
    if (modalStatus !== ModalStatus.CREATE_CUSTOMER || !accessGroups) return;
    return (
      <CustomersModal
        accessGroups={accessGroups}
        closeModal={() => {
          dispatch(clearPotentialParents());
          setModalStatus(ModalStatus.CLOSED);
        }}
        groupTags={groupTags}
        hierarchyLevels={hierarchyLevels}
      />
    );
  };

  const renderEditModal = () => {
    if (modalStatus !== ModalStatus.EDIT_CUSTOMER || !selectedCustomer) return;

    const endUsersForCustomer = RD.isSuccess(endUsers)
      ? endUsers.data.filter((user) => user.group_id === selectedCustomer?.id)
      : [];

    return (
      <EditCustomerSideSheet
        accessGroups={accessGroups ?? []}
        endUsers={endUsersForCustomer}
        groupTags={groupTags}
        hierarchyLevels={hierarchyLevels}
        onClose={() => {
          dispatch(clearPotentialParents());
          setModalStatus(ModalStatus.CLOSED);
        }}
        selectedEntity={selectedCustomer}
      />
    );
  };
  const handleDeleteCustomer = () => {
    if (!selectedCustomer) {
      showErrorToast('No Customer Selected');
      return;
    }

    setDeleteLoading(true);

    dispatch(
      deleteCustomer(
        { id: selectedCustomer.id },
        () => {
          setModalStatus(ModalStatus.CLOSED);
          setSelectedCustomer(undefined);
          setDeleteLoading(false);
        },
        (error) => {
          showErrorToast(`Error deleting customer: ${error.detail}`);
          setDeleteLoading(false);
        },
      ),
    );
  };

  const renderDeleteCustomerConfirmModal = () => {
    if (modalStatus !== ModalStatus.DELETE_CUSTOMER) return;

    return (
      <AlertModal
        isOpen
        actionButtonProps={{ onClick: handleDeleteCustomer }}
        onClose={() => setModalStatus(ModalStatus.CLOSED)}
        title="Are you sure you want to delete this customer?"
      />
    );
  };

  const renderHeader = () => {
    return (
      <PageHeader
        pageTitle="Customers"
        primaryActionProps={
          doesUserHavePermission(customerPermissions, PERMISSIONED_ACTIONS.CREATE)
            ? {
                closeSearchOnClick: true, // this clears the search field when creating a new customer
                disabled: isCreateCustomerDisabledForPaymentPlan,
                text: 'Create Customer',
                tooltipText: isCreateCustomerDisabledForPaymentPlan
                  ? 'Upgrade your plan to add more customers.'
                  : undefined,
                onClick: () => {
                  setModalStatus(ModalStatus.CREATE_CUSTOMER);
                  setCustomerFilterInfo(initialCustomerFilterInfo);
                },
              }
            : undefined
        }
        searchBarPlaceholderText="Search by Name or Provided ID"
        searchBarSubmit={(searchStringSupplied: string) => {
          debounceFn(() =>
            setCustomerFilterInfo((draft) => {
              draft.searchString = searchStringSupplied;
              draft.pageNumber = 1;
            }),
          );
        }}
      />
    );
  };

  const renderListHeader = () => {
    return (
      <>
        <div className={styles.headerCellContainer}>CUSTOMER</div>
        {showChildrenColumn ? <div className={styles.headerCellContainer}>CHILDREN</div> : null}
        <div className={styles.headerCellContainer}>ID</div>
        {showLevelColumn ? <div className={styles.headerCellContainer}>LEVEL</div> : null}
        <div className={styles.headerCellContainer}>GROUP TAG</div>
        <div className={styles.headerCellContainer}>EMAIL ADDRESS</div>
        <div className={styles.headerCellContainer} />
      </>
    );
  };

  const renderList = () => {
    if (filteredCustomers.length === 0) {
      return !isFiltering ? (
        <EmptyPageActionCallout text="Connect to our API to sync your customer list, or add customers manually here" />
      ) : (
        <div className={sprinkles({ flexItems: 'center' })}>
          No customers found with given search parameters.
        </div>
      );
    }

    const invalidGroupIds = new Set(invalidCustomers.map((group) => group.id));
    const customers = invalidCustomers.concat(validCustomers);

    return (
      <div
        className={
          showLevelColumn
            ? styles.listContainerLevels
            : showChildrenColumn
            ? styles.listContainerChildren
            : styles.listContainer
        }>
        {renderListHeader()}
        {customers.map((customer, index) => (
          <CustomerListItem
            customer={customer}
            deleteLoading={deleteLoading}
            gridMetadata={{
              isFirstRow: index === 0,
              isLastRow: index === customers.length - 1,
              showLevelColumn: showLevelColumn,
              showChildrenColumn: showChildrenColumn,
            }}
            groupTags={RD.isSuccess(groupTags) ? groupTags.data : []}
            key={`customer-item-${customer.id}`}
            levels={hierarchyLevels}
            onCustomerClick={(parent) =>
              setCustomerFilterInfo((draft) => {
                draft.selectedParentProvidedIds.push(parent);
                draft.selectedHierarchyLevelId = -1;
                draft.pageNumber = 1;
              })
            }
            onDelete={
              userCanDeleteCustomer
                ? () => {
                    setSelectedCustomer(customer);
                    setModalStatus(ModalStatus.DELETE_CUSTOMER);
                  }
                : undefined
            }
            onEdit={
              userCanEditCustomer
                ? () => {
                    setSelectedCustomer(customer);
                    setModalStatus(ModalStatus.EDIT_CUSTOMER);
                  }
                : undefined
            }
            resetVisibilityGroup={invalidGroupIds.has(customer.id)}
          />
        ))}
      </div>
    );
  };
  const renderPager = () => {
    const numCustomers = totalFilteredCustomerCount ?? filteredCustomers.length;
    const maxPageNumber = Math.max(Math.ceil(numCustomers / CUSTOMERS_PER_PAGE), 1);
    const currentPageNumber = customerFilterInfo.pageNumber;

    return (
      <div className={styles.pagerFooterContainer}>
        <div className={sprinkles({ paddingLeft: 'sp2' })}>{numCustomers} customers</div>
        <TablePager
          currentPage={currentPageNumber}
          maxPageNumber={maxPageNumber}
          onNewPage={(newPage) => {
            const newPageNumber = Number.parseInt(newPage);

            if (
              !newPageNumber ||
              newPageNumber < 1 ||
              newPageNumber > maxPageNumber ||
              currentPageNumber === newPageNumber
            ) {
              return;
            }
            setCustomerFilterInfo((draft) => {
              draft.pageNumber = newPageNumber;
            });
          }}
        />
        <div />
      </div>
    );
  };

  const renderGroupTagFilter = () => {
    if (!RD.isSuccess(groupTags) || (!isFiltering && filteredCustomers.length === 0)) return;
    return (
      <Select
        className={styles.groupTagDropDown}
        onChange={(groupTagId) =>
          setCustomerFilterInfo((draft) => {
            const id = parseInt(groupTagId);
            if (isNaN(id)) return;
            draft.selectedGroupTagId = id;
          })
        }
        selectedValue={`${customerFilterInfo.selectedGroupTagId}`}
        values={[{ value: '-1', label: 'All Group Tags' }].concat(
          groupTags.data.map((tag) => {
            return { value: `${tag.id}`, label: tag.name };
          }),
        )}
      />
    );
  };

  const renderTab = (level: HierarchyLevel) => {
    const customerCount =
      level.id === -1
        ? totalCustomerCount
        : hierarchyLevelCounts.find((l) => l.hierarchy_level_id === level.id)?.count ?? 0;
    return (
      <div
        className={
          level.id === customerFilterInfo.selectedHierarchyLevelId
            ? styles.activeTab
            : styles.inactiveTab
        }
        key={level.id}
        onClick={() =>
          setCustomerFilterInfo((draft) => {
            draft.selectedHierarchyLevelId = level.id;
            draft.pageNumber = 1;
          })
        }>
        <div className={sprinkles({ truncateText: 'ellipsis' })}>{level.name}</div>
        {`(${customerCount})`}
      </div>
    );
  };

  const renderBanner = () => {
    return (
      <div className={styles.bannerContainer}>
        <div className={styles.banner}>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp2' })}>
            <Icon className={sprinkles({ color: 'active' })} name="gear-reg" />
            Notice to Explo App Users: Explo has launched a customer hierarchy. By default, we have
            migrated your customers to the top level within the hierarchy. This DOES NOT have an
            effect on any of your current dashboards. If you are interested in utilizing
            parent-child relationships with your customers, please reach out to Explo support in
            Slack or via support@explo.co.
          </div>
          <Icon
            className={sprinkles({ color: 'contentSecondary', cursor: 'pointer' })}
            name="cross"
            onClick={() => dispatch(updateHasSeenHierarchyBanner({ postData: {} }))}
          />
        </div>
      </div>
    );
  };

  const renderTabSection = () => {
    if (!isFiltering && filteredCustomers.length === 0) return;
    const dummyLevel = { name: 'View All', id: -1, level: -1 };
    const reversedLevels = [...hierarchyLevels, dummyLevel].reverse();
    return (
      <div className={styles.tabSectionOuterContainer}>
        <div className={styles.tabSectionInnerContainer}>
          <div className={styles.tabGroup}>{reversedLevels.map((level) => renderTab(level))}</div>

          {renderGroupTagFilter()}
        </div>
      </div>
    );
  };

  const renderParentTags = () => {
    const parentList = customerFilterInfo.selectedParentProvidedIds;
    if (parentList.length === 0) return;
    return (
      <div className={styles.parentFilterTag}>
        {parentList.map((parent, index) => (
          <Tag
            closeIconStyle={sprinkles({ justifyContent: 'flex-end' })}
            intent="active"
            key={`parent-tag-${index}`}
            leftIcon="list-tree"
            onClose={() => {
              setCustomerFilterInfo((draft) => {
                draft.selectedParentProvidedIds.pop();
                draft.pageNumber = 1;
                draft.selectedHierarchyLevelId = -1;
                draft.searchString = '';
              });
            }}>
            <div className={sprinkles({ flexItems: 'alignCenter', body: 'b3', gap: 'sp.5' })}>
              {parent.levelName}: <div className={styles.tagBoldText}>{parent.name}</div>
            </div>
          </Tag>
        ))}
      </div>
    );
  };

  const isLoading =
    RD.isLoading(filteredCustomerData) ||
    RD.isLoading(endUsers) ||
    RD.isLoading(groupTags) ||
    !teamData;

  return (
    <div className={styles.root}>
      {renderHeader()}
      {!hasSeenHierarchyBanner ? renderBanner() : null}
      {renderTabSection()}
      {renderParentTags()}

      <div className={styles.scroll}>
        <div className={styles.content}>
          {isLoading ? (
            <ExploLoadingSpinner />
          ) : (
            <>
              <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>{renderList()}</div>
              {renderPager()}
            </>
          )}
        </div>
        {renderAddGroupModal()}
        {renderEditModal()}
        {renderDeleteCustomerConfirmModal()}
      </div>
    </div>
  );
};

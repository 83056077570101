import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { SettingHeader } from 'components/SettingHeader';
import { SharedLegendConfigs } from 'components/ChartConfigs/LegendConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  LegendFormat,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions | V2BoxPlotInstructions;
};

export const LegendConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const { legendFormat } = instructions;

  const updateLegendFormat = (legendUpdates: LegendFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.legendFormat = {
        ...draft.legendFormat,
        ...legendUpdates,
      };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <>
      <SettingHeader
        name="Legend"
        switchProps={{
          isOn: !legendFormat?.hideLegend,
          onChange: () => updateLegendFormat({ hideLegend: !legendFormat?.hideLegend }),
        }}
      />
      {!legendFormat?.hideLegend ? (
        <div className={configRootClass}>
          <SharedLegendConfigs
            configInputClass={configInputClass}
            instructions={instructions}
            operationType={visualizationType}
            updateLegendFormat={updateLegendFormat}
          />
        </div>
      ) : null}
    </>
  );
};

import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { SettingHeader } from 'components/SettingHeader';
import DroppableColumnSection from './droppable/DroppableColumnSection';
import { Input, sprinkles } from 'components/ds';

import { VisualizeGeospatialChartInstructions, OPERATION_TYPES } from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import { showErrorToast } from 'shared/sharedToasts';
import { FLOAT } from 'constants/dataConstants';

const MAX_ROW_LIMIT = 5000;
const DEFAULT_ROW_LIMIT = 500;

type Props = {
  instructions: VisualizeGeospatialChartInstructions;
  schema: DatasetSchema;
};

export const GeospatialVizConfig: FC<Props> = ({ instructions, schema }) => {
  const dispatch = useDispatch();

  const updateOperation = useCallback(
    (updates) => {
      const newInstructions = produce(instructions, (draft) => ({
        ...draft,
        ...updates,
      }));
      dispatch(
        updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP),
      );
    },
    [dispatch, instructions],
  );

  return (
    <div>
      <SettingHeader name="Geospatial Data" />

      <DroppableColumnSection
        required
        allowedTypes={[FLOAT]}
        columns={instructions.latitudeColumn ? [{ column: instructions.latitudeColumn }] : []}
        label="Latitude"
        maxCols={1}
        onColAdded={(col) => updateOperation({ latitudeColumn: col })}
        onRemoveCol={() => updateOperation({ latitudeColumn: undefined })}
        schema={schema}
      />
      <DroppableColumnSection
        required
        allowedTypes={[FLOAT]}
        columns={instructions.longitudeColumn ? [{ column: instructions.longitudeColumn }] : []}
        label="Longitude"
        maxCols={1}
        onColAdded={(col) => updateOperation({ longitudeColumn: col })}
        onRemoveCol={() => updateOperation({ longitudeColumn: undefined })}
        schema={schema}
      />
      <Input
        className={sprinkles({ paddingX: 'sp1', paddingY: 'sp.5' })}
        defaultValue={(instructions.rowLimit ?? DEFAULT_ROW_LIMIT).toString()}
        label={{
          text: 'Row Limit',
          infoText:
            'This value controls the maximum number of markers displayed on the map, which impacts map performance. It cannot be higher than 5000.',
        }}
        onSubmit={(value) => {
          const parsedVal = parseInt(value);
          const newRowLimit = isNaN(parsedVal) ? undefined : parsedVal;
          if (newRowLimit && newRowLimit > MAX_ROW_LIMIT) {
            showErrorToast(
              `Selected row limit exceeds maximum row limit of ${MAX_ROW_LIMIT}. Consider applying filters to visualize the desired data.`,
            );
            return;
          }
          updateOperation({ rowLimit: newRowLimit });
        }}
      />
      <SettingHeader name="Tooltip Data" />
      <DroppableColumnSection
        columns={
          instructions.tooltipColumns !== undefined
            ? instructions.tooltipColumns.map((col) => ({
                column: col,
              }))
            : []
        }
        onColAdded={(col, oldCol) => {
          // If oldCol is not defined, we are adding a new column
          if (oldCol === undefined || !instructions.tooltipColumns)
            updateOperation({ tooltipColumns: [...(instructions.tooltipColumns ?? []), col] });
          else {
            const newInstructions = produce(instructions, (draft) => {
              if (draft.tooltipColumns === undefined) return;
              const oldIndex = draft.tooltipColumns.findIndex((column) => column.name === oldCol);
              draft.tooltipColumns.splice(oldIndex, 1, col);
            });
            dispatch(
              updateVisualizeOperation(
                newInstructions,
                OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP,
              ),
            );
          }
        }}
        onRemoveCol={(col) => {
          if (!instructions.tooltipColumns) return;
          const updatedTooltipList = instructions.tooltipColumns?.filter(
            (column) => col.name !== column.name,
          );
          updateOperation({ tooltipColumns: updatedTooltipList });
        }}
        schema={schema}
      />
    </div>
  );
};

import { FC, ReactNode } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Icon, sprinkles } from 'components/ds';

import { CustomerReportVisualization } from 'actions/customerReportActions';
import { VIZ_TO_SECTION } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';

type Props = {
  operationType: CustomerReportVisualization;
  children: ReactNode;
};

export const InvalidData: FC<Props> = ({ operationType, children }) => {
  return (
    <div
      className={sprinkles({
        parentContainer: 'fill',
        flexItems: 'centerColumn',
        gap: 'sp.5',
        color: 'contentTertiary',
      })}>
      <Icon name="circle-exclamation" size="lg" />
      <EmbedText body="b1">
        Data not valid for {VIZ_TO_SECTION[operationType || OPERATION_TYPES.VISUALIZE_TABLE]}
      </EmbedText>
      <EmbedText body="b2">{children}</EmbedText>
    </div>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { isSuccess } from 'remotedata';

import { createErrorAction, createSuccessAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { getCurrentView } from 'utils/customerReportUtils';
import { OPERATION_TYPES, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { Metadata } from 'utils/analyticsUtils';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { getViewRequestParams } from './utils';
import { enqueueReportBuilderJobsThunk } from './jobThunks';
import { createJob } from 'components/JobQueue/createJob';
import { ExportCustomerReportBody } from '../apiTypes';
import { SelectedReportType, ReportType } from 'reportBuilderContent/reducers/types';
import { downloadReportBuilderComputationSpreadsheet } from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import { ExportSpreadsheetType } from 'actions/exportActions';
import { useFidoForRequest } from 'utils/fido/fidoUtils';

export const exportReport = createAsyncThunk<
  Record<string, Jobs>,
  { selectedReport: SelectedReportType; reportName: string; exportType: string },
  { state: ReportBuilderReduxState }
>(
  ACTION.EXPORT_CUSTOMER_REPORT,
  async ({ selectedReport, reportName, exportType }, { dispatch, getState }) => {
    const { embeddedReportBuilder, reportEditing, fido } = getState();
    const { requestInfo, reportBuilderVersion, variables, reportBuilder, team } =
      embeddedReportBuilder;
    const { currentConfig, currentView } = reportEditing;

    const view = getCurrentView(currentConfig?.views, currentView);
    const dataset =
      reportBuilderVersion && currentConfig?.dataInfo
        ? reportBuilderVersion.config.datasets[currentConfig.dataInfo.datasetId]
        : undefined;

    if (!view || !dataset) throw new Error('Export report missing view or dataset');

    const reportId = String(selectedReport.id);
    const jobArgs: ExportCustomerReportBody = {
      ...getViewRequestParams(view, dataset),
      embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
      file_name: reportName,
      folder_name: reportId,
      export_type: exportType,
      variables,
      group_bys: view.groupBys,
      totals: view.totals,
      col_group_bys: view.columnGroupBys,
      visualization: view.visualization ?? OPERATION_TYPES.VISUALIZE_TABLE,
    } as const;

    if (
      useFidoForRequest(
        {
          type: 'embedded',
          useFido: team?.feature_flags.use_fido,
          parentSchemaDataSourceMapping: {},
        },
        fido,
        dataset,
      )
    ) {
      return dispatch(
        downloadReportBuilderComputationSpreadsheet({
          computationBody: jobArgs,
          dataset,
          fileFormat: exportType as ExportSpreadsheetType,
          fileName: reportName,
        }),
      );
    }
    const jobs = createJob({
      job_type: ACTION.EXPORT_CUSTOMER_REPORT,
      job_args: jobArgs,
    });
    dispatch(enqueueReportBuilderJobsThunk(jobs));
    const hubId = isSuccess(reportBuilder) ? reportBuilder.data.id : undefined;
    const metadata: Metadata = { hub_id: hubId, view_name: view.name };
    if (selectedReport.type === ReportType.BUILT_IN) metadata['built_in_id'] = selectedReport.id;
    else if (selectedReport.type === ReportType.CUSTOMER_REPORT)
      metadata['customer_report_id'] = selectedReport.id;

    dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.REPORT_EXPORTED, metadata));
    return Promise.resolve({});
  },
);

export const exportCustomerReportSuccess = createSuccessAction<{ export_url: string }>(
  ACTION.EXPORT_CUSTOMER_REPORT,
);
export const exportCustomerReportError = createErrorAction(ACTION.EXPORT_CUSTOMER_REPORT);

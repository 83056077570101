import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | undefined;
};

export const CategoryDrilldownConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  return (
    <ConfigSubSectionWithSwitch
      label="Drilldown"
      onChange={() => {
        const newInstructions = produce(instructions ?? {}, (draft) => {
          if (!draft.drilldown) draft.drilldown = {};
          draft.drilldown.categorySelectEnabled = !draft.drilldown.categorySelectEnabled;
        });

        dispatch(updateVisualizeOperation(newInstructions, visualizationType));
      }}
      switchOn={!!instructions?.drilldown?.categorySelectEnabled}
    />
  );
};

import { FC } from 'react';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type Props = {
  text: string;
};

export const StepHeader: FC<Props> = ({ text }) => {
  return (
    <div
      className={cx(
        sprinkles({ fontWeight: 600, paddingY: 'sp2.5', paddingLeft: 'sp2.5' }),
        embedSprinkles({ heading: 'h2' }),
      )}>
      {text}
    </div>
  );
};

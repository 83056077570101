import { Customer, EmbedCustomer } from 'actions/teamActions';
import { partition } from 'lodash';

export function getCustomerVariables(
  customer: Customer | EmbedCustomer,
  archetypePropertyNames: Set<string>,
): Record<string, string> {
  const computedProps = customer.computed_properties;
  const customerProperties: Record<string, string> = {};

  // Separate archetype properties from the other computed properties
  const [archetypeProps, customProps] = partition(Object.keys(computedProps), (propKey) =>
    archetypePropertyNames.has(propKey),
  );

  // Add in archetype properties
  if (archetypeProps) {
    archetypeProps.forEach((archetypeName) => {
      if (archetypeName in computedProps)
        customerProperties[archetypeName] = computedProps[archetypeName];
    });
  }

  // Add in the custom properties
  if (customProps) {
    customProps.forEach((key) => {
      const val = computedProps[key];
      customerProperties[`customer.${key}`] = val;
      customerProperties[`user_group.${key}`] = val;
      customerProperties[`properties.${key}`] = val;
    });
  }

  return customerProperties;
}

export const doesCustomerHaveInvalidAccessGroup = (
  customer: Customer,
  accessGroupIds: Set<number>,
) => !accessGroupIds.has(customer.access_group_id);

import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';

import {
  AtLeastOneAlert,
  NoDataAlert,
  RemovedDataAlert,
} from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { IconName } from 'components/ds/Icon';
import { DataPanelList } from './DataPanelList';
import { AddColumnMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddColumnMenu';
import { GroupByListItem } from 'pages/ReportBuilder/ReportView/DataPanel/GroupByListItem';

import { VIZ_TO_SECTION } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';
import {
  clearPrevGroupBys,
  getCurrentViewRemovedGroupBys,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DraggableColumnInfo, getGroupByUniqueId, BucketsByCol } from 'utils/customerReportUtils';
import { ROWS_SECTION_ID, DataPanelData } from './constants';
import { CustomerReportGroupBy, CustomerReportVisualization } from 'actions/customerReportActions';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { isLineOrBarVisualization } from 'pages/ReportBuilder/utils/visualizationUtils';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';

type Props = {
  bucketsByCol: BucketsByCol;
  columnConfigs?: ReportBuilderColConfigs;
  groupBys: CustomerReportGroupBy[];
  disabled?: boolean;
  operationType: CustomerReportVisualization;
  max?: number;
  columns: DraggableColumnInfo[];
  onAddColumn: (column: DraggableColumnInfo) => void;
};

export const GroupBySection: FC<Props> = ({
  bucketsByCol,
  columnConfigs,
  groupBys,
  disabled,
  operationType,
  max,
  columns,
  onAddColumn,
}) => {
  const dispatch = useDispatch();
  const removedGroupBys = useSelector((state: ReportBuilderReduxState) =>
    getCurrentViewRemovedGroupBys(state.reportEditing),
  );

  const { active, over, isOver, setNodeRef } = useDroppable(DROPPABLE_ARGS);

  const selectedColumns = useMemo(() => groupBys.map((groupBy) => groupBy.column), [groupBys]);

  const sortableIds = useMemo(
    () => groupBys.map((item) => `${ROWS_SECTION_ID}-${getGroupByUniqueId(item)}`),
    [groupBys],
  );

  const isOverContainer = isOverSection(ROWS_SECTION_ID, isOver, over, sortableIds);
  const isOverSelf = active?.data.current?.section === ROWS_SECTION_ID;

  const visualizationName = VIZ_TO_SECTION[operationType || OPERATION_TYPES.VISUALIZE_TABLE];
  const numGroupBys = groupBys.length;
  const numRemovedGroupBys = removedGroupBys ? removedGroupBys.length - numGroupBys : 0;
  const hasMax = max != null && groupBys.length >= max;
  const isPopoverOpen = isOverContainer && hasMax && !isOverSelf;
  const maxText = `${visualizationName} can have up to ${max} groupings`;

  const { title, icon } = getGroupBysTitleAndIcon(operationType);

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={ROWS_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon={icon}
        popoverChildren={isPopoverOpen ? maxText : undefined}
        title={title}>
        <AddColumnMenu
          columnConfigs={columnConfigs}
          columns={columns}
          disabled={disabled}
          onAddColumn={onAddColumn}
          selectedColumns={selectedColumns}
          tooltipText={hasMax ? maxText : undefined}
        />
      </DataPanelSubHeader>

      {numRemovedGroupBys > 0 ? (
        <RemovedDataAlert
          name="grouping"
          numRemoved={numRemovedGroupBys}
          onDismiss={() => dispatch(clearPrevGroupBys())}
          visualizationName={visualizationName}
        />
      ) : null}

      {operationType !== OPERATION_TYPES.VISUALIZE_TABLE && groupBys.length === 0 ? (
        <AtLeastOneAlert name="grouping" visualizationName={visualizationName} />
      ) : null}

      {groupBys.length === 0 ? <NoDataAlert name="grouping" /> : null}

      {groupBys.map((groupBy) => (
        <GroupByListItem
          bucketsByCol={bucketsByCol}
          columnConfigs={columnConfigs}
          groupBy={groupBy}
          key={getGroupByUniqueId(groupBy)}
          section={ROWS_SECTION_ID}
        />
      ))}
    </DataPanelList>
  );
};

function getGroupBysTitleAndIcon(operationType: CustomerReportVisualization): {
  title: string;
  icon: IconName;
} {
  return isLineOrBarVisualization(operationType)
    ? { title: 'X-Axis Grouping', icon: 'right-long' }
    : { title: 'Group by', icon: 'table-rows' };
}

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: ROWS_SECTION_ID,
  data: { id: ROWS_SECTION_ID, section: ROWS_SECTION_ID } as DataPanelData,
};

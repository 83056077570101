import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isIdle, isLoading, isSuccess } from 'remotedata';
import { v4 as uuidv4 } from 'uuid';

import { EmbedModalHeader, EmbedModal } from 'components/embed';
import { ExportScheduler } from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ExportScheduler';
import { ScheduleManager } from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ScheduleManager';

import { bulkEnqueueJobs } from 'actions/jobQueueActions';
import {
  createReportBuilderEmailCadence,
  deleteReportBuilderEmailCadence,
  listReportBuilderEmailCadences,
  updateReportBuilderEmailCadence,
} from 'actions/reportBuilderEmailCadenceActions';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { EmailBody, ExportBody } from 'types/emailCadence';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { ReportType } from 'reportBuilderContent/reducers/types';

type Props = { reportName: string; isIframe: boolean };

export const ScheduleExportModal: FC<Props> = ({ reportName, isIframe }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState(ModalView.MANAGE);
  const [editingEmailId, setEditingEmailId] = useState<string | null>(null);

  const { requestInfo, selectedReport, reportBuilder, emailCadences } = useSelector(
    (state: ReportBuilderReduxState) => ({
      requestInfo: state.embeddedReportBuilder.requestInfo,
      reportBuilder: state.embeddedReportBuilder.reportBuilder,
      emailCadences: state.reportEditing.emailCadences,
      selectedReport: state.reportEditing.selectedReport,
    }),
    shallowEqual,
  );
  const { customerToken, embedId, embedJwt: jwt, envTagId } = requestInfo;

  const emails = isSuccess(emailCadences) ? emailCadences.data : [];

  // Should only be opened for customer reports and built ins
  const reportIdParam = useMemo(() => {
    if (selectedReport?.type === ReportType.CUSTOMER_REPORT) {
      return { customer_report_id: selectedReport.id, environment_tag_id: envTagId };
    } else if (selectedReport?.type === ReportType.BUILT_IN) {
      return { built_in_id: selectedReport.id, environment_tag_id: envTagId };
    }
  }, [selectedReport, envTagId]);

  const reportBuilderId = isSuccess(reportBuilder) && reportBuilder.data.id;
  const isEmailCadencesIdle = isIdle(emailCadences);

  useEffect(() => {
    if (!isEmailCadencesIdle || !reportIdParam || !embedId) return;
    dispatch(
      listReportBuilderEmailCadences({
        customerToken,
        jwt,
        postData: { resource_embed_id: embedId, ...reportIdParam },
      }),
    );
  }, [customerToken, jwt, dispatch, isEmailCadencesIdle, embedId, reportIdParam, reportBuilderId]);

  const createExportCadence = useCallback(
    (email: EmailBody, onSuccess: () => void, onFailure: () => void) => {
      if (!reportBuilderId || !reportIdParam || !reportBuilderId) return;

      dispatch(
        createReportBuilderEmailCadence(
          {
            customerToken,
            jwt,
            postData: { email, resource_embed_id: reportBuilderId, ...reportIdParam },
          },
          () => {
            onSuccess();
            dispatch(
              sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.REPORT_CADENCE_CREATED, {
                hub_id: reportBuilderId,
                ...reportIdParam,
              }),
            );
          },
          onFailure,
        ),
      );
    },
    [customerToken, jwt, reportIdParam, dispatch, reportBuilderId],
  );

  const updateExportCadence = useCallback(
    (emailId: string, email: EmailBody, onSuccess: () => void, onFailure: () => void) =>
      dispatch(
        updateReportBuilderEmailCadence(
          { customerToken, jwt, postData: { email, email_id: emailId } },
          onSuccess,
          onFailure,
        ),
      ),
    [customerToken, jwt, dispatch],
  );

  const deleteExportCadence = useCallback(
    (emailId: string) =>
      dispatch(
        deleteReportBuilderEmailCadence({ customerToken, jwt, postData: { email_id: emailId } }),
      ),
    [customerToken, jwt, dispatch],
  );

  const sendTestExport = useCallback(
    (emailId: string, onSuccess: (jobs: Record<string, Jobs>) => void) => {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL,
          job_args: { report_builder_email_cadence_id: emailId, is_test_email: true },
        },
      };
      dispatch(bulkEnqueueJobs({ jobs, customerToken, jwt }, onSuccess));
    },
    [customerToken, jwt, dispatch],
  );

  const sendTestDraftExport = useCallback(
    (body: ExportBody, onSuccess: (jobs: Record<string, Jobs>) => void) => {
      if (!reportIdParam) return;
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS,
          job_args: {
            ...body,
            customer_token: customerToken,
            report_builder_id: reportBuilderId,
            ...reportIdParam,
          },
        },
      };

      dispatch(bulkEnqueueJobs({ jobs, customerToken, jwt }, onSuccess));
    },
    [customerToken, jwt, dispatch, reportIdParam, reportBuilderId],
  );

  const loading = isLoading(emailCadences);
  useEffect(() => {
    if (!loading && emails.length > 0) setView(ModalView.MANAGE);
  }, [emails.length, loading]);

  const closeModal = () => dispatch(closeReportModal());
  const modalTitle =
    view === ModalView.MANAGE
      ? 'Manage exports'
      : editingEmailId
      ? 'Edit export'
      : 'Schedule a new export';

  const renderBody = () => {
    if (view === ModalView.SCHEDULE) {
      const editingEmail = editingEmailId
        ? emails.find((email) => email.id === editingEmailId)
        : undefined;

      return (
        <ExportScheduler
          createExportCadence={createExportCadence}
          editingEmail={editingEmail}
          goToManage={() => {
            setView(ModalView.MANAGE);
            setEditingEmailId(null);
          }}
          resourceName={reportName}
          sendTestDraftExport={sendTestDraftExport}
          updateExportCadence={updateExportCadence}
        />
      );
    }

    return (
      <ScheduleManager
        closeModal={closeModal}
        deleteEmail={deleteExportCadence}
        editEmail={(emailId) => {
          setView(ModalView.SCHEDULE);
          setEditingEmailId(emailId);
        }}
        emails={emails}
        loading={loading}
        openScheduler={() => setView(ModalView.SCHEDULE)}
        sendTestExport={sendTestExport}
      />
    );
  };

  return (
    <EmbedModal isOpen isIframe={isIframe} onClose={closeModal} size="xlarge">
      <EmbedModalHeader
        onBack={
          view === ModalView.SCHEDULE
            ? () => {
                setView(ModalView.MANAGE);
                setEditingEmailId(null);
              }
            : undefined
        }
        title={modalTitle}
      />
      {renderBody()}
    </EmbedModal>
  );
};

enum ModalView {
  SCHEDULE,
  MANAGE,
}

import { FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Color from 'color';
import { isLoading, isSuccess } from 'remotedata';

import { StepFooter } from '../StepFooter';
import { StepHeader } from '../StepHeader';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';

import { GlobalStylesContext } from 'globalStyles';
import { CSV_ICON, PDF_ICON } from 'constants/iconConstants';
import { DashboardStates } from 'reducers/rootReducer';
import { ExportSpreadsheetType, ExportType } from 'actions/exportActions';
import { clearDownloads } from 'reducers/dashboardLayoutReducer';

type Props = {
  dataPanelId: string;
  onBack: () => void;
  onDownloadPanelSpreadsheet: (fileFormat: ExportSpreadsheetType) => void;
  onDownloadPanelPdf: () => void;
  onNext: () => void;
};

export const ExportStep: FC<Props> = ({
  dataPanelId,
  onBack,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  onNext,
}) => {
  const dispatch = useDispatch();
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  const dpDownload = useSelector(
    (state: DashboardStates) => state.dashboardLayout.dpDownloads[dataPanelId],
  );

  useEffect(() => {
    return () => {
      dispatch(clearDownloads(dataPanelId));
    };
  }, [dispatch, dataPanelId]);

  useEffect(() => {
    if (dpDownload?.type === 'url' && isSuccess(dpDownload.status))
      window.open(dpDownload.status.data);
  }, [dpDownload]);

  const containerBgDark = Color(globalStyleConfig.container.fill).isDark();

  const isDownloading = isLoading(dpDownload?.status);

  return (
    <div
      className={cx(
        sprinkles({ height: 'fill', flexItems: 'column' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <StepHeader text="Export" />
      <div className={rootClass}>
        <ExportOption
          containerBgDark={containerBgDark}
          disabled={isDownloading}
          icon={CSV_ICON(globalStyleConfig.text.secondaryColor, { height: 50, width: 50 })}
          loading={isDownloading && dpDownload?.exportType === ExportType.CSV}
          onDownloadClicked={() => onDownloadPanelSpreadsheet(ExportType.CSV)}
          primaryText="Export as CSV"
          secondaryText="Great for exporting large amounts of data, and allows for easy data re-upload or analysis."
        />
        <ExportOption
          containerBgDark={containerBgDark}
          disabled={isDownloading}
          icon={CSV_ICON(globalStyleConfig.text.secondaryColor, { height: 50, width: 50 })}
          loading={isDownloading && dpDownload?.exportType === ExportType.XLSX}
          onDownloadClicked={() => onDownloadPanelSpreadsheet(ExportType.XLSX)}
          primaryText="Export as XLSX"
          secondaryText="Great for exporting large amounts of data, and allows for easy data re-upload or analysis."
        />
        <ExportOption
          containerBgDark={containerBgDark}
          disabled={isDownloading}
          icon={PDF_ICON(globalStyleConfig.text.secondaryColor, { height: 50, width: 50 })}
          loading={isDownloading && dpDownload?.exportType === ExportType.PDF}
          onDownloadClicked={onDownloadPanelPdf}
          primaryText="Export as PDF"
          secondaryText="Renders a maximum of 200 rows. Great for sharing data in a readable format."
        />
      </div>
      <StepFooter secondary onBack={onBack} onNext={onNext} text="Reset" />
    </div>
  );
};

const rootClass = sprinkles({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: 'sp2',
  overflow: 'auto',
  flex: 1,
});

type ExportOptionProps = {
  disabled: boolean;
  icon: JSX.Element;
  loading: boolean;
  primaryText: string;
  secondaryText: string;
  containerBgDark: boolean;

  onDownloadClicked: () => void;
};

const ExportOption: FC<ExportOptionProps> = ({
  disabled,
  icon,
  loading,
  onDownloadClicked,
  primaryText,
  secondaryText,
  containerBgDark,
}) => {
  return (
    <div
      className={cx(
        optionClass,
        sprinkles({
          backgroundColor: containerBgDark ? 'gray12' : 'gray1',
          color: containerBgDark ? 'white' : undefined,
        }),
        embedSprinkles({ body: 'primaryWithoutColor' }),
      )}>
      {icon}
      <div className={sprinkles({ marginTop: 'sp3', marginBottom: 'sp.5', heading: 'h3' })}>
        {primaryText}
      </div>
      <div className={sprinkles({ marginTop: 'sp.5', marginBottom: 'sp2', body: 'b2' })}>
        {secondaryText}
      </div>
      <EmbedButton
        fillWidth
        disabled={disabled}
        icon="download"
        loading={loading}
        onClick={onDownloadClicked}
        variant="primary">
        Download
      </EmbedButton>
    </div>
  );
};

const optionClass = sprinkles({
  flexItems: 'centerColumn',
  paddingY: 'sp3',
  paddingX: 'sp2',
  marginX: 'sp2.5',
  textAlign: 'center',
});

import { FC } from 'react';

import { Intent, Tag, IconButton } from 'components/ds';
import * as styles from 'pages/ReportBuilderEditor/DatasetEditor/DatasetItem/index.css';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { CustomerPermissionsSummary } from 'components/CustomerPermissions/Summary';

type Props = {
  dataset: ReportBuilderDataset;
  isSelected: boolean;

  onClick: () => void;
};

export const DatasetItem: FC<Props> = ({ dataset, isSelected, onClick }) => {
  return (
    <div className={isSelected ? styles.selectedDataset : styles.dataset} onClick={onClick}>
      <div className={styles.datasetHeading}>
        <div className={styles.datasetInfo}>
          <div className={styles.datasetName}>{dataset.name}</div>
          {dataset.queryDraft ? (
            <Tag
              className={styles.tag}
              intent={isSelected ? Intent.ACTIVE : Intent.NONE}
              inverted={isSelected}>
              Draft
            </Tag>
          ) : null}
        </div>
        <IconButton name="chevron-right" />
      </div>
      {dataset.description ? (
        <div className={styles.datasetDescription}>{dataset.description}</div>
      ) : null}
      <CustomerPermissionsSummary permissions={dataset.permissions} />
    </div>
  );
};

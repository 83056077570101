export enum ROUTES {
  HOME = '/',
  EXPLO_HOME = '/business-intelligence',
  ABOUT = '/about-us',
  CAREERS = '/careers',
  LOGIN = '/login',
  SAML_SIGNIN = '/saml',
  SAML_INITIATE_AUTH = '/saml-login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:resetToken',
  SIGNUP = '/signup',
  TELL_US_ABOUT_YOU = '/signup/tell-us-about-yourself',
  CHANGELOG = '/changelog',
  USE_CASES = '/use-cases/:useCaseId',
  INTEGRATIONS = '/integrations/:integrationId',
  CHANGELOG_ENTRY = '/changelog/:entryUrlId',
  CHECK_YOUR_EMAIL = '/check-your-email',
  VERIFY_EMAIL = '/verify-email/:verificationId',
  SETUP = '/setup',
  HOME_APP_PAGE = '/home',
  // temp routes for testing on staging
  HOME_APP_PAGE_TEST = '/home-test',
  HOME_APP_PAGE_FOLDER_TEST = '/home-test/:folderId',
  JOIN_TEAM = '/join-team',
  TEAM = '/team',
  TRIAL_EXPIRED = '/trial-expired',
  CONNECT_DATA_SOURCE = '/connect',
  SWAP_DATA_SOURCE = '/swap-datasource',
  SYNC_DATA_TABLES = '/sync-tables/:passedSchemaId',
  SYNC_DATA_TABLES_NO_SCHEMA = '/sync-tables',
  DASHBOARD_PAGE = '/dashboard/:dashboardId',
  DASHBOARD_PREVIEW_PAGE = '/preview/:embedId',
  DATA_PAGE = '/datasources',
  MANAGE_DATA_TABLES = '/datasources/:dataSourceId',
  SETTINGS_PAGE = '/settings/:subsectionId',
  SETTINGS_ACCESS_GROUPS = '/settings/api-tokens',
  BILLING_PAGE = '/settings/billing',
  GLOBAL_CUSTOM_STYLES_PAGE = '/styles',
  CUSTOMERS_PAGE = '/customers',
  SHARED_DASHBOARD = '/share/:shareId',
  END_USER_PORTAL = '/portal/:token?',
  SHARED_DASHBOARD_STRICT = '/share/:shareId/strict',
  SHARE_CHART = '/share/:shareId/chart/:dataPanelId',
  PDF_DASHBOARD = '/pdf/:shareId',
  EMAIL_DASHBOARD = '/email/:shareId',
  IFRAME = '/iframe/:firstParam/:secondParam?/:thirdParam?',
  NOTION_ANALYTICS = '/notion-analytics/:pageName',
  EMAIL_FORM_SUBMISSION_REDIRECT = '/email-form-submission/:source/:email',
  ANALYTICS = '/analytics',
  SUPERUSER = '/superuser',
  REPORT_BUILDER = '/report-builder',
  // temp routes for testing on staging
  REPORT_BUILDER_TEST = '/report-builder-test',
  REPORT_BUILDER_FOLDER_TEST = '/report-builder-test/:folderId',
  REPORT_BUILDER_EDITOR = '/report-builder/:reportBuilderId/:view',
  REPORT_BUILDER_EXPORT = '/report-builder/export/:reportBuilderEmbedId/:customerToken?',
  REPORT_BUILDER_IFRAME = '/report-builder/iframe/:firstParam/:secondParam?/:thirdParam?',
  UNSUBSCRIBE_REPORT_BUILDER_EMAIL = '/report-builder/unsubscribe/:emailId/:email',
}

import { createReducer } from '@reduxjs/toolkit';

import { loginGoogleSuccess, setSignupInfoCollectionStep } from 'actions/authAction';
import { setTestimonialIndex } from 'actions/onboardingActions';
import { logInUserSuccess } from 'actions/userActions';
import { SIGNUP_INFO_NEXT } from 'constants/onboardingConstants';

interface OnboardingReducer {
  signupInfoCollectionStep: SIGNUP_INFO_NEXT | null;
  testimonialIndex: number | null;
}

const onboardingReducerInitialState: OnboardingReducer = {
  signupInfoCollectionStep: null,
  testimonialIndex: null,
};

export default createReducer(onboardingReducerInitialState, (builder) => {
  builder
    //Initializing
    .addCase(logInUserSuccess, (state, { payload }) => {
      state.signupInfoCollectionStep = payload.signup_info_collection_step;
    })
    .addCase(loginGoogleSuccess, (state, { payload }) => {
      state.signupInfoCollectionStep = payload.user.signup_info_collection_step;
    })
    //Updating
    .addCase(setSignupInfoCollectionStep, (state, { payload }) => {
      state.signupInfoCollectionStep = payload;
    })
    .addCase(setTestimonialIndex, (state, { payload }) => {
      state.testimonialIndex = payload;
    })
    .addDefaultCase((state) => state);
});

import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { sprinkles } from 'components/ds';
import { SortOrderToggle } from 'components/SortDirectionToggles';
import { SettingHeader } from 'components/SettingHeader';

import { EmptyV2PivotInstructions, V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES } from 'constants/types';

type Props = { instructions: V2PivotTableInstructions | undefined };

export const V2PivotTableConfig: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader name="Rows Sorting" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <SortOrderToggle
          order={instructions?.rowSortOrder}
          updateOrder={(newOrder) => {
            const newInstructions = produce(instructions ?? EmptyV2PivotInstructions, (draft) => {
              draft.rowSortOrder = newOrder;
            });

            dispatch(
              updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2),
            );
          }}
        />
      </div>
    </>
  );
};

import { FC, useMemo } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import DropdownSelect from 'shared/DropdownSelect';
import { Checkbox } from 'components/ds';
import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import {
  OPERATION_TYPES,
  TableDrilldownConfig as TableDrilldownConfigType,
  VisualizeTableInstructions,
} from 'constants/types';

type Props = {
  instructions: VisualizeTableInstructions;
  schema: DatasetSchema | undefined;
};

export const TableDrilldownConfig: FC<Props> = ({ instructions, schema }) => {
  const dispatch = useDispatch();

  const updateDrilldownConfig = (updates: TableDrilldownConfigType) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.drilldownConfig = { ...draft.drilldownConfig, ...updates };
    });

    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_TABLE));
  };

  const config = instructions.drilldownConfig;
  const drilldownEnabled = config?.drilldownEnabled;

  const colOptions = useMemo(() => {
    if (!drilldownEnabled || !schema) return [];
    return schema.map((col) => ({ id: col.name, name: col.friendly_name || col.name }));
  }, [schema, drilldownEnabled]);

  const renderSelectCol = () => {
    if (config?.allColumns) return null;
    const drilldownCol = config?.drilldownColumn;
    const selectedItem = drilldownCol
      ? colOptions.find((opt) => opt.id === drilldownCol)
      : undefined;

    return (
      <DropdownSelect
        btnMinimal
        fillWidth
        minimal
        filterable={false}
        label="Column value to select"
        noSelectionText="Column"
        onChange={(item) => updateDrilldownConfig({ drilldownColumn: item.id })}
        options={colOptions}
        selectedItem={selectedItem}
      />
    );
  };

  return (
    <ConfigSubSectionWithSwitch
      label="Drilldown"
      onChange={() => updateDrilldownConfig({ drilldownEnabled: !drilldownEnabled })}
      switchOn={!!drilldownEnabled}>
      <Checkbox
        isChecked={!!config?.allColumns}
        label="Select all columns"
        onChange={() =>
          updateDrilldownConfig({ allColumns: !config?.allColumns, drilldownColumn: undefined })
        }
      />
      {renderSelectCol()}
    </ConfigSubSectionWithSwitch>
  );
};

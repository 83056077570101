import { FC } from 'react';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { StepFooter } from '../StepFooter';
import DataTable from '../../DataTable/DataTable';

import { AdHocOperationInstructions, DataPanelData } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetRow } from 'types/datasets';
import { FilterOperationInstructions, VisualizeTableInstructions } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import { DataPanel } from 'types/exploResource';
import { DataTableHeader } from 'pages/dashboardPage/DashboardDatasetView/Header/DataTableHeader';
import { DatasetDataObject } from 'actions/datasetActions';

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  dataPanel: DataPanel;
  dataPanelData: DataPanelData | undefined;
  isEmbed: boolean;
  loading?: boolean;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  onBack: () => void;
  onNext: () => void;
  reportName?: string;
  schema: DatasetSchema;
  secondaryData: DatasetRow[];
  updateDataTableOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  variables: DashboardVariableMap;
};

export const PreviewStep: FC<Props> = ({
  adHocOperationInstructions,
  dataPanel,
  dataPanelData,
  isEmbed,
  loading,
  onAdHocFilterInfoUpdate,
  onAdHocSortOrPageUpdate,
  onBack,
  onNext,
  reportName,
  schema,
  secondaryData,
  updateDataTableOperation,
  variables,
  datasetData,
  datasetNamesToId,
}) => {
  if (reportName) reportName = replaceVariablesInString(reportName, variables);

  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'column', height: 'fill' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <div
        className={sprinkles({ flex: 1, borderTop: 1, borderColor: 'gray2' })}
        style={{ minHeight: 0 }}>
        <DataTableHeader
          adHocOperationInstructions={adHocOperationInstructions}
          error={!!dataPanelData?.error}
          infoTooltipText="This is a preview of how your data will appear"
          isSchemaCustomizationEnabled={false}
          loading={loading}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          schema={schema}
          title={reportName ?? 'Preview'}
        />
        <DataTable
          dataPanel={dataPanel}
          dataPanelData={dataPanelData}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          isEmbed={isEmbed}
          loading={loading}
          onAdHocSortOrPageUpdate={onAdHocSortOrPageUpdate}
          schema={schema}
          secondaryData={secondaryData}
          updateDataTableOperation={updateDataTableOperation}
          variables={variables}
        />
      </div>
      <StepFooter onBack={onBack} onNext={onNext} text="Export" />
    </div>
  );
};

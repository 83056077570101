import { FC, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Select, Tooltip, sprinkles, Input, Label } from 'components/ds';
import { SettingHeader } from 'components/SettingHeader';
import { showSuccessToast } from 'shared/sharedToasts';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { GlobalStylesContext } from 'globalStyles';

import { V2TwoDimensionChartInstructions } from 'constants/types';
import { BoundaryFormat, BoundaryType } from 'types/maps';
import { saveMapViewState } from 'utils/customEventUtils';
import { MAP_STYLE_TO_MAPBOX_URL, MAP_STYLES_SELECT_VALUES } from 'constants/maps';
import { updateMapFormat } from 'reducers/thunks/mapThunks';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
  dataPanelId: string;
};

const BOUNDARY_TYPES = [
  { label: 'Country', value: BoundaryType.COUNTRY },
  { label: 'US State', value: BoundaryType.STATE },
  { label: 'US Zip Code', value: BoundaryType.ZIP },
];

const BOUNDARY_FORMATS = [
  { label: 'Country Name (United States of America)', value: BoundaryFormat.COUNTRY_NAME },
  { label: 'Country - ISO3 (USA)', value: BoundaryFormat.COUNTRY_CODE_3 },
  { label: 'Country - ISO2 (US)', value: BoundaryFormat.COUNTRY_CODE_2 },
  { label: 'State Name (California)', value: BoundaryFormat.STATE_NAME },
  { label: 'State Code (CA)', value: BoundaryFormat.STATE_CODE },
  { label: 'Zip Code (5 digit)', value: BoundaryFormat.ZIP_CODE },
];

export const ChoroplethMapConfig: FC<Props> = ({ instructions, dataPanelId }) => {
  const dispatch = useDispatch();

  const configStyle = instructions.chartSpecificFormat?.choroplethMap?.style;

  // Load custom styles for default minimum and maximum colors
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const {
    visualizations: { gradientPalette },
  } = globalStyleConfig;

  // For backward compatibility with the previous method of storing map style as the value string
  const selectedMapStyle = useMemo(() => {
    const selectedStyle = Object.entries(MAP_STYLE_TO_MAPBOX_URL).find(
      ([label, style]) => label === configStyle || style === configStyle,
    );
    return selectedStyle ? selectedStyle[0] : undefined;
  }, [configStyle]);

  return (
    <>
      <SettingHeader name="Map Options" />
      <div className={sprinkles({ margin: 'sp1.5', flexItems: 'column', gap: 'sp1.5' })}>
        <Select
          label="Boundary Type"
          onChange={(value) => {
            dispatch(updateMapFormat(dataPanelId, { boundaryType: value as BoundaryType }));
          }}
          selectedValue={instructions.chartSpecificFormat?.choroplethMap?.boundaryType}
          values={BOUNDARY_TYPES}
        />
        <Select
          label="Boundary Format"
          onChange={(value) =>
            dispatch(updateMapFormat(dataPanelId, { boundaryFormat: value as BoundaryFormat }))
          }
          selectedValue={instructions.chartSpecificFormat?.choroplethMap?.boundaryFormat}
          values={BOUNDARY_FORMATS}
        />
        <Select
          label="Map Style"
          onChange={(value) => dispatch(updateMapFormat(dataPanelId, { style: value }))}
          selectedValue={selectedMapStyle}
          values={MAP_STYLES_SELECT_VALUES}
        />
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label htmlFor="">Minimum Color</Label>
          <CustomStylesColorPicker
            fill
            color={
              instructions.chartSpecificFormat?.choroplethMap?.minColor ?? gradientPalette.hue1
            }
            onClose={(newColor) => {
              dispatch(updateMapFormat(dataPanelId, { minColor: newColor }));
            }}
          />
        </div>
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label htmlFor="">Maximum Color</Label>
          <CustomStylesColorPicker
            fill
            color={
              instructions.chartSpecificFormat?.choroplethMap?.maxColor ?? gradientPalette.hue2
            }
            onClose={(newColor) => {
              dispatch(updateMapFormat(dataPanelId, { maxColor: newColor }));
            }}
          />
        </div>
        <Input
          defaultValue={`${instructions.chartSpecificFormat?.choroplethMap?.colorSteps || 8}`}
          label="Color Steps"
          onSubmit={(value) => {
            const intValue = parseInt(value);
            if (isNaN(intValue) || intValue < 1) return;
            dispatch(updateMapFormat(dataPanelId, { colorSteps: intValue }));
          }}
        />

        <Tooltip text="This will save the current map view as the starting point.">
          <Button
            fillWidth
            onClick={() => {
              saveMapViewState();
              showSuccessToast('Saved Map View');
            }}>
            Save Initial View
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

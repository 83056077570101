import { PureComponent } from 'react';
import cx from 'classnames';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { isNumber } from 'utils/standard';

import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ProgressBar } from 'components/ProgressBar';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { ChartMenu } from 'components/ChartMenu';
import { sprinkles, Tooltip, vars } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { EmbedInfoIcon, EmbedSpinner } from 'components/embed';

import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  V2KPIChartInstructions,
  OPERATION_TYPES,
  VisualizeOperationGeneralFormatOptions,
  ColorSettings,
  DEFAULT_NO_DATA_FONT_SIZE,
} from 'constants/types';
import {
  TITLE_VALUE_ARRANGEMENTS,
  VERTICAL_CONTENT_ALIGNMENTS,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
} from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { formatValue, getAxisNumericalValue } from './utils';
import { getCategoricalColors } from 'globalStyles';
import { aggReady } from 'utils/dataPanelConfigUtils';
import { GlobalStyleConfig } from 'globalStyles/types';

const styles = () =>
  createStyles({
    baseContainer: (props: PassedProps) => ({
      paddingLeft: `${props.globalStyleConfig.container.padding.default / 2}px`,
      paddingRight: `${props.globalStyleConfig.container.padding.default / 2}px`,
    }),
    headerContainer: (props: PassedProps) => ({
      paddingBottom:
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT ||
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? `0px`
          : `${props.globalStyleConfig.container.padding.default / 2}px`,
      paddingTop: `${props.globalStyleConfig.container.padding.default / 2}px`,
      display: 'block',
    }),
    bodyContainer: (props: PassedProps) => ({
      paddingTop:
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT ||
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? `0px`
          : `${props.globalStyleConfig.container.padding.default / 2}px`,
      paddingBottom: `${props.globalStyleConfig.container.padding.default / 2}px`,
    }),
  });

type PassedProps = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2KPIChartInstructions;
  dataPanelTemplateId: string;
  schema: DatasetSchema;
  hideChartMenu?: boolean;
  infoTooltipText?: string;
  operationType: OPERATION_TYPES;
  newDataLoading?: boolean;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  processString: (s: string) => string;
  globalStyleConfig: GlobalStyleConfig;
  // If empty, defaults to GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base
  kpiTitleClassName?: string;
  // If empty, defaults to GLOBAL_STYLE_CLASSNAMES.text.kpiValue.base
  kpiValueClassName?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

class BaseSingleNumberChart extends PureComponent<Props, {}> {
  instructionsReadyToDisplay = () => aggReady(this.props.instructions?.aggColumn);

  hasShownTitle = () => {
    const { generalOptions } = this.props;
    return !generalOptions?.headerConfig?.isHeaderHidden && this.getTitle();
  };

  hasFixedTitle = () => {
    const { instructions } = this.props;
    return (
      instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER ||
      instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
    );
  };

  hasShownHeader = () => {
    const { instructions } = this.props;
    return (this.hasShownTitle() || instructions?.generalFormat?.subtitle) && this.hasFixedTitle();
  };

  getBodyStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      height: 'fill',
      flexItems: 'column',
      justifyContent:
        instructions?.generalFormat?.vertical_content_alignment === VERTICAL_CONTENT_ALIGNMENTS.TOP
          ? 'flex-start'
          : instructions?.generalFormat?.vertical_content_alignment ===
            VERTICAL_CONTENT_ALIGNMENTS.BOTTOM
          ? 'flex-end'
          : 'center', //default is center
      textAlign: 'center',
      position: 'relative',
      marginTop: this.hasShownHeader() ? 'sp1' : 'sp0', //default is no padding on top (only used when there is a fixed title)
    });
  };

  getTitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT
          ? 'flex-end'
          : instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
          ? 'flex-start'
          : 'center', // default is center
      alignItems: 'center',
      marginTop:
        instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW
          ? 'sp.5'
          : 'sp0', // default is no padding on top since title is on top by default
      marginBottom: 'sp.5',
    });
  };

  getFixedTitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? 'center'
          : 'flex-start',
      alignItems: 'center',
    });
  };

  getSubtitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      marginTop:
        !this.hasShownTitle() &&
        instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW
          ? 'sp.5' // subtitle only has a margin if title is not showing and the value is on top
          : 'sp0',
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT
          ? 'flex-end'
          : instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
          ? 'flex-start'
          : 'center', // default is center
      alignItems: 'center',
      marginBottom: 'sp.5',
    });
  };

  getFixedSubTitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? 'center'
          : 'flex-start',
      alignItems: 'center',
      marginTop: !this.hasShownTitle() ? 'sp0' : 'sp.5',
    });
  };

  getValueStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT
          ? 'flex-end'
          : instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
          ? 'flex-start'
          : 'center', //default is center
      alignItems: 'center',
      marginTop:
        instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW ||
        this.hasShownHeader()
          ? 'sp0'
          : 'sp.5', //by default assumes the title is present and on top
      marginBottom: 'sp.5',
    });
  };

  render() {
    const { classes, instructions, loading, operationType, generalOptions, hideChartMenu } =
      this.props;

    if (!this.instructionsReadyToDisplay()) {
      return <NeedsConfigurationPanel fullHeight instructionsNeedConfiguration loading={loading} />;
    }

    const hasNoData = this.isNoData();

    const drilldownHeader =
      generalOptions?.enableRawDataDrilldown && !hideChartMenu && !hasNoData
        ? this.renderDrilldownHeader()
        : null;

    const progressBar =
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 && !hasNoData
        ? this.renderProgressBar()
        : null;
    const numberValue = hasNoData ? this.renderEmptyValue() : this.renderNumberValue();
    const sharedClasses = cx(this.getBodyStyling(), classes.baseContainer, classes.bodyContainer);

    if (instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW) {
      return (
        <>
          {drilldownHeader}
          <div className={sharedClasses}>
            {numberValue}
            {this.renderTitle()}
            {this.renderSubtitle()}
            {progressBar}
          </div>
        </>
      );
    }

    if (this.hasFixedTitle()) {
      return (
        <>
          {drilldownHeader}
          <div className={cx(classes.baseContainer, classes.headerContainer)}>
            {this.renderTitle()}
            {this.renderSubtitle()}
          </div>
          <div className={sharedClasses}>
            {numberValue}
            {progressBar}
          </div>
        </>
      );
    }

    // in the default case, position the title above the label and make the title non-fixed
    return (
      <>
        {drilldownHeader}
        <div className={sharedClasses}>
          {this.renderTitle()}
          {this.renderSubtitle()}
          {numberValue}
          {progressBar}
        </div>
      </>
    );
  }

  renderProgressBar = () => {
    const { previewData, schema, instructions } = this.props;
    if (schema?.length === 0 || !previewData) return;

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = this.getProgressValue() ?? value;
    const formattedValue = this.getFormattedNumberValue();

    const tooltipText = `${formattedValue} ${this.getUnits(true) ?? `/ ${formattedValue}`}`;

    return (
      <Tooltip align="center" side="bottom" text={tooltipText}>
        <div>
          <ProgressBar
            className={sprinkles({ marginTop: 'sp1.5' })}
            color={
              instructions?.valueFormat?.progressBarColor ||
              getCategoricalColors(this.props.globalStyleConfig)[0]
            }
            value={value / denominator}
          />
        </div>
      </Tooltip>
    );
  };
  renderTitle = () => {
    const { infoTooltipText, newDataLoading, generalOptions, processString } = this.props;

    if (generalOptions?.headerConfig?.isHeaderHidden) return null;

    return (
      <div
        className={cx(
          this.hasFixedTitle() ? this.getFixedTitleStyling() : this.getTitleStyling(), //have the floating title styling by default
          sprinkles({ fontWeight: 700 }),
          embedSprinkles({ otherText: 'kpiTitle' }),
          this.props.kpiTitleClassName,
        )}>
        <span style={this.getTitleColorStyle()}>{processString(this.getTitle())}</span>
        {infoTooltipText ? <EmbedInfoIcon text={infoTooltipText} /> : null}
        {newDataLoading && (
          <EmbedSpinner className={sprinkles({ marginLeft: 'sp1.5' })} size="md" />
        )}
      </div>
    );
  };

  // if the title is undefined, we then use the aggregation name as the title for the KPI
  getTitle = () => {
    const { instructions, generalOptions } = this.props;

    if (generalOptions?.headerConfig?.title !== undefined) return generalOptions.headerConfig.title;

    const aggColumn = instructions?.aggColumn?.column;

    return aggColumn?.friendly_name || aggColumn?.name || '';
  };

  renderSubtitle = () => {
    const { instructions, processString } = this.props;
    const generalFormat = instructions?.generalFormat;

    if (!generalFormat?.subtitle) return null;

    return (
      <div
        className={cx(
          this.hasFixedTitle() ? this.getFixedSubTitleStyling() : this.getSubtitleStyling(),
          embedSprinkles({ body: 'secondary' }),
        )}>
        {processString(generalFormat.subtitle)}
      </div>
    );
  };

  renderNumberValue = () => {
    const { instructions, generalOptions, loading } = this.props;

    if (loading) return this.renderLoadingState();

    const value = this.getFormattedNumberOrPctValue();
    const imageUrl = instructions?.valueFormat?.imageUrl;
    const linkFormat = generalOptions?.linkFormat;
    const units = this.getUnits();
    const showUnits = units !== undefined && units !== '' && !this.isKPIProgressPctValue();
    const valueClass = this.props.kpiValueClassName ?? embedSprinkles({ otherText: 'kpiValue' });

    return (
      <div className={this.getValueStyling()}>
        {imageUrl ? this.renderImage(imageUrl) : null}
        <span
          className={cx(
            {
              [sprinkles({ fontWeight: 600 })]: instructions?.valueFormat?.bold,
              [sprinkles({ fontStyle: 'italic' })]: instructions?.valueFormat?.italic,
            },
            valueClass,
          )}
          style={this.getValueColorStyle(this.getRawValue())}>
          {value}
        </span>
        {showUnits && (
          <span
            className={cx(valueClass, {
              [sprinkles({ marginLeft: 'sp1' })]: instructions?.valueFormat?.unitPadding,
            })}
            style={this.getGoalUnitsColorStyle()}>
            {units}
          </span>
        )}
        <UrlClickThroughButton linkFormat={linkFormat} />
      </div>
    );
  };

  renderImage = (url: string) => {
    return (
      <img
        alt=""
        className={sprinkles({ marginRight: 'sp1', height: 36, width: 36 })}
        src={this.props.processString(url)}
      />
    );
  };

  renderDrilldownHeader = () => {
    const { operationType, dataPanelTemplateId } = this.props;

    const isProgress = operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2;

    return (
      <div
        className={sprinkles({
          paddingTop: isProgress ? undefined : 'sp2',
          paddingRight: isProgress ? undefined : 'sp2',
          display: 'flex',
          justifyContent: 'flex-end',
          positionAbsolute: 'topRight',
          zIndex: 'base',
          height: 48,
          width: 48,
        })}
        onClick={(e) => e.stopPropagation()}>
        <ChartMenu enableDrilldownModal dataPanelId={dataPanelTemplateId} />
      </div>
    );
  };

  getTitleColorStyle = () => {
    const color = this.props.instructions?.generalFormat?.titleColor;
    return color ? { color } : undefined;
  };

  getValueColorStyle = (rawValue: number) => {
    const { instructions, processString } = this.props;
    const colorFormat = instructions?.colorFormat;

    if (colorFormat?.colorSettingType === ColorSettings.CONSTANT && colorFormat.constantColor) {
      return { color: colorFormat.constantColor };
    } else if (
      colorFormat?.colorSettingType === ColorSettings.CONDITIONAL &&
      colorFormat.conditionalTriggerValue
    ) {
      const multipliedValue = rawValue * (instructions?.valueFormat?.multiplyFactor ?? 1);
      const triggerValue = processString(colorFormat.conditionalTriggerValue);

      let triggerValueNum = parseFloat(triggerValue);

      if (isNaN(triggerValueNum)) triggerValueNum = 0;

      if (multipliedValue >= triggerValueNum) {
        return { color: colorFormat.conditionalPositiveColor || vars.colors.green9 };
      } else {
        return { color: colorFormat.conditionalNegativeColor || vars.colors.red9 };
      }
    }
  };

  getGoalUnitsColorStyle = () => {
    const { instructions } = this.props;

    if (instructions?.colorFormat?.applyColorToProgressGoal) {
      return this.getValueColorStyle(this.getRawValue());
    }
  };

  isKPIProgressPctValue = () => {
    const { instructions, operationType } = this.props;

    return (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      instructions?.valueFormat?.progressShowPct
    );
  };

  renderLoadingState = () => {
    return (
      <div className={this.getValueStyling()}>
        <EmbedSpinner size="lg" />
      </div>
    );
  };

  renderEmptyValue = () => {
    const { generalOptions } = this.props;
    const maybeNoDataNumber = parseFloat(generalOptions?.noDataState?.noDataText || '');
    const noDataStyle = {
      fontSize: generalOptions?.noDataState?.noDataFontSize || DEFAULT_NO_DATA_FONT_SIZE,
    };
    const colorStyle = isNaN(maybeNoDataNumber) ? {} : this.getValueColorStyle(maybeNoDataNumber);
    return (
      <div
        className={cx(this.getValueStyling(), embedSprinkles({ body: 'primary' }))}
        style={{ ...noDataStyle, ...colorStyle }}>
        {generalOptions?.noDataState?.noDataText || 'No Data'}
      </div>
    );
  };

  getUnits = (forceDenominator?: boolean) => {
    const { operationType, instructions } = this.props;

    const valueFormat = instructions?.valueFormat ?? {};

    if (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      (forceDenominator || !valueFormat.progressHideGoal)
    ) {
      const denominator =
        instructions?.valueFormat?.progressGoal &&
        formatValue({
          value: this.getProgressValue(),
          decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
          formatId: instructions?.valueFormat?.numberFormat?.id ?? V2_NUMBER_FORMATS.NUMBER.id,
          significantDigits: instructions?.valueFormat.significantDigits,
          hasCommas: true,
        });

      // If it is a progress bar but the number format is Percent, don't show the denominator
      // if it is 100%, because 30% / 100% doesn't make sense. But if its another value then show.
      if (
        valueFormat.numberFormat?.id === V2_NUMBER_FORMATS.PERCENT.id &&
        parseFloat(denominator as string) === 100
      ) {
        return valueFormat.units;
      }

      return denominator ? `/ ${denominator} ${valueFormat?.units || ''}` : valueFormat?.units;
    }

    return valueFormat?.units;
  };

  getRawValue = () => {
    const { previewData, schema } = this.props;

    return getAxisNumericalValue(previewData[0][schema[0].name]);
  };

  isNoData = () => {
    const { previewData, generalOptions, loading } = this.props;
    if (loading) return false;
    if (!previewData || previewData.length === 0) return true;

    const value = this.getRawValue();

    if (!isNumber(value) || isNaN(value)) return true;

    return generalOptions?.noDataState?.isZeroNoData ? value === 0 : false;
  };

  getFormattedNumberValue = () => {
    const { instructions } = this.props;

    const value = this.getRawValue();

    return formatValue({
      value,
      decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
      formatId: instructions?.valueFormat?.numberFormat?.id ?? V2_NUMBER_FORMATS.NUMBER.id,
      multiplier: instructions?.valueFormat?.multiplyFactor,
      hasCommas: true,
      timeFormatId: instructions?.valueFormat?.timeFormat?.id,
      customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
      significantDigits: instructions?.valueFormat?.significantDigits,
    });
  };

  getFormattedNumberOrPctValue = () => {
    const { instructions } = this.props;

    if (!this.isKPIProgressPctValue()) return this.getFormattedNumberValue();

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = this.getProgressValue() ?? value;

    let ratio = value / denominator;
    if (denominator === 0) {
      ratio = value === 0 ? 0 : 1;
    }

    return formatValue({
      value: ratio,
      decimalPlaces: instructions?.valueFormat?.pctDecimalPlaces ?? 2,
      formatId: V2_NUMBER_FORMATS.PERCENT.id,
    });
  };

  getProgressValue = () => {
    const { instructions, processString } = this.props;

    const valueString = processString(instructions?.valueFormat?.progressGoal?.toString() || '100');

    const valueNum = parseFloat(valueString);

    if (isNaN(valueNum)) return 0;

    return valueNum;
  };
}

export const SingleNumberChart = withStyles(styles)(BaseSingleNumberChart);

import produce from 'immer';

import {
  AdHocOperationInstructions,
  DataPanelData,
  SortInfo_DEPRECATED,
} from 'types/dataPanelTemplate';
import {
  FilterOperationInstructions,
  OPERATION_TYPES,
  SortOrder,
  VISUALIZE_TABLE_OPERATIONS_SET,
} from 'constants/types';
import { DataPanel } from 'types/exploResource';
import { DatasetSchema } from 'types/datasets';

export const getSortInfo = (
  dp: DataPanel,
  dpData: DataPanelData | undefined,
): SortInfo_DEPRECATED | SortInfo_DEPRECATED[] | undefined => {
  const operationType = dp.visualize_op.operation_type;
  if (!VISUALIZE_TABLE_OPERATIONS_SET.has(operationType)) return;

  if (dpData?.adHocOperationInstructions?.sortInfo)
    return dpData?.adHocOperationInstructions.sortInfo;

  // Only grab default sort if its initial request
  if (dpData?.rows) return undefined;

  let colsInfo;
  if (
    operationType === OPERATION_TYPES.VISUALIZE_TABLE ||
    operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
  ) {
    colsInfo = dp.visualize_op.instructions.VISUALIZE_TABLE.defaultSortedColumn;
  } else {
    colsInfo = dp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE?.defaultSortedColumn;
  }
  if (!colsInfo) return undefined;
  const sortInfo: SortInfo_DEPRECATED[] = [];
  (Array.isArray(colsInfo) ? colsInfo : [colsInfo]).forEach((col) => {
    if (!col.column) return;
    sortInfo.push({ column_name: col.column, order: col.order ?? SortOrder.ASC });
  });

  return sortInfo.length == 0 ? undefined : sortInfo;
};

export const getFilterInfo = (
  operationType: OPERATION_TYPES,
  dpData: DataPanelData | undefined,
): FilterOperationInstructions | undefined =>
  VISUALIZE_TABLE_OPERATIONS_SET.has(operationType)
    ? dpData?.adHocOperationInstructions?.filterInfo
    : undefined;

export const getPrimarySortInfo = (
  sortInfo: SortInfo_DEPRECATED | SortInfo_DEPRECATED[] | undefined,
): SortInfo_DEPRECATED | undefined => {
  if (!sortInfo) return;
  return Array.isArray(sortInfo) ? sortInfo[0] : sortInfo;
};

export const convertSortInfoToList = (
  sortInfo: SortInfo_DEPRECATED | SortInfo_DEPRECATED[] | undefined,
): SortInfo_DEPRECATED[] | undefined =>
  sortInfo !== undefined && !Array.isArray(sortInfo) ? [sortInfo] : sortInfo;

export const sortTable = (
  colIndex: number,
  schema: DatasetSchema,
  adHocOperationInstructions: AdHocOperationInstructions,
  descendingFirst: boolean,
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void,
): { column_name: string; order: SortOrder } => {
  const colName = schema[colIndex].name;
  const oldSortInfo = getPrimarySortInfo(adHocOperationInstructions.sortInfo);

  // we use the opposite of the old sort info, where SortOrder.ASC is the default
  // if there is no sort info
  const derivedSortOrder = oldSortInfo
    ? oldSortInfo.order === SortOrder.ASC
      ? SortOrder.DESC
      : SortOrder.ASC
    : descendingFirst
    ? SortOrder.DESC
    : SortOrder.ASC;

  const sortInfo = {
    column_name: colName,
    order: derivedSortOrder,
  };

  if (oldSortInfo?.column_name === sortInfo.column_name && oldSortInfo?.order === sortInfo.order)
    return oldSortInfo;

  const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
    draft.sortInfo = sortInfo;
  });

  onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

  return sortInfo;
};

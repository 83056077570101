import { FC } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ImageAndText } from 'pages/ReportBuilder/Onboarding/Blocks/ImageAndText';
import ExportNowImg from 'constants/images/report-builder/export-now.gif';
import ScheduleExportImg from 'constants/images/report-builder/schedule-export.gif';
import ManageScheduledExportsImg from 'constants/images/report-builder/manage-scheduled-exports.gif';

export const ExportingDoc: FC = () => {
  return (
    <>
      <ImageAndText alt="Export Now" img={ExportNowImg}>
        <EmbedText body="b2">
          You can export your report immediately as a CSV or schedule it to go out to a set of
          emails at any cadence.
        </EmbedText>
      </ImageAndText>
      <ImageAndText alt="Schedule Export" img={ScheduleExportImg}>
        <EmbedText body="b2">
          Scheduled exports can be sent to any number of emails at either a daily, weekly, or
          monthly cadence.
        </EmbedText>
      </ImageAndText>
      <ImageAndText alt="Manage Scheduled Exports" img={ManageScheduledExportsImg}>
        <EmbedText body="b2">
          Access the Export Manager by clicking Export → Schedule Export.
        </EmbedText>
      </ImageAndText>
    </>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { sprinkles } from 'components/ds';

import { GlobalStyleConfig, GlobalStylePanelErrorConfig } from 'globalStyles/types';
import { ComponentTextConfig } from './ComponentTextConfig';
import { ComponentIconConfig } from './ComponentIconConfig';
import { DEFAULT_COMPONENTS_CONFIG, DEFAULT_PANEL_ERROR_CONFIG } from 'globalStyles/constants';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

const updateNestedProperty = <T, K extends keyof T>(
  config: T,
  key: K,
  updates: Partial<T[K]>,
): T => {
  return { ...config, [key]: { ...config[key], ...updates } };
};

const DEFAULT_MESSAGE_SIZE = 20;
const DEFAULT_DESCRIPTION_SIZE = 14;

export const PanelErrorConfig: FC<Props> = ({ styleConfig, updateConfig }) => {
  const { text, components } = styleConfig;

  const updateConfigWrapper = <T extends keyof GlobalStylePanelErrorConfig>(
    key: T,
    updates: Partial<GlobalStylePanelErrorConfig[T]>,
  ) => {
    const newConfig = produce(styleConfig, (draft) => {
      if (!draft.components) draft.components = { ...DEFAULT_COMPONENTS_CONFIG };

      if (!draft.components.panelError) {
        draft.components.panelError = { ...DEFAULT_PANEL_ERROR_CONFIG };
      }

      draft.components.panelError = updateNestedProperty(draft.components.panelError, key, updates);
    });
    updateConfig(newConfig);
  };

  return (
    <div className={sprinkles({ padding: 'sp2', flexItems: 'column', gap: 'sp2' })}>
      <ComponentIconConfig
        config={{
          size: components?.panelError?.icon.size || 'lg',
          color: components?.panelError?.icon.color || '#E5484D',
        }}
        header="Icon"
        updateConfig={(updates) => updateConfigWrapper('icon', updates)}
      />
      <ComponentTextConfig
        config={{
          color:
            components?.panelError?.message?.color || text.overrides.h2?.color || text.primaryColor,
          size:
            components?.panelError?.message?.size ||
            text.overrides.h2?.size ||
            DEFAULT_MESSAGE_SIZE,
        }}
        header="Message"
        updateConfig={(updates) => updateConfigWrapper('message', updates)}
      />
      <ComponentTextConfig
        config={{
          color:
            components?.panelError?.description?.color ||
            text.overrides.body?.color ||
            text.secondaryColor,
          size:
            components?.panelError?.description?.size ||
            text.overrides.body?.size ||
            DEFAULT_DESCRIPTION_SIZE,
        }}
        header="Description"
        updateConfig={(updates) => updateConfigWrapper('description', updates)}
      />
    </div>
  );
};

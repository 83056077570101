import cx from 'classnames';

import { sprinkles } from 'components/ds';

import { V2KPITrendInstructions } from 'constants/types';
import { format } from 'utils/localizationUtils';

interface Props {
  pctChange: number;
  instructions?: V2KPITrendInstructions;
  className?: string;
}

const noChangeClass = sprinkles({ color: 'contentSecondary', backgroundColor: 'gray1' });
const positiveClass = sprinkles({ color: 'success', backgroundColor: 'successSubdued' });
const negativeClass = sprinkles({ color: 'error', backgroundColor: 'errorSubdued' });

const getTrendContainerClass = (pctChange: number, instructions?: V2KPITrendInstructions) => {
  const isPositive = pctChange > 0;
  const isNoChange = pctChange === 0;

  if (isNoChange) return noChangeClass;
  if (instructions?.displayFormat?.trendColorsReversed)
    return isPositive ? negativeClass : positiveClass;
  return isPositive ? positiveClass : negativeClass;
};

export default function TrendPctChange({ pctChange, instructions, className }: Props) {
  const isPositive = pctChange > 0;
  const isNoChange = pctChange === 0;
  const changeText = format('.1%')(Math.abs(pctChange));

  const containerClass = getTrendContainerClass(pctChange, instructions);

  return (
    <div className={cx(rootClass, className, containerClass)}>
      {isPositive ? '+' : isNoChange ? '' : '-'}
      {changeText}
    </div>
  );
}

const rootClass = sprinkles({
  borderRadius: 8,
  paddingY: 'sp.5',
  paddingX: 'sp1',
  fontSize: 12,
  fontWeight: 500,
});

import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';
import {
  NoDataAlert,
  AtLeastOneAlert,
  RemovedDataAlert,
} from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DataPanelList } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelList';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';

import { CustomerReportAgg, CustomerReportVisualization } from 'actions/customerReportActions';
import { IconName } from 'components/ds/Icon';
import { VIZ_TO_SECTION } from 'constants/dataConstants';
import { Aggregation, OPERATION_TYPES } from 'constants/types';
import {
  clearPrevAggregations,
  getCurrentViewRemovedAggregations,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DraggableColumnInfo, getAggUniqueId, getGroupByUniqueId } from 'utils/customerReportUtils';
import { AGGS_SECTION_ID, DataPanelData } from './constants';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { isLineOrBarVisualization } from 'pages/ReportBuilder/utils/visualizationUtils';
import { AggItem } from 'pages/ReportBuilder/ReportView/DataPanel/AggItem';
import { AddAggMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddAggMenu';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';

type Props = {
  customAggregations?: CustomerReportAgg[];
  aggregations: CustomerReportAgg[];
  columns: DraggableColumnInfo[];
  disabled?: boolean;
  max?: number;
  operationType: CustomerReportVisualization;
  columnConfigs?: ReportBuilderColConfigs;
};

export const AggSection: FC<Props> = ({
  columns,
  aggregations,
  disabled,
  max,
  operationType,
  customAggregations,
  columnConfigs,
}) => {
  const dispatch = useDispatch();
  const removedAggs = useSelector((state: ReportBuilderReduxState) =>
    getCurrentViewRemovedAggregations(state.reportEditing),
  );

  const { over, isOver, setNodeRef, active } = useDroppable(DROPPABLE_ARGS);

  const sortableIds = useMemo(
    () => aggregations.map((item) => `${AGGS_SECTION_ID}-${getGroupByUniqueId(item)}`),
    [aggregations],
  );

  const aggsByCol = useMemo(() => {
    const byCol: Record<string, Set<Aggregation>> = {};
    aggregations.forEach(({ column, agg }) => {
      if (column.name in byCol) byCol[column.name].add(agg.id);
      else byCol[column.name] = new Set([agg.id]);
    });
    return byCol;
  }, [aggregations]);

  const isOverContainer = isOverSection(AGGS_SECTION_ID, isOver, over, sortableIds);
  const isOverSelf = active?.data.current?.section === AGGS_SECTION_ID;

  const visualizationName = VIZ_TO_SECTION[operationType || OPERATION_TYPES.VISUALIZE_TABLE];
  const numAggregations = aggregations.length;
  const numRemovedAggregations = removedAggs ? removedAggs.length - numAggregations : 0;
  const hasMax = max != null && aggregations.length >= max;
  const isPopoverOpen = isOverContainer && hasMax && !isOverSelf;
  const maxText = `${visualizationName} can have up to ${max} values`;

  const { title, icon } = getAggregationsTitleAndIcon(operationType);

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={AGGS_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon={icon}
        popoverChildren={isPopoverOpen ? maxText : undefined}
        title={title}>
        <AddAggMenu
          aggregations={aggregations}
          columnConfigs={columnConfigs}
          columns={columns}
          customAggregations={customAggregations}
          disabled={disabled}
          tooltipText={hasMax ? maxText : undefined}
        />
      </DataPanelSubHeader>

      {numRemovedAggregations > 0 ? (
        <RemovedDataAlert
          name="value"
          numRemoved={numRemovedAggregations}
          onDismiss={() => dispatch(clearPrevAggregations())}
          visualizationName={visualizationName}
        />
      ) : null}

      {operationType !== OPERATION_TYPES.VISUALIZE_TABLE && aggregations.length === 0 ? (
        <AtLeastOneAlert name="value" visualizationName={visualizationName} />
      ) : null}

      {aggregations.length === 0 ? (
        <NoDataAlert name="value" />
      ) : (
        aggregations.map((aggregation) => (
          <AggItem
            aggregation={aggregation}
            aggsByCol={aggsByCol}
            columnConfigs={columnConfigs}
            key={getAggUniqueId(aggregation)}
          />
        ))
      )}
    </DataPanelList>
  );
};

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: AGGS_SECTION_ID,
  data: { id: AGGS_SECTION_ID, section: AGGS_SECTION_ID } as DataPanelData,
};

function getAggregationsTitleAndIcon(operationType: CustomerReportVisualization): {
  title: string;
  icon: IconName;
} {
  return isLineOrBarVisualization(operationType)
    ? { title: 'Y-Axis Values', icon: 'up-long' }
    : { title: 'Values', icon: 'table-cells' };
}

import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { DASHBOARD_ELEMENT_TYPES, DashboardVariableMap } from 'types/dashboardTypes';

/**
 * Extra variables allows filter elements to assign more than one variable when something
 * is selected.
 * Current Extra Variables Supported
 * - Toggle/SingleSelect - Display (display value of selected item)
 * - Multiselect - Length (number of items selected)
 */

// This object is used to pass around info for setting extra variables
export type VariableSelectOptions = {
  //Display used for dropdown and toggle display values
  display?: string | undefined;
  //Length used for multiselects count of selected elements
  length?: number;
  //Clears data when setting variables to prevent showing stale data
  clearData?: boolean;
};

// When a variable is being set apply the options to those variables
export const applyExtraVariables = (
  variables: DashboardVariableMap,
  varName: string,
  options?: VariableSelectOptions,
) => {
  if (!options) return;
  const { displayVar, lengthVar } = getExtraVarNames(varName);
  if ('display' in options) variables[displayVar] = options.display;
  if ('length' in options) variables[lengthVar] = options.length;
};

// When a variable is being cleared also clear the extra variables
export const clearExtraVariables = (variables: DashboardVariableMap, varName: string) => {
  const { displayVar, lengthVar } = getExtraVarNames(varName);
  if (displayVar in variables) delete variables[displayVar];
  if (lengthVar in variables) delete variables[lengthVar];
};

const getExtraVarNames = (varName: string): { displayVar: string; lengthVar: string } => ({
  displayVar: getDisplayVarName(varName),
  lengthVar: getLengthVarName(varName),
});

// Get list of extra variables for display and blocked variables purposes
export const getListOfExtraVarsForElement = (
  varName: string,
  elementType: DASHBOARD_ELEMENT_TYPES,
): string[] => {
  if (!SELECT_ELEMENT_SET.has(elementType)) return [];
  if (elementType === DASHBOARD_ELEMENT_TYPES.MULTISELECT) return [getLengthVarName(varName)];
  return [getDisplayVarName(varName)];
};

// Functions to get the variable name for extra variable types
export const getDisplayVarName = (varName: string) => `${varName}.display`;
export const getLengthVarName = (varName: string) => `${varName}.length`;

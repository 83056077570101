import { MutableRefObject, useMemo } from 'react';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import cx from 'classnames';

import { ColumnHeader } from 'components/embed/EmbedDataGrid/ColumnHeader';

import { DatasetColumn } from 'types/datasets';
import { COLUMN_FITS, ColumnWidths, SortInfo } from 'constants/types';
import { CellBordersType, ColumnTooltips } from './index';
import { generateDataGridColumn, UseColumnsParams } from 'components/ds/DataGrid/columnUtils';
import { getFlexAlignments } from 'components/ds/DataGrid/utils';

import * as styles from './index.css';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

export type UseEmbedColumnsParams = Omit<
  UseColumnsParams,
  'columnToCategoryToColor' | 'setColumnToCategoryToColor'
> & {
  onSortColumn?: (sort: SortInfo[]) => void;
  onFilterColumn?: (column: DatasetColumn) => void;

  // Ref for the parent container to create a boundary for Radix tooltips, dropdowns, and popovers
  containerRef?: MutableRefObject<HTMLElement | null>;
  columnTooltips?: ColumnTooltips;
  columnFit?: COLUMN_FITS;
  columnWidths?: ColumnWidths;
  enableBoldFirstColumn?: boolean;
  enableBoldHeader?: boolean;
  enableLockedFirstColumn?: boolean;
  hideHeaderMenu?: boolean;
  showCellBorders?: CellBordersType;
  shouldTruncateText?: boolean;
  shouldVisuallyGroupByFirstColumn?: boolean;
  isInitialSortDesc?: boolean;
  // Remove when report builder supports custom styles
  disableCustomStyles?: boolean;
};

export const useEmbedColumns = (params: UseEmbedColumnsParams) => {
  const gridColumns: TypeColumn[] = useMemo(() => {
    const {
      columns,
      schema,
      rows,
      columnConfigs,
      columnTooltips,
      columnFit,
      columnWidths,
      enableBoldFirstColumn,
      enableBoldHeader,
      enableLockedFirstColumn,
      hideHeaderMenu,
      showCellBorders,
      shouldTruncateText,
      shouldVisuallyGroupByFirstColumn,
      disableCustomStyles,
      isInitialSortDesc,
      ...rest
    } = params;
    if (columns) return columns;
    if (!schema || !rows) return [];

    const noColumnFit = !columnFit || columnFit === COLUMN_FITS.FILL;

    return schema.map((columnInfo, i) => {
      const { name, type } = columnInfo;
      const config = columnConfigs?.[name];
      const isFirstColumn = i === 0;
      const tooltip = columnTooltips?.[name];
      const width = columnWidths?.[name];

      const baseColumn = generateDataGridColumn({
        ...rest,
        rows,
        columnConfigs,
        columnInfo,
      });

      const widthProps = width
        ? {
            width,
            minWidth: undefined,
            defaultFlex: undefined,
          }
        : {
            minWidth: noColumnFit ? baseColumn.minWidth : undefined,
            defaultFlex: noColumnFit ? baseColumn.defaultFlex : undefined,
            width: undefined,
          };

      return {
        ...baseColumn,
        ...widthProps,
        headerProps: {
          ...baseColumn.headerProps,
          className: cx({
            [embedSprinkles({ backgroundColor: 'containerFillOffset' })]: !disableCustomStyles,
            [styles.columnHeader]: !disableCustomStyles,
          }),
        },
        className: cx(baseColumn.className, {
          [embedSprinkles({ body: 'primary' })]: !disableCustomStyles,
          [styles.cell]: !shouldTruncateText,
        }),
        style: {
          ...baseColumn.style,
          fontWeight: isFirstColumn && enableBoldFirstColumn ? 'bold' : 'normal',
          borderLeft: showCellBorders === 'horizontal' || showCellBorders === false ? 0 : undefined,
        },
        resizable: true,
        textEllipsis: true, // Needs to be true even when wrapping text to prevent text from overflowing the cell
        locked: isFirstColumn && enableLockedFirstColumn,
        rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
          let rowspan = 1;

          const prevData = dataSourceArray[rowIndex - 1];
          if (
            !shouldVisuallyGroupByFirstColumn ||
            prevData?.[column.name] === value ||
            column.computedAbsoluteIndex > 0
          ) {
            return rowspan;
          }
          let currentRowIndex = rowIndex + 1;
          while (
            dataSourceArray[currentRowIndex] &&
            dataSourceArray[currentRowIndex][column.name] === value
          ) {
            rowspan++;
            currentRowIndex++;
          }
          return rowspan;
        },
        renderHeader: (cellProps) => {
          return (
            <ColumnHeader
              {...cellProps}
              alignment={getFlexAlignments(config?.displayFormatting, type)}
              column={columnInfo}
              containerRef={params.containerRef}
              disableCustomStyles={disableCustomStyles}
              hideMenu={hideHeaderMenu}
              isBold={enableBoldHeader}
              isInitialSortDesc={isInitialSortDesc}
              onFilter={params.onFilterColumn}
              onSort={params.onSortColumn}
              shouldTruncateText={shouldTruncateText}
              sort={cellProps.computedSortInfo}
              tooltip={tooltip}
            />
          );
        },
      };
    });
  }, [params]);

  return gridColumns;
};

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse, Method } from 'axios';
import Redux from 'redux';

import {
  CancelablePromise,
  ApiError,
  OpenAPI,
  ClientError,
  DataSourceError,
} from '@explo-tech/fido-api';
import { ErrorResponse } from 'actions/responseTypes';
import { Jobs } from 'components/JobQueue/types';
import { JobDefinition } from 'actions/jobQueueActions';
import { createApiRequestConfig } from 'actions/actionUtils';
import { getDispatchActions, handleBulkEnqueueResponse, handleEnqueueError } from './jobQueueUtils';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { DataPanel, RequestDataset, ResourceDataset } from 'types/exploResource';
import { getDataPanelDatasetId } from './exploResourceUtils';

export const createApiRequestConfigWithRequestInfo = (
  urls: { embedUrl: string; appUrl: string },
  requestType: Method,
  requestInfo: DashboardLayoutRequestInfo,
  postData: Record<string | number, unknown> | undefined,
): AxiosRequestConfig => {
  let customerToken, jwt;
  let url: string;

  if (requestInfo.type === 'embedded') {
    customerToken = requestInfo.customerToken;
    jwt = requestInfo.jwt;
    url = urls.embedUrl;
  } else url = urls.appUrl;

  const postDataWithAdditionalArgs = { ...postData, ...getAdditionalArgs(requestInfo) };

  return createApiRequestConfig(url, requestType, postDataWithAdditionalArgs, customerToken, jwt);
};

type AdditionalArgs = { timezone: string } & (
  | { resource_id: number; user_group_id: number | undefined; customer_id: number | undefined }
  | { resource_embed_id: string; version_number: number }
);

export const getAdditionalArgs = (requestInfo: DashboardLayoutRequestInfo): AdditionalArgs => {
  if (requestInfo.type === 'app')
    return {
      timezone: requestInfo.timezone,
      ['resource_id']: requestInfo.resourceId,
      ['user_group_id']: requestInfo.customerId,
      // Some requests now use customer_id, passing both for backwards compatibility
      ['customer_id']: requestInfo.customerId,
    };

  return {
    timezone: requestInfo.timezone,
    ['resource_embed_id']: requestInfo.resourceEmbedId,
    ['version_number']: requestInfo.versionNumber,
  };
};

export const attachDatasetToPostData = (
  dataPanel: DataPanel,
  datasets: Record<string, ResourceDataset>,
  requestInfo: DashboardLayoutRequestInfo,
): { dataset_id: string } | { dataset: RequestDataset } => {
  const datasetId = getDataPanelDatasetId(dataPanel);
  if (requestInfo.type === 'embedded') return { dataset_id: datasetId };

  const dataset = datasets[datasetId] ?? {};
  return { dataset: { query: dataset.query, parent_schema_id: dataset.parent_schema_id } };
};

type ThunkOptions<T> = {
  onSuccess?: (response: T) => void;
  onError?: (error: ErrorResponse) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rejectWithValue?: (value: any) => void;
};

export async function makeThunkRequest<T>(
  requestConfig: AxiosRequestConfig | null,
  defaultErrMsg: string,
  options?: ThunkOptions<T>,
): Promise<T> {
  if (!requestConfig) throw new Error(defaultErrMsg);

  let errorMessage: string | null = null;

  return axios(requestConfig)
    .then(({ data }: AxiosResponse) => {
      if (data.success === 1) {
        options?.onSuccess?.(data);
        return data;
      } else if (data.success === 0) {
        options?.onError?.(data);
        if (options?.rejectWithValue) return options.rejectWithValue(data);
      }
      errorMessage = data.error_msg ?? defaultErrMsg;
    })
    .catch((error: AxiosError) => {
      console.error(error.response);
      options?.onError?.(
        error.response && { ...error.response.data, status: error.response.status },
      );
      throw new Error(defaultErrMsg);
    })
    .finally(() => {
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    });
}

export type FidoRequestFn<T> = (() => CancelablePromise<T>) | null;

export async function makeFidoThunkRequest<T>(
  requestFn: FidoRequestFn<T>,
  fidoToken: string,
  defaultErrMsg: string,
  onSuccess?: (response: T) => void,
  onError?: (error: ErrorResponse) => void,
): Promise<T> {
  if (!requestFn) throw new Error(defaultErrMsg);
  OpenAPI.TOKEN = fidoToken;

  return requestFn()
    .then((response) => {
      onSuccess?.(response);
      return response;
    })
    .catch((error: ApiError) => {
      const errorBody = error.body as ClientError | DataSourceError;
      console.error(errorBody);
      onError?.({
        detail: errorBody.message ?? '',
        status: error.status,
        error_msg: errorBody.message ?? '',
      });
      throw new Error(errorBody.message ?? defaultErrMsg);
    });
}

export async function makeEnqueueJobsThunkRequest(
  jobs: Record<string, JobDefinition>,
  dispatch: Redux.Dispatch,
  customerToken?: string,
  jwt?: string,
): Promise<Record<string, Jobs>> {
  const isEmbed = !!customerToken || !!jwt;
  const requestConfig = createApiRequestConfig(
    'async_jobs/enqueue_jobs/',
    'POST',
    { jobs },
    customerToken,
    jwt,
  );

  //   Dispatch request actions for each job
  Object.values(jobs).forEach((job) => {
    const { requestFn } = getDispatchActions(job.job_type, isEmbed);

    if (requestFn) dispatch(requestFn({ postData: job.job_args }));
  });

  return axios(requestConfig)
    .then(({ data }: AxiosResponse) => {
      return handleBulkEnqueueResponse(data, jobs, dispatch, isEmbed);
    })
    .catch((error: AxiosError) => {
      Object.values(jobs).forEach((job) =>
        handleEnqueueError(
          { jobType: job.job_type, jobArgs: job.job_args, onError: job.onError },
          error.message,
          isEmbed,
          dispatch,
        ),
      );
      console.error(error.response);
      throw new Error('Error enqueuing jobs');
    });
}

import { AxiosRequestConfig } from 'axios';
import { ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { Layout } from '@explo-tech/react-grid-layout';

import { ReduxState } from 'reducers/rootReducer';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  deleteEditableSectionChart,
  setEditableSectionLayout,
} from 'reducers/dashboardEditConfigReducer';
import { onNewDataPanelsAddedThunk } from './dashboardDataThunks/requestLogicThunks';
import { addChartToLayout, cleanLayout } from 'utils/editableSectionUtils';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createApiRequestConfig } from 'actions/actionUtils';
import { makeThunkRequest } from 'utils/thunkUtils';
import { CustomerEditableSection, CustomerEditableSectionConfig } from 'actions/dashboardActions';
import { createDebouncedFn } from 'utils/general';
import { updateEmbedEditableSectionLayout } from 'reducers/embedDashboardReducer';
import { deleteDataPanelAftermathThunk } from './dashboardSelectionThunks';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

const debounceFn = createDebouncedFn(2000);

export const deleteEditableSectionChartThunk =
  (dpId: string): Thunk =>
  (dispatch) => {
    dispatch(deleteEditableSectionChart());
    dispatch(deleteDataPanelAftermathThunk(dpId));
  };

export const addChartToEditableSectionThunk =
  (layout: Layout[] | undefined, dp: DataPanelTemplate, columns: number): Thunk =>
  (dispatch, getState) => {
    const {
      dashboardInteractions: { interactionsInfo },
      dashboardLayout: { requestInfo },
    } = getState();

    const newLayout = layout ? cleanLayout([...layout]) : [];
    addChartToLayout(dp.id, dp.visualize_op.operation_type, newLayout, columns);

    if (requestInfo.type === 'embedded') {
      dispatch(saveEditableSectionThunk({ layout: newLayout }, requestInfo.embedType));
    } else {
      const isEditing = interactionsInfo.isEditing;
      dispatch(setEditableSectionLayout({ layout: newLayout, isPreview: !isEditing }));
    }
    dispatch(onNewDataPanelsAddedThunk([dp]));
  };

export const removeChartFromEditableSectionThunk =
  (layout: Layout[], dpId: string): Thunk =>
  (dispatch) => {
    const newLayout = layout.filter((item) => item.i !== dpId);
    dispatch(setEditableSectionLayoutThunk(newLayout));
    dispatch(deleteDataPanelAftermathThunk(dpId));
  };

export const setEditableSectionLayoutThunk =
  (newLayout: Layout[]): Thunk =>
  (dispatch, getState) => {
    const {
      dashboardInteractions: { interactionsInfo },
      dashboardLayout: { requestInfo },
    } = getState();

    const cleanedLayout = cleanLayout(newLayout);

    if (requestInfo.type === 'embedded') {
      dispatch(saveEditableSectionThunk({ layout: cleanedLayout }, requestInfo.embedType));
    } else {
      const isEditing = interactionsInfo.isEditing;
      dispatch(setEditableSectionLayout({ layout: cleanedLayout, isPreview: !isEditing }));
    }
  };

// TODO: Should we send edit version number to not allow overwrites?
type SaveEditableSectionParams = {
  config: CustomerEditableSectionConfig;
  dashboard_embed_id: string;
};

const allowedSaveEditableSectionEmbedTypes: Set<EmbeddedDashboardType> = new Set([
  'embedded',
  'iframe',
  'shared',
  'portal',
]);

const saveEditableSectionThunk =
  (config: CustomerEditableSectionConfig, embedType: EmbeddedDashboardType): Thunk =>
  (dispatch) => {
    dispatch(updateEmbedEditableSectionLayout(config.layout));
    if (!allowedSaveEditableSectionEmbedTypes.has(embedType)) return;

    debounceFn(() => dispatch(saveEditableSection(config)));
  };

export const saveEditableSection = createAsyncThunk<
  { customer_editable_section: CustomerEditableSection },
  CustomerEditableSectionConfig,
  { state: EmbedReduxState }
>(ACTION.SAVE_CUSTOMER_EDITABLE_SECTION, async (config, { getState }) => {
  const { requestInfo } = getState().dashboardLayout;
  let requestConfig: AxiosRequestConfig | null = null;
  if (requestInfo.type === 'embedded') {
    const postData: SaveEditableSectionParams = {
      config,
      dashboard_embed_id: requestInfo.resourceEmbedId,
    };

    requestConfig = createApiRequestConfig(
      'embed/save_editable_section/',
      'POST',
      postData,
      requestInfo.customerToken,
      requestInfo.jwt,
    );
  }

  return makeThunkRequest(requestConfig, 'Error saving editable section');
});

import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { Input } from 'components/ds';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export const KPITrendTitleConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const selectedAlignment =
    instructions.titleFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  return (
    <div className={configRootClass}>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={instructions.textOnlyFormat?.subtitle}
        label={{ text: 'Subtitle', variableInput: true }}
        onSubmit={(newValue) => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.textOnlyFormat) draft.textOnlyFormat = {};
            draft.textOnlyFormat.subtitle = newValue;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
      />
      <HorizontalAlignmentToggle
        className={configInputClass}
        selectedAlignment={selectedAlignment}
        updateAlignment={(alignment) => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.titleFormat) draft.titleFormat = {};
            draft.titleFormat.horizAlignment = alignment;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
      />
    </div>
  );
};

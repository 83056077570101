import { GlobalStyleConfig } from 'globalStyles/types';
import { FC, useMemo } from 'react';
import cx from 'classnames';
import { Layout } from 'react-grid-layout';
import { times } from 'utils/standard';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useSelector } from 'react-redux';

import ElementGridLayout from './ElementGridLayout';
import { EditableSection } from 'components/EditableSection';
import { sprinkles, vars } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { DashboardElement, DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getLayoutHeightInRows } from 'utils/layoutResolverUtil';
import {
  PDF_PAGE_HEIGHT,
  ROWS_PER_PDF_PAGE,
  PDF_PAGE_BREAK_HEIGHT,
  PDF_EDITOR_PAGE_BORDER_WIDTH,
} from 'constants/dashboardConstants';
import { Customer, EmbedCustomer } from 'actions/teamActions';
import * as wrapperStyles from './wrapperStyles.css';
import { DashboardStates } from 'reducers/rootReducer';
import { getDatasetNamesToId } from 'utils/datasetUtils';

type Props = {
  customer: Customer | EmbedCustomer | undefined;
  datasets: Record<string, ResourceDataset>;
  dashboardElements: DashboardElement[];
  dataPanels: DataPanel[];
  dashboardLayout: Layout[];
  isDemoCustomer: boolean;
  isEditableSectionEnabled: boolean | undefined;
  globalStyleConfig: GlobalStyleConfig;
  isViewOnly: boolean;
  pageType: PAGE_TYPE;
  variables: DashboardVariableMap;
  viewMode: VIEW_MODE;
};

export const DashboardBody: FC<Props> = ({
  customer,
  dataPanels,
  datasets,
  dashboardElements,
  dashboardLayout,
  isEditableSectionEnabled,
  isDemoCustomer,
  isViewOnly,
  variables,
  globalStyleConfig,
  pageType,
  viewMode,
}) => {
  const isPdfEditor = !isViewOnly && viewMode === VIEW_MODE.PDF;
  const isEmailView = viewMode === VIEW_MODE.EMAIL;

  const isEditing = useSelector(
    (state: DashboardStates) => state.dashboardInteractions.interactionsInfo.isEditing,
  );
  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  const renderPdfEditorPages = () => {
    const numberOfPages = Math.ceil(getLayoutHeightInRows(dashboardLayout) / ROWS_PER_PDF_PAGE);
    return times(numberOfPages, (pageNumber) => {
      return (
        <div
          key={pageNumber}
          style={{
            position: 'absolute',
            top: pageNumber * PDF_PAGE_HEIGHT + PDF_PAGE_BREAK_HEIGHT / 2,
            height: PDF_PAGE_HEIGHT - PDF_PAGE_BREAK_HEIGHT - PDF_EDITOR_PAGE_BORDER_WIDTH,
            width: '100%',
            backgroundColor: vars.colors.white,
            border: `${PDF_EDITOR_PAGE_BORDER_WIDTH}px solid ${vars.colors.gray7}`,
          }}
        />
      );
    });
  };

  let style = { width: isEmailView ? 650 : undefined };
  if (isEditing) style = { ...style, ...wrapperVars };

  // If an element is made visible after being hidden react grid layout needs to repaint
  // So using this value for the key so that when it changes it rerenders the entire layout
  // Only matters in embed so not while editing
  const numberOfElements = isEditing ? 0 : dashboardElements.length + dataPanels.length;

  return (
    <div
      className={cx(rootClass, {
        [embedSprinkles({ backgroundColor: 'background' })]: !isPdfEditor,
        [pdfEditorClass]: isPdfEditor,
        [emailViewClass]: isEmailView,
      })}
      id="scrollableLayout"
      key={`${viewMode}-${numberOfElements}`}
      style={style}>
      {isPdfEditor ? renderPdfEditorPages() : null}
      <ElementGridLayout
        dashboardElements={dashboardElements}
        dashboardLayout={dashboardLayout}
        dataPanels={dataPanels}
        datasetNamesToId={datasetNamesToId}
        datasets={datasets}
        globalStyleConfig={globalStyleConfig}
        isDemoCustomer={isDemoCustomer}
        isEditableSectionEnabled={!!isEditableSectionEnabled}
        isViewOnly={isViewOnly}
        pageType={pageType}
        variables={variables}
      />
      {isEditableSectionEnabled ? (
        <EditableSection
          customer={customer}
          datasets={datasets}
          elements={dashboardElements}
          globalStyleConfig={globalStyleConfig}
          isEditingDashboard={isEditing}
          viewMode={viewMode}
        />
      ) : null}
    </div>
  );
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  overflowY: 'auto',
  position: 'relative',
});

const pdfEditorClass = sprinkles({ marginTop: 'sp4', overflowY: 'visible' });

const emailViewClass = sprinkles({
  margin: 'auto',
  borderRadius: 4,
  border: 1,
  borderColor: 'gray7',
});

const wrapperVars = assignInlineVars({
  [wrapperStyles.activeColor]: vars.colors.blue9,
  [wrapperStyles.activeHoverColor]: vars.colors.blue10,
  [wrapperStyles.activeContrastColor]: vars.colors.white,
  [wrapperStyles.activeSubduedColor]: vars.colors.blue3,
  [wrapperStyles.activeSubduedContrastColor]: vars.colors.black,
});

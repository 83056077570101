import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ConfigSection } from 'components/resource/ConfigSection';
import { Button, sprinkles } from 'components/ds';
import { DatasetCustomAggItem } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetCustomAggItem';
import * as sharedStyles from 'components/resource/index.css';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { addReportBuilderCustomAggregation } from 'reducers/reportBuilderEditReducer';
import { getAggColName } from 'utils/V2ColUtils';

type Props = {
  dataset: ReportBuilderDataset;
  onDelete: (aggId: string) => void;
};

export const DatasetCustomAggSection: FC<Props> = ({ dataset, onDelete }) => {
  const dispatch = useDispatch();

  return (
    <ConfigSection title="Custom aggregations">
      <div className={sharedStyles.configSection}>
        <div className={sharedStyles.sectionText}>
          Create custom columns using SQL queries to aggregate your data. These can be selected in
          the Customer Report Aggregations panel.
        </div>
        <Button
          className={sprinkles({ marginBottom: 'sp1' })}
          icon="plus"
          onClick={() => dispatch(addReportBuilderCustomAggregation({ datasetId: dataset.id }))}>
          New Custom Aggregation
        </Button>
        {dataset.customAggregations?.map((agg, i, array) => (
          <DatasetCustomAggItem
            agg={agg}
            columnConfig={dataset.columnConfigs[getAggColName(agg)]}
            datasetId={dataset.id}
            key={agg.column.name}
            onDelete={onDelete}
            startOpen={i === array.length - 1}
          />
        ))}
      </div>
    </ConfigSection>
  );
};

import { FC, useContext, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import cx from 'classnames';

import EmailBuilderTextEditor from 'components/EmailBuilder/EmailBuilderTextEditor';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { ResizableDatasetEditor } from './dashboardDatasetEditor/resizableDatasetEditor';
import * as styles from './editDashboardPage.css';

import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { datasetEditorNotShown } from 'constants/dataPanelEditorConstants';
import { Dashboard } from 'actions/dashboardActions';
import { getTimezone } from 'utils/timezoneUtils';
import { VersionInfo } from 'types/exploResource';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { Customer } from 'actions/teamActions';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { getEditableDatasets } from 'reducers/selectors';
import { GlobalStylesContext } from 'globalStyles';
import { getDatasetEditorHeight } from 'reducers/dashboardInteractionsReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { getTeamDataSources } from 'reducers/dataSourceReducer';

type Props = {
  dashboard: Dashboard;
  isFullPageDashboard: boolean;
  versionInfo: VersionInfo;
  width: number | undefined;
};

export const EditDashboardPageBody: FC<Props> = ({
  dashboard,
  isFullPageDashboard,
  versionInfo,
  width,
}) => {
  const globalStylesClassName = useContext(GlobalStylesContext).globalStylesClassName;

  const {
    editableDashboard,
    viewMode,
    team,
    customer,
    dataSources,
    schemas,
    paneStates,
    datasetEditorHeight,
  } = useSelector(
    (state: ReduxState) => ({
      editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
      team: state.currentUser.team,
      customer: getSelectedCustomer(state.customers),
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      dataSources: getTeamDataSources(state),
      schemas: getParentSchemasList(state),
      paneStates: state.dashboardInteractions.paneOpenStates,
      datasetEditorHeight: getDatasetEditorHeight(state.dashboardInteractions),
    }),
    shallowEqual,
  );

  const requestInfo: DashboardLayoutRequestInfo | undefined = useMemo(() => {
    if (!customer) return;
    return {
      type: 'app',
      resourceId: dashboard.id,
      timezone: getTimezone(dashboard.default_timezone),
      useJobQueue: team?.feature_flags.use_job_queue ?? false,
      useFido: team?.feature_flags.use_fido ?? false,
      versionNumber: versionInfo.version_number,
      customerId: customer.id,
      isDraft: versionInfo.is_draft,
      parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
      dataSources,
      schemas,
    };
  }, [
    dashboard,
    team,
    versionInfo.version_number,
    versionInfo.is_draft,
    customer,
    dataSources,
    schemas,
  ]);

  const dashboardWidth = useMemo(() => {
    const leftPanelAddition = paneStates.left ? 0 : styles.SIDE_PANE_WIDTH;
    const rightPanelAddition = paneStates.right ? -styles.SIDE_PANE_WIDTH : 0;

    return (width || 0) + leftPanelAddition + rightPanelAddition;
  }, [paneStates, width]);

  const showDatasetEditor = useMemo(
    () => width && !datasetEditorNotShown(dashboardWidth),
    [dashboardWidth, width],
  );

  const renderDashboardPreview = () => {
    if (customer === null || !requestInfo) {
      return (
        <div className={styles.noCustomer} style={{ fontSize: 20 }}>
          Create a Customer to Populate Dashboard Data
        </div>
      );
    }

    return (
      <div
        className={cx(
          styles.dashboardPreview,
          {
            [styles.fullSizeDashboard]:
              !editableDashboard || !showDatasetEditor || viewMode === VIEW_MODE.MOBILE,
            [styles.pdfOrEmailDashboard]:
              viewMode === VIEW_MODE.PDF || viewMode === VIEW_MODE.EMAIL,
          },
          globalStylesClassName,
        )}
        style={{ height: `calc(100% - ${datasetEditorHeight}px)` }}>
        <DashboardLayoutWrapper
          customer={customer}
          dashboard={dashboard}
          requestInfo={requestInfo}
          viewMode={viewMode}
          width={width}
        />
      </div>
    );
  };

  const renderDatasetEditor = () => {
    if (isFullPageDashboard || !showDatasetEditor) return null;

    return (
      <ResizableDatasetEditor
        dashboardId={dashboard.id}
        inEditMode={editableDashboard}
        leftPaneIsOpen={paneStates.left}
        pageWidth={dashboardWidth}
      />
    );
  };

  return (
    <div
      className={cx(styles.mainPageBody, {
        leftPaneOpen: paneStates.left && editableDashboard,
        rightPaneOpen: paneStates.right && editableDashboard,
      })}>
      {renderDashboardPreview()}
      {renderDatasetEditor()}
    </div>
  );
};

type LayoutProps = Pick<Props, 'dashboard' | 'width'> & {
  customer: Customer;
  requestInfo: DashboardLayoutRequestInfo;
  viewMode: VIEW_MODE;
};

const DashboardLayoutWrapper: FC<LayoutProps> = ({
  dashboard,
  customer,
  requestInfo,
  viewMode,
  width,
}) => {
  const { config, datasets, isEditableSectionEnabled, archetypeProperties } = useSelector(
    (state: ReduxState) => ({
      // We should not be here unless the config is defined. This makes rest a lot cleaner
      config: state.dashboardEditConfig.config as DashboardVersionConfig,
      datasets: getEditableDatasets(state),
      archetypeProperties: state.teamData.data?.archetype_properties,
      isEditableSectionEnabled:
        state.currentUser.team?.entitlements.enable_editable_section &&
        state.dashboardEditConfig.config?.editable_section?.enabled,
    }),
    shallowEqual,
  );

  const dashboardElements = useMemo(() => Object.values(config.elements), [config.elements]);
  const dataPanels = useMemo(() => Object.values(config.data_panels), [config.data_panels]);

  const dashboardLayout = useMemo(
    () => getLayoutFromDashboardVersionConfig(config, viewMode),
    [config, viewMode],
  );

  return (
    <>
      {viewMode === VIEW_MODE.EMAIL ? (
        <EmailBuilderTextEditor isHeader initialValue={config.email_header_html} />
      ) : null}
      <DashboardLayout
        archetypeProperties={archetypeProperties}
        customer={customer}
        dashboardElements={dashboardElements}
        dashboardLayout={dashboardLayout}
        dataPanels={dataPanels}
        datasets={datasets}
        isEditableSectionEnabled={isEditableSectionEnabled}
        isViewOnly={false}
        pageLayoutConfig={config.dashboard_page_layout_config}
        pageType={PAGE_TYPE.EXPLO_APP}
        params={config.params}
        requestInfo={requestInfo}
        resourceId={dashboard.id}
        width={width}
      />
      {viewMode === VIEW_MODE.EMAIL ? (
        <EmailBuilderTextEditor initialValue={config.email_footer_html} isHeader={false} />
      ) : null}
    </>
  );
};

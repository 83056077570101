import { FC } from 'react';
import cx from 'classnames';

import * as styles from '../styles.css';
import { InfoIcon, Switch, sprinkles } from 'components/ds';

import { DASHBOARD_ELEMENT_TYPES, DateElemConfig, DATE_FILTER_TYPE } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateEditInteractionsConfig: FC<Props> = ({
  config,
  dateFilterType,
  updateDateConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  return (
    <>
      <Switch
        className={styles.configInput}
        label="Enable clearing selection"
        onChange={() => updateConfig((draft) => (draft.disableCancel = !draft.disableCancel))}
        switchOn={!config.disableCancel}
      />
      {dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER ? (
        <>
          <div
            className={cx(
              sprinkles({ display: 'flex', justifyContent: 'space-between' }),
              styles.configInput,
            )}>
            <div
              className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', marginRight: 'sp1' })}>
              <div className={sprinkles({ color: 'contentPrimary', body: 'b3' })}>
                Set end date to end of day
              </div>
              <InfoIcon text="Turning this off may affect your dashboard's caching, if configured" />
            </div>
            <Switch
              onChange={() =>
                updateConfig((draft) => (draft.endDateEndOfDay = !draft.endDateEndOfDay))
              }
              switchOn={config.endDateEndOfDay}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

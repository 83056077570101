import { useMemo } from 'react';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';

import {
  useEmbedColumns,
  UseEmbedColumnsParams,
} from 'components/embed/EmbedDataGrid/useEmbedColumns';
import { vars } from 'components/ds';
import produce from 'immer';

export const useReportColumns = (params: UseEmbedColumnsParams) => {
  const embedColumns = useEmbedColumns(params);

  const gridColumns: TypeColumn[] = useMemo(
    () =>
      embedColumns.map((column, i) =>
        produce(column, (draft) => {
          // Pad the first column
          draft.style.paddingLeft = i === 0 ? vars.spacing.sp2 : undefined;
        }),
      ),
    [embedColumns],
  );

  return gridColumns;
};

import { FC, useState, useEffect, useMemo } from 'react';
import { keyBy } from 'utils/standard';
import cx from 'classnames';
import { FormGroup } from '@blueprintjs/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as RD from 'remotedata';
import { Updater } from 'use-immer';

import { sprinkles, Checkbox, Spinner, Input, Select, InfoIcon } from 'components/ds';
import { SelectValues } from 'components/ds/Select';

import { EndUserPortalConfiguration } from '../customerPortalConfiguration';
import { SchemasSection } from './SchemasSection';
import { GroupTagSection } from './GroupTagSection';
import { EmailSection } from './EmailSection';
import { PropertiesSection } from './PropertiesSection';

import {
  EndUser,
  Customer,
  AccessGroup,
  fetchUserTeam,
  GroupTag,
  HierarchyLevel,
  Properties,
} from 'actions/teamActions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { ACTION } from 'actions/types';
import { fetchDashboardList } from 'actions/dashboardActions';
import { fetchCustomerPotentialParents } from 'actions/customerActions';
import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { fetchCustomerParent } from 'reducers/thunks/customerThunks';
import { selectCustomerParent } from 'reducers/customersReducer';

import { doesCustomerHaveInvalidAccessGroup } from 'utils/customerUtils';
import { CUSTOMER_SELECTOR_QUERY_PARAMS } from 'constants/customerConstants';
import * as styles from '../styles.css';

export type EditorCustomer = {
  name: string;
  providedId: string;
  mapping: Record<string, string>;
  endUsers?: EndUser[];
  accessGroupId: number;
  isDemoGroup: boolean;
  properties: Properties;
  permissioned_dashboard_id?: number;
  selectedDashboardId?: number;
  selectedGroupTagIds: number[];
  emails: string[];
  hierarchyLevelId: number;
  parentProvidedId?: string;
};

export type CustomerContentProps = {
  onSubmit: () => void;
  selectedGroup?: Customer;
  accessGroups: AccessGroup[];
  groupTags: RD.ResponseData<GroupTag[]>;
  editorGroup: EditorCustomer;
  setEditorGroup: Updater<EditorCustomer>;
  hierarchyLevels: HierarchyLevel[];
};

export const CustomerContent: FC<CustomerContentProps> = ({
  selectedGroup,
  onSubmit,
  accessGroups,
  groupTags,
  editorGroup,
  setEditorGroup,
  hierarchyLevels,
}: CustomerContentProps) => {
  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState('');

  const {
    dataSources,
    parentSchemas,
    dashboards,
    teamData,
    pageDataLoading,
    customerPotentialParents,
    loadingPotentialParents,
    selectedParent,
  } = useSelector(
    (state: ReduxState) => ({
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      dashboards: state.dashboard.dashboardList,
      teamData: state.teamData.data,
      pageDataLoading: createLoadingSelector([ACTION.FETCH_DASHBOARD_LIST], true)(state),
      customerPotentialParents: state.customers.customerPotentialParents,
      loadingPotentialParents: RD.isLoading(state.customers.customerPotentialParentsStatus),
      selectedParent: state.customers.selectedParent,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(listTeamDataSources());
  }, [dispatch]);

  useEffect(() => {
    if (RD.isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  useEffect(() => {
    if (!dashboards && teamData) dispatch(fetchDashboardList({ id: teamData.id }));
  }, [dispatch, teamData, dashboards]);

  useEffect(() => {
    if (!selectedGroup) return;
    dispatch(fetchCustomerParent(selectedGroup.id));
  }, [dispatch, selectedGroup]);

  const hierarchyLevelsById = useMemo(() => keyBy(hierarchyLevels, 'id'), [hierarchyLevels]);

  const hasChildren = selectedGroup !== undefined && selectedGroup.children_count > 0;

  const hierarchyLevelValues = useMemo(() => {
    // If not parent, include all levels
    if (!selectedParent) {
      return hierarchyLevels.map(({ id, name }) => ({ value: id.toString(), label: name }));
    }

    // Only include levels lower than parent
    const parentLevel = hierarchyLevelsById[selectedParent.hierarchy_level_id].level;
    return hierarchyLevels.reduce((acc, { level, id, name }) => {
      if (level < parentLevel) {
        acc.push({ value: id.toString(), label: name });
      }
      return acc;
    }, [] as SelectValues<string>);
  }, [hierarchyLevels, hierarchyLevelsById, selectedParent]);

  const parentValues: SelectValues<string> = useMemo(
    () =>
      customerPotentialParents.reduce((acc, parent) => {
        if (parent.provided_id !== editorGroup.providedId) {
          acc.push({
            icon: 'list-tree',
            label: parent.name,
            value: parent.provided_id,
            secondaryLabel: hierarchyLevelsById[parent.hierarchy_level_id]?.name,
          });
        }
        return acc;
      }, [] as SelectValues<string>),
    [customerPotentialParents, editorGroup.providedId, hierarchyLevelsById],
  );

  useEffect(
    () =>
      dispatch(
        fetchCustomerPotentialParents({
          queryParams: {
            ...CUSTOMER_SELECTOR_QUERY_PARAMS,
            child_hierarchy_level: hierarchyLevelsById[editorGroup.hierarchyLevelId].level,
            search_string: searchString || undefined,
            access_group_id:
              editorGroup.accessGroupId === -1 ? undefined : editorGroup.accessGroupId,
          },
        }),
      ),
    [
      dispatch,
      editorGroup.hierarchyLevelId,
      searchString,
      hierarchyLevelsById,
      editorGroup.accessGroupId,
    ],
  );

  const accessGroupValues = useMemo(
    () =>
      accessGroups.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })),
    [accessGroups],
  );

  const renderAccessGroupsSection = () => {
    if (
      accessGroups.length <= 1 &&
      (!selectedGroup ||
        !doesCustomerHaveInvalidAccessGroup(
          selectedGroup,
          new Set((accessGroups ?? []).map((group) => group.id)),
        ))
    )
      return;

    return (
      <div className={sprinkles({ flexItems: 'column' })}>
        <div className={cx(styles.label, sprinkles({ paddingX: 'sp1.5', paddingTop: 'sp2' }))}>
          Data Visibility Group
          <InfoIcon
            text={
              hasChildren
                ? `You cannot change the data visibility group of a customer with children.`
                : 'Changing the data visibility group for this customer will remove its parent.'
            }
            tooltipSide="right"
          />
        </div>
        <Select
          className={styles.sectionRowContainer}
          disabled={hasChildren}
          onChange={(id) => {
            setEditorGroup((draft) => {
              draft.mapping = {};
              draft.accessGroupId = parseInt(id);
              draft.parentProvidedId = undefined;
            });
            dispatch(selectCustomerParent(undefined));
          }}
          placeholder="Select Data Visibility Group"
          selectedValue={editorGroup.accessGroupId.toString()}
          values={accessGroupValues}
        />
      </div>
    );
  };

  const renderInheritanceSection = () => {
    const onSelectParent = (parentId: string) => {
      const newParent = customerPotentialParents.find(
        ({ provided_id }) => provided_id === parentId,
      );

      // If parent is selected before access group is set, infer access group from selected parent
      // New parent should always be defined since parentValues is derived from customerPotentialParents
      setEditorGroup((draft) => {
        if (editorGroup.accessGroupId === -1) {
          draft.accessGroupId = newParent ? newParent.access_group_id : editorGroup.accessGroupId;
        }
        draft.parentProvidedId = parentId;
      });
      dispatch(selectCustomerParent(newParent));
    };

    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
        <div className={sprinkles({ heading: 'h3' })}>Inheritance</div>
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })}>
            <div className={styles.label}>
              Hierarchy Level
              <InfoIcon
                text="The selected hierarchy level must be below the parent's hierarchy level."
                tooltipSide="right"
              />
            </div>
            <Select
              disabled={hasChildren}
              onChange={(id) =>
                setEditorGroup((draft) => {
                  draft.hierarchyLevelId = Number(id);
                })
              }
              placeholder="Select hierarchy level"
              selectedValue={editorGroup.hierarchyLevelId?.toString()}
              values={hierarchyLevelValues}
            />
            {hasChildren ? (
              <div className={sprinkles({ color: 'contentTertiary', body: 'b3' })}>
                You cannot cannot change the hierarchy level of a customer with children.
              </div>
            ) : null}
          </div>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })}>
            <div className={styles.label}>
              Parent
              <InfoIcon
                text="This customer will inherit properties, group tags, data visibility group, and data source access from its parent. Parents and children must be in the same data visibility group."
                tooltipSide="right"
              />
            </div>
            <Select
              filterProps={{
                isLoading: loadingPotentialParents,
                placeholder: 'Filter for parent',
                onFilter: setSearchString,
                selectedLabel: selectedParent?.name,
              }}
              onCancel={() => {
                setEditorGroup((draft) => {
                  draft.parentProvidedId = undefined;
                });
                dispatch(selectCustomerParent(undefined));
              }}
              onChange={onSelectParent}
              placeholder="No parent"
              selectedValue={editorGroup?.parentProvidedId}
              values={parentValues}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderProfileContent = () => {
    return (
      <div>
        <div className={sprinkles({ heading: 'h3', marginBottom: 'sp3' })}>Profile</div>
        <FormGroup className={styles.formContainer} labelFor="text-input">
          <div className={styles.label}>Name</div>
          <Input
            onChange={(value) =>
              setEditorGroup((draft) => {
                draft.name = value;
              })
            }
            onEnter={onSubmit}
            placeholder="Name"
            value={editorGroup.name}
          />
        </FormGroup>
        <FormGroup className={styles.formContainer} labelFor="text-input">
          <div className={styles.label}>Provided ID</div>
          <div className={styles.formRowContainer}>
            <Input
              className={styles.flexGrowContainer}
              onChange={(value) =>
                setEditorGroup((draft) => {
                  draft.providedId = value;
                })
              }
              onEnter={onSubmit}
              placeholder="Provided ID"
              value={editorGroup.providedId}
            />
          </div>
        </FormGroup>

        {selectedGroup ? (
          <FormGroup className={styles.formContainer} labelFor="text-input">
            <div className={cx(sprinkles({ gap: 'sp.5' }), styles.label)}>Token</div>
            <div className={styles.formRowContainer}>
              <Input
                readOnly
                className={styles.flexGrowContainer}
                onChange={() => undefined}
                value={selectedGroup?.token}
              />
            </div>
            <div className={sprinkles({ body: 'b3', color: 'contentTertiary' })}>
              The customer token is no longer the recommended method for embedding. Please use embed
              secrets (JWTs) instead. Learn more{' '}
              <a href="https://docs.explo.co/embedding-documentation/embed-secrets">here</a>.
            </div>
          </FormGroup>
        ) : null}
        <GroupTagSection
          editorGroup={editorGroup}
          groupTags={groupTags}
          setEditorGroup={setEditorGroup}
        />
        <EmailSection editorGroup={editorGroup} setEditorGroup={setEditorGroup} />
        <FormGroup
          className={styles.formContainer}
          contentClassName={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}
          labelFor="text-input">
          <Checkbox
            isChecked={editorGroup.isDemoGroup}
            onChange={() =>
              setEditorGroup((draft) => {
                draft.isDemoGroup = !editorGroup.isDemoGroup;
              })
            }
          />
          <span className={sprinkles({ body: 'b2', color: 'gray11' })}>
            This is a demo or development account, exclude it from billing.
          </span>
          <InfoIcon
            text="Dashboards for a demo or development account will be watermarked."
            tooltipSide="right"
          />
        </FormGroup>
      </div>
    );
  };

  const renderDatabaseAssignment = () => {
    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
        <div className={sprinkles({ heading: 'h3' })}>Database Assignment</div>
        <div className={styles.sectionContainer}>
          {renderAccessGroupsSection()}
          <SchemasSection
            accessGroups={accessGroups}
            editorGroup={editorGroup}
            setEditorGroup={setEditorGroup}
          />
        </div>
      </div>
    );
  };

  const renderPageContent = () => {
    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp5' })}>
        {renderInheritanceSection()}
        {renderProfileContent()}
        <PropertiesSection editorGroup={editorGroup} setEditorGroup={setEditorGroup} />
        {renderDatabaseAssignment()}
        <div className={sprinkles({ marginBottom: 'sp3' })}>
          <EndUserPortalConfiguration
            dashboards={dashboards ?? []}
            endUsers={editorGroup.endUsers ?? []}
            selectedDashboardId={editorGroup.selectedDashboardId}
            updateEndUsers={(newEndUsers) =>
              setEditorGroup((draft) => {
                draft.endUsers = newEndUsers;
              })
            }
            updateSelectedDashboardId={(id) =>
              setEditorGroup((draft) => {
                draft.selectedDashboardId = id;
              })
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.contentContainer}>
      {!selectedGroup ? (
        <div className={sprinkles({ marginBottom: 'sp2' })}>
          If you&rsquo;ve connected to our API, then new customers will be added automatically.
        </div>
      ) : null}
      {pageDataLoading ||
      RD.isLoadingMulti([groupTags, parentSchemas, dataSources], true) ||
      !teamData ? (
        <div className={sprinkles({ flexItems: 'center', parentContainer: 'fill' })}>
          <Spinner size="xl" />
        </div>
      ) : (
        renderPageContent()
      )}
    </div>
  );
};

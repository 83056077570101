import { FC, useState, useMemo } from 'react';
import { IconButton, sprinkles } from 'components/ds';

import { TableDataset } from 'actions/dataSourceActions';
import { FidoTableView } from 'reducers/fidoReducer';

type Props = {
  table: TableDataset | FidoTableView;
};

/**
 * Displays the columns in a schema table
 */
export const SchemaTableViewer: FC<Props> = ({ table }) => {
  const [isOpen, setIsOpen] = useState(false);

  const schema = useMemo(() => {
    if (isOpen && table.schema) {
      return [...table.schema].sort((a, b) => a.name.localeCompare(b.name));
    }
  }, [isOpen, table.schema]);

  return (
    <div>
      <div
        className={sprinkles({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}
        onClick={() => setIsOpen(!isOpen)}>
        <IconButton name={isOpen ? 'caret-down' : 'caret-right'} />
        <div className={tableHeaderClass}>
          {'table_name' in table ? table.table_name : table.tableName}
        </div>
      </div>
      {isOpen ? (
        <div className={sprinkles({ flexItems: 'column', gap: 'sp.25', paddingX: 'sp2' })}>
          {schema?.map((col, index) => (
            <div className={tableColRowClass} key={`${col.name}-${index}`}>
              <div>{col.name}</div>
              <div>{col.type}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const tableHeaderClass = sprinkles({
  truncateText: 'ellipsis',
  fontSize: 12,
  fontWeight: 700,
  padding: 'sp.5',
  cursor: 'pointer',
});

const tableColRowClass = sprinkles({
  flexItems: 'alignCenterBetween',
  body: 'b3',
  borderRadius: 2,
  padding: 'sp.5',
  backgroundColor: { hover: 'activeSubdued' },
});

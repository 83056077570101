import {
  EditingLayout,
  setEditingLayout,
  setIsEditingEditableSection,
  setSelectedDashboardItem,
  clearSelectedDashboardItem,
} from 'reducers/dashboardInteractionsReducer';
import { DashboardLayoutThunk } from './dashboardLayoutThunks/types';
import { clearDataPanelData } from 'reducers/dashboardDataReducer';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { setEditableSectionModal } from 'reducers/dashboardEditConfigReducer';

type SelectOptions = {
  type?: DASHBOARD_ELEMENT_TYPES;
  setEditableSection?: boolean;
};

export const selectItemOnDashboardThunk =
  (id: string, options?: SelectOptions): DashboardLayoutThunk =>
  (dispatch) => {
    if (options?.setEditableSection) dispatch(setEditingLayout(EditingLayout.EDITABLE_SECTION));
    else dispatch(setEditingLayout(null));
    dispatch(setSelectedDashboardItem({ id, type: options?.type }));
  };

export const clearSelectedItemThunk = (): DashboardLayoutThunk => (dispatch, getState) => {
  const state = getState();

  const { isEditingEditableSection, interactionsInfo, selectedItem, editingLayout } =
    state.dashboardInteractions;

  if (!interactionsInfo.isEditing && !isEditingEditableSection) return;
  const editableSectionModalOpen =
    'dashboardEditConfig' in state && !!state.dashboardEditConfig.editableSectionModal;

  if (editableSectionModalOpen) dispatch(setEditableSectionModal(null));
  else if (selectedItem) dispatch(clearSelectedDashboardItem());
  else if (isEditingEditableSection) dispatch(setIsEditingEditableSection(false));
  else if (editingLayout) dispatch(setEditingLayout(null));
};

export const deleteDataPanelAftermathThunk =
  (dpId: string): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const selectedItem = getState().dashboardInteractions.selectedItem;

    dispatch(clearDataPanelData(dpId));
    if (selectedItem?.id === dpId) dispatch(clearSelectedDashboardItem());
  };

export const handleEditableSectionClickThunk = (): DashboardLayoutThunk => (dispatch, getState) => {
  const { isEditingEditableSection, interactionsInfo, selectedItem, editingLayout } =
    getState().dashboardInteractions;

  if (isEditingEditableSection) {
    if (selectedItem) dispatch(clearSelectedDashboardItem());
  } else if (interactionsInfo.isEditing && editingLayout !== EditingLayout.EDITABLE_SECTION)
    dispatch(setEditingLayout(EditingLayout.EDITABLE_SECTION));
};

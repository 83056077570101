import { FC, useEffect, useRef } from 'react';
import * as RD from 'remotedata';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, sprinkles, Button } from 'components/ds';

import {
  deleteCustomFont,
  fetchCustomFonts,
  uploadCustomFontFile,
} from 'actions/customFontsActions';
import { ReduxState } from 'reducers/rootReducer';

export const SettingsCustomFontsSection: FC = () => {
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const fonts = useSelector((state: ReduxState) => state.customFonts.customFonts);

  useEffect(() => {
    if (RD.isIdle(fonts)) dispatch(fetchCustomFonts());
  }, [fonts, dispatch]);

  const handleFile = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      dispatch(
        uploadCustomFontFile({ postData: { file: fileReader.result, fileName: files[0].name } }),
      );
    });

    fileReader.readAsDataURL(files[0]);
  };

  const deleteFont = (font: string) => {
    dispatch(deleteCustomFont({ postData: { fileName: font } }));
  };

  const renderEmptyFont = (text: string) => (
    <div className={sprinkles({ body: 'b1', flexItems: 'center' })} style={{ height: 40 }}>
      {text}
    </div>
  );

  const renderCustomFonts = (fonts: string[]) => {
    if (fonts.length === 0) return renderEmptyFont("You haven't uploaded any custom fonts.");

    return (
      <>
        {fonts.map((font) => {
          return (
            <div
              className={sprinkles({ paddingX: 'sp1.5', flexItems: 'alignCenterBetween' })}
              key={font}
              style={{ height: 40 }}>
              <div className={sprinkles({ body: 'b1' })}>{font}</div>
              <IconButton name="trash" onClick={() => deleteFont(font)} variant="destructive" />
            </div>
          );
        })}
      </>
    );
  };

  if (!fonts) return <></>;

  return (
    <div>
      <div className={sprinkles({ flexItems: 'alignCenterBetween', marginBottom: 'sp2' })}>
        <div className={sprinkles({ heading: 'h2' })}>Custom Fonts</div>
        <Button
          disabled={!RD.isSuccess(fonts)}
          onClick={() => inputFile.current?.click()}
          variant="primary">
          Upload a Font
        </Button>
      </div>
      <RD.RemoteComponent
        Error={() => renderEmptyFont('Error loading custom fonts')}
        Loading={() => renderEmptyFont('Loading...')}
        Success={renderCustomFonts}
        data={fonts}
      />
      <input
        accept=".otf,.woff,.ttf,.woff2"
        id="file"
        onChange={(event) => handleFile(event.target.files)}
        ref={inputFile}
        style={{ display: 'none' }}
        type="file"
      />
    </div>
  );
};

import { FC } from 'react';

import { DatePickerInput } from 'components/DatePickerInput';
import { Input, sprinkles } from 'components/ds';

import { dateTimeFromISOString } from 'utils/dateUtils';
import { DashboardVariable } from 'types/dashboardTypes';
import { DateTime } from 'luxon';
import { showWarningToast } from 'shared/sharedToasts';
import { DATE_TYPES } from 'constants/dataConstants';

type Props = {
  className?: string;
  type: string;
  value: DashboardVariable;
  onValueChange: (newValue?: DashboardVariable) => void;
};

export const DashboardVariableEntry: FC<Props> = ({ className, type, value, onValueChange }) => {
  const renderDateInput = () => (
    <DatePickerInput
      showCancelBtn
      withPortal
      className={sprinkles({ width: 'fill' })}
      onNewValueSelect={(newValue) => onValueChange((newValue as DateTime) || undefined)}
      selectedValue={typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)}
      showTimeSelect={false}
    />
  );

  const renderInput = () => {
    return (
      <Input
        className={sprinkles({ width: 'fill' })}
        defaultValue={value ? JSON.stringify(value as string | number | string[] | number[]) : ''}
        onSubmit={(newValue) => {
          if (!newValue) return onValueChange(undefined);
          try {
            newValue = JSON.parse(newValue);
            onValueChange(newValue);
          } catch {
            showWarningToast(
              'Please enter the variable as valid javascript. Strings should have quotes.',
              10,
            );
            return;
          }
        }}
        placeholder=""
      />
    );
  };

  return (
    <div className={className}>{DATE_TYPES.has(type) ? renderDateInput() : renderInput()}</div>
  );
};

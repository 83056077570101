import { FC, useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import cx from 'classnames';
import { isLoading, isSuccess, isError } from 'remotedata';

import { sprinkles, Spinner, Icon, ErrorFallback } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ReportChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChart';
import {
  EmbedDataGrid,
  EmbedModal,
  EmbedModalHeader,
  EmbedModalClose,
  EmbedModalFooter,
  EmbedButton,
} from 'components/embed';
import * as styles from 'pages/ReportBuilder/ModalViews/ReportAIModal.css';

import {
  fetchGenerateAiViewJob,
  fetchGenerateAiViewSuggestionsJob,
} from 'reportBuilderContent/thunks/aiThunks';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { fetchAiData } from 'reportBuilderContent/thunks';
import {
  getCurrentColorTracker,
  createView,
  closeReportModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { getSchemaAndColConfigs } from 'utils/customerReportUtils';
import { filterViewParams, isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { OPERATION_ICON_MAP, OPERATION_NAME_MAP } from 'pages/ReportBuilder/constants';
import { OPERATION_TYPES } from 'constants/types';
import { UseEmbedColumnsParams } from 'components/embed/EmbedDataGrid/useEmbedColumns';
import { useReportColumns } from 'pages/ReportBuilder/ReportView/useReportColumns';
import { setGeneratedView, setDatasetId } from 'reportBuilderContent/reducers/reportAiReducer';
import { reportError } from 'analytics/datadog';
import { ErrorBoundary } from 'react-error-boundary';
import { aiOutputToView } from 'reportBuilderContent/thunks/aiUtils';
import { getAiDataId } from 'reportBuilderContent/reducers/reportEditingUtils';

type Props = {
  dataInfo?: CustomerReportDataInfo;
  isIframe: boolean;
};

export const ReportAIModal: FC<Props> = ({ dataInfo, isIframe }) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState('');
  const [view, setView] = useState<CustomerReportView | undefined>(undefined);
  const [isExplanationOpen, setIsExplanationOpen] = useState(false);
  const [error, setError] = useState('');
  const [viewName, setViewName] = useState('Untitled Report'); // Store separately from view, so it doesn't trigger data fetching

  const { generatedView, versionConfig, aiData, colorCategoryTracker, suggestedViews } =
    useSelector(
      (state: ReportBuilderReduxState) => ({
        versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
        aiData: state.reportEditing.reportData[getAiDataId()],
        colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
        generatedView: state.reportAi.generatedView,
        suggestedViews: state.reportAi.suggestedViews[state.reportAi.datasetId],
      }),
      shallowEqual,
    );
  const dataset = dataInfo && versionConfig?.datasets[dataInfo.datasetId];

  const handleGenerate = () => {
    if (dataset?.schema) dispatch(fetchGenerateAiViewJob({ query, schema: dataset.schema }));
  };

  const handleClose = () => dispatch(closeReportModal());

  const handleCreateView = () => {
    dispatch(createView(view ? { ...view, name: viewName } : undefined));
    handleClose();
  };

  // If the modal has been closed and re-opened, autofill the query with the previous query
  useEffect(() => {
    if (isSuccess(generatedView))
      setQuery((query) => query || generatedView.data.promptInterpretation);
  }, [generatedView]);

  // Generate AI suggestions for this dataset
  useEffect(() => {
    if (dataset?.id && dataset.schema && !suggestedViews)
      dispatch(
        fetchGenerateAiViewSuggestionsJob({ datasetId: dataset.id, schema: dataset.schema }),
      );
  }, [dataset, dispatch, suggestedViews]);

  // When the dataset changes, reset the generated visualization because the suggestions are no longer valid
  const datasetId = dataInfo?.datasetId;
  useEffect(() => {
    if (datasetId) dispatch(setDatasetId(datasetId));
  }, [datasetId, dispatch]);

  useEffect(() => {
    try {
      if (dataset && isSuccess(generatedView)) {
        const view = aiOutputToView(
          generatedView.data.chartTitle,
          generatedView.data.exploChart,
          dataset,
        );
        setViewName(view?.name || 'Untitled Report');
        setView(view);
      }
    } catch {
      setError('Unable to generate chart');
    }
  }, [generatedView, dataset]);

  useEffect(() => {
    if (view) dispatch(fetchAiData(view));
  }, [dispatch, view]);

  const { schema, columnConfigs } = useMemo(
    () =>
      getSchemaAndColConfigs(
        dataset,
        view?.columnOrder || [],
        view?.hiddenColumns || [],
        view ? filterViewParams(view) : null,
      ),
    [dataset, view],
  );

  const columnParams: UseEmbedColumnsParams = useMemo(
    () => ({
      rows: aiData?.rows ?? [],
      schema,
      shouldTruncateText: true,
      disableCustomStyles: true,
      colorTracker: colorCategoryTracker,
      containerRef: modalRef,
      columnConfigs,
    }),
    [aiData, schema, colorCategoryTracker, columnConfigs],
  );

  const gridColumns = useReportColumns(columnParams);

  const isQueryTooShort = query.length < MIN_QUERY_LENGTH;
  const isQueryTooLong = query.length > MAX_QUERY_LENGTH;
  const isQueryInvalid = isQueryTooShort || isQueryTooLong;
  const hasGeneratedView = isSuccess(generatedView) && view;

  return (
    <EmbedModal isOpen isIframe={isIframe} onClose={handleClose} size="xlarge">
      <EmbedModalHeader title="AI Visualization Creator" />
      <div className={styles.modalContainer} ref={modalRef}>
        {isLoading(generatedView) ? (
          <div className={styles.loadingCard}>
            <Icon
              bounce
              className={sprinkles({ color: 'brandTertiary' })}
              name="sparkles"
              size="lg"
            />
            <EmbedText body="b2" className={styles.loadingText}>
              Generating your report with AI. This may take a minute...
            </EmbedText>
          </div>
        ) : (
          <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
            <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
              <Icon className={sprinkles({ color: 'brandTertiary' })} name="wand" />
              <EmbedText body="b2" color="contentSecondary">
                What would you like to generate a visualization of?
              </EmbedText>
            </div>
            <textarea
              className={styles.queryInput}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleGenerate();
                }
              }}
              placeholder="Describe what you want to visualize or ask a question you'd like to answer about your data"
              rows={3}
              style={{ resize: 'vertical' }}
              value={query}
            />
            <EmbedButton
              fillWidth
              className={styles.generateButton}
              disabled={isQueryInvalid}
              icon="wand"
              onClick={handleGenerate}
              tooltipProps={
                isQueryInvalid
                  ? {
                      side: 'bottom',
                      align: 'end',
                      text: `Write ${
                        isQueryTooShort
                          ? `at least ${MIN_QUERY_LENGTH}`
                          : `at most ${MAX_QUERY_LENGTH}`
                      } characters to generate a visualization`,
                    }
                  : undefined
              }
              variant="primary">
              Generate visualization
            </EmbedButton>
          </div>
        )}
        {error ? (
          <EmbedText body="b2" color="warning">
            {error}
          </EmbedText>
        ) : null}
        {hasGeneratedView ? (
          <>
            <div className={styles.iconHeading}>
              <Icon className={sprinkles({ color: 'brandTertiary' })} name="sparkles" />
              <EmbedText heading="h3">Generated Visualization</EmbedText>
            </div>

            <div className={styles.section}>
              <EmbedText body="b3" color="contentSecondary">
                Visualization Name
              </EmbedText>
              <input
                className={styles.nameInput}
                onChange={(e) => setViewName((prev) => e.target.value || prev)}
                placeholder="Enter a name for your view"
                type="text"
                value={viewName}
              />
            </div>

            <div className={styles.section}>
              <EmbedText body="b3" color="contentSecondary">
                Pick a Visualization Type
              </EmbedText>
              <div className={styles.iconHeading}>
                {[
                  OPERATION_TYPES.VISUALIZE_TABLE,
                  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
                  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
                  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
                  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
                ].map((op) => (
                  <div
                    className={cx(
                      styles.visualizationCard,
                      aiData?.isLoading || aiData?.error
                        ? styles.disabledCard
                        : op === view?.visualization
                        ? styles.activeCard
                        : styles.defaultCard,
                    )}
                    key={op}
                    onClick={() => {
                      if (aiData?.isLoading || aiData?.error) return;
                      setView((view) =>
                        view
                          ? { ...view, visualization: op as CustomerReportView['visualization'] }
                          : undefined,
                      );
                    }}>
                    <Icon
                      className={sprinkles({ color: 'contentSecondary' })}
                      name={OPERATION_ICON_MAP[op] || 'table'}
                    />
                    <EmbedText heading="h4">{OPERATION_NAME_MAP[op]}</EmbedText>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.section}>
              <EmbedText body="b3" color="contentSecondary">
                Visualization Preview
              </EmbedText>
              <div className={styles.reportCard}>
                {aiData?.isLoading ? (
                  <>
                    <Spinner size="lg" />
                    <EmbedText body="b2">Loading visualization data...</EmbedText>
                  </>
                ) : aiData?.error ? (
                  <EmbedText body="b2">{aiData.error}</EmbedText>
                ) : aiData ? (
                  isTableVisualization(view?.visualization) ? (
                    <EmbedDataGrid
                      columnConfigs={columnConfigs}
                      columns={gridColumns}
                      rowHeight={40}
                      rows={aiData.rows ?? []}
                      schema={schema}
                    />
                  ) : (
                    <ReportChart
                      containerRef={modalRef}
                      dataset={dataset}
                      reportData={aiData}
                      view={view}
                    />
                  )
                ) : null}
              </div>
            </div>

            <div
              className={styles.additionalInfoContainer}
              onClick={() => setIsExplanationOpen((prev) => !prev)}>
              <div className={styles.additionalInfoHeader}>
                <div className={styles.iconHeading}>
                  <Icon name="circle-info" />
                  <EmbedText body="b2" color="violet9">
                    How did we generate this visualization?
                  </EmbedText>
                </div>
                <Icon name={isExplanationOpen ? 'caret-up' : 'caret-down'} />
              </div>
              {isExplanationOpen && (
                <>
                  <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
                    <EmbedText
                      body="b2"
                      className={sprinkles({ fontWeight: 700 })}
                      color="contentSecondary">
                      How we Interpreted Your Question
                    </EmbedText>
                    <EmbedText body="b1">{generatedView.data.promptInterpretation}</EmbedText>
                  </div>

                  <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
                    <EmbedText
                      body="b2"
                      className={sprinkles({ fontWeight: 700 })}
                      color="contentSecondary">
                      Assumptions About Your Data
                    </EmbedText>
                    <EmbedText body="b1">{generatedView.data.promptAssumptions}</EmbedText>
                  </div>
                </>
              )}
            </div>
          </>
        ) : !isLoading(generatedView) ? (
          <div className={styles.section}>
            <EmbedText body="b3" color="contentSecondary">
              Examples
            </EmbedText>
            {isSuccess(suggestedViews) ? (
              <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
                {suggestedViews.data.charts.map((chart) => (
                  <div
                    className={styles.suggestionCard}
                    key={chart.promptInterpretation}
                    onClick={() => {
                      dispatch(setGeneratedView(chart));
                      setQuery(chart.promptInterpretation);
                    }}>
                    <Icon className={sprinkles({ color: 'brandTertiary' })} name="wand" />
                    <EmbedText body="b2">{chart.promptInterpretation}</EmbedText>
                  </div>
                ))}
              </ErrorBoundary>
            ) : isLoading(suggestedViews) ? (
              <div className={styles.loadingCard}>
                <Icon
                  bounce
                  className={sprinkles({ color: 'brandTertiary' })}
                  name="sparkles"
                  size="lg"
                />
                <EmbedText body="b2" className={styles.loadingText}>
                  Feel free to write your own prompt or wait for example questions to generate. This
                  may take a minute...
                </EmbedText>
              </div>
            ) : isError(suggestedViews) ? (
              <div className={styles.loadingCard}>
                <EmbedText body="b2">Unable to load examples</EmbedText>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <EmbedModalFooter>
        <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
        {hasGeneratedView ? (
          <EmbedModalClose
            disabled={!hasGeneratedView}
            onClick={handleCreateView}
            variant="primary">
            Create view
          </EmbedModalClose>
        ) : null}
      </EmbedModalFooter>
    </EmbedModal>
  );
};

const MIN_QUERY_LENGTH = 10;
const MAX_QUERY_LENGTH = 500;

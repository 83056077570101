import { FC } from 'react';

import { ColorPicker } from 'components/ColorPicker';
import { Input, Label, sprinkles } from 'components/ds';

import { GlobalStyleConfig, GlobalStyleModalConfig } from 'globalStyles/types';
import { getGlobalStyleModalInfo } from 'globalStyles/getGlobalStyleVars/utils';
import produce from 'immer';
import { DEFAULT_COMPONENTS_CONFIG } from 'globalStyles/constants';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export const ModalConfig: FC<Props> = ({ styleConfig, updateConfig }) => {
  const { overlayBackgroundColor, overlayOpacity } = getGlobalStyleModalInfo(
    styleConfig.components?.modal,
  );

  const handleChange = (updates: Partial<GlobalStyleModalConfig>) => {
    const newConfig = produce(styleConfig, (draft) => {
      if (!draft.components) draft.components = { ...DEFAULT_COMPONENTS_CONFIG };

      draft.components.modal = { ...draft.components.modal, ...updates };
    });
    updateConfig(newConfig);
  };

  return (
    <div className={sprinkles({ padding: 'sp2', flexItems: 'column', gap: 'sp2' })}>
      <div>
        <Label htmlFor="">Overlay Background Color</Label>
        <ColorPicker
          fill
          color={overlayBackgroundColor}
          onClose={(newBackgroundColor) =>
            handleChange({ overlayBackgroundColor: newBackgroundColor })
          }
        />
      </div>
      <Input
        defaultValue={overlayOpacity.toString()}
        label="Overlay Opacity"
        onSubmit={(newOpacity) => {
          const opacityNum = parseFloat(newOpacity);
          if (isNaN(opacityNum) || opacityNum > 1 || opacityNum <= 0) return;
          handleChange({ overlayOpacity: opacityNum.toString() });
        }}
      />
    </div>
  );
};

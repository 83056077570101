import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedButton } from 'components/embed';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { openAiModalThunk } from 'reportBuilderContent/thunks/aiThunks';

type Props = { datasetId?: string };
export const ReportAIButton: FC<Props> = ({ datasetId }) => {
  const dispatch = useDispatch();
  const isAiEnabled = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.isAiEnabled,
  );

  const tooltipText = datasetId
    ? 'Generate a new view using AI'
    : 'Select a dataset before generating a view using AI';

  return isAiEnabled ? (
    <EmbedButton
      disabled={!datasetId}
      icon="wand"
      onClick={() => dispatch(openAiModalThunk())}
      tooltipProps={{ align: 'center', side: 'bottom', text: tooltipText }}
      variant="secondary">
      Report AI
    </EmbedButton>
  ) : null;
};

import { cloneDeep } from 'utils/standard';

import {
  CustomerReportAgg,
  CustomerReportGroupBy,
  SortableChartOptions,
} from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import {
  OPERATION_TYPES,
  V2KPIChartInstructions,
  V2TwoDimensionChartInstructions,
  NumberDisplayOptions,
  LegendPosition,
  Aggregation,
  SortAxis,
  SortOption,
} from 'constants/types';
import { getAggUniqueId } from 'utils/customerReportUtils';
import { DatasetRow } from 'types/datasets';
import { DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  ROWS_SECTION_ID,
  AGGS_SECTION_ID,
} from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { getAggColDisplay, getAggColConfig, getGroupByDisplay } from 'utils/V2ColUtils';

// The number of allowed group bys changes based on the current visualization types
export function getGroupByLimit(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
) {
  return visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 && aggregations?.length === 1
    ? 2
    : isTableVisualization(visualization)
    ? undefined
    : 1;
}

export function getGroupByDisabled(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
) {
  const groupByLimit = getGroupByLimit(visualization, aggregations);
  return groupBys != null && groupByLimit != null && groupBys.length >= groupByLimit;
}

export function getColumnGroupByDisabled(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
  activeSection?: string,
) {
  return (
    !groupBys?.length ||
    !aggregations?.length ||
    (activeSection === ROWS_SECTION_ID
      ? !groupBys || groupBys.length < 2
      : activeSection === AGGS_SECTION_ID && (!aggregations || aggregations.length < 2))
  );
}

// The number of allowed aggs changes based on the current visualization types
export function getAggLimit(visualization?: OPERATION_TYPES, groupBys?: CustomerReportGroupBy[]) {
  return !visualization
    ? undefined
    : [OPERATION_TYPES.VISUALIZE_NUMBER_V2, OPERATION_TYPES.VISUALIZE_PIE_CHART_V2].includes(
        visualization,
      )
    ? 1
    : visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 && groupBys && groupBys.length > 1
    ? 1
    : undefined;
}
export function getAggDisabled(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
) {
  const aggLimit = getAggLimit(visualization, groupBys);
  return aggregations != null && aggLimit != null && aggregations.length >= aggLimit;
}

function getNumberFormat(displayFormatting: NumberDisplayOptions) {
  return Object.values(V2_NUMBER_FORMATS).find(
    (format) => format.name === displayFormatting?.format,
  );
}

// Common instructions shared between all charts
export function customerReportToSharedInstructions(
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
  columnConfigs?: ReportBuilderColConfigs,
): Partial<V2TwoDimensionChartInstructions> {
  const firstGroupBy = groupBys?.[0];
  const groupByName = firstGroupBy ? getGroupByDisplay(firstGroupBy, columnConfigs) : undefined;
  const columnConfig = firstGroupBy ? columnConfigs?.[firstGroupBy.column.name] : undefined;
  const numberDisplayOptions = columnConfig?.displayFormatting as NumberDisplayOptions;
  const numberFormat = getNumberFormat(numberDisplayOptions);

  return {
    legendFormat: { position: LegendPosition.BOTTOM },
    aggColumns: aggregations?.map((agg) => {
      const friendlyName = getAggColDisplay(agg, columnConfigs);
      return {
        ...agg,
        column: { ...agg.column, friendly_name: friendlyName },
        yAxisFormatId: getAggUniqueId(agg),
      };
    }),
    categoryColumn: firstGroupBy
      ? {
          column: firstGroupBy.column,
          bucket: firstGroupBy.bucket ? { id: firstGroupBy.bucket } : undefined,
        }
      : undefined,
    xAxisFormat: {
      title: groupByName,
      showTitle: !!groupByName,
      showDecimals: !!numberDisplayOptions?.decimalPlaces,
      decimalPlaces: numberDisplayOptions?.decimalPlaces,
      numberFormat,
      hideTotalValues: true,
    },
    yAxisFormats: aggregations?.map((agg) => {
      const aggColName = getAggColDisplay(agg, columnConfigs);
      const aggDisplayFormatting = getAggColConfig(agg, columnConfigs)
        ?.displayFormatting as NumberDisplayOptions;
      const aggNumberFormat = getNumberFormat(aggDisplayFormatting);
      return {
        id: getAggUniqueId(agg),
        title: aggColName,
        showTitle: !!aggColName,
        showDecimals: !!aggDisplayFormatting?.decimalPlaces,
        decimalPlaces: aggDisplayFormatting?.decimalPlaces,
        numberFormat: aggNumberFormat,
      };
    }),
  };
}

export function getCurrentSortValues(sortableOptions: SortableChartOptions | undefined): {
  sortAxis: SortAxis;
  sortOption: SortOption;
} {
  return {
    sortAxis: sortableOptions?.axis ?? SortAxis.CAT_AXIS,
    sortOption: sortableOptions?.direction ?? SortOption.DESC,
  };
}

export function customerReportToBarChartInstructions(
  aggregations: CustomerReportAgg[] | undefined,
  groupBys: CustomerReportGroupBy[] | undefined,
  columnConfigs: ReportBuilderColConfigs | undefined,
  sortableOptions: SortableChartOptions | undefined,
): V2TwoDimensionChartInstructions {
  const instructions = customerReportToSharedInstructions(aggregations, groupBys, columnConfigs);
  return {
    ...instructions,
    xAxisFormat: {
      ...instructions.xAxisFormat,
      ...getCurrentSortValues(sortableOptions),
      enableScroll: true,
    },
    // Only allow a single level of grouping
    colorColumnOptions: groupBys?.slice(1, 2).map(({ column }) => ({ column })),
  };
}

export function customerReportToSingleNumberChartInstructions(
  aggregations?: CustomerReportAgg[],
  columnConfigs?: ReportBuilderColConfigs,
): V2KPIChartInstructions {
  const firstAgg = aggregations?.[0];
  if (!firstAgg) return {};

  const column = { ...firstAgg.column, friendly_name: getAggColDisplay(firstAgg, columnConfigs) };
  const agg =
    firstAgg.agg.id === Aggregation.FORMULA
      ? // For custom aggs, singleNumberChart expects a formula, but it's only injected when fetching data so give a fake one
        { id: Aggregation.FORMULA, formula: 'fake_formula' }
      : firstAgg.agg;

  return { aggColumn: { column, agg, yAxisFormatId: '' } };
}

export function cloneRowsIfDate(groupBys?: CustomerReportGroupBy[], rows?: DatasetRow[]) {
  // Charts mutate previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const isGroupedByDate = rows && groupBys?.find((groupBy) => DATE_TYPES.has(groupBy?.column.type));
  if (isGroupedByDate) return cloneDeep(rows);
  return rows ?? [];
}

export const isLineOrBarVisualization = (visualization: OPERATION_TYPES | undefined) =>
  visualization === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
  visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2;

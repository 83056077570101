import { useDispatch } from 'react-redux';
import { some } from 'utils/standard';
import produce from 'immer';
import { AppToaster } from 'toaster';

import { SettingHeader } from 'components/SettingHeader';
import DroppableColumnSection from './droppable/DroppableColumnSection';

import {
  Aggregation,
  OPERATION_TYPES,
  VisualizeCollapsibleListInstructions,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import {
  resolveAggColDropped,
  getNewAggCol,
  resolveCategoryColDropped,
} from 'utils/dataPanelColUtils';
import { showCustomToast } from 'shared/sharedToasts';
import { findMatchingAggColIdx, findMatchingAgg } from 'utils/aggUtils';
import { PivotAgg } from 'types/dateRangeTypes';
import { AggregationType } from 'types/columnTypes';

type Props = {
  instructions: VisualizeCollapsibleListInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function CollapsibleListConfig({ instructions, chartType, loading, schema }: Props) {
  const dispatch = useDispatch();

  const onRowColumnAdded = (newCol: DatasetColumn, oldColName?: string) => {
    const newInstructions = produce(instructions, (draft) => {
      if (some(draft.rowColumns, (opt) => opt.column.name === newCol.name)) {
        showCustomToast(
          'The selected row is already present for this column. Duplicates are not allowed.',
          { icon: 'warning-sign' },
        );
        return;
      }
      // This is case we are adding a new column
      if (oldColName === undefined) {
        if (!draft.rowColumns) draft.rowColumns = [];
        draft.rowColumns.push(resolveCategoryColDropped(newCol));
        return;
      }

      // This is case we are replacing an old column
      if (!draft.rowColumns) return;
      const removedColIndex = draft.rowColumns.findIndex((col) => col.column.name === oldColName);
      if (removedColIndex === -1) return;
      draft.rowColumns.splice(removedColIndex, 1, resolveCategoryColDropped(newCol));
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
  };

  const onAggregationColumnAdded = (
    newCol: DatasetColumn,
    oldColName?: string,
    oldColAggType?: AggregationType,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      // This is case we are adding a new column
      if (oldColName === undefined) {
        draft.aggregations = resolveAggColDropped(
          newCol,
          (toastInfo) => AppToaster.show(toastInfo),
          draft.aggregations ? draft.aggregations : [],
          undefined,
          undefined,
          true,
        );
        return;
      }
      // This is case we are replacing an old column
      if (!draft.aggregations) return;
      const removedColIndex = findMatchingAggColIdx(draft.aggregations, oldColName, oldColAggType);
      if (removedColIndex == -1) return;

      const changedCol = getNewAggCol(
        newCol,
        (toastInfo) => AppToaster.show(toastInfo),
        draft.aggregations ? draft.aggregations : [],
        undefined,
        true,
      );
      if (changedCol === undefined) return;
      draft.aggregations?.splice(removedColIndex, 1, changedCol);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <div>
      <SettingHeader name="Rows" />
      <DroppableColumnSection
        required
        columns={instructions.rowColumns ?? []}
        disableEdits={loading}
        onColAdded={onRowColumnAdded}
        onColOptionChanged={(option, name) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.rowColumns?.length) {
              const indexToChange = draft.rowColumns.findIndex(
                (rowCol) => rowCol.column.name === name,
              );
              if (indexToChange < 0) return;
              draft.rowColumns[indexToChange].bucket = { id: option.id as PivotAgg };
            }
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={(name) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.rowColumns?.length === 1) {
              draft.rowColumns = undefined;
            } else {
              const removedColIndex = (draft.rowColumns || []).findIndex(
                (col) => col.column.name === name,
              );
              draft.rowColumns?.splice(removedColIndex, 1);
            }
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />

      <SettingHeader name="Aggregations" />
      <DroppableColumnSection
        isCollapsibleListAgg
        required
        columns={instructions.aggregations ? instructions.aggregations : []}
        disableEdits={loading}
        onColAdded={onAggregationColumnAdded}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const changedCol = findMatchingAgg(draft.aggregations, name, aggType);
            if (!changedCol) return;

            if (findMatchingAggColIdx(draft.aggregations, name, option) >= 0) {
              showCustomToast(
                'The selected aggregation is already present for this column. Duplicates are not allowed.',
                { icon: 'warning-sign' },
              );

              return;
            }

            changedCol.agg = { id: option.id as Aggregation, formula: option.formula };
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={(col, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.aggregations?.length === 1) draft.aggregations = undefined;
            else {
              const removedColIndex = findMatchingAggColIdx(draft.aggregations, col.name, aggType);
              draft.aggregations?.splice(removedColIndex, 1);
            }
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
    </div>
  );
}

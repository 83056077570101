import { useState, FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ConnectDataSourceContainer } from 'pages/ConnectDataSourceFlow/ConnectDataSourceContainer';
import { CredentialsSection } from './shared/CredentialsSection';
import { AlertModal, sprinkles, CalloutLink } from 'components/ds';
import { FidoCredentialsSection } from './shared/FidoCredentialsSection';

import { ConnectDataSourceStep, DatabasesToPort, PortToDatabase } from '../constants';
import { ReduxState } from 'reducers/rootReducer';
import { DBConnectionConfig } from '../types';
import { dataSourceByType } from 'constants/dataSourceConstants';
import { areCredentialsComplete, getPropertyNames, buildConfigWithoutTunnel } from '../utils';
import { SupportedDataSource, DataSourceConfiguration } from 'actions/dataSourceActions';
import { computeUseFidoForConnectingOrUpdating } from 'reducers/fidoDataSourceConfigurationReducer';

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
};

export const EnterCredentials: FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
}) => {
  const { fidoConfig, useFido } = useSelector(
    (state: ReduxState) => ({
      fidoConfig: state.fidoDataSourceConfig.config,
      useFido: computeUseFidoForConnectingOrUpdating(state),
    }),
    shallowEqual,
  );

  const [openModal, setOpenModal] = useState(false);
  const dataSource = config.selectedDataSource;

  if (!dataSource) return <div>Oops</div>;

  const configuration = config.dataSourceConfig || {};

  const port = (useFido ? fidoConfig?.port?.toString() : configuration?.port?.toString()) ?? '';
  const expectedPort = dataSource.name !== undefined ? DatabasesToPort[dataSource.name] : undefined;

  const getModalBodyText = () => {
    const expectedDatabases = PortToDatabase[port];
    const normallyUsedText =
      port in PortToDatabase
        ? `(which is normally used by ${
            expectedDatabases.length === 2
              ? `${expectedDatabases[0]} or ${expectedDatabases[1]}`
              : expectedDatabases[0]
          })`
        : '';
    return `${dataSource.name} database are usually on port ${expectedPort} but you have selected port ${port} ${normallyUsedText}. If there is an error, please go back and select the correct database type before proceeding.`;
  };

  const onNextClickedCheckPort = () => {
    if (expectedPort === undefined || expectedPort === port) onNextClicked();
    else setOpenModal(true);
  };

  return (
    <>
      <ConnectDataSourceContainer
        backBtnOnClick={onBackClicked}
        bodyContent={<EnterCredentialsBody config={config} updateConfig={updateConfig} />}
        currentStep={ConnectDataSourceStep.ENTER_CREDS}
        headerSubtitle="If you don't have your database credentials, contact your system administrator."
        headerTitle="Enter Credentials"
        primaryActionConfig={{
          text: 'Next',
          disabled: useFido
            ? !buildConfigWithoutTunnel(fidoConfig, true)
            : dataSource &&
              !areCredentialsComplete(
                dataSource.configuration_schema.properties,
                configuration,
                false,
              ),
          onClick: onNextClickedCheckPort,
        }}
      />
      <AlertModal
        actionButtonProps={{ text: 'Connect anyway', onClick: onNextClicked, variant: 'primary' }}
        cancelButtonProps={{ text: 'Go back' }}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Mismatched credentials with database">
        {getModalBodyText()}
      </AlertModal>
    </>
  );
};

type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  selectedDataSource?: SupportedDataSource;
  userViewableCredentials?: DataSourceConfiguration;
  headerClassName?: string;
};

export const EnterCredentialsBody: FC<BodyProps> = ({
  config,
  updateConfig,
  selectedDataSource,
  userViewableCredentials,
  headerClassName,
}) => {
  const useFido = useSelector((state: ReduxState) => computeUseFidoForConnectingOrUpdating(state));

  const dataSource = config.selectedDataSource ?? selectedDataSource;
  if (!dataSource)
    return (
      <div className={sprinkles({ body: 'b1' })}>
        <b>Error:</b> A data source type must be selected before proceeding
      </div>
    );

  const helpCenterUrl = dataSourceByType[dataSource.name].credentialsLink;

  return (
    <div>
      {useFido ? (
        <FidoCredentialsSection />
      ) : (
        <CredentialsSection
          config={config}
          dataSource={dataSource}
          headerClassName={headerClassName}
          propertyNames={getPropertyNames(dataSource.configuration_schema, false)}
          updateConfig={updateConfig}
          userViewableCredentials={userViewableCredentials}
        />
      )}
      {helpCenterUrl && (
        <CalloutLink text={`How to find ${dataSource.name} credentials`} url={helpCenterUrl} />
      )}
    </div>
  );
};

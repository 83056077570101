import { DataPanelResponse } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';

export type QueryDebuggingInformation = {
  _query: string;
  _query_timing: QueryTiming;
};

export type QueryTiming = {
  time_to_connect?: string;
  time_to_ssh?: string;
  time_to_run?: string;
  time_to_process?: string;
  total_time?: string;

  cache_hit?: boolean;
};

export type ErrorResponse = {
  detail: string;
  status: number;
  error_msg?: string;
  error_code?: ApiException;
};

export type ExportUrlResponse = {
  url?: string;
};

export type FetchDataPanelData = {
  data_panel_template: DataPanelResponse;
  query_information?: QueryDebuggingInformation;
};

export type FetchDataPanelRowCountData = {
  _total_row_count: number;
};

export type FetchDashboardDatasetPreviewData = {
  dataset_preview: {
    schema: DatasetSchema;
    _rows: DatasetRow[];
    _unsupported_operations: DatabaseUnsupportedOperations[];
  };
  query_information?: QueryDebuggingInformation;
};

export enum ApiException {
  VERSION_OUT_OF_DATE = 'version_out_of_date',
}

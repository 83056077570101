import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseSaveModal } from 'pages/ReportBuilder/ModalViews/BaseSaveModal';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { createCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';

type Props = { reportId: number; reportName: string; isIframe: boolean };

export const SaveAsModal: FC<Props> = ({ reportId, reportName, isIframe }) => {
  const dispatch = useDispatch();

  const currentConfig = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.currentConfig,
  );

  if (!currentConfig) return null;

  const handleSubmit = (newReportName: string) =>
    dispatch(createCustomerReportThunk({ name: newReportName, config: currentConfig, reportId }));

  return <BaseSaveModal isIframe={isIframe} onSubmit={handleSubmit} reportName={reportName} />;
};

import { FC } from 'react';

import * as styles from '../styles.css';
import { IconButton, Select, sprinkles } from 'components/ds';

import * as constants from '../constants';
import { AccessGroup } from 'actions/teamActions';
import { cloneDeep, intersection } from 'utils/standard';
import { AccessGroupDataSourcesMap } from 'constants/types';
import { DataSource } from 'actions/dataSourceActions';

type Props = {
  accessGroup: AccessGroup;
  accessGroupDataSourcesMap?: AccessGroupDataSourcesMap;
  accessGroupIdToDefaultDataSourceIdUpdates?: Record<number, number[]>;
  dataSources?: DataSource[];
  updateAccessGroupDefaultDataSource: (
    accessGroupId: number,
    newDefaultDataSourceIds: number[],
  ) => void;
  isInModal?: boolean;
};

export const AccessGroupDropdown: FC<Props> = ({
  accessGroup,
  accessGroupDataSourcesMap,
  accessGroupIdToDefaultDataSourceIdUpdates,
  dataSources,
  updateAccessGroupDefaultDataSource,
  isInModal,
}) => {
  const accessGroupInfo = accessGroupDataSourcesMap?.[accessGroup.id];
  const availableDataSourceIds = accessGroupInfo?.availableDataSourceIds;
  const defaultDataSourceIdUpdates = accessGroupIdToDefaultDataSourceIdUpdates?.[accessGroup.id];
  const newDefault = intersection(defaultDataSourceIdUpdates, availableDataSourceIds)[0];

  const defaultDataSourceId = newDefault || accessGroupInfo?.defaultDataSourceId;
  const hasAvailableDataSources = !!availableDataSourceIds?.length;

  const getDataSourceName = (id: number | undefined) => {
    if (!id) return constants.DS_SELECTION_TEXT;
    return dataSources?.find((dataSource) => dataSource.id === id)?.name || '';
  };

  return (
    <div className={styles.labelAndInput}>
      <div className={styles.sideSheetLabelAndIcon}>
        <p className={styles.label}>
          {!isInModal ? accessGroup.name : `[${accessGroup.name}]: Default Data Source`}
        </p>
        {!hasAvailableDataSources ? (
          <IconButton
            className={styles.dropdownExclamationMark}
            name="circle-exclamation-reg"
            tooltipProps={{ text: constants.NO_AVAILABLE_DS_TEXT }}
            variant="tertiary"
          />
        ) : !defaultDataSourceId ? (
          <IconButton
            className={styles.dropdownExclamationMark}
            name="circle-exclamation-reg"
            tooltipProps={{ text: constants.NO_DEFAULT_DS_TEXT }}
            variant="tertiary"
          />
        ) : null}
      </div>
      <Select
        className={sprinkles({ width: 'fill' })}
        disabled={!hasAvailableDataSources}
        onChange={(value) => {
          const dataSourceId = parseInt(value);
          let newDefaults = cloneDeep(
            defaultDataSourceIdUpdates ?? accessGroup.default_data_source_ids,
          );

          if (defaultDataSourceId !== undefined) {
            // remove old default
            newDefaults = newDefaults.filter(
              (dataSourceId) => defaultDataSourceId !== dataSourceId,
            );
          }

          newDefaults.push(dataSourceId);

          updateAccessGroupDefaultDataSource(accessGroup.id, newDefaults);
        }}
        placeholder="Select Schema"
        selectedValue={defaultDataSourceId?.toString()}
        values={(availableDataSourceIds || []).map((value) => {
          return {
            label: getDataSourceName(value),
            value: value.toString(),
          };
        })}
      />
    </div>
  );
};

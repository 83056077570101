import { IconName } from 'components/ds/Icon';
import { OPERATION_TYPES } from 'constants/types';
import {
  INTEGER_DATA_TYPE,
  UNSIGNED_INTEGER,
  FLOAT,
  STRING,
  BOOLEAN,
  DATETIME,
  DATE,
  TIMESTAMP,
} from 'constants/dataConstants';

export const REPORT_BUILDER_VISUALIZATION_TYPES = [
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
];

export const OPERATION_NAME_MAP: Partial<Record<OPERATION_TYPES, string>> = {
  [OPERATION_TYPES.VISUALIZE_TABLE]: 'Table',
  [OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Line',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Bar',
  [OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Pie',
  [OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Donut',
  [OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
};

export const OPERATION_ICON_MAP: Partial<Record<OPERATION_TYPES, IconName>> = {
  [OPERATION_TYPES.VISUALIZE_TABLE]: 'table',
  [OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'chart-line',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'chart-column',
  [OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'chart-pie-simple',
  [OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'chart-pie-simple',
  [OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'report-builder-kpi',
};

export const GROUP_BY_VISUALIZATION_TYPES = [
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
];

export const SCHEMA_TYPE_ICON_MAP: Partial<Record<string, IconName>> = {
  [INTEGER_DATA_TYPE]: 'report-builder-number',
  [UNSIGNED_INTEGER]: 'report-builder-number',
  [FLOAT]: 'report-builder-decimal',
  [STRING]: 'report-builder-string',
  [BOOLEAN]: 'tick',
  [DATETIME]: 'calendar',
  [DATE]: 'calendar',
  [TIMESTAMP]: 'calendar',
};

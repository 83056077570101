import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import { Input, sprinkles } from 'components/ds';
import { GradientConfiguration } from 'components/ColumnFormatConfigs/NumberFormatConfig/GradientConfiguration';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { CollapsibleGroup } from 'components/CollapsibleGroup';

import { EmptyV2PivotInstructions, V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { getAggColName, getGroupByName } from 'utils/V2ColUtils';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { NumberDisplayOptions, OPERATION_TYPES } from 'constants/types';

type Props = {
  instructions: V2PivotTableInstructions | undefined;
};

export const V2PivotColumnsConfig: FC<Props> = ({ instructions = EmptyV2PivotInstructions }) => {
  const dispatch = useDispatch();
  const { rowGroupBys, columnConfigs, aggregations } = instructions;

  const updateInstructions = (newInstructions: V2PivotTableInstructions) =>
    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2));

  const updateNumberOptions = (configKey: string, newOptions: NumberDisplayOptions) => {
    const newInstructions = produce(instructions, (draft) => {
      const config = draft.columnConfigs[configKey];
      if (!config) return;
      config.displayFormatting = { ...config.displayFormatting, ...newOptions };
    });
    updateInstructions(newInstructions);
  };

  const updateColTitleInput = (configKey: string, colDisplay: string) => (
    <Input
      defaultValue={colDisplay}
      label="Column Title"
      onSubmit={(value) => {
        const newInstructions = produce(instructions, (draft) => {
          draft.columnConfigs[configKey].name = value;
        });
        updateInstructions(newInstructions);
      }}
    />
  );

  return (
    <div className={sprinkles({ padding: 'sp1.5', flexItems: 'column', gap: 'sp1.5' })}>
      {rowGroupBys.map((groupBy) => {
        const colName = groupBy.column.name;
        const configKey = getGroupByName(groupBy);
        const colDisplay = columnConfigs[configKey]?.name ?? colName;
        return (
          <CollapsibleGroup key={colName} title={colDisplay}>
            <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5', padding: 'sp1' })}>
              {updateColTitleInput(configKey, colDisplay)}
            </div>
          </CollapsibleGroup>
        );
      })}
      {aggregations.map((agg) => {
        const colName = agg.column.name;
        const configKey = getAggColName(agg);
        const colConfig = columnConfigs[configKey];
        const colDisplay = colConfig?.name ?? colName;
        return (
          <CollapsibleGroup key={configKey} title={colDisplay}>
            <div className={sprinkles({ padding: 'sp1', marginBottom: 'sp1' })}>
              {updateColTitleInput(configKey, colDisplay)}
              <NumberFormatConfig
                column={agg.column}
                displayOptions={colConfig?.displayFormatting as NumberDisplayOptions}
                operationType={OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2}
                updateNumberOptions={(newOptions) => updateNumberOptions(configKey, newOptions)}
              />
            </div>
            <div className={sprinkles({ backgroundColor: 'outline' })} style={{ height: 1 }} />
            <div className={sprinkles({ padding: 'sp1' })}>
              <GradientConfiguration
                displayOptions={colConfig?.displayFormatting as NumberDisplayOptions | undefined}
                updateNumberOptions={(newOptions) => updateNumberOptions(configKey, newOptions)}
              />
            </div>
          </CollapsibleGroup>
        );
      })}
    </div>
  );
};

import { FC } from 'react';

import { Input, Select, Switch, Toggle, ToggleItem } from 'components/ds';

import { STRING_FORMATS, STRING } from 'constants/dataConstants';
import {
  LegendFormat,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { LegendPosition } from 'constants/types';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions | V2BoxPlotInstructions;
  operationType: OPERATION_TYPES;

  updateLegendFormat: (legendFormat: LegendFormat) => void;
};

const stringFormatOptions = Object.values(STRING_FORMATS).map((formatOption) => ({
  value: formatOption,
}));

export const SharedLegendConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  operationType,
  updateLegendFormat,
}) => {
  const { legendFormat } = instructions;
  const selectedPosition = legendFormat?.position || LegendPosition.AUTO;

  const selectedStringFormat = legendFormat?.stringFormat?.format || STRING_FORMATS.DEFAULT;

  const showLegendFormatOption = () => {
    if (
      operationType !== OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 &&
      operationType !== OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2
    ) {
      const legendColumnOptions =
        (instructions as V2TwoDimensionChartInstructions).colorColumnOptions || [];
      return !!legendColumnOptions.find((option) => option.column.type === STRING);
    }
    return false;
  };

  return (
    <>
      <Switch
        className={configInputClass}
        label="Title"
        onChange={() => updateLegendFormat({ showTitle: !legendFormat?.showTitle, title: '' })}
        switchOn={legendFormat?.showTitle}
      />
      {legendFormat?.showTitle ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={legendFormat?.title}
          onSubmit={(newValue) => updateLegendFormat({ title: newValue })}
        />
      ) : null}
      <Toggle
        className={configInputClass}
        label="Position"
        onValueChange={(value) => updateLegendFormat({ position: value as LegendPosition })}
        selectedValue={selectedPosition}>
        {Object.values(LegendPosition).map((position) => (
          <ToggleItem key={position} value={position} />
        ))}
      </Toggle>
      {showLegendFormatOption() ? (
        <>
          <Select
            className={configInputClass}
            label="String Format"
            onChange={(value) =>
              updateLegendFormat({
                stringFormat: { ...legendFormat?.stringFormat, format: value as STRING_FORMATS },
              })
            }
            selectedValue={selectedStringFormat}
            values={stringFormatOptions}
          />
          <Switch
            className={configInputClass}
            label="Remove Underscores"
            onChange={() => {
              updateLegendFormat({
                stringFormat: {
                  ...legendFormat?.stringFormat,
                  replaceUnderscores: !legendFormat?.stringFormat?.replaceUnderscores,
                },
              });
            }}
            switchOn={legendFormat?.stringFormat?.replaceUnderscores}
          />
        </>
      ) : null}
    </>
  );
};

import { FC, useState, useMemo } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { Select, sprinkles, IconButton, Switch, Icon, InfoIcon } from 'components/ds';
import { SortOrderToggle } from 'components/SortDirectionToggles';

import { DefaultSortColumn, SortOrder } from 'constants/types';
import { VisualizeTableInstructions, VisualizePivotTableInstructions } from 'constants/types';
import { updateVisualizeOperationThunk } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES } from 'constants/types';
import { DatasetSchema } from 'types/datasets';

const convertSortInfoToArray = (sortInfo: DefaultSortColumn | DefaultSortColumn[] | undefined) => {
  return Array.isArray(sortInfo) ? sortInfo : sortInfo === undefined ? [] : [sortInfo];
};

type Props = {
  schema: DatasetSchema;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  operationType: OPERATION_TYPES.VISUALIZE_PIVOT_TABLE | OPERATION_TYPES.VISUALIZE_TABLE;
};

export const MultiColumnSortingConfig: FC<Props> = ({ schema, instructions, operationType }) => {
  const dispatch = useDispatch();

  const [columns, sortOptions] = useMemo(() => {
    const defaultSortColumn = instructions.defaultSortedColumn;
    const initialSortColumns = !Array.isArray(defaultSortColumn)
      ? defaultSortColumn === undefined
        ? []
        : [defaultSortColumn]
      : defaultSortColumn;
    const initialSortColumnNames = new Set(initialSortColumns.map((col) => col.column));
    const options: Array<{ value: string; label: string }> = [];
    schema.forEach((col) => {
      if (!initialSortColumnNames.has(col.name))
        options.push({ value: col.name, label: col.friendly_name || col.name });
    });
    return [initialSortColumns, options];
  }, [instructions.defaultSortedColumn, schema]);

  const [isEnabled, setIsEnabled] = useState(columns.length > 0);

  const updateInstructions = (
    updateFunc: (draft: VisualizeTableInstructions | VisualizePivotTableInstructions) => void,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      updateFunc(draft);
    });
    dispatch(updateVisualizeOperationThunk(newInstructions, operationType));
  };

  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <Switch
          onChange={(switchOn) => {
            setIsEnabled(switchOn);
            if (!switchOn) updateInstructions((draft) => (draft.defaultSortedColumn = undefined));
          }}
          switchOn={isEnabled}
        />
        <div className={sortToggleStyle}>
          Set an initial sort
          <InfoIcon text="Configures the columns by which to sort the table. The order of sorting precedence is from top to bottom." />
        </div>
      </div>
      {isEnabled ? (
        <div className={sprinkles({ paddingLeft: 'sp5' })}>
          <div className={adderStyle}>
            Sort by column values
            <IconButton
              name="plus"
              onClick={() =>
                updateInstructions((draft) => {
                  draft.defaultSortedColumn = convertSortInfoToArray(draft.defaultSortedColumn);
                  draft.defaultSortedColumn.push({ column: undefined, order: undefined });
                })
              }
              variant="secondary"
            />
          </div>
          <div className={sprinkles({ gap: 'sp1.5', flexItems: 'column' })}>
            {columns.map((col, i) => (
              <InitialColumnSortingConfig
                index={i}
                instructions={instructions}
                key={`col-${col.column}-${i}`}
                operationType={operationType}
                order={col.order ?? SortOrder.ASC}
                selectedCol={col.column}
                sortOptions={sortOptions}
                updateInstructions={updateInstructions}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

type ColumnSortProps = {
  order: SortOrder;
  index: number;
  selectedCol: string | undefined;
  sortOptions: { value: string; label: string }[];
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  operationType: OPERATION_TYPES.VISUALIZE_PIVOT_TABLE | OPERATION_TYPES.VISUALIZE_TABLE;
  updateInstructions: (
    updateFunc: (draft: VisualizeTableInstructions | VisualizePivotTableInstructions) => void,
  ) => void;
};

const InitialColumnSortingConfig: FC<ColumnSortProps> = ({
  order,
  index,
  selectedCol,
  sortOptions,
  updateInstructions,
}) => {
  const [isOpen, setIsOpen] = useState(index == 0 || selectedCol === undefined);

  const options = selectedCol
    ? [...sortOptions, { value: selectedCol, label: selectedCol }]
    : sortOptions;

  const updateSelectedSortColumn = (sortColumn: DefaultSortColumn, index: number) =>
    updateInstructions((draft) => {
      draft.defaultSortedColumn = convertSortInfoToArray(draft.defaultSortedColumn);
      draft.defaultSortedColumn[index] = { ...draft.defaultSortedColumn[index], ...sortColumn };
    });

  return (
    <div className={containerStyle}>
      <div
        className={sprinkles({
          flexItems: 'alignCenterBetween',
          paddingX: 'sp2',
        })}
        style={{ height: 44 }}>
        <div className={sprinkles({ flexItems: 'alignCenter', overflowX: 'hidden' })}>
          <IconButton
            name={isOpen ? 'caret-down' : 'caret-right'}
            onClick={() => setIsOpen((prev) => !prev)}
          />
          <div className={headerStyle}>
            <div className={sprinkles({ truncateText: 'ellipsis' })} title={selectedCol ?? ''}>
              {selectedCol ? selectedCol : '[Column Name]'}
            </div>
            <Icon name="arrow-right" />
            {order === SortOrder.DESC ? 'Descending' : 'Ascending'}
          </div>
        </div>
        <IconButton
          name="trash-can"
          onClick={() =>
            updateInstructions((draft) => {
              draft.defaultSortedColumn = convertSortInfoToArray(draft.defaultSortedColumn);
              draft.defaultSortedColumn.splice(index, 1);
            })
          }
        />
      </div>
      {isOpen ? (
        <div className={sprinkles({ flexItems: 'column' })}>
          <div className={sprinkles({ backgroundColor: 'outline' })} style={{ height: 1 }}></div>
          <div className={sprinkles({ padding: 'sp2', flexItems: 'column', gap: 'sp2' })}>
            <Select
              label="Column"
              onChange={(newCol) => updateSelectedSortColumn({ column: newCol }, index)}
              selectedValue={selectedCol}
              values={options}
            />
            <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })}>
              <div className={sprinkles({ body: 'b2', color: 'contentSecondary' })}>Order</div>
              <SortOrderToggle
                order={order}
                updateOrder={(newOrder) => updateSelectedSortColumn({ order: newOrder }, index)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const containerStyle = sprinkles({
  flexItems: 'column',
  backgroundColor: 'elevationMid',
  width: 'fill',
  borderRadius: 8,
});

const headerStyle = sprinkles({
  body: 'section',
  flexItems: 'alignCenter',
  gap: 'sp.5',
  flex: 1,
  overflowX: 'hidden',
});

const adderStyle = sprinkles({
  flexItems: 'alignCenterBetween',
  body: 'b3',
  paddingY: 'sp2',
  borderTop: 1,
  borderTopColor: 'outline',
});

const sortToggleStyle = sprinkles({
  heading: 'h4',
  width: 'fill',
  paddingY: 'sp2',
  flexItems: 'alignCenter',
  gap: 'sp1',
});

import { useEffect, useContext, FC } from 'react';

import { EmbedButton, EmbedPopover } from 'components/embed';

import { DatasetSchema } from 'types/datasets';
import { UserTransformedSchema } from 'constants/types';
import { TransformationSettingsMenu } from './TransformationSettingsMenu';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

type Props = {
  isDrilldownModal: boolean;
  loading?: boolean;
  schema: DatasetSchema;
  userTransformedSchema: UserTransformedSchema;
  updateUserTransformedSchema: (newSchema: UserTransformedSchema) => void;
};

export const TransformationSettingsButton: FC<Props> = ({
  loading,
  schema,
  userTransformedSchema,
  updateUserTransformedSchema,
  isDrilldownModal,
}) => {
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);

  useEffect(() => {
    if (schema.length && !userTransformedSchema) {
      const initializedUserTransformedSchema = schema.map((column) => ({
        ...column,
        isVisible: true,
      }));
      updateUserTransformedSchema(initializedUserTransformedSchema);
    }
  }, [schema, userTransformedSchema, updateUserTransformedSchema]);

  return (
    <EmbedPopover
      align="end"
      portalContainerId={isDrilldownModal ? undefined : dashboardLayoutTagId}
      trigger={<EmbedButton disabled={loading} icon="gear" variant="tertiary" />}>
      <TransformationSettingsMenu
        schema={userTransformedSchema}
        updateSchema={updateUserTransformedSchema}
      />
    </EmbedPopover>
  );
};

import { FC } from 'react';
import cx from 'classnames';

import { ColorPicker } from 'components/ColorPicker';
import { mixColors } from 'utils/general';
import { sprinkles } from 'components/ds';
import * as styles from './index.css';

type Props = {
  // A list of either 2 or 3 hues
  hues: string[];
  // A list of either 2 or 3 callbacks fired on hue change
  onHuesChanged: ((newHue: string) => void)[];
  // A list of either 2 or 3 labels to be used for the color pickers
  labels?: string[];
};

export const ColorPaletteControl: FC<Props> = ({ hues, onHuesChanged, labels }) => {
  const color1 = hues[0];
  const color2 = hues[1];

  const label1 = labels && labels[0];
  const label2 = labels && labels[1];

  if (hues.length === 2) {
    return (
      <div className={sprinkles({ display: 'flex' })}>
        <div className={sprinkles({ flex: 1 })}>
          <ColorPicker
            fill
            buttonClassName={cx(styles.colorPickerButton, styles.linearLeftColorPicker)}
            className={styles.colorPicker}
            color={hues[0]}
            label={label1}
            onClose={onHuesChanged[0]}
          />
        </div>
        <div
          className={styles.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.75).rgb().toString() }}
        />
        <div
          className={styles.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.5).rgb().toString() }}
        />
        <div
          className={styles.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.25).rgb().toString() }}
        />
        <div className={sprinkles({ flex: 1 })}>
          <ColorPicker
            fill
            buttonClassName={cx(styles.colorPickerButton, styles.linearRightColorPicker)}
            className={styles.colorPicker}
            color={hues[1]}
            label={label2}
            onClose={onHuesChanged[1]}
          />
        </div>
      </div>
    );
  }

  const color3 = hues[2];
  const label3 = labels && labels[2];

  return (
    <div className={sprinkles({ display: 'flex' })}>
      <div className={sprinkles({ flex: 1 })}>
        <ColorPicker
          fill
          buttonClassName={cx(styles.colorPickerButton, styles.linearLeftColorPicker)}
          className={styles.colorPicker}
          color={hues[0]}
          label={label1}
          onClose={onHuesChanged[0]}
        />
      </div>
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.66).rgb().toString() }}
      />
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.33).rgb().toString() }}
      />
      <div className={sprinkles({ flex: 1 })}>
        <ColorPicker
          fill
          buttonClassName={cx(styles.colorPickerButton, styles.linearCenterColorPicker)}
          className={styles.colorPicker}
          color={hues[1]}
          label={label2}
          onClose={onHuesChanged[1]}
        />
      </div>
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.66).rgb().toString() }}
      />
      <div
        className={styles.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.33).rgb().toString() }}
      />
      <div className={sprinkles({ flex: 1 })}>
        <ColorPicker
          fill
          buttonClassName={cx(styles.colorPickerButton, styles.linearRightColorPicker)}
          className={styles.colorPicker}
          color={hues[2]}
          label={label3}
          onClose={onHuesChanged[2]}
        />
      </div>
    </div>
  );
};

import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Poller } from 'components/JobQueue/Poller';

import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { receiveFinishedJobs } from 'reducers/dashboardLayoutReducer';

export const DashboardLayoutPoller: FC = () => {
  const dispatch = useDispatch();

  const { awaitedJobs, requestInfo } = useSelector(
    (state: EmbedReduxState) => ({
      awaitedJobs: state.dashboardLayout.awaitedJobs,
      requestInfo: state.dashboardLayout.requestInfo,
    }),
    shallowEqual,
  );

  const customerToken = requestInfo.type === 'embedded' ? requestInfo.customerToken : undefined;
  const jwt = requestInfo.type === 'embedded' ? requestInfo.jwt : undefined;

  return (
    <Poller
      awaitedJobs={awaitedJobs}
      customerToken={customerToken}
      jwt={jwt}
      updateJobResult={(finishedJobIds, onComplete) => {
        if (finishedJobIds.length > 0) dispatch(receiveFinishedJobs(finishedJobIds));

        onComplete();
      }}
    />
  );
};

import { FC } from 'react';
import cx from 'classnames';
import { Popover, Position, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { APP_PORTAL_ID, IconButton, sprinkles } from 'components/ds';

import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import * as styles from './styles.css';

import { PeriodRangeTypes, PERIOD_RANGE_OPTIONS } from 'types/dateRangeTypes';
import { OldConfigurableColInfo } from 'types/columnTypes';

type Props = {
  column: OldConfigurableColInfo;
  draggingClass: string;
  draggingPopoverClass: string | undefined;
  onColAdded: (newCol: DatasetColumn, oldColName?: string) => void;
  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  disableEdits: boolean;
  periodRange: PeriodRangeTypes | undefined;
  schema: DatasetSchema;
};

export const DroppedPeriodColumn: FC<Props> = ({
  column,
  draggingClass,
  draggingPopoverClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  periodRange,
  schema,
}) => {
  const selectedOption = periodRange && PERIOD_RANGE_OPTIONS[periodRange];

  const containerStyles = cx(
    sprinkles({
      borderRadius: 3,
      display: 'flex',
      marginBottom: 'sp.5',
      backgroundColor: 'lightBlue',
    }),
    draggingClass,
  );

  return (
    <div className={containerStyles}>
      <div
        className={cx(
          sprinkles({ width: 'fill', flex: 3, overflow: 'hidden', display: 'flex' }),
          draggingPopoverClass,
        )}>
        <ColumnOptionsPopover
          rightDivider
          displayName={column.column.name}
          onColChange={(newCol) => onColAdded(newCol, column.column.name)}
          schema={schema}
        />
      </div>
      <div
        className={cx(
          sprinkles({ width: 'fill', flex: 2, display: 'flex', overflow: 'hidden' }),
          draggingPopoverClass,
        )}>
        <Popover
          captureDismiss
          fill
          minimal
          content={
            <Menu>
              {Object.values(PERIOD_RANGE_OPTIONS).map((option, i) => (
                <MenuItem
                  className={styles.menuItemOption}
                  disabled={disableEdits}
                  key={`col-config-option-${i}`}
                  onClick={() => !disableEdits && onColOptionChanged(option)}
                  text={option.name}
                />
              ))}
              <MenuDivider />
              <MenuItem
                disabled={disableEdits}
                onClick={() => !disableEdits && onRemoveCol()}
                text="Remove"
              />
            </Menu>
          }
          portalContainer={document.getElementById(APP_PORTAL_ID) ?? undefined}
          position={Position.BOTTOM_RIGHT}>
          <div className={cx(styles.popoverTargetContainer, sprinkles({ cursor: 'pointer' }))}>
            <div className={styles.colName}>{selectedOption?.id}</div>
            <IconButton className={styles.iconBtnWithDivider} name="caret-down" />
          </div>
        </Popover>
      </div>
      <IconButton
        className={styles.iconBtn}
        disabled={disableEdits}
        name="cross"
        onClick={() => !disableEdits && onRemoveCol()}
      />
    </div>
  );
};

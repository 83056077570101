import { useEffect, useMemo, useState, useCallback } from 'react';
import validator from 'validator';
import cx from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import produce from 'immer';

import { sprinkles, Tag } from 'components/ds';
import { Poller } from 'components/JobQueue/Poller';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import {
  EmbedButton,
  EmbedRadioGroup,
  EmbedModalFooter,
  EmbedModalContent,
  EmbedInput,
} from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ExportScheduler.css';

import { EmailBody, EmailCadence, ExportBody } from 'types/emailCadence';
import { EMAIL_FREQUENCY } from 'constants/types';
import { getTimezoneOptions } from 'utils/timezoneUtils';
import {
  getEmailCadence,
  timeOptions,
  weekdayOptions,
  weekOfMonthOptions,
  cadenceOptions,
} from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/utils';
import { Jobs } from 'components/JobQueue/types';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { JobQueueResult } from 'actions/jobQueueActions';
import { ExportType, EXPORT_TYPE_NAME_MAP } from 'actions/exportActions';

type Props = {
  createExportCadence: (email: EmailBody, onSuccess: () => void, onFailure: () => void) => void;
  editingEmail: EmailCadence | undefined;
  goToManage: () => void;
  resourceName: string;
  sendTestDraftExport: (body: ExportBody, onSuccess: (jobs: Record<string, Jobs>) => void) => void;
  updateExportCadence: (
    emailId: string,
    email: EmailBody,
    onSuccess: () => void,
    onFailure: () => void,
  ) => void;
};

export function ExportScheduler({
  createExportCadence,
  editingEmail,
  goToManage,
  resourceName,
  sendTestDraftExport,
  updateExportCadence,
}: Props): JSX.Element {
  const [recipients, setRecipients] = useState<string[]>(editingEmail?.recipients ?? []);
  const [recipientError, setRecipientError] = useState(false);
  const [currRecipient, setCurrRecipient] = useState('');
  const [subject, setSubject] = useState(
    editingEmail?.subject ?? `View the latest from ${resourceName}`,
  );
  const [message, setMessage] = useState(editingEmail?.message ?? '');
  const [exportType, setExportType] = useState(editingEmail?.export_type ?? DEFAULT_EXPORT_TYPE);
  const [cadence, setCadence] = useState(EMAIL_FREQUENCY.DAILY);
  const [dayOfWeek, setDayOfWeek] = useState(editingEmail?.day_of_week ?? 0);
  const [weekOfMonth, setWeekOfMonth] = useState(editingEmail?.week_of_month ?? 0);
  const [time, setTime] = useState(720);
  const [timezone, setTimezone] = useState(
    editingEmail?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [failedJobs, setFailedJobs] = useState<Record<string, string>>({});
  const [succeededJobs, setSucceededJobs] = useState<Record<string, string>>({});

  const { customerToken, jwt } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      jwt: state.embeddedReportBuilder.requestInfo.embedJwt,
    }),
    shallowEqual,
  );

  const timezoneOptions = useMemo(
    () => getTimezoneOptions().map((opt) => ({ value: opt.id, name: opt.name })),
    [],
  );

  useEffect(() => {
    if (editingEmail) {
      setTime(editingEmail.hour * 60 + editingEmail.minute);
      setCadence(getEmailCadence(editingEmail));
    } else {
      const date = new Date();
      const hours = date.getHours();
      const day = date.getDay();
      setTime(hours * 60 + (date.getMinutes() >= 30 ? 30 : 0));
      setDayOfWeek(day === 0 ? 6 : day - 1);
    }
  }, [editingEmail]);

  const noSubject = subject.trim() === '';
  const isMissingInfo = () => {
    const noRecipients = recipients.length === 0;
    setRecipientError(noRecipients);
    return noRecipients || noSubject;
  };

  useEffect(() => {
    if (recipients.length > 0) setRecipientError(false);
  }, [recipients]);

  const updateOrCreateEmail = () => {
    setFailedJobs({});
    setSucceededJobs({});
    if (isMissingInfo()) return;

    const hour = time / 60;
    const minute = Number.isInteger(hour) ? 0 : 30;

    const emailBody: EmailBody = {
      recipients,
      minute,
      hour: Math.floor(hour),
      week_of_month: cadence === EMAIL_FREQUENCY.MONTHLY ? weekOfMonth : null,
      day_of_week: cadence === EMAIL_FREQUENCY.DAILY ? null : dayOfWeek,
      timezone,
      subject,
      message,
      export_type: exportType,
    };

    setSaveLoading(true);
    const onFailure = () => setSaveLoading(false);
    const onSuccess = () => goToManage();
    editingEmail
      ? updateExportCadence(editingEmail.id, emailBody, onSuccess, onFailure)
      : createExportCadence(emailBody, onSuccess, onFailure);
  };

  const sendTestEmail = () => {
    setFailedJobs({});
    setSucceededJobs({});
    if (isMissingInfo()) return;
    sendTestDraftExport(
      { recipients, subject, message, export_type: exportType, timezone },
      setAwaitedJobs,
    );
  };

  const renderNonDailyCadence = () => {
    if (cadence === EMAIL_FREQUENCY.DAILY) return null;
    const isWeekly = cadence === EMAIL_FREQUENCY.WEEKLY;
    return (
      <>
        <div className={styles.cadenceText}>{isWeekly ? 'on' : 'on the'}</div>
        {!isWeekly ? (
          <EmbeddedDropdownMenu
            menuOptions={weekOfMonthOptions}
            onClick={(week) => setWeekOfMonth(parseInt(week))}>
            <EmbeddedDropdownButton
              className={cx(styles.flexOne, styles.marginRightDropdown)}
              selectedName={weekOfMonthOptions[weekOfMonth].name}
            />
          </EmbeddedDropdownMenu>
        ) : null}
        <EmbeddedDropdownMenu
          menuOptions={weekdayOptions}
          onClick={(day) => setDayOfWeek(parseInt(day))}>
          <EmbeddedDropdownButton
            className={styles.flexOne}
            selectedName={weekdayOptions[dayOfWeek].name}
          />
        </EmbeddedDropdownMenu>
      </>
    );
  };

  const renderCadence = () => {
    const strTime = time.toString();
    const selectedTime = timeOptions.find((opt) => opt.value === strTime)?.name;
    const selectedTimezone = timezoneOptions.find((opt) => opt.value === timezone)?.name;
    return (
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        <EmbeddedDropdownMenu
          menuOptions={cadenceOptions}
          onClick={(cadence) => setCadence(cadence as EMAIL_FREQUENCY)}>
          <EmbeddedDropdownButton className={styles.flexOne} selectedName={cadence} />
        </EmbeddedDropdownMenu>
        {renderNonDailyCadence()}
        <div className={styles.cadenceText}>at</div>
        <EmbeddedDropdownMenu menuOptions={timeOptions} onClick={(time) => setTime(parseInt(time))}>
          <EmbeddedDropdownButton
            className={cx(styles.flexOne, styles.marginRightDropdown)}
            selectedName={selectedTime}
          />
        </EmbeddedDropdownMenu>
        <EmbeddedDropdownMenu menuOptions={timezoneOptions} onClick={(tz) => setTimezone(tz)}>
          <EmbeddedDropdownButton className={styles.flexOne} selectedName={selectedTimezone} />
        </EmbeddedDropdownMenu>
      </div>
    );
  };

  const handleEmailSubmission = () => {
    if (!validator.isEmail(currRecipient)) return;
    if (!recipients.includes(currRecipient)) setRecipients([...recipients, currRecipient]);
    setCurrRecipient('');
  };

  const handleJobResult = useCallback(
    (finishedJobIds: string[], onComplete: () => void, results: Record<string, JobQueueResult>) => {
      if (finishedJobIds.length > 0) setAwaitedJobs({});
      for (const jobId of finishedJobIds) {
        const { error, success } = results[jobId];
        if (error) setFailedJobs((prev) => ({ ...prev, [jobId]: error }));
        else setSucceededJobs((prev) => ({ ...prev, [jobId]: success }));

        setAwaitedJobs((prev) =>
          produce(prev, (jobs) => {
            delete jobs[jobId];
          }),
        );
      }
      onComplete();
    },
    [],
  );

  return (
    <>
      <EmbedModalContent className={styles.modalContent} size="xlarge">
        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Select Recipients
          </EmbedText>
          <EmbedInput
            onBlur={handleEmailSubmission}
            onChange={setCurrRecipient}
            onEnter={handleEmailSubmission}
            placeholder="Add Recipient Email"
            value={currRecipient}
          />
          {recipientError ? (
            <EmbedText body="b3" color="error">
              You must insert at least one recipient
            </EmbedText>
          ) : null}
          {recipients.length > 0 ? (
            <div className={styles.recipientTags}>
              {recipients.map((recipient) => (
                <Tag
                  intent="active"
                  key={recipient}
                  onClose={() => setRecipients(recipients.filter((email) => email !== recipient))}>
                  {recipient}
                </Tag>
              ))}
            </div>
          ) : null}
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Email Content
          </EmbedText>
          <EmbedText color="contentPrimary" heading="h4">
            Subject
          </EmbedText>
          <EmbedInput onChange={setSubject} placeholder="Subject" value={subject} />
          {noSubject ? (
            <EmbedText body="b3" color="error">
              Subject cannot be empty
            </EmbedText>
          ) : null}
          <EmbedText color="contentPrimary" heading="h4">
            Body
          </EmbedText>
          <textarea
            className={styles.textarea}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Add content to your email..."
            rows={6}
            value={message}
          />
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Export Type
          </EmbedText>
          <EmbedRadioGroup
            defaultValue={DEFAULT_EXPORT_TYPE}
            onValueChange={(value) => setExportType(value as ExportType)}
            renderValue={(value) => EXPORT_TYPE_NAME_MAP[value as ExportType]}
            value={exportType}
            values={exportOptions}
          />
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Scheduling
          </EmbedText>
          {renderCadence()}
        </div>
      </EmbedModalContent>

      <EmbedModalFooter>
        <EmbedButton
          loading={Object.keys(awaitedJobs).length > 0}
          onClick={sendTestEmail}
          variant="secondary">
          Send Test
        </EmbedButton>
        <EmbedButton
          disabled={noSubject}
          loading={saveLoading}
          onClick={updateOrCreateEmail}
          variant="primary">
          {editingEmail ? 'Update' : 'Schedule Email'}
        </EmbedButton>
        {Object.keys(failedJobs).length > 0 ? (
          <EmbedText body="b3" color="error">
            Failed to send test email, please try again then contact support if the issue persists.
          </EmbedText>
        ) : Object.keys(succeededJobs).length > 0 ? (
          <EmbedText body="b3" color="success">
            Successfully sent test email.
          </EmbedText>
        ) : null}
      </EmbedModalFooter>

      <Poller
        awaitedJobs={awaitedJobs}
        customerToken={customerToken}
        jwt={jwt}
        updateJobResult={handleJobResult}
      />
    </>
  );
}

const DEFAULT_EXPORT_TYPE = ExportType.CSV;
const exportOptions = Object.values(ExportType);

import { useMemo, useState, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageHeader, ViewType } from 'components/PageHeader';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { sprinkles, Breadcrumbs } from 'components/ds';
import TablePager from 'components/dataTable/tablePager';
import * as styles from './styles.css';
import { PromotionalPage } from './PromotionalPage';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { Dashboard, createDashboard } from 'actions/dashboardActions';
import { isCreateResourceDisabled, isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { showErrorToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { createReportBuilder, ReportBuilder } from 'actions/reportBuilderActions';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { createFolder } from 'reducers/thunks/resourceThunks';
import { ROUTES } from 'constants/routes';
import { RESOURCES_PER_CARD_PAGE, RESOURCES_PER_LIST_PAGE } from './constants';
import { getResourceText } from 'utils/exploResourceUtils';
import { getResources } from 'reducers/folderReducer';

export type Resource = Dashboard | ReportBuilder;

type Props = {
  currPage: number;
  setCurrPage: (page: number) => void;
  viewType: ViewType;
  setViewType: (view: ViewType) => void;
  pageType: ResourcePageType;

  children: JSX.Element;
};

export const ResourceListPageWrapper: FC<Props> = ({
  currPage,
  setCurrPage,
  viewType,
  setViewType,
  pageType,
  children,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    currentUser,
    teamPaymentPlan,
    createResourceLoading,
    globalStyleConfig,
    folders,
    breadcrumbs,
    totalCount,
    resources,
  } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      teamPaymentPlan: state.currentUser.team?.payment_plan,
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
      folders: state.folder.folders,
      breadcrumbs: state.folder.breadcrumbs,
      totalCount: state.folder.totalCount,
      resources: getResources(state, isExploreProduct),

      createResourceLoading: createLoadingSelector(
        [ACTION.CREATE_DASHBOARD, ACTION.CLONE_DASHBOARD],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const uniqueNames = useMemo(
    () => new Set([...resources, ...folders].map((r) => r.name)),
    [resources, folders],
  );

  const currentFolder = useMemo(() => {
    return breadcrumbs.length >= 1 ? breadcrumbs[breadcrumbs.length - 1] : undefined;
  }, [breadcrumbs]);

  const [createResourceModalOpen, setCreateResourceModalOpen] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const userPermissions = currentUser.permissions[resourcePermissionEntity];

  const userCanCreateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const rootPath = isExploreProduct ? ROUTES.HOME_APP_PAGE_TEST : ROUTES.REPORT_BUILDER_TEST;

  const globalStyleVars = useMemo(() => {
    if (pageType === ResourcePageType.REPORT_BUILDER) return {};
    return getGlobalStyleVars(globalStyleConfig, { onlyCustomTheme: true });
  }, [globalStyleConfig, pageType]);

  const getPageTitle = () => {
    switch (pageType) {
      case ResourcePageType.REPORT_BUILDER:
        return 'Report Builder';
      case ResourcePageType.EXPLORE:
        return 'Dashboards';
    }
  };

  if (!resources) {
    return (
      <div className={styles.errorLoadingResources}>
        Error Loading {getResourceText(pageType, { plural: true, capitalized: true })}
      </div>
    );
  }

  const createResourceDisabled = isCreateResourceDisabled(
    isExploreProduct,
    resources.length,
    teamPaymentPlan,
  );

  const renderPager = () => {
    if (folders.length + resources.length === 0) return;

    const resourcesPerPage =
      viewType === ViewType.List ? RESOURCES_PER_LIST_PAGE : RESOURCES_PER_CARD_PAGE;
    const maxPageNumber = Math.max(Math.ceil(totalCount / resourcesPerPage), 1);

    return (
      <div className={sprinkles({ display: 'block' })}>
        <div className={styles.pagerFooterContainer}>
          <div className={styles.pagerCount}>
            <div className={sprinkles({ display: 'flex', flexDirection: 'row', gap: 'sp1' })}>
              <div>{`${folders.length} folder${folders.length == 1 ? '' : 's'}`}</div>
              <div>
                {resources.length} {getResourceText(pageType, { plural: resources.length !== 1 })}
              </div>
            </div>
          </div>
          <TablePager
            currentPage={currPage}
            maxPageNumber={maxPageNumber}
            onNewPage={(newPage) => {
              const newPageNumber = parseInt(newPage);

              if (
                !newPageNumber ||
                newPageNumber < 1 ||
                newPageNumber > maxPageNumber ||
                currPage === newPageNumber
              ) {
                return;
              }
              setCurrPage(newPageNumber);
            }}
          />
          <div />
        </div>
      </div>
    );
  };

  const showCreationError = () => {
    showErrorToast(
      `There was an error creating your ${getResourceText(
        pageType,
      )}. Please try again or contact support if the error continues.`,
    );
  };

  const renderCreateFolderModal = () => {
    if (!currentUser.team || !currentFolder) return;

    return (
      <TextFieldModal
        buttonName="Create Folder"
        closeModal={() => setCreateFolderModalOpen(false)}
        getErrorMessage={(input) => {
          if (input && uniqueNames.has(input)) {
            return 'Name cannot be duplicate name';
          }
        }}
        loading={createResourceLoading}
        modalOpen={createFolderModalOpen}
        modalTitle="Create a Folder"
        onSubmit={(name) => {
          dispatch(
            createFolder({
              config: { parent_id: currentFolder.id, name },
              onSuccess: () => setCreateFolderModalOpen(false),
              onError: () =>
                showErrorToast(
                  `There was an error creating your folder. Please try again or contact support if the error continues.`,
                ),
            }),
          );
        }}
        textFieldPlaceholder="Enter Folder name"
      />
    );
  };

  const renderCreateResourceModal = () => {
    if (!currentUser.team) return;

    return (
      <TextFieldModal
        buttonName={`Create a ${getResourceText(pageType)}`}
        closeModal={() => setCreateResourceModalOpen(false)}
        getErrorMessage={(input) => {
          if (input && uniqueNames.has(input)) {
            return 'Name cannot be duplicate name';
          }
        }}
        loading={createResourceLoading}
        modalOpen={createResourceModalOpen}
        modalTitle={`Create a ${getResourceText(pageType)}.`}
        onSubmit={(name) => {
          if (!currentFolder) return;
          if (isExploreProduct) {
            dispatch(
              createDashboard(
                { id: currentUser.team?.id, postData: { name, parent_id: currentFolder.id } },
                (data) => {
                  trackEvent(EVENTS.CREATED_DASHBOARD, {
                    dashboard_template_id: data.new_dashboard_template.id,
                    dashboard_name: name,
                  });
                  setCreateResourceModalOpen(false);
                  history.push(`/dashboard/${data.new_dashboard_template.id}#edit`);
                },
                () => showCreationError(),
              ),
            );
          } else {
            dispatch(
              createReportBuilder(
                { postData: { name, parent_id: currentFolder.id } },
                (data) => {
                  setCreateResourceModalOpen(false);
                  history.push(`/report-builder/${data.report_builder.id}/datasets`);
                },
                () => showCreationError(),
              ),
            );
          }
        }}
        textFieldPlaceholder={`Enter ${getResourceText(pageType, { plural: true })} name`}
      />
    );
  };

  const primaryActionProps = userCanCreateResource
    ? {
        disabled: createResourceDisabled,
        text: `Create ${getResourceText(pageType, { capitalized: true })}`,
        tooltipText: createResourceDisabled
          ? 'Upgrade your plan to create more than 3 dashboards.'
          : undefined,
        onClick: () => setCreateResourceModalOpen(true),
      }
    : undefined;

  const secondaryActionProps = userCanCreateResource
    ? {
        text: 'Create a Folder',
        onClick: () => setCreateFolderModalOpen(true),
      }
    : undefined;

  const breadcrumbHeader = () => {
    if (breadcrumbs.length <= 1 || !currentFolder) return getPageTitle();

    // Split three parts, first breadcrumb, current context, and everything in the middle
    const firstItem = {
      text: `All ${isExploreProduct ? 'Dashboards' : 'Reports'}`,
      onClick: () => history.push(rootPath),
    };
    const middleBreadcrumbs = breadcrumbs.slice(1, breadcrumbs.length - 1);

    const middleBreadcrumbItems = middleBreadcrumbs.map(({ id, name }) => ({
      text: name,
      onClick: () => history.push(`${rootPath}/${id}`),
    }));

    return (
      <div>
        <Breadcrumbs items={[firstItem, ...middleBreadcrumbItems]} />
        {currentFolder.name}
      </div>
    );
  };

  const renderBody = () => (
    <>
      <PageHeader
        currentView={viewType}
        pageTitle={breadcrumbHeader()}
        primaryActionProps={primaryActionProps}
        searchBarPlaceholderText="Search by name"
        searchBarSubmit={() => {
          setCurrPage(1);
        }}
        secondaryActionProps={secondaryActionProps}
        toggleView={
          pageType === ResourcePageType.REPORT_BUILDER
            ? undefined
            : (view: ViewType) => {
                setCurrPage(1);
                setViewType(view);
              }
        }
      />
      <div className={styles.scroll}>
        <div className={styles.content} style={globalStyleVars}>
          {children}
          {renderCreateResourceModal()}
          {renderCreateFolderModal()}
          {renderPager()}
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.root}>
      {pageType === ResourcePageType.REPORT_BUILDER && !isReportBuilderEnabled(currentUser) ? (
        <PromotionalPage pageTitle={getPageTitle()} />
      ) : (
        renderBody()
      )}
    </div>
  );
};

import { DateTime, DateTimeOptions, DurationUnits } from 'luxon';
import { groupBy } from 'utils/standard';

import { CategoryChartColumnInfo, SmartBucketInfo } from 'constants/types';
import {
  DATETIME_PART_PIVOT_AGG_SET,
  DATE_PIVOT_AGGS_SET,
  DEFAULT_DATE_RANGES,
  PivotAgg,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_RANGES,
} from 'types/dateRangeTypes';
import { zoneToUtc } from './timezoneUtils';
import { getColBucketName } from './dataPanelColUtils';
import { GroupByBucket } from 'types/columnTypes';

type ColNames = { xAxisColName: string; yAxisColName: string; colorColName: string };
type PreviewData = Record<string, string | number>[];

export const insertZeroesForMissingDateData = (
  previewData: PreviewData,
  column: CategoryChartColumnInfo | undefined,
  colNames: ColNames,
) => {
  const bucket = column?.bucket;
  if (!bucket) return;

  if (DATE_PIVOT_AGGS_SET.has(bucket.id)) {
    insertUnsortedZeroesForMissingDates(previewData, bucket, colNames, column.smartBucketInfo);
  } else if (DATETIME_PART_PIVOT_AGG_SET.has(bucket.id)) {
    insertUnsortedZeroesForMissingDateParts(previewData, bucket, colNames);
  } else return;

  // Highcharts needs pre-sorted data
  previewData.sort((a, b) => {
    return (a[colNames.xAxisColName] as number) - (b[colNames.xAxisColName] as number);
  });
};

const FromMillisOpts = { zone: 'utc' };

const insertUnsortedZeroesForMissingDates = (
  previewData: PreviewData,
  bucket: GroupByBucket,
  colNames: ColNames,
  smartBucketInfo: SmartBucketInfo | undefined,
) => {
  const { xAxisColName, colorColName } = colNames;
  const aggBucket = getColBucketName(bucket.id).toLowerCase();

  const attachMissingDates = (data: PreviewData, colorValue?: string | number) => {
    const missingDates = getMissingDates(data, colNames, smartBucketInfo, aggBucket);

    missingDates.forEach((row) => {
      if (colorValue !== undefined) row[colorColName] = colorValue;
      previewData.push(row);
    });
  };

  if (colorColName !== xAxisColName) {
    // If data is grouped need to group the previewData to attach correct empty values
    // and then add those new values to previewData
    const dataByColorCategory = groupBy(previewData, (row) => row[colorColName]);
    Object.entries(dataByColorCategory).forEach(([colorValue, data]) =>
      attachMissingDates(data, colorValue),
    );
  } else {
    attachMissingDates(previewData);
  }
};

const getMissingDates = (
  data: PreviewData,
  { xAxisColName, yAxisColName }: ColNames,
  smartBucketInfo: SmartBucketInfo | undefined,
  aggBucket: string,
): PreviewData => {
  const newData: PreviewData = [];
  // this is the gap between points on the x-axis (e.g. if we're bucketing by day, 6/10 and 6/13 will have a gap of 2)
  const checkDateGap = (
    fromDate: DateTime,
    toDate: DateTime,
    outerValues = false,
    goBackwards = false,
  ) => {
    // When adding outer values for smart grouping we need to make sure that we add one
    const xAxisDateGap = getDateGap(fromDate, toDate, aggBucket) + (outerValues ? 1 : 0);
    if (xAxisDateGap <= 1) return;

    // Fill in each gap between the current and next date e.g. 1 day out, 2 days out, etc.
    for (let gapPart = 1; gapPart < xAxisDateGap; gapPart++) {
      // Reset the currDate to 12AM UTC before adding time onto it to avoid off-by-ones in certain edge cases
      let missingDate: DateTime = (goBackwards ? toDate : fromDate).toUTC().startOf('day');
      missingDate = goBackwards
        ? missingDate.minus({ [aggBucket]: gapPart })
        : missingDate.plus({ [aggBucket]: gapPart });

      // Push at the end and then sort later instead of insertion because it is less expensive
      newData.push({
        [xAxisColName]: missingDate.toLocal().valueOf(),
        [yAxisColName]: 0,
      });
    }
  };

  const dataLength = data.length;
  for (let idx = 0; idx < dataLength; idx++) {
    const currDate = DateTime.fromMillis(data[idx][xAxisColName] as number, FromMillisOpts);
    if (idx === 0 && smartBucketInfo) {
      // Add date before the first value if there are any missing from smart grouping
      const earliestDate = DateTime.fromMillis(smartBucketInfo.startTime, FromMillisOpts);
      checkDateGap(earliestDate, currDate, true, true);
    }
    if (idx === dataLength - 1) {
      // Add date values after the last value if theres any missing values
      if (smartBucketInfo) {
        const latestDate = DateTime.fromMillis(smartBucketInfo.endTime, FromMillisOpts);
        checkDateGap(currDate, latestDate, true);
      }
      continue;
    }

    const nextDate = DateTime.fromMillis(data[idx + 1][xAxisColName] as number, FromMillisOpts);
    checkDateGap(currDate, nextDate);
  }
  return newData;
};

const insertUnsortedZeroesForMissingDateParts = (
  previewData: PreviewData,
  bucket: GroupByBucket,
  { xAxisColName, yAxisColName }: ColNames,
) => {
  const existingDateParts = new Set();
  previewData.forEach((row) => existingDateParts.add(row[xAxisColName]));

  const start = 0;
  const end = DatePartToMaxBoundMapping.get(bucket.id);
  if (!end) return;
  for (let datePart = start; datePart < end; datePart++) {
    if (existingDateParts.has(datePart)) continue;

    previewData.push({ [xAxisColName]: datePart, [yAxisColName]: 0 });
  }
};

const getDateGap = (currDate: DateTime, nextDate: DateTime, aggDuration: string) => {
  const diff = nextDate.diff(currDate, aggDuration as DurationUnits);
  switch (aggDuration) {
    case 'hour':
      return Math.round(diff.hours);
    case 'day':
      return Math.round(diff.days);
    case 'week':
      return Math.round(diff.weeks);
    case 'month':
      return Math.round(diff.months);
    case 'year':
      return Math.round(diff.years);
  }

  return 0;
};

const DatePartToMaxBoundMapping: Map<string, number> = new Map([
  [PivotAgg.DATE_PART_HOUR, 24],
  [PivotAgg.DATE_PART_MONTH, 12],
  [PivotAgg.DATE_PART_MONTH_DAY, 31],
  [PivotAgg.DATE_PART_WEEK_DAY, 7],
]);

export const getDateMin = (
  range: RELATIVE_DATE_RANGES | undefined,
  timezone: string,
): DateTime | undefined => {
  if (!range || range === RELATIVE_DATE_RANGES.PAST_DATES) return;

  const minDate = DateTime.local().setZone(timezone);

  switch (range) {
    case RELATIVE_DATE_RANGES.TODAY:
      return zoneToUtc(minDate.startOf('day'));
    case RELATIVE_DATE_RANGES.YESTERDAY:
      return zoneToUtc(minDate.minus({ days: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_WEEK:
      return zoneToUtc(minDate.startOf('week').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_MONTH:
      return zoneToUtc(minDate.startOf('month').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_YEAR:
      return zoneToUtc(minDate.startOf('year').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_WEEK:
      return zoneToUtc(minDate.startOf('week').minus({ weeks: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_MONTH:
      return zoneToUtc(minDate.startOf('month').minus({ months: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.PREVIOUS_YEAR:
      return zoneToUtc(minDate.startOf('year').minus({ years: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_WEEK:
      return zoneToUtc(minDate.minus({ days: 6 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_MONTH:
      return zoneToUtc(minDate.minus({ days: 30 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_YEAR:
      return zoneToUtc(minDate.minus({ days: 365 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_THREE_YEARS:
      return zoneToUtc(minDate.minus({ year: 3 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_FIVE_YEARS:
      return zoneToUtc(minDate.minus({ year: 5 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_TEN_YEARS:
      return zoneToUtc(minDate.minus({ year: 10 }).startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_QUARTER:
      return zoneToUtc(minDate.startOf('quarter').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_QUARTER:
      return zoneToUtc(minDate.startOf('quarter').minus({ quarter: 1 }).startOf('day'));
  }
};

export const getDateMax = (
  range: RELATIVE_DATE_RANGES | undefined,
  timezone: string,
): DateTime | undefined => {
  if (!range) return;

  const maxDate = DateTime.local().setZone(timezone);

  switch (range) {
    case RELATIVE_DATE_RANGES.TODAY:
      return zoneToUtc(maxDate.endOf('day'));
    case RELATIVE_DATE_RANGES.YESTERDAY:
      return zoneToUtc(maxDate.minus({ days: 1 }).endOf('day'));
    case RELATIVE_DATE_RANGES.THIS_WEEK:
      return zoneToUtc(maxDate.endOf('week').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_MONTH:
      return zoneToUtc(maxDate.endOf('month').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_YEAR:
      return zoneToUtc(maxDate.endOf('year').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_WEEK:
      return zoneToUtc(maxDate.endOf('week').minus({ days: 7 }).startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_MONTH:
      return zoneToUtc(maxDate.endOf('month').minus({ months: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_WEEK:
      return zoneToUtc(maxDate.plus({ days: 6 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_MONTH:
      return zoneToUtc(maxDate.plus({ days: 30 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_YEAR:
      return zoneToUtc(maxDate.plus({ days: 365 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_THREE_YEARS:
      return zoneToUtc(maxDate.plus({ year: 3 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_FIVE_YEARS:
      return zoneToUtc(maxDate.plus({ year: 5 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_TEN_YEARS:
      return zoneToUtc(maxDate.plus({ year: 10 }).startOf('day'));
    case RELATIVE_DATE_RANGES.PAST_DATES:
      return zoneToUtc(maxDate);
    case RELATIVE_DATE_RANGES.THIS_QUARTER:
      return zoneToUtc(maxDate.endOf('quarter').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_QUARTER:
      return zoneToUtc(maxDate.endOf('quarter').minus({ quarter: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.PREVIOUS_YEAR:
      return zoneToUtc(maxDate.endOf('year').minus({ year: 1 }).startOf('day'));
  }
};

export const getDefaultRelativeValue = (
  defaultRelativeOption: RELATIVE_DATE_OPTIONS,
  timezone: string,
): DateTime => {
  const date = DateTime.local().setZone(timezone);

  switch (defaultRelativeOption) {
    case RELATIVE_DATE_OPTIONS.CURRENT_DAY:
      return zoneToUtc(date.startOf('day'));
    case RELATIVE_DATE_OPTIONS.SEVEN_DAYS_AGO:
      return zoneToUtc(date.startOf('day').minus({ days: 7 }));
    case RELATIVE_DATE_OPTIONS.THIRTY_DAYS_AGO:
      return zoneToUtc(date.startOf('day').minus({ days: 30 }));
    case RELATIVE_DATE_OPTIONS.ONE_YEAR_AGO:
      return zoneToUtc(date.startOf('day').minus({ year: 1 }));
    case RELATIVE_DATE_OPTIONS.START_OF_WEEK:
      return zoneToUtc(date.startOf('week').startOf('day'));
    case RELATIVE_DATE_OPTIONS.START_OF_MONTH:
      return zoneToUtc(date.startOf('month').startOf('day'));
    case RELATIVE_DATE_OPTIONS.START_OF_YEAR:
      return zoneToUtc(date.startOf('year').startOf('day'));
    case RELATIVE_DATE_OPTIONS.END_OF_MONTH:
      return zoneToUtc(date.endOf('month').startOf('day'));
    case RELATIVE_DATE_OPTIONS.END_OF_WEEK:
      return zoneToUtc(date.endOf('week').startOf('day'));
    case RELATIVE_DATE_OPTIONS.END_OF_YEAR:
      return zoneToUtc(date.endOf('year').startOf('day'));
  }
};

export const getDefaultRangeValues = (
  defaultRangeType: DEFAULT_DATE_RANGES,
  endDateEndOfDay: boolean | undefined,
  timezone: string,
) => {
  let startDate: DateTime | undefined;
  let endDate = DateTime.local().setZone(timezone);

  switch (defaultRangeType) {
    case DEFAULT_DATE_RANGES.TODAY:
      startDate = getDateMin(RELATIVE_DATE_RANGES.TODAY, timezone);
      break;
    case DEFAULT_DATE_RANGES.YESTERDAY:
      startDate = getDateMin(RELATIVE_DATE_RANGES.YESTERDAY, timezone);
      endDate = startDate ? startDate.endOf('day') : endDate;
      break;
    case DEFAULT_DATE_RANGES.THIS_WEEK:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_WEEK, timezone);
      break;
    case DEFAULT_DATE_RANGES.THIS_MONTH:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_MONTH, timezone);
      break;
    case DEFAULT_DATE_RANGES.THIS_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_YEAR, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_WEEK:
      startDate = getDateMin(RELATIVE_DATE_RANGES.LAST_WEEK, timezone);
      endDate = startDate ? startDate.endOf('week') : endDate;
      break;
    case DEFAULT_DATE_RANGES.LAST_MONTH:
      startDate = getDateMin(RELATIVE_DATE_RANGES.LAST_MONTH, timezone);
      endDate = startDate ? startDate.endOf('month') : endDate;
      break;
    case DEFAULT_DATE_RANGES.PREVIOUS_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.PREVIOUS_YEAR, timezone);
      endDate = startDate ? startDate.endOf('year') : endDate;
      break;
    case DEFAULT_DATE_RANGES.LAST_7_DAYS:
      startDate = getDateMin(RELATIVE_DATE_RANGES.WITHIN_A_WEEK, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_30_DAYS:
      startDate = getDateMin(RELATIVE_DATE_RANGES.WITHIN_A_MONTH, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_3_MONTHS:
      startDate = endDate.minus({ month: 3 }).startOf('day');
      break;
    case DEFAULT_DATE_RANGES.LAST_6_MONTHS:
      startDate = endDate.minus({ month: 6 }).startOf('day');
      break;
    case DEFAULT_DATE_RANGES.LAST_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.WITHIN_A_YEAR, timezone);
      break;
    case DEFAULT_DATE_RANGES.THIS_QUARTER:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_QUARTER, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_QUARTER: {
      startDate = getDateMin(RELATIVE_DATE_RANGES.LAST_QUARTER, timezone);
      endDate = startDate ? startDate.endOf('quarter') : endDate;
      break;
    }
    case DEFAULT_DATE_RANGES.LAST_12_COMPLETE_MONTHS:
      endDate = endDate.startOf('month').minus({ day: 1 });
      startDate = endDate.minus({ month: 11 }).startOf('month');
      break;
    case DEFAULT_DATE_RANGES.LAST_12_COMPLETE_WEEKS:
      endDate = endDate.startOf('week').minus({ day: 1 });
      startDate = endDate.minus({ week: 11 }).startOf('week');
      break;
    case DEFAULT_DATE_RANGES.LAST_3_COMPLETE_MONTHS:
      startDate = endDate.minus({ months: 3 }).startOf('month');
      endDate = endDate.minus({ months: 1 }).endOf('month');
      break;
    case DEFAULT_DATE_RANGES.LAST_6_COMPLETE_MONTHS:
      startDate = endDate.minus({ months: 6 }).startOf('month');
      endDate = endDate.minus({ months: 1 }).endOf('month');
      break;
    case DEFAULT_DATE_RANGES.YEAR_TO_LAST_COMPLETED_MONTH:
      // if january, the range should be last year to end of december
      if (endDate.month === 1) {
        startDate = endDate.minus({ year: 1 }).startOf('year');
        endDate = endDate.minus({ months: 1 }).endOf('month');
      } else {
        startDate = endDate.startOf('year');
        endDate = endDate.minus({ months: 1 }).endOf('month');
      }
      break;
    case DEFAULT_DATE_RANGES.FULL_WEEK:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_WEEK, timezone);
      endDate = startDate ? startDate.endOf('week') : endDate;
      break;
    case DEFAULT_DATE_RANGES.FULL_MONTH:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_MONTH, timezone);
      endDate = startDate ? startDate.endOf('month') : endDate;
      break;
    case DEFAULT_DATE_RANGES.FULL_QUARTER:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_QUARTER, timezone);
      endDate = startDate ? startDate.endOf('quarter') : endDate;
      break;
    case DEFAULT_DATE_RANGES.FULL_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_YEAR, timezone);
      endDate = startDate ? startDate.endOf('year') : endDate;
      break;
  }

  if (endDateEndOfDay) endDate = endDate.endOf('day');

  // this shouldn't ever happen, but just so we don't crash
  if (!startDate) startDate = endDate;

  return { startDate: zoneToUtc(startDate), endDate: zoneToUtc(endDate) };
};

const fixISODateFormatting = (isoDate: string) => {
  // if the date is in the format YYYY-MM-DD followed by a space, replace it with a T
  // this is because the date picker library we use doesn't like the space
  return isoDate.replace(/(\d{4}-\d{2}-\d{2}) /, '$1T');
};

export const dateTimeFromISOString = (isoString: string, options?: DateTimeOptions) => {
  // wrapper function for DateTime.fromISO to handle formatting issues
  const isoFormattedString =
    typeof isoString === 'string' ? fixISODateFormatting(isoString) : isoString;
  return DateTime.fromISO(isoFormattedString, options || {});
};

/**
 * Get the current date as an ISO string
 */
export const getCurrentISOString = () => zoneToUtc(DateTime.now()).toISO();

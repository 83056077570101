import { ReactNode, FC } from 'react';
import { UniqueIdentifier, Over } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';

import { sprinkles } from 'components/ds';

type Props = {
  id: string;
  items: UniqueIdentifier[];
  children: ReactNode;
  over: Over | null;
  isOver: boolean;
  disabled?: boolean;
  setNodeRef: (element: HTMLElement | null) => void;
};

export const DataPanelList: FC<Props> = ({
  id,
  items,
  children,
  over,
  isOver,
  setNodeRef,
  disabled,
}) => {
  const isOverContainer =
    isOver || over?.data.current?.section === id || !!items.find((id) => id === over?.id);

  return (
    <DataPanelSection ref={setNodeRef}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
      {isOverContainer && disabled ? (
        <div className={disabledBorderStyle} />
      ) : isOverContainer ? (
        <div className={activeBorderStyle} />
      ) : null}
    </DataPanelSection>
  );
};

const disabledBorderStyle = sprinkles({
  positionAbsolute: 'fill',
  border: 2,
  borderColor: 'gray3',
});

const activeBorderStyle = sprinkles({
  positionAbsolute: 'fill',
  border: 2,
  borderColor: 'active',
});

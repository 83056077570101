import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { Switch } from 'components/ds';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const TooltipConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  return (
    <div className={configRootClass}>
      <Switch
        className={configInputClass}
        label="Show percentage values"
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.tooltipFormat) draft.tooltipFormat = {};
            draft.tooltipFormat.showPct = !draft.tooltipFormat.showPct;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        switchOn={instructions.tooltipFormat?.showPct}
      />
    </div>
  );
};

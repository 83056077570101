import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Updater } from 'use-immer';
import * as RD from 'remotedata';

import { Button, Icon, IconButton, Switch, sprinkles } from 'components/ds';
import DropdownSelect from 'shared/DropdownSelect';
import * as styles from './styles.css';

import { CustomerPermissionsForObject } from 'types/permissionTypes';
import { ReduxState } from 'reducers/rootReducer';
import { fetchGroupTags } from 'actions/customerActions';
import { SelectedDropdownInputItem } from 'constants/types';
import { IconName } from 'components/ds/Icon';

type Props = {
  permissions: CustomerPermissionsForObject;
  setPermissions: Updater<CustomerPermissionsForObject>;
};

type NewSelection = { type: 'customer'; id: number } | { type: 'group'; id: number };
const GROUP_ID = 'group-';
const CUSTOMER_ID = 'customer-';

export const CustomerPermissionsSetter: FC<Props> = ({ permissions, setPermissions }) => {
  const dispatch = useDispatch();

  const [newSelection, setNewSelection] = useState<NewSelection | null>(null);

  const { customers, groupTags } = useSelector(
    (state: ReduxState) => ({
      customers: state.customers.groups,
      groupTags: state.customers.groupTags,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (RD.isIdle(groupTags)) dispatch(fetchGroupTags());
  }, [dispatch, groupTags]);

  const options = useMemo(() => {
    if (!RD.isSuccess(customers) || !RD.isSuccess(groupTags)) return [];

    const filterCustomerSet = new Set(permissions.customerIds);
    const filterGroupTagSet = new Set(permissions.groupTagIds);
    const options: SelectedDropdownInputItem[] = [];

    groupTags.data.forEach((tag) => {
      if (filterGroupTagSet.has(tag.id)) return;
      // ID can be the same between a group and a customer so need to prepend
      options.push({
        id: `${GROUP_ID}${tag.id}`,
        name: tag.name,
        icon: <Icon name="users" />,
      });
    });

    customers.data.forEach((customer) => {
      if (filterCustomerSet.has(customer.id)) return;
      options.push({
        id: `${CUSTOMER_ID}${customer.id}`,
        name: customer.name || String(customer.id),
        icon: <Icon name="user" />,
      });
    });

    return options;
  }, [customers, groupTags, permissions.customerIds, permissions.groupTagIds]);

  const selectedOption = useMemo(() => {
    if (!newSelection) return;

    const selectedId = `${newSelection.type === 'customer' ? CUSTOMER_ID : GROUP_ID}${
      newSelection.id
    }`;
    return options.find((opt) => opt.id === selectedId);
  }, [options, newSelection]);

  const selectedCustomers = useMemo(() => {
    if (!RD.isSuccess(customers) || !permissions.customerIds.length) return [];
    const customerIdSet = new Set(permissions.customerIds);
    return customers.data.filter((customer) => customerIdSet.has(customer.id));
  }, [customers, permissions.customerIds]);

  const selectedGroupTags = useMemo(() => {
    if (!RD.isSuccess(groupTags) || !permissions.groupTagIds?.length) return [];
    const groupTagIdSet = new Set(permissions.groupTagIds);
    return groupTags.data.filter((tag) => groupTagIdSet.has(tag.id));
  }, [groupTags, permissions.groupTagIds]);

  const renderListItem = (
    name: string,
    id: string | undefined,
    iconName: IconName,
    onDelete: () => void,
  ) => {
    return (
      <div className={customerListItemClass} key={`${iconName}-${id ?? name}`}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', body: 'b2' })}>
          <Icon name={iconName} size="sm" /> {name}{' '}
          {id !== undefined ? (
            <span className={sprinkles({ color: 'contentSecondary' })}>({id})</span>
          ) : null}
        </div>
        <IconButton name="cross" onClick={onDelete} variant="secondary" />
      </div>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
      <Switch
        className={styles.switchStyle}
        label="Visible to all customers"
        onChange={() => {
          setPermissions((draft) => {
            draft.allCustomers = !draft.allCustomers;
          });
        }}
        switchOn={permissions.allCustomers}
      />

      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', width: 'fill' })}>
        <div className={sprinkles({ flex: 1 })}>
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            disabled={!RD.isSuccess(customers) || !RD.isSuccess(groupTags)}
            noSelectionText="Select customer or group tag"
            onChange={(item) => {
              const isGroup = item.id.startsWith(GROUP_ID);
              const stringId = item.id.replace(isGroup ? GROUP_ID : CUSTOMER_ID, '');
              const itemId = parseInt(stringId);
              if (isNaN(itemId)) return;
              setNewSelection({ type: isGroup ? 'group' : 'customer', id: itemId });
            }}
            options={options}
            selectedItem={selectedOption}
          />
        </div>
        <Button
          disabled={!newSelection}
          onClick={() => {
            if (newSelection?.type === 'customer') {
              setPermissions((draft) => {
                draft.allCustomers = false;
                const customerIdx = draft.customerIds.indexOf(newSelection.id);
                if (customerIdx === -1) draft.customerIds.push(newSelection.id);
                else draft.customerIds.splice(customerIdx, 1);
              });
            } else if (newSelection?.type === 'group') {
              setPermissions((draft) => {
                draft.allCustomers = false;
                if (!draft.groupTagIds) {
                  draft.groupTagIds = [newSelection.id];
                  return;
                }

                const groupIdx = draft.groupTagIds.indexOf(newSelection.id);
                if (groupIdx === -1) draft.groupTagIds.push(newSelection.id);
                else draft.groupTagIds.splice(groupIdx, 1);
              });
            }
            setNewSelection(null);
          }}
          variant="primary">
          Add
        </Button>
      </div>
      <div
        className={sprinkles({ flexItems: 'column', gap: 'sp1', overflow: 'auto' })}
        style={{ minHeight: 200 }}>
        {permissions.allCustomers ? (
          <div className={emptyCustomerTextClass}>Visible to all customers</div>
        ) : selectedCustomers.length === 0 && selectedGroupTags.length === 0 ? (
          <div className={emptyCustomerTextClass}>Not visible to any customers</div>
        ) : (
          <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
            {selectedGroupTags.map((tag) =>
              renderListItem(tag.name, undefined, 'users', () =>
                setPermissions((draft) => {
                  draft.groupTagIds = draft.groupTagIds?.filter((id) => id !== tag.id);
                }),
              ),
            )}
            {selectedCustomers.map((customer) =>
              renderListItem(customer.name, customer.provided_id, 'user', () =>
                setPermissions((draft) => {
                  draft.customerIds = draft.customerIds.filter((id) => id !== customer.id);
                }),
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const emptyCustomerTextClass = sprinkles({
  height: 'fill',
  flexItems: 'center',
  body: 'b2',
  color: 'contentTertiary',
});

const customerListItemClass = sprinkles({
  flexItems: 'alignCenterBetween',
  gap: 'sp1',
});

import { DateTime } from 'luxon';

import {
  And,
  BooleanPropertyValue,
  Computation,
  DateTimePropertyValue,
  Equal,
  Filter,
  In,
  IntegerPropertyValue,
  LateBoundEqual,
  LateBoundIn,
  Not,
  Or,
  PropertyValue,
  SortDirection,
  StringPropertyValue,
  Aggregation as FidoAggregation,
  Grouping,
  ValueGrouping,
  IntegerIntervalGrouping,
  CalendarInterval,
  CalendarIntervalGrouping,
  DatePartGrouping,
  DatePart,
  GreaterThanOrEqual,
  LessThanOrEqual,
  LessThan,
  GreaterThan,
  LateBoundStringContains,
  StringContains,
  Null,
  LateBoundLessThan,
  LateBoundGreaterThan,
  LateBoundLessThanOrEqual,
  LateBoundGreaterThanOrEqual,
  BaseComputation,
  Pivot,
  AggregationOption,
} from '@explo-tech/fido-api';

import { NUMBER_TYPES, TIME_COLUMN_TYPES } from 'constants/dataConstants';
import {
  Aggregation,
  ChartColumnInfo,
  FilterOperationInstructions,
  FilterValueDateType,
  FilterValueNumberRangeType,
  FilterValueRelativeDateType,
  FilterValueSourceType,
  FilterValueType,
  SortInfo,
  SortOrder,
  OPERATION_TYPES,
} from 'constants/types';
import { PivotAgg, TrendGroupingOptions } from 'types/dateRangeTypes';
import {
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_EMPTY,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NEGATED,
  FILTER_OPS_NUMBER,
  FILTER_OPS_NUMBER_RANGE,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperator,
} from 'types/filterOperations';

import { getDatesFromDateRelativeOption } from 'utils/dateTimeUtils';
import { GroupByBucket } from 'types/columnTypes';
import { VisualizeOperation } from 'types/dataPanelTemplate';

const DEFAULT_QUERY_LIMIT = 5000;

export const getEmptyComputation = (): Computation => ({
  '@type': 'computation',
  properties: [],
  filter: null,
  sorts: [],
  groupings: [],
});

export const getEmptyPivot = (): Pivot => ({
  '@type': 'pivot',
  columns: [],
  filter: null,
  sorts: [],
  rows: [],
  values: [],
});

export const processSort = (sortInfo: SortInfo[] | undefined, computation: BaseComputation) => {
  (sortInfo ?? []).forEach((colInfo) => {
    computation.sorts.push({
      propertyId: colInfo.column.name,
      sortDirection: colInfo.order === SortOrder.ASC ? SortDirection.ASC : SortDirection.DESC,
    });
  });
};

export const getQueryLimit = (visualizeOperation: VisualizeOperation) => {
  switch (visualizeOperation.operation_type) {
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      return visualizeOperation.instructions.VISUALIZE_GEOSPATIAL_CHART?.rowLimit ?? 1000;
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_TABLE:
      return visualizeOperation.instructions.VISUALIZE_TABLE.rowsPerPage ?? 50;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      return 1000;
    default:
      return DEFAULT_QUERY_LIMIT;
  }
};

export const getFilterValue = (
  value: FilterValueType,
  operator: FilterOperator,
): { value?: PropertyValue; values?: PropertyValue[] } | null | undefined => {
  // this block checks the no value filters
  if (operator === FilterOperator.BOOLEAN_IS_FALSE) {
    const propertyValue: BooleanPropertyValue = { value: false, '@type': 'boolean' };
    return { value: propertyValue };
  } else if (operator === FilterOperator.BOOLEAN_IS_TRUE) {
    const propertyValue: BooleanPropertyValue = { value: true, '@type': 'boolean' };
    return { value: propertyValue };
  } else if (operator === FilterOperator.DATE_TODAY) {
    // TODO FIDO we need to decide how we're handling dates vs datetimes
    const now = DateTime.now();
    const startDatePropertyValue: DateTimePropertyValue = {
      value: now.startOf('day').toISO(),
      '@type': 'datetime',
    };
    const endDatePropertyValue: DateTimePropertyValue = {
      value: now.endOf('day').toISO(),
      '@type': 'datetime',
    };
    return { values: [startDatePropertyValue, endDatePropertyValue] };
  } else if (FILTER_OPS_EMPTY.has(operator)) {
    return {};
  }

  if (value === undefined) return null;

  if (FILTER_OPS_NUMBER.has(operator)) {
    const propertyValue: IntegerPropertyValue = { value: value as number, '@type': 'integer' };
    return { value: propertyValue };
  } else if (FILTER_OPS_STRING.has(operator)) {
    const propertyValue: StringPropertyValue = { value: value as string, '@type': 'string' };
    return { value: propertyValue };
  } else if (FILTER_OPS_MULTISELECT.has(operator)) {
    const propertyValues: PropertyValue[] = [...(value as string[] | number[])].map((v) => {
      if ([FilterOperator.NUMBER_IS_IN, FilterOperator.NUMBER_IS_NOT_IN].includes(operator)) {
        const propertyValue: IntegerPropertyValue = { value: v as number, '@type': 'integer' };
        return propertyValue;
      } else {
        const propertyValue: StringPropertyValue = { value: v as string, '@type': 'string' };
        return propertyValue;
      }
    });
    return { values: propertyValues };
  } else if (FILTER_OPS_NUMBER_RANGE.has(operator)) {
    const { min, max } = value as FilterValueNumberRangeType;
    if (!min || !max) return null;
    // TODO FIDO we never pass things to the backend as raw dates rn
    const minPropertyValue: IntegerPropertyValue = { value: min, '@type': 'integer' };
    const maxPropertyValue: IntegerPropertyValue = { value: max, '@type': 'integer' };
    return { values: [minPropertyValue, maxPropertyValue] };
  } else if (FILTER_OPS_DATE_PICKER.has(operator)) {
    const date = (value as FilterValueDateType).startDate;
    if (!date) return null;
    if (new Set([FilterOperator.DATE_IS, FilterOperator.DATE_IS_NOT]).has(operator)) {
      const startDatePropertyValue: DateTimePropertyValue = { value: date, '@type': 'datetime' };
      const endDatePropertyValue: DateTimePropertyValue = {
        value: DateTime.fromISO(date).endOf('day').toISO(),
        '@type': 'datetime',
      };

      return { values: [startDatePropertyValue, endDatePropertyValue] };
    }

    const propertyValue: DateTimePropertyValue = { value: date, '@type': 'datetime' };
    return { value: propertyValue };
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(operator)) {
    const { startDate, endDate } = value as FilterValueDateType;
    if (!startDate || !endDate) return null;
    // TODO FIDO we never pass things to the backend as raw dates rn
    const startDatePropertyValue: DateTimePropertyValue = { value: startDate, '@type': 'datetime' };
    const endDatePropertyValue: DateTimePropertyValue = { value: endDate, '@type': 'datetime' };
    return { values: [startDatePropertyValue, endDatePropertyValue] };
  } else if (FILTER_OPS_RELATIVE_PICKER.has(operator)) {
    const { number, relativeTimeType } = value as FilterValueRelativeDateType;
    if (!number || !relativeTimeType) return null;

    const { startDate, endDate } = getDatesFromDateRelativeOption(
      relativeTimeType.id,
      number,
      operator === FilterOperator.DATE_PREVIOUS,
    );

    const startDatePropertyValue: DateTimePropertyValue = {
      value: startDate.toISO(),
      '@type': 'datetime',
    };
    const endDatePropertyValue: DateTimePropertyValue = {
      value: endDate.toISO(),
      '@type': 'datetime',
    };
    return { values: [startDatePropertyValue, endDatePropertyValue] };
  }

  return null;
};

export const getFilter = (
  columnName: string,
  operation: FilterOperator,
  sourceType: FilterValueSourceType,
  variableSource: string | undefined,
  valueSource: FilterValueType | undefined,
) => {
  const value = getFilterValue(valueSource, operation);

  // var references still get populated at request time sometimes
  const isVarReference =
    sourceType === FilterValueSourceType.VARIABLE && (value == null || value == undefined);

  switch (operation) {
    case FilterOperator.NUMBER_EQ:
    case FilterOperator.STRING_IS:
    case FilterOperator.NUMBER_NEQ:
    case FilterOperator.STRING_IS_NOT: {
      if (isVarReference) {
        if (!variableSource) return null;
        const filter: LateBoundEqual = {
          '@type': 'eq-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.value) return null;
        const filter: Equal = {
          '@type': 'eq',
          value: value.value,
          propertyId: columnName,
        };
        return filter;
      }
    }
    case FilterOperator.DATE_IS:
    case FilterOperator.DATE_IS_NOT:
    case FilterOperator.DATE_TODAY: {
      if (isVarReference) {
        // we really should never get here
        if (!variableSource) return null;
        const filter: LateBoundEqual = {
          '@type': 'eq-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.values) return null;
        const startFilter: GreaterThanOrEqual = {
          '@type': 'gte',
          value: value.values[0],
          propertyId: columnName,
        };
        const endFilter: LessThan = {
          '@type': 'lt',
          value: value.values[1],
          propertyId: columnName,
        };
        const and: And = {
          '@type': 'and',
          values: [startFilter, endFilter],
        };
        return and;
      }
    }
    case FilterOperator.BOOLEAN_IS_TRUE:
    case FilterOperator.BOOLEAN_IS_FALSE: {
      // isVarReference shouldn't ever be set for these
      if (!value?.value) return null;
      const filter: Equal = {
        '@type': 'eq',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.STRING_IS_NOT_IN:
    case FilterOperator.STRING_IS_IN:
    case FilterOperator.NUMBER_IS_IN:
    case FilterOperator.NUMBER_IS_NOT_IN: {
      if (isVarReference) {
        if (!variableSource) return null;
        const filter: LateBoundIn = {
          '@type': 'in-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.values) return null;
        const filter: In = {
          '@type': 'in',
          values: value.values,
          propertyId: columnName,
        };
        return filter;
      }
    }
    case FilterOperator.NUMBER_LT:
    case FilterOperator.DATE_LT: {
      if (isVarReference) {
        if (!variableSource) return null;
        const filter: LateBoundLessThan = {
          '@type': 'lt-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.value) return null;
        const filter: LessThan = {
          '@type': 'lt',
          value: value.value,
          propertyId: columnName,
        };
        return filter;
      }
    }
    case FilterOperator.NUMBER_GT:
    case FilterOperator.DATE_GT: {
      if (isVarReference) {
        if (!variableSource) return null;
        const filter: LateBoundGreaterThan = {
          '@type': 'gt-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.value) return null;
        const filter: GreaterThan = {
          '@type': 'gt',
          value: value.value,
          propertyId: columnName,
        };
        return filter;
      }
    }
    case FilterOperator.NUMBER_LTE:
    case FilterOperator.DATE_LTE: {
      if (isVarReference) {
        if (!variableSource) return null;
        const filter: LateBoundLessThanOrEqual = {
          '@type': 'lte-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.value) return null;
        const filter: LessThanOrEqual = {
          '@type': 'lte',
          value: value.value,
          propertyId: columnName,
        };
        return filter;
      }
    }
    case FilterOperator.NUMBER_GTE:
    case FilterOperator.DATE_GTE: {
      if (isVarReference) {
        if (!variableSource) return null;
        const filter: LateBoundGreaterThanOrEqual = {
          '@type': 'gte-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
        };
        return filter;
      } else {
        if (!value?.value) return null;
        const filter: GreaterThanOrEqual = {
          '@type': 'gte',
          value: value.value,
          propertyId: columnName,
        };
        return filter;
      }
    }
    case FilterOperator.DATE_IS_BETWEEN: {
      if (isVarReference) {
        if (!variableSource) return null;
        const startFilter: LateBoundGreaterThanOrEqual = {
          '@type': 'gte-var-ref',
          // variableSource is just "dateRange", for example, so we need to add .startDate and .endDate for the nesting
          valueVariableReference: variableSource + '.startDate',
          propertyId: columnName,
        };
        const endFilter: LateBoundLessThanOrEqual = {
          '@type': 'lte-var-ref',
          valueVariableReference: variableSource + '.endDate',
          propertyId: columnName,
        };
        const and: And = {
          '@type': 'and',
          values: [startFilter, endFilter],
        };
        return and;
      } else {
        if (!value?.values) return null;
        const startFilter: GreaterThanOrEqual = {
          '@type': 'gte',
          value: value.values[0],
          propertyId: columnName,
        };
        const endFilter: LessThanOrEqual = {
          '@type': 'lte',
          value: value.values[1],
          propertyId: columnName,
        };
        const and: And = {
          '@type': 'and',
          values: [startFilter, endFilter],
        };
        return and;
      }
    }
    case FilterOperator.NUMBER_IS_BETWEEN:
    case FilterOperator.DATE_PREVIOUS:
    case FilterOperator.DATE_NEXT: {
      if (!value?.values) return null;
      const startFilter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.values[0],
        propertyId: columnName,
      };
      const endFilter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.values[1],
        propertyId: columnName,
      };
      const and: And = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.STRING_CONTAINS:
    case FilterOperator.STRING_DOES_NOT_CONTAIN: {
      if (isVarReference) {
        if (!variableSource) return null;
        const contains: LateBoundStringContains = {
          '@type': 'str-ctns-var-ref',
          valueVariableReference: variableSource,
          propertyId: columnName,
          caseInsensitive: true,
        };
        return contains;
      } else {
        if (!value?.value) return null;
        const contains: StringContains = {
          '@type': 'str-ctns',
          value: value.value as StringPropertyValue,
          propertyId: columnName,
          caseInsensitive: true,
        };
        return contains;
      }
    }
    case FilterOperator.IS_EMPTY:
    case FilterOperator.IS_NOT_EMPTY: {
      const isNull: Null = {
        '@type': 'null',
        propertyId: columnName,
      };
      return isNull;
    }
  }
};

export const aggregationMap: Record<
  Aggregation,
  { agg: FidoAggregation; aggOption?: AggregationOption }
> = {
  [Aggregation.COUNT]: { agg: FidoAggregation.COUNT },
  [Aggregation.COUNT_DISTINCT]: { agg: FidoAggregation.COUNT_DISTINCT },
  [Aggregation.AVG]: { agg: FidoAggregation.AVG },
  [Aggregation.SUM]: { agg: FidoAggregation.SUM },
  [Aggregation.MIN]: { agg: FidoAggregation.MIN },
  [Aggregation.MAX]: { agg: FidoAggregation.MAX },
  [Aggregation['25_PERCENTILE']]: {
    agg: FidoAggregation.PERCENTILE,
    aggOption: { decimalValue: 0.25 },
  },
  [Aggregation.MEDIAN]: { agg: FidoAggregation.PERCENTILE, aggOption: { decimalValue: 0.5 } },
  [Aggregation['75_PERCENTILE']]: {
    agg: FidoAggregation.PERCENTILE,
    aggOption: { decimalValue: 0.75 },
  },

  // TODOs
  [Aggregation.FORMULA]: { agg: FidoAggregation.COUNT },
  [Aggregation.FIRST]: { agg: FidoAggregation.COUNT },
};

export const processFilter = (filterInfo: FilterOperationInstructions | undefined) => {
  if (!filterInfo || filterInfo.filterClauses.length === 0) return null;

  const filters: Filter[] = [];

  filterInfo.filterClauses.forEach(
    ({ filterOperation, filterValueSource, filterColumn, filterValue, filterValueVariableId }) => {
      if (!filterOperation || !filterColumn) return null;

      // when we're done implementing this'll just be Filter | null
      const filter: Filter | null | undefined = getFilter(
        filterColumn.name,
        filterOperation.id,
        // ad hoc filters don't have a value source type but are input values
        filterValueSource ?? FilterValueSourceType.INPUT,
        filterValueVariableId,
        filterValue,
      );

      if (!filter) return null;

      if (FILTER_OPS_NEGATED.has(filterOperation.id)) {
        const not: Not = {
          '@type': 'not',
          value: filter,
        };

        filters.push(not);
      } else {
        filters.push(filter);
      }
    },
  );

  if (filters.length === 1) {
    return filters[0];
  } else if (filters.length > 1) {
    if (filterInfo.matchOnAll) {
      const andFilter: And = { values: filters, '@type': 'and' };
      return andFilter;
    } else {
      const orFilter: Or = { values: filters, '@type': 'or' };
      return orFilter;
    }
  }
  return null;
};

export const getGrouping = ({
  column,
  bucket,
  bucketSize,
}: {
  column: ChartColumnInfo;
  bucket?: GroupByBucket;
  bucketSize?: number;
}): Grouping | null => {
  if (NUMBER_TYPES.has(column.type ?? '') && bucketSize) {
    const grouping: IntegerIntervalGrouping = {
      '@type': 'integer-interval',
      interval: bucketSize,
      propertyId: column.name ?? '',
    };
    return grouping;
  } else if (TIME_COLUMN_TYPES.has(column.type ?? '') && bucket) {
    switch (bucket.id) {
      case PivotAgg.DATE_PART_HOUR: {
        const grouping: DatePartGrouping = {
          '@type': 'date-part',
          datePart: DatePart.HOUR_OF_DAY,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_PART_MONTH: {
        const grouping: DatePartGrouping = {
          '@type': 'date-part',
          datePart: DatePart.MONTH_OF_YEAR,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_PART_MONTH_DAY: {
        const grouping: DatePartGrouping = {
          '@type': 'date-part',
          datePart: DatePart.DAY_OF_MONTH,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_PART_WEEK_DAY: {
        const grouping: DatePartGrouping = {
          '@type': 'date-part',
          datePart: DatePart.DAY_OF_WEEK,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_HOUR: {
        const grouping: CalendarIntervalGrouping = {
          '@type': 'calendar-interval',
          calendarInterval: CalendarInterval.HOUR,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_DAY: {
        const grouping: CalendarIntervalGrouping = {
          '@type': 'calendar-interval',
          calendarInterval: CalendarInterval.DAY,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_WEEK: {
        const grouping: CalendarIntervalGrouping = {
          '@type': 'calendar-interval',
          calendarInterval: CalendarInterval.WEEK,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_MONTH: {
        const grouping: CalendarIntervalGrouping = {
          '@type': 'calendar-interval',
          calendarInterval: CalendarInterval.MONTH,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      case PivotAgg.DATE_YEAR: {
        const grouping: CalendarIntervalGrouping = {
          '@type': 'calendar-interval',
          calendarInterval: CalendarInterval.YEAR,
          propertyId: column.name ?? '',
        };
        return grouping;
      }
      default:
        return null;
    }
  } else {
    const grouping: ValueGrouping = {
      '@type': 'value',
      propertyId: column.name ?? '',
    };
    return grouping;
  }
};

export const getTrendGrouping = ({
  column,
  grouping,
}: {
  column: ChartColumnInfo;
  grouping: TrendGroupingOptions;
}): Grouping | null => {
  switch (grouping) {
    case TrendGroupingOptions.HOURLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.HOUR,
        propertyId: column.name ?? '',
      };
      return grouping;
    }
    case TrendGroupingOptions.DAILY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.DAY,
        propertyId: column.name ?? '',
      };
      return grouping;
    }
    case TrendGroupingOptions.WEEKLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.WEEK,
        propertyId: column.name ?? '',
      };
      return grouping;
    }
    case TrendGroupingOptions.MONTHLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.MONTH,
        propertyId: column.name ?? '',
      };
      return grouping;
    }
    case TrendGroupingOptions.YEARLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.YEAR,
        propertyId: column.name ?? '',
      };
      return grouping;
    }
    default:
      return null;
  }
};

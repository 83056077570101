import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { FilterValueType } from 'constants/types';
import { FilterOperator } from 'types/filterOperations';
import { FilterableColumn } from 'utils/customerReportUtils';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { deleteFilter } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = {
  clause: CustomerReportFilter | undefined;
  column: FilterableColumn;
  operator: FilterOperator;
  showDelete: boolean;
  value: FilterValueType;
  onApply: () => void;
};

const buttonRowClass = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'flex-end',
  marginTop: 'sp2',
  paddingX: 'sp2',
});

export const ApplyFilterRow: FC<Props> = ({
  clause,
  column,
  operator,
  value,
  onApply,
  showDelete,
}) => {
  const dispatch = useDispatch();

  const col = { name: column.name, type: column.type };
  const newClause = { filterColumn: col, filterOperation: { id: operator }, filterValue: value };

  return (
    <div className={buttonRowClass}>
      {clause && showDelete ? (
        <EmbedButton
          className={sprinkles({ marginRight: 'sp2' })}
          onClick={() => dispatch(deleteFilter(clause.id))}
          variant="tertiary">
          Delete
        </EmbedButton>
      ) : null}
      <EmbedButton
        disabled={
          isFilterClauseIncomplete(newClause) ||
          (operator === clause?.filterOperation.id && value === clause?.filterValue)
        }
        onClick={onApply}
        variant="primary">
        Apply
      </EmbedButton>
    </div>
  );
};

import { FC, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { sprinkles } from 'components/ds';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { TransformationSettingsButton } from '../DataTable/TransformationSettingsButton';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { FilterButton } from './FilterButton';
import { PanelName } from './PanelName';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { EmbedButton } from 'components/embed';
import { DownloadTableButton } from './DownloadTableButton';

import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import {
  FilterOperationInstructions,
  UserTransformedSchema,
  LinkFormat,
  ExportConfig,
} from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { GlobalStylesContext } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DownloadPanelFunc, DownloadSpreadsheetFunc } from 'actions/exportActions';
import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import { isChartExportEnabled } from 'utils/exportUtils';

const useStyles = makeStyles((theme: Theme) => ({
  header: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    borderBottom: `1px solid ${globalStyleConfig.container.outline.color}`,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
    height: HEADER_HEIGHT,
    justifyContent: 'space-between',
  }),
  dataTablePadding: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(5)}px ${globalStyleConfig.container.padding.default}px`,
    paddingBottom: theme.spacing(5),
  }),
}));

type Props = {
  drilldownDatasetName?: string;
  adHocOperationInstructions: AdHocOperationInstructions;
  dpEndsOnRightSide?: boolean;
  error?: boolean;
  infoTooltipText?: string;
  isSchemaCustomizationEnabled?: boolean;
  isFilteringDisabled?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  title: string;
  schema: DatasetSchema;
  updateUserTransformedSchema?: (userTransformedSchema: UserTransformedSchema) => void;
  linkFormat?: LinkFormat;
  userTransformedSchema?: UserTransformedSchema;
  isDrilldownModal?: boolean;

  // Export Button props
  dataPanelId?: string;
  exportCurrentlyDisabled?: boolean;
  exportConfig?: ExportConfig;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadPanelFunc;
};

export const DataTableHeader: FC<Props> = ({
  drilldownDatasetName,
  adHocOperationInstructions,
  dpEndsOnRightSide,
  error,
  infoTooltipText,
  isSchemaCustomizationEnabled,
  isFilteringDisabled,
  loading,
  onAdHocFilterInfoUpdate,
  title,
  schema,
  updateUserTransformedSchema,
  linkFormat,
  userTransformedSchema,
  isDrilldownModal,
  dataPanelId,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  exportCurrentlyDisabled,
  exportConfig,
}) => {
  const dispatch = useDispatch();

  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  return (
    <div
      className={cx(
        classes.header,
        isDrilldownModal
          ? sprinkles({ paddingY: 'sp3', paddingX: 'sp4' })
          : classes.dataTablePadding,
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <PanelName
        drilldownDatasetName={drilldownDatasetName}
        infoTooltipText={infoTooltipText}
        loading={loading}
        panelName={title}
      />
      <div
        className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}
        onClick={(e) => e.stopPropagation()}>
        {isSchemaCustomizationEnabled && userTransformedSchema ? (
          <TransformationSettingsButton
            isDrilldownModal={!!isDrilldownModal}
            loading={loading}
            schema={schema}
            updateUserTransformedSchema={(schema) => updateUserTransformedSchema?.(schema)}
            userTransformedSchema={userTransformedSchema}
          />
        ) : null}
        {!isFilteringDisabled ? (
          <div className={sprinkles({ marginX: isDrilldownModal ? 'sp1' : undefined })}>
            <FilterButton
              adHocFilterInfo={adHocOperationInstructions?.filterInfo}
              error={!!error}
              isDrilldownModal={!!isDrilldownModal}
              loading={loading}
              onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
              openPopoverToRight={dpEndsOnRightSide === undefined ? false : !dpEndsOnRightSide}
              schema={userTransformedSchema ?? schema}
            />
          </div>
        ) : null}
        {exportConfig && dataPanelId && isChartExportEnabled(exportConfig, true) ? (
          <DownloadTableButton
            dataPanelId={dataPanelId}
            disabled={exportCurrentlyDisabled}
            exportConfig={exportConfig}
            isDrilldownModal={!!isDrilldownModal}
            onDownloadPanelPdf={onDownloadPanelPdf}
            onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
          />
        ) : null}
        {isDrilldownModal ? (
          <EmbedButton
            icon="cross"
            onClick={() => dispatch(updateDrilldownDataPanel())}
            variant="tertiary"
          />
        ) : (
          <UrlClickThroughButton linkFormat={linkFormat} />
        )}
      </div>
    </div>
  );
};

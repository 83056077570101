import { FC } from 'react';
import { useParams, useHistory } from 'react-router';

import { sprinkles, Tabs } from 'components/ds';
import { VIEW_MODE } from './types';

const rootClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  borderRight: 1,
  borderColor: 'gray7',
  backgroundColor: 'white',
});

const tabs = [
  { name: 'Datasets', id: VIEW_MODE.DATASETS },
  { name: 'Built Ins', id: VIEW_MODE.BUILT_INS },
  { name: 'Settings', id: VIEW_MODE.SETTINGS },
];

const tabNames = tabs.map((tab) => tab.name);

export const EditorLeftColumn: FC = ({ children }) => {
  const history = useHistory();
  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();

  const selectedTabId = tabs.find((tab) => tab.id === view)?.name;

  const handleTabSelect = (tabName: string) => {
    const tab = tabs.find((tab) => tab.name === tabName);
    if (tab) history.push(`/report-builder/${reportBuilderId}/${tab.id}`);
  };

  return (
    <div className={rootClass} style={{ minWidth: 350, width: 350 }}>
      <Tabs onTabSelect={handleTabSelect} selectedTabId={selectedTabId} tabs={tabNames} />
      <div className={sprinkles({ flex: 1, overflowY: 'auto' })}>{children}</div>
    </div>
  );
};

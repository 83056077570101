import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import sqlFormatter from 'sql-formatter';

import { SchemaViewer } from 'components/resource/SchemaViewer';
import { SqlEditor } from 'components/SqlEditor';
import { ConfigSection } from '../ConfigSection';

import { ReduxState } from 'reducers/rootReducer';
import { saveDraftDatasetQuery } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

import * as styles from './index.css';
import * as sharedStyles from '../index.css';
import { getSchemaNameInfo } from 'utils/queryUtils';
import { getSchemaTablesMap } from 'reducers/selectors';

type Props = {
  dataset: ReportBuilderDataset;

  onSelectSchema: (schemaId: number) => void;
  getPreview: () => void;
  saveQuery: () => void;
};

export const QuerySection: FC<Props> = ({ dataset, getPreview, saveQuery, onSelectSchema }) => {
  const dispatch = useDispatch();

  const { schemaTablesMap, shouldUseFido } = useSelector(
    (state: ReduxState) => ({
      schemaTablesMap: getSchemaTablesMap(state),
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const [currentQuery, setCurrentQuery] = useState(dataset.queryDraft ?? dataset.query);
  const [currDatasetId, setCurrDatasetId] = useState(dataset.id);

  useEffect(() => {
    // Update currentQuery when dataset is switched
    if (dataset.id === currDatasetId) return;
    setCurrDatasetId(dataset.id);
    setCurrentQuery(dataset.queryDraft ?? dataset.query);
  }, [dataset, currDatasetId]);

  const parentSchemaId = dataset.parent_schema_id;

  const { tableNames, columnNames } = useMemo(
    () => getSchemaNameInfo(schemaTablesMap, parentSchemaId.toString()),
    [schemaTablesMap, parentSchemaId],
  );

  const formatSqlQuery = () => {
    const newQuery = sqlFormatter.format(currentQuery || '', { indent: '    ' });
    setCurrentQuery(newQuery);
    dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
  };

  const onRevertDraft = () => {
    dispatch(saveDraftDatasetQuery({ queryDraft: undefined, dataset_id: dataset.id }));
    setCurrentQuery(dataset.query);
  };

  const hasChanges = currentQuery !== dataset.query;

  return (
    <ConfigSection defaultIsOpen title="Write your query">
      <div className={sharedStyles.configSection}>
        <div className={styles.editorWrapper}>
          <SqlEditor
            columnNames={columnNames}
            onChange={setCurrentQuery}
            onChangeDraft={(newQuery) => {
              dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
            }}
            query={currentQuery}
            tableNames={tableNames}
          />
        </div>

        <SchemaViewer
          isReportBuilder
          onFormat={formatSqlQuery}
          onPreview={currentQuery.trim() ? getPreview : undefined}
          onRevertDraft={onRevertDraft}
          onSave={hasChanges ? saveQuery : getPreview}
          onSelectSchema={onSelectSchema}
          saveText={hasChanges ? 'Save & Run' : 'Run'}
          selectedDatasetSchemaId={
            shouldUseFido
              ? dataset.namespace_id ?? dataset.parent_schema_id
              : dataset.parent_schema_id
          }
        />
      </div>
    </ConfigSection>
  );
};

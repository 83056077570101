import { Dataset } from 'actions/datasetActions';
import { DashboardVersionConfig } from './dashboardVersionConfig';
import { DataPanelTemplate } from './dataPanelTemplate';

export type ResourceDataset = Dataset;

export type RequestDataset = Pick<ResourceDataset, 'query' | 'parent_schema_id' | 'namespace_id'>;

export type DataPanel = DataPanelTemplate;

export interface VersionInfo {
  is_draft: boolean;
  change_comments: string | null;
  version_number: number;
  edit_version_number: number;
}

export interface LatestVersionInfo {
  is_draft: boolean;
  version_number: number;
  modified: string;
  configuration: DashboardVersionConfig;
}

export enum ResourcePageType {
  REPORT_BUILDER = 'REPORT_BUILDER',
  EXPLORE = 'EXPLORE',
}

import { createReducer } from '@reduxjs/toolkit';

import {
  activateDisabledDashboardSuccess,
  Dashboard,
  createDashboardSuccess,
  deleteDashboardSuccess,
  fetchDashboardSuccess,
  fetchDashboardError,
  fetchDashboardListSuccess,
  renameDashboardSuccess,
  saveShareLinkTitleRequest,
  updateDashboardDefaultTimezoneSuccess,
  updateDisableFiltersWhileLoadingSuccess,
  updateDashboardCacheConfigSuccess,
} from 'actions/dashboardActions';
import { ErrorResponse } from 'actions/responseTypes';
import { assignDashboardValueSuccess } from 'actions/dashboardAttributesActions';
import { cloneResourceThunk } from './thunks/versionManagementThunks';
import { moveEntry, listDashboardFolderContents } from 'reducers/thunks/resourceThunks';

interface DashboardReducerState {
  currentDashboardId?: number;
  dashboardList?: Dashboard[];
  error?: ErrorResponse;
}

const dashboardReducerInitialState: DashboardReducerState = {};

const updateDashboardList = (
  state: DashboardReducerState,
  dashboardId: number | string | undefined | null,
  updateFunc: (dashboard: Dashboard) => void,
): void => {
  if (!state.dashboardList) return;

  const dashboard = state.dashboardList.find((elem) => elem.id === dashboardId);

  if (dashboard) updateFunc(dashboard);
};

export default createReducer(dashboardReducerInitialState, (builder) => {
  builder
    .addCase(createDashboardSuccess, (state, { payload }) => {
      if (!state.dashboardList) return;

      state.currentDashboardId = payload.new_dashboard_template.id;
      state.dashboardList.push(payload.new_dashboard_template);
    })
    .addCase(cloneResourceThunk.fulfilled, (state, { payload, meta }) => {
      const { new_resource } = payload;
      if (!state.dashboardList || !meta.arg.isExplore || !('dashboard_attributes' in new_resource))
        return;

      state.currentDashboardId = new_resource.id;
      state.dashboardList.push(new_resource);
    })
    .addCase(deleteDashboardSuccess, (state, { payload }) => {
      if (!state.dashboardList) return;

      const deletedIndex = state.dashboardList.findIndex((elem) => elem.id === payload.id);
      if (deletedIndex >= 0) state.dashboardList.splice(deletedIndex, 1);
    })
    .addCase(activateDisabledDashboardSuccess, (state, { payload }) => {
      if (!state.dashboardList) return;

      const activateIndex = state.dashboardList.findIndex((elem) => elem.id === payload.id);
      if (activateIndex >= 0) state.dashboardList[activateIndex].disabled = false;

      const deactivateIndex = state.dashboardList.findIndex(
        (elem) => elem.id === payload.postData.disable_dashboard_id,
      );
      if (deactivateIndex >= 0) state.dashboardList[deactivateIndex].disabled = true;
    })
    .addCase(fetchDashboardSuccess, (state, { payload }) => {
      state.currentDashboardId = payload.dashboard_template_id;
      state.error = undefined;
    })
    .addCase(fetchDashboardError, (state, { payload }) => {
      state.error = payload.errorData;
    })
    .addCase(fetchDashboardListSuccess, (state, { payload }) => {
      state.dashboardList = payload.dashboard_template_list;
    })
    .addCase(listDashboardFolderContents.fulfilled, (state, { payload }) => {
      state.dashboardList = payload.resources;
    })
    .addCase(renameDashboardSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.name = payload.name;
      });
    })
    .addCase(saveShareLinkTitleRequest, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.share_link_title = payload.postData.share_link_title;
      });
    })
    .addCase(updateDashboardDefaultTimezoneSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.default_timezone = payload.postData.default_timezone;
      });
    })
    .addCase(updateDisableFiltersWhileLoadingSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.disable_filters_while_loading = payload.postData.disable_filters_while_loading;
      });
    })
    .addCase(updateDashboardCacheConfigSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        if (payload.postData.is_cache_enabled !== undefined)
          dashboard.is_cache_enabled = payload.postData.is_cache_enabled;
        if (payload.postData.cache_cron !== undefined)
          dashboard.cache_cron = payload.postData.cache_cron;
      });
    })
    .addCase(assignDashboardValueSuccess, (state, { payload }) => {
      const { value_id, attribute_id, template_id } = payload.postData;
      updateDashboardList(state, template_id, (template) => {
        const newAttributes = template.dashboard_attributes.filter(
          (elem) => elem.attribute_id !== attribute_id,
        );
        if (value_id !== '') newAttributes.push({ attribute_id, value_id: parseInt(value_id) });
        template.dashboard_attributes = newAttributes;
      });
    })
    .addCase(moveEntry.fulfilled, (state, { payload }) => {
      /**
       * Move will only ever remove things from the current view
       * because we can move into different folders (out of view)
       * or we can use the move modal on a dashboard currently in view
       * to move it into another folder, so out of view.
       */
      if (!state.dashboardList || payload.type !== 'dashboard') return;
      state.dashboardList = state.dashboardList.filter(({ id }) => id !== payload.id);
    })
    .addDefaultCase((state) => state);
});

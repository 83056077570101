import { PureComponent } from 'react';
import Highcharts from 'highcharts';
import ReactDOMServer from 'react-dom/server';

import { HighCharts } from './highCharts';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ChartTooltip } from 'components/embed';

import {
  formatLabel,
  xAxisFormat,
  formatLegend,
  getLabelStyle,
  getAxisNumericalValue,
} from './utils';
import { V2BoxPlotInstructions } from 'constants/types';
import { DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema, DatasetRow } from 'types/datasets';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getSingleYAxisInstructions } from './utils/multiYAxisUtils';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { DatasetDataObject } from 'actions/datasetActions';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  dataPanelTemplateId: string;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  globalStyleConfig: GlobalStyleConfig;
  instructions?: V2BoxPlotInstructions;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  secondaryData: DatasetRow[];
  schema: DatasetSchema;
  variables: DashboardVariableMap;
};

type State = {};

export class BoxPlot extends PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  render() {
    const { instructions, loading } = this.props;
    const instructionsReadyToDisplay = !!(
      instructions &&
      instructions.calcColumns?.length &&
      instructions.groupingColumn
    );

    if (loading || !instructionsReadyToDisplay) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
        />
      );
    }

    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      variables,
      datasetData,
      datasetNamesToId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const groupingColumnName = this.getGroupingColName();
    const { valueFormatId, decimalPlaces } = this.getValueFormat();

    return {
      chart: {
        type: this.getChartType(),
        inverted: !instructions?.boxPlotFormat?.isVertical,
        backgroundColor,
      },
      series: this.transformData(),
      title: sharedTitleConfig,
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        categories: this.getAxisCategoriesOrDefault(),
        type: this.getXAxisTypeOrDefault(),
        labels: {
          formatter: function () {
            return formatLabel(
              DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')
                ? previewData[this.value as number][groupingColumnName]
                : this.value,
              instructions?.groupingColumn?.column.type,
              instructions?.groupingColumn?.bucket?.id,
              instructions?.groupingColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      yAxis: getSingleYAxisInstructions(
        globalStyleConfig,
        instructions,
        variables,
        datasetNamesToId,
        datasetData,
      ),
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      plotOptions: {
        boxplot: {
          lineWidth: 1,
          medianWidth: 1,
          stemWidth: instructions?.boxPlotFormat?.hideWhisker ? 0 : 2,
          whiskerLength: '25%',
          whiskerWidth: instructions?.boxPlotFormat?.hideWhisker ? 0 : 2,
        },
        series: {
          animation: false,
        },
      },
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          const format = { decimalPlaces, formatId: valueFormatId };
          return ReactDOMServer.renderToStaticMarkup(
            <ChartTooltip
              globalStyleConfig={globalStyleConfig}
              header={formatLabel(
                DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')
                  ? previewData[parseInt(this.point.category)][groupingColumnName]
                  : this.point.category,
                instructions?.groupingColumn?.column.type,
                instructions?.groupingColumn?.bucket?.id,
                instructions?.groupingColumn?.bucketSize,
                instructions?.xAxisFormat?.dateFormat,
                instructions?.xAxisFormat?.stringFormat,
              )}
              points={[
                {
                  color: String(this.point.color),
                  name: 'Maximum',
                  value: this.point.options.high,
                  format,
                },
                {
                  color: String(this.point.color),
                  name: 'Upper Quartile',
                  value: this.point.options.q3,
                  format,
                },
                {
                  color: String(this.point.color),
                  name: 'Median',
                  value: this.point.options.median,
                  format,
                },
                {
                  color: String(this.point.color),
                  name: 'Lower Quartile',
                  value: this.point.options.q1,
                  format,
                },
                {
                  color: String(this.point.color),
                  name: 'Minimum',
                  value: this.point.options.low,
                  format,
                },
              ]}
            />,
          );
        },
      },
    };
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      valueFormatId: instructions?.yAxisFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
      decimalPlaces: instructions?.yAxisFormat?.decimalPlaces ?? 2,
    };
  };

  getChartType = () => {
    return 'boxplot';
  };

  getGroupingColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getXAxisTypeOrDefault = () => {
    const { instructions } = this.props;

    if (DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')) return 'datetime';
  };

  getAxisCategoriesOrDefault = () => {
    const { instructions, previewData } = this.props;
    if (DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')) return;

    const xAxisColName = this.getGroupingColName();
    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.groupingColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getGroupingColName();

    if (
      instructions?.groupingColumn?.bucket?.id &&
      instructions.groupingColumn.bucket.id.indexOf('DATE_PART') >= 0
    )
      return;

    previewData.forEach((row) => {
      // If it's a number, it has already been converted to milliseconds
      if (instructions?.groupingColumn?.column.type && typeof row[xAxisColName] !== 'number')
        row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
    });
  };

  transformData = () => {
    const { instructions, schema, previewData, secondaryData, globalStyleConfig } = this.props;
    const { calcColumns, groupingColumn, boxPlotFormat } = instructions ?? {};

    if (!calcColumns?.length || !groupingColumn || !schema || (schema.length - 1) % 5 !== 0)
      return [];

    /**
     * This is only relevant for Redshift and MySQL backed charts - since we can only grab
     * metrics for one calc column at a time,  we pass additional metrics through secondaryData.
     */
    const allData = [...previewData];
    for (let idx = 0; idx < secondaryData.length; idx++) {
      const numberOfGroupings = previewData.length;
      allData[idx % numberOfGroupings] = {
        ...allData[idx % numberOfGroupings],
        ...secondaryData[idx],
      };
    }

    const data: Highcharts.SeriesBoxplotOptions[] = [];
    for (let calcIdx = 0; calcIdx < calcColumns.length; calcIdx++) {
      const columnName = calcColumns[calcIdx]?.name || '';
      const fillColor =
        boxPlotFormat?.fillColorByColumn?.[columnName] ||
        globalStyleConfig.visualizations.gradientPalette.hue2;
      const medianColor =
        boxPlotFormat?.medianColorByColumn?.[columnName] ||
        globalStyleConfig.visualizations.gradientPalette.hue1;

      data.push({
        type: 'boxplot',
        data: allData.map((row) => [
          getAxisNumericalValue(row[`${columnName}_min`]),
          getAxisNumericalValue(row[`${columnName}_25_percentile`]),
          getAxisNumericalValue(row[`${columnName}_median`]),
          getAxisNumericalValue(row[`${columnName}_75_percentile`]),
          getAxisNumericalValue(row[`${columnName}_max`]),
        ]),
        name: boxPlotFormat?.seriesLabelByColumn?.[columnName] || `Series ${calcIdx + 1}`,
        color: fillColor,
        fillColor,
        // @ts-ignore
        lineColor: fillColor,
        whiskerColor: fillColor,
        stemColor: fillColor,
        medianColor,
      });
    }

    return data;
  };
}

import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { DroppedFilterColumn } from './DroppedFilterColumn';
import { EmptyDroppableSection } from '../vizConfigs/droppable/EmptyDroppableSection';
import { FilterLink } from './FilterLink';

import { ItemTypes, ConfigColumnItem } from 'constants/dragAndDrop';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { Dataset } from 'actions/datasetActions';
import { DatasetSchema } from 'types/datasets';
import { FilterClause } from 'constants/types';
import { DashboardElement } from 'types/dashboardTypes';
import { createFilterClause } from 'actions/dataPanelConfigActions';
import { getPossibleLinksForDataPanel } from 'utils/filterLinking';
import { ReduxState } from 'reducers/rootReducer';
import { filterDpsWithDrilldowns } from 'utils/drilldownUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.ds.grey600,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  colIsDragging: {
    border: `1px dashed ${theme.palette.ds.blue} !important`,
  },
}));

type Props = {
  clauses: FilterClause[];
  dataPanel: DataPanelTemplate;
  datasets: Record<string, Dataset>;
  dashboardElements: DashboardElement[];
  dashboardParams: Record<string, DashboardParam>;
  schema: DatasetSchema;
};

export default function DroppableFilterColumnSection({
  clauses,
  dataPanel,
  datasets,
  dashboardElements,
  dashboardParams,
  schema,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataPanelsById = useSelector(
    (state: ReduxState) => state.dashboardEditConfig.config?.data_panels,
  );

  const dpsWithDrilldown = useMemo(
    () => filterDpsWithDrilldowns(Object.values(dataPanelsById ?? {})),
    [dataPanelsById],
  );

  const [{ isDragging }, drop] = useDrop({
    accept: ItemTypes.CONFIGURATION_COLUMN,
    collect: (monitor) => ({
      isDragging: !!monitor.getItem(),
    }),
    drop: (item: ConfigColumnItem) => {
      dispatch(createFilterClause(item.data));
    },
  });

  const validFilters = clauses.filter((clause) => clause.filterColumn);

  return (
    <div className={classes.root} ref={drop}>
      {validFilters.map((clause, i) => (
        <DroppedFilterColumn
          clause={clause}
          clauseIdx={i}
          dashboardElements={dashboardElements}
          dashboardParams={dashboardParams}
          dpsWithDrilldown={dpsWithDrilldown}
          key={`filter-clause-${i}-${clause.filterColumn?.name}-${dataPanel.id}`}
          schema={schema}
        />
      ))}
      {getPossibleLinksForDataPanel(dataPanel, datasets, dashboardElements).map((link) => (
        <FilterLink
          dataPanelId={dataPanel.id}
          key={`${dataPanel.id}-${link.elementName}`}
          link={link}
        />
      ))}
      <EmptyDroppableSection
        draggingClass={isDragging ? classes.colIsDragging : undefined}
        onItemSelect={(column) => dispatch(createFilterClause(column))}
        schema={schema}
      />
    </div>
  );
}

import { useEffect, useState, useRef, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { DataConfigTab } from './DataConfigTab';
import { FormatConfigTab } from './FormatConfigTab';
import { ActionsConfigTab } from './ActionsConfigTab';
import { ChangeDataPanelIdModal } from './ChangeDataPanelIdModal';
import { sprinkles, IconButton, Tabs } from 'components/ds';
import * as styles from './styles.css';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { duplicateDashboardItem } from 'actions/dashboardV2Actions';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import {
  shouldRecomputeDataForDataPanel,
  shouldRecomputeSecondaryDataForDataPanel,
  dataPanelToConfig,
} from 'utils/dataPanelConfigUtils';

import { showSuccessToast } from 'shared/sharedToasts';
import { setDeleteSelectedItemModalOpen } from 'reducers/dashboardInteractionsReducer';
import { onDataPanelUpdateThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { setEditableSectionModal } from 'reducers/dashboardEditConfigReducer';
import { getEditableDatasets } from 'reducers/selectors';
import { VIZ_TO_HELP_DOCS } from 'constants/dataConstants';

enum CONFIG_TABS {
  DATA = 'Data',
  FORMAT = 'Format',
  ACTIONS = 'Actions',
}

const TabNames = Object.values(CONFIG_TABS);

type Props = {
  dashboardId: number;
  dataPanel: DataPanelTemplate;
  isEditableSection?: boolean;
};

export const DataPanelConfig: FC<Props> = ({ dataPanel, dashboardId, isEditableSection }) => {
  const [selectedConfigTabId, setSelectedConfigTabId] = useState(CONFIG_TABS.DATA);
  const [disableDuplicating, setDisableDuplicating] = useState(false);
  const [isChangeDpIdModalOpen, setIsChangeDpIdModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { isDataPanelUpdating, datasets } = useSelector(
    (state: ReduxState) => ({
      isDataPanelUpdating: createLoadingSelector([ACTION.FETCH_DATA_PANEL_TEMPLATE], false)(state),
      datasets: getEditableDatasets(state),
    }),
    shallowEqual,
  );

  const dataset = datasets[dataPanel.table_id];

  const prevConfigRef = useRef(dataPanel);

  useEffect(() => {
    // Only recompute if the config changes because the data panel changed
    if (prevConfigRef.current.id === dataPanel.id) {
      const prevConfig = dataPanelToConfig(prevConfigRef.current);
      const newConfig = dataPanelToConfig(dataPanel);

      const shouldRecompute = shouldRecomputeDataForDataPanel(prevConfig, newConfig);

      // If shouldRecompute is true then this value won't be used
      const shouldRecomputeSecondary = shouldRecompute
        ? false
        : shouldRecomputeSecondaryDataForDataPanel(prevConfig, newConfig);

      dispatch(onDataPanelUpdateThunk(dataPanel.id, shouldRecompute, shouldRecomputeSecondary));
    }

    prevConfigRef.current = dataPanel;
  }, [dataPanel, dispatch]);

  const renderDashboardTitle = () => {
    const helpDocLink = VIZ_TO_HELP_DOCS[dataPanel.visualize_op.operation_type];
    return (
      <div className={styles.configurationHeader}>
        <div className={sprinkles({ flex: 1, overflow: 'hidden' })}>
          <div className={styles.elementIdTag}>{dataPanel.provided_id}</div>
          <div className={styles.dataTableName}>{dataset.table_name}</div>
        </div>
        <div className={sprinkles({ flexItems: 'alignCenter', marginLeft: 'sp1' })}>
          {helpDocLink ? (
            <IconButton
              name="file-reg"
              to={`https://docs.explo.co/creating-dashboards/visualizations/charts/${helpDocLink}`}
              tooltipProps={{ text: 'Help docs' }}
            />
          ) : null}
          <IconButton
            name="pencil"
            onClick={() =>
              isEditableSection
                ? dispatch(
                    setEditableSectionModal({ type: 'EditChartInfo', chartId: dataPanel.id }),
                  )
                : setIsChangeDpIdModalOpen(true)
            }
            tooltipProps={{ text: 'Edit chart ID' }}
          />
          {isEditableSection ? (
            <IconButton
              name="user"
              onClick={() =>
                dispatch(
                  setEditableSectionModal({ type: 'EditChartPermissions', chartId: dataPanel.id }),
                )
              }
              tooltipProps={{ text: 'Edit chart permissions' }}
            />
          ) : (
            <IconButton
              disabled={disableDuplicating}
              name={disableDuplicating ? 'tick' : 'copy'}
              onClick={() => {
                setDisableDuplicating(true);
                dispatch(
                  duplicateDashboardItem({
                    dashboardItem: dataPanel,
                    itemType: DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
                    dashId: dashboardId,
                  }),
                );

                showSuccessToast(`${dataPanel.provided_id} has been duplicated below`, 10, () =>
                  setDisableDuplicating(false),
                );
              }}
              tooltipProps={{ text: 'Duplicate chart' }}
            />
          )}
          <IconButton
            name="trash"
            onClick={() =>
              dispatch(
                isEditableSection
                  ? setEditableSectionModal({ type: 'DeleteChart', chartId: dataPanel.id })
                  : setDeleteSelectedItemModalOpen(true),
              )
            }
            tooltipProps={{ text: 'Delete chart' }}
          />
        </div>
      </div>
    );
  };

  const renderTabConfigOptions = () => {
    switch (selectedConfigTabId) {
      case CONFIG_TABS.DATA:
        return (
          <DataConfigTab
            dataPanel={dataPanel}
            datasets={datasets}
            loading={isDataPanelUpdating}
            schema={dataset.schema ?? []}
          />
        );
      case CONFIG_TABS.FORMAT:
        return (
          <FormatConfigTab
            dataPanel={dataPanel}
            datasets={datasets}
            isEditableSection={isEditableSection}
          />
        );
      case CONFIG_TABS.ACTIONS:
        return <ActionsConfigTab dataPanel={dataPanel} />;
    }
  };

  return (
    <>
      {renderDashboardTitle()}
      <div className={styles.bodyContainer}>
        <Tabs
          onTabSelect={(tab) => setSelectedConfigTabId(tab as CONFIG_TABS)}
          selectedTabId={selectedConfigTabId}
          tabs={TabNames}
        />
        <div className={styles.configMenu}>{renderTabConfigOptions()}</div>
      </div>
      {isChangeDpIdModalOpen ? (
        <ChangeDataPanelIdModal
          dataPanel={dataPanel}
          onClose={() => setIsChangeDpIdModalOpen(false)}
        />
      ) : null}
    </>
  );
};

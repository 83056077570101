import { FC } from 'react';
import cx from 'classnames';

import { DataGridPaginator, InternalPaginatorProps } from 'components/ds/DataGrid/paginator';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

import * as styles from './index.css';

export const EmbedPaginator: FC<InternalPaginatorProps> = (props) => {
  return (
    <DataGridPaginator
      isEmbed
      {...props}
      className={cx(
        styles.footer,
        embedSprinkles({ body: 'secondary' }),
        GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
      )}
      inputClassName={cx(
        GLOBAL_STYLE_CLASSNAMES.text.body.input,
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}
    />
  );
};

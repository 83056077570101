import { FC, useEffect } from 'react';
import validator from 'validator';
import cx from 'classnames';
import { useImmer } from 'use-immer';

import InputWithTag from 'shared/InputWithTag';
import { Intent, sprinkles, Modal, Select, APP_PORTAL_ID, Button } from 'components/ds';

import { DEFAULT_ROLE_OPTIONS } from 'constants/roleConstants';
import { InvitedUser } from 'actions/teamActions';
import { getTeamMemberRole } from 'utils/permissionUtils';

type Props = {
  closeModal: () => void;
  inviteTeammateLoading: boolean;
  modalOpen: boolean;
  onSubmit: (invites: InvitedUser[]) => void;
};

const createBlankInvite = () => {
  return { email: '', role_names: [] };
};

export const InviteTeammatesModal: FC<Props> = ({
  inviteTeammateLoading,
  modalOpen,
  closeModal,
  onSubmit,
}) => {
  const [invites, updateInvites] = useImmer<InvitedUser[]>([createBlankInvite()]);

  // initialize as true because the initial state is empty
  let areAllInvitesValidOrEmpty = true;
  let isSomeInviteValid = false;

  invites.forEach((invite) => {
    const isRowValid = validator.isEmail(invite.email) && invite.role_names.length !== 0;
    const isRowEmpty = invite.email.trim() === '';

    areAllInvitesValidOrEmpty = areAllInvitesValidOrEmpty && (isRowValid || isRowEmpty);

    isSomeInviteValid = isSomeInviteValid || isRowValid;
  });

  const isReadyToSubmit = areAllInvitesValidOrEmpty && isSomeInviteValid;

  useEffect(() => {
    if (!modalOpen) updateInvites([createBlankInvite()]);
  }, [modalOpen, updateInvites]);

  const renderInviteRow = (invite: InvitedUser, index: number) => (
    <div
      className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}
      key={`invited-user-row-${index}`}>
      <InputWithTag
        hideLabel
        className={sprinkles({ flex: 5 })}
        onChange={(value) =>
          updateInvites((draft) => {
            draft.splice(index, 1, { ...invite, email: value });
          })
        }
        placeholder="name@domain.com"
        statusInfo={
          invite.email !== ''
            ? !validator.isEmail(invite.email)
              ? { statusIntent: Intent.ERROR, statusText: 'Invalid Email Address' }
              : { statusIntent: Intent.SUCCESS, statusIcon: 'check' }
            : undefined
        }
        value={invite.email}
      />
      <Select
        inModal
        className={sprinkles({ flex: 2, zIndex: 'tooltips' })}
        onChange={(role) =>
          updateInvites((draft) => {
            draft.splice(index, 1, { ...invite, role_names: [role] });
          })
        }
        placeholder="Select a Role"
        portalContainerId={APP_PORTAL_ID}
        selectedValue={invite.role_names.length === 0 ? undefined : getTeamMemberRole(invite)}
        values={DEFAULT_ROLE_OPTIONS}
      />
    </div>
  );

  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      primaryButtonProps={{
        text: 'Send Invites',
        loading: inviteTeammateLoading,
        disabled: !isReadyToSubmit,
        onClick: () => isReadyToSubmit && onSubmit(invites),
      }}
      size="small"
      title="Invite Team Members">
      <div className={cx(sprinkles({ flexItems: 'column', gap: 'sp1.5', paddingX: 'sp3' }))}>
        {invites.map(renderInviteRow)}
        <Button
          icon="plus"
          onClick={() => updateInvites((draft) => draft.concat([createBlankInvite()]))}
          variant="secondary">
          Add More
        </Button>
      </div>
    </Modal>
  );
};

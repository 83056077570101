import { Layout } from '@explo-tech/react-grid-layout';

import { DashboardElement } from 'types/dashboardTypes';
import { Dataset } from 'actions/datasetActions';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { CustomerPermissionsForObject } from './permissionTypes';

export enum DashboardHeaderLocation {
  RIGHT_ALIGNED = 'rightAligned',
  BELOW_HEADER = 'belowHeader',
}

export interface DashboardStickyHeaderConfig {
  enabled?: boolean;
  headerDisabled?: boolean;
  headerName?: string;
  backgroundColor?: string;
  disableBottomBorder?: boolean;
  disableBottomShadow?: boolean;
  enabledExpandableFilterRow?: boolean;
  enableStretchFilters?: boolean;
  headerTextColor?: string;
  headerTextSize?: number;
  headerContentOrder?: string[];
  filterLocations?: DashboardHeaderLocation;
}

export interface DashboardPageLayoutConfig {
  stickyHeader?: DashboardStickyHeaderConfig;
}

export interface EditableSectionChart {
  name: string;
  permissions: CustomerPermissionsForObject;
  data_panel: DataPanelTemplate;
}

export interface EditableSectionSettings {
  title: string;
  tooltipText?: string;
}

export interface EditableSectionConfig {
  enabled: boolean;
  default_layout: Layout[];
  // Chart Key is the same as Data Panel Id
  charts: Record<string, EditableSectionChart>;
  settings: EditableSectionSettings;
}

export interface DashboardVersionConfig {
  dashboard_layout: Layout[];
  pdf_layout?: Layout[];
  email_layout?: Layout[];
  mobile_layout?: Layout[];
  data_panels: Record<string, DataPanelTemplate>;
  elements: Record<string, DashboardElement>;
  params: Record<string, DashboardParam>;
  datasets: Record<string, Dataset>;
  dashboard_page_layout_config?: DashboardPageLayoutConfig;
  editable_section?: EditableSectionConfig;
  email_header_html?: string;
  email_footer_html?: string;
  category_colors?: Record<string, string>; // Value to color
}

export interface DashboardParam {
  id: string;
  name: string;
  type: string;
  defaultValue?: string;
}

import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';

import { CustomerPermissionsForObject } from 'types/permissionTypes';

type Props = { permissions: CustomerPermissionsForObject | undefined };

export const CustomerPermissionsSummary: FC<Props> = ({ permissions }) => {
  if (!permissions || permissions.allCustomers) {
    return (
      <div className={itemPermissionClass}>
        <Icon name="user" />
        All customers
      </div>
    );
  }

  const numCustomers = permissions.customerIds.length;
  const numGroupTags = permissions.groupTagIds?.length ?? 0;

  if (numCustomers + numGroupTags === 0) {
    return <div className={itemPermissionClass}>No customers assigned</div>;
  }

  const renderSection = (num: number, isCustomer: boolean) => {
    if (num === 0) return null;
    return (
      <div className={itemPermissionClass}>
        <Icon name={isCustomer ? 'user' : 'user-group'} />
        <div className={sprinkles({ truncateText: 'ellipsis' })}>
          {`${num} ${isCustomer ? 'customer' : 'group'}${num === 1 ? '' : 's'}`}
        </div>
      </div>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1.5' })}>
      {renderSection(numCustomers, true)}
      {renderSection(numGroupTags, false)}
    </div>
  );
};

const itemPermissionClass = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
  color: 'contentSecondary',
  body: 'b2',
  truncateText: 'ellipsis',
});

import { FC, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { APP_PORTAL_ID, Button, Input, Modal, Select, Intent, sprinkles } from 'components/ds';
import { DeleteConfirmationButton } from 'components/DeleteConfirmationButton';

import {
  BOOLEAN,
  FLOAT,
  INTEGER_DATA_TYPE,
  SCHEMA_DATA_TYPES_BY_ID,
  STRING,
  TIMESTAMP,
} from 'constants/dataConstants';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { updateDashboardParamsThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { isDefaultValueValid, isNameValid } from 'utils/paramUtils';
import { InfoCard } from 'components/InfoCard';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';

type Props = {
  dashboardId: number;
  dashboardParams: Record<string, DashboardParam>;

  onClose: () => void;
};

export const CustomVariablesModal: FC<Props> = ({ dashboardId, dashboardParams, onClose }) => {
  const dispatch = useDispatch();

  const archetypeProperties = useSelector((state: ReduxState) => getArchetypeProperties(state));

  const [currParams, setCurrParams] = useImmer({ ...dashboardParams });
  const [searchText, setSearchText] = useState('');

  const filteredParams = useMemo(() => {
    if (searchText.trim() === '') return Object.values(currParams);
    const lowerSearch = searchText.toLocaleLowerCase();
    return Object.values(currParams).filter((param) =>
      param.name.toLowerCase().includes(lowerSearch),
    );
  }, [currParams, searchText]);

  const areParamsValid = useMemo(
    () =>
      !Object.values(currParams).some(
        (param) =>
          !isNameValid(param.name) ||
          !isDefaultValueValid(param.type, param.defaultValue) ||
          archetypeProperties.has(param.name),
      ),
    [currParams, archetypeProperties],
  );

  const onVarChange = (fieldName: 'name' | 'type' | 'defaultValue', id: string, newVal: string) => {
    setCurrParams((draft) => {
      draft[id][fieldName] = newVal;
      if (fieldName === 'type') draft[id].defaultValue = undefined;
    });
  };

  const onSave = () => {
    if (!areParamsValid) return;
    dispatch(updateDashboardParamsThunk(dashboardParams, currParams));
  };

  const cleanVariableName = (name: string) => {
    name = name.trim();
    name = name.replace(/\s/g, '_');

    if (name.length > 0 && !isNaN(+name[0])) name = '_' + name;

    return name;
  };

  const renderDefaultValueSelector = (id: string, type: string, currValue: string | undefined) => {
    if (type === TIMESTAMP) {
      return <Input disabled className={defaultValueClass} onChange={() => null} value="" />;
    }
    if (type === BOOLEAN)
      return (
        <Select
          className={defaultValueClass}
          onChange={(value) => onVarChange('defaultValue', id, value)}
          placeholder="Default value"
          selectedValue={currValue}
          values={[{ value: 'true' }, { value: 'false' }]}
        />
      );
    return (
      <Input
        className={defaultValueClass}
        defaultValue={currValue ?? ''}
        intent={isDefaultValueValid(type, currValue) ? undefined : Intent.ERROR}
        onSubmit={(value) => onVarChange('defaultValue', id, value)}
        placeholder="Default value"
        type={type === STRING ? 'text' : 'number'}
      />
    );
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{ text: 'Save', disabled: !areParamsValid, onClick: onSave }}
      size="medium"
      tertiaryButtonProps={{ text: 'Cancel', onClick: onClose }}
      title="Custom Variables">
      <div className={sprinkles({ flexItems: 'column', paddingX: 'sp3' })}>
        <Input
          fillWidth
          leftIcon="search"
          onChange={setSearchText}
          placeholder="Search"
          value={searchText}
        />
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp8', marginTop: 'sp2' })}>
          <Button
            icon="plus"
            onClick={() => {
              setSearchText('');
              setCurrParams((draft) => {
                const newId = `dash${dashboardId}-${uuidv4()}`;
                draft[newId] = { id: newId, name: '', type: STRING };
              });
            }}>
            New Variable
          </Button>
          <InfoCard
            noTopMargin
            className={sprinkles({ flex: 1 })}
            text="Default values here will be the default for both when editing and embedding this dashboard"
          />
        </div>
        <div className={sprinkles({ overflowY: 'auto', marginTop: 'sp2' })} style={{ height: 240 }}>
          {filteredParams.map(({ type, id, name, defaultValue }) => {
            return (
              <div
                className={sprinkles({ flexItems: 'alignCenter', marginBottom: 'sp2', gap: 'sp1' })}
                key={id}>
                <div style={{ width: 104 }}>
                  <Select
                    onChange={(newType) => onVarChange('type', id, newType)}
                    selectedValue={type}
                    values={dataTypeOptions}
                  />
                </div>
                <Input
                  className={sprinkles({ flex: 1 })}
                  defaultValue={name}
                  intent={
                    isNameValid(name) && !archetypeProperties?.has(name) ? undefined : Intent.ERROR
                  }
                  onSubmit={(value) => onVarChange('name', id, cleanVariableName(value))}
                  placeholder="variable_name"
                />
                {renderDefaultValueSelector(id, type, defaultValue)}
                <DeleteConfirmationButton
                  onDelete={() =>
                    setCurrParams((draft) => {
                      if (id in draft) delete draft[id];
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

const defaultValueClass = sprinkles({ flex: 2 });

const dataTypeOptions = [STRING, INTEGER_DATA_TYPE, FLOAT, BOOLEAN, TIMESTAMP].map((dataType) => ({
  value: dataType,
  label: SCHEMA_DATA_TYPES_BY_ID[dataType].name,
}));

import { keyBy } from 'utils/standard';
import * as RD from 'remotedata';
import {
  TableDataset,
  fetchDatasetPreviewSuccess,
  fetchDatasetPreviewRequest,
  fetchDatasetPreviewError,
  fetchDatasetRowCountSuccess,
  fetchDatasetRowCountError,
} from 'actions/dataSourceActions';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchDatasetsError,
  fetchDatasetsSuccess,
  fetchDatasetsRequest,
} from 'actions/parentSchemaActions';
import { DatasetData, DatasetDataObject } from 'actions/datasetActions';

interface DatasetsReducer {
  datasets: RD.ResponseData<Record<number, TableDataset>>;
  datasetData: DatasetDataObject;
}

const datasetsReducerInitialState: DatasetsReducer = {
  datasets: RD.Idle(),
  datasetData: {},
};

const updateDatasetData = (
  state: DatasetsReducer,
  datasetId: number,
  func: (data: DatasetData) => void,
) => {
  const datasetData = state.datasetData[datasetId];
  if (datasetData) func(datasetData);
};

export default createReducer(datasetsReducerInitialState, (builder) => {
  builder
    .addCase(fetchDatasetsRequest, (state) => {
      state.datasets = RD.Loading();
    })
    .addCase(fetchDatasetsSuccess, (state, { payload }) => {
      state.datasets = RD.Success(keyBy(payload.datasets, 'id'));
    })
    .addCase(fetchDatasetsError, (state, { payload }) => {
      state.datasets = RD.Error(payload.errorData?.detail ?? 'Error loading datasets');
    })
    .addCase(fetchDatasetPreviewRequest, (state, { payload }) => {
      const datasetData = state.datasetData[payload.postData.dataset_id];
      if (!datasetData) {
        state.datasetData[payload.postData.dataset_id] = { loading: true };
      } else {
        datasetData.loading = true;
        datasetData.queryInformation = undefined;
      }
    })
    .addCase(fetchDatasetPreviewSuccess, (state, { payload }) => {
      updateDatasetData(state, payload.postData.dataset_id, (datasetData) => {
        datasetData.loading = false;
        datasetData.error = undefined;
        datasetData.schema = payload.dataset_preview.schema;
        datasetData.rows = payload.dataset_preview._rows;
        datasetData.unsupportedOperations = payload.dataset_preview._unsupported_operations;
      });
    })
    .addCase(fetchDatasetPreviewError, (state, { payload }) => {
      updateDatasetData(state, payload.postData.dataset_id, (datasetData) => {
        datasetData.error = payload.error_msg;
        datasetData.loading = false;
      });
    })
    .addCase(fetchDatasetRowCountSuccess, (state, { payload }) => {
      updateDatasetData(state, payload.postData.dataset_id, (datasetData) => {
        datasetData.totalRowCount = payload._total_row_count;
      });
    })
    .addCase(fetchDatasetRowCountError, (state, { payload }) => {
      updateDatasetData(state, payload.postData.dataset_id, (datasetData) => {
        datasetData.totalRowCount = undefined;
      });
    })
    .addDefaultCase((state) => state);
});

import { compact, uniq } from 'utils/standard';
import { useSelector } from 'react-redux';

import { ColorPicker, Props as ColorPickerProps } from 'components/ColorPicker';
import { ReduxState } from 'reducers/rootReducer';

type Props = Omit<ColorPickerProps, 'colors'>;

export default function CustomStylesColorPicker(props: Props) {
  const globalStyleConfig = useSelector(
    (state: ReduxState) =>
      state.styleConfig.editingConfig ?? state.dashboardStyles.globalStyleConfig,
  );

  const colors = compact(
    uniq([
      globalStyleConfig.base.backgroundColor,
      globalStyleConfig.base.actionColor.default,
      globalStyleConfig.container.fill,
      globalStyleConfig.text.primaryColor,
      globalStyleConfig.text.secondaryColor,
      globalStyleConfig.base.actionColor.buttonColor,
      globalStyleConfig.base.actionColor.interactionStateColor,
      globalStyleConfig.container.outline.color,
      globalStyleConfig.container.shadow.color,
      globalStyleConfig.visualizations.categoricalPalette.hue1,
      globalStyleConfig.visualizations.categoricalPalette.hue2,
      globalStyleConfig.visualizations.categoricalPalette.hue3,
      globalStyleConfig.visualizations.categoricalPalette.hue4,
      globalStyleConfig.visualizations.categoricalPalette.hue5,
      globalStyleConfig.visualizations.categoricalPalette.hue6,
    ]),
  );

  return <ColorPicker colors={colors} {...props} />;
}

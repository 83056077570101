import { FC, useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { reportError } from 'analytics/datadog';
import cx from 'classnames';

import { ErrorFallback } from 'components/ds';
import { DataPanel } from 'pages/ReportBuilder/ReportView/DataPanel';
import { ReportBuilderFilters } from './Filters';
import { NoDataSelected } from './NoDataSelected';
import { ReportChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChart';
import * as styles from './ViewContent.css';

import {
  CustomerReportDataInfo,
  CustomerReportView,
  CustomerReportFilter,
} from 'actions/customerReportActions';
import {
  getCurrentViewData,
  updateDrilldownFilters,
  getCurrentView,
  updateCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { getFilterableColumns, getSortableColumns } from 'utils/customerReportUtils';
import { DrilldownPanel } from 'pages/ReportBuilder/ReportView/DrilldownPanel';
import { DatasetColumn } from 'types/datasets';
import { filterViewParams } from 'reportBuilderContent/thunks/utils';
import { createFilterThunk } from 'reportBuilderContent/thunks/reportThunks';
import { constructFilterFromDrilldownColumn } from 'utils/dataPanelConfigUtils';
import { FilterOperator } from 'types/filterOperations';
import { PivotAgg } from 'types/dateRangeTypes';
import ResizeObserver from 'react-resize-observer';

type Props = {
  dataInfo: CustomerReportDataInfo;
  view: CustomerReportView;
};

export const ViewContent: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();

  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { versionConfig, viewData, showTotals, canShowTotals, totalsOnByDefault } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      viewData: getCurrentViewData(state.reportEditing),
      showTotals: getCurrentView(state.reportEditing)?.showTotals,
      canShowTotals: state.embeddedReportBuilder.reportBuilderVersion?.config.general?.showTotals,
      totalsOnByDefault:
        state.embeddedReportBuilder.reportBuilderVersion?.config.general?.totalsOnByDefault,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (canShowTotals && totalsOnByDefault && showTotals == null)
      dispatch(updateCurrentView({ showTotals: true }));
  }, [view.id, dispatch, canShowTotals, totalsOnByDefault, showTotals]);

  const dataset = versionConfig?.datasets[dataInfo.datasetId];
  const schemaInfo = useMemo(() => filterViewParams(view), [view]);
  const hasPivots =
    schemaInfo.columnGroupBys.length || schemaInfo.groupBys.length || schemaInfo.aggs.length;
  const columnConfigs = !dataset || hasPivots ? {} : dataset.columnConfigs;

  const filterableColumns = useMemo(
    () => (dataset ? getFilterableColumns(view.aggregations, view.columnOrder, dataset) : []),
    [dataset, view.aggregations, view.columnOrder],
  );

  const sortableColumns = useMemo(
    () => (dataset ? getSortableColumns(dataset, view) : []),
    [dataset, view],
  );

  const handleSelect = useCallback(
    (selection: { column: string; bucket?: PivotAgg; value: string | number | null }[]) => {
      const filters = selection.flatMap(({ column, bucket, value }) => {
        const filterableColumn = filterableColumns.find((col) => col.name === column);
        if (!(filterableColumn && !filterableColumn.isPostFilter)) return [];

        const filterClauses =
          constructFilterFromDrilldownColumn(
            { column: filterableColumn, bucket: bucket ? { id: bucket } : undefined },
            value == null ? 'null' : value,
          ) || [];

        // Convert from Explore FilterClause to RB CustomerReportFilter
        return filterClauses?.map(
          (filter): CustomerReportFilter => ({
            ...filter,
            id: Math.round(Math.random() * 1e9), // Random ID to prevent collisions
            isPostFilter: filterableColumn.isPostFilter,
            filterColumn: filterableColumn,
            filterOperation: filter.filterOperation || { id: FilterOperator.NUMBER_EQ },
          }),
        );
      });

      if (filters.length) dispatch(updateDrilldownFilters(filters));
    },
    [dispatch, filterableColumns],
  );

  const handleFilter = useCallback(
    (column: DatasetColumn) => {
      const filterableColumn = filterableColumns.find((col) => col.name === column.name);
      if (filterableColumn && !filterableColumn.isPostFilter) {
        const payload = { column, isPostFilter: filterableColumn.isPostFilter };
        dispatch(createFilterThunk(payload));
      }
    },
    [dispatch, filterableColumns],
  );

  if (!dataset) return <NoDataSelected datasetDeleted />;

  return (
    <div
      className={cx(styles.viewContainer, { 'explo-dashboard-loaded': viewData.rows })}
      ref={containerRef}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
        <div className={styles.reportContainer}>
          <ReportBuilderFilters
            columnConfigs={columnConfigs}
            columns={filterableColumns}
            filters={view.filters}
            sort={view.sort}
            sortableColumns={sortableColumns}
          />
          {/* overflow: hidden is necessary for Highcharts to resize when the layout changes */}
          <div className={styles.chartContainer} id="shared-explo-dashboard">
            <ReportChart
              containerRef={containerRef}
              dataset={dataset}
              onFilter={handleFilter}
              onSelect={handleSelect}
              reportData={viewData}
              view={view}
            />
          </div>
          <DrilldownPanel
            columnConfigs={dataset.columnConfigs}
            dataInfo={dataInfo}
            height={height}
            view={view}
          />
        </div>
      </ErrorBoundary>
      <DataPanel dataInfo={dataInfo} view={view} />
      <ResizeObserver
        onResize={(rect) => {
          setHeight(rect.height);
        }}
      />
    </div>
  );
};

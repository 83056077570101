import { FC } from 'react';

import { IconButton } from 'components/ds';
import { EmbeddedDropdownMenu } from '../EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { IconName } from 'components/ds/Icon';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';

type Props = {
  hideDelete?: boolean;
  disabled?: boolean;
  loading?: boolean;
  menuItems?: DropdownOption[];
  deleteItem: () => void;
};

export const DeleteMenu: FC<Props> = ({ disabled, hideDelete, loading, menuItems, deleteItem }) => {
  const menuOptions = [...(menuItems || [])];

  if (!hideDelete) {
    menuOptions.push({
      borderTop: true,
      icon: 'trash' as IconName,
      name: 'Delete',
      onClick: () => deleteItem(),
    });
  }

  return (
    <EmbeddedDropdownMenu disabled={disabled || loading} menuOptions={menuOptions}>
      <IconButton disabled={disabled} loading={loading} name="ellipsis-vertical" />
    </EmbeddedDropdownMenu>
  );
};

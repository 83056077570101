import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import cx from 'classnames';

import { sprinkles } from 'components/ds';

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const DataPanelSection = forwardRef<HTMLDivElement, Props>(function DataPanelSection(
  { className, children, ...props },
  ref,
) {
  return (
    <div className={cx(dataPanel, className)} ref={ref} {...props}>
      {children}
    </div>
  );
});

const dataPanel = sprinkles({
  position: 'relative',
  paddingY: 'sp2',
  borderBottom: 1,
  borderColor: 'outline',
});

import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';
// @ts-ignore
import JSURL from 'jsurl';
import * as RD from 'remotedata';

import { EmbedSpinner } from 'components/embed';
import { ChartLayout } from 'components/ChartLayout/ChartLayout';
import { sprinkles } from 'components/ds';

import { embedFetchDashboard } from 'actions/shareActions';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { pageView } from 'analytics/exploAnalytics';
import { UserTransformedSchema } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { useSetupAnalytics } from 'utils/analyticsUtils';
import { getQueryVariables } from 'utils/variableUtils';
import { loadLocale } from 'utils/localizationUtils';
import { getCustomerVariables } from 'utils/customerUtils';
import { loadFonts } from 'globalStyles/utils';
import { getCurrentTheme, setDashboardTheme } from 'reducers/dashboardStylesReducer';

const errorMessageClass = sprinkles({
  margin: 'sp5',
  padding: 'sp5',
  borderRadius: 8,
  backgroundColor: 'red3',
  fontSize: 24,
});

type Props = {
  dashboardEmbedId: string;
  customerToken: string;
  dataPanelId: string;
  versionNumber?: number;
};

export const EmbeddedChart: FC<Props> = ({
  dashboardEmbedId,
  versionNumber,
  customerToken,
  dataPanelId,
}) => {
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables('shared'));
  const [userTransformedSchema] = useState<UserTransformedSchema>(getUserTransformedSchema());
  const [adHocOps] = useState<AdHocOperationInstructions>(getAdHocOps());
  const [reportName] = useState<string>(getReportName());

  const dispatch = useDispatch();

  useEffect(() => {
    const theme = urlVariables['theme'];
    if (!theme || typeof theme !== 'string') return;
    dispatch(setDashboardTheme(theme));
  }, [urlVariables, dispatch]);

  const { dashboard, dashboardVersion, team, customer } = useSelector(
    (state: EmbedReduxState) => state.embedDashboard,
  );
  const dashboardConfig = dashboardVersion?.configuration;

  const globalStyleConfig = useSelector((state: EmbedReduxState) =>
    getCurrentTheme(state.dashboardStyles),
  );

  const archetypePropertySet = useMemo(
    () => new Set(team?.archetype_properties?.map((prop) => prop.name)),
    [team?.archetype_properties],
  );

  const { environment, isProduction } = useMemo(() => {
    const queryVariables = getQueryVariables('shared');
    const environment = queryVariables['environment'];
    const isProduction = queryVariables['is_production'];
    return { environment, isProduction };
  }, []);

  const onLoad = () => {
    pageView('Shared Chart');

    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
          },
        },
        (data) => {
          loadLocale({
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  };
  useEffect(onLoad, [dispatch, versionNumber, dashboardEmbedId, customerToken, environment]);

  const analyticsReady = useSetupAnalytics({
    pageViewEvent: REPORTED_ANALYTIC_ACTION_TYPES.SHARED_CHART_VIEWS,
    environment: environment as string | undefined,
    isProduction,
    embedType: 'chart',
  });

  useEffect(() => {
    if (team) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig.text, team]);

  if (RD.isError(dashboard)) {
    return <div className={errorMessageClass}>{dashboard.error}</div>;
  } else if (RD.isLoading(dashboard, true) || !analyticsReady) {
    return <EmbedSpinner fillContainer size="xl" />;
  } else if (!RD.isSuccess(dashboard) || !dashboardConfig || !customer) {
    return (
      <div className={errorMessageClass}>
        There was an error loading the dashboard. Please contact your support team for help.
      </div>
    );
  }

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <div className={globalStylesClassName} id="shared-explo-dashboard">
          {dashboard && dashboardConfig && (
            <ChartLayout
              adHocOperationInstructions={adHocOps}
              customerToken={customerToken}
              dashboardConfig={dashboardConfig}
              dashboardEmbedId={dashboardEmbedId}
              dataPanelId={dataPanelId}
              reportName={reportName}
              userTransformedSchema={userTransformedSchema}
              variables={{
                ...urlVariables,
                ...getCustomerVariables(customer, archetypePropertySet),
              }}
            />
          )}
        </div>
      )}
    </GlobalStylesProvider>
  );
};

function getReportName() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.reportName) return '';

  const reportNameWithQuotesRemoved = rawVars.reportName.substring(
    1,
    rawVars.reportName.length - 1,
  );

  return reportNameWithQuotesRemoved;
}

function getUserTransformedSchema() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.userTransformedSchema) return [];

  return JSURL.parse(
    rawVars.userTransformedSchema.substring(1, rawVars.userTransformedSchema.length),
  ) as UserTransformedSchema;
}

function getAdHocOps() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.adHocOps) return {};

  return JSURL.parse(
    rawVars.adHocOps.substring(1, rawVars.adHocOps.length),
  ) as AdHocOperationInstructions;
}

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Switch, sprinkles } from 'components/ds';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { DirectionSection } from 'pages/ReportBuilder/ReportView/DataPanel/DirectionSection';
import { GroupingSection } from 'pages/ReportBuilder/ReportView/DataPanel/GroupingSection';
import { SortingSection } from 'pages/ReportBuilder/ReportView/DataPanel/SortingSection';
import CollapsibleGroupIcon from 'constants/images/collapsible-group-icon.png';
import ColumnTotalIcon from 'constants/images/column-total-icon.png';

import { updateCurrentView } from 'reportBuilderContent/reducers/reportEditingReducer';
import { CustomerReportView, BarVisualizationDirection } from 'actions/customerReportActions';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { OPERATION_TYPES } from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { OPERATION_NAME_MAP } from 'pages/ReportBuilder/constants';

type Props = { view: CustomerReportView };

export const FormatTab: FC<Props> = ({ view }) => {
  const dispatch = useDispatch();
  const isTable = isTableVisualization(view.visualization);
  const groupByLen = view.groupBys?.length ?? 0;
  const firstGroupBy = view.groupBys?.[0];

  const canShowTotals = useSelector(
    (state: ReportBuilderReduxState) =>
      state.embeddedReportBuilder.reportBuilderVersion?.config.general?.showTotals,
  );

  return view.visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ? (
    <>
      <DirectionSection visualizationDirection={view.barChart?.direction} />
      {/* If only one group by then grouping is unnecessary */}
      {groupByLen > 1 ? <GroupingSection visualizationGrouping={view.barChart?.grouping} /> : null}
      {/* If group by is a date then it won't be sorted */}
      {firstGroupBy && !DATE_TYPES.has(firstGroupBy.column.type) ? (
        <SortingSection
          isHorizontal={view.barChart?.direction === BarVisualizationDirection.Horizontal}
          options={view.sortOptions}
        />
      ) : null}
    </>
  ) : isTable ? (
    <>
      <DataPanelSection className={itemContainerClassName}>
        <div className={itemLeftClassName}>
          <div className={itemHeaderClassName}>
            <Switch
              onChange={(usePivot) => dispatch(updateCurrentView({ usePivot }))}
              switchOn={!!view.usePivot}
            />
            <EmbedText heading="h4">Collapsible groups</EmbedText>
          </div>
          <EmbedText body="b2">
            Collapse groups when there are 2 or more &quot;Group bys&quot;, showing subtotals for
            each group.
          </EmbedText>
        </div>
        <img
          alt="Collapsible groups"
          className={sprinkles({ flex: 1 })}
          src={CollapsibleGroupIcon}
        />
      </DataPanelSection>
      {canShowTotals ? (
        <DataPanelSection className={itemContainerClassName}>
          <div className={itemLeftClassName}>
            <div className={itemHeaderClassName}>
              <Switch
                onChange={(showTotals) => dispatch(updateCurrentView({ showTotals }))}
                switchOn={!!view.showTotals}
              />
              <EmbedText heading="h4">Column totals</EmbedText>
            </div>
            <EmbedText body="b2">Show configurable totals at the bottom of each column.</EmbedText>
          </div>
          <img alt="Column totals" className={sprinkles({ flex: 1 })} src={ColumnTotalIcon} />
        </DataPanelSection>
      ) : null}
    </>
  ) : (
    <DataPanelSection className={itemContainerClassName}>
      <EmbedText body="b2">
        No format options available for{' '}
        {OPERATION_NAME_MAP[view.visualization || OPERATION_TYPES.VISUALIZE_TABLE]} visualization
      </EmbedText>
    </DataPanelSection>
  );
};

const itemContainerClassName = sprinkles({
  flexItems: 'alignCenterBetween',
  gap: 'sp1',
  padding: 'sp2',
});
const itemLeftClassName = sprinkles({ flexItems: 'column', gap: 'sp1' });
const itemHeaderClassName = sprinkles({ flexItems: 'alignCenter', gap: 'sp1' });

import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import * as styles from './styles.css';
import { sprinkles, Tag } from 'components/ds';
import { ResourcePreview } from './ResourcePreview';
import { Resource } from './ResourceListPage';

import { ReduxState } from 'reducers/rootReducer';
import { LatestVersionInfo } from 'types/exploResource';
import { maxBy } from 'utils/standard';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import { getPreviewInfoFromLatestVersion } from 'utils/exploResourceUtils';

type Props = {
  isCard: boolean;
  resource: Resource;
  showPreview?: boolean;
  dotsMenu: JSX.Element | null;
  hasEmailState?: boolean;
  onClickUrl: string;
  isLoading?: boolean;
};

export const ResourceItem: FC<Props> = ({
  isCard,
  resource,
  showPreview,
  dotsMenu,
  hasEmailState,
  onClickUrl,
  isLoading,
}) => {
  const history = useHistory();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const lastModified = maxBy(resource.latest_versions, (info) => info.version_number)?.modified;
  const modifiedText = lastModified
    ? `Last edited ${getTimezoneAwareDate(lastModified).toRelative()}`
    : '';

  const isDisabled = 'disabled' in resource ? resource.disabled ?? false : false;

  const latestConfig = resource.latest_versions[resource.latest_versions.length - 1];

  const browserSupportsZoom = 'zoom' in document.body.style;

  const renderEmailTag = () => {
    return (
      <Tag intent="active" leftIcon="envelope-reg">
        Email enabled
      </Tag>
    );
  };

  const renderLatestVersionTag = (
    latest: Pick<LatestVersionInfo, 'is_draft' | 'version_number'>,
  ) => (
    <Tag key={`${latest.version_number}`}>
      {latest.is_draft ? 'In-progress draft' : `Version ${latest.version_number}`}
    </Tag>
  );

  const renderPreview = (latestVersion: LatestVersionInfo | undefined) => {
    const previewInfo = latestVersion ? getPreviewInfoFromLatestVersion(latestVersion) : undefined;

    if (!previewInfo) {
      return <div className={styles.noPreview}>Error</div>;
    }

    if (!previewInfo.layout.length && previewInfo.editableSectionTitle === undefined) {
      return <div className={styles.noPreview}>Empty</div>;
    }

    return (
      <ResourcePreview
        dataPanels={previewInfo.dataPanels}
        editableSectionTitle={previewInfo.editableSectionTitle}
        elements={previewInfo.elements}
        globalStyleConfig={globalStyleConfig}
        layout={previewInfo.layout}
        useZoom={browserSupportsZoom && !isCard}
      />
    );
  };

  if (!isCard) {
    return (
      <div
        className={cx(styles.resourceListItem, {
          [styles.disabledResource]: isDisabled,
        })}
        key={resource.id}
        onClick={isDisabled || isLoading ? undefined : () => history.push(onClickUrl)}>
        <div className={styles.resourceListColumnGroup}>
          {showPreview ? (
            <div className={styles.listPreviewContainer}>
              <div className={styles.listPreview}>
                {renderPreview(latestConfig as LatestVersionInfo)}
              </div>
            </div>
          ) : null}

          <div className={styles.resourceListName}>{resource.name}</div>
        </div>
        <div className={styles.resourceListEndGroup}>
          <div className={styles.resourceListInfo}>
            {resource.latest_versions.map(renderLatestVersionTag)}
            {modifiedText}
          </div>
          <div className={styles.emailContainer}>{hasEmailState ? renderEmailTag() : null}</div>

          {dotsMenu}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.resourceCardItem, {
        [styles.disabledResource]: isDisabled,
      })}
      key={resource.id}
      onClick={isDisabled || isLoading ? undefined : () => history.push(onClickUrl)}>
      <div className={styles.cardPreviewContainer}>
        <div className={styles.cardPreview}>{renderPreview(latestConfig as LatestVersionInfo)}</div>
        {dotsMenu}
      </div>

      <div className={styles.resourceCardInfo}>
        <div className={styles.resourceCardName}>{resource.name}</div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          {resource.latest_versions.map(renderLatestVersionTag)}
          <div className={styles.resourceCardLastModified}>{modifiedText}</div>
        </div>
        {hasEmailState ? renderEmailTag() : null}
      </div>
    </div>
  );
};

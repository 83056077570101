import { FC, useMemo, useState } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { TableOfContentsItem } from 'pages/ReportBuilder/Onboarding/TableOfContentsItem';
import { EmbedInput } from 'components/embed';

import { sprinkles } from 'components/ds';
import { DocPage, DOC_PAGES, Doc } from 'pages/ReportBuilder/Onboarding/Docs';
import { fullTextSearch } from 'utils/stringUtils';
import { groupBy } from 'utils/standard';

type Props = { setPage: (page: Doc) => void };

export const TableOfContents: FC<Props> = ({ setPage }) => {
  const [query, setQuery] = useState('');

  const filteredPages = useMemo(() => {
    const filtered = fullTextSearch(query, pages, (page) => page[0]);
    const grouped = groupBy(filtered, (page) => page[1].section);
    return Object.entries(grouped);
  }, [query]);

  return (
    <>
      <EmbedInput leftIcon="search" onChange={setQuery} placeholder="Search guides" value={query} />
      {filteredPages.map(([section, pages]) => (
        <>
          <EmbedText heading="h3">{section}</EmbedText>
          <div
            className={sprinkles({
              display: 'flex',
              justifyContent: 'stretch',
              flexWrap: 'wrap',
              gap: 'sp2',
            })}>
            {pages.map(([doc, page]) => (
              <TableOfContentsItem
                key={doc}
                title={doc}
                {...page}
                onClick={() => setPage(doc as Doc)}
              />
            ))}
          </div>
        </>
      ))}
    </>
  );
};
const pages = Object.entries(DOC_PAGES) as [Doc, DocPage][];

import { FC, forwardRef, ReactNode } from 'react';
import cx from 'classnames';

import * as styles from './index.css';
import { Icon, Intent, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';

export type Props = {
  /**
   * Optional override for tag background color
   */
  backgroundColor?: string;
  /**
   * Text to display in tag
   */
  children?: ReactNode;
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Optional class to further style closeIcon
   */
  closeIconStyle?: string;
  /**
   * The intent defines the color of the tag
   */
  intent?: Intent;
  /**
   * Inverts the colors of the text and background
   */
  inverted?: boolean;
  /**
   * Icon to show on left inside of tag
   */
  leftIcon?: IconName;
  /**
   * Function fired for closing tags with cross icon
   */
  onClose?: () => void;
};

export const Tag: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    {
      backgroundColor,
      children,
      className,
      closeIconStyle,
      intent = Intent.NONE,
      inverted = false,
      leftIcon,
      onClose,
      ...props
    },
    ref,
  ) => {
    const iconOnly = children === undefined;

    return (
      <div
        {...props}
        className={cx(styles.base({ intent, inverted, iconOnly }), className)}
        ref={ref}
        style={backgroundColor ? { backgroundColor } : undefined}>
        {leftIcon ? (
          <Icon
            className={
              iconOnly ? sprinkles({ marginRight: 'sp0' }) : sprinkles({ marginRight: 'sp.5' })
            }
            name={leftIcon}
            size="sm"
          />
        ) : null}
        {children}
        {onClose ? (
          <Icon
            className={cx(styles.closeIcon, closeIconStyle)}
            data-testid="tag-close"
            name="cross"
            onClick={onClose}
            size="sm"
          />
        ) : null}
      </div>
    );
  },
);

Tag.displayName = 'Tag';

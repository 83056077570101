import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Tabs, sprinkles } from 'components/ds';
import { PromotionalPage } from 'shared/ExploResource/PromotionalPage';

import { ReduxState } from 'reducers/rootReducer';
import { fetchUserTeam } from 'actions/teamActions';
import { useScript } from 'usehooks-ts';
import { getEnvironment } from 'utils/environmentUtils';
import { ResourcePageType } from 'types/exploResource';
import { isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { useQuery } from 'utils/routerUtils';

const contentClass = sprinkles({
  flexDirection: 'column',
  widthConstraints: 'minOnly',
});

// dashboard is Overview Dashboard (Embed) from team Explo App
const embedExploreId = 'jQgYwVJAvR';
const embedRBId = 'XyAZ08Bxdl';

export const AnalyticsPage: FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(displayToResource(query.get('product')));

  const { teamData, isRBEnabled } = useSelector(
    (state: ReduxState) => ({
      teamData: state.teamData.data,
      isRBEnabled: isReportBuilderEnabled(state.currentUser),
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!teamData) {
      dispatch(fetchUserTeam());
    }
  }, [teamData, dispatch]);

  useScript('https://embed.explo.co/bundle.js', { removeOnUnmount: true });

  const customerToken =
    getEnvironment() === 'development'
      ? process.env.REACT_APP_ANALYTICS_DEFAULT_CUSTOMER_TOKEN
      : teamData?.internal_analytics_user_group_token;
  // TODO: add error handling
  if (!customerToken) return null;

  const embedId = currentPage === ResourcePageType.REPORT_BUILDER ? embedRBId : embedExploreId;

  return (
    <div className={contentClass}>
      <Tabs
        onTabSelect={(tabId) => {
          setCurrentPage(displayToResource(tabId));
          history.replace({ search: `?product=${tabId}` });
        }}
        selectedTabId={resourceToDisplay(currentPage)}
        tabs={tabs}
      />
      {!isRBEnabled && currentPage === ResourcePageType.REPORT_BUILDER ? (
        <PromotionalPage pageTitle="Report Builder" />
      ) : (
        <explo-dashboard
          dash-customer-token={`${embedId}:${customerToken}`}
          isProduction={true}
          refresh-minutes={10}
          updateUrlParams={true}
        />
      )}
    </div>
  );
};

const ExploreDisplay = 'Dashboards';
const RBDisplay = 'Report Builder';

const resourceToDisplay = (resource: ResourcePageType): string =>
  resource === ResourcePageType.REPORT_BUILDER ? RBDisplay : ExploreDisplay;

const tabs = [
  resourceToDisplay(ResourcePageType.EXPLORE),
  resourceToDisplay(ResourcePageType.REPORT_BUILDER),
];

const displayToResource = (display: string | null): ResourcePageType =>
  display === RBDisplay ? ResourcePageType.REPORT_BUILDER : ResourcePageType.EXPLORE;

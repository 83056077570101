import { createReducer } from '@reduxjs/toolkit';
import { setSelectedDrilldownColumn } from 'actions/cssLinkActions';

interface CssLinkingReducerState {
  selectedDrilldownColumn?: { name: string; type: string };
}

export default createReducer<CssLinkingReducerState>({}, (builder) => {
  builder
    .addCase(setSelectedDrilldownColumn, (state, { payload }) => {
      state.selectedDrilldownColumn = payload;
    })
    .addDefaultCase((state) => state);
});

import { FC } from 'react';

import * as styles from '../styles.css';
import { Switch, Input } from 'components/ds';

import {
  DASHBOARD_ELEMENT_TYPES,
  SelectElemConfig,
  SELECT_FILTER_TYPE,
} from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SelectElemConfig;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig) => void;
};

export const SelectEditInteractionsConfig: FC<Props> = ({
  config,
  selectType,
  updateSelectConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  return (
    <>
      {selectType === DASHBOARD_ELEMENT_TYPES.DROPDOWN ? (
        <>
          <Switch
            className={styles.configInput}
            label="Enable filtering"
            onChange={() => updateConfig((draft) => (draft.disableSearch = !draft.disableSearch))}
            switchOn={!config.disableSearch}
          />
          {!config.disableSearch ? (
            <Input
              showInputButton
              className={styles.configInput}
              defaultValue={config.filterPlaceholderText || 'Filter...'}
              label="Filter Placeholder"
              onSubmit={(newValue) =>
                updateConfig(
                  (draft) =>
                    (draft.filterPlaceholderText = newValue.trim() === '' ? undefined : newValue),
                )
              }
            />
          ) : null}
        </>
      ) : null}
      {selectType !== DASHBOARD_ELEMENT_TYPES.MULTISELECT ? (
        <Switch
          className={styles.configInput}
          label="Enable clearing selection"
          onChange={() => updateConfig((draft) => (draft.disableCancel = !draft.disableCancel))}
          switchOn={!config.disableCancel}
        />
      ) : null}
      {selectType !== DASHBOARD_ELEMENT_TYPES.TOGGLE ? (
        <Switch
          className={styles.configInput}
          label="Disable dropdown if there are no items"
          onChange={() =>
            updateConfig((draft) => (draft.disableOnNoItems = !draft.disableOnNoItems))
          }
          switchOn={config.disableOnNoItems}
        />
      ) : null}
    </>
  );
};

import { TypeColumn, CellProps } from '@inovua/reactdatagrid-enterprise/types';
import { TypeSummaryReducer } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { keyBy } from 'utils/standard';

import {
  CellStyleOverrides,
  getCellStyles,
  renderNumberCell,
  renderCell,
} from 'components/ds/DataGrid/columnUtils';
import { getCellAlignment } from 'components/ds/DataGrid/utils';
import { DATE_TYPES, FLOAT, NUMBER_TYPES, BOOLEAN } from 'constants/dataConstants';
import { DateDisplayOptions, DisplayOptions, NumberDisplayOptions } from 'constants/types';
import { formatDateField } from 'pages/dashboardPage/charts/utils';
import { DatasetColumn, DatasetSchema, DatasetRow } from 'types/datasets';
import { ColumnConfigs } from 'types/columnTypes';
import { defaultFormatCellData } from 'components/dataTable/utils';
import { MetricsByColumn } from 'types/dashboardTypes';
import { isDataRequiredForTableColumnGradient } from 'utils/gradientUtils';
import { EmbedPivotTableRow } from 'components/embed/EmbedPivotTable/index';
import { sprinkles } from 'components/ds';
import * as styles from './pivotUtils.css';

type GeneratePivotColumnParams = {
  columnConfigs: ColumnConfigs;
  schema: DatasetSchema | undefined;
  pivotColumns?: string[];
  groupByColumns: string[];
};

export type PivotColumns = {
  columns: TypeColumn[];
  groupColumn: TypeColumn;
};

type RenderCellData = { leaf: boolean; array: PivotTableRow[]; fieldPath: string[]; depth: number };

type RenderCellParams = {
  value: string;
  data: RenderCellData;
  cellProps?: CellProps;
  row: PivotTableRow;
};

type RenderGroupValueParams = {
  value: string;
  data: RenderCellData;
};

export function generatePivotColumns({
  columnConfigs,
  schema,
  pivotColumns,
  groupByColumns,
}: GeneratePivotColumnParams): PivotColumns {
  const pivotSet = new Set(pivotColumns || []);
  const groupBySet = new Set(groupByColumns);

  const schemaByName = schema ? keyBy(schema, (col) => col.name) : {};

  const columns: TypeColumn[] = (schema ?? []).map((columnInfo) => {
    const { name, friendly_name, type } = columnInfo;
    const config = columnConfigs[name];

    const groupSummaryReducer: TypeSummaryReducer | undefined =
      pivotSet.has(name) || groupBySet.has(name) ? undefined : firstReducer;

    return {
      name,
      header: friendly_name,
      defaultFlex: 1,
      minWidth: 100,
      textAlign: getCellAlignment(config?.displayFormatting, type),
      groupSummaryReducer,
      render: ({ data, value, cellProps }: RenderCellParams) => {
        const { leaf, array } = data;
        // Checking value == null handles an edge case where if you pivot and group by the same column, "row" below will be incorrect
        if (!leaf || value == null) return null;

        const row = array.find((row) => {
          // Match the cellProps.id format, which is groupByKey1:groupByValue1_groupByKey2:groupByValue2-aggKey
          const groupByStr = pivotColumns
            ? pivotColumns.map((groupBy) => `${groupBy}:${row[groupBy]}`).join('_') + '-'
            : '';
          const id = groupByStr + name;
          return id === cellProps?.id;
        });

        if (cellProps) {
          const cellStyles = row?.[getCellStyleId(name)] as CellStyleOverrides | undefined;
          if (cellStyles) cellProps.style = { ...cellProps.style, ...cellStyles };
        }

        // Render transformed value (using transformRows)
        return row?.[getCellTransformedId(name)] || value;
      },
    };
  });

  const headerNames: string[] = [];
  groupByColumns.forEach((colName) => {
    const schemaCol = schemaByName[colName];
    if (schemaCol) headerNames.push(schemaCol.friendly_name ?? schemaCol.name);
  });

  const groupColumn: TypeColumn = {
    header: headerNames.join(' / '),
    defaultFlex: 1,
    minWidth: groupByColumns.length * 100,
    className: styles.groupCell,
    renderGroupValue: ({ data, value }: RenderGroupValueParams) => {
      if (value == null) return null;

      // Render transformed value (using transformRows)
      const { array } = data;

      // array should only contain 1 item corresponding to the row being rendered
      const row = array?.[0];

      // If there are 2 group bys, then when rendering the 2nd group by, depth=2 (they match 1:1)
      const renderedValue = row?.[getCellTransformedId(groupByColumns[data.depth - 1])] || value;
      return <div className={cellClassName}>{renderedValue}</div>;
    },
  };

  return { columns, groupColumn };
}

const cellClassName = sprinkles({ flex: 1, truncateText: 'ellipsis' });

const renderValue = (
  value: string | number | undefined,
  column: DatasetColumn | undefined,
  config?: { displayFormatting?: DisplayOptions },
): string | number => {
  if (value === null || value === undefined || !column) return '';

  const { type } = column;
  const displayOptions = config?.displayFormatting;
  if (!displayOptions) {
    // defaultFormatCellData returns JSX for Booleans, we need to prevent that
    return type === BOOLEAN ? String(value) : String(defaultFormatCellData(value, column));
  }

  if (NUMBER_TYPES.has(type)) {
    return renderNumberCell({
      cellData: value,
      displayOptions: displayOptions as NumberDisplayOptions,
      isFloatCol: type === FLOAT,
    }) as string;
  } else if (DATE_TYPES.has(type)) {
    return formatDateField(
      value as string,
      type,
      displayOptions as DateDisplayOptions,
      false,
      true,
    );
  }

  return String(value);
};

// This reducer doesn't actually do anything since it should have nothing to reduce on
const firstReducer = { initialValue: 0, reducer: (_: number, b: number) => b };

/**
 * Should be used to transform/render data before passing it to ReactDataGrid to improve efficiency
 */
export const transformRows = (
  rows: DatasetRow[] | undefined,
  schema: DatasetSchema | undefined,
  columnConfigs: ColumnConfigs,
  pivotColumns: string[] | undefined,
): EmbedPivotTableRow[] => {
  if (!rows?.length || !schema?.length) return [];

  return rows.map((row) => {
    const newRow: EmbedPivotTableRow = {};
    schema.forEach((columnInfo) => {
      const name = columnInfo.name;
      const value = row[name];
      const config = columnConfigs[name];

      // Pivot values are rendered as column headers which don't have a custom render function so they need to be pre-rendered
      if (pivotColumns?.includes(name)) {
        newRow[name] = renderValue(value, columnInfo, config);
        return;
      }

      // Group by values and cell values need to be clickable so we need to have both the value and the rendered value
      newRow[name] = value;
      newRow[getCellTransformedId(name)] = renderCell({
        config: config,
        columnInfo,
        row,
        value,
      });
    });
    return newRow;
  });
};

type PivotTableRow = Record<string, string | number | CellStyleOverrides>;

const CELL_TRANSFORMED_KEY = '_transformed';
const getCellTransformedId = (name: string) => `${name}${CELL_TRANSFORMED_KEY}`;

const CELL_STYLE_KEY = '_cell_styles';
const getCellStyleId = (name: string) => `${name}${CELL_STYLE_KEY}`;

// No need to create a shared function with above for now since its simple
// But as more gets added might be good to share between the two
export const transformRowsWithCellStyles = (
  rows: DatasetRow[] | undefined,
  schema: DatasetSchema | undefined,
  columnConfigs: ColumnConfigs,
): PivotTableRow[] => {
  if (!rows?.length || !schema?.length) return [];

  const metricsByColumn = getColumnMetricsForGradient(rows, schema, columnConfigs);

  return rows.map((row) => {
    const newRow: PivotTableRow = {};
    schema.forEach((columnInfo) => {
      const name = columnInfo.name;
      const colConfig = columnConfigs[name];
      const value = row[name];
      newRow[name] = renderValue(value, columnInfo, colConfig);
      const cellStyles = getCellStyles({
        cellData: value,
        colType: columnInfo.type,
        displayOptions: colConfig?.displayFormatting,
        metrics: metricsByColumn[name],
      });
      if (cellStyles) newRow[getCellStyleId(name)] = { ...cellStyles };
    });
    return newRow;
  });
};

const getColumnMetricsForGradient = (
  rows: DatasetRow[],
  schema: DatasetSchema,
  columnConfigs: ColumnConfigs,
): MetricsByColumn => {
  const metricsByColumn: MetricsByColumn = {};
  schema.forEach(({ name }) => {
    const colConfig = columnConfigs[name];
    if (!colConfig?.displayFormatting) return;
    const displayOptions = colConfig.displayFormatting as NumberDisplayOptions;
    if (!isDataRequiredForTableColumnGradient(displayOptions)) return;

    let min = Infinity;
    let max = -Infinity;
    let total = 0;
    rows.forEach((row) => {
      const value = row[name] as number;
      min = Math.min(min, value);
      max = Math.max(max, value);
      total += value;
    });
    metricsByColumn[name] = { min, max, avg: total / rows.length };
  });
  return metricsByColumn;
};

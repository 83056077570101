import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { BooleanFormatConfig } from 'components/ColumnFormatConfigs/BooleanFormatConfig';
import { DateFormatConfig } from 'components/ColumnFormatConfigs/DateFormatConfig';
import { StringFormatConfig } from 'components/ColumnFormatConfigs/StringFormatConfig';
import { sprinkles } from 'components/ds';

import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import {
  BooleanDisplayOptions,
  DateDisplayOptions,
  DisplayOptions,
  NumberDisplayOptions,
  StringDisplayOptions,
} from 'constants/types';
import { updateReportBuilderColFormatting } from 'reducers/reportBuilderEditReducer';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { getReportBuilderSchema } from 'utils/reportBuilderConfigUtils';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  column: { name: string; type: string };
  dataset: ReportBuilderDataset;
  displayFormatting: DisplayOptions | undefined;
};

export const ColumnFormatting: FC<Props> = ({ column, dataset, displayFormatting }) => {
  const dispatch = useDispatch();

  const { datasetData, globalStyleConfig } = useSelector(
    (state: ReduxState) => ({
      datasetData: state.reportBuilderEdit.datasetData[dataset.id],
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
    }),
    shallowEqual,
  );

  const updateFormatting = (updates: DisplayOptions) => {
    const newFormatting = { ...displayFormatting, ...updates };
    dispatch(
      updateReportBuilderColFormatting({
        datasetId: dataset.id,
        colName: column.name,
        formatting: newFormatting,
      }),
    );
  };

  const renderColFormattingType = () => {
    if (column.type === BOOLEAN) {
      return (
        <BooleanFormatConfig
          isNewDataGrid
          column={column}
          displayOptions={displayFormatting as BooleanDisplayOptions}
          globalStyleConfig={globalStyleConfig}
          updateBooleanOptions={updateFormatting}
        />
      );
    }
    if (column.type === STRING) {
      return (
        <StringFormatConfig
          column={column}
          dataPanelData={datasetData.rows ?? []}
          displayOptions={displayFormatting as StringDisplayOptions}
          globalStyleConfig={globalStyleConfig}
          originalSchema={getReportBuilderSchema(dataset.schema, dataset)}
          updateStringOptions={updateFormatting}
        />
      );
    }
    if (DATE_TYPES.has(column.type)) {
      return (
        <DateFormatConfig
          column={column}
          displayOptions={displayFormatting as DateDisplayOptions}
          operationType="REPORT_BUILDER"
          updateDateOptions={updateFormatting}
        />
      );
    }
    if (NUMBER_TYPES.has(column.type)) {
      return (
        <NumberFormatConfig
          column={column}
          displayOptions={displayFormatting as NumberDisplayOptions}
          operationType="REPORT_BUILDER"
          updateNumberOptions={updateFormatting}
        />
      );
    }
    return null;
  };

  return <div className={sprinkles({ marginTop: 'sp1' })}>{renderColFormattingType()}</div>;
};

import { FC, useMemo } from 'react';
import cx from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';

import { Button, IconButton, Select, sprinkles } from 'components/ds';
import { SchemaTableViewer } from 'components/resource/SchemaTableViewer';

import { TableDataset } from 'actions/dataSourceActions';

import { ReduxState } from 'reducers/rootReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { getSchemaTablesMap } from 'reducers/selectors';
import { FidoTableView } from 'reducers/fidoReducer';

type Props = {
  saveText: string;
  selectedDatasetSchemaId: number | string | undefined;

  onSelectSchema: (schemaId: number) => void;
  onRevertDraft?: () => void;
  onPreview?: () => void;
  onSave?: () => void;
  onFormat: () => void;
  isReportBuilder?: boolean;
};

/**
 * Lets the user choose a schema from a dropdown list, and then displays the tables in that schema
 * Also provides buttons for reverting edits, previewing, saving, and formatting the SQL
 */
export const SchemaViewer: FC<Props> = ({
  saveText,
  selectedDatasetSchemaId,
  onRevertDraft,
  onPreview,
  onSave,
  onFormat,
  onSelectSchema,
  isReportBuilder,
}) => {
  const { parentSchemas, schemaTablesMap } = useSelector(
    (state: ReduxState) => ({
      schemaTablesMap: getSchemaTablesMap(state),
      parentSchemas: getParentSchemasList(state),
    }),
    shallowEqual,
  );

  const schemaId: number | undefined = useMemo(() => {
    if (typeof selectedDatasetSchemaId === 'string') {
      return parentSchemas.find((s) => s.fido_id === String(selectedDatasetSchemaId))?.id ?? -1;
    }
    return selectedDatasetSchemaId as number | undefined;
  }, [selectedDatasetSchemaId, parentSchemas]);

  const schemaOptions = useMemo(
    () => parentSchemas.map((schema) => ({ value: String(schema.id), label: schema.name })),
    [parentSchemas],
  );

  const tableDatasets: TableDataset[] = useMemo(() => {
    if (!schemaId || !schemaTablesMap || !selectedDatasetSchemaId) return [];

    return Object.values(
      // TODO when we clean up FIDO, we should really clean up when the embeddo ID is used vs the fido id....
      schemaTablesMap[schemaId === selectedDatasetSchemaId ? schemaId : selectedDatasetSchemaId] ??
        {},
    ).sort((a: TableDataset | FidoTableView, b) => {
      if ('table_name' in a) return a.table_name.localeCompare(b.table_name);
      return a.tableName.localeCompare(b.tableName);
    });
  }, [schemaTablesMap, schemaId, selectedDatasetSchemaId]);

  const rootStyle = isReportBuilder ? { maxHeight: 240 } : { minWidth: 320, width: 320 };
  const className = sprinkles(
    isReportBuilder ? { border: 1, borderRadiusBottom: 8 } : { borderLeft: 1 },
  );

  return (
    <div className={cx(rootClass, className)} style={rootStyle}>
      <div className={actionItemClass}>
        <IconButton
          disabled={!onRevertDraft}
          name="trashUndo"
          onClick={onRevertDraft}
          tooltipProps={{ text: 'Revert draft' }}
          variant="primary"
        />
        <IconButton
          name="wrench"
          onClick={onFormat}
          tooltipProps={{ text: 'Format SQL (⌘+⇧+F)' }}
          variant="primary"
        />
        <Button disabled={!onPreview} onClick={onPreview} variant="secondary">
          Preview
        </Button>
        <Button disabled={!onSave} icon="play" onClick={onSave} variant="primary">
          {saveText}
        </Button>
      </div>
      <div className={sprinkles({ flexItems: 'column', overflow: 'hidden' })}>
        <Select
          className={sprinkles({ borderBottom: 1, borderColor: 'outline', padding: 'sp1.5' })}
          onChange={(value) => onSelectSchema(parseInt(value))}
          selectedValue={schemaId?.toString()}
          values={schemaOptions}
        />
        <div className={sprinkles({ padding: 'sp1', flex: 1, overflowY: 'auto' })}>
          {tableDatasets.length === 0 ? (
            <div className={sprinkles({ flexItems: 'centerColumn', padding: 'sp1.5' })}>
              <div className={sprinkles({ marginBottom: 'sp1', textAlign: 'center' })}>
                This schema has not been synced. Click below to sync the tables.
              </div>
              <Button to={`/sync-tables/${schemaId}`} variant="primary">
                Sync Tables
              </Button>
            </div>
          ) : (
            tableDatasets.map((table) => <SchemaTableViewer key={table.id} table={table} />)
          )}
        </div>
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'column',
  overflow: 'hidden',
  borderColor: 'outline',
  backgroundColor: 'white',
});

const actionItemClass = sprinkles({
  display: 'flex',
  gap: 'sp1',
  paddingY: 'sp1',
  paddingX: 'sp1.5',
  borderBottom: 1,
  borderColor: 'outline',
});

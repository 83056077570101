import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { EmbedButton } from 'components/embed';
import { sprinkles } from 'components/ds';

import { DashboardButtonElemConfig } from 'types/dashboardTypes';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';

type Props = {
  config: DashboardButtonElemConfig;
};

export const DashboardRefreshElement: FC<Props> = ({ config }) => {
  const dispatch = useDispatch();

  return (
    <div className={sprinkles({ paddingTop: 'sp2.5' })}>
      <EmbedButton
        fillWidth
        icon={config.hideRefreshIcon ? undefined : 'refresh'}
        onClick={() => {
          dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }));
        }}
        variant="primary">
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <div className={sprinkles({ truncateText: 'ellipsis', flex: 1 })}>
            {config.buttonText ?? 'Refresh Dashboard'}
          </div>
        </div>
      </EmbedButton>
    </div>
  );
};

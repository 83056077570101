import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import * as RD from 'remotedata';
import { v4 as uuidv4 } from 'uuid';

import { saveGlobalStylesSuccess } from 'actions/styleConfigActions';
import { logInUserSuccess } from 'actions/userActions';
import * as customerReportActions from 'actions/customerReportActions';
import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import { EmbedReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { DEFAULT_GLOBAL_STYLE_CONFIG, getBaseGlobalStyles } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import * as navUtils from 'pages/ReportBuilder/utils/navigationUtils';
import { clearSelectedReport } from './reportEditingReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  createCustomerReport,
  favoriteBuiltIn,
  saveCustomerReport,
  favoriteCustomerReport,
} from 'reportBuilderContent/thunks/reportThunks';
import { FetchCustomerReportsTeamData } from 'actions/customerReportActions';

type RequestInfo = {
  customerToken: string | undefined;
  embedId: string | undefined;
  embedJwt: string | undefined;
  versionNumber: number;
  envTagId: number | null;
};

export interface EmbeddedReportBuilderReducerState {
  reportBuilder: RD.ResponseData<EmbedReportBuilder>;
  reportBuilderVersion: EmbedReportBuilderVersion | null;
  reports: customerReportActions.CustomerReport[];
  styleConfig: GlobalStyleConfig;
  team: FetchCustomerReportsTeamData | null;
  requestInfo: RequestInfo;
  selectedTab: ReportBuilderTab;
  favoriteBuiltIns: string[]; // List of Built-In Report Ids
  variables: DashboardVariableMap;
  timezone: string | null;

  // For editing built ins and previewing reports
  isInApp: boolean;
  isAiEnabled: boolean;
  isPreview: boolean;

  // Unique portal for each instance of report builder
  portalId: string;
}

const getInitialState = (): EmbeddedReportBuilderReducerState => ({
  reportBuilder: RD.Idle(),
  reportBuilderVersion: null,
  reports: [],
  selectedTab: ReportBuilderTab.ALL_REPORTS,
  styleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
  team: null,
  timezone: null,
  requestInfo: {
    customerToken: undefined,
    embedId: undefined,
    embedJwt: undefined,
    versionNumber: -1,
    envTagId: null,
  },
  favoriteBuiltIns: [],
  variables: {},
  isInApp: false,
  isAiEnabled: false,
  isPreview: false,
  portalId: uuidv4(),
});

const embeddedReportBuilderSlice = createSlice({
  name: 'embeddedReportBuilder',
  initialState: getInitialState(),
  reducers: {
    clearEmbeddedReportBuilderReducer: (state) => {
      // styleConfig shouldn't be reset since it's only reloaded when the page loads (user logs in)
      return { ...getInitialState(), styleConfig: state.styleConfig };
    },
    setVersionForInAppPreview: (state, { payload }: PayloadAction<EmbedReportBuilderVersion>) => {
      state.reportBuilderVersion = payload;
      state.isInApp = true;
    },
    setIsPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.isPreview = payload;
    },
    setSelectedTab: (state, { payload }: PayloadAction<{ tab: ReportBuilderTab }>) => {
      state.selectedTab = payload.tab;
      if (!state.isInApp) navUtils.goToHomePageTab(payload.tab);
    },
    setVariables: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      state.variables = payload;
    },
    setTimezone: (state, { payload }: PayloadAction<string>) => {
      state.timezone = payload;
    },
    setAiEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isAiEnabled = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerReportActions.fetchCustomerReportsRequest, (state) => {
        state.reportBuilder = RD.Loading();
      })
      .addCase(customerReportActions.fetchCustomerReportsSuccess, (state, { payload }) => {
        const { report_builder } = payload;
        state.reportBuilder = RD.Success(report_builder);
        state.reportBuilderVersion = payload.report_builder_version;
        state.reports = payload.reports;
        state.team = payload.team;
        state.isAiEnabled = payload.team.entitlements.report_builder_ai_enabled;
        state.favoriteBuiltIns = payload.metadata.favorite_built_ins;
        state.requestInfo = {
          customerToken: payload.customerToken,
          embedId: payload.report_builder.embed_id ?? payload.postData.resource_embed_id,
          embedJwt: payload.jwt,
          versionNumber: payload.report_builder_version.version_number,
          envTagId: payload.environment_tag_id,
        };
        if (payload.style_config) state.styleConfig = payload.style_config;
      })
      .addCase(customerReportActions.fetchCustomerReportsError, (state, { payload }) => {
        state.reportBuilder = RD.Error(
          payload.errorData?.error_msg || 'Error Loading Report Builder',
        );
      })
      .addCase(createCustomerReport.fulfilled, (state, { payload }) => {
        state.reports.push(payload.report);
      })
      .addCase(favoriteCustomerReport.fulfilled, (state, { payload }) => {
        state.reports.forEach((report) => {
          if (report.id === payload.report_id) report.is_starred = !report.is_starred;
        });
      })
      .addCase(customerReportActions.deleteCustomerReportSuccess, (state, { payload }) => {
        state.reports = state.reports.filter((report) => report.id !== payload.report_id);
      })
      .addCase(saveCustomerReport.fulfilled, (state, { payload }) => {
        state.reports = state.reports.filter((report) => report.id !== payload.report.id);
        state.reports.push(payload.report);
      })
      .addCase(customerReportActions.updateCustomerReportNameSuccess, (state, { payload }) => {
        state.reports.forEach((report) => {
          if (report.id === payload.report_id) report.name = payload.name;
        });
      })
      .addCase(clearSelectedReport, (state) => {
        if (!state.isInApp) navUtils.goToHomePageTab(state.selectedTab);
      })
      .addCase(favoriteBuiltIn.fulfilled, (state, { payload }) => {
        state.favoriteBuiltIns = payload.favorite_built_ins;
      })
      .addCase(logInUserSuccess, (state, { payload }) => {
        if (payload.team) state.styleConfig = getBaseGlobalStyles(payload.team.style_config_v2);
      })
      .addCase(saveGlobalStylesSuccess, (state, { payload }) => {
        state.styleConfig = payload.config_v2;
      });
  },
});

export const getOrderedBuiltIns = createSelector(
  (state: EmbeddedReportBuilderReducerState) => state.reportBuilderVersion?.config.builtInReports,
  (state: EmbeddedReportBuilderReducerState) => state.reportBuilderVersion?.config.builtInOrder,
  (builtInReports, builtInOrder) => {
    if (!builtInOrder || !builtInReports) return Object.values(builtInReports || {});
    return builtInOrder.map((id) => builtInReports[id]).filter((report) => !!report);
  },
);

export const {
  setSelectedTab,
  setVersionForInAppPreview,
  clearEmbeddedReportBuilderReducer,
  setVariables,
  setAiEnabled,
  setIsPreview,
  setTimezone,
} = embeddedReportBuilderSlice.actions;

export const embeddedReportBuilderReducer = embeddedReportBuilderSlice.reducer;

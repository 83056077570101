import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as RD from 'remotedata';
import {
  SSHTunnel,
  SSHAuthentication,
  PasswordAuthentication,
  TestConnectionResponse,
  NamespaceResponse,
  DataSourceResponse,
} from '@explo-tech/fido-api';
import {
  FidoDataSourceConfig,
  MiscSSHConfig,
  AwsAuthOptions,
} from 'pages/ConnectDataSourceFlow/types';
import {
  createNamespace,
  testConnectionUsingFido,
  createDataSourceInFido,
  createEmbeddoDataSource,
  retestConnectionUsingFido,
} from './thunks/connectDataSourceThunks';
import {
  DATABASES,
  SSH_AUTH_TYPE,
  DATA_SOURCE_AUTH,
  TUNNEL,
  SUPPORTED_FIDO_DATA_SOURCES,
} from 'pages/ConnectDataSourceFlow/constants';
import { DataSourceConfiguration } from 'actions/dataSourceActions';
import {
  getFidoReducerSSHConfigFromCredentials,
  getCommonDataSourceConfigInfo,
} from 'utils/fido/fidoShims';
import { ReduxState } from './rootReducer';

interface FidoDataSourceConfigReducer {
  finalConfig?: FidoDataSourceConfig;
  config: Partial<FidoDataSourceConfig> & { authMethod: string };
  sshConfig: Partial<SSHTunnel> & MiscSSHConfig;
  awsAuthOptions: Partial<AwsAuthOptions>;
  type?: DATABASES;
  testConnectionResponse: RD.ResponseData<TestConnectionResponse>;
  createNamespaceResponse: RD.ResponseData<NamespaceResponse>;
  createDataSourceResponse: RD.ResponseData<DataSourceResponse>;
  // Don't need to know actual value, just if it was successful or not
  connectDataSourceUsingFidoResponse: RD.ResponseData<boolean>;
  isConfigDraft: boolean;
  connectingError?: string;
  isUpdatingDataSource?: boolean;
}

const initialState: FidoDataSourceConfigReducer = {
  config: {
    authMethod: DATA_SOURCE_AUTH.USERNAME_PASSWORD,
  },
  sshConfig: { sshAuthMethod: SSH_AUTH_TYPE.VENDOR },
  awsAuthOptions: {},
  testConnectionResponse: RD.Idle(),
  createNamespaceResponse: RD.Idle(),
  createDataSourceResponse: RD.Idle(),
  connectDataSourceUsingFidoResponse: RD.Idle(),
  isConfigDraft: true,
};

const fidoDataSourceConfigSlice = createSlice({
  name: 'fidoDataSourceConfigReducer',
  initialState,
  reducers: {
    setAwsAuthOptions: (state, { payload }: PayloadAction<Partial<AwsAuthOptions>>) => {
      state.awsAuthOptions = { ...state.awsAuthOptions, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setFinalDataSourceConfig: (state, { payload }: PayloadAction<FidoDataSourceConfig>) => {
      state.finalConfig = payload;
      state.isConfigDraft = false;
    },
    setDataSourceConfig: (
      state,
      { payload }: PayloadAction<Partial<FidoDataSourceConfig & { authMethod: string }>>,
    ) => {
      state.config = { ...state.config, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setDataSourceType: (state, { payload }: PayloadAction<DATABASES>) => {
      state.type = payload;
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setPasswordAuthentication: (
      state,
      { payload }: PayloadAction<Partial<PasswordAuthentication>>,
    ) => {
      state.config = {
        ...state.config,
        authentication: {
          ...(state.config as FidoDataSourceConfig)?.authentication,
          ...payload,
        },
      };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    resetRetestConnectionResponse: (state) => {
      state.testConnectionResponse = RD.Idle();
    },
    setSSHAuthenticationConfig: (state, { payload }: PayloadAction<Partial<SSHAuthentication>>) => {
      state.sshConfig = {
        ...state.sshConfig,
        authentication: { ...(state.sshConfig as SSHTunnel)?.authentication, ...payload },
      };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setSSHConfig: (
      state,
      { payload }: PayloadAction<Partial<SSHTunnel> | Partial<MiscSSHConfig>>,
    ) => {
      state.sshConfig = { ...state.sshConfig, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setSSHAuth: (state, { payload }: PayloadAction<string>) => {
      state.sshConfig.sshAuthMethod = payload;
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setConnectingError: (state, { payload }: PayloadAction<string>) => {
      state.connectingError = payload;
    },
    resetFidoDataSourceConfigReducer: () => initialState,
    setInitialConfigForUpdate: (state, { payload }: PayloadAction<DataSourceConfiguration>) => {
      state.isConfigDraft = false;
      state.isUpdatingDataSource = true;

      const commonConfig = getCommonDataSourceConfigInfo(payload);
      if (!commonConfig) return;
      const { database, port, host, authentication, tunnelType } = commonConfig;
      state.config = { ...state.config, database, port, host, authentication };

      if (tunnelType === TUNNEL.SSH) {
        const sshConfig = getFidoReducerSSHConfigFromCredentials(payload);
        if (!sshConfig) return;
        state.sshConfig = sshConfig;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(testConnectionUsingFido.pending, (state) => {
        state.testConnectionResponse = RD.Loading();
        state.createNamespaceResponse = RD.Idle();
        state.createDataSourceResponse = RD.Idle();
        state.connectDataSourceUsingFidoResponse = RD.Idle();
      })
      .addCase(testConnectionUsingFido.fulfilled, (state, { payload }) => {
        state.testConnectionResponse = RD.Success(payload);
      })
      .addCase(testConnectionUsingFido.rejected, (state, { error }) => {
        state.testConnectionResponse = RD.Error(error.message ?? 'Something went wrong');
        state.connectingError = error.message ?? 'Something went wrong';
      })
      .addCase(createNamespace.pending, (state) => {
        state.createNamespaceResponse = RD.Loading();
      })
      .addCase(retestConnectionUsingFido.pending, (state) => {
        state.testConnectionResponse = RD.Loading();
      })
      .addCase(retestConnectionUsingFido.fulfilled, (state, { payload }) => {
        state.testConnectionResponse = RD.Success(payload);
      })
      .addCase(retestConnectionUsingFido.rejected, (state, { error }) => {
        state.testConnectionResponse = RD.Error(error.message ?? 'Something went wrong');
      })
      .addCase(createNamespace.fulfilled, (state, { payload }) => {
        state.createNamespaceResponse = RD.Success(payload);
        state.connectingError = undefined;
      })
      .addCase(createNamespace.rejected, (state, { error }) => {
        state.createNamespaceResponse = RD.Error(error.message ?? 'Something went wrong');
        state.connectDataSourceUsingFidoResponse = RD.Error(
          error.message ?? 'Something went wrong',
        );
        state.connectingError = error.message ?? 'Something went wrong';
      })
      .addCase(createDataSourceInFido.pending, (state) => {
        state.createDataSourceResponse = RD.Loading();
      })
      .addCase(createDataSourceInFido.fulfilled, (state, { payload }) => {
        state.createDataSourceResponse = RD.Success(payload);
      })
      .addCase(createDataSourceInFido.rejected, (state, { error }) => {
        state.createDataSourceResponse = RD.Error(error.message ?? 'Something went wrong');
        state.connectDataSourceUsingFidoResponse = RD.Error(
          error.message ?? 'Something went wrong',
        );
        state.connectingError = error.message ?? 'Something went wrong';
      })
      .addCase(createEmbeddoDataSource.pending, (state) => {
        state.connectDataSourceUsingFidoResponse = RD.Loading();
      })
      .addCase(createEmbeddoDataSource.fulfilled, () => {
        return { ...initialState, connectDataSourceUsingFidoResponse: RD.Success(true) };
      })
      .addCase(createEmbeddoDataSource.rejected, (state, { error }) => {
        state.connectDataSourceUsingFidoResponse = RD.Error(
          error.message ?? 'Something went wrong',
        );
        state.connectingError = error.message ?? 'Something went wrong';
      });
  },
});

/**
 * Used in the case we are connecting a data source and are past selecting a type
 * or we are updating a data source. Note isUpdatingDataSource in this reducer is only set
 * to true is the data source we are updating has a fido_id.
 */
export const computeUseFidoForConnectingOrUpdating = createSelector(
  (state: ReduxState) => state.currentUser.team?.feature_flags.use_fido,
  (state: ReduxState) => state.fidoDataSourceConfig.type,
  (state: ReduxState) => state.fidoDataSourceConfig.isUpdatingDataSource,
  (useFido, type, isUpdatingDataSource) =>
    useFido && type && SUPPORTED_FIDO_DATA_SOURCES.includes(type) && (isUpdatingDataSource ?? true),
);

export const {
  setAwsAuthOptions,
  setFinalDataSourceConfig,
  setDataSourceConfig,
  setDataSourceType,
  setPasswordAuthentication,
  setSSHAuthenticationConfig,
  setSSHConfig,
  setSSHAuth,
  setConnectingError,
  setInitialConfigForUpdate,
  resetFidoDataSourceConfigReducer,
  resetRetestConnectionResponse,
} = fidoDataSourceConfigSlice.actions;

export const fidoDataSourceConfigReducer = fidoDataSourceConfigSlice.reducer;

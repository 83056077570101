import { FC } from 'react';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';

import { SettingHeader } from 'components/SettingHeader';
import { SharedColorConfigs } from 'components/ChartConfigs/ColorConfigs';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

import { updateVisualizeOperationThunk } from 'actions/dataPanelConfigActions';
import {
  ColorFormat,
  OPERATION_TYPES,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions;
  includeZones?: boolean;
};

export const ColorConfig: FC<Props> = ({ visualizationType, includeZones, instructions }) => {
  const dispatch = useDispatch();
  /**
   * We use useSelector here instead of useContext because the context around content other than the DashboardLayout
   * uses the default theme to avoid using custom styles in Explo app components. But here we want to use the user's configured
   * theme to determine what to display, not the default.
   */
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateColorFormat = (colorUpdates: ColorFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.colorFormat = { ...draft.colorFormat, ...colorUpdates };
    });
    dispatch(updateVisualizeOperationThunk(newInstructions, visualizationType, true));
  };

  return (
    <>
      <SettingHeader name="Colors" />
      <div className={configRootClass}>
        <SharedColorConfigs
          configInputClass={configInputClass}
          globalStyleConfig={globalStyleConfig}
          instructions={instructions}
          updateColorFormat={updateColorFormat}
          zonesAllowed={includeZones}
        />
      </div>
    </>
  );
};

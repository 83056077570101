import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { Input, Spinner, sprinkles } from 'components/ds';

import { TeamMember } from 'actions/userActions';
import { SettingsTeamMemberItem } from './settingsTeamMemberItem';

const compareUsers = (user1: TeamMember, user2: TeamMember) => {
  if (!user1.first_name || !user1.last_name || !user2.first_name || !user2.last_name) return 0;
  if (user1.first_name.toLowerCase() < user2.first_name.toLowerCase()) return -1;
  else if (
    user1.first_name.toLowerCase() === user2.first_name.toLowerCase() &&
    user1.last_name.toLowerCase() < user2.last_name.toLowerCase()
  )
    return -1;
  else return 1;
};

export const SettingsTeamMemberList: FC = () => {
  const [memberSearchQuery, setMemberSearchQuery] = useState('');

  const teamId = useSelector((state: ReduxState) => state.currentUser.team?.id);
  const teamData = useSelector((state: ReduxState) => state.teamData);

  const teamMembers = teamData.data?.team_members ?? [];

  if (!teamId || teamData.loading) {
    return (
      <div
        className={sprinkles({ display: 'flex', justifyContent: 'center', width: 'fill' })}
        style={{ height: 200 }}>
        <Spinner fillContainer size="lg" />
      </div>
    );
  }

  const memberSearchQueryLower = memberSearchQuery.toLowerCase();

  const filteredInvites = memberSearchQueryLower
    ? teamData.invitedUsers.filter((user) =>
        user.email.toLowerCase().includes(memberSearchQueryLower),
      )
    : [...teamData.invitedUsers];

  const filteredTeamMembers =
    memberSearchQueryLower && teamMembers
      ? teamMembers.filter(
          (user) =>
            !user.email ||
            user.first_name?.toLowerCase().includes(memberSearchQueryLower) ||
            user.last_name?.toLowerCase().includes(memberSearchQueryLower) ||
            user.email.toLowerCase().includes(memberSearchQueryLower),
        )
      : [...teamMembers];

  return (
    <div>
      <div className={sprinkles({ marginBottom: 'sp3', marginTop: 'sp4' })}>
        <Input
          leftIcon="search"
          onChange={setMemberSearchQuery}
          placeholder="Search"
          value={memberSearchQuery}
        />
      </div>
      {filteredInvites.sort().map((user) => (
        <SettingsTeamMemberItem invitedUser={user} key={user.email} />
      ))}
      {filteredTeamMembers.sort(compareUsers).map((user) => (
        <SettingsTeamMemberItem key={user.id} user={user} />
      ))}
    </div>
  );
};

import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BuiltInListItem } from 'pages/ReportBuilder/HomeView/BuiltInListItem';
import { ReportListItem } from 'pages/ReportBuilder/HomeView/ReportListItem';
import { EmbedButton } from 'components/embed';
import * as styles from 'pages/ReportBuilder/HomeView/AllReportTab.css';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { orderBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { getOrderedBuiltIns } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

interface Props {
  createReport: () => void;
}

export const StarredReportTab: FC<Props> = ({ createReport }) => {
  const { reports, builtInReports, favoriteBuiltIns } = useSelector(
    (state: ReportBuilderReduxState) => ({
      reports: state.embeddedReportBuilder.reports,
      builtInReports: getOrderedBuiltIns(state.embeddedReportBuilder),
      favoriteBuiltIns: state.embeddedReportBuilder.favoriteBuiltIns,
    }),
    shallowEqual,
  );

  const builtInReportList = useMemo(
    () => builtInReports.filter((builtIn) => favoriteBuiltIns.includes(builtIn.id)),
    [builtInReports, favoriteBuiltIns],
  );

  const filteredAndSortedReports = useMemo(() => {
    const filteredReports = reports.filter((report) => report.is_starred);
    return orderBy(filteredReports, (report) => getTimezoneAwareUnix(report.modified), 'desc');
  }, [reports]);

  const renderEmptyState = () => (
    <div className={styles.emptyState}>
      <EmbedText body="b1" color="contentPrimary">
        No starred reports or built ins
      </EmbedText>
      <EmbedButton
        className={sprinkles({ marginTop: 'sp1' })}
        onClick={createReport}
        variant="tertiary">
        Create your first report
      </EmbedButton>
    </div>
  );

  return (
    <div className={styles.reportTabContainer}>
      {!builtInReportList.length && !filteredAndSortedReports.length ? (
        renderEmptyState()
      ) : (
        <div className={styles.reportListContainer}>
          {builtInReportList.map((builtIn) => (
            <BuiltInListItem builtIn={builtIn} key={builtIn.id} />
          ))}
          {filteredAndSortedReports.map((report) => (
            <ReportListItem key={report.id} report={report} />
          ))}
        </div>
      )}
    </div>
  );
};

import { FC, useState } from 'react';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { FilterableColumn, getFilterDefaultOperation } from 'utils/customerReportUtils';
import { FILTER_OPERATORS } from 'types/filterOperations';
import { BOOLEAN } from 'constants/dataConstants';
import { FilterPopover } from './FilterPopover';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
};

const booleanOptions = FILTER_OPERATORS.filter((op) => op.supported_column_types.has(BOOLEAN));

export const BooleanFilterPopover: FC<Props> = ({ column, clause }) => {
  const [holdPopoverOpen, setHoldPopoverOpen] = useState(false);
  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );

  return (
    <FilterPopover
      clause={clause}
      column={column}
      holdPopoverOpen={holdPopoverOpen}
      operator={currOperator}
      operatorOptions={booleanOptions}
      setHoldPopoverOpen={setHoldPopoverOpen}
      setOperator={setOperator}
    />
  );
};

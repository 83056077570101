import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import * as RD from 'remotedata';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { DatasetEditorNonIdealState } from 'pages/dashboardPage/dashboardDatasetEditor/DatasetEditorNonIdealState';
import DataTableFooter from './DataTableFooter';
import { ColumnResizeType, EmbedDataGrid } from 'components/embed/EmbedDataGrid';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';

import {
  AdHocOperationInstructions,
  DataPanelData,
  SortInfo_DEPRECATED,
} from 'types/dataPanelTemplate';
import { Dataset, DatasetDataObject } from 'actions/datasetActions';
import { DatasetSchema, DatasetRow } from 'types/datasets';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import {
  ColumnWidths,
  LegacyWidths,
  VisualizeTableInstructions,
  REPORTED_ANALYTIC_ACTION_TYPES,
  SortInfo,
  SortOrder,
  UserTransformedSchema,
  DEFAULT_NO_DATA_FONT_SIZE,
  ColumnColorTracker,
} from 'constants/types';
import { getMetricsByColumn, removeUserDisabledColumns } from 'utils/dashboardUtils';
import { sortTable, getPrimarySortInfo } from 'utils/adHocUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { Metadata } from 'utils/analyticsUtils';
import { getDrilldownDisplayOptions } from 'utils/drilldownDatasetUtils';
import { getTooltipVariables } from 'utils/variableUtils';
import {
  convertColumnConfigs,
  convertLegacyWidths,
  generateResizedColumnInstructions,
  getCellBorders,
} from 'components/embed/EmbedDataGrid/utils';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { setVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';

const FOOTER_HEIGHT = 34;

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,

    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
  loadingDataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,
    borderBottom: `1px solid ${theme.palette.ds.grey500}`,
    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
  emptyIcon: {
    display: 'block',
    height: 30,
  },
  noDataCallout: ({ dataPanel }: Pick<Props, 'dataPanel'>) => ({
    height: '100%',
    overflow: 'auto',

    '& .bp3-non-ideal-state-visual': {
      height: 50,
    },

    '& .bp3-heading': {
      fontSize:
        dataPanel.visualize_op.generalFormatOptions?.noDataState?.noDataFontSize ||
        DEFAULT_NO_DATA_FONT_SIZE,
      fontWeight: 'unset',
    },
  }),
}));

type Props = {
  dataPanel: DataPanel;
  dataPanelData: DataPanelData | undefined;
  enableColumnResizing?: boolean;
  enableNewGrid?: boolean;
  isDrilldownModal?: boolean;
  drilldownDataset?: Dataset;
  loading?: boolean;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  schema: DatasetSchema;
  secondaryData: DatasetRow[];
  updateDataTableOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  userTransformedSchema?: UserTransformedSchema;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  isEmbed: boolean;
  variables: DashboardVariableMap;
  colorTracker?: ColumnColorTracker;
};

// Converts Adhoc sort info to TypeSortInfo for reactdatagrid
const convertSortInfo = (sortInfo?: SortInfo_DEPRECATED): TypeSortInfo | undefined => {
  if (!sortInfo) return undefined;
  const { column_name, order } = sortInfo;
  return {
    columnName: column_name,
    name: column_name,
    dir: order === SortOrder.ASC ? 1 : order === SortOrder.DESC ? -1 : 0,
  };
};

type WithAnalyticsTracker = {
  sendEvent: (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => void;
};

// Remove when baseDataTable is removed
const getOnColumnSelect =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    sendEvent,
    instructions,
  }: Pick<Props, 'onAdHocSortOrPageUpdate'> &
    WithAnalyticsTracker & {
      adHocOperationInstructions: AdHocOperationInstructions;
      instructions: VisualizeTableInstructions;
    }) =>
  (colIndex: number, headerList: DatasetSchema) => {
    const sortInfo = sortTable(
      colIndex,
      headerList,
      adHocOperationInstructions,
      !!instructions.isInitialSortDesc,
      onAdHocSortOrPageUpdate,
    );

    sendEvent(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_SORTED, {
      column_name: sortInfo.column_name,
      order: sortInfo.order.toString(),
    });
  };

const getOnNewPage =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    sendEvent,
    totalRowCount,
    rowsPerPage,
  }: Pick<Props, 'onAdHocSortOrPageUpdate'> &
    WithAnalyticsTracker & {
      adHocOperationInstructions: AdHocOperationInstructions;
      rowsPerPage: number;
      totalRowCount: RD.ResponseData<number> | undefined;
    }) =>
  (newPage: string) => {
    if (!RD.isSuccess(totalRowCount)) return;

    const newPageNumber = Number.parseInt(newPage);
    const maxPageNumber = Math.ceil(totalRowCount.data / rowsPerPage);

    if (
      !newPageNumber ||
      newPageNumber < 1 ||
      newPageNumber > maxPageNumber ||
      adHocOperationInstructions.currentPage === newPageNumber
    ) {
      return;
    }

    const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
      draft.currentPage = newPageNumber;
    });

    onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

    sendEvent(REPORTED_ANALYTIC_ACTION_TYPES.TABLED_PAGED, { new_page: newPageNumber });
  };

const onTableColumnWidthsEdited =
  ({
    updateDataTableOperation,
    enableColumnResizing,
    instructions,
    schema,
  }: Pick<Props, 'updateDataTableOperation' | 'enableColumnResizing' | 'schema'> & {
    instructions: VisualizeTableInstructions;
  }) =>
  (index: number, size: number) => {
    const existingWidths = instructions.columnWidths || {};
    if (!enableColumnResizing || Array.isArray(existingWidths)) return;

    const newWidths = produce(existingWidths, (draft) => {
      // Get the colName of the column in the schema by the index provided.
      // Assumes new ColumnWidths is an object with keys in the same order as the schema
      const colName = schema[index].name;
      draft[colName] = Math.round(size);
    });

    const newOperation = produce(instructions, (draft) => {
      draft.columnWidths = newWidths;
    });
    updateDataTableOperation(newOperation);
  };

export default function DataTable({
  dataPanel,
  enableColumnResizing,
  enableNewGrid,
  isDrilldownModal,
  loading,
  onAdHocSortOrPageUpdate,
  schema,
  secondaryData,
  updateDataTableOperation,
  userTransformedSchema,
  isEmbed,
  drilldownDataset,
  variables,
  colorTracker,
  dataPanelData,
  datasetNamesToId,
  datasetData,
}: Props) {
  const classes = useStyles({ dataPanel });
  const dispatch = useDispatch();

  const metricsByColumn = useMemo(() => getMetricsByColumn(secondaryData || []), [secondaryData]);

  const sendEvent = useCallback(
    (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => {
      dispatch(sendAnalyticsEvent(eventName, metadata));
    },
    [dispatch],
  );

  const { rows, totalRowCount, unsupportedOperations } = dataPanelData ?? {};

  const adHocOperationInstructions = useMemo(
    () => dataPanelData?.adHocOperationInstructions || {},
    [dataPanelData?.adHocOperationInstructions],
  );

  const {
    instructions: { VISUALIZE_TABLE: instructions },
    generalFormatOptions,
  } = dataPanel.visualize_op;
  const tableLoading = loading || !rows?.length;
  const hasNoData = !loading && rows?.length === 0;

  const isSchemaCustomizationEnabled =
    instructions.isSchemaCustomizationEnabled || isDrilldownModal;
  const rowsPerPage = instructions.rowsPerPage || MAX_ROWS_TO_PREVIEW;

  const drilldownDisplayOptions = useMemo(() => {
    const drilldownColumnConfigs = drilldownDataset?.drilldownColumnConfigs;

    if (!drilldownColumnConfigs || tableLoading) return undefined;
    return getDrilldownDisplayOptions(drilldownColumnConfigs);
  }, [tableLoading, drilldownDataset]);

  const columnConfigs = useMemo(() => {
    if (isDrilldownModal) return drilldownDataset?.drilldownColumnConfigs;

    return convertColumnConfigs(instructions);
  }, [isDrilldownModal, drilldownDataset?.drilldownColumnConfigs, instructions]);

  const tableSchema =
    isSchemaCustomizationEnabled && userTransformedSchema
      ? removeUserDisabledColumns(userTransformedSchema)
      : schema;

  // Sort callback for DataGrid, converts SortInfo to SortInfo_DEPRECATED
  const handleColumnSelect = (sortInfo: SortInfo[]) => {
    let newSortInfo: SortInfo_DEPRECATED | undefined;
    if (sortInfo.length > 0) {
      newSortInfo = {
        column_name: sortInfo[0].column.name,
        order: sortInfo[0].order,
      };

      const oldSortInfo = getPrimarySortInfo(adHocOperationInstructions.sortInfo);
      if (
        oldSortInfo?.column_name === newSortInfo.column_name &&
        oldSortInfo?.order === newSortInfo.order
      )
        return;
    }

    const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
      draft.sortInfo = newSortInfo;
    });

    onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

    sendEvent(
      REPORTED_ANALYTIC_ACTION_TYPES.TABLE_SORTED,
      newSortInfo && {
        column_name: newSortInfo.column_name,
        order: newSortInfo.order.toString(),
      },
    );
  };

  const handleGoToPage = useCallback(
    (page: number) => {
      if (!RD.isSuccess(totalRowCount)) return;

      const maxPageNumber = Math.ceil(totalRowCount.data / rowsPerPage);

      if (
        !page ||
        page < 1 ||
        page > maxPageNumber ||
        adHocOperationInstructions.currentPage === page
      ) {
        return;
      }

      const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
        draft.currentPage = page;
      });

      onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

      sendEvent(REPORTED_ANALYTIC_ACTION_TYPES.TABLED_PAGED, { new_page: page });
    },
    [adHocOperationInstructions, onAdHocSortOrPageUpdate, totalRowCount, sendEvent, rowsPerPage],
  );

  // TODO: Remove this effect when we are confident there are no more tables using LegacyWidths type
  useEffect(() => {
    if (!enableNewGrid || !enableColumnResizing) return;

    const newOperation = convertLegacyWidths(instructions, tableSchema);
    if (!newOperation) return;

    updateDataTableOperation(newOperation);
  }, [enableNewGrid, enableColumnResizing, instructions, tableSchema, updateDataTableOperation]);

  const handleColumnResize = useCallback(
    (columns: ColumnResizeType[]) => {
      const existingWidths = instructions.columnWidths || {};
      // Only allow resize if a single column is being resized by dragging, not when autosize is applied
      // TODO: Remove array check when we are confident there are no more tables using LegacyWidths type
      if (!enableColumnResizing || columns.length !== 1 || Array.isArray(existingWidths)) return;

      const newOperation = generateResizedColumnInstructions(instructions, columns);
      if (!newOperation) return;
      updateDataTableOperation(newOperation);
    },
    [enableColumnResizing, instructions, updateDataTableOperation],
  );

  const drilldownConfig = instructions.drilldownConfig;
  // TODO: Move into handleRowSelectionChange when baseDataTable is removed
  const [drilldownCol, drilldownAll] = drilldownConfig?.drilldownEnabled
    ? [drilldownConfig?.drilldownColumn, drilldownConfig?.allColumns]
    : [undefined, undefined];

  const canSelectRow = drilldownCol || drilldownAll;
  const handleRowSelectionChange = useCallback(
    (row: DatasetRow | undefined) => {
      if (!drilldownCol && !drilldownAll) return;

      if (drilldownCol) {
        const value = row?.[drilldownCol];
        dispatch(setVariableThunk({ varName: dataPanel.provided_id, value }));
      } else {
        dispatch(
          setVariableThunk({
            varName: dataPanel.provided_id,
            value: row,
          }),
        );
      }
    },
    [drilldownCol, drilldownAll, dispatch, dataPanel.provided_id],
  );

  // Reset drilldown var when new rows are loaded
  useEffect(() => {
    if (!canSelectRow) return;
    dispatch(setVariableThunk({ varName: dataPanel.provided_id, value: undefined }));
  }, [dispatch, rows, dataPanel.provided_id, canSelectRow]);

  const paginatorProps: PaginatorProps | undefined = useMemo(() => {
    if (!enableNewGrid || instructions.isFooterHidden) return;
    return {
      error: RD.isError(totalRowCount),
      totalRowCount: RD.isSuccess(totalRowCount) ? totalRowCount.data : undefined,
      currentPage: adHocOperationInstructions.currentPage || 1,
      isPagingDisabled: isPagingDisabled(unsupportedOperations),
      loading: totalRowCount === undefined || RD.isLoading(totalRowCount),
      rowsPerPage,
      goToPage: ({ page }) => handleGoToPage(page),
    };
  }, [
    totalRowCount,
    adHocOperationInstructions,
    unsupportedOperations,
    handleGoToPage,
    enableNewGrid,
    instructions.isFooterHidden,
    rowsPerPage,
  ]);

  const maxRows = Math.min(rows?.length ?? 0, rowsPerPage);

  const slicedRows = useMemo(() => {
    if (!rows) return [];
    return rows.slice(0, maxRows);
  }, [maxRows, rows]);

  const showCellBorders = getCellBorders(instructions);

  const renderFooter = () => (
    <DataTableFooter
      adHocOperationInstructions={adHocOperationInstructions}
      isEmbed={isEmbed}
      loading={tableLoading && !hasNoData}
      onNewPage={getOnNewPage({
        adHocOperationInstructions,
        onAdHocSortOrPageUpdate,
        sendEvent,
        totalRowCount,
        rowsPerPage,
      })}
      rowsPerPage={instructions.rowsPerPage}
      totalRowCount={totalRowCount}
      unsupportedOperations={unsupportedOperations}
    />
  );

  if (tableLoading && !hasNoData && !enableNewGrid) {
    return (
      <>
        <LoadingDataTable
          disableRowHeader
          className={cx(classes.loadingDataTable, {
            noHeader: !isDrilldownModal && generalFormatOptions?.headerConfig?.isHeaderHidden,
            noFooter: instructions.isFooterHidden,
          })}
          maxRows={50}
          rowHeight={instructions.rowHeight}
        />
        {instructions.isFooterHidden ? null : renderFooter()}
      </>
    );
  }

  if (isDrilldownModal && tableSchema.length === 0 && !tableLoading) {
    return <DatasetEditorNonIdealState title="No visible columns" />;
  }

  if (enableNewGrid) {
    const columnTooltips = getTooltipVariables(instructions.changeSchemaList, variables);
    const columnFit = isDrilldownModal
      ? drilldownDataset?.drilldownConfig?.columnFit
      : instructions.columnFit;

    return (
      <>
        <EmbedDataGrid
          hideHeaderMenu
          colorTracker={colorTracker}
          columnConfigs={columnConfigs}
          columnFit={columnFit}
          columnTooltips={columnTooltips}
          columnWidths={instructions.columnWidths as ColumnWidths}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          enableBoldFirstColumn={instructions.isFirstColumnBolded}
          enableBoldHeader={instructions.isColumnHeadersBolded}
          enableLockedFirstColumn={instructions.isFirstColumnFrozen}
          isInitialSortDesc={instructions.isInitialSortDesc}
          loading={tableLoading}
          metricsByColumn={metricsByColumn}
          onColumnResize={handleColumnResize}
          onRowSelectionChange={canSelectRow ? handleRowSelectionChange : undefined}
          onSortColumn={
            isDrilldownModal || !instructions.isColumnSortingDisabled
              ? handleColumnSelect
              : undefined
          }
          paginatorProps={paginatorProps}
          rowHeight={isDrilldownModal ? 40 : instructions.rowHeight}
          rows={slicedRows}
          schema={tableSchema}
          shouldTruncateText={isDrilldownModal || instructions.shouldTruncateText}
          shouldVisuallyGroupByFirstColumn={instructions.shouldVisuallyGroupByFirstColumn}
          showCellBorders={showCellBorders}
          sortInfo={convertSortInfo(getPrimarySortInfo(adHocOperationInstructions.sortInfo))}
          variables={variables}
        />
      </>
    );
  }

  return (
    <>
      <BaseDataTable
        disableRowHeader
        fill
        isDashboardTable
        noBorderRadius
        truncateEmptyRowSpace
        unrestrictedHeight
        useFriendlyNameForHeader
        changeSchemaList={instructions.changeSchemaList}
        className={cx(classes.dataTable, {
          noHeader: !isDrilldownModal && generalFormatOptions?.headerConfig?.isHeaderHidden,
          noFooter: instructions.isFooterHidden,
        })}
        columnLinesEnabled={instructions.isColumnLinesEnabled}
        columnWidths={instructions.columnWidths as LegacyWidths}
        datasetData={datasetData}
        datasetNamesToId={datasetNamesToId}
        enableColumnResizing={enableColumnResizing}
        isColumnHeadersBolded={instructions.isColumnHeadersBolded}
        isFirstColumnBolded={instructions.isFirstColumnBolded}
        isFirstColumnFrozen={instructions.isFirstColumnFrozen}
        isInitialSortDesc={instructions.isInitialSortDesc}
        isSortable={isDrilldownModal || !instructions.isColumnSortingDisabled}
        loading={tableLoading}
        maxRows={rowsPerPage}
        metricsByColumn={metricsByColumn}
        onColumnSelect={getOnColumnSelect({
          adHocOperationInstructions,
          onAdHocSortOrPageUpdate,
          sendEvent,
          instructions,
        })}
        onColumnWidthChanged={onTableColumnWidthsEdited({
          updateDataTableOperation,
          enableColumnResizing,
          instructions,
          schema,
        })}
        onRowSelection={canSelectRow ? handleRowSelectionChange : undefined}
        rowHeight={instructions.rowHeight}
        rowLinesDisabled={instructions.isRowLinesDisabled}
        rows={slicedRows}
        schema={tableSchema}
        schemaDisplayOptions={
          isDrilldownModal ? drilldownDisplayOptions : instructions.schemaDisplayOptions
        }
        shouldTruncateText={instructions.shouldTruncateText}
        shouldVisuallyGroupByFirstColumn={instructions.shouldVisuallyGroupByFirstColumn}
        sortInfo={getPrimarySortInfo(adHocOperationInstructions.sortInfo)}
        variables={variables}
      />
      {instructions.isFooterHidden ? null : renderFooter()}
    </>
  );
}

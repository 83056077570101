import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import * as RD from 'remotedata';

import * as styles from '../styles.css';
import { SideSheet, sprinkles } from 'components/ds';
import { GettingStartedBody } from 'pages/ConnectDataSourceFlow/StepPages/GettingStarted';
import { DataSourceButton } from 'components/DataSourceButton';
import { showErrorToast, showSuccessToast, showWarningToast } from 'shared/sharedToasts';
import { DBConnectionConfig, FidoDataSourceConfig } from 'pages/ConnectDataSourceFlow/types';
import { EnterCredentialsBody } from 'pages/ConnectDataSourceFlow/StepPages/EnterCredentials';
import { SecurityConfigurationBody } from 'pages/ConnectDataSourceFlow/StepPages/SecurityConfiguration';
import { Jobs } from 'components/JobQueue/types';
import { Poller } from 'components/JobQueue/Poller';
import { AlertModal } from 'components/ds';

import {
  editDataSource,
  DataSource,
  fetchSupportedDataSources,
  testUpdatedDataSourceConnection,
  DataSourceConfiguration,
  TestDataSourceConnectionData,
  EditDataSourceBody,
  SupportedDataSource,
} from 'actions/dataSourceActions';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { sendPing } from 'actions/pingActions';
import { ReduxState } from 'reducers/rootReducer';
import { TOAST_TIMEOUT } from '../constants';
import { PingTypes } from 'constants/types';
import { dataSourceByOldType, dataSourceByType } from 'constants/dataSourceConstants';
import { parseJsonFields } from 'utils/general';
import { some } from 'utils/standard';
import { hasValidConfiguration, hasConfigUpdates } from '../utils';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { getTeamDataSources } from 'reducers/dataSourceReducer';
import {
  setInitialConfigForUpdate,
  setDataSourceType,
  resetFidoDataSourceConfigReducer,
  resetRetestConnectionResponse,
  computeUseFidoForConnectingOrUpdating,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import {
  buildFinalFidoDataSourceConfig,
  pingDataSourceConnectionMsg,
} from 'pages/ConnectDataSourceFlow/utils';
import {
  retestConnectionUsingFido,
  updateDataSourceInFido,
} from 'reducers/thunks/connectDataSourceThunks';
import { TestConnectionResponse } from '@explo-tech/fido-api';
import { ErrorResponse } from 'actions/responseTypes';

type Props = {
  dataSource: DataSource;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const ManageDataSourceSideSheet: FC<Props> = ({ dataSource, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const {
    dataSources,
    currentUser,
    parentSchemas,
    supportedDataSources,
    team,
    useFido,
    fidoDataSourceConfig,
  } = useSelector((state: ReduxState) => {
    return {
      parentSchemas: getParentSchemasList(state),
      dataSources: getTeamDataSources(state),
      currentUser: state.currentUser,
      team: state.teamData.data,
      supportedDataSources: state.dataSource.supportedDataSources,
      useFido: computeUseFidoForConnectingOrUpdating(state),
      fidoDataSourceConfig: state.fidoDataSourceConfig,
    };
  }, shallowEqual);

  const [connectionStatus, setConnectionStatus] = useState<
    RD.ResponseData<TestDataSourceConnectionData>
  >(RD.Idle());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [config, setConfig] = useState<DBConnectionConfig>({});
  const [accessGroupUpdates, setAccessGroupUpdates] = useState<number[]>();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  useEffect(() => {
    if (RD.isIdle(supportedDataSources)) dispatch(fetchSupportedDataSources());
  }, [dispatch, supportedDataSources]);

  const supportedDataSource = useMemo(
    () =>
      RD.getOrDefault(supportedDataSources, []).find((ds) =>
        useFido ? ds.name === dataSource.source_type : ds.type === dataSource.source_type,
      ),
    [supportedDataSources, dataSource, useFido],
  );

  const parentSchema = useMemo(
    () => parentSchemas.find((schema) => schema.id === dataSource.parent_schema_id),
    [parentSchemas, dataSource],
  );
  const newName = config.name !== undefined ? config.name : dataSource.name;
  const newProvidedId =
    config.providedId !== '' && config.providedId !== dataSource.provided_id
      ? config.providedId
      : undefined;

  const updateConfig = (newConfig: DBConnectionConfig) => {
    useFido ? dispatch(resetRetestConnectionResponse()) : setConnectionStatus(RD.Idle());
    setConfig(newConfig);
    dispatch(resetRetestConnectionResponse());
  };

  const bulkEnqueueJobsWrapper = useCallback(
    (jobs: JobDefinition[]) => {
      if (jobs.length === 0) return;

      const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

      dispatch(
        bulkEnqueueJobs({ jobs: jobMap }, (jobs) => {
          setAwaitedJobs(jobs);
        }),
      );
    },
    [dispatch],
  );

  const getTestPingMsg = (isError?: boolean, isJobQueue?: boolean) =>
    pingDataSourceConnectionMsg(
      currentUser,
      newName,
      dataSource.source_type,
      isError,
      useFido,
      true,
      isJobQueue,
    );

  const onTestingSuccess = (data: TestDataSourceConnectionData | TestConnectionResponse) => {
    // Use types key difference to determine whether need to update embeddo connection status
    if ((data as TestDataSourceConnectionData).num_tables) {
      setConnectionStatus(RD.Success(data as TestDataSourceConnectionData));
    }
    const numTables = useFido
      ? (data as TestConnectionResponse).numberOfTables
      : (data as TestDataSourceConnectionData).num_tables;
    const numTablesText = numTables > 0 ? ` with ${numTables}` : ', but it has 0';
    const toastMsg = `Data source successfully connects ${numTablesText} tables. Click 'Update' to save these changes.`;

    if (numTables > 0) showSuccessToast(toastMsg, TOAST_TIMEOUT);
    else showWarningToast(toastMsg, TOAST_TIMEOUT);

    // Ping #bd-ping-testing-datasource that a test connection was successful.
    sendPing({
      postData: {
        message: `${getTestPingMsg()} with ${numTables} tables`,
        message_type: PingTypes.PING_DATASOURCE_TESTING,
      },
    });
  };

  const onTestingFailure = (errorMessage: string | undefined) => {
    if (!useFido) setConnectionStatus(RD.Idle());
    showErrorToast('Testing Failure' + (errorMessage ? `: ${errorMessage}` : ''));
    // ping #bd-ping-testing-datasource that a test connection failed.
    sendPing({
      postData: {
        message: `<!channel> ${getTestPingMsg(true)}`,
        message_type: PingTypes.PING_DATASOURCE_TESTING,
      },
    });
  };

  const areAccessGroupUpdatesSafe = () => {
    if (accessGroupUpdates?.length === 0) {
      if (!useFido) setConnectionStatus(RD.Idle());
      return showErrorToast(`At least one visibility group must be selected.`);
    }

    if (accessGroupUpdates) {
      const removingDefaultDataSource = some(team?.access_groups, (accessGroup) => {
        const isDefaultDataSource = accessGroup.default_data_source_ids.includes(dataSource.id);
        return isDefaultDataSource && !accessGroupUpdates.includes(accessGroup.id);
      });
      if (removingDefaultDataSource) {
        if (!useFido) setConnectionStatus(RD.Idle());
        return showErrorToast(`You cannot remove a visibility group from its default data source.`);
      }
      return true;
    }
    return true;
  };

  const testConnectionCredentials = (parsedConfig: DataSourceConfiguration) => {
    if (!areAccessGroupUpdatesSafe()) return;

    const postData = {
      name: config.name,
      configuration: parsedConfig,
      id: dataSource.id,
    };

    if (!currentUser.team?.feature_flags.use_job_queue) {
      dispatch(
        testUpdatedDataSourceConnection({ postData }, onTestingSuccess, (response) =>
          onTestingFailure(response.error_msg),
        ),
      );
    } else {
      bulkEnqueueJobsWrapper([
        {
          job_type: ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION,
          job_args: postData,
          onSuccess: onTestingSuccess,
          onError: onTestingFailure,
        } as JobDefinition,
      ]);
      sendPing({
        postData: {
          message: getTestPingMsg(false, true),
          message_type: PingTypes.PING_DATASOURCE_TESTING,
        },
      });
    }
  };

  const onResetShared = () => {
    if (!useFido) setConnectionStatus(RD.Idle());
    updateConfig({});
    setAccessGroupUpdates(undefined);
  };

  const onDiscard = () => {
    if (useFido) {
      dispatch(resetFidoDataSourceConfigReducer());
    }
    onResetShared();
  };

  const onResetChanges = () => {
    if (useFido) {
      dispatch(setDataSourceType(dataSource.source_type as DATABASES));
      dispatch(setInitialConfigForUpdate(dataSource.user_viewable_credentials));
    }
    onResetShared();
  };

  const hasUpdates = useMemo(() => {
    return (
      (useFido ? fidoDataSourceConfig.isConfigDraft : false) ||
      hasConfigUpdates(config) ||
      accessGroupUpdates !== undefined
    );
  }, [config, accessGroupUpdates, fidoDataSourceConfig.isConfigDraft, useFido]);

  const hasValidConfig = useMemo(() => {
    const properties = supportedDataSource?.configuration_schema.properties;
    if (!properties) return false;

    const isFidoDataSourceConfigValid =
      !useFido ||
      !!buildFinalFidoDataSourceConfig(
        fidoDataSourceConfig.config,
        fidoDataSourceConfig.sshConfig,
        fidoDataSourceConfig.type,
        false,
      );

    return isFidoDataSourceConfigValid && hasValidConfiguration(config, properties);
  }, [config, fidoDataSourceConfig, supportedDataSource?.configuration_schema.properties, useFido]);

  const handleCloseAttempt = () => {
    if (hasUpdates) {
      setShowConfirmationModal(true);
    } else {
      onDiscard();
      setShowConfirmationModal(false);
      setIsOpen(false);
    }
  };

  const onUpdateError = (e?: ErrorResponse) => {
    setIsUpdateLoading(false);
    showErrorToast(
      `${newName} failed to update.` + (e?.detail ? ` Error: ${e.detail}` : ''),
      TOAST_TIMEOUT,
    );
  };

  const editDataSourceWrapper = (request: EditDataSourceBody) => {
    setIsUpdateLoading(true);
    return dispatch(
      editDataSource(
        { id: dataSource.id, postData: request },
        () => {
          showSuccessToast(`${newName} successfully updated.`, TOAST_TIMEOUT);
          setIsUpdateLoading(false);
          onDiscard();
          setIsOpen(false);
        },
        onUpdateError,
      ),
    );
  };

  const getDefinedFidoInfo = (): void | {
    configuration: FidoDataSourceConfig;
    id: string;
    externalId: string;
    namespaceId: string;
  } => {
    const configuration = buildFinalFidoDataSourceConfig(
      fidoDataSourceConfig.config,
      fidoDataSourceConfig.sshConfig,
      fidoDataSourceConfig.type,
      false,
    );

    if (!configuration) {
      return showErrorToast('Parsing the credentials failed.', TOAST_TIMEOUT);
    }
    const namespaceId = parentSchema?.fido_id;

    if (!dataSource.fido_id || !dataSource.provided_id || !namespaceId) {
      return showErrorToast('Error getting data source information.', TOAST_TIMEOUT);
    }

    return {
      configuration,
      id: dataSource.fido_id,
      externalId: newProvidedId ? newProvidedId : dataSource.provided_id,
      namespaceId,
    };
  };

  const updateWithFido = () => {
    const fidoInfo = getDefinedFidoInfo();
    if (!fidoInfo) return;
    const { configuration, id, externalId, namespaceId } = fidoInfo;

    if (!RD.isSuccess(fidoDataSourceConfig.testConnectionResponse)) {
      if (!areAccessGroupUpdatesSafe()) return;
      dispatch(
        retestConnectionUsingFido({
          configuration,
          id,
          onSuccess: onTestingSuccess,
          onError: onTestingFailure,
        }),
      );
      return;
    }

    dispatch(
      updateDataSourceInFido({
        dataSource: { name: newName, externalId, configuration },
        id,
        namespaceId,
        onSuccess: () =>
          editDataSourceWrapper({
            name: newProvidedId,
            provided_id: newProvidedId,
            access_group_ids: accessGroupUpdates,
            credentials: {},
          }),
        onError: onUpdateError,
      }),
    );
  };

  const updateWithoutFido = (supportedDataSource: SupportedDataSource) => {
    const { parsedConfig, error } = parseJsonFields(
      supportedDataSource,
      config?.dataSourceConfig || {},
    );

    if (error !== undefined) {
      setConnectionStatus(RD.Idle());
      showErrorToast('Parsing the credentials failed.', TOAST_TIMEOUT);
      return;
    }

    if (!RD.isSuccess(connectionStatus)) {
      setConnectionStatus(RD.Loading());
      testConnectionCredentials(parsedConfig);
      return;
    }

    editDataSourceWrapper({
      name: newName,
      provided_id: newProvidedId,
      credentials: parsedConfig,
      access_group_ids: accessGroupUpdates,
    });
  };

  const onUpdate = () => {
    if (supportedDataSource === undefined) {
      showErrorToast('Data source metadata undefined.', TOAST_TIMEOUT);
      return;
    }

    useFido ? updateWithFido() : updateWithoutFido(supportedDataSource);
  };

  const renderManageDataSource = () => {
    const dataSourceInfo = (useFido ? dataSourceByType : dataSourceByOldType)[
      dataSource.source_type
    ];

    return (
      <div className={styles.sideSheetContent}>
        <div className={styles.section}>
          <GettingStartedBody
            isEditing
            accessGroups={team?.access_groups}
            config={config}
            existingDataSources={dataSources}
            headerClassName={styles.sectionHeader}
            placeholderName={dataSource.name}
            placeholderProvidedId={dataSource.provided_id}
            selectedAccessGroupIds={accessGroupUpdates ?? dataSource?.access_groups ?? []}
            setSelectedAccessGroupIds={(newAccessGroupIds) => {
              setAccessGroupUpdates(newAccessGroupIds);
              setConnectionStatus(RD.Idle());
            }}
            updateConfig={updateConfig}
          />
        </div>
        {supportedDataSource ? (
          <>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>Database Type</div>
              <DataSourceButton
                disabled
                selected
                dataSourceName={dataSourceInfo?.sourceType}
                imgUrl={dataSourceInfo?.datasourceIconImg}
              />
            </div>

            <div className={styles.section}>
              <div className={styles.sectionHeader}>Credentials</div>
              <EnterCredentialsBody
                config={config}
                headerClassName={cx(
                  sprinkles({ marginBottom: 'sp3', marginTop: 'sp7' }),
                  styles.sectionHeader,
                )}
                selectedDataSource={supportedDataSource}
                updateConfig={updateConfig}
                userViewableCredentials={dataSource.user_viewable_credentials}
              />
            </div>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>Security</div>
              <SecurityConfigurationBody
                config={config}
                headerClassName={sprinkles({ heading: 'h4', marginBottom: 'sp2' })}
                selectedDataSource={supportedDataSource}
                updateConfig={updateConfig}
                userViewableCredentials={dataSource.user_viewable_credentials}
              />
            </div>
          </>
        ) : (
          <div>
            Error: A schema type must be selected for this data source before credentials can be
            edited.
          </div>
        )}
      </div>
    );
  };

  const shouldUpdate = useFido
    ? RD.isSuccess(fidoDataSourceConfig.testConnectionResponse)
    : RD.isSuccess(connectionStatus);

  return (
    <>
      <SideSheet
        breadcrumbs={[parentSchema?.name ?? String(dataSource.parent_schema_id)]}
        className={styles.sidesheet}
        isOpen={isOpen}
        onClickOutside={handleCloseAttempt}
        onCloseClick={handleCloseAttempt}
        primaryButtonProps={{
          onClick: onUpdate,
          disabled: !hasUpdates || !hasValidConfig,
          text: shouldUpdate ? 'Update' : 'Test',
          loading:
            RD.isLoading(
              useFido ? fidoDataSourceConfig.testConnectionResponse : connectionStatus,
            ) || isUpdateLoading,
        }}
        secondaryButtonProps={{ onClick: onResetChanges, disabled: !hasUpdates }}
        title={dataSource.name}>
        {renderManageDataSource()}
      </SideSheet>

      <AlertModal
        actionButtonProps={{
          text: 'Discard Changes',
          onClick: () => {
            onDiscard();
            setShowConfirmationModal(false);
            setIsOpen(false);
          },
        }}
        cancelButtonProps={{ text: 'Keep Editing' }}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Do you want to discard your changes?"
      />
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) setAwaitedJobs({});
          onComplete();
        }}
      />
    </>
  );
};

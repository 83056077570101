import { FC } from 'react';
import produce from 'immer';

import { ConfigSectionHeader } from '../configSectionHeader';
import { ColorPicker } from 'components/ColorPicker';
import { ColorPaletteControl } from '../ColorPaletteControl';
import { sprinkles } from 'components/ds';
import * as styles from './index.css';

import {
  GlobalStyleConfig,
  GlobalStyleDivergingPaletteConfig,
  GlobalStyleGradientPaletteConfig,
} from 'globalStyles/types';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

type DivergingHue = keyof GlobalStyleDivergingPaletteConfig;
type GradientHue = keyof GlobalStyleGradientPaletteConfig;

export const VisualizationsConfigSection: FC<Props> = (props) => {
  return (
    <>
      <ConfigSectionHeader className={sprinkles({ marginTop: 'sp5' })} title="Visualizations" />
      <CategoricalPalette {...props} />
      <DivergingPalette {...props} />
      <GradientPalette {...props} />
    </>
  );
};

const CategoricalPalette: FC<Props> = ({ styleConfig, updateConfig }) => {
  return (
    <>
      <div className={styles.paletteLabel}>Categorical palette</div>
      <div className={styles.gridContainer}>
        {Object.keys(styleConfig.visualizations.categoricalPalette).map((hueString) => {
          const hue = hueString as keyof typeof styleConfig.visualizations.categoricalPalette;
          return (
            <ColorPicker
              fill
              color={styleConfig.visualizations.categoricalPalette[hue]}
              key={hue}
              onClose={(newHue) => {
                const newConfig = produce(styleConfig, (draft) => {
                  draft.visualizations.categoricalPalette[hue] = newHue;
                });
                updateConfig(newConfig);
              }}
            />
          );
        })}
      </div>
    </>
  );
};

const DivergingPalette: FC<Props> = ({ styleConfig, updateConfig }) => {
  const hues: DivergingHue[] = ['hue1', 'hue2', 'hue3'];
  const getOnHueChanged = (key: DivergingHue) => {
    return (newHue: string) => {
      const newConfig = produce(styleConfig, (draft) => {
        draft.visualizations.divergingPalette[key] = newHue;
      });
      updateConfig(newConfig);
    };
  };

  return (
    <>
      <div className={styles.paletteLabel}>Diverging palette</div>
      <ColorPaletteControl
        hues={hues.map((hue) => styleConfig.visualizations.divergingPalette[hue])}
        onHuesChanged={hues.map(getOnHueChanged)}
      />
    </>
  );
};

const GradientPalette: FC<Props> = ({ styleConfig, updateConfig }) => {
  const hues: GradientHue[] = ['hue1', 'hue2'];
  const getOnHueChanged = (key: GradientHue) => {
    return (newHue: string) => {
      const newConfig = produce(styleConfig, (draft) => {
        draft.visualizations.gradientPalette[key] = newHue;
      });
      updateConfig(newConfig);
    };
  };

  return (
    <>
      <div className={styles.paletteLabel}>Gradient palette</div>
      <ColorPaletteControl
        hues={hues.map((hue) => styleConfig.visualizations.gradientPalette[hue])}
        onHuesChanged={hues.map(getOnHueChanged)}
      />
    </>
  );
};

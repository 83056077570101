import { Layout } from '@explo-tech/react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

import { DashboardLayoutThunk } from './types';
import { elemIdFromDropId } from 'utils/dashboardUtils';
import { OPERATION_TYPES } from 'constants/types';
import { setAddDataPanelState } from 'reducers/dashboardInteractionsReducer';
import { createDashboardElement } from 'actions/dashboardV2Actions';
import { EVENTS, trackEvent } from 'analytics/exploAnalytics';
import { DASHBOARD_ELEMENT_TYPES, VIEW_MODE } from 'types/dashboardTypes';
import {
  updateDashboardEmailLayout,
  updateDashboardMobileLayout,
  updateDashboardPdfLayout,
  updateDashboardLayout,
} from 'actions/layoutActions';

export const onDropThunk =
  (
    newLayout: Layout[],
    layoutItemId: string,
    containerId: string | undefined,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const requestInfo = getState().dashboardLayout.requestInfo;
    if (requestInfo.type !== 'app') return;

    const elemType = elemIdFromDropId(layoutItemId);
    if (elemType.indexOf('data-panel-') >= 0) {
      const opType = elemType.split('data-panel-')[1] as OPERATION_TYPES;
      dispatch(setAddDataPanelState({ layout: newLayout, containerId, opType }));
    } else {
      dispatch(
        createDashboardElement({
          id: `dash${requestInfo.resourceId}-${uuidv4()}`,
          elementType: elemType as DASHBOARD_ELEMENT_TYPES,
          newLayout,
          containerId,
        }),
      );
      trackEvent(EVENTS.ADDED_DASHBOARD_ELEMENT, {
        dashboard_template_id: requestInfo.resourceId,
        element_type: elemType,
      });
    }
  };

export const updateLayoutThunk =
  (newLayout: Layout[]): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const { isEditing, viewMode } = getState().dashboardInteractions.interactionsInfo;
    if (!isEditing) return;

    const updateFunc =
      viewMode === VIEW_MODE.MOBILE
        ? updateDashboardMobileLayout
        : viewMode === VIEW_MODE.PDF
        ? updateDashboardPdfLayout
        : viewMode === VIEW_MODE.EMAIL
        ? updateDashboardEmailLayout
        : updateDashboardLayout;
    dispatch(updateFunc(newLayout));
  };

import { forwardRef } from 'react';
import { motion, AnimatePresence, MotionValue } from 'framer-motion';

import * as styles from 'pages/ReportBuilder/ReportView/PanelHandle.css';
import { MotionProps } from 'framer-motion/types/motion/types';

export enum PanelSide {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

type Props = MotionProps & {
  side: PanelSide;
  isVisible: boolean;
  motionValue: MotionValue<number>;
  onStart?: () => void;
  onStop?: () => void;
};

export const PanelHandle = forwardRef<HTMLDivElement, Props>(function PanelHandle(
  { isVisible, side, motionValue, onStart, onStop, style, ...props },
  ref,
) {
  const startResizing = () => {
    // User's cursor may go off the handle itself so set the entire body's cursor so there's no flickering
    document.body.style.cursor = 'row-resize';
    onStart?.();
  };

  const stopResizing = () => {
    document.body.style.cursor = 'default';
    onStop?.();
  };

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          animate={{ opacity: 1 }}
          className={styles.handle({ side })}
          drag={side === PanelSide.Left || side === PanelSide.Right ? 'x' : 'y'}
          dragElastic={0.025}
          dragMomentum={false}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          onPanEnd={stopResizing}
          onPointerDown={startResizing}
          onPointerUp={stopResizing}
          onTap={stopResizing}
          ref={ref}
          style={{
            ...style,
            x: side === PanelSide.Left ? motionValue : side === PanelSide.Right ? motionValue : 0,
            y: side === PanelSide.Top ? motionValue : side === PanelSide.Bottom ? motionValue : 0,
          }}
          {...props}
        />
      ) : null}
    </AnimatePresence>
  );
});

import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { ResourceListPage } from 'shared/ExploResource/ResourceListPage';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { fetchDashboardAttributes } from 'actions/dashboardAttributesActions';
import { fetchEmailCadenceList } from 'actions/emailActions';
import { pageView } from 'analytics/exploAnalytics';
import { fetchDashboardList } from 'actions/dashboardActions';
import { ResourcePageType } from 'types/exploResource';

export default function HomeAppPage(): JSX.Element {
  const dispatch = useDispatch();

  const { currentUser, dashboardAttributes, dashboardListLoading, dashboardList, emailCadences } =
    useSelector(
      (state: ReduxState) => ({
        currentUser: state.currentUser,
        dashboardAttributes: state.dashboardAttributes.attributes,
        dashboardList: state.dashboard.dashboardList,
        emailCadences: state.emailCadence.cadences,

        dashboardListLoading: createLoadingSelector([ACTION.FETCH_DASHBOARD_LIST])(state),
      }),
      shallowEqual,
    );

  // on mount
  useEffect(() => {
    document.title = 'Explo | Home';
    pageView('Home App (Dashboards)');
  }, []);

  const teamId = currentUser.team?.id;

  // load dashboards
  useEffect(() => {
    if (dashboardList || !teamId) return;
    dispatch(fetchDashboardList({ id: teamId }));
  }, [dashboardList, teamId, dispatch]);

  // load email cadence list
  useEffect(() => {
    if (!RD.isIdle(emailCadences) || !teamId) return;
    dispatch(fetchEmailCadenceList({ id: teamId }));
  }, [emailCadences, teamId, dispatch]);

  // load dashboard attributes
  useEffect(() => {
    if (RD.isIdle(dashboardAttributes)) dispatch(fetchDashboardAttributes());
  }, [dashboardAttributes, dispatch]);

  return (
    <ResourceListPage
      dashboardAttributes={RD.getOrDefault(dashboardAttributes, [])}
      emailCadenceList={RD.getOrDefault(emailCadences, [])}
      pageType={ResourcePageType.EXPLORE}
      resources={dashboardList}
      resourcesLoading={dashboardListLoading}
    />
  );
}

import { useState } from 'react';
import { sortBy } from 'utils/standard';
import { useSelector, shallowEqual } from 'react-redux';

import { Poller } from 'components/JobQueue/Poller';
import { DeleteMenu } from 'components/resource/DeleteMenu';
import { Spinner, sprinkles, Tag } from 'components/ds';
import { EmbedButton, EmbedModalContent, EmbedModalFooter } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ScheduleManager.css';

import { EmailCadence } from 'types/emailCadence';
import {
  getEmailCadence,
  getEmailScheduleText,
} from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/utils';
import { Jobs } from 'components/JobQueue/types';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { EXPORT_TYPE_NAME_MAP, ExportType } from 'actions/exportActions';

type Props = {
  emails: EmailCadence[];
  closeModal: () => void;
  editEmail: (emailId: string) => void;
  deleteEmail: (emailId: string) => void;
  openScheduler: () => void;
  sendTestExport: (emailId: string, onSuccess: (jobs: Record<string, Jobs>) => void) => void;
  loading?: boolean;
};

export function ScheduleManager({
  loading,
  closeModal,
  editEmail,
  deleteEmail,
  emails,
  openScheduler,
  sendTestExport,
}: Props): JSX.Element {
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [loadingEmailId, setLoadingEmailId] = useState<string | undefined>();

  const { customerToken, jwt, supportEmail } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      jwt: state.embeddedReportBuilder.requestInfo.embedJwt,
      supportEmail: state.embeddedReportBuilder.team?.support_email,
    }),
    shallowEqual,
  );

  const hasJob = !!loadingEmailId || Object.keys(awaitedJobs).length > 0;

  const renderMenu = (emailId: string) => {
    return (
      <DeleteMenu
        deleteItem={() => deleteEmail(emailId)}
        disabled={hasJob}
        loading={hasJob && loadingEmailId === emailId}
        menuItems={[
          {
            icon: 'edit',
            name: 'Edit',
            onClick: () => editEmail(emailId),
          },
          {
            disabled: hasJob,
            icon: 'paper-plane',
            name: 'Send Test',
            onClick: () => {
              setLoadingEmailId(emailId);
              sendTestExport(emailId, setAwaitedJobs);
            },
          },
        ]}
      />
    );
  };

  return (
    <>
      <EmbedModalContent className={modalContentClassName} size="xlarge">
        <EmbedText body="b3" color="contentPrimary">
          Emails are sent from {supportEmail || DEFAULT_SUPPORT_EMAIL}. Remember to check your spam
          folder if you don&rsquo;t see it in your inbox.
        </EmbedText>
        {loading ? (
          <Spinner fillContainer />
        ) : emails.length === 0 ? (
          <EmbedText color="contentPrimary" heading="h4">
            No Emails Scheduled
          </EmbedText>
        ) : (
          <div className={styles.emailList}>
            {sortBy(emails, 'subject').map((email) => {
              const numRecipients = email.recipients.length;
              return (
                <div className={styles.email} key={email.id}>
                  <EmbedText className={styles.emailSubject} color="contentPrimary" heading="h4">
                    {email.subject}
                  </EmbedText>
                  <EmbedText
                    body="b2"
                    className={styles.emailRecipients}
                    color="contentSecondary">{`${numRecipients} recipient${
                    numRecipients > 1 ? 's' : ''
                  }`}</EmbedText>
                  <div className={styles.emailInfo}>
                    <Tag intent="active">
                      {EXPORT_TYPE_NAME_MAP[email.export_type || ExportType.CSV]}
                    </Tag>
                    <Tag intent="active">{getEmailCadence(email)}</Tag>
                    <EmbedText body="b2" color="contentSecondary">
                      {getEmailScheduleText(email)}
                    </EmbedText>
                  </div>
                  {renderMenu(email.id)}
                </div>
              );
            })}
          </div>
        )}
      </EmbedModalContent>
      <EmbedModalFooter>
        <EmbedButton icon="plus" onClick={openScheduler} variant="secondary">
          Schedule an Email
        </EmbedButton>
        <EmbedButton onClick={closeModal} variant="primary">
          Done
        </EmbedButton>
      </EmbedModalFooter>
      <Poller
        awaitedJobs={awaitedJobs}
        customerToken={customerToken}
        jwt={jwt}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) {
            setAwaitedJobs({});
            setLoadingEmailId(undefined);
          }
          onComplete();
        }}
      />
    </>
  );
}

const modalContentClassName = sprinkles({
  overflow: 'auto',
  paddingX: 'sp3',
  flexItems: 'column',
  gap: 'sp1',
});

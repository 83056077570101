import { createAsyncThunk } from '@reduxjs/toolkit';
import * as RD from 'remotedata';
import { cloneDeep } from 'utils/standard';

import {
  ViewResponse,
  ViewResourceService,
  ListViewsResponse,
  ComputedView,
  ListViewsResourceService,
  ListNamespacesResponse,
  NamespaceResourceService,
  QueryExecutionResponse,
  TablePreviewRequest,
  QueryResourceService,
  NamespaceResponse,
  DataSourceResourceService,
} from '@explo-tech/fido-api';

import { FidoRequestFn, makeFidoThunkRequest } from 'utils/thunkUtils';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { ParentSchema } from 'actions/dataSourceActions';
import { ComputedViewWithIds, NamespaceWithIds } from 'utils/fido/fidoUtils';
import { ResourcePageType } from 'types/exploResource';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { CustomerPermissionsForObject } from 'types/permissionTypes';

export const getNamespaces = createAsyncThunk<ListNamespacesResponse, void, { state: ReduxState }>(
  ACTION.FETCH_NAMESPACES,
  async (_: void, { getState }) =>
    makeFidoThunkRequest(
      () => NamespaceResourceService.getNamespaces(true, true),
      getState().fido.fidoToken ?? '',
      'Error fetching namespaces with data sources',
    ),
  {
    condition: (_, { getState }) => {
      const { fido, currentUser } = getState();

      const useFido = currentUser.team?.feature_flags.use_fido;
      return (
        useFido &&
        RD.isIdle(fido.fidoDaos) &&
        fido.embeddoDaos.dataSources !== undefined &&
        fido.embeddoDaos.usedParentSchemas !== undefined
      );
    },
  },
);

export const updateNamespace = createAsyncThunk<
  NamespaceResponse,
  { namespace: NamespaceWithIds; onSuccess?: () => void },
  { state: ReduxState }
>(ACTION.UPDATE_NAMESPACE, async ({ namespace, onSuccess }, { getState }) =>
  makeFidoThunkRequest(
    () =>
      NamespaceResourceService.updateNamespace(namespace.id, {
        namespace: namespace,
      }),
    getState().fido.fidoToken ?? '',
    'Error updating namespace',
    onSuccess,
  ),
);

export const deleteNamespace = createAsyncThunk<{}, { namespaceId: string }, { state: ReduxState }>(
  ACTION.DELETE_NAMESPACE,
  async ({ namespaceId }, { getState }) =>
    makeFidoThunkRequest(
      () => NamespaceResourceService.deleteNamespace(namespaceId),
      getState().fido.fidoToken ?? '',
      'Error deleting namespace',
    ),
);

export const deleteDataSourceInFido = createAsyncThunk<
  {},
  { namespaceId: string; dataSourceId: string },
  { state: ReduxState }
>(ACTION.DELETE_DATA_SOURCE_IN_FIDO, async ({ namespaceId, dataSourceId }, { getState }) =>
  makeFidoThunkRequest(
    () => DataSourceResourceService.deleteDataSource(dataSourceId, namespaceId),
    getState().fido.fidoToken ?? '',
    'Error deleting dataSource',
  ),
);

/**
 * Fetches a table preview for a data source under a namespace.
 */
export const fetchFidoTablePreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    dataSourceId: string;
    namespaceId: string;
    body: TablePreviewRequest;
    onSuccess?: (data: QueryExecutionResponse) => void;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_FIDO_TABLE_VIEW_PREVIEW,
  async ({ dataSourceId, namespaceId, body, onSuccess }, { getState }) => {
    const state = getState();

    return makeFidoThunkRequest(
      () => QueryResourceService.getTablePreview(dataSourceId, namespaceId, body),
      state.fido.fidoToken ?? '',
      'Error loading table preview',
      onSuccess,
    );
  },
);

export const getComputedViews = createAsyncThunk<
  ListViewsResponse,
  Array<string>,
  { state: ReduxState }
>(
  ACTION.FETCH_COMPUTED_VIEWS,
  async (viewIds, { getState }) => {
    const fido = getState().fido;

    return makeFidoThunkRequest(
      () => ListViewsResourceService.getViews({ viewIds }),
      fido.fidoToken ?? '',
      'Error fetching computed views',
    );
  },
  {
    condition: (viewIds, { getState }) => {
      const {
        fido: { computedViews },
      } = getState();

      if (viewIds.length === 0) return false;

      // do fetch if we haven't tried to fetch yet
      if (RD.isIdle(computedViews)) return true;

      // don't fetch if we errored fetching
      if (!RD.isSuccess(computedViews)) return false;

      // if data is there, only fetch if we're requesting a new id
      return !computedViews.data.every((view) => viewIds.includes(view.id));
    },
  },
);

export const createComputedView = createAsyncThunk<
  ViewResponse,
  {
    name: string;
    namespace: ParentSchema | undefined;
    resourceType?: ResourcePageType;
    datasetId?: string;
    onSuccess?: () => void;
  },
  { state: ReduxState }
>(ACTION.CREATE_COMPUTED_VIEW, async ({ name, namespace, onSuccess }, { getState }) => {
  const fido = getState().fido;
  // TODO FIDO figure this out...
  // @ts-ignore
  const view: ComputedView = {
    ['@type']: 'computed-view',
    query: '',
    name,
  };

  let request: FidoRequestFn<ViewResponse> = null;

  if (namespace?.fido_id) {
    request = () =>
      ViewResourceService.createView(namespace?.fido_id ?? '', {
        view,
      });
  }

  return makeFidoThunkRequest(request, fido.fidoToken ?? '', 'Error creating your view', onSuccess);
});

type SaveComputedViewPayload = ComputedViewWithIds & {
  permissions?: CustomerPermissionsForObject; // This is passed to Report Builder reducer and not saved to FIDO
};

export const saveComputedView = createAsyncThunk<
  ViewResponse,
  SaveComputedViewPayload,
  { state: ReduxState }
>(ACTION.SAVE_COMPUTED_VIEW, async (view, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => ViewResourceService.updateView(view.id, view.namespaceId, { view }),
    fido.fidoToken ?? '',
    'Error saving your view',
  );
});

export const deleteComputedView = createAsyncThunk<
  unknown,
  { viewId: string; namespaceId: string },
  { state: ReduxState }
>(ACTION.DELETE_COMPUTED_VIEW, async ({ viewId, namespaceId }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => ViewResourceService.deleteView(viewId, namespaceId),
    fido.fidoToken ?? '',
    'Error deleting your view',
  );
});

export const cloneComputedViews = createAsyncThunk<
  { views: ViewResponse[] },
  {
    configuration: DashboardVersionConfig | ReportBuilderConfig;
    onSuccess: (configuration: DashboardVersionConfig | ReportBuilderConfig) => void;
  },
  { state: ReduxState }
>(ACTION.CLONE_COMPUTED_VIEW, async ({ configuration, onSuccess }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () =>
      ListViewsResourceService.cloneViews({
        viewIds: Object.values(configuration.datasets).map((d) => d.fido_id ?? ''),
      }),
    fido.fidoToken ?? '',
    'Error duplicating your views',
    ({ views }) => {
      const newConfig = cloneDeep(configuration);

      Object.values(newConfig.datasets).forEach((d) => {
        const view = views.find(
          (v) => v.view.name === d.table_name || v.view.name === d.name,
        )?.view;

        if (!view?.id) return;

        d.fido_id = view.id;
      });

      onSuccess(newConfig);
    },
  );
});

import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'utils/standard';

import { ReportSaveStatus } from 'pages/ReportBuilder/ReportSaveStatus';
import { ReportName } from 'pages/ReportBuilder/ReportName';
import { ModalViews } from 'pages/ReportBuilder/ModalViews';
import { Intent, Tag, sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed/EmbedButton';
import { StarStatus } from '../StarStatus';
import { ExportButton } from './ExportButton';
import { SaveButton } from './SaveButton';
import { ViewTabs } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs';
import { ReportAIButton } from 'pages/ReportBuilder/ReportView/ReportAIButton';
import { ReportDescription } from './ReportDescription';
import * as styles from './index.css';

import {
  ReportModalType,
  SelectedReportType,
  ReportType,
} from 'reportBuilderContent/reducers/types';
import { getReportName } from 'utils/customerReportUtils';
import { CustomerReportConfig, updateCustomerReportName } from 'actions/customerReportActions';
import {
  clearSelectedReport,
  openDataModal,
  openModalType,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

type Props = {
  savedConfig: CustomerReportConfig;
  rawReportName: string;
  isStarred: boolean;
  selectedReport: SelectedReportType;
  description?: string;
  isIframe: boolean;
};

export const ReportBuilderReportView: FC<Props> = ({
  savedConfig,
  rawReportName,
  isStarred,
  selectedReport,
  description,
  isIframe,
}) => {
  const dispatch = useDispatch();

  const { customerToken, selectedTab, currentConfig, isPreview, jwt } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      jwt: state.embeddedReportBuilder.requestInfo.embedJwt,
      selectedTab: state.embeddedReportBuilder.selectedTab,
      currentConfig: state.reportEditing.currentConfig,
      isPreview: state.embeddedReportBuilder.isPreview,
    }),
    shallowEqual,
  );

  const hasUnsavedChanges = useMemo(
    () => !isEqual(currentConfig, savedConfig),
    [currentConfig, savedConfig],
  );

  if (!currentConfig || selectedReport.type === ReportType.EDIT_BUILT_IN) return null;

  const isBuiltIn = selectedReport.type === ReportType.BUILT_IN;
  const reportName = getReportName(rawReportName);

  // If there are unsaved changes, prompt the user to confirm before leaving
  const handleBack = () => {
    if (isPreview || !hasUnsavedChanges) dispatch(clearSelectedReport());
    else
      dispatch(
        openModalType(
          isBuiltIn
            ? { type: ReportModalType.CONFIRM_CLOSE_BUILT_IN, builtInId: selectedReport.id }
            : { type: ReportModalType.CONFIRM_CLOSE_REPORT, reportId: selectedReport.id },
        ),
      );
  };

  const saveButton = isBuiltIn ? (
    <EmbedButton
      disabled={isPreview}
      onClick={() =>
        dispatch(
          openModalType({
            type: ReportModalType.SAVE_BUILT_IN_AS_REPORT,
            builtInId: selectedReport.id,
          }),
        )
      }
      tooltipProps={{ text: 'Save to a new Report' }}
      variant="secondary">
      Save As
    </EmbedButton>
  ) : (
    <SaveButton
      currentConfig={currentConfig}
      hasUnsavedChanges={hasUnsavedChanges}
      reportId={selectedReport.id}
    />
  );

  return (
    <>
      <div className={styles.headerContainer}>
        <EmbedButton
          className={sprinkles({ paddingY: 'sp1', marginX: 'sp2' })}
          icon="arrow-left"
          onClick={handleBack}
          variant="tertiary">
          {selectedTab}
        </EmbedButton>
        <div className={styles.header}>
          <div
            className={sprinkles({ flexItems: 'column', gap: 'sp1', overflow: 'hidden', flex: 1 })}>
            <div className={styles.headerName}>
              <StarStatus isStarred={isStarred} reportInfo={selectedReport} />
              <ReportName
                disabled={isBuiltIn || isPreview}
                onSubmit={(value) => {
                  if (isBuiltIn || isPreview) return;
                  dispatch(
                    updateCustomerReportName({
                      customerToken,
                      jwt,
                      postData: { report_id: selectedReport.id, name: value },
                    }),
                  );
                }}
                value={reportName}
              />
              {isBuiltIn ? (
                <Tag className={sprinkles({ whiteSpace: 'nowrap' })} intent={Intent.ACTIVE}>
                  Built In Report
                </Tag>
              ) : null}
              <ReportSaveStatus isSaved={!hasUnsavedChanges} />
            </div>
            {description?.trim() ? <ReportDescription description={description} /> : null}
          </div>
          <div className={styles.headerButtons}>
            <ReportAIButton datasetId={currentConfig.dataInfo?.datasetId} />
            <EmbedButton
              icon="pencil"
              onClick={() => dispatch(openDataModal())}
              variant="secondary">
              Edit data
            </EmbedButton>
            {saveButton}
            <ExportButton exportDisabled={!currentConfig.dataInfo || isPreview} />
          </div>
        </div>
      </div>
      <ViewTabs
        dataInfo={currentConfig.dataInfo}
        savedViews={savedConfig.views || []}
        views={currentConfig.views || []}
      />
      <ModalViews
        dataInfo={currentConfig.dataInfo}
        isIframe={isIframe}
        reportName={rawReportName}
      />
    </>
  );
};

import { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Settings } from 'luxon';
import { isEqual } from 'utils/standard';
import cx from 'classnames';
import { Layout } from '@explo-tech/react-grid-layout';

import { DashboardStickyHeader } from './DashboardStickyHeader';
import { DashboardLayoutPoller } from './DashboardLayoutPoller';
import { DashboardBody } from './DashboardBody';
import { sprinkles } from 'components/ds';

import { GlobalStylesContext } from 'globalStyles';
import { DashboardElement, DashboardVariableMap, VIEW_MODE, PAGE_TYPE } from 'types/dashboardTypes';
import { ArchetypeProperty, Customer, EmbedCustomer } from 'actions/teamActions';
import DashboardLayoutContext from './DashboardLayoutContext';
import { getCustomerVariables } from 'utils/customerUtils';
import * as variableUtils from 'utils/variableUtils';
import * as dashboardUtils from 'utils/dashboardUtils';
import { ResourceDataset, DataPanel } from 'types/exploResource';
import { EXPLO_SVG_LOGO } from 'constants/iconConstants';
import { DrilldownModal } from 'pages/dashboardPage/charts/DrilldownModal';
import { DashboardPageLayoutConfig, DashboardParam } from 'types/dashboardVersionConfig';
import {
  DashboardLayoutRequestInfo,
  clearDashboardLayoutReducer,
  getIsIframe,
  setRequestInfo,
} from 'reducers/dashboardLayoutReducer';
import { createDebouncedFn } from 'utils/general';
import { DashboardStates } from 'reducers/rootReducer';
import { setDashboardVariables, updateDashboardVariables } from 'reducers/dashboardDataReducer';
import {
  fetchDashboardDataThunk,
  initializeDashboardDataThunk,
  onNewDataPanelsAddedThunk,
} from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { handleCustomerChangeThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { fetchDatasetsThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';
import { getDefaultVariablesFromParams } from 'utils/paramUtils';
import * as styles from './styles.css';
import { sendDashboardLoadedEvent } from 'utils/customEventUtils';

const MINIMUM_MINUTES_FOR_REFRESH = 0.25; // 15 Seconds

const debounceFn = createDebouncedFn(500);

type PassedProps = {
  archetypeProperties: ArchetypeProperty[] | undefined;
  datasets: Record<string, ResourceDataset>;
  dashboardElements: DashboardElement[];
  dashboardLayout: Layout[];
  dataPanels: DataPanel[];
  params?: Record<string, DashboardParam>;
  resourceId: number;
  isEditableSectionEnabled?: boolean;
  isViewOnly: boolean;
  isVisible?: boolean;
  pageLayoutConfig?: DashboardPageLayoutConfig;
  pageType: PAGE_TYPE;
  refreshMinutes?: number;
  requestInfo: DashboardLayoutRequestInfo;
  showExploBranding?: boolean;
  customer: Customer | EmbedCustomer | undefined;
  variablesDefaultValues?: DashboardVariableMap;
  width?: number | null;
};

type Props = PassedProps & PropsFromRedux;

type State = {
  intervalId?: number;
  /*
   * When switching quickly between dashboards constructor of next dashboard
   * is called before componentWillUnmount of initial dashboard. Keeping this
   * status allows us to make sure that the initial request is made after first
   * component has unmounted and next one has set necessary information
   */
  hasInitialRequestBeenMade: boolean;
};

class DashboardLayout extends Component<Props, State> {
  state: State = {
    hasInitialRequestBeenMade: false,
  };

  constructor(props: Props) {
    super(props);

    // the luxon default timezone is local, set this to UTC just so everything's
    // standard. In reality, dates are handled by the backend so this shouldn't
    // matter
    Settings.defaultZone = 'UTC';
  }

  componentDidMount() {
    const {
      dashboardElements,
      dataPanels,
      variablesDefaultValues,
      customer,
      setDashboardVariables,
      refreshMinutes,
      requestInfo,
      params,
      archetypeProperties,
    } = this.props;

    this.props.setRequestInfo(requestInfo);

    let elemDefaultVars = variableUtils.getDefaultVariablesFromDashElements(
      dashboardElements,
      requestInfo.timezone,
      variablesDefaultValues,
    );

    const archetypePropertySet = new Set(archetypeProperties?.map((prop) => prop.name));

    elemDefaultVars = {
      ...(params ? getDefaultVariablesFromParams(params) : undefined),
      ...elemDefaultVars,
      ...variablesDefaultValues,
      ...variableUtils.initializeDpColorCategoryDropdownVariables(dataPanels),
      ...(customer ? getCustomerVariables(customer, archetypePropertySet) : undefined),
    };

    setDashboardVariables(elemDefaultVars);

    if (refreshMinutes && refreshMinutes >= MINIMUM_MINUTES_FOR_REFRESH) {
      this.setState({
        intervalId: window.setInterval(
          this.refreshDashboard.bind(this),
          // convert minutes to milliseconds
          refreshMinutes * 60 * 1000,
        ),
      });
    }

    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.requestInfo !== this.props.requestInfo) {
      this.props.setRequestInfo(this.props.requestInfo);
    }

    if (!this.state.hasInitialRequestBeenMade) {
      if (this.props.variables === null) return;
      this.props.initializeDashboardDataThunk(this.props.dashboardElements, this.props.datasets);
      this.setState({ hasInitialRequestBeenMade: true });
      return;
    }

    const versionNumberChanged =
      prevProps.requestInfo.versionNumber !== this.props.requestInfo.versionNumber;
    const timezoneChanged = prevProps.requestInfo.timezone !== this.props.requestInfo.timezone;
    const customerChanged = prevProps.customer?.id !== this.props.customer?.id;
    const useFidoChanged = prevProps.requestInfo.useFido !== this.props.requestInfo.useFido;

    if (versionNumberChanged || timezoneChanged || customerChanged || useFidoChanged) {
      if (customerChanged && this.props.customer) {
        this.props.handleCustomerChangeThunk(
          this.props.dashboardElements,
          this.props.datasets,
          this.props.customer,
        );
      } else {
        this.props.initializeDashboardDataThunk(this.props.dashboardElements, this.props.datasets);
      }
      return;
    }

    if (
      prevProps.isVisible !== this.props.isVisible &&
      this.props.refreshMinutes &&
      this.props.refreshMinutes >= MINIMUM_MINUTES_FOR_REFRESH
    ) {
      if (this.props.isVisible) {
        // if the tab is refocused on, re-set the refresh interval
        this.setState({
          intervalId: window.setInterval(
            this.refreshDashboard.bind(this),
            // convert minutes to milliseconds
            this.props.refreshMinutes * 60 * 1000,
          ),
        });
      } else {
        // stop fetching dashboard data if tab is closed to prevent
        // browser performance issues
        if (this.state.intervalId) clearInterval(this.state.intervalId);
      }
    }

    if (
      this.props.variablesDefaultValues &&
      this.props.variablesDefaultValues !== prevProps.variablesDefaultValues &&
      !isEqual(prevProps.variablesDefaultValues, this.props.variablesDefaultValues)
    ) {
      this.props.updateDashboardVariables(this.props.variablesDefaultValues);
    }

    if (prevProps.width !== this.props.width) {
      // Trigger resize event for react-grid-layout if container size changes
      debounceFn(() => window.dispatchEvent(new Event('resize')));
    }

    const newDataPanels = dashboardUtils.dataPanelsAdded(
      prevProps.dataPanels,
      this.props.dataPanels,
    );

    if (newDataPanels.length) this.props.onNewDataPanelsAddedThunk(newDataPanels);

    const newElems = dashboardUtils.dashboardElementsAdded(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );
    if (newElems.length) {
      const defaultElemValues: DashboardVariableMap = {};
      newElems.forEach((newElem) => {
        const defaultValueForElem = dashboardUtils.getDefaultValueForNewElem(newElem);
        if (!defaultValueForElem) return;
        defaultElemValues[newElem.name] = defaultValueForElem;
      });

      this.props.updateDashboardVariables(defaultElemValues);
    }

    const newDatasetIds = dashboardUtils.datasetsChanged(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );
    if (newDatasetIds.length) this.props.fetchDatasetsThunk(newDatasetIds, this.props.datasets);

    if (!prevProps.dashboardLoaded && this.props.dashboardLoaded) {
      window.DD_RUM?.addTiming('dashboard_loaded');
      sendDashboardLoadedEvent(this.props.isIframe);
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    document.removeEventListener('keydown', this.handleKeyDown);

    if (intervalId) clearInterval(intervalId);
    this.props.clearDashboardLayoutReducer();
  }

  refreshDashboard = () => this.props.fetchDashboardDataThunk({});

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key !== 'Escape') return;
    this.props.clearSelectedItemThunk();
  };

  render() {
    const {
      datasets,
      dashboardElements,
      resourceId,
      dataPanels,
      dashboardLayout,
      isViewOnly,
      pageLayoutConfig,
      pageType,
      customer,
      clearSelectedItemThunk,
      viewMode,
      variables,
      dashboardLayoutTagId,
      dashboardLoaded,
      isEditableSectionEnabled,
    } = this.props;

    return (
      <DashboardLayoutContext.Provider value={{ dashboardLayoutTagId }}>
        <div
          className={cx(rootStyle, {
            [sprinkles({ overflowY: 'visible' })]: viewMode === VIEW_MODE.PDF && !isViewOnly,
            [sprinkles({ overflowY: 'auto' })]: viewMode !== VIEW_MODE.PDF || isViewOnly,
            [cx(sprinkles({ height: 'auto' }), styles.emailView)]: viewMode === VIEW_MODE.EMAIL,
            [sprinkles({ height: 'fill' })]: viewMode !== VIEW_MODE.EMAIL,
            [styles.mobileEditor]: viewMode === VIEW_MODE.MOBILE && !isViewOnly,
            'explo-dashboard-loaded': dashboardLoaded,
          })}
          id={dashboardLayoutTagId}
          // Added to force a refresh and subsequent resizing of all charts when dashboard changes
          key={`${resourceId}${dashboardLayoutTagId}`}
          onClick={() => clearSelectedItemThunk()}
          style={this.context.globalStyleVars}>
          {viewMode === VIEW_MODE.PDF || viewMode === VIEW_MODE.EMAIL ? null : (
            <DashboardStickyHeader
              config={pageLayoutConfig?.stickyHeader}
              dashboardElements={dashboardElements}
              datasets={datasets}
              variables={variables ?? {}}
              viewMode={viewMode}
            />
          )}
          <DashboardBody
            customer={customer}
            dashboardElements={dashboardElements}
            dashboardLayout={dashboardLayout}
            dataPanels={dataPanels}
            datasets={datasets}
            globalStyleConfig={this.context.globalStyleConfig}
            isDemoCustomer={customer?.is_demo_group ?? false}
            isEditableSectionEnabled={isEditableSectionEnabled}
            isViewOnly={isViewOnly}
            pageType={pageType}
            variables={variables ?? {}}
            viewMode={viewMode}
          />
          {this.renderExploBranding()}
          <DrilldownModal
            dataPanels={dataPanels}
            datasets={datasets}
            pageType={pageType}
            variables={variables ?? {}}
          />
        </div>
        <DashboardLayoutPoller />
      </DashboardLayoutContext.Provider>
    );
  }

  renderExploBranding = () => {
    const { showExploBranding } = this.props;
    if (!showExploBranding) return null;

    return (
      <a
        className={exploBrandButton}
        href="https://www.explo.co/"
        rel="noopener noreferrer"
        target="_blank">
        {EXPLO_SVG_LOGO(18)}
        <span className={sprinkles({ marginLeft: 'sp1' })}>Powered by Explo</span>
      </a>
    );
  };
}

DashboardLayout.contextType = GlobalStylesContext;

const mapStateToProps = (state: DashboardStates) => ({
  viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
  variables: state.dashboardData.variables,
  dashboardLayoutTagId: state.dashboardLayout.layoutId,
  dashboardLoaded: state.dashboardData.dashboardLoaded,
  isIframe: getIsIframe(state.dashboardLayout),
});

const mapDispatchToProps = {
  clearDashboardLayoutReducer,
  setRequestInfo,
  setDashboardVariables,
  initializeDashboardDataThunk,
  fetchDashboardDataThunk,
  handleCustomerChangeThunk,
  updateDashboardVariables,
  onNewDataPanelsAddedThunk,
  fetchDatasetsThunk,
  clearSelectedItemThunk,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

// Using this allows correct typing of thunk props
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DashboardLayout);

const rootStyle = sprinkles({
  width: 'fill',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const exploBrandButton = sprinkles({
  backgroundColor: { default: 'white', hover: 'gray1' },
  borderRadius: 8,
  bottom: 'sp1.5',
  color: 'gray12',
  flexItems: 'alignCenter',
  fontWeight: 500,
  height: 32,
  paddingX: 'sp1.5',
  position: 'absolute',
  right: 'sp1.5',
  textDecoration: { hover: 'none' },
});

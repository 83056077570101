import { useEffect, useState, FC } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { ColorPickerButton } from 'components/ColorPickerButton';
import { AlertModal, Spinner, sprinkles, Button, IconButton, Input } from 'components/ds';
import * as styles from './styles.css';

import { DashboardAttribute, DashboardAttributeValue } from 'actions/teamActions';
import {
  createDashboardAttribute,
  deleteDashboardAttribute,
  createAttributeValue,
  deleteAttributeValue,
  fetchDashboardAttributes,
} from 'actions/dashboardAttributesActions';

type NewValue = { attrId: number; value: string; colorHex: string };

type DeleteModal =
  | { type: 'attribute'; attr: DashboardAttribute }
  | { type: 'value'; value: DashboardAttributeValue; attrId: number };

export const SettingsAttributesSection: FC = () => {
  const dispatch = useDispatch();

  const attributes = useSelector((state: ReduxState) => state.dashboardAttributes.attributes);

  useEffect(() => {
    if (RD.isIdle(attributes)) dispatch(fetchDashboardAttributes());
  }, [attributes, dispatch]);

  const [newAttribute, setNewAttribute] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<NewValue | null>(null);
  const [deleteModal, setDeleteModal] = useState<DeleteModal | null>(null);

  const createNewAttribute = (attrList: DashboardAttribute[], attrName: string) => {
    const disabled = attrName.trim() === '' || attrList.some((attr) => attr.name === attrName);
    const onSubmit = () => {
      if (disabled) return;
      dispatch(
        createDashboardAttribute({ postData: { name: attrName.trim() } }, () =>
          setNewAttribute(null),
        ),
      );
    };
    return (
      <div className={attributesContainerClass}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <Input
            autoFocus
            className={sprinkles({ flex: 1 })}
            onChange={setNewAttribute}
            onEnter={onSubmit}
            placeholder="Attribute Name (ex: Status)"
            value={attrName}
          />
          <IconButton name="cross" onClick={() => setNewAttribute(null)} variant="secondary" />
          <IconButton disabled={disabled} name="tick" onClick={onSubmit} variant="primary" />
        </div>
      </div>
    );
  };

  const createNewValue = (
    attributeId: number,
    values: DashboardAttributeValue[],
    newValue: NewValue,
  ) => {
    const { value, colorHex } = newValue;
    const disabled = value.trim() === '' || values.some((val) => val.value === value);
    const onSubmit = () => {
      if (disabled) return;
      dispatch(
        createAttributeValue(
          {
            postData: {
              value: value.trim(),
              color_hex: colorHex,
              attribute_id: attributeId,
            },
          },
          () => setNewValue(null),
        ),
      );
    };
    return (
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', paddingBottom: 'sp1.5' })}>
        <ColorPickerButton
          btnClassName={styles.colorPickerButton}
          className={styles.colorPickerWrapper}
          color={colorHex}
          colorPalette={[]}
          onColorChange={(newHex) => setNewValue({ ...newValue, colorHex: newHex })}
          size={32}
        />
        <Input
          autoFocus
          className={sprinkles({ flex: 1 })}
          onChange={(value) => setNewValue({ ...newValue, value })}
          onEnter={onSubmit}
          placeholder="Value"
          value={value}
        />
        <IconButton name="cross" onClick={() => setNewValue(null)} variant="secondary" />
        <IconButton disabled={disabled} name="tick" onClick={onSubmit} variant="primary" />
      </div>
    );
  };

  const viewAttribute = (attribute: DashboardAttribute) => {
    const { name, values, id } = attribute;
    const newValue_ = newValue?.attrId === id ? newValue : null;
    const hasValues = values.length > 0 || newValue_ !== null;
    return (
      <div className={attributesContainerClass} key={`attr-${name}`}>
        <div className={styles.attributeRow}>
          <div className={attributeBoxClass}>{name}</div>
          <IconButton
            name="trash"
            onClick={() => setDeleteModal({ type: 'attribute', attr: attribute })}
            variant="destructive"
          />
        </div>
        {hasValues &&
          values.map((value) => {
            return (
              <div className={styles.attributeRow} key={`${name}-${value.value}`}>
                <div className={styles.colorSquare} style={{ backgroundColor: value.color_hex }} />
                <div className={attributeBoxClass}>{value.value}</div>
                <Button
                  icon="cross"
                  onClick={() => setDeleteModal({ type: 'value', value, attrId: id })}
                  variant="destructive"
                />
              </div>
            );
          })}
        {newValue_ && createNewValue(attribute.id, values, newValue_)}
        <Button
          className={sprinkles({ marginLeft: hasValues ? 'sp5' : undefined })}
          disabled={newValue_ !== null}
          icon="plus"
          onClick={() => {
            setNewAttribute(null);
            setNewValue({ attrId: id, value: '', colorHex: '#7ACC39' });
          }}
          variant="secondary">
          {hasValues ? 'Add another value' : 'Add a value'}
        </Button>
      </div>
    );
  };

  const viewDeleteModal = (modalInfo: DeleteModal) => {
    let title = 'Are you sure you want to delete the ';
    let confirmText = 'Delete ';
    let onConfirm: () => void;
    let bodyText = '';
    if (modalInfo.type === 'attribute') {
      title += `${modalInfo.attr.name} attribute?`;
      confirmText += `${modalInfo.attr.name} attribute`;
      bodyText =
        'If this attribute is deleted then the dashboards will no longer be linked to the values you’ve assigned. These changes will happen immediately.';
      onConfirm = () => {
        dispatch(deleteDashboardAttribute({ id: modalInfo.attr.id }));
        setDeleteModal(null);
      };
    } else {
      title += `${modalInfo.value.value} value?`;
      confirmText += `${modalInfo.value.value} value`;
      bodyText = `This value is assigned to your dashboards, if it’s deleted then the dashboards will no longer have any value assigned for ${modalInfo.value.value}.`;
      onConfirm = () => {
        dispatch(
          deleteAttributeValue({ postData: { id: modalInfo.value.id, attrId: modalInfo.attrId } }),
        );
        setDeleteModal(null);
      };
    }
    return (
      <AlertModal
        isOpen
        actionButtonProps={{ text: confirmText, onClick: onConfirm }}
        onClose={() => setDeleteModal(null)}
        title={title}>
        {bodyText}
      </AlertModal>
    );
  };

  return (
    <div>
      <div className={sprinkles({ heading: 'h2', marginBottom: 'sp1' })}>Attributes</div>
      <div className={sprinkles({ marginBottom: 'sp3', body: 'b2', color: 'gray11' })}>
        Attributes are customizable tags that you can use to differentiate your dashboards, these
        attributes can then be referenced programmatically.
      </div>
      {!RD.isSuccess(attributes) ? (
        <Spinner />
      ) : (
        <>
          {attributes.data.length ? attributes.data.map(viewAttribute) : null}
          {newAttribute !== null ? createNewAttribute(attributes.data, newAttribute) : null}
          <Button
            disabled={newAttribute !== null}
            icon="plus"
            onClick={() => {
              setNewValue(null);
              setNewAttribute('');
            }}
            variant="primary">
            Add an attribute
          </Button>
          {deleteModal ? viewDeleteModal(deleteModal) : null}
        </>
      )}
    </div>
  );
};

const attributesContainerClass = sprinkles({
  backgroundColor: 'gray2',
  borderRadius: 4,
  padding: 'sp1.5',
  marginBottom: 'sp2',
  flexItems: 'column',
  gap: 'sp1.5',
});

const attributeBoxClass = sprinkles({
  borderRadius: 4,
  paddingX: 'sp1.5',
  flexItems: 'alignCenter',
  flex: 1,
  backgroundColor: 'white',
  border: 1,
  borderColor: 'outline',
  height: 'fill',
});

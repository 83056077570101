import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import {
  EmbedSpinner,
  EmbedButton,
  EmbedModal,
  EmbedModalHeader,
  EmbedModalContent,
} from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { PanelError } from 'pages/dashboardPage/DashboardDatasetView/PanelError';

import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesProvider } from 'globalStyles';
import { GlobalStylesContext } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { ReduxState } from 'reducers/rootReducer';
import { ComponentSection } from 'reducers/styleConfigReducer';

type Props = {
  styleConfig: GlobalStyleConfig;
};

export const ComponentPreview: FC<Props> = ({ styleConfig }) => (
  <GlobalStylesProvider globalStyleConfig={styleConfig}>
    {(globalStylesClassName) => (
      <ComponentPreviewWrapper globalStylesClassName={globalStylesClassName} />
    )}
  </GlobalStylesProvider>
);

type WrapperProps = {
  globalStylesClassName: string;
};

const ComponentPreviewWrapper: FC<WrapperProps> = ({ globalStylesClassName }) => {
  const { globalStyleVars } = useContext(GlobalStylesContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openComponentSection = useSelector(
    (state: ReduxState) => state.styleConfig.openComponentSection,
  );

  useEffect(() => {
    setIsModalOpen(false);
  }, [openComponentSection]);

  const renderComponentSection = (openSection: ComponentSection) => {
    switch (openSection) {
      case ComponentSection.Spinner:
        return <EmbedSpinner fillContainer />;
      case ComponentSection.Button:
        return (
          <>
            <EmbedButton variant="primary">Primary Button</EmbedButton>
            <EmbedButton variant="secondary">Secondary Button</EmbedButton>
            <EmbedButton icon="report-builder-number" variant="tertiary" />
          </>
        );
      case ComponentSection.PanelError:
        return <PanelError description="description" title="error message" />;
      case ComponentSection.Modal:
        return (
          <>
            <EmbedButton onClick={() => setIsModalOpen(true)} variant="primary">
              Open Modal
            </EmbedButton>
            <EmbedModal
              isIframe={false}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              size="small">
              <EmbedModalHeader title="Check Modal Overlay" />
              <EmbedModalContent
                className={sprinkles({ paddingX: 'sp3', paddingBottom: 'sp2' })}
                size="small">
                <EmbedText body="b2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. At urna condimentum mattis
                  pellentesque id nibh. Consequat ac felis donec et odio pellentesque.
                </EmbedText>
              </EmbedModalContent>
            </EmbedModal>
          </>
        );
    }
  };

  return (
    <div
      className={cx(
        globalStylesClassName,
        rootClass,
        embedSprinkles({ backgroundColor: 'background' }),
      )}
      style={globalStyleVars}>
      {openComponentSection ? (
        <div className={chartClassName}>{renderComponentSection(openComponentSection)}</div>
      ) : null}
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'centerColumn',
  parentContainer: 'fill',
  gap: 'sp4',
  padding: 'sp8',
  backgroundColor: 'elevationLow',
});

const chartClassName = cx(
  sprinkles({ flexItems: 'center', flex: 1, width: 'fill', gap: 'sp1' }),
  embedSprinkles({
    backgroundColor: 'containerFill',
    padding: 'container',
    borderRadius: 'container',
  }),
  GLOBAL_STYLE_CLASSNAMES.container.outline.border,
  GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
);

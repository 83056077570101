import { Middleware, Dispatch } from 'redux';

import { Action, DashboardStates } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import {
  defaultDropdownsFinishedLoadingThunk,
  dropdownUpdatesFinishedLoadingThunk,
} from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';

const FETCH_DATASET_ACTIONS = new Set([
  `${ACTION.FETCH_DASHBOARD_DATASET_PREVIEW}/fulfilled`,
  `${ACTION.FETCH_DASHBOARD_DATASET_PREVIEW}/rejected`,
  `${ACTION.FETCH_DASHBOARD_DATASET_PREVIEW}_SUCCESS`,
  `${ACTION.FETCH_DASHBOARD_DATASET_PREVIEW}_ERROR`,
  `${ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW}_ERROR`,
  `${ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW}_SUCCESS`,
  `${ACTION.FETCH_COMPUTED_VIEW_DATA}/fulfilled`,
  `${ACTION.FETCH_COMPUTED_VIEW_DATA}/rejected`,
]);

export const dashboardDataMiddleware: Middleware<{}, DashboardStates> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    if (!FETCH_DATASET_ACTIONS.has(action.type)) return next(action);

    const { defaultDropdownInfo, refreshDataInfo } = getState().dashboardData;
    const datasetsWaitingOnForDefault = defaultDropdownInfo.datasetsLeftToLoad.length;
    const datasetsWaitingOnForRefresh = refreshDataInfo.datasetsLeftToLoad.length;
    if (!datasetsWaitingOnForDefault && !datasetsWaitingOnForRefresh) return next(action);

    const result = next(action);

    const { defaultDropdownInfo: postDropdownInfo, refreshDataInfo: postRefreshInfo } =
      getState().dashboardData;
    const datasetsLeftForDefault = postDropdownInfo.datasetsLeftToLoad.length;
    const datasetsLeftForRefresh = postRefreshInfo.datasetsLeftToLoad.length;

    // Typing of all our redux shit needs to be fixed, tried some initial thoughts to no avail

    // This and below checks if we were waiting on a dataset before and not anymore
    // Aka all datasets are loaded and waiting for next step
    if (datasetsWaitingOnForDefault && !datasetsLeftForDefault) {
      // @ts-ignore
      dispatch(defaultDropdownsFinishedLoadingThunk());
    }

    if (datasetsWaitingOnForRefresh && !datasetsLeftForRefresh) {
      // @ts-ignore
      dispatch(dropdownUpdatesFinishedLoadingThunk());
    }

    return result;
  };

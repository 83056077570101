import { FC } from 'react';
import { omit, some } from 'utils/standard';
import { useDispatch } from 'react-redux';

import { SettingHeader } from 'components/SettingHeader';
import DroppableColumnSection from './droppable/DroppableColumnSection';
import { showCustomToast } from 'shared/sharedToasts';

import { OPERATION_TYPES, V2BoxPlotInstructions } from 'constants/types';
import { INTEGER_DATA_TYPE, FLOAT } from 'constants/dataConstants';
import { updateClonedVisualizationInstructions } from 'actions/dataPanelConfigActions';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { resolveCategoryColDropped } from 'utils/dataPanelColUtils';
import { PivotAgg } from 'types/dateRangeTypes';

type Props = {
  instructions: V2BoxPlotInstructions;
  chartType: string;
  loading?: boolean;
  schema: DatasetSchema;
};

export const BoxPlotConfig: FC<Props> = ({ instructions, chartType, loading, schema }) => {
  const dispatch = useDispatch();

  const updateInstructions = (modifyInstructions: (instructions: V2BoxPlotInstructions) => void) =>
    updateClonedVisualizationInstructions(
      instructions,
      chartType as OPERATION_TYPES,
      dispatch,
      modifyInstructions,
    );

  const onCalculationColAdded = (newCol: DatasetColumn, oldColName?: string) => {
    if (some(instructions.calcColumns, (opt) => opt.name === newCol.name)) {
      showCustomToast(
        'The selected calculation is already present for this column. Duplicates are not allowed.',
        { icon: 'warning-sign' },
      );
      return;
    }

    updateInstructions((instructions) => {
      // This is case we are adding a new column
      if (oldColName === undefined) {
        instructions.calcColumns = instructions.calcColumns
          ? [...instructions.calcColumns, newCol]
          : [newCol];
        return;
      }
      // This is case we are replacing an old column
      if (!instructions.calcColumns) return;
      const removedColIndex = instructions.calcColumns.findIndex((col) => col.name === oldColName);
      if (removedColIndex == -1) return;
      instructions.calcColumns.splice(removedColIndex, 1, newCol);

      if (instructions.boxPlotFormat?.seriesLabelByColumn) {
        instructions.boxPlotFormat.seriesLabelByColumn = omit(
          instructions.boxPlotFormat.seriesLabelByColumn,
          oldColName,
        );
      }
    });
  };

  return (
    <div>
      <SettingHeader name="Grouping" />
      <DroppableColumnSection
        required
        columns={instructions.groupingColumn ? [instructions.groupingColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) =>
          updateInstructions((instructions) => {
            instructions.groupingColumn = resolveCategoryColDropped(
              col,
              instructions.groupingColumn,
            );
          })
        }
        onColOptionChanged={(option) =>
          updateInstructions((instructions) => {
            if (!instructions.groupingColumn) return;
            instructions.groupingColumn.bucket = { id: option.id as PivotAgg };
          })
        }
        onRemoveCol={() =>
          updateInstructions((instructions) => (instructions.groupingColumn = undefined))
        }
        schema={schema}
      />
      <SettingHeader name="Calculation" />
      <DroppableColumnSection
        required
        allowedTypes={[INTEGER_DATA_TYPE, FLOAT]}
        columns={
          instructions.calcColumns ? instructions.calcColumns.map((column) => ({ column })) : []
        }
        disableEdits={loading}
        onColAdded={onCalculationColAdded}
        onColOptionChanged={() => undefined}
        onRemoveCol={(removedCol) => {
          updateInstructions((instructions) => {
            instructions.calcColumns = instructions.calcColumns?.filter(
              (col) => col.name !== removedCol.name,
            );
            if (instructions.boxPlotFormat?.seriesLabelByColumn) {
              instructions.boxPlotFormat.seriesLabelByColumn = omit(
                instructions.boxPlotFormat.seriesLabelByColumn,
                removedCol.name ?? '',
              );
            }
          });
        }}
        schema={schema}
      />
    </div>
  );
};

import { FC, MouseEventHandler, useEffect, useRef, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { BuiltInMenu } from 'pages/ReportBuilderEditor/BuiltIns/BuiltInMenu';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { CustomerPermissionsSummary } from 'components/CustomerPermissions/Summary';
import { sprinkles, Tooltip } from 'components/ds';
import * as styles from 'pages/ReportBuilderEditor/BuiltIns/BuiltInListItem.css';

import { deleteBuiltInReport, duplicateBuiltInReport } from 'reducers/reportBuilderEditReducer';
import { selectBuiltInForEdit } from 'reducers/thunks/reportBuilderEditorThunks';
import { openModalType } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportModalType } from 'reportBuilderContent/reducers/types';

type Props = {
  selectedBuiltInId?: string;
  builtIn: BuiltInReportConfig;
};

export const BuiltInListItem: FC<Props> = ({ builtIn, selectedBuiltInId }) => {
  const dispatch = useDispatch();
  const itemRef = useRef<HTMLDivElement>(null);
  const isSelected = selectedBuiltInId === builtIn.id;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: builtIn.id,
  });
  const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition };

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.defaultPrevented) return;
    dispatch(selectBuiltInForEdit(builtIn));
  };

  const onDuplicate = () => dispatch(duplicateBuiltInReport(builtIn));
  const onDelete = () => dispatch(deleteBuiltInReport(builtIn.id));

  const onEdit = () => {
    dispatch(selectBuiltInForEdit(builtIn));
    dispatch(openModalType({ type: ReportModalType.EDIT_BUILT_IN }));
  };

  useEffect(() => {
    // Auto-scroll to the currently selected Item
    if (isSelected)
      itemRef.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
  }, [isSelected]);

  return (
    <Tooltip
      className={sprinkles({ zIndex: 'base' })}
      delayDuration={500}
      side="right"
      text={
        <div className={sprinkles({ padding: 'sp1' })}>
          <div className={sprinkles({ heading: 'h4', overflow: 'hidden' })}>{builtIn.name}</div>
          <div className={sprinkles({ body: 'b2', overflow: 'hidden' })}>{builtIn.description}</div>
        </div>
      }>
      <div
        className={cx(
          styles.builtInItem,
          isSelected ? styles.builtInItemActive : styles.builtInItemInactive,
        )}
        onClick={onClick}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}>
        <div className={styles.itemHeading} ref={itemRef}>
          <span className={sprinkles({ heading: 'h4', truncateText: 'ellipsis' })}>
            {builtIn.name}
          </span>
          <BuiltInMenu onDelete={onDelete} onDuplicate={onDuplicate} onEdit={onEdit} />
        </div>
        {builtIn.description ? (
          <span className={styles.itemDescription}>{builtIn.description}</span>
        ) : null}
        <CustomerPermissionsSummary permissions={builtIn.permissions} />
      </div>
    </Tooltip>
  );
};

type BuiltInListItemOverlayProps = {
  builtIn: BuiltInReportConfig;
};

export const BuiltInListItemOverlay: FC<BuiltInListItemOverlayProps> = ({ builtIn }) => {
  return (
    <div className={cx(styles.builtInItem, styles.builtInItemActive)}>
      <div className={styles.itemHeading}>
        <span className={sprinkles({ heading: 'h4', truncateText: 'ellipsis' })}>
          {builtIn.name}
        </span>
      </div>
      {builtIn.description && <span className={styles.itemDescription}>{builtIn.description}</span>}
      <CustomerPermissionsSummary permissions={builtIn.permissions} />
    </div>
  );
};

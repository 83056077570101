import { FC, useContext } from 'react';
import cx from 'classnames';

import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { sprinkles } from 'components/ds';

import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

type Props = {
  startRow: number;
  numCurrentRows: number;
  totalRows: number;
  pageNumber: number;
  numPages: number;
};

export const PDFTableFooter: FC<Props> = ({
  startRow,
  numCurrentRows,
  totalRows,
  pageNumber,
  numPages,
}) => {
  const context = useContext(GlobalStylesContext);

  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'alignCenterBetween', paddingX: 'sp2' }),
        embedSprinkles({ color: 'secondaryFont' }),
        GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
      )}
      style={{
        height: 32,
        border: `1px solid ${context.globalStyleConfig.container.outline.color}`,
        borderTop: 0,
      }}>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        {totalRows === 0 ? 0 : startRow}-{startRow + numCurrentRows - 1} of {totalRows} results
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        Page {pageNumber} of {numPages}
      </div>
    </div>
  );
};

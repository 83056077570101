import { FC } from 'react';
import { isEmpty } from 'utils/standard';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { SingleNumberChart } from '../charts/singleNumberChart';
import { NumberTrend } from '../charts/numberTrend';
import { DemoWatermark } from 'components/DemoWatermark';
import { NoDataPanel } from 'components/ChartLayout/NoDataPanel';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { OPERATION_TYPES } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { getChartTooltipText } from 'utils/graphUtils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { HEADER_HEIGHT } from './DashboardDatasetView';
import { DataPanelData } from 'types/dataPanelTemplate';

type Props = {
  dataPanel: DataPanel;
  dataPanelData: DataPanelData | undefined;
  displayDemoWatermark: boolean;
  globalStyleConfig: GlobalStyleConfig;
  loading: boolean;
  variables: DashboardVariableMap;

  processString: (s: string) => string;
};

export const NoDataChartView: FC<Props> = ({
  dataPanel,
  dataPanelData,
  loading,
  variables,
  globalStyleConfig,
  displayDemoWatermark,
  processString,
}) => {
  const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

  const schema = dataPanelData?.schema ?? [];

  const renderDemoWatermark = () => {
    return displayDemoWatermark ? <DemoWatermark /> : null;
  };

  if (
    operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    return (
      <div className={sprinkles({ height: 'fill', padding: 'sp2' })}>
        {renderDemoWatermark()}
        <SingleNumberChart
          dataPanelTemplateId={dataPanel.id}
          generalOptions={generalFormatOptions}
          globalStyleConfig={globalStyleConfig}
          infoTooltipText={getChartTooltipText(operation_type, instructions, variables)}
          instructions={instructions.V2_KPI}
          loading={loading}
          operationType={operation_type}
          previewData={[]}
          processString={processString}
          schema={schema}
        />
      </div>
    );
  } else if (operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    const secondaryData = dataPanelData?.secondaryData ?? [];
    return (
      <>
        {renderDemoWatermark()}
        <NumberTrend
          aggValuesLoading={!dataPanelData || !!dataPanelData.outstandingSecondaryDataRequests}
          aggregatedValues={
            !isEmpty(secondaryData)
              ? {
                  comparisonRange: secondaryData[0]
                    ? (secondaryData[0][Object.keys(secondaryData[0])[0] as string] as number) ?? 0
                    : 0,
                  periodRange: secondaryData[1]
                    ? (secondaryData[1][Object.keys(secondaryData[1])[0] as string] as number) ?? 0
                    : 0,
                }
              : undefined
          }
          dataPanelTemplateId={dataPanel.id}
          generalOptions={generalFormatOptions}
          globalStyleConfig={globalStyleConfig}
          infoTooltipText={getChartTooltipText(operation_type, instructions, variables)}
          instructions={instructions.V2_KPI_TREND}
          loading={loading}
          previewData={[]}
          processString={processString}
          schema={schema}
          variables={variables}
        />
      </>
    );
  }

  return (
    <div
      className={cx(
        sprinkles({ padding: 'sp1' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}
      //20px to account for header padding
      style={{ height: `calc(100% - ${HEADER_HEIGHT}px - 20px)` }}>
      <NoDataPanel
        fullHeight
        loading={loading}
        noDataState={generalFormatOptions?.noDataState}
        operationType={operation_type}
      />
    </div>
  );
};

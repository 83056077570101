import { FC, DetailedHTMLProps, HTMLAttributes, useState, useEffect } from 'react';
import cx from 'classnames';

import { Tab } from 'components/ds/Tabs/Tab';
import * as styles from './index.css';

type HTMLDivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>;

export type Props = HTMLDivProps & {
  tabs: string[];
  selectedTabId?: string;
  onTabSelect: (tabId: string) => void;
};

export const Tabs: FC<Props> = ({ className, selectedTabId, tabs, onTabSelect, ...props }) => {
  const [selectedTab, setSelectedTab] = useState<string>(selectedTabId ?? tabs[0]);

  useEffect(() => {
    setSelectedTab(selectedTabId ?? tabs[0]);
  }, [selectedTabId, tabs]);

  const handleClick = (tab: string) => {
    if (tab === selectedTab) return;
    setSelectedTab(tab);
    onTabSelect(tab);
  };

  return (
    <div {...props} className={cx(styles.navTabs, className)}>
      {tabs.map((tab) => (
        <Tab isSelected={selectedTab === tab} key={tab} onClick={() => handleClick(tab)}>
          {tab}
        </Tab>
      ))}
    </div>
  );
};

export { Tab } from './Tab';

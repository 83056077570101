import { FC } from 'react';
import cx from 'classnames';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { sprinkles, Icon } from 'components/ds';
import * as dataPanelStyles from './DataPanelListItem.css';

import { DraggableColumnInfo } from 'utils/customerReportUtils';
import { getColTypeDisplay } from 'utils/reportBuilderConfigUtils';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { getColDisplay } from 'utils/V2ColUtils';

type DraggingColumnProps = {
  column: DraggableColumnInfo;
  columnConfigs?: ReportBuilderColConfigs;
  disabled: boolean;
};

export const DraggingColumn: FC<DraggingColumnProps> = ({ column, columnConfigs, disabled }) => {
  const columnName = getColDisplay(column, columnConfigs);

  return (
    <div
      className={cx(
        dataPanelStyles.item,
        baseStyle,
        disabled ? dataPanelStyles.itemDisabled : dataPanelStyles.itemGrabbing,
      )}>
      <div className={dataPanelStyles.dragArea}>
        <Icon
          className={sprinkles({
            color: 'contentTertiary',
            cursor: 'grabbing',
          })}
          name="vertical-grip"
          size="md"
        />
        <div className={dataPanelStyles.textContainer}>
          <EmbedText body="b1" className={dataPanelStyles.columnName}>
            {columnName}
          </EmbedText>
          <EmbedText body="b2" color="contentTertiary">
            {getColTypeDisplay(column.type)}
          </EmbedText>
        </div>
      </div>
    </div>
  );
};

const baseStyle = sprinkles({ borderRadius: 8, boxShadow: 'sm' });

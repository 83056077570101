import { FC, useMemo } from 'react';
import { DateTime } from 'luxon';

import { sprinkles, Checkbox, Toggle, ToggleItem, Select } from 'components/ds';
import * as styles from '../styles.css';
import { DatePickerInput } from 'components/DatePickerInput';
import {
  SortableListItemDragHandle,
  SortableList,
  SortableListItem,
} from 'components/SortableList/SortableList';

import { DASHBOARD_ELEMENT_TYPES, DateElemConfig, DATE_FILTER_TYPE } from 'types/dashboardTypes';
import {
  DATE_RANGE_TYPES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
  RELATIVE_DATE_RANGES,
} from 'types/dateRangeTypes';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { getUpdateConfigFunc } from '../utils';
import { getSortedDateRangesWithVisibility, toggleRangeVisibility } from 'utils/dateRangeUtils';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;

  updateDateConfig: (config: DateElemConfig) => void;
};

const RelativeDateRangeOptions = Object.values(RELATIVE_DATE_RANGES).map((date) => ({
  value: date,
}));

export const DateValuesConfig: FC<Props> = ({ config, dateFilterType, updateDateConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  const orderedRanges = useMemo(() => {
    if (dateFilterType !== DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) return [];

    return getSortedDateRangesWithVisibility(config);
  }, [dateFilterType, config]);

  if (dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
    return (
      <SortableList
        getIdFromElem={(item) => item.range}
        onListUpdated={(newList) =>
          updateConfig((draft) => {
            draft.defaultRangesOrder = newList.map((item) => item.range);
          })
        }
        sortableItems={orderedRanges}>
        {orderedRanges.map(({ range, isVisible }) => {
          return (
            <SortableListItem key={range} sortId={range}>
              <div className={styles.sortableRangeOption}>
                <SortableListItemDragHandle
                  className={sprinkles({ color: 'contentSecondary', marginRight: 'sp1' })}
                />
                <Checkbox
                  isChecked={isVisible}
                  onChange={() =>
                    updateConfig((draft) => {
                      toggleRangeVisibility(range, isVisible, draft);
                    })
                  }
                />
                <div className={sprinkles({ marginLeft: 'sp1' })}>
                  {DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[range] ?? range}
                </div>
              </div>
            </SortableListItem>
          );
        })}
      </SortableList>
    );
  }

  return (
    <>
      <Toggle
        className={styles.configInput}
        label="Selectable Range"
        onValueChange={(value) =>
          updateConfig((draft) => (draft.dateRangeType = value as DATE_RANGE_TYPES))
        }
        selectedValue={config.dateRangeType ?? DATE_RANGE_TYPES.RELATIVE}>
        <ToggleItem key="exact" value={DATE_RANGE_TYPES.EXACT}>
          Exact Range
        </ToggleItem>
        <ToggleItem key="relative" value={DATE_RANGE_TYPES.RELATIVE}>
          Relative Range
        </ToggleItem>
      </Toggle>
      {config.dateRangeType === DATE_RANGE_TYPES.EXACT ? (
        <>
          <DatePickerInput
            showCancelBtn
            className={styles.configInput}
            label="Start Date"
            onNewValueSelect={(newValue) =>
              updateConfig((draft) => (draft.minValue = (newValue as DateTime) || undefined))
            }
            selectedValue={
              config.minValue
                ? typeof config.minValue === 'string'
                  ? dateTimeFromISOString(config.minValue)
                  : config.minValue
                : undefined
            }
            showTimeSelect={!config.hideTimeSelect}
          />
          <DatePickerInput
            showCancelBtn
            className={styles.configInput}
            label="End Date"
            onNewValueSelect={(newValue) =>
              updateConfig((draft) => (draft.maxValue = (newValue as DateTime) || undefined))
            }
            selectedValue={
              config.maxValue
                ? typeof config.maxValue === 'string'
                  ? dateTimeFromISOString(config.maxValue)
                  : config.maxValue
                : undefined
            }
            showTimeSelect={!config.hideTimeSelect}
          />
        </>
      ) : (
        <Select
          onCancel={() => updateConfig((draft) => (draft.relativeDateRange = undefined))}
          onChange={(value) =>
            updateConfig((draft) => (draft.relativeDateRange = value as RELATIVE_DATE_RANGES))
          }
          placeholder="Select Relative range"
          selectedValue={config.relativeDateRange}
          values={RelativeDateRangeOptions}
        />
      )}
    </>
  );
};

import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { DataGrid, sprinkles } from 'components/ds';
import { DEFAULT_ROWS_PER_PAGE, PaginatorProps } from 'components/ds/DataGrid/paginator';

import { getSelectedDataPanelData } from 'reducers/selectors';

const rootClass = sprinkles({ parentContainer: 'fill', flexItems: 'column', overflow: 'hidden' });
const spacingClass = sprinkles({ padding: 'sp1.5' });

export const DataDebugger: FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dataPanelData = useSelector(getSelectedDataPanelData);

  const paginatorProps: PaginatorProps = useMemo(() => {
    return {
      minimal: true,
      loading: dataPanelData?.loading ?? false,
      totalRowCount: dataPanelData?.rows?.length || 0,
      currentPage: currentPage,
      goToPage: ({ page }) => setCurrentPage(page),
    };
  }, [dataPanelData, currentPage]);

  if (!dataPanelData) {
    return (
      <div className={cx(rootClass, spacingClass)}>
        Select a data panel on the dashboard to use the debugger
      </div>
    );
  } else if (dataPanelData?.error) {
    return <div className={cx(rootClass, spacingClass)}>There was a problem fetching the data</div>;
  } else if (!dataPanelData?.rows) {
    return (
      <div className={cx(rootClass, spacingClass)}>
        Configure this data panel to see the underlying data
      </div>
    );
  }

  return (
    <div className={rootClass}>
      <DataGrid
        loading={dataPanelData?.loading}
        paginatorProps={paginatorProps}
        rows={dataPanelData?.rows.slice(DEFAULT_ROWS_PER_PAGE * (currentPage - 1))}
        schema={dataPanelData?.schema ?? []}
      />
    </div>
  );
};

import { FC } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import VisualizationsBarDirectionImg from 'constants/images/report-builder/visualizations-bar-direction.gif';
import VisualizationsBarImg from 'constants/images/report-builder/visualizations-bar.gif';
import VisualizationsLineImg from 'constants/images/report-builder/visualizations-line.gif';
import VisualizationsImg from 'constants/images/report-builder/visualizations.gif';
import { ImageAndText } from 'pages/ReportBuilder/Onboarding/Blocks/ImageAndText';
import { DocImage } from 'pages/ReportBuilder/Onboarding/Blocks/DocImage';

export const VisualizationsDoc: FC = () => (
  <>
    <DocImage alt="Visualization" img={VisualizationsImg} />
    <EmbedText body="b2">
      There are 5 possible types of visualizations. Each view can have one visualization. The types
      of visualizations are:
      <ul>
        <li>Table - view all data or grouped data in tabular format</li>
        <li>Line chart - view data over a continuous spectrum</li>
        <li>Bar chart - view data by category</li>
        <li>Pie chart - view how individual values contribute to a whole</li>
        <li>KPI - view a single “Key Performance Indicator” metric</li>
      </ul>
    </EmbedText>
    <ImageAndText alt="Line Visualization" img={VisualizationsLineImg}>
      <EmbedText body="b2">
        After a visualization type is selected, the chart will be created once all fields are valid
        - the interface will indicate which ones need refinement.
      </EmbedText>
    </ImageAndText>
    <ImageAndText alt="Bar Visualization" img={VisualizationsBarImg}>
      <EmbedText body="b2">
        Attempting to switch visualizations in a single view will try to convert the existing
        visualization into the next.
      </EmbedText>
    </ImageAndText>
    <ImageAndText alt="Bar Visualization Direction" img={VisualizationsBarDirectionImg}>
      <EmbedText body="b2">
        Some reports have additional formatting options (like directional and grouping options for
        bar charts)
      </EmbedText>
    </ImageAndText>
  </>
);

import { configureStore } from '@reduxjs/toolkit';
import sessionExpiredMiddleware from './sessionExpiredMiddleware';

import rootReducer from 'reducers/rootReducer';
import { getEnvironment } from 'utils/environmentUtils';
import { dashboardDataMiddleware } from './dashboardDataMiddleware';
import {
  reportBuilderSharedMiddleware,
  reportBuilderDashboardMiddleware,
} from '../reportBuilderContent/middleware/reportBuilderDataMiddleware';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Calendly: any;
  }
}

export default () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // TODO: Stop passing DateTimes to actions/state. Turn to string first
      getDefaultMiddleware({
        serializableCheck: { ignoreActions: true, ignoreState: true },
      }).concat([
        sessionExpiredMiddleware,
        dashboardDataMiddleware,
        reportBuilderSharedMiddleware,
        reportBuilderDashboardMiddleware,
      ]),
    preloadedState: {},
    devTools: getEnvironment() === 'development',
  });
};

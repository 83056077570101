import produce from 'immer';
import cx from 'classnames';
import { capitalize } from 'utils/standard';

import { GlobalStyleConfig } from 'globalStyles/types';
import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles';
import { ConfigSectionHeader } from './configSectionHeader';
import ColorPicker from './CustomStylesColorPicker';
import { Select } from 'components/ds';
import { CollapsibleMenu } from 'components/CollapsibleMenu';
import LineSelect from 'shared/LineSelect';
import { CustomizedTag } from './CustomizedTag';
import { Input, sprinkles } from 'components/ds';
import { FC } from 'react';
import { SelectValues } from 'components/ds/Select';
import { getFontFamilyName } from 'globalStyles/utils';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
  fonts: SelectValues<string>;
};
const MAX_FONTS = 25;

export const TextConfigSection: FC<Props> = (props) => {
  return (
    <>
      <ConfigSectionHeader className={sprinkles({ marginTop: 'sp5' })} title="Text" />
      <FontRow type="primary" {...props} />
      <FontRow
        className={sprinkles({ marginTop: 'sp2', marginBottom: 'sp2.5' })}
        type="secondary"
        {...props}
      />
      <ConfigSectionHeader isSubTitle title="Text Size" />
      <TextSizeInputs {...props} />
      <TextOverrideCollapsibleMenu {...props} />
    </>
  );
};

function FontRow({
  styleConfig,
  updateConfig,
  fonts,
  type,
  className,
}: Props & {
  type: 'primary' | 'secondary';
  className?: string;
}) {
  const fontType = type === 'primary' ? 'primaryFont' : 'secondaryFont';
  const fontColor = type === 'primary' ? 'primaryColor' : 'secondaryColor';
  const font = styleConfig.text[fontType];

  return (
    <div className={cx(sprinkles({ display: 'flex', gap: 'sp3' }), className)}>
      <div className={sprinkles({ flex: 1 })}>
        <div className={inputLabelClass}>{`${capitalize(type)} Font`}</div>
        <Select
          filterProps={{ maxValues: 25, selectedLabel: font && getFontFamilyName(font) }}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text[fontType] = undefined;
            });
            updateConfig(newConfig);
          }}
          onChange={(font) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text[fontType] = font;
            });
            updateConfig(newConfig);
          }}
          placeholder="Inherit"
          selectedValue={font}
          values={fonts}
        />
      </div>
      <div className={sprinkles({ flex: 1 })}>
        <div className={inputLabelClass}>Color</div>
        <ColorPicker
          fill
          color={styleConfig.text[fontColor]}
          onClose={(newColor) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text[fontColor] = newColor;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
    </div>
  );
}

function TextSizeInputs({ styleConfig, updateConfig }: Props) {
  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1.5' })}>
      <Input
        defaultValue={`${styleConfig.text.textSize}px`}
        onSubmit={(newTextSizeString) => {
          const newTextSize = Number(newTextSizeString.replace('px', ''));

          if (isNaN(newTextSize) || newTextSize < 1) return;

          const newConfig = produce(styleConfig, (draft) => {
            draft.text.textSize = newTextSize;
          });
          updateConfig(newConfig);
        }}
        style={{ width: 55 }}
      />
      <LineSelect
        fill
        breakpoints={[10, 12, 14, 16, 18]}
        className={sprinkles({ flex: 1 })}
        labels={['Small', 'Moderate', 'Large']}
        setValue={(newTextSize) => {
          const newConfig = produce(styleConfig, (draft) => {
            draft.text.textSize = newTextSize;
          });
          updateConfig(newConfig);
        }}
        value={styleConfig.text.textSize}
      />
    </div>
  );
}

function TextOverrideCollapsibleMenu(props: Props) {
  return (
    <CollapsibleMenu className={sprinkles({ marginTop: 'sp2' })} title="Custom text">
      <CollapsibleMenuRow isFirst override="h1" text="H1" {...props} />
      <CollapsibleMenuRow override="h2" text="Chart Titles" {...props} />
      <CollapsibleMenuRow override="kpiTitle" text="KPI Titles" {...props} />
      <CollapsibleMenuRow override="kpiValue" text="KPI Values" {...props} />
      <CollapsibleMenuRow override="tableColumnHeader" text="Table Column Headers" {...props} />
      <CollapsibleMenuRow override="body" text="Body" {...props} />
      <CollapsibleMenuRow override="smallHeading" text="Small Heading" {...props} />
      <CollapsibleMenuRow override="smallBody" text="Small Body" {...props} />
      <CollapsibleMenuRow override="filterLabel" text="Filter Labels" {...props} />
    </CollapsibleMenu>
  );
}

type CollapsibleMenuRowProps = {
  override: keyof GlobalStyleConfig['text']['overrides'];
  text: string;
  isFirst?: boolean;
};

function CollapsibleMenuRow({
  fonts,
  override,
  text,
  isFirst,
  styleConfig,
  updateConfig,
}: CollapsibleMenuRowProps & Props) {
  const font = styleConfig.text.overrides[override]?.font;
  const size =
    styleConfig.text.overrides[override]?.size ||
    styleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP[override];

  return (
    <>
      <div
        className={cx(
          inputLabelClass,
          sprinkles({ flexItems: 'alignCenter', marginTop: isFirst ? undefined : 'sp2' }),
        )}>
        {text}
        <CustomizedTag
          hidden={!styleConfig.text.overrides[override]}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = undefined;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <div className={sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1' })}>
        <Select
          className={sprinkles({ flex: 1, overflow: 'hidden' })}
          filterProps={{ maxValues: MAX_FONTS, selectedLabel: font && getFontFamilyName(font) }}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              const overrideProperties = draft.text.overrides[override];
              if (overrideProperties) {
                overrideProperties.font = undefined;
              }
            });
            updateConfig(newConfig);
          }}
          onChange={(font) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = {
                ...styleConfig.text.overrides[override],
                font,
              };
            });
            updateConfig(newConfig);
          }}
          placeholder="Inherit"
          selectedValue={font}
          values={fonts}
        />
        <div className={sprinkles({ flex: 1 })}>
          <ColorPicker
            fill
            color={styleConfig.text.overrides[override]?.color || styleConfig.text.primaryColor}
            onClose={(newColor) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text.overrides[override] = {
                  ...styleConfig.text.overrides[override],
                  color: newColor,
                };
              });
              updateConfig(newConfig);
            }}
          />
        </div>
        <Input
          defaultValue={`${size}px`}
          onSubmit={(newTextSizeString) => {
            const newTextSize = Number(newTextSizeString.replace('px', ''));

            if (isNaN(newTextSize) || newTextSize < 1) return;

            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = {
                ...styleConfig.text.overrides[override],
                size: newTextSize,
              };
            });
            updateConfig(newConfig);
          }}
          style={{ width: 55 }}
        />
      </div>
    </>
  );
}

const inputLabelClass = sprinkles({ body: 'b3', marginBottom: 'sp.5' });

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReduxState } from 'reducers/rootReducer';
import { makeThunkRequest } from 'utils/thunkUtils';
import { ACTION } from 'actions/types';
import { createGetRequestConfig, createApiRequestConfig } from 'actions/actionUtils';
import { Dashboard } from 'actions/dashboardActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { ROUTES } from 'constants/routes';

export type Folder = {
  id: number;
  name: string;
  parent_id: number;
  type: string;
  num_folders: number;
  num_resources: number;
};
export type Breadcrumb = { name: string; id: number };

type FolderContents = {
  folders: Folder[];
  breadcrumbs: Breadcrumb[];
  count: number;
};

export type DashboardFolderContents = FolderContents & {
  resources: Dashboard[];
};
export type ReportFolderContents = FolderContents & {
  resources: ReportBuilder[];
};

type ListFolderContentsQueryParams = {
  id?: number;
  page: number;
  page_size: number;
};

/**
 * Note for reviewer:
 * Keeping these separate so I can listen for the different actions in the dashboards reducer and the reports reducer separately.
 * Will only every want to update state.dashboards when listDashboardFolderContents is called and vice versa.
 * Alt: could change the return type to have two different keys for reports or dashboards and just always listen in both reducers and
 * check what was returned, but I thought this was simpler.
 */
export const listDashboardFolderContents = createAsyncThunk<
  DashboardFolderContents,
  ListFolderContentsQueryParams,
  { state: ReduxState }
>(ACTION.LIST_DASHBOARD_FOLDER_CONTENTS, async (queryParams) =>
  makeThunkRequest(
    createGetRequestConfig('resources/list_dashboard_folder_contents/', 'GET', queryParams),
    'Error getting dashboard folder contents',
    {
      onError: () => {
        if (!queryParams.id) return;
        window.location.href = `${window.location.origin}${ROUTES.HOME_APP_PAGE_TEST}`;
      },
    },
  ),
);

export const listReportFolderContents = createAsyncThunk<
  ReportFolderContents,
  ListFolderContentsQueryParams,
  { state: ReduxState }
>(ACTION.LIST_REPORT_FOLDER_CONTENTS, async (queryParams) =>
  makeThunkRequest(
    createGetRequestConfig('resources/list_report_folder_contents/', 'GET', queryParams),
    'Error getting report folder contents',
    {
      onError: () => {
        if (!queryParams.id) return;
        window.location.href = `${window.location.origin}${ROUTES.REPORT_BUILDER_TEST}`;
      },
    },
  ),
);

type CreateFolderBody = {
  config: { name: string; parent_id: number };
  onSuccess: () => void;
  onError: () => void;
};

export const createFolder = createAsyncThunk<
  { folder: Folder },
  CreateFolderBody,
  { state: ReduxState }
>(ACTION.CREATE_FOLDER, async ({ config, onSuccess, onError }) =>
  makeThunkRequest(createApiRequestConfig(`resources/`, 'POST', config), 'Error creating folder', {
    onSuccess,
    onError,
  }),
);

type RenameFolderBody = { name: string; id: number; onSuccess: () => void };

export const renameFolder = createAsyncThunk<
  { folder: Folder },
  RenameFolderBody,
  { state: ReduxState }
>(ACTION.RENAME_FOLDER, async ({ name, id, onSuccess }) =>
  makeThunkRequest(
    createApiRequestConfig(`resources/${id}/rename/`, 'PUT', { name }),
    'Error renaming folder',
    { onSuccess },
  ),
);

export const deleteFolder = createAsyncThunk<{ id: number }, number, { state: ReduxState }>(
  ACTION.DELETE_FOLDER,
  async (id) =>
    makeThunkRequest(
      createApiRequestConfig(`resources/${id}/`, 'DELETE', undefined),
      'Error deleting folder',
    ),
);

// This is not used in this PR, but will be in the next
type MoveEntryBody = { parent_id: number; id: number };

export const moveEntry = createAsyncThunk<
  { id: number; type: string },
  MoveEntryBody,
  { state: ReduxState }
>(ACTION.MOVE_ENTRY, async (config) =>
  makeThunkRequest(createApiRequestConfig(`resources/move/`, 'PUT', config), 'Error moving entry'),
);

// This is not used in this PR, but will be in the next
// Searches either the context of folders + dashboards or folders + report builders
export type ResourceSearchResult = {
  id: number;
  name: string;
  type: string;
  breadcrumbs: Breadcrumb[];
};

type SearchResourcesQueryParams = {
  type: 'dashboard' | 'report';
  name: string;
};

export const searchResources = createAsyncThunk<
  ResourceSearchResult[],
  SearchResourcesQueryParams,
  { state: ReduxState }
>(ACTION.SEARCH_RESOURCES, async (queryParams) =>
  makeThunkRequest(
    createGetRequestConfig('resources/search/', 'GET', queryParams),
    'Error searching resources',
  ),
);

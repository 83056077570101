import { GlobalStyleConfig } from 'globalStyles/types';
import { defineAPIPostAction, defineAPIAction } from './actionUtils';
import { ACTION } from './types';

type SaveGlobalStylesData = {
  config_v2: GlobalStyleConfig;
};

export const { actionFn: saveGlobalStyles, successAction: saveGlobalStylesSuccess } =
  defineAPIPostAction<SaveGlobalStylesData, SaveGlobalStylesData>(
    ACTION.SAVE_GLOBAL_STYLES,
    'teams',
    'save_style_config_v2',
    'POST',
  );

type SaveAdditionalStyleBody = {
  theme_name: string;
  style_config: GlobalStyleConfig;
};

export const { actionFn: saveAdditionalStyle, successAction: saveAdditionalStyleSuccess } =
  defineAPIPostAction<SaveAdditionalStyleBody, SaveAdditionalStyleBody>(
    ACTION.SAVE_ADDITIONAL_STYLES,
    'teams',
    'save_additional_style_config',
    'POST',
  );

type DeleteAdditionalStyleBody = { theme_name: string };

export const { actionFn: deleteAdditionalStyle, successAction: deleteAdditionalStyleSuccess } =
  defineAPIPostAction<DeleteAdditionalStyleBody, DeleteAdditionalStyleBody>(
    ACTION.DELETE_ADDITIONAL_STYLE,
    'teams',
    'delete_additional_style',
    'POST',
  );

type FetchGoogleFontsResponse = {
  items: string[];
};

export const {
  actionFn: fetchGoogleFonts,
  requestAction: fetchGoogleFontsRequest,
  successAction: fetchGoogleFontsSuccess,
  errorAction: fetchGoogleFontsError,
} = defineAPIAction<FetchGoogleFontsResponse>(ACTION.FETCH_GOOGLE_FONTS, 'google_fonts', '', 'GET');

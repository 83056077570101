import { FC } from 'react';
import cx from 'classnames';
import { uniqBy, uniqueId } from 'utils/standard';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Button, ButtonGroup } from '@blueprintjs/core';
import { EmbedSpinner, EmbedFilterLabel } from 'components/embed';
import * as styles from './dashboardButtonGroupInput.css';

import { SelectedDropdownInputItem } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.bp3-button': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: 32,

      '&.disabled': {
        backgroundColor: 'rgba(206, 217, 224, 0.5) !important',

        '&:hover': {
          backgroundColor: 'rgba(206, 217, 224, 0.5) !important',
          color: 'inherit !important',
        },
      },
    },
  },
  emptyText: {
    fontStyle: 'italic',
    fontColor: theme.palette.ds.grey800,
    marginTop: '10px',
  },
}));

type Props = {
  disabled?: boolean;
  infoTooltipText?: string;
  selectedItem?: SelectedDropdownInputItem;
  onChange: (item: SelectedDropdownInputItem | undefined) => void;
  options: SelectedDropdownInputItem[];
  label?: string;
  loading?: boolean;
  preventUnselect?: boolean;
};

export const DashboardButtonGroupInput: FC<Props> = ({
  disabled,
  options,
  selectedItem,
  label,
  loading,
  preventUnselect,
  infoTooltipText,
  onChange,
}) => {
  const classes = useStyles();

  let uniqueOptions = uniqBy(options, (option: SelectedDropdownInputItem) => option.name);

  if (uniqueOptions.length > 5) {
    uniqueOptions = uniqueOptions.slice(0, 5);
  }

  if (loading) {
    return (
      <Button fill>
        <EmbedSpinner size="md" />
      </Button>
    );
  } else if (uniqueOptions.length === 0) {
    return (
      <Button disabled fill>
        <p className={classes.emptyText}>No button currently configured.</p>
      </Button>
    );
  }

  return (
    <div>
      <EmbedFilterLabel helpText={infoTooltipText}>{label}</EmbedFilterLabel>
      <ButtonGroup fill>
        {uniqueOptions.map((option) => {
          return (
            <Button
              fill
              active={option.name === selectedItem?.name}
              className={cx(
                classes.button,
                styles.button,
                { disabled },
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleSwitchInteraction,
                GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder,
                GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
                GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius,
              )}
              disabled={disabled}
              key={uniqueId(`${option.id}_`)}
              onClick={() => {
                if (option.name === selectedItem?.name) {
                  if (!preventUnselect) onChange(undefined);
                } else {
                  onChange(option);
                }
              }}
              text={option.name}
            />
          );
        })}
      </ButtonGroup>
    </div>
  );
};

import { FC } from 'react';
import produce from 'immer';

import { Input } from 'components/ds';

import { TextInputElemConfig } from 'types/dashboardTypes';
import { isValidForm } from 'utils/isValidFormUtils';

type Props = {
  config: TextInputElemConfig;

  updateInputConfig: (config: TextInputElemConfig) => void;
};

export const TextInputDefaultValuesConfig: FC<Props> = ({ config, updateInputConfig }) => {
  return (
    <Input
      showInputButton
      defaultValue={config.defaultValue}
      label="Default Value"
      onSubmit={(newValue) => {
        if (!isValidForm(config.limitEntriesToNumbers, newValue)) return;

        const newConfig = produce(config, (draft) => {
          draft.defaultValue = newValue;
        });
        updateInputConfig(newConfig);
      }}
    />
  );
};

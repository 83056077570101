import { FC, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as RD from 'remotedata';

import { QueryExecutionResponse } from '@explo-tech/fido-api';

import { DashboardDatasetEditor } from './dashboardDatasetEditor';

import { setSelectedDrilldownColumn } from 'actions/cssLinkActions';
import { saveDraftComputedViewQuery } from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import {
  getDatasetConfigFromView,
  getEmbeddoSchemaFromFidoSchema,
  getFidoSchemaFromEmbeddoSchema,
} from 'utils/fido/fidoShims';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';

import { ReduxState } from 'reducers/rootReducer';
import {
  createComputedView,
  deleteComputedView,
  saveComputedView,
} from 'reducers/thunks/fidoThunks';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import {
  fetchEditorDatasetPreviewThunk,
  fetchSavedDatasetThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { getDatasetsByFidoId } from 'reducers/selectors';
import { getViewFromDatasetId } from 'utils/fido/fidoUtils';
import { DatasetSchema } from 'types/datasets';

type Props = {
  pageWidth: number | null;
};

export const FidoDashboardDatasetEditorDataFetcher: FC<Props> = ({ pageWidth }) => {
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { computedViews, parentSchemas, datasetData, datasets, datasetsByFidoId } = useSelector(
    (state: ReduxState) => ({
      computedViews: state.fido.computedViews,
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dashboardEditConfig.datasetData,
      datasets: state.dashboardEditConfig.config?.datasets,
      datasetsByFidoId: getDatasetsByFidoId(state),
    }),
    shallowEqual,
  );

  const datasetIdsRef = useRef(Object.keys(datasets ?? {}));
  const getView = useCallback(
    (datasetId: string) => getViewFromDatasetId(computedViews, datasets, datasetId),
    [computedViews, datasets],
  );
  const datasetConfigs = useMemo(
    () =>
      RD.isSuccess(computedViews)
        ? Object.fromEntries(
            computedViews.data.map((view) => {
              const dataset = datasetsByFidoId[view.id];
              return [dataset.id, getDatasetConfigFromView(view, dataset)];
            }),
          )
        : {},
    [computedViews, datasetsByFidoId],
  );

  useEffect(() => {
    if (!datasets) return;

    const datasetIds = Object.keys(datasets);

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      dispatch(setSelectedDrilldownColumn());
      setSelectedDatasetId(datasetIds[0] ?? null);
    } else if (datasetIds.length > datasetIdsRef.current.length) {
      const diff = datasetIds.filter((datasetId) => !datasetIdsRef.current.includes(datasetId));

      if (diff.length === 1) {
        setSelectedDatasetId(diff[0]);
      }
    }

    datasetIdsRef.current = datasetIds;
  }, [selectedDatasetId, dispatch, datasets]);

  const onCreate = (name: string, schemaId: number) => {
    dispatch(
      createComputedView({
        name,
        namespace: parentSchemas.find((s) => s.id === schemaId),
      }),
    );

    trackEvent(EVENTS.CREATED_NEW_DATASET, {});
  };

  const onDelete = (datasetId: string) => {
    const viewToDelete = getView(datasetId);
    viewToDelete &&
      dispatch(
        deleteComputedView({
          viewId: viewToDelete.id,
          namespaceId: viewToDelete.namespaceId,
        }),
      );

    trackEvent(EVENTS.DELETED_DATASET, { datasetId });
  };

  const onSaveQuery = (query: string) => {
    if (!selectedDatasetId) return;

    getUnderlyingData(query, undefined, (data) => {
      const selectedView = getView(selectedDatasetId);
      if (!selectedView) return;

      let schema: DatasetSchema;

      if ('meta' in data) {
        dispatch(
          saveComputedView({
            ...selectedView,
            query: query,
            columnDefinitions: data.meta.schema.propertySchema,
          }),
        );
        schema = getEmbeddoSchemaFromFidoSchema(data.meta.schema.propertySchema);
      } else {
        dispatch(
          saveComputedView({
            ...selectedView,
            query: query,
            columnDefinitions: getFidoSchemaFromEmbeddoSchema(data.dataset_preview.schema),
          }),
        );
        schema = data.dataset_preview.schema;
      }

      dispatch(fetchSavedDatasetThunk(selectedDatasetId));

      trackEvent(EVENTS.SAVED_QUERY, {
        dataset_id: selectedDatasetId,
        dataset_query: query,
      });

      showDuplicateColumnNameToast(getDuplicateColumnsFromSchema(schema));
    });
  };

  const onSaveQueryDraft = (query: string | undefined) => {
    if (!selectedDatasetId) return;

    dispatch(saveDraftComputedViewQuery({ queryDraft: query, viewId: selectedDatasetId }));
  };

  const onEditName = (name: string) => {
    if (!selectedDatasetId) return;

    const selectedView = getView(selectedDatasetId);

    selectedView && dispatch(saveComputedView({ ...selectedView, name: name }));

    trackEvent(EVENTS.EDITED_DATASET_NAME, {
      dataset_id: selectedDatasetId,
      dataset_name: name,
    });
  };

  const onSelectSchema = (schemaId: number | string) => {
    const schema = parentSchemas.find((s) => s.id === schemaId);
    if (!selectedDatasetId || !schema) return;

    selectedView &&
      dispatch(
        saveComputedView({ ...selectedView, namespaceId: schema.fido_id?.toString() ?? '' }),
      );
  };

  const getUnderlyingData = (
    query: string,
    pageNumber?: number,
    onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
  ) => {
    if (!selectedDatasetId) return;
    const view = getView(selectedDatasetId);

    if (!view) return;

    dispatch(
      fetchEditorDatasetPreviewThunk(
        {
          selectedDatasetId,
          query,
          parentSchemaId: parentSchemas.find((s) => s.fido_id === view.namespaceId)?.id ?? -1,
        },
        pageNumber,
        onSuccess,
      ),
    );
  };

  const selectedView = getView(selectedDatasetId ?? '');
  const selectedDataset = datasets ? datasets[selectedDatasetId ?? ''] : null;
  const data = selectedDatasetId ? datasetData[selectedDatasetId] : undefined;

  const savedSchema = getEmbeddoSchemaFromFidoSchema(selectedView?.columnDefinitions ?? []);

  return (
    <DashboardDatasetEditor
      // @ts-ignore
      activeDatasetConfig={
        selectedView && selectedDataset
          ? getDatasetConfigFromView(selectedView, selectedDataset)
          : null
      }
      activeDatasetData={data ?? null}
      activeDatasetSavedSchema={savedSchema}
      activeDatasetSchema={data?.schema ?? savedSchema}
      activeQuery={selectedDataset?.queryDraft ?? selectedDataset?.query ?? ''}
      // @ts-ignore
      datasetConfigs={datasetConfigs}
      fetchData={getUnderlyingData}
      onCreate={onCreate}
      onDelete={onDelete}
      onEditName={onEditName}
      onSave={onSaveQuery}
      onSaveDraft={onSaveQueryDraft}
      onSelectSchema={onSelectSchema}
      pageWidth={pageWidth}
      parentSchemas={parentSchemas}
      selectedDatasetId={selectedDatasetId}
      setSelectedDatasetId={(datasetId: string) => {
        setSelectedDatasetId(datasetId);

        trackEvent(EVENTS.SELECTED_DATASET, {
          dataset_id: datasetId,
          dataset_name: selectedView?.name,
        });
      }}
    />
  );
};

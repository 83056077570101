import { sortBy } from 'utils/standard';

import { DEFAULT_DATE_RANGES, NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT } from 'types/dateRangeTypes';
import { DateElemConfig, DateRangePickerElemConfig } from 'types/dashboardTypes';
import { WritableDraft } from 'immer/dist/internal';

const dateRangeList = Object.values(DEFAULT_DATE_RANGES);
const numDefaultRanges = dateRangeList.length;

export const getValidDateRanges = (
  config: DateRangePickerElemConfig,
  sort = false,
): DEFAULT_DATE_RANGES[] => {
  const hiddenSet = new Set(config.hiddenDefaultRanges ?? []);
  const newRangesToShow = new Set(config.newRangesToShow ?? []);

  const filteredDateRanges = dateRangeList.filter((dateRange) =>
    isVisible(dateRange, hiddenSet, newRangesToShow),
  );

  return sort ? sortDateRanges(filteredDateRanges, config.defaultRangesOrder) : filteredDateRanges;
};

export const getSortedDateRangesWithVisibility = (
  config: DateRangePickerElemConfig,
): { range: DEFAULT_DATE_RANGES; isVisible: boolean }[] => {
  const sorted = sortDateRanges(dateRangeList, config.defaultRangesOrder);
  const hiddenSet = new Set(config.hiddenDefaultRanges ?? []);
  const newRangesToShow = new Set(config.newRangesToShow ?? []);
  return sorted.map((range) => ({
    range,
    isVisible: isVisible(range, hiddenSet, newRangesToShow),
  }));
};

// There are two ways for a range to be hidden.
// 1. It is in the hiddenDefaultRanges list
// 2. It is one of the new ranges that are added and have not been enabled by user
export const toggleRangeVisibility = (
  range: DEFAULT_DATE_RANGES,
  isVisible: boolean,
  draft: WritableDraft<DateElemConfig>,
) => {
  if (!draft.hiddenDefaultRanges) draft.hiddenDefaultRanges = [];

  if (isVisible) {
    draft.hiddenDefaultRanges.push(range);
    return;
  }

  // If its one of the new date ranges we have to make sure to include them in the new list
  if (NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT.has(range) && !draft.newRangesToShow?.includes(range)) {
    if (!draft.newRangesToShow) draft.newRangesToShow = [];
    draft.newRangesToShow.push(range);
  } else {
    draft.hiddenDefaultRanges = draft.hiddenDefaultRanges.filter((r) => r !== range);
  }
};

const isVisible = (
  dateRange: DEFAULT_DATE_RANGES,
  hiddenSet: Set<DEFAULT_DATE_RANGES>,
  newRangesToShow: Set<DEFAULT_DATE_RANGES>,
): boolean => {
  if (hiddenSet.has(dateRange)) return false;

  // New ranges that are added have to be explicitly enabled by user to be shown
  return !NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT.has(dateRange) || newRangesToShow.has(dateRange);
};

const sortDateRanges = (
  dateRanges: DEFAULT_DATE_RANGES[],
  rangeOrder: DEFAULT_DATE_RANGES[] | undefined,
) =>
  rangeOrder?.length
    ? sortBy(dateRanges, (dateRange) => {
        const index = rangeOrder.indexOf(dateRange);
        return index === -1 ? numDefaultRanges : index;
      })
    : dateRanges;

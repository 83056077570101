import { FC, useEffect, useState } from 'react';

import { sprinkles, Icon } from 'components/ds';
import * as styles from './styles.css';

import { IconName } from 'components/ds/Icon';

type BaseProps = {
  icon: IconName;
  title: string;
  defaultOpen?: boolean;
};

type InternalStateProps = {
  defaultOpen?: boolean;
  isOpen?: never;
  onOpen?: never;
};

type ExternalStateProps = {
  isOpen: boolean;
  onOpen: (isOpen: boolean) => void;
  defaultOpen?: never;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

type Props = BaseProps & InteractionProps;

export const ConfigSection: FC<Props> = ({
  defaultOpen,
  children,
  icon,
  title,
  isOpen,
  onOpen,
}) => {
  const [currIsOpen, setCurrIsOpen] = useState(defaultOpen ?? false);

  useEffect(() => {
    if (isOpen === undefined) return;
    setCurrIsOpen(isOpen);
  }, [isOpen]);

  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div
        className={styles.sectionHeader}
        onClick={() => {
          if (onOpen) onOpen(!currIsOpen);
          else setCurrIsOpen((prev) => !prev);
        }}>
        <div className={styles.sectionTitleContainer}>
          <Icon name={icon} />
          <div className={sprinkles({ heading: 'h3', color: 'contentPrimary' })}>{title}</div>
        </div>
        <Icon name={currIsOpen ? 'chevron-down' : 'chevron-right'} />
      </div>
      {currIsOpen ? children : null}
    </div>
  );
};

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'utils/standard';
import { isSuccess } from 'remotedata';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Button, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  BuiltInListItem,
  BuiltInListItemOverlay,
} from 'pages/ReportBuilderEditor/BuiltIns/BuiltInListItem';
import { BuiltInReportView } from 'pages/ReportBuilderEditor/BuiltIns/BuiltInReportView';
import { EditorLeftColumn } from '../EditorLeftColumn';
import * as styles from './BuiltIns.css';

import {
  createBuiltInReport,
  getReportBuilderConfig,
  getSelectedBuiltIn,
  updateBuiltInReportConfig,
  reorderBuiltIns,
  getOrderedBuiltIns,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  clearReportBuilderPreview,
  selectBuiltInForEdit,
  setVersionForPreviewThunk,
} from 'reducers/thunks/reportBuilderEditorThunks';
import { clearSelectedReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportType } from 'reportBuilderContent/reducers/types';

export const ReportBuilderBuiltIns: FC = () => {
  const dispatch = useDispatch();
  const [draggingData, setDraggingData] = useState<BuiltInData | undefined>();

  const {
    currentConfig,
    selectedReport,
    selectedGroupId,
    customerGroups,
    configData,
    selectedBuiltIn,
    orderedBuiltIns,
  } = useSelector(
    (state: ReduxState) => ({
      selectedGroupId: state.customers.selectedGroupId,
      customerGroups: state.customers.groups,
      currentConfig: state.reportEditing.currentConfig,
      selectedReport: state.reportEditing.selectedReport,
      configData: getReportBuilderConfig(state.reportBuilderEdit),
      selectedBuiltIn: getSelectedBuiltIn(state.reportBuilderEdit),
      orderedBuiltIns: getOrderedBuiltIns(state.reportBuilderEdit),
    }),
    shallowEqual,
  );

  const selectedEditReportId =
    selectedReport?.type === ReportType.EDIT_BUILT_IN ? selectedReport.id : undefined;

  useEffect(() => {
    dispatch(setVersionForPreviewThunk());
    return () => {
      dispatch(clearReportBuilderPreview());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedBuiltIn) dispatch(clearSelectedReport());
    else if (selectedBuiltIn.id !== selectedEditReportId)
      dispatch(selectBuiltInForEdit(selectedBuiltIn));
  }, [dispatch, selectedBuiltIn, selectedEditReportId]);

  const isSaved = useMemo(
    () => isEqual(currentConfig, selectedBuiltIn?.config),
    [currentConfig, selectedBuiltIn],
  );

  useEffect(() => {
    if (selectedBuiltIn && currentConfig && !isSaved && selectedBuiltIn.id === selectedEditReportId)
      dispatch(
        updateBuiltInReportConfig({
          builtInId: selectedBuiltIn.id,
          config: currentConfig,
        }),
      );
  }, [currentConfig, dispatch, isSaved, selectedBuiltIn, selectedEditReportId]);

  const customerToken = useMemo(() => {
    if (!isSuccess(customerGroups)) return;
    return customerGroups.data.find((group) => group.id === selectedGroupId)?.token;
  }, [selectedGroupId, customerGroups]);

  const createNewBuiltIn = useCallback(() => dispatch(createBuiltInReport()), [dispatch]);

  const draggingBuiltIn = useMemo(() => {
    if (!draggingData) return;
    return configData?.builtInReports?.[draggingData.id];
  }, [configData, draggingData]);

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    setDraggingData(undefined);

    const activeId = over?.id;
    const overId = active.id;
    if (typeof activeId == 'string' && typeof overId == 'string' && activeId !== overId)
      dispatch(reorderBuiltIns({ activeId, overId }));
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Only drag if the user moves the tab, otherwise the drag would prevent selection and using the dropdown menu
        distance: 2,
      },
    }),
  );

  if (!customerToken) return <div>No token found or no selected customer</div>;

  return (
    <div className={styles.rootClass}>
      <EditorLeftColumn>
        <div className={styles.leftColClass}>
          <div className={styles.header}>
            <Button fillWidth icon="plus" onClick={createNewBuiltIn} variant="primary">
              New Built In Report
            </Button>
          </div>
          <div className={styles.builtInListItems}>
            <div className={styles.infoText}>
              <p>
                Built In Reports allow your users to view your data without having to create their
                own reports. They can also be used as a starting point for your users to create
                their own reports.
              </p>
              <p>
                Built In Reports are automatically saved, but you must save and publish the entire
                Report Builder using &quot;Version Control&quot; in order for your users to see
                changes you make.
              </p>
            </div>
            <DndContext
              modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
              onDragEnd={handleDragEnd}
              onDragStart={({ active }) => setDraggingData(active.data?.current as BuiltInData)}
              sensors={sensors}>
              <SortableContext
                items={configData?.builtInOrder || []}
                strategy={verticalListSortingStrategy}>
                {orderedBuiltIns.map((builtIn) => (
                  <BuiltInListItem
                    builtIn={builtIn}
                    key={builtIn.id}
                    selectedBuiltInId={selectedBuiltIn?.id}
                  />
                ))}
              </SortableContext>
              <DragOverlay dropAnimation={null}>
                {draggingBuiltIn ? <BuiltInListItemOverlay builtIn={draggingBuiltIn} /> : null}
              </DragOverlay>
            </DndContext>
          </div>
        </div>
      </EditorLeftColumn>
      <div className={styles.previewContainer}>
        {selectedBuiltIn ? (
          <div className={styles.existingBuiltInBanner}>
            Editing &quot;{selectedBuiltIn.name}&quot;
          </div>
        ) : null}
        {currentConfig && selectedBuiltIn ? (
          <BuiltInReportView
            builtInConfig={selectedBuiltIn}
            currentConfig={currentConfig}
            isSaved={isSaved}
          />
        ) : (
          <div className={styles.noBuiltIns}>
            <EmbedText body="b1" color="contentPrimary">
              No Built Ins created
            </EmbedText>
            <Button
              className={sprinkles({ marginTop: 'sp1' })}
              onClick={createNewBuiltIn}
              variant="tertiary">
              Create your first Built In
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

type BuiltInData = {
  id: string;
};

import { FC, forwardRef, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import cx from 'classnames';

import { IdTag } from './IdTag';
import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import { DataPanelLinkOverlay } from './DataPanelLinkOverlay';
import { PanelError } from 'pages/dashboardPage/DashboardDatasetView/PanelError';
import * as styles from './wrapperStyles.css';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { DashboardStates } from 'reducers/rootReducer';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import { DashboardElement, DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { prepareDataPanel } from 'utils/dashboardUtils';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { UNSUPPORTED_CHART_ERROR } from 'constants/dashboardConstants';

type Props = {
  dashboardElements: DashboardElement[];
  // We need to pass the id in case it is not set below
  dataPanelId: string;
  // If editable section chart got deleted then there is no DP
  dataPanel: DataPanel | undefined;
  datasetNamesToId: Record<string, string>;
  datasets: Record<string, ResourceDataset>;
  dpEndsOnRightSide: boolean | undefined;
  hideEditingElements?: boolean;
  isDemoCustomer?: boolean;
  isDragging: boolean;
  isEditing: boolean;
  isInContainer?: boolean;
  isResizing: boolean;
  isViewOnly: boolean;
  pageType: PAGE_TYPE;
  variables: DashboardVariableMap;

  onSelect: () => void;
  onDelete?: () => void;
  stopDragging?: () => void;
};

export const DataPanelWrapper: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    {
      dataPanel,
      isDragging,
      isResizing,
      stopDragging,
      isViewOnly,
      dashboardElements,
      datasets,
      isDemoCustomer,
      dpEndsOnRightSide,
      pageType,
      variables,
      isEditing,
      onDelete,
      dataPanelId,
      hideEditingElements,
      onSelect,
      datasetNamesToId,
      children,
      isInContainer,
      ...props
    },
    ref,
  ) => {
    const { isSelected, viewMode, datasetData } = useSelector(
      (state: DashboardStates) => ({
        isSelected: state.dashboardInteractions.selectedItem?.id === dataPanelId,
        datasetData: state.dashboardData.datasetData,
        viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      }),
      shallowEqual,
    );

    const preparedDataPanel = useMemo(() => {
      if (!dataPanel) return;
      return prepareDataPanel(variables, dataPanel, datasets, datasetData, dashboardElements);
    }, [variables, dataPanel, datasets, dashboardElements, datasetData]);

    const applyHoverClass = isEditing && !isSelected && !isDragging;

    return (
      <div
        {...props}
        className={cx(styles.dataPanel, {
          [styles.selectedItem]: isSelected,
          [styles.editableElement]: isEditing,
          [styles.hoverElement]: applyHoverClass,
        })}
        key={dataPanelId}
        onClick={(e) => {
          if (isEditing && !isSelected) onSelect();
          e.stopPropagation();
        }}
        ref={ref}>
        {isEditing && !hideEditingElements ? (
          <IdTag
            name={onDelete ? undefined : dataPanel?.provided_id}
            onDelete={onDelete}
            stopDragging={stopDragging}
          />
        ) : null}
        {preparedDataPanel ? (
          <>
            <DashboardDatasetView
              canDownloadDataPanel
              dataPanel={preparedDataPanel}
              datasetNamesToId={datasetNamesToId}
              datasets={datasets}
              displayDemoWatermark={isDemoCustomer}
              dpEndsOnRightSide={dpEndsOnRightSide}
              editableDashboard={isEditing}
              isInContainer={isInContainer}
              isSelected={isSelected}
              isUpdatingPosition={isResizing || isDragging}
              isViewOnly={isViewOnly}
              pageType={pageType}
              variables={variables}
              viewMode={viewMode}
            />
            {isEditing && dataPanel ? (
              <DataPanelLinkOverlay
                dataPanelDatasetId={getDataPanelDatasetId(preparedDataPanel)}
                dataPanelId={dataPanelId}
                datasets={datasets}
                operationType={dataPanel.visualize_op.operation_type}
              />
            ) : null}
          </>
        ) : (
          <PanelError
            className={cx(
              embedSprinkles({ borderRadius: 'container' }),
              GLOBAL_STYLE_CLASSNAMES.container.outline.border,
              GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
            )}
            description={UNSUPPORTED_CHART_ERROR.DESCRIPTION}
            title={UNSUPPORTED_CHART_ERROR.TITLE}
          />
        )}
        {/* Children here is the resizer handle, so not needed unless editing */}
        {isEditing && !hideEditingElements ? children : null}
      </div>
    );
  },
);

DataPanelWrapper.displayName = 'DataPanelWrapper';

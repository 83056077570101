import { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { partition } from 'utils/standard';
import * as RD from 'remotedata';
import { sprinkles, Button, Tag, Tooltip, Select, InfoIcon } from 'components/ds';
import { SelectValues } from 'components/ds/Select';

import { GroupTag } from 'actions/teamActions';
import * as styles from '../styles.css';
import type { CustomerContentProps } from '.';
import { ReduxState } from 'reducers/rootReducer';

export type GroupTagProps = Pick<
  CustomerContentProps,
  'editorGroup' | 'setEditorGroup' | 'groupTags' | 'selectedGroup'
>;

export const GroupTagSection: FC<GroupTagProps> = ({
  editorGroup,
  setEditorGroup,
  groupTags,
  selectedGroup,
}) => {
  const [selectedGroupTagId, setSelectedGroupTagId] = useState<number | undefined>();
  const selectedParent = useSelector((state: ReduxState) => state.customers.selectedParent);

  const handleAddGroupTag = () => {
    if (!selectedGroupTagId) return;
    setEditorGroup((draft) => {
      if (draft.selectedGroupTagIds.includes(selectedGroupTagId)) return;
      draft.selectedGroupTagIds.push(selectedGroupTagId);
    });
    setSelectedGroupTagId(undefined);
  };

  const [selectedGroupTags, unSelectedGroupTags] = useMemo(() => {
    if (!RD.isSuccess(groupTags)) return [[], []];
    const groupTagIdSet = new Set(editorGroup.selectedGroupTagIds);
    return partition(groupTags.data, (tag) => groupTagIdSet.has(tag.id));
  }, [groupTags, editorGroup.selectedGroupTagIds]);

  const groupTagValues: SelectValues<string> = useMemo(
    () =>
      unSelectedGroupTags.map((tag) => ({
        value: tag.id.toString(),
        label: tag.name,
        icon: 'users',
      })),
    [unSelectedGroupTags],
  );

  const inheritedGroupTags = useMemo(() => {
    const parentTags: GroupTag[] = [];
    if (!RD.isSuccess(groupTags) || selectedParent === undefined) return [];
    selectedParent.computed_group_tags.forEach((tagId) => {
      const tag = groupTags.data.find((t) => t.id === tagId);
      if (tag) parentTags.push(tag);
    });
    return parentTags;
  }, [groupTags, selectedParent]);

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1', marginBottom: 'sp2' })}>
      <div className={styles.groupTagSectionContainer}>
        <div className={sprinkles({ flex: 1 })}>
          <div className={styles.label}>
            Group Tags
            <InfoIcon
              text="Use Group Tags to add customers to groups. Configure Group Tags on the Settings Page."
              tooltipSide="right"
            />
          </div>
          <Select
            className={selectedGroup ? styles.dropdownSelectSideSheet : styles.dropdownSelectModal}
            disabled={!RD.isSuccess(groupTags)}
            onChange={(id) => setSelectedGroupTagId(parseInt(id))}
            placeholder="Select group tag"
            selectedValue={selectedGroupTagId ? selectedGroupTagId.toString() : undefined}
            values={groupTagValues}
          />
        </div>
        <Button disabled={!selectedGroupTagId} onClick={handleAddGroupTag} variant="primary">
          Add
        </Button>
      </div>
      <div className={styles.tagOutputContainer}>
        {selectedGroupTags.length + inheritedGroupTags.length > 0 ? (
          <div className={styles.emailTagsContainer}>
            {inheritedGroupTags.map((tag) => (
              <Tooltip key={`tooltip-${tag.id}`} text="Inherited From Parent">
                <Tag leftIcon="users">{tag.name}</Tag>
              </Tooltip>
            ))}
            {selectedGroupTags.map((tag) => (
              <Tag
                intent="active"
                key={tag.id}
                leftIcon="users"
                onClose={() =>
                  setEditorGroup((draft) => {
                    draft.selectedGroupTagIds = draft.selectedGroupTagIds.filter(
                      (tagId) => tagId !== tag.id,
                    );
                  })
                }>
                {tag.name}
              </Tag>
            ))}
          </div>
        ) : (
          <div className={styles.emptyItemsText}>No group tags configured for this customer</div>
        )}
      </div>
    </div>
  );
};

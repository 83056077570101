import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';

import { DatasetRow } from 'types/datasets';
import { BaseCol } from 'types/columnTypes';
import { ReportBuilderReduxState } from '../reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createApiRequestConfig } from 'actions/actionUtils';
import { makeThunkRequest } from 'utils/thunkUtils';
import {
  CustomerReportRowCountBody,
  FetchEmbeddedDataBody,
  FetchReportBuilderDatasetData,
} from '../apiTypes';
import {
  getDistinctColumnBody,
  getAggBody,
  getViewRequestParams,
  isPivotView,
  getTotalBody,
  getDrilldownView,
} from './utils';
import { createJob } from 'components/JobQueue/createJob';
import { enqueueReportBuilderJobsThunk } from './jobThunks';
import { JobDefinition } from 'actions/jobQueueActions';
import { CustomerReportView } from 'actions/customerReportActions';
import {
  getDistinctDataId,
  getAggDataId,
  getAiDataId,
  getDatasetDataId,
  getDrilldownDataId,
} from 'reportBuilderContent/reducers/reportEditingUtils';
import { fetchFidoEmbedReportBuilderView } from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import { generateReportBuilderComputations } from 'utils/fido/fidoInstructionShims';
import { useFidoForRequest } from 'utils/fido/fidoUtils';
import { getFilterableRawColumns, getCurrentView } from 'utils/customerReportUtils';
import { validateColumnsThunk } from 'reportBuilderContent/thunks/dataThunks';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

/**
 * Thunks for fetching customer report data in the embedded component or iFrame
 */
const makeCustomerReportDataRequest = (
  postData: FetchEmbeddedDataBody,
  customerToken: string | undefined,
  onSuccess?: (response: { rows: DatasetRow[] }) => void,
) => {
  const requestConfig = createApiRequestConfig(
    'customer_reports/get_data/',
    'POST',
    postData,
    customerToken,
  );
  return makeThunkRequest<FetchReportBuilderDatasetData>(requestConfig, 'Error loading data', {
    onSuccess,
  });
};

export const fetchEmbeddedDistinctColumn =
  (column: BaseCol): Thunk =>
  async (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing, fido } = getState();
    const { requestInfo, variables, team, reportBuilderVersion } = embeddedReportBuilder;
    const { currentConfig } = reportEditing;
    if (!currentConfig?.dataInfo) return;

    const dataConfig = getDistinctColumnBody(column);
    const dataBody: FetchEmbeddedDataBody = {
      ...dataConfig,
      variables,
      resource_embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: currentConfig.dataInfo.datasetId,
    };
    const fetchArgs: FetchReportDataArgs = {
      dataBody,
      page: 1,
      id: getDistinctDataId(column),
    };

    const dataset = reportBuilderVersion?.config.datasets[currentConfig.dataInfo.datasetId];
    if (!dataset) return;

    if (
      useFidoForRequest(
        {
          type: 'embedded',
          useFido: team?.feature_flags.use_fido,
          parentSchemaDataSourceMapping: {},
        },
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoEmbedReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(dataConfig),
          },
        }),
      );
    } else if (team?.feature_flags.use_job_queue) {
      dispatch(fetchEmbeddedReportDataJob(fetchArgs));
    } else {
      dispatch(fetchEmbeddedReportDataApi(fetchArgs));
    }
  };

export const fetchEmbeddedAggData =
  (view: CustomerReportView): Thunk =>
  async (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing, fido } = getState();
    const { requestInfo, variables, team, reportBuilderVersion } = embeddedReportBuilder;
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId;
    const dataset = datasetId && reportBuilderVersion?.config.datasets[datasetId];
    if (!dataset) return;

    const columns = getFilterableRawColumns(view.columnOrder, dataset);
    const body = getAggBody(columns);
    if (!body.aggs.length) return;

    const dataBody: FetchEmbeddedDataBody = {
      ...body,
      variables,
      resource_embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
    };
    const fetchArgs: FetchReportDataArgs = { dataBody, page: 1, id: getAggDataId(dataset.id) };

    const useFido = useFidoForRequest(
      {
        type: 'embedded',
        useFido: team?.feature_flags.use_fido,
        parentSchemaDataSourceMapping: {},
      },
      fido,
      dataset,
    );
    if (useFido) {
      dispatch(
        fetchFidoEmbedReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: { pagingConfiguration: { page: 0 } },
            queryContext: {},
            computation: generateReportBuilderComputations(body),
          },
        }),
      );
    } else if (team?.feature_flags.use_job_queue) {
      dispatch(fetchEmbeddedReportDataJob(fetchArgs));
    } else {
      dispatch(fetchEmbeddedReportDataApi(fetchArgs));
    }
  };

export const fetchEmbeddedTotalData =
  (view: CustomerReportView): Thunk =>
  async (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing, fido } = getState();
    const { requestInfo, variables, team, reportBuilderVersion } = embeddedReportBuilder;
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId;
    const dataset = datasetId && reportBuilderVersion?.config.datasets[datasetId];
    const showTotals = reportBuilderVersion?.config?.general?.showTotals;
    if (!dataset || !showTotals || !view.totals) return;

    const columns = getFilterableRawColumns(view.columnOrder, dataset);
    const body = getTotalBody(columns, view.filters, view.totals);
    if (!body.aggs.length) return;

    const dataBody: FetchEmbeddedDataBody = {
      ...body,
      variables,
      resource_embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
    };
    const fetchArgs: FetchReportDataArgs = { dataBody, page: 1, id: getAggDataId(dataset.id) };

    const useFido = useFidoForRequest(
      {
        type: 'embedded',
        useFido: team?.feature_flags.use_fido,
        parentSchemaDataSourceMapping: {},
      },
      fido,
      dataset,
    );
    if (useFido) {
      dispatch(
        fetchFidoEmbedReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: { pagingConfiguration: { page: 0 } },
            queryContext: {},
            computation: generateReportBuilderComputations(body),
          },
        }),
      );
    } else if (team?.feature_flags.use_job_queue) {
      dispatch(fetchEmbeddedReportDataJob(fetchArgs));
    } else {
      dispatch(fetchEmbeddedReportDataApi(fetchArgs));
    }
  };

export const fetchEmbeddedReportRowCount = createAsyncThunk<
  { row_count: number },
  FetchRowCountArgs,
  EmbeddedState
>(ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT, async ({ rowCountBody }, { getState }) => {
  const { customerToken } = getState().embeddedReportBuilder.requestInfo;

  const requestConfig = createApiRequestConfig(
    'customer_reports/get_row_count/',
    'POST',
    rowCountBody,
    customerToken,
  );

  return makeThunkRequest(requestConfig, 'Error loading row count');
});

export const fetchEmbeddedDatasetModalData =
  (datasetId: string): Thunk =>
  (dispatch, getState) => {
    const { embeddedReportBuilder, fido } = getState();
    const { requestInfo, variables, team, reportBuilderVersion } = embeddedReportBuilder;

    const dataBody: FetchEmbeddedDataBody = {
      resource_embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: datasetId,
      variables,
      page: 1,
      sort: [],
      aggs: [],
      filters: [],
      group_bys: [],
      hidden_columns: [],
      columns: [],
    };

    const fetchArgs: FetchReportDataArgs = {
      rowCountBody: undefined,
      dataBody,
      page: 1,
      id: getDatasetDataId(datasetId),
    };

    const dataset = reportBuilderVersion?.config.datasets[datasetId];
    if (!dataset) return;

    if (
      useFidoForRequest(
        {
          type: 'embedded',
          useFido: team?.feature_flags.use_fido,
          parentSchemaDataSourceMapping: {},
        },
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoEmbedReportBuilderView({
          id: fetchArgs.id,
          dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0 },
            },
            queryContext: {},
            computation: null,
          },
        }),
      );
    } else if (team?.feature_flags.use_job_queue) {
      dispatch(fetchEmbeddedReportDataJob(fetchArgs));
    } else {
      dispatch(fetchEmbeddedReportDataApi(fetchArgs));
    }
  };

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 */
export const fetchEmbeddedReportData =
  (page: number | undefined): Thunk =>
  (dispatch, getState) => {
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    if (!viewConfig) return;

    dispatch(fetchEmbeddedData(page, dataset, viewConfig, viewConfig.id));
  };

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 */
export const fetchEmbeddedDrilldownData =
  (page: number | undefined): Thunk =>
  (dispatch, getState) => {
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView, drilldownConfigs } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    const currentConfigs = currentView ? drilldownConfigs[currentView] : null;
    if (!viewConfig || !currentConfigs?.filters) return;

    const filteredConfig: CustomerReportView = getDrilldownView(
      viewConfig,
      currentConfigs.filters,
      currentConfigs.sort,
    );
    dispatch(fetchEmbeddedData(page, dataset, filteredConfig, getDrilldownDataId(viewConfig.id)));
  };

export const fetchEmbeddedData =
  (
    page: number | undefined,
    dataset: ReportBuilderDataset,
    viewConfig: CustomerReportView,
    dataId: string,
  ): Thunk =>
  (dispatch, getState) => {
    const {
      embeddedReportBuilder: { variables, team, requestInfo },
      fido,
    } = getState();

    const viewParams = getViewRequestParams(viewConfig, dataset);
    const rowCountBody: CustomerReportRowCountBody = {
      resource_embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
      variables: variables,
      ...viewParams,
    };
    const dataBody: FetchEmbeddedDataBody = {
      ...rowCountBody,
      page: page || 1,
    };

    // If first page data is requested and not a pivot table also get row count
    const shouldFetchRowCount = page === undefined && !isPivotView(viewConfig);
    const fetchArgs: FetchReportDataArgs = {
      rowCountBody: shouldFetchRowCount ? rowCountBody : undefined,
      dataBody,
      page: page || 1,
      id: dataId,
    };

    if (
      useFidoForRequest(
        {
          type: 'embedded',
          useFido: team?.feature_flags.use_fido,
          parentSchemaDataSourceMapping: {},
        },
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoEmbedReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: {
              includeTotalResults: shouldFetchRowCount,
              pagingConfiguration: { page: (page || 1) - 1 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(viewParams),
          },
        }),
      );
    } else if (team?.feature_flags.use_job_queue) {
      dispatch(fetchEmbeddedReportDataJob(fetchArgs));
    } else {
      dispatch(fetchEmbeddedReportDataApi(fetchArgs));
    }
  };

export const fetchEmbeddedReportDataApi = createAsyncThunk<
  FetchReportBuilderDatasetData,
  FetchReportDataArgs,
  EmbeddedState
>(
  ACTION.FETCH_CUSTOMER_REPORT_DATA,
  async ({ rowCountBody, dataBody, id }, { getState, dispatch }) => {
    const { customerToken } = getState().embeddedReportBuilder.requestInfo;
    // If first page data is requested and not a pivot table also get row count
    const onSuccess = rowCountBody
      ? () => dispatch(fetchEmbeddedReportRowCount({ rowCountBody, id }))
      : undefined;
    return makeCustomerReportDataRequest(dataBody, customerToken, onSuccess);
  },
);

const fetchEmbeddedReportDataJob =
  ({ rowCountBody, dataBody, id }: FetchReportDataArgs): Thunk =>
  (dispatch) => {
    const jobs: Record<string, JobDefinition> = {};
    const dataJob = createJob({
      job_type: ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB,
      job_args: { ...dataBody, id },
    });
    Object.assign(jobs, dataJob);

    // If first page data is requested and not a pivot table also get row count
    if (rowCountBody) {
      const rowCountJob = createJob({
        job_type: ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB,
        job_args: { ...rowCountBody, id },
      });
      Object.assign(jobs, rowCountJob);
    }

    dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

export const fetchEmbeddedAiData =
  (viewConfig: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const {
      embeddedReportBuilder: { team, requestInfo, variables, reportBuilderVersion },
      reportEditing: { currentConfig },
      fido,
    } = getState();
    const datasetId = currentConfig?.dataInfo?.datasetId ?? '';
    const dataset = reportBuilderVersion?.config.datasets?.[datasetId];
    if (!dataset || !viewConfig || !currentConfig?.dataInfo) return;

    const viewParams = getViewRequestParams(viewConfig, dataset);
    const dataBody: FetchEmbeddedDataBody = {
      ...viewParams,
      resource_embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
      variables: variables,
      columns: currentConfig.dataInfo.columns,
      page: 1,
    };

    // If first page data is requested and not a pivot table also get row count
    const fetchArgs: FetchReportDataArgs = {
      rowCountBody: undefined,
      dataBody,
      page: 1,
      id: getAiDataId(),
    };

    if (
      useFidoForRequest(
        {
          type: 'embedded',
          useFido: team?.feature_flags.use_fido,
          parentSchemaDataSourceMapping: {},
        },
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoEmbedReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0 },
            },
            queryContext: {},
            computation: generateReportBuilderComputations(viewParams),
          },
        }),
      );
    } else if (team?.feature_flags.use_job_queue) {
      dispatch(fetchEmbeddedReportDataJob(fetchArgs));
    } else {
      dispatch(fetchEmbeddedReportDataApi(fetchArgs));
    }
  };

type FetchReportDataArgs = {
  rowCountBody?: CustomerReportRowCountBody;
  dataBody: FetchEmbeddedDataBody;
  id: string; // id is used in the reducer
  page: number; // page is used in the reducer
};

type FetchRowCountArgs = {
  rowCountBody: CustomerReportRowCountBody;
  id: string; // id is used in the reducer
};

type EmbeddedState = { state: ReportBuilderReduxState };

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;

import { FC, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { EmbedButton, EmbedPopover } from 'components/embed';
import { TableOfContents } from 'pages/ReportBuilder/Onboarding/TableOfContents';
import { Doc, DOC_PAGES } from 'pages/ReportBuilder/Onboarding/Docs';

import { sprinkles } from 'components/ds';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

export const DocsPopover: FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState<Doc | undefined>();
  const DocComponent = page && DOC_PAGES[page].component;

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen);
      if (isOpen)
        dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_DOCS_OPENED));
    },
    [dispatch],
  );

  return (
    <EmbedPopover
      showCloseButton
      align="end"
      className={sprinkles({ overflow: 'auto' })}
      isOpen={isOpen}
      onBack={page ? () => setPage(undefined) : undefined}
      onOpenChange={handleOpenChange}
      side="bottom"
      title={page || 'Report Builder guides'}
      trigger={<EmbedButton icon="circle-info" variant="tertiary" />}
      width="xlarge">
      <div className={popoverContentClassName}>
        {DocComponent ? <DocComponent /> : <TableOfContents setPage={setPage} />}
      </div>
    </EmbedPopover>
  );
};

const popoverContentClassName = sprinkles({
  flexItems: 'column',
  gap: 'sp2',
  color: 'contentPrimary',
  paddingTop: 'sp1',
  paddingBottom: 'sp2',
  paddingX: 'sp2',
});

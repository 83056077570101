import { Layout } from '@explo-tech/react-grid-layout';

import { ReduxState } from 'reducers/rootReducer';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import {
  filterHiddenElements,
  getDataPanelsDependentOnVariable,
  filterHiddenPanels,
} from 'utils/variableUtils';
import { DashboardVersionConfig, EditableSectionChart } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

export type DashboardConfig = {
  dataPanels: Record<string, DataPanel>;
  datasets: Record<string, ResourceDataset>;
  elements: DashboardElement[];

  // Sometimes they need to be accessed even if not in layout when editing
  editableSectionCharts?: Record<string, EditableSectionChart>;
};

/*
 * This function gets things needed for all the requests like data panels, datasets, dashboard elements
 * Each type of embed has it in different places so this is where the logic of where to get it is at
 * Once we remove architect hopefully we can find a way to make this more efficient
 */
export const getDashboardConfig = (state: ReduxState): DashboardConfig | undefined => {
  const { type } = state.dashboardLayout.requestInfo;
  // TODO - FIDO add config.views for embed

  // Edit dashboard page
  if (type === 'app') {
    const config = state.dashboardEditConfig?.config;
    if (!config) return;

    const editableSectionDps = getEditableSectionDps(
      config,
      state.dashboardEditConfig.editableSectionLayout,
      state.dashboardInteractions.interactionsInfo.isEditing,
    );
    const dataPanels = editableSectionDps
      ? { ...config.data_panels, ...editableSectionDps }
      : config.data_panels;

    return {
      dataPanels,
      elements: Object.values(config.elements),
      datasets: config.datasets,
      editableSectionCharts: config.editable_section?.enabled
        ? config.editable_section.charts
        : undefined,
    };
    // Embedded dashboard
  } else if (type === 'embedded') {
    const config = state.embedDashboard?.dashboardVersion?.configuration;
    if (!config) return;
    const hiddenElementSet = new Set(state.embedDashboard.hiddenElements);

    const editableSectionDps = getEditableSectionDpsEmbed(
      config,
      state.embedDashboard.currentEditableSectionLayout,
    );
    const dataPanels = filterHiddenPanels(config.data_panels, hiddenElementSet);

    return {
      dataPanels: editableSectionDps ? { ...dataPanels, ...editableSectionDps } : dataPanels,
      elements: filterHiddenElements(config.elements, hiddenElementSet),
      datasets: config.datasets,
    };
  }
  return;
};

const getEditableSectionDps = (
  { editable_section: config }: DashboardVersionConfig,
  previewLayout: Layout[] | null,
  isEditing: boolean,
) => {
  if (!config?.enabled) return;

  const layout = isEditing ? config.default_layout : previewLayout ?? config.default_layout;
  return getEditableSectionDpsHelper(config.charts, layout);
};

const getEditableSectionDpsEmbed = (
  { editable_section: config }: DashboardVersionConfig,
  layout: Layout[] | undefined,
) => {
  if (!config?.enabled) return;
  return getEditableSectionDpsHelper(config.charts, layout);
};

const getEditableSectionDpsHelper = (
  charts: Record<string, EditableSectionChart>,
  layout: Layout[] | undefined,
): Record<string, DataPanelTemplate> | undefined => {
  if (!layout) return;

  const panelsInLayout = new Set(layout.map((elem) => elem.i));
  if (panelsInLayout.size === 0) return;

  const dataPanels: Record<string, DataPanelTemplate> = {};
  Object.values(charts).forEach((chart) => {
    if (!panelsInLayout.has(chart.data_panel.id)) return;
    dataPanels[chart.data_panel.id] = chart.data_panel;
  });

  return dataPanels;
};

export const getDataPanelsDependentOnVars = (
  config: DashboardConfig,
  varNameSet: Set<string>,
  variables: DashboardVariableMap,
): string[] => {
  return getDataPanelsDependentOnVariable(
    Object.values(config.dataPanels),
    config.datasets,
    config.elements,
    varNameSet,
    variables,
  ).map((dp) => dp.id);
};

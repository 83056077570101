import { FC } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import cx from 'classnames';

import { Button, sprinkles } from 'components/ds';
import { ButtonProps } from 'components/ds/Button';
import * as styles from '../Modal/index.css';

type BaseProps = {
  portalContainerId?: string;
  title: string;
};

type FooterButtonProps = ButtonProps & {
  text?: string;
};

type FooterProps = {
  actionButtonProps: Omit<FooterButtonProps, 'variant'> & {
    // Limit possible variants of action button
    variant?: 'primary' | 'destructive';
  };
  cancelButtonProps?: FooterButtonProps;
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  // The controlled open state of the AlertDialog. Must be used in conjunction with onOpenChange.
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
};

type InternalStateProps = {
  isOpen?: never;
  onOpen?: () => void;
  onClose?: () => void;
  // The open state of the AlertDialog when it is initially rendered. Use when you do not need to control its open state.
  defaultOpen?: boolean;
  // Element that opens and closes modal
  trigger: JSX.Element;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps & FooterProps;

export const AlertModal: FC<Props> = ({
  isOpen,
  portalContainerId,
  title,
  children,
  defaultOpen = false,
  trigger,
  actionButtonProps,
  cancelButtonProps,
  onOpen,
  onClose,
}) => {
  const content = (
    <div className={styles.dialogContainer}>
      <AlertDialog.Overlay className={styles.dialogOverlay} data-testid="alert-modal-overlay" />
      <AlertDialog.Content className={styles.dialogContent({ size: 'small' })}>
        <AlertDialog.Title className={styles.alertModalHeader} data-testid="alert-modal-title">
          {title}
        </AlertDialog.Title>
        <div
          className={cx(
            styles.childContent({ size: 'small' }),
            sprinkles({ paddingX: typeof children === 'string' ? 'sp3' : undefined }),
          )}>
          {children}
        </div>
        <div
          className={cx(
            styles.dialogFooter,
            sprinkles({ paddingTop: !children ? 'sp0' : undefined }),
          )}>
          <AlertDialog.Cancel asChild>
            <Button
              data-testid="alert-modal-cancel-button"
              onClick={cancelButtonProps?.onClick || onClose}
              variant="tertiary"
              {...cancelButtonProps}>
              {cancelButtonProps?.text || 'Cancel'}
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <Button
              data-testid="alert-modal-action-button"
              variant={actionButtonProps.variant || 'destructive'}
              {...actionButtonProps}>
              {actionButtonProps.text || 'Delete'}
            </Button>
          </AlertDialog.Action>
        </div>
      </AlertDialog.Content>
    </div>
  );

  return (
    <AlertDialog.Root
      defaultOpen={defaultOpen}
      onOpenChange={(open) => {
        if (open && onOpen) onOpen();
        else if (!open && onClose) onClose();
      }}
      open={isOpen}>
      {trigger ? <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger> : null}
      {portalContainerId ? (
        <AlertDialog.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </AlertDialog.Portal>
      ) : (
        content
      )}
    </AlertDialog.Root>
  );
};

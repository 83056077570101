import { FC } from 'react';
import * as styles from './index.css';
import { Link } from 'react-router-dom';
import cx from 'classnames';

type BreadcrumbsHierarchyItem = {
  /**
   * Text to show in breadcrumb item.
   */
  text: string;
  /**
   * Link for internal path to navigate to when breadcrumb item is clicked.
   */
  link?: string;
  onClick?: () => void;
};

export type Props = {
  /**
   * A list of breadcrumb items to display linking to other places in the navigation items.
   * Each item can optionally have a `link` property.
   */
  items: BreadcrumbsHierarchyItem[];
  /**
   * Optional class name for style overrides
   */
  className?: string;
};

export const Breadcrumbs: FC<Props> = ({ items, className }) => {
  return (
    <div className={cx(styles.baseContainer, className)}>
      {items.map((item, index) => (
        <span key={index}>
          {item.link ? (
            <Link className={styles.linkItem} style={{ textDecoration: 'none' }} to={item.link}>
              {item.text}
            </Link>
          ) : (
            <span
              className={item?.onClick ? styles.linkItem : styles.displayItem}
              onClick={item?.onClick}>
              {item.text}
            </span>
          )}
          {index !== items.length - 1 && <span className={styles.divider}> / </span>}
        </span>
      ))}
    </div>
  );
};

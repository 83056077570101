import { defineEmbedPostAction, defineEmbedAction } from './actionUtils';
import { ACTION } from 'actions/types';

import {
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
  FetchDataPanelRowCountData,
} from 'actions/responseTypes';
import {
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
} from './dataPanelTemplateAction';
import { EmbedCustomer, EmbedTeam } from 'actions/teamActions';
import { GlobalStyleConfig } from 'globalStyles/types';
import { CustomerEditableSection, EmbedDashboard } from 'actions/dashboardActions';
import { DashboardVersion } from 'types/dashboardVersion';
import { FetchDashboardDatasetPreviewBody } from './datasetActions';

// Dashboard

type EmbedFetchDashboardBody = {
  dashboard_embed_id: string | undefined;
  version_number: number | undefined;
  environment: string | undefined;
  is_preview?: boolean;
};

export type EmbedFidoDashboardDataConfig = {
  token: string;
  url: string;
  data_source_mapping: Record<string, string>;
};

type EmbedFetchDashboardData = {
  dashboard_version: DashboardVersion;
  style_config_v2: GlobalStyleConfig | null;
  dashboard_template: EmbedDashboard;
  team: EmbedTeam;
  customer: EmbedCustomer;
  additional_style_configs: Record<string, GlobalStyleConfig>;
  customer_editable_section: CustomerEditableSection | null;
  fido_config?: EmbedFidoDashboardDataConfig | null;
};

export const { actionFn: embedFetchDashboard, ...embedFetchDashboardActions } =
  defineEmbedPostAction<EmbedFetchDashboardBody, EmbedFetchDashboardData>(
    ACTION.EMBED_FETCH_DASHBOARD,
    'embed',
    'dashboard',
    'POST',
  );

// Dashboard Data

export const {
  actionFn: embedFetchDataPanel,
  requestAction: embedFetchDataPanelRequest,
  errorAction: embedFetchDataPanelError,
  successAction: embedFetchDataPanelSuccess,
} = defineEmbedPostAction<FetchDataPanelBody, FetchDataPanelData>(
  ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE,
  'embed',
  'get_data',
  'POST',
);

type EmbedFetchDataPanelRowCountBody = FetchDataPanelRowCountBody & {
  version_number: number;
};

export const {
  actionFn: embedFetchDataPanelRowCount,
  requestAction: embedFetchDataPanelRowCountRequest,
  successAction: embedFetchDataPanelRowCountSuccess,
  errorAction: embedFetchDataPanelRowCountError,
} = defineEmbedPostAction<EmbedFetchDataPanelRowCountBody, FetchDataPanelRowCountData>(
  ACTION.EMBED_FETCH_DATA_PANEL_ROW_COUNT,
  'embed',
  'get_row_count',
  'POST',
);

type EmbedFetchSecondaryDataBody = FetchSecondaryDataBody & { version_number: number };

export const {
  actionFn: embedFetchSecondaryData,
  requestAction: embedFetchSecondaryDataRequest,
  successAction: embedFetchSecondaryDataSuccess,
  errorAction: embedFetchSecondaryDataError,
} = defineEmbedPostAction<EmbedFetchSecondaryDataBody, FetchDataPanelData>(
  ACTION.EMBED_FETCH_SECONDARY_DATA,
  'embed',
  'get_data',
  'POST',
);

type EmbedFetchDashboardDatasetPreviewBody = FetchDashboardDatasetPreviewBody & {
  version_number: number;
  resource_embed_id: string;
};

export const {
  actionFn: embedFetchDashboardDatasetPreview,
  successAction: embedFetchDashboardDatasetPreviewSuccess,
  errorAction: embedFetchDashboardDatasetPreviewError,
} = defineEmbedPostAction<EmbedFetchDashboardDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW,
  'embed',
  'get_preview',
  'POST',
);

// Share Links

export type EmbedFetchShareData = {
  dashboard_embed_id: string;
  customer_token: string;
  user_group_name: string;
  version_number: number;
  is_strict_viewing_mode: boolean;
  favicon_url?: string;
  site_title?: string;
};

export const { actionFn: embedFetchShareData } = defineEmbedAction<EmbedFetchShareData>(
  ACTION.EMBED_FETCH_SHARE_DATA,
  'share',
  'get_share_data',
  'GET',
);

type EmbedFetchShareDataWithPasswordBody = {
  password: string;
  username: string | undefined;
};

export const { actionFn: embedFetchShareDataWithPassword } = defineEmbedPostAction<
  EmbedFetchShareDataWithPasswordBody,
  EmbedFetchShareData
>(ACTION.EMBED_FETCH_SHARE_DATA, 'share', 'get_share_data', 'POST');

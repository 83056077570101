import { FC, forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { usePrevious } from '@radix-ui/react-use-previous';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { FilterPopover } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/FilterPopover';
import { FilterDropdown } from './FilterDropdown';
import * as styles from './styles.css';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { DATE, DATETIME } from 'constants/dataConstants';
import {
  DATE_RELATIVE_OPTION,
  DATE_RELATIVE_OPTIONS,
  DATE_RELATIVE_OPTIONS_BY_ID,
} from 'constants/dataPanelEditorConstants';
import { FilterValueDateType, FilterValueRelativeDateType } from 'constants/types';
import {
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPERATORS,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_RELATIVE_PICKER,
} from 'types/filterOperations';
import { FilterableColumn, getFilterDefaultOperation } from 'utils/customerReportUtils';
import { newOperatorShouldClearSelectedVariable } from 'utils/dashboardUtils';
import { getDatePickerDateFromISO, getLuxonDateFromDatePicker } from 'utils/timezoneUtils';
import {
  RELATIVE_DATE_OPTIONS,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_TYPES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
} from 'types/dateRangeTypes';
import { getDefaultRangeValues, getDefaultRelativeValue } from 'utils/dateUtils';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
};

export const DateFilterPopover: FC<Props> = ({ column, clause }) => {
  // Used to hold open popover when the popover has a dropdown open
  const [holdPopoverOpen, setHoldPopoverOpen] = useState(false);

  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );
  const [dateType, setDateType] = useState(DEFAULT_DATE_TYPES.EXACT);
  const [relativeDateRange, setRelativeDateRange] = useState<DEFAULT_DATE_RANGES | undefined>();
  const [relativeDate, setRelativeDate] = useState<RELATIVE_DATE_OPTIONS | undefined>();
  const [value, setValue] = useState(
    clause?.filterValue as FilterValueRelativeDateType | undefined,
  );
  const [dateValue, setDateValue] = useState(
    clause?.filterValue as FilterValueDateType | undefined,
  );

  const oldOperator = usePrevious(currOperator);
  useEffect(() => {
    if (newOperatorShouldClearSelectedVariable(currOperator, oldOperator)) {
      setDateValue(undefined);
      setValue(undefined);
    }
  }, [currOperator, oldOperator]);

  const operator = FILTER_OPERATOR_TYPES_BY_ID[currOperator];
  const isDate = FILTER_OPS_DATE_PICKER.has(operator.id);
  const isDateRange = FILTER_OPS_DATE_RANGE_PICKER.has(operator.id);
  useEffect(() => {
    if (isDateRange && relativeDateRange) {
      setDateType(DEFAULT_DATE_TYPES.RELATIVE);
      const { endDate, startDate } = getDefaultRangeValues(relativeDateRange, true, 'UTC');
      setDateValue({ startDate: startDate.toISO(), endDate: endDate.toISO() });
    } else if (isDate && relativeDate) {
      setDateType(DEFAULT_DATE_TYPES.RELATIVE);
      const startDate = getDefaultRelativeValue(relativeDate, 'UTC').toISO();
      setDateValue({ startDate });
    }
  }, [isDate, isDateRange, relativeDate, relativeDateRange]);

  const renderContent = () => {
    if (FILTER_OPS_NO_VALUE.has(operator.id)) return null;

    const startDate = dateValue?.startDate ? getDatePickerDateFromISO(dateValue?.startDate) : null;
    if (isDate) {
      return (
        <div className={styles.relativeContent}>
          <DatePicker
            customInput={<CustomDatePickerInput />}
            onChange={(input) => {
              setDateType(DEFAULT_DATE_TYPES.EXACT);
              setRelativeDate(undefined);
              if (!input) setDateValue(undefined);

              const date = getLuxonDateFromDatePicker(input as Date);
              setDateValue({
                startDate: date.startOf('day').toISO(),
              });
            }}
            selected={startDate}
            showTimeSelect={false}
          />
        </div>
      );
    }

    if (isDateRange) {
      const endDate = dateValue?.endDate ? getDatePickerDateFromISO(dateValue?.endDate) : null;
      return (
        <div className={styles.relativeContent}>
          <DatePicker
            selectsStart
            customInput={<CustomDatePickerInput />}
            endDate={endDate}
            onChange={(input) => {
              setDateType(DEFAULT_DATE_TYPES.EXACT);
              setRelativeDateRange(undefined);
              if (!input) setDateValue((prev) => ({ ...prev, startDate: undefined }));

              const date = getLuxonDateFromDatePicker(input as Date);
              setDateValue((prev) => ({ ...prev, startDate: date.startOf('day').toISO() }));
            }}
            selected={startDate}
            showTimeSelect={false}
            startDate={startDate}
          />
          <div className={sprinkles({ body: 'b3' })}>to</div>
          <DatePicker
            selectsEnd
            customInput={<CustomDatePickerInput />}
            endDate={endDate}
            minDate={startDate}
            onChange={(input) => {
              setDateType(DEFAULT_DATE_TYPES.EXACT);
              setRelativeDateRange(undefined);
              if (!input) setDateValue((prev) => ({ ...prev, endDate: undefined }));

              const date = getLuxonDateFromDatePicker(input as Date);
              setDateValue((prev) => ({ ...prev, endDate: date.startOf('day').toISO() }));
            }}
            selected={endDate}
            showTimeSelect={false}
            startDate={startDate}
          />
        </div>
      );
    }

    if (FILTER_OPS_RELATIVE_PICKER.has(operator.id)) {
      const relativeValue = (value as FilterValueRelativeDateType | undefined) ?? {};
      const selectedRelative = relativeValue.relativeTimeType
        ? DATE_RELATIVE_OPTIONS_BY_ID[relativeValue.relativeTimeType.id]
        : undefined;

      return (
        <div className={styles.relativeContent}>
          <div className={sprinkles({ flex: 1 })}>
            <EmbedText body="b3">Number</EmbedText>
            <input
              className={styles.valueInput}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue === '') return setValue({ ...relativeValue, number: undefined });

                const newVal = Number(newValue);
                if (isNaN(newVal)) return;
                setValue({ ...relativeValue, number: newVal });
              }}
              placeholder="Number"
              value={relativeValue.number === undefined ? '' : relativeValue.number}
            />
          </div>
          <div className={sprinkles({ flex: 1 })}>
            <EmbedText body="b3">Time Type</EmbedText>
            <FilterDropdown
              items={DATE_RELATIVE_OPTIONS}
              onOpenChange={setHoldPopoverOpen}
              onSelect={(relativeType) =>
                setValue({
                  ...relativeValue,
                  relativeTimeType: { id: relativeType as DATE_RELATIVE_OPTION },
                })
              }
              placeholder="Select a Time"
              selectedItem={selectedRelative}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <FilterPopover
      clause={clause}
      column={column}
      holdPopoverOpen={holdPopoverOpen}
      operator={currOperator}
      operatorOptions={filterOperations}
      setHoldPopoverOpen={setHoldPopoverOpen}
      setOperator={setOperator}
      value={
        FILTER_OPS_DATE_PICKER.has(operator.id) || FILTER_OPS_DATE_RANGE_PICKER.has(operator.id)
          ? dateValue
          : value
      }>
      <div className={styles.dateContent}>
        {FILTER_OPS_DATE_RANGE_PICKER.has(operator.id) ? (
          <FilterDropdown
            items={relativeDateRangeOptions}
            onOpenChange={setHoldPopoverOpen}
            onSelect={(id) => setRelativeDateRange(id as DEFAULT_DATE_RANGES)}
            placeholder="Custom Date Range"
            selectedItem={
              dateType === DEFAULT_DATE_TYPES.RELATIVE && relativeDateRange
                ? {
                    id: relativeDateRange,
                    name:
                      DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[relativeDateRange] ??
                      relativeDateRange,
                  }
                : undefined
            }
          />
        ) : FILTER_OPS_DATE_PICKER.has(operator.id) ? (
          <FilterDropdown
            items={relativeDateOptions}
            onOpenChange={setHoldPopoverOpen}
            onSelect={(id) => setRelativeDate(id as RELATIVE_DATE_OPTIONS)}
            placeholder="Custom Date"
            selectedItem={
              dateType === DEFAULT_DATE_TYPES.RELATIVE
                ? relativeDate && { id: relativeDate, name: relativeDate }
                : undefined
            }
          />
        ) : null}
        {renderContent()}
      </div>
    </FilterPopover>
  );
};

const CustomDatePickerInput = forwardRef<HTMLInputElement>(function CustomDatePickerInput(
  props,
  ref,
) {
  return <input {...props} className={styles.valueInput} placeholder="MM/DD/YYYY" ref={ref} />;
});

const filterOperations = FILTER_OPERATORS.filter(
  (op) =>
    (op.supported_column_types.has(DATE) || op.supported_column_types.has(DATETIME)) &&
    (FILTER_OPS_RELATIVE_PICKER.has(op.id) ||
      FILTER_OPS_DATE_RANGE_PICKER.has(op.id) ||
      FILTER_OPS_DATE_PICKER.has(op.id) ||
      FILTER_OPS_NO_VALUE.has(op.id)),
);

const relativeDateOptions = Object.values(RELATIVE_DATE_OPTIONS).map((id) => ({
  id,
  name: id,
}));

const relativeDateRangeOptions = Object.values(DEFAULT_DATE_RANGES).map((id) => ({
  id,
  name: DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[id] ?? id,
}));

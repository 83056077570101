import cx from 'classnames';
import { useDispatch } from 'react-redux';
import * as styles from './index.css';

import { Icon as BPIcon, IconName as BPIconName } from '@blueprintjs/core';
import { IconButton, sprinkles } from 'components/ds';

import { IconName } from 'components/ds/Icon';
import { setLinkedElementHover } from 'reducers/dashboardInteractionsReducer';

type Props = {
  actionIcon: IconName;
  elemId: string;
  icon: JSX.Element | BPIconName;
  name: string;
  onActionClicked: () => void;
  subName: string;
};

export const HeaderElemOptionSimple = ({
  actionIcon,
  elemId,
  icon,
  name,
  onActionClicked,
  subName,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <div
      className={cx(elemOptionSimpleStyle, styles.headerElemOptionContainer)}
      onMouseEnter={() => dispatch(setLinkedElementHover(elemId))}
      onMouseLeave={() => dispatch(setLinkedElementHover(null))}>
      <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
        <div className={styles.headerElemIcon}>
          <BPIcon icon={icon} />
        </div>
        <div className={sprinkles({ paddingRight: 'sp2' })}>
          <div
            className={cx(
              sprinkles({
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }),
              styles.headerElemName,
            )}>
            {name}
          </div>
          <div className={sprinkles({ color: 'gray10', whiteSpace: 'nowrap' })}>{subName}</div>
        </div>
      </div>
      <IconButton
        name={actionIcon}
        onClick={() => {
          onActionClicked();
          dispatch(setLinkedElementHover(null));
        }}
        variant="tertiary"
      />
    </div>
  );
};

const elemOptionSimpleStyle = sprinkles({
  flexItems: 'alignCenterBetween',
  borderRadius: 8,
  padding: 'sp.5',
  width: 'fill',
});

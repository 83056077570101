import { createAsyncThunk } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  QueryResourceService,
  QueryExecutionResponse,
  OpenAPI,
  QueryPreviewRequest,
  ViewRunRequest,
  ExportFormat,
  QueryExportResponse,
} from '@explo-tech/fido-api';

import { FidoRequestFn, makeFidoThunkRequest } from 'utils/thunkUtils';
import { getFilterInfo, getSortInfo } from 'utils/adHocUtils';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import {
  generateComputations,
  generateReportBuilderComputations,
} from 'utils/fido/fidoInstructionShims';
import { getQueryLimit } from 'utils/fido/fidoInstructionShimUtils';
import { ComputedViewWithIds, getDataSource, getDefaultDataSource } from 'utils/fido/fidoUtils';

import { ACTION } from 'actions/types';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import { Dataset, FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { AdHocOperationInstructions, SortInfo_DEPRECATED } from 'types/dataPanelTemplate';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { saveComputedView } from '../fidoThunks';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { FilterOperationInstructions, SortInfo } from 'constants/types';
import { ExportSpreadsheetType } from 'actions/exportActions';
import { CustomerReportFilter, CustomerReportGroupBy } from 'actions/customerReportActions';
import { AggColInfo } from 'types/columnTypes';
import { convertSortInfoToList } from 'utils/adHocUtils';

OpenAPI.BASE = process.env.REACT_APP_FIDO_URL ?? '';

/**
 * Fetches a preview of the provided ComputedView. This fetch uses the default data source of
 * the view's namespace to query data.
 */
export const fetchFidoViewPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    view: Pick<ComputedViewWithIds, 'id' | 'namespaceId'>;
    body: QueryPreviewRequest;
    onSuccess?: (data: QueryExecutionResponse) => void;
  },
  { state: ReduxState }
>(ACTION.FETCH_FIDO_COMPUTED_VIEW_PREVIEW, async ({ body, onSuccess, view }, { getState }) => {
  const state = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const defaultDataSourceId = getDefaultDataSource(view.namespaceId, state)?.fido_id;

  if (defaultDataSourceId)
    requestFn = () =>
      QueryResourceService.getQueryPreview(defaultDataSourceId, view.namespaceId, body);

  return makeFidoThunkRequest(
    requestFn,
    state.fido.fidoToken ?? '',
    'Error loading preview for your query',
    onSuccess,
  );
});

/**
 * Report Builder Fido Thunks
 */
export const fetchFidoReportBuilderView = createAsyncThunk<
  QueryExecutionResponse,
  {
    id: string;
    dataset: ReportBuilderDataset;
    body: ViewRunRequest;
    customerId: number;
  },
  { state: ReduxState }
>(ACTION.FETCH_FIDO_REPORT_BUILDER_VIEW, async ({ dataset, body, customerId }, { getState }) => {
  const { fido, parentSchemas, dataSource, customers } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const { fido_id: viewId, namespace_id: namespaceId } = dataset;

  const customer = RD.isSuccess(customers.groups)
    ? customers.groups.data.find((c) => c.id === customerId)
    : undefined;

  if (customer && viewId && namespaceId) {
    const dataSourceId = getDataSource({
      namespaceId,
      parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
      schemas: RD.getOrDefault(parentSchemas.usedParentSchemas, []),
      dataSources: RD.getOrDefault(dataSource.dataSources, []),
      type: 'app',
    });

    if (dataSourceId) {
      requestFn = () => QueryResourceService.runView(dataSourceId, namespaceId, viewId, body);
    }
  }

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

export const fetchFidoEmbedReportBuilderView = createAsyncThunk<
  QueryExecutionResponse,
  {
    id: string;
    dataset: ReportBuilderDataset;
    body: ViewRunRequest;
  },
  { state: ReportBuilderReduxState }
>(ACTION.EMBED_FETCH_FIDO_REPORT_BUILDER_VIEW, async ({ dataset, body }, { getState }) => {
  const { fido } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const { fido_id: viewId, namespace_id: namespaceId } = dataset;

  if (viewId && namespaceId) {
    const dataSourceId = getDataSource({ namespaceId: namespaceId, type: 'embedded' }, fido);

    if (dataSourceId) {
      requestFn = () => QueryResourceService.runView(dataSourceId, namespaceId, viewId, body);
    }
  }

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

export const fetchFidoReportBuilderQueryPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    dataset: ReportBuilderDataset;
    body: QueryPreviewRequest;
    customerId: number;
    save?: boolean | undefined;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_FIDO_REPORT_BUILDER_QUERY_PREVIEW,
  async ({ dataset, body, customerId, save }, { getState, dispatch }) => {
    const { fido, parentSchemas, dataSource, customers } = getState();

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;
    let onSuccess: ((response: QueryExecutionResponse) => void) | undefined = undefined;

    const selectedView = RD.getOrDefault(fido.computedViews, []).find(
      (view) => view.id == dataset.fido_id ?? '',
    );
    const customer = RD.isSuccess(customers.groups)
      ? customers.groups.data.find((c) => c.id === customerId)
      : undefined;

    if (customer && selectedView) {
      const dataSourceId = getDataSource({
        namespaceId: selectedView.namespaceId,
        parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
        schemas: RD.getOrDefault(parentSchemas.usedParentSchemas, []),
        dataSources: RD.getOrDefault(dataSource.dataSources, []),
        type: 'app',
      });

      if (dataSourceId) {
        requestFn = () =>
          QueryResourceService.getQueryPreview(dataSourceId, selectedView.namespaceId, body);
        onSuccess = save
          ? (response) =>
              dispatch(
                saveComputedView({
                  ...selectedView,
                  query: body.query,
                  columnDefinitions: response.meta.schema.propertySchema,
                }),
              )
          : undefined;
      }
    }

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
      onSuccess,
    );
  },
);

/**
 * Dashboard FIDO thunks
 */

/**
 * Fetches the data for the provided ComputedView. This fetch uses the current user's assigned
 * data source.
 */
export const fetchFidoViewData = createAsyncThunk<
  QueryExecutionResponse,
  {
    body: Pick<FetchDashboardDatasetPreviewBody, 'variables' | 'query_limit' | 'timezone'>;
    dataset: ResourceDataset;
  },
  { state: DashboardStates }
>(
  ACTION.FETCH_COMPUTED_VIEW_DATA,
  async ({ body: { variables, query_limit: queryLimit }, dataset }, { getState }) => {
    const { fido, dashboardLayout } = getState();

    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

    if (namespaceId && viewId) {
      const dataSource = getDataSource(
        { namespaceId: namespaceId, ...dashboardLayout.requestInfo },
        fido,
      );

      requestFn = dataSource
        ? () =>
            QueryResourceService.runView(dataSource, namespaceId, viewId, {
              queryContext: variables,
              dataRequestParameters: {
                pagingConfiguration: {
                  perPage: queryLimit,
                },
              },
              computation: null,
            })
        : null;
    }

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
    );
  },
);

/**
 * Fetches the data for the provided data panel. This fetch uses the current users's assigned data source
 */
export const fetchFidoComputationDataThunk =
  (
    dataPanel: DataPanel,
    datasets: Record<string, Dataset>,
    adHocInstructions?: AdHocOperationInstructions,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const { fido, dashboardLayout, dashboardData } = getState();

    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    const { namespace_id: namespaceId, fido_id: viewId } =
      datasets[getDataPanelDatasetId(dataPanel)] ?? {};

    if (!namespaceId || !viewId) return;
    const dataSource = getDataSource({ namespaceId, ...dashboardLayout.requestInfo }, fido);

    if (!dataSource) return;

    const filterInfo = adHocInstructions
      ? adHocInstructions.filterInfo
      : getFilterInfo(dataPanel.visualize_op.operation_type, dataPanelData);
    const sortInfo = convertSortInfoToList(
      adHocInstructions ? adHocInstructions.sortInfo : getSortInfo(dataPanel, dataPanelData),
    );

    const pageNumber = adHocInstructions ? adHocInstructions.currentPage : undefined;

    const computations = generateComputations(dataPanel, { sortInfo, filterInfo });

    (computations ?? []).forEach((computation, i) => {
      // for some data panels, like kpi trends, we require multiple queries to populate the chart.
      // The first query is the primary request, and subsequent queries are secondary requests
      const isPrimaryRequest = i === 0;

      const requestFn = () =>
        QueryResourceService.runView(dataSource, namespaceId, viewId, {
          queryContext: dashboardData.variables ?? {},
          dataRequestParameters: {
            includeTotalResults: isPrimaryRequest,
            pagingConfiguration: {
              page: !isPrimaryRequest ? 0 : (pageNumber ?? 1) - 1,
              perPage: getQueryLimit(dataPanel.visualize_op),
            },
          },
          computation,
        });

      dispatch(
        fetchFidoComputationData({
          requestFn,
          reducerArgs: {
            dataPanelId: dataPanel.id,
            filterInfo,
            sortInfo,
            pageNumber,
            isPrimaryRequest,
          },
        }),
      );
    });
  };

/**
 * Fetches the data for the provided data panel. This fetch uses the current users's assigned data source
 */
export const fetchFidoComputationData = createAsyncThunk<
  QueryExecutionResponse,
  {
    requestFn: FidoRequestFn<QueryExecutionResponse>;

    // used by the reducers
    reducerArgs: {
      dataPanelId: string;
      sortInfo: SortInfo_DEPRECATED[] | undefined;
      filterInfo: FilterOperationInstructions | undefined;
      pageNumber: number | undefined;
      isPrimaryRequest: boolean;
    };
  },
  { state: DashboardStates }
>(ACTION.FETCH_SUMMARIZED_VIEW_DATA, async ({ requestFn }, { getState }) => {
  const { fidoToken } = getState().fido;

  return makeFidoThunkRequest(requestFn, fidoToken ?? '', 'Error loading preview for your query');
});

export const downloadExploreComputationSpreadsheet = createAsyncThunk<
  QueryExportResponse,
  {
    dataPanel: DataPanel;
    dataset: ResourceDataset;
    adHocInstructions?: AdHocOperationInstructions;
    fileFormat: ExportSpreadsheetType;
  },
  { state: DashboardStates }
>(
  ACTION.FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET,
  async ({ dataPanel, dataset, adHocInstructions, fileFormat }, { getState, rejectWithValue }) => {
    const { fido, dashboardLayout, dashboardData } = getState();

    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) return rejectWithValue('Unable to find namespace or view id');

    const dataSource = getDataSource({ namespaceId, ...dashboardLayout.requestInfo }, fido);

    if (!dataSource) return rejectWithValue('Unable to find data source');

    const filterInfo = adHocInstructions
      ? adHocInstructions.filterInfo
      : getFilterInfo(dataPanel.visualize_op.operation_type, dataPanelData);
    const sortInfo = convertSortInfoToList(
      adHocInstructions ? adHocInstructions.sortInfo : getSortInfo(dataPanel, dataPanelData),
    );
    const computations = generateComputations(dataPanel, { sortInfo, filterInfo });

    let exportFormat: ExportFormat;
    switch (fileFormat) {
      case 'csv':
        exportFormat = dataPanel.visualize_op.generalFormatOptions?.export?.csvFormat?.tsvEnabled
          ? ExportFormat.TSV
          : ExportFormat.CSV;
        break;
      case 'xlsx':
        exportFormat = ExportFormat.XLSX;
        break;
    }

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.exportView(dataSource, namespaceId, viewId, {
          queryContext: dashboardData.variables ?? {},
          exportConfiguration: {
            fileName:
              dataPanel.visualize_op.generalFormatOptions?.export?.downloadFileName ??
              dataPanel.provided_id ??
              'download',
            exportFormat,
          },
          // TODO the only chart that requires two computations is KPI trend, do we allow exports for that?
          computation: computations ? computations[0] : null,
        }),
      fido.fidoToken ?? '',
      'Error downloading your data panel',
    );
  },
);

export const downloadReportBuilderComputationSpreadsheet = createAsyncThunk<
  QueryExportResponse,
  {
    computationBody: {
      aggs: AggColInfo[];
      sort: SortInfo[];
      filters: CustomerReportFilter[];
      group_bys: CustomerReportGroupBy[] | undefined;
      col_group_bys: CustomerReportGroupBy[] | undefined;
      columns: string[];
      hidden_columns: string[];
    };
    dataset: ReportBuilderDataset;
    fileFormat: ExportSpreadsheetType;
    fileName: string;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET_REPORT_BUILDER,
  async ({ computationBody, dataset, fileFormat, fileName }, { getState, rejectWithValue }) => {
    const {
      fido,
      embeddedReportBuilder: { variables },
    } = getState();
    const {
      aggs,
      sort,
      filters,
      group_bys: groupBys,
      col_group_bys: colGroupBys,
      columns,
      hidden_columns: hiddenColumns,
    } = computationBody;

    const computation = generateReportBuilderComputations({
      aggs,
      sort,
      filters,
      group_bys: (groupBys ?? []).concat(colGroupBys ?? []).map((g) => ({
        column: g.column,
        bucket: g.bucket ? { id: g.bucket } : undefined,
      })),
      columns,
      hidden_columns: hiddenColumns,
    });

    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) return rejectWithValue('Unable to find namespace or view id');

    const dataSource = getDataSource({ namespaceId, type: 'embedded' }, fido);

    if (!dataSource) return rejectWithValue('Unable to find data source');
    let exportFormat: ExportFormat;
    switch (fileFormat) {
      case 'csv':
        exportFormat = ExportFormat.CSV;
        break;
      case 'xlsx':
        exportFormat = ExportFormat.XLSX;
        break;
    }

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.exportView(dataSource, namespaceId, viewId, {
          queryContext: variables,
          exportConfiguration: {
            fileName,
            exportFormat,
          },
          computation,
        }),
      fido.fidoToken ?? '',
      'Error downloading your data panel',
    );
  },
);

import { FC } from 'react';
import cx from 'classnames';

import * as styles from './IdTag.css';
import { Icon } from 'components/ds';

import { DRAGGABLE_HANDLE_CLASS } from 'constants/dashboardConstants';

type Props = {
  name?: string;

  onDelete?: () => void;
  stopDragging?: () => void;
};

export const IdTag: FC<Props> = ({ name, stopDragging, onDelete }) => {
  return (
    <>
      <div
        className={cx(styles.idTag, 'dashboardElementIdTag', DRAGGABLE_HANDLE_CLASS)}
        onMouseUp={() => stopDragging?.()}>
        <Icon className={cx(styles.dragIcon, 'dragIcon')} name="grid3-round" size="sm" />

        {name !== undefined ? (
          <div className={styles.idTagName}>{name}</div>
        ) : (
          <Icon
            className={cx(styles.dragIcon, 'dragIcon')}
            name="grid3-round"
            size="sm"
            style={{ marginLeft: -1 }}
          />
        )}
      </div>
      {onDelete ? (
        <div className={cx(styles.deleteDpIcon, 'dashboardElementDeleteTag')} onClick={onDelete}>
          <Icon name="trash" />
        </div>
      ) : null}
      <div className={styles.invisibleDivForHover} />
    </>
  );
};

import { FC } from 'react';

import * as styles from './settingsEmbedSecretsSection.css';

import { Button } from 'components/ds';
import InputLabel from 'shared/InputLabel';
import { showSuccessToast } from 'shared/sharedToasts';

import { EmbedSecret } from 'actions/embedSecretActions';

type Props = {
  embedSecret: EmbedSecret;
  setDeleteModalOpen: () => void;
  setSelectedEmbedSecret: () => void;
};

export const SettingsEmbedSecretsSection: FC<Props> = ({
  embedSecret,
  setDeleteModalOpen,
  setSelectedEmbedSecret,
}: Props) => {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    showSuccessToast('Copied To Clipboard');
  };

  return (
    <div className={styles.row} key={embedSecret.id}>
      <div className={styles.container}>
        <InputLabel text="Secret Key ID" />
        <div className={styles.textContainer} onClick={() => copyToClipboard(embedSecret.id)}>
          <div className={styles.valueText}>{embedSecret.id}</div>
          <div className={styles.copy}>Copy</div>
        </div>
      </div>
      <div className={styles.deleteButtonContainer}>
        <Button
          className={styles.deleteButton}
          icon="trash"
          onClick={() => {
            setDeleteModalOpen();
            setSelectedEmbedSecret();
          }}
          variant="destructive"
        />
      </div>
    </div>
  );
};

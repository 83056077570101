import { ReactNode } from 'react';
import produce from 'immer';
import { useTheme, makeStyles } from '@material-ui/core/index';

import { GlobalStyleConfig } from './types';
import { DEFAULT_OUTLINE_COLOR, GLOBAL_STYLE_CLASSNAMES } from './constants';
import { getCalculatedStyles } from 'globalStyles/utils';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { GlobalStylesContext } from './GlobalStylesContext';

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  children: (className: string) => ReactNode;
};

export const GlobalStylesProvider = ({ children, globalStyleConfig }: Props) => {
  const theme = useTheme();

  const {
    base: {
      actionColor: {
        default: {
          backgroundColorStyle: defaultActionColorBackgroundColorStyle,
          blackOrWhiteColorStyle: defaultActionColorBlackOrWhiteColorStyle,
          borderColorStyle: defaultActionColorBorderColorStyle,
          boxShadowStyle: defaultActionColorBoxShadowStyle,
          boxShadowImportantStyle: defaultActionColorBoxShadowImportantStyle,
        },
        buttonColor: {
          colorStyle: buttonColorStyle,
          backgroundColorStyle: buttonColorBackgroundColorStyle,
          buttonMinimalColor,
          buttonMinimalHoverStyle,
          buttonMinimalActiveHoverStyle,
          buttonMinimalActiveStyle,
          blackOrWhiteColorStyle: buttonColorBlackOrWhiteColorStyle,
          buttonHoverStyle,
          buttonActiveStyle,
          buttonActiveHoverStyle,
        },
        interactionStateColor: {
          borderColorStyle: interactionStateColorBorderColorStyle,
          boxShadowStyle: interactionStateColorBoxShadowStyle,
        },
      },
    },
    container: {
      fill: {
        offsetBackgroundColorStyle: containerFillOffsetBackgroundColorStyle,
        deeperOffsetBackgroundColorStyle: containerFillDeeperOffsetBackgroundColorStyle,
      },
      outline: {
        borderStyle: containerOutlineBorderStyle,
        boxShadowStyle: containerOutlineBoxShadowStyle,
        boxShadowInsetStyle: containerOutlineBoxShadowInsetStyle,
      },
      shadow: { shadowStyle: containerShadowStyle },
      cornerRadius: {
        default: { borderRadiusStyle: defaultContainerCornerRadiusBorderRadiusStyle },
        inputFieldCornerRadius: { borderRadiusStyle: inputFieldCornerRadiusBorderRadiusStyle },
      },
    },
    text: {
      secondaryColor: { inputPlaceholderStyle: textSecondaryInputPlaceholderColorStyle },
      overrides: {
        body: {
          primaryStyle: bodyTextPrimaryStyle,
          secondaryColorStyle: bodyTextSecondaryColorStyle,
        },
      },
    },
  } = getCalculatedStyles(globalStyleConfig, theme);

  const useStyles = makeStyles({
    core: {
      /**
       * Base // Action Color // Default
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem}`]: {
        '&.bp3-active': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
        '&.bp3-intent-primary:active': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownFilterInputOutline}`]: {
        '& .bp3-input:focus': {
          ...defaultActionColorBoxShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputBorder}`]: {
        '&:active:not(.disabled)': {
          ...defaultActionColorBorderColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputOutlineActive}`]: {
        '& .bp3-tag-input.bp3-active': {
          ...defaultActionColorBoxShadowImportantStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputTag}`]: {
        '& .bp3-tag': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.buttonBorderActive}`]: {
        '&.bp3-button': {
          ...defaultActionColorBoxShadowImportantStyle,
        },
      },
      /**
       * Base // Action Color // Button
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor}`]: {
        '&.bp3-button:not(.bp3-disabled)': {
          ...buttonColorBackgroundColorStyle,
          ...buttonColorBlackOrWhiteColorStyle,
          '& .bp3-icon': {
            ...buttonColorBlackOrWhiteColorStyle,
          },
        },
        '&.bp3-button:hover:not(.bp3-disabled)': {
          backgroundImage: 'none !important',
          ...buttonHoverStyle,
        },
        '&.bp3-button:active:not(.bp3-disabled)': {
          ...buttonActiveStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonMinimalColor}`]: {
        // Supports both Blueprint and Design System buttons
        ...buttonMinimalColor,
        '&.bp3-button:not(.bp3-disabled)': {
          ...buttonMinimalColor,
          '& .bp3-icon': {
            ...buttonMinimalColor,
          },
        },
        '&.bp3-button:hover:not(.bp3-disabled), &:hover': {
          backgroundImage: 'none !important',
          ...buttonMinimalHoverStyle,
          '& .bp3-icon': {
            ...buttonMinimalHoverStyle,
          },
        },
        '&.bp3-button:active:not(.bp3-disabled), &.bp3-button.bp3-active, &:active': {
          boxShadow: 'none',
          ...buttonMinimalActiveStyle,
          '& .bp3-icon': {
            ...buttonMinimalActiveStyle,
          },
        },
        '&.bp3-button:active:hover:not(.bp3-disabled), &:active:hover': {
          ...buttonMinimalActiveHoverStyle,
          '& .bp3-icon': {
            ...buttonMinimalActiveHoverStyle,
          },
        },
        '&.bp3-disabled': {
          ...buttonMinimalColor,
          '&.bp3-button:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonColor}`]: {
        '&.bp3-button': {
          ...buttonColorStyle,
          '& .bp3-icon': {
            ...buttonColorStyle,
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleSwitchInteraction}`]: {
        boxShadow: 'none',
        ...bodyTextPrimaryStyle,
        '&.bp3-button:hover': {
          backgroundImage: 'none',
          ...buttonHoverStyle,
        },
        '&.bp3-active': {
          ...buttonActiveStyle,
        },
        '&.bp3-active:hover': {
          ...buttonActiveHoverStyle,
        },
      },
      /**
       * Base // Action Color // Interaction States
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownItemHover}`]: {
        '&:hover:not(.disabled)': {
          ...containerFillOffsetBackgroundColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownInputBorderHover}`]:
        {
          '&:hover:not(.disabled)': {
            ...interactionStateColorBorderColorStyle,
          },
        },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.multiSelectInputBorderHover}`]:
        {
          '& .bp3-tag-input:hover:not(.disabled)': {
            ...interactionStateColorBoxShadowStyle,
          },
        },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.textInputBorderHover}`]: {
        '& .bp3-input:hover:not(.disabled)': {
          ...interactionStateColorBoxShadowStyle,
        },
      },

      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.buttonBorderHover}`]: {
        '&.bp3-button:hover': {
          boxShadow: 'none',
          ...interactionStateColorBorderColorStyle,
        },
      },
      /**
       * Container // Fill
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor}`]: {
        ...containerFillOffsetBackgroundColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.deeperOffsetBackgroundColor}`]: {
        ...containerFillDeeperOffsetBackgroundColorStyle,
      },
      /**
       * Container // Outline
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownInputBorder}`]: {
        '&.bp3-button': {
          boxShadow: 'none',
          ...containerOutlineBorderStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder}`]: {
        '& .bp3-transition-container': {
          '& .bp3-popover': {
            ...containerOutlineBorderStyle,
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.multiSelectInputBorder}`]: {
        '& .bp3-tag-input': {
          ...containerOutlineBoxShadowInsetStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownFilterInputBorder}`]: {
        '& .bp3-input': {
          ...containerOutlineBoxShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder}`]: {
        '&.bp3-button': {
          boxShadow: 'none',
          ...containerOutlineBorderStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.border}`]: {
        ...containerOutlineBorderStyle,
      },
      /**
       * Container // Shadow
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow}`]: {
        ...containerShadowStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.multiSelectInputShadow}`]: {
        '& .bp3-tag-input': {
          ...containerShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow}`]: {
        '&.bp3-button': {
          ...containerShadowStyle,
        },
      },
      /**
       * Container // Corner radius
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.popoverCornerRadius}`]: {
        // This line, popover-content, and bp3-menu are needed to override BP3's Portal dropdown
        ...defaultContainerCornerRadiusBorderRadiusStyle,
        '& .bp3-popover-content': {
          ...defaultContainerCornerRadiusBorderRadiusStyle,
        },
        '& .bp3-menu': {
          ...defaultContainerCornerRadiusBorderRadiusStyle,
        },
        '& .bp3-popover': {
          ...defaultContainerCornerRadiusBorderRadiusStyle,
        },
      },
      /**
       * Container // Input Field corner radius
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius}`]: {
        ...inputFieldCornerRadiusBorderRadiusStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.textElementInputBorderRadius}`]:
        {
          '& .bp3-input': {
            ...inputFieldCornerRadiusBorderRadiusStyle,
          },
        },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.multiselectBorderRadius}`]:
        {
          '& .bp3-tag-input': {
            ...inputFieldCornerRadiusBorderRadiusStyle,
          },
        },
      /**
       * Text // Overrides // Body
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem}`]: {
        '&.bp3-menu-item': {
          ...bodyTextPrimaryStyle,
          '&:hover:not(.bp3-active)': {
            ...bodyTextSecondaryColorStyle,
            ...containerFillDeeperOffsetBackgroundColorStyle,
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.input}`]: {
        '& .bp3-input': {
          ...bodyTextPrimaryStyle,
          '&::placeholder': {
            ...textSecondaryInputPlaceholderColorStyle,
          },
        },
        // For multi select inputs
        '& .bp3-input-ghost.bp3-multi-select-tag-input-input': {
          ...bodyTextPrimaryStyle,
          '&::placeholder': {
            ...textSecondaryInputPlaceholderColorStyle,
          },
        },
        // For dropdown buttons (that look like inputs)
        '& .bp3-button-text': {
          ...bodyTextPrimaryStyle,
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <GlobalStylesContext.Provider
      value={{
        globalStyleConfig: transformGlobalStyleConfig(globalStyleConfig),
        globalStylesClassName: classes.core,
        globalStyleVars: getGlobalStyleVars(globalStyleConfig),
      }}>
      {children(classes.core)}
    </GlobalStylesContext.Provider>
  );
};

function transformGlobalStyleConfig(globalStyleConfig: GlobalStyleConfig) {
  const newConfig = produce(globalStyleConfig, (draft) => {
    if (!draft.container.outline.enabled) {
      draft.container.outline.color = DEFAULT_OUTLINE_COLOR;
    }
  });

  return newConfig;
}

import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { BaseCol } from 'types/columnTypes';
import { ReduxState } from 'reducers/rootReducer';
import { getCurrentView } from 'utils/customerReportUtils';
import { xor } from 'utils/standard';
import { closeReportModal, saveDataInfo, createView } from './reducers/reportEditingReducer';
import {
  fetchEmbeddedDistinctColumn,
  fetchEmbeddedDatasetModalData,
  fetchEmbeddedAiData,
  fetchEmbeddedData,
} from './thunks/embeddedDataThunks';
import {
  fetchAppDistinctColumnData,
  fetchDashboardDatasetModalData,
  fetchAppAiData,
  fetchAppData,
} from 'reportBuilderContent/thunks/appDataThunks';

import {
  getDatasetDataId,
  getDrilldownDataId,
} from 'reportBuilderContent/reducers/reportEditingUtils';
import { getDrilldownView } from 'reportBuilderContent/thunks/utils';
import { validateColumnsThunk } from 'reportBuilderContent/thunks/dataThunks';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const fetchModalData =
  (datasetId: string): Thunk =>
  (dispatch, getState) => {
    const {
      embeddedReportBuilder: { isInApp },
      reportEditing: { reportData },
    } = getState();
    if (getDatasetDataId(datasetId) in reportData) return;

    if (isInApp) dispatch(fetchDashboardDatasetModalData(datasetId));
    else dispatch(fetchEmbeddedDatasetModalData(datasetId));
  };

export const createViewFromDrilldown = (): Thunk => (dispatch, getState) => {
  const { currentConfig, currentView, drilldownConfigs } = getState().reportEditing;
  const viewConfig = getCurrentView(currentConfig?.views, currentView);
  const currentConfigs = currentView ? drilldownConfigs[currentView] : null;
  if (!viewConfig) return;

  const filteredView = getDrilldownView(
    viewConfig,
    currentConfigs?.filters || [],
    currentConfigs?.sort,
  );
  dispatch(createView(filteredView));
};

export const fetchDrilldownData =
  (page?: number): Thunk =>
  (dispatch, getState) => {
    const {
      embeddedReportBuilder: { isInApp },
      reportEditing,
    } = getState();
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView, drilldownConfigs } = reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    const currentConfigs = currentView ? drilldownConfigs[currentView] : null;
    if (!viewConfig || !currentConfigs?.filters) return;

    const filteredConfig: CustomerReportView = getDrilldownView(
      viewConfig,
      currentConfigs.filters,
      currentConfigs.sort,
    );
    if (isInApp)
      dispatch(fetchAppData(page, dataset, filteredConfig, getDrilldownDataId(viewConfig.id)));
    else
      dispatch(fetchEmbeddedData(page, dataset, filteredConfig, getDrilldownDataId(viewConfig.id)));
  };

export const fetchDistinctColumnData =
  (column: BaseCol): Thunk =>
  (dispatch, getState) => {
    const { isInApp } = getState().embeddedReportBuilder;
    if (isInApp) dispatch(fetchAppDistinctColumnData(column));
    else dispatch(fetchEmbeddedDistinctColumn(column));
  };

export const fetchAiData =
  (viewConfig: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { isInApp } = getState().embeddedReportBuilder;
    if (isInApp) dispatch(fetchAppAiData(viewConfig));
    else dispatch(fetchEmbeddedAiData(viewConfig));
  };

export const updateDataInfoThunk =
  (newInfo: CustomerReportDataInfo): Thunk =>
  (dispatch, getState) => {
    // Should only access embedded state
    const { currentConfig } = getState().reportEditing;
    if (!currentConfig) return;

    const dataInfo = currentConfig.dataInfo;
    if (
      !dataInfo ||
      dataInfo.datasetId !== newInfo.datasetId ||
      xor(dataInfo.columns, newInfo.columns).length > 0
    ) {
      dispatch(saveDataInfo(newInfo));
    } else {
      dispatch(closeReportModal());
    }
  };

import WorksmithSteve from 'images/marketing/testimonials/worksmith_steve.jpeg';
import ZuddlVedha from 'images/marketing/testimonials/zuddl_vedha.jpeg';
import MedmeNick from 'images/marketing/testimonials/medme_nick.jpeg';
import GoldcastPalash from 'images/marketing/testimonials/goldcast_palash.jpeg';
import TogetherNathan from 'images/marketing/testimonials/together_nathan.jpeg';

export type Testimonial = {
  companyName: string;
  personName: string;
  personTitle: string;
  personImage: string;
  quote: string;
};

export enum TESTIMONIAL_COMPANIES {
  WORKSMITH = 'Worksmith',
  ZUDDL = 'Zuddl',
  MEDME_HEALTH = 'MedMe Health',
  TOGETHER = 'Together',
  GOLDCAST = 'Goldcast',
}

export const TESTIMONIALS: Record<TESTIMONIAL_COMPANIES, Testimonial> = {
  [TESTIMONIAL_COMPANIES.WORKSMITH]: {
    companyName: 'Worksmith',
    personName: 'Steve Hehl',
    personTitle: 'VP of Engineering',
    personImage: WorksmithSteve,
    quote:
      'Explo has allowed us to provide best-in-class reporting to our clients in a fraction of the time that it would have taken us otherwise. The tool is easy to use and the team is extremely helpful and responsive.',
  },
  [TESTIMONIAL_COMPANIES.ZUDDL]: {
    companyName: 'Zuddl',
    personName: 'Vedha Sayyaparaju',
    personTitle: 'CTO',
    personImage: ZuddlVedha,
    quote:
      'Using Explo has been awesome! We’re able to launch new dashboards to customers with ease and not a lot of engineering hours. The turnaround time is great. I’ve had feature requests implemented in minutes :)',
  },
  [TESTIMONIAL_COMPANIES.MEDME_HEALTH]: {
    companyName: 'MedMe Health',
    personName: 'Nick Hui',
    personTitle: 'CPO and Founder',
    personImage: MedmeNick,
    quote:
      'Explo has allowed us to funnel valuable dev resources into other business critical initiatives while giving us the peace-of-mind that our customer facing dashboards are taken care of. The Explo team has always been available to lend us a hand getting set up and has been indispensable in making sure their platform serves our nuanced needs.',
  },
  [TESTIMONIAL_COMPANIES.GOLDCAST]: {
    companyName: 'Goldcast',
    personName: 'Palash Soni',
    personTitle: 'CEO',
    personImage: GoldcastPalash,
    quote:
      'Explo enabled us to save months of engineering effort and quickly build a beautiful dashboard. Embedding was simple and Explo makes it easy to silo data between customers and create custom dashboards for specific customers.',
  },
  [TESTIMONIAL_COMPANIES.TOGETHER]: {
    companyName: 'Together',
    personName: 'Nathan Goldstein',
    personTitle: 'Head of Product',
    personImage: TogetherNathan,
    quote:
      'Explo solves embedded analytics in a smart way. We’ve tried other vendors and they are either too much work for engineering, or swing too far the opposite way and don’t easily allow any engineers to create specific queries that our customers want. The combination of the dashboard editor and SQL is perfect for developing quickly and to customer requirements',
  },
};

export enum SIGNUP_INFO_NEXT {
  USER = 'USER',
  DONE = 'DONE',
}

// user questions
export const QUESTION_ROLE = 'Role';
export const QUESTION_PHONE_NUMBER = 'Contact Number (Optional)';
export const QUESTION_SQL_FAMILIARITY = "What's your familiarity with SQL?";

export enum SQL_FAMILIARITIES {
  NONE = 'I’ve never written SQL before',
  SOME = 'I have some familiarity with SQL',
  VERY = 'I write SQL often',
}

export enum ROLES {
  PRODUCT = 'Product',
  ENGINEER = 'Engineer',
  DESIGN = 'Design',
  DATA_AND_ANALYTICS = 'Data and Analytics',
  IT = 'IT',
  SALES_MARKETING = 'Sales and Marketing',
  FOUNDER_MANAGEMENT = 'Founder or Management',
  OTHER = 'Other',
}
